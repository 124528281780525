import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getPostulationSponsorInterviews (pIdPostulation) {
    const URI = `${BASE_URL}/api/postulations/${pIdPostulation}/sponsorInterviews`
    return axios.get(URI, { headers })
}

export { getPostulationSponsorInterviews }