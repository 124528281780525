<template>
    <div>
        <b-button variant="transparent" size="sm" @click="modalClick"> <b-icon icon="pencil" variant="naranja" scale="1.3"></b-icon></b-button>

        <b-modal
            id="modal-update-visa-data"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">ACTUALIZAR DATOS/VISA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="updateData">
                                <!-- ds_160 -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="DS-160:" 
                                        >
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="ds-160"
                                                v-model="program_season_participant.ds_160"
                                                :state="showInputStatus('ds_160')"
                                                v-show="!loading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('ds_160')"
                                                :key="`${i}-ud-ds_160`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- ds_2019 -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="DS-2019:" 
                                        >
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2  text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="ds-2019"
                                                v-model="program_season_participant.ds_2019"
                                                :state="showInputStatus('ds_2019')"
                                                v-show="!loading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('ds_2019')"
                                                :key="`${i}-ud-ds_2019`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit" :disabled="loading">
                                            ACTUALIZAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { updateProgramSeasonParticipantVisaData } from '@/api/ProgramSeasonParticipant/UpdateVisaData'
import swal from 'sweetalert'

export default {
    name: 'UpdateUserDataComponent',
    props: {
        programSeasonParticipant: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        program_season_participant: {},
        loading: false,
        inputErrors: {},
        initialInputValues: true
    }),

    methods: {
        modalClick() {
            this.program_season_participant = JSON.parse(JSON.stringify(this.programSeasonParticipant))
            this.$bvModal.show('modal-update-visa-data')
        },
        modalClose() {
            this.$bvModal.hide('modal-update-visa-data')
            this.clearInputsAndStates()
        },

        updateData() {
            this.loading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let Form = new FormData()
            Form.append('.method', 'put')
            Form.append('ds_160', this.program_season_participant.ds_160)
            Form.append('ds_2019', this.program_season_participant.ds_2019)

            updateProgramSeasonParticipantVisaData(Form, this.program_season_participant.id)
                .then(() => {
                    swal('Actualización correcta!', 'Datos actualizados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.modalClose()
                                    this.$emit('updated')
                                    break;

                                default:
                                    console.log('updateVisaData--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                })
                .finally(() => {
                    this.loading = false
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.initialInputValues = true
            this.inputErrors = {}
        }
    }
}
</script>

<style>

</style>