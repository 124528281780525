import { mergeAttributes } from '@tiptap/core'
import Image from '@tiptap/extension-image'

export default Image.extend({
    name: 'ImageInlineCustom',

    inline() {
        return true
    },

    group() {
        return 'inline'
    },

    parseHTML() {
        return [
        {
           tag: this.options.allowBase64
            ? `img[src][data-type="${this.name}"]`
            : `img[src]:not([src^="data:"])[data-type="${this.name}"]`,
        },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes({ 'data-type': `${this.name}` }, this.options.HTMLAttributes, HTMLAttributes)]
    },

    addCommands() {
        return {
            setInlineImage: options => ({ commands }) => {
                return commands.insertContent({
                type: this.name,
                attrs: options,
                })
          },
        }
    },
})