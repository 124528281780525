import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function storeParticipantDocument (pFormData) {
    const URI = `${BASE_URL}/api/participantDocuments`
    return axios.post(URI, pFormData, { headers })
}

function updateParticipantDocument (pFormData, pIdParticipantDocument) {
    const URI = `${BASE_URL}/api/participantDocuments/${pIdParticipantDocument}`
    return axios.post(URI, pFormData, { headers })
}

function registerParticipantDocument (pFormData, pIdParticipantDocument) {
    const URI = `${BASE_URL}/api/participantDocument/${pIdParticipantDocument}/register`
    return axios.post(URI, pFormData, { headers })
}

function destroyParticipantDocument (pIdParticipantDocument) {
    const URI = `${BASE_URL}/api/participantDocuments/${pIdParticipantDocument}`
    return axios.delete(URI, { headers })
}

export {
    storeParticipantDocument,
    updateParticipantDocument,
    registerParticipantDocument,
    destroyParticipantDocument
}