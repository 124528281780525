<template>
    <payments-filter-component
        :ui="ui"
        :routeDetails="routeDetails"
        :routePayments="routePayments"
    ></payments-filter-component>
</template>

<script>
import PaymentsFilterComponent from '@/components/Admin-Employee/Participant/Filters/PaymentsFilterComponent.vue'

export default {
    name : 'PaymentsFilter',
    components: { PaymentsFilterComponent },
    data: () => ({
        ui: 'admin',
        routeDetails: 'admin-participants-detail',
        routePayments: 'admin-participants-payments-vouchers'
    })
}
</script>