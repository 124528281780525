import { login } from '@/api/Login/Login'
import { logout } from '@/api/Logout/Logout'

const userInitialData = () => {
    if (localStorage.getItem('userData')) {
        return JSON.parse(localStorage.getItem('userData'))
    } else {
        return {}
    }
}

export default {
    namespaced:  true,
    state: {
        attributes: userInitialData(),
        loginLoading: false,
        initialInputValues: true,
        inputErrors: {},
        errorResponseMessage: '',
        sessionStatus: ''
    },

    mutations: {
        // attributes
        SET_USER_ATTRIBUTES (state, payload) {
            state.attributes = payload
            localStorage.setItem('userData', JSON.stringify(payload))
            
        },
        DELETE_USER_ATTRIBUTES (state) {
            state.attributes = {}
            if (localStorage.getItem('userData')) localStorage.removeItem('userData')
        },

        // pusher
        SET_USER_ROLES_PUSHER (state, payload) {
            state.attributes.roles = payload
            if (localStorage.getItem('userData')) localStorage.removeItem('userData')
            localStorage.setItem('userData', JSON.stringify(state.attributes))
            setTimeout(() => {
                location.reload();
            }, 2000);
        },

        // login-loading
        SET_LOADING_STATE (state, payload) {
            state.loginLoading = payload
        },

        // input-initial-values
        SET_INITIAL_INPUT_VALUES_STATE (state, payload) {
            state.initialInputValues = payload
        },

        // input-errors
        SET_INPUT_ERRORS (state, payload) {
            state.inputErrors = payload
        },
        DELETE_INPUT_ERRORS (state) {
            state.inputErrors = {}
        },
       
        // error-response-message
        SET_ERROR_RESPONSE_MESSAGE (state, payload) {
            state.errorResponseMessage = payload
        },
        DELETE_ERROR_RESPONSE_MESSAGE (state) {
            state.errorResponseMessage = ''
        },

        SET_SESSION_STATUS (state, payload) {
            state.sessionStatus = payload
        }
    },

    actions: {
        userLogin (context, payload) {
            context.commit('SET_LOADING_STATE', true)
            context.commit('SET_INITIAL_INPUT_VALUES_STATE', false)
            context.commit('DELETE_INPUT_ERRORS')
            context.commit('DELETE_ERROR_RESPONSE_MESSAGE')
            // context.commit('errors/DELETE_GLOBAL_ERROR_MESSAGE', '', { root: true })

            let userFormData = new FormData()
            userFormData.append('email', payload.email)
            userFormData.append('password', payload.password)

            login(context.rootState.api.baseUrl, userFormData)
                .then( ({ data }) => {
                    context.commit('SET_USER_ATTRIBUTES', data)
                    context.commit('SET_INITIAL_INPUT_VALUES_STATE', true)
                    context.commit('SET_SESSION_STATUS', 'login')
                    window.location.reload()
                })
                .catch( err => {
                    if (err.response) {
                        context.commit('SET_ERROR_RESPONSE_MESSAGE', err.response.data.message)
                        if (err.response.status == 401) {
                            context.commit('SET_INITIAL_INPUT_VALUES_STATE', true)
                        } else if (err.response.status == 422) {
                            context.commit('SET_INPUT_ERRORS', err.response.data.errors)
                        }
                    }
                    //  else {
                    //     context.commit('errors/SET_GLOBAL_ERROR_MESSAGE', `${err.name} : ${err.message}`, { root: true })
                    // }
                })
                .finally(() => {
                    context.commit('SET_LOADING_STATE', false)
                })
        },

        userLogout (context) {
            logout(context.rootState.api.baseUrl, context.rootState.user.attributes.token)
                .then( () => {
                    context.commit('DELETE_USER_ATTRIBUTES')
                    context.commit('SET_SESSION_STATUS', 'logout')
                    window.location.reload()
                })
                .catch( err => {
                    if (err.response) console.log('logout err.response: ', err.response)
                })
                .finally(() => {
                    // context.commit('SET_LOADING_STATE', false)
                    console.log('logout res end!!!');
                })
        },
    },

    getters: {
        getActiveRoles (state) {
            if (Object.keys(state.attributes).length != 0) {
                return state.attributes.roles
                    .filter(el => el.attributes.status == 'activado')
                    .map(el => ({ value: el.attributes.role_name, text: el.attributes.role_name.toUpperCase() }));
            } else {
                return []
            }
        },
        getUserRoleByRolname: (state) => (pRolname) => {
            return state.attributes.roles.find(rol => rol.attributes.role_name == pRolname)
        },
        hasRoleAndIsActive: (state) => (pRolname) => {
            if (Object.keys(state.attributes).length != 0) {
                let hasRoleActive = state.attributes.roles.find(rol => rol.attributes.role_name == pRolname && rol.attributes.status == 'activado')
                if (hasRoleActive) return true
                else return false
            } else {
                return false
            }
        },
        hasEmployeePermission: (state) => (pPermissionName) => {
            if (Object.keys(state.attributes).length != 0) {
                let hasPermissionName = state.attributes.employee_permissions.find(el => el.attributes.permission_name == pPermissionName && el.attributes.status == 'activado')
                if (hasPermissionName) return true
                else return false
            } else return false
        },
        getEmployeePermissionData: (state) => (pPermissionName) => {
            if (Object.keys(state.attributes).length != 0) {
                let employeePermission = state.attributes.employee_permissions.find(el => el.attributes.permission_name == pPermissionName && el.attributes.status == 'activado')
                if (employeePermission) return employeePermission
                else return false
            } else return {}
        },
        getUserRoleData: (state) => (pRoleName) => {
            if (Object.keys(state.attributes).length != 0) {
                let userRole = state.attributes.roles.find(el => el.attributes.role_name == pRoleName && el.attributes.status == 'activado')
                if (userRole) return userRole
                else return false
            } else return {}
        }

    }
}