import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function createPostponement (pFormData) {
    const URI = `${BASE_URL}/api/postponements`
    return axios.post(URI, pFormData, { headers })
}

function postponementChangeToAbandonment (pPostponementId) {
    const URI = `${BASE_URL}/api/postponement/${pPostponementId}/change-to-abandonment`
    return axios.get(URI, { headers })
}

function destroyPostponement (pPostponementId) {
    const URI = `${BASE_URL}/api/postponements/${pPostponementId}`
    return axios.delete(URI, { headers })
}

export {
    createPostponement,
    postponementChangeToAbandonment,
    destroyPostponement
}