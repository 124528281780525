<template>
    <!-- card -->
    <b-card class="shadow" no-body>
        <!-- header -->
        <b-card-header>
            <b-row class="align-items-center">
                <b-col>
                    <h3 class="mb-0 text-primary text-button">
                        PROGRAMAS
                    </h3>
                </b-col>
            </b-row>
        </b-card-header>

        <!-- laodingTableData -->
        <div style="height:200px;" v-show="loadingTableData">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
        </div>

        <!-- table -->
        <div class="table-responsive" v-show="!loadingTableData">
            <base-table class="table align-items-center table-flush"
                thead-classes="thead-light"
                tbody-classes="list"
                :data="tableData"
            >
                <template slot="columns">
                    <th>#</th>
                    <th>programa</th>
                    <th>estado</th>
                </template>

                <template slot-scope="{row, index}">
                    <td>
                        <!-- numeración descendente -->
                        {{ index + 1 }}
                    </td>

                    <td>
                        {{ row.attributes.name}}
                    </td>

                    <td :class="row.attributes.status === 'activado' ? 'text-green' : 'text-red'">
                        {{ row.attributes.status}}
                    </td>
                </template>
            </base-table>
        </div>

        <!-- pagination -->
        <div class="card-footer" v-show="!loadingTableData">
            <base-pagination
                align="center"
                :pageCount="tableMeta.last_page" 
                :perPage="tableMeta.per_page"
                :value="tableMeta.current_page"
                @input="paginationCLiked"
            />
        </div>
    </b-card>
</template>

<script>
import { getPrograms } from '@/api/Program/Program'
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'ProgramsList',
    //components: { PulseLoader },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false
    }),
    created() {
        this.getProgramsData()
    },
    methods: {
        getProgramsData (pPage = 1) {
            this.loadingTableData = true

            getPrograms(pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch( err => {
                    console.log('getPrograms Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getPrograms request end');
                })
        },

        paginationCLiked (e) {
            this.getProgramsData(e)
        }
    }
}
</script>