<template>
    <b-modal
        id="modal-update-voucher-image"
        centered
        hide-footer
        size="md"
        no-close-on-backdrop
        no-close-on-esc
        hide-backdrop
        header-class="pb-0"
    >
        <!-- modal header -->
        <template #modal-header>
            <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                <b-row align-h="between" align-v="center">
                    <b-col cols="auto"><h3 class="text-primary mb-0">ACTUALIZAR IMAGEN</h3></b-col>
                    <b-col cols="auto">
                        <b-button size="sm" variant="magenta" @click="modalClose">
                            <b-icon icon="x" scale="1.5"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>

        <!-- modal content -->
        <template #default>
            <b-container>
                <b-row>
                    <b-col cols="12" class="mb-4">
                        <b-list-group>
                            <b-list-group-item>
                                <b-icon icon="bookmark-fill" class="mr-1"></b-icon>
                                {{ voucher.attributes.voucher_concept }}
                            </b-list-group-item>

                            <b-list-group-item v-show="voucher.attributes.type == 'pago normal'">
                                <b-iconstack class="mr-1">
                                    <b-icon stacked icon="square"></b-icon>
                                    <b-icon stacked icon="hash"></b-icon>
                                </b-iconstack>
                                {{ voucher.attributes.code }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>

                    <!-- form -->
                    <b-col cols="12">
                        <b-form @submit.prevent="invalidateVoucher">
                            <b-form-row>
                                <b-col>
                                    <b-form-group
                                        label="Imagen:" 
                                    >
                                        <pulse-loader
                                            :loading="loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-file
                                            v-model="image"
                                            :state="showInputStatus('image')"
                                            v-show="!loading"
                                            style="overflow:hidden;"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('image')"
                                            :key="`${i}-iv-image`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <b-row align-v="center" align-h="center">
                                <b-col cols="auto">
                                    <b-button variant="primary" type="submit">
                                        ACTUALIZAR
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import { updateVoucherImage } from '@/api/VoucherImage/VoucherImage'
import swal from 'sweetalert'

export default {
    name: 'UpdateVoucherImageComponent',
    props: {
        show: {
            type: Number,
            required: true
        },
        voucher: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        image: null,
        loading: false,
        inputErrors: {},
        initialInputValues: true

    }),

    watch: {
        show() {
            this.modalClick()
        }
    },

    methods: {
        modalClick() {
            this.$bvModal.show('modal-update-voucher-image')
        },
        modalClose() {
            this.$bvModal.hide('modal-update-voucher-image')
            this.clearInputsAndStates()
        },

        invalidateVoucher() {
            this.loading = true
            this.initialInputValues = false
            this.inputErrors = {}
            let Form = new FormData()
            Form.append('.method', 'put')
            Form.append('type', this.voucher.type)
            Form.append('image', this.image)
            updateVoucherImage(Form, parseInt(this.voucher.id))
                .then(() => {
                    swal('Actualización correcta!', 'Imagen actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.modalClose()
                                    this.$emit('update-done')
                                    break;

                                default:
                                    console.log('updatevoucher--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                })
                .finally(() => {
                    this.loading = false
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.initialInputValues = true
            this.inputErrors = {}
            this.image = null
        }
    }
}
</script>

<style>

</style>