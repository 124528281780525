import { get } from 'axios'
import store from '@/store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { 
    'Cache-Control': 'no-store, no-cache',
    Authorization: `Bearer ${store.state.user.attributes.token}`
}

function exportVouchers (pIdSeason, pParams) {
    const URI = `${BASE_URL}/api/season/${pIdSeason}/programSeasonParticipant/export/vouchers`
    const responseType = 'blob'
    return get(URI, { headers, params: pParams, responseType })
}

export { exportVouchers }