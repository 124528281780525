import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//get
function getEmployee (pEmployeeId) {
    const URI = `${BASE_URL}/api/employees/${pEmployeeId}`
    return get(URI, { headers })
}
function getEmployees (pPage) {
    const URI = `${BASE_URL}/api/employees?page=${pPage}`
    return get(URI, { headers })
}

function storeEmployee (pFormData) {
    const URI = `${BASE_URL}/api/employees`
    return post(URI, pFormData, { headers })
}

export {
    getEmployee,
    getEmployees,
    storeEmployee
}