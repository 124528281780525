import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// delete
function destroyTalkScheduleParticipant (pIdTalkScheduleParticipant) {
    const URI = `${BASE_URL}/api/talkScheduleParticipants/${pIdTalkScheduleParticipant}`
    return axios.delete(URI, { headers })
}

export { 
    destroyTalkScheduleParticipant
 }