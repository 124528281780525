<template>
    <participant-documents-export-component></participant-documents-export-component>
</template>

<script>
import ParticipantDocumentsExportComponent from '@/components/Admin-Employee/Export/ParticipantDocuments/ParticipantDocumentsExportComponent.vue'

export default {
    name: 'PartticipantDocuments',
    components: { ParticipantDocumentsExportComponent },
    data: () => ({})
}
</script>