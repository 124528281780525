<template>
    <li class="nav-item">
        <router-link
            :to="link.path"
            @click.native="linkClick"
            class="nav-link"
            :target="link.target"
            active-class="sidebar-active"
            exact-active-class="sidebar-active"
            exact
            >
            <template>
                <!-- <i :class="link.icon"></i> -->
                <b-icon :icon="link.icon" class="mr-5" :variant="link.color" scale="0.8"></b-icon>
                <span class="nav-link-text">{{ link.name }}</span>
            </template>
        </router-link>
    </li>
</template>
<script>
  export default {
    name: 'sidebar-item-exact',
    props: {
      link: {
        type: Object,
        default: () => {
          return {
            name: '',
            path: '',
            icon: '',
            color: '',
            children: []
          };
        },
        description:
          'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
      }
    },
    inject: {
      autoClose: {
        default: true
      }
    },
    data() {
      return {
        children: [],
        collapsed: true
      };
    },
    methods: {
      linkClick() {
        if (
          this.autoClose &&
          this.$sidebar &&
          this.$sidebar.showSidebar === true
        ) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>

<style scoped>
.sidebar-active {
    background-color:rgba(94, 114, 228, .1);
    border-radius:10px;
    transition: 180ms all ease-in;
}
@media (max-width: 767px) {
    .sidebar-active {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
@media (min-width: 768px) {
    .sidebar-active {
        margin: 0px 1.5rem;
    }
}
.nav-link.sidebar-active > span {
    font-weight: 500;
    color: #5e72e4;
}
</style>