<template>
    <search-voucher-component
        :routeToPostulantsDetails="'admin-postulants-payments-vouchers'"
        :routeToParticipantsDetails="'admin-participants-payments-vouchers'"
        ></search-voucher-component>
</template>

<script>
import SearchVoucherComponent from '@/components/Admin-Employee/Postulant-Participant/Search/SearchVoucherComponent.vue'
export default {
    name: 'SearchVoucher',
    components: { SearchVoucherComponent },
    data: () => ({})
}
</script>