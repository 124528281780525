<template>
    <participant-postulation-information-export-component></participant-postulation-information-export-component>
</template>

<script>
import ParticipantPostulationInformationExportComponent from '@/components/Admin-Employee/Export/Information/Postulation/ParticipantPostulationInformationExportComponent.vue'

export default {
    name: 'Home',
    components: { ParticipantPostulationInformationExportComponent },
    data: () => ({})
}
</script>
