import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getForms () {
    const URI = `${BASE_URL}/api/forms`
    return axios.get(URI, { headers })
}

function storeForm (pFormData) {
    const URI = `${BASE_URL}/api/forms`
    return axios.post(URI, pFormData, { headers })
}

function updateForm (pIdForm, pFormData) {
    const URI = `${BASE_URL}/api/forms/${pIdForm}`
    return axios.post(URI, pFormData, { headers })
}

function destroyForm (pIdForm) {
    const URI = `${BASE_URL}/api/forms/${pIdForm}`
    return axios.delete(URI, { headers })
}

export {
    getForms,
    storeForm,
    updateForm,
    destroyForm
}