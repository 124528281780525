<template>
    <div>
        <b-button variant="naranja" size="sm" @click="modalClick"> <b-icon icon="pencil"  scale="1.3"></b-icon></b-button>

        <b-modal
            id="modal-chenge-credentials"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">ACTIVAR / DESACTIVAR CREDENCIALES</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group>
                                <pulse-loader
                                    :loading="loading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-checkbox
                                    v-model="checked" switch 
                                    v-show="!loading"
                                    @change="changeStatus"
                                    >Credenciales activadas
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { block } from '@/api/AccessCredentials/AccessCredentials'
import { activate } from '@/api/AccessCredentials/AccessCredentials'
import swal from 'sweetalert'

export default {
    name: 'ChangeCredentialsStateComponent',
    props: {
        currentStatus: {
            type: String,
            required: true
        },
        programSeasonPostulantId: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        checked: '',
        loading: false
    }),

    methods: {
        modalClick() {
        this.checked = this.currentStatus == 'activados' ? true : false

            this.$bvModal.show('modal-chenge-credentials')
        },
        modalClose() {
            this.$bvModal.hide('modal-chenge-credentials')
        },

        changeStatus(e) {
            if (e) this.activarCredenciales();
            else this.desactivarCredenciales();
        },

        activarCredenciales() {
            this.loading = true
            activate(this.programSeasonPostulantId)
                .then(() => {
                    swal('Crendenciales activadas')
                        .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.modalClose()
                                        this.$emit('updated')
                                        break;
                                    default:
                                        console.log('credentia break');
                                        break;
                                }
                        })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        desactivarCredenciales() {
            this.loading = true
            block(this.programSeasonPostulantId)
                .then(() => {
                    swal('Crendenciales desactivadas')
                        .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.modalClose()
                                        this.$emit('updated')
                                        break;
                                    default:
                                        console.log('credentia break');
                                        break;
                                }
                        })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style scoped>
.apk-details-tag {
    text-transform: uppercase;
    color: #b5b4b4;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 12px;
}
</style>