<template>
    <b-card class="shadow">
        <b-card-header class="">
            <h3 class="mb-0 text-button text-primary">
                Roles del Empleado
            </h3>
        </b-card-header>

        <!-- get employee-permission loading -->
        <div style="height:300px;" v-show="getEmployeePermissionsLoading">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)" />
        </div>

        <b-row class="mt-5">
            <b-col
                class="mt-4 mt-lg-0"
                cols="12"
                lg="6"
                v-for="(employeePermission, i) in employeePermissions" :key="`employeePermission-${i}`"
            >
                <b-card border-variant="lighter" no-body>
                    <b-card-header >
                        <b-row align-h="between">
                            <b-col cols="auto" class="text-uppercase">
                                {{ employeePermission.attributes.permission_name }}
                            </b-col>

                            <b-col cols="auto" class="text-uppercase">
                                <pulse-loader
                                    :loading="employeePermission.updateLoading || false"
                                    :color="'#5e72e4'"
                                    :margin="'2px'"
                                    :size="10"
                                />

                                <span
                                    :class="employeePermission.attributes.status === 'activado' ? 'text-green' : 'text-red'"
                                    v-show="!employeePermission.updateLoading || false"
                                >
                                    {{ employeePermission.attributes.status }}
                                </span>
                            </b-col>

                            <b-col cols="auto" class="text-uppercase">
                                <b-form-checkbox
                                    switch
                                    size="lg"
                                    :checked="employeePermission.attributes.status === 'activado' ? true : false"
                                    @change="(e) => updatePermissionStatus(employeePermission, e)"
                                    :disabled="employeePermission.updateLoading || false"
                                ></b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card-header>

                    <manage-s-a-permissions
                        v-if="employeePermission.attributes.permission_name == 'asesor-ventas'"
                        :salesAdvisorId="employeePermission.attributes.typeAdvisor_id"
                    />

                    <manage-c-a-permissions
                        v-else
                        :culturalAdvisorId="employeePermission.attributes.typeAdvisor_id"
                    />
                    
                </b-card>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { getEmployeeEmployeePermissions, updateEmployeeEmployeePermissionStatus } from '@/api/Employee/EmployeeEmployeePermission'
import ManageSAPermissions from '@/layout/Admin/ManageSAPermissions.vue'
import ManageCAPermissions from '@/layout/Admin/ManageCAPermissions.vue'

export default {
    name: 'ManagePermissions',
    props: {
        employeeId: {
            type: Number
        }
    },
    components: { 
        ManageSAPermissions,
        ManageCAPermissions
    },
    data: () => ({
        employeePermissions: [],
        //
        getEmployeePermissionsLoading: false
    }),
    created () {
        this.getPermissions()
    },
    methods: {
        getPermissions () {
            this.getEmployeePermissionsLoading = true

            getEmployeeEmployeePermissions(this.employeeId)
                .then(({data}) => {
                    console.log('user employeePermissions' ,data);
                    this.employeePermissions = data.data
                })
                .catch(err => {
                    console.log('getPermissions err', err)
                    console.log('getPermissions err.response', err.response)
                })
                .finally(() => {
                    this.getEmployeePermissionsLoading = false
                    console.log('request getPermissions end')
                })
        },

        updatePermissionStatus (pEmployeePermission, event) {
            this.$set(pEmployeePermission, "updateLoading", true);

            let UpdateFormData = new FormData()
            UpdateFormData.append('.method', 'put')

            if (event) UpdateFormData.append('process', 'activar')
            else UpdateFormData.append('process', 'desactivar')

            updateEmployeeEmployeePermissionStatus(
                pEmployeePermission.attributes.employee_id,
                pEmployeePermission.attributes.id,
                UpdateFormData)
                .then(({ data }) => {
                    console.log('updateEmployeePermissionStatus: ', data);
                    pEmployeePermission.attributes.status = data.data.attributes.status
                })
                .catch(err => {
                    console.log('updateEmployeePermissionStatus error: ', err);
                })
                .finally(() => {
                    pEmployeePermission.updateLoading = false
                })
        }
    }
}
</script>