<template>
    <b-row>
        <b-col cols="12">
            <div class="divider"></div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'DividerComponent'
}
</script>

<style scoped>
.divider {
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.35);
    margin-bottom: 25px;
}
</style>