<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header>
                    <h3 class="mb-3">
                        Detalles postulante ({{ this.$route.params.id}})  ( usuario:{{ postulantData.attributes.user_id }}, postulante {{ postulantData.attributes.id}} )
                    </h3>
                </b-card-header>

                <b-card-text>
                    <ul>
                        <li> nombre: {{ this.postulantData.attributes.name }}</li>
                        <li> apellido: {{ this.postulantData.attributes.last_name }}</li>
                        <li> tipo doc: {{ this.postulantData.attributes.document_type }}</li>
                        <li> documento: {{ this.postulantData.attributes.document_number }}</li>
                        <li> celular: {{ this.postulantData.attributes.phone_number }}</li>
                        <li> correo: {{ this.postulantData.attributes.email }}</li>
                        <li> descripción: {{ this.postulantData.attributes.description }}</li>
                        <li> estado de usuario: {{ this.postulantData.attributes.user_status }}</li>
                        <li> estado de empleado: {{ this.postulantData.attributes.participant_status }}</li>
                    </ul>
                </b-card-text>
            </b-card>
        </b-col>

        <b-col cols="12" lg="7" class="mt-4 mt-lg-0">
            <manage-roles 
                v-if="showRoles"
                :userId="postulantData.attributes.user_id"
                role="postulante"
            />
        </b-col>
    </b-row>
</template>

<script>
import { getPostulant } from '@/api/Postulant/Postulant';
import ManageRoles from '@/layout/Admin/ManageRoles.vue'
import swal from 'sweetalert'

export default {
    name: 'EmployeesDetails',
    components: { ManageRoles },
    data: () => ({
        postulantData: {
            attributes: {
                //participant
                id: '',
                user_id: '',
                description: '',
                postulant_status: '',
                //user
                name: '',
                last_name: '',
                document_type: '',
                document_number: '',
                phone_number: '',
                email: '',
                user_status: '',
            }
        },
        //
        loadingPostulantData: false,
        showRoles: false
    }),
    created () {
        this.getPostulantData()
    },
    methods: {
        getPostulantData () {
            this.loadingPostulantData = true

            getPostulant(this.$route.params.id) //IdPostulant
                .then(({data}) => {
                    console.log('postulant data', data);
                    this.postulantData = data.data
                    this.showRoles = true
                })
                .catch(err => {
                    console.log('err', err)
                    console.log('err.response', err.response)
                    if (err.response.status === 404) {
                        swal('404', 'Recurso no encontrado', 'warning')
                            .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.$router.push({ name: 'admin-postulants-list' })
                                        break;

                                    default:
                                        console.log('adminDetails-getPostulant-swal-default-break');
                                        break;
                                }
                            })
                    }
                })
                .finally(() => {
                    this.loadingPostulantData = false
                    console.log('request getPostulant end')
                })
        },
    },

}
</script>