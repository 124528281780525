<template>
    <!-- create employee with existing user -->
    <div>
        <!-- search user -->
        <b-form @submit.prevent="searchUserWithoutRoleEmployee">
            <b-form-row>
                <b-col cols="10">
                    <b-form-group>
                        <pulse-loader
                            :loading="loadingTableData"
                            :color="'#5e72e4'"
                            :margin="'5px'"
                            :size="10"
                            class="pt-2 pb-1 pl-2 text-center"
                            style="border-radius:5px; border:1px solid #5e72e4"
                        />
                        
                        <b-form-input
                            type="text"
                            placeholder="Búsqueda por nombres o dni"
                            v-model="inputToSearch"
                            :state="showInputStatus('input_to_search')"
                            v-show="!loadingTableData"
                        />

                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('input_to_search')" :key="`${i}-serchUsers`">
                            {{ inputError }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="2">
                    <b-form-group>
                        <b-button
                            class="w-100 py-auto px-0"
                            variant="primary"
                            type="submit"
                        ><b-icon icon="search" class="m-0 p-0"/></b-button>
                    </b-form-group>
                </b-col>
            </b-form-row>
        </b-form>

        <!-- laodingTableData -->
        <div style="height:300px;" v-show="loadingTableData">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
        </div>

        <!-- card users table -->
        <b-card
            no-body
            border-variant="lighter"
            style="overflow:hidden"
            v-show="!loadingTableData && !searchFailed"
        >
            <!-- users table-->
            <div class="table-responsive mb-0">
                <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                >
                    <template slot="columns">
                        <th>Nombres</th>
                        <th>documento</th>
                        <th>correo</th>
                        <th></th>
                    </template>

                    <template slot-scope="{row}">
                        <td class="text-uppercase">
                            <!-- {{ row.attributes.last_name }} {{ row.attributes.name }} -->
                            <span class="text-uppercase">{{ row.attributes.last_name}} </span>
                            <span class="text-capitalize">{{ row.attributes.name}}</span>
                        </td>

                        <td>
                            {{ row.attributes.document_number}}
                        </td>

                        <td>
                            {{ row.attributes.email}}
                        </td>

                        <td class="text-center">
                            <b-btn 
                                variant="outline-primary"
                                size="sm"
                                @click="$bvModal.show(`userRole-modal-${row.attributes.document_number}`)"
                            >
                                <b-icon icon="grid-fill" class="m-0 p-0"/>
                            </b-btn>
                        </td>
                    </template>
                </base-table>
            </div>
        </b-card>

        <!-- pagination -->
        <div class="card-footer border-0" v-show="!loadingTableData && !searchFailed">
            <base-pagination
                class="mb-0"
                align="center"
                :pageCount="tableMeta.last_page" 
                :perPage="tableMeta.per_page"
                :value="tableMeta.current_page"
                @input="paginationCLiked"
            />
        </div>

        <!-- user-roles modals -->
        <b-modal
            v-for="(item, i) in tableData" :key="`${i}-roleuser`"
            :id="`userRole-modal-${item.attributes.document_number}`"
            centered
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-primary">
                                CREAR EMPLEADO
                            </h3>
                        </b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="danger" @click="() => {
                                    clearInputs()
                                    close()
                                }">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- user data -->
                        <b-col cols="12" class="d-flex justify-content-center">
                            <div class="modal-user-data"
                            >
                                <b-icon
                                    icon="person-square"
                                    variant="primary"
                                    class="mr-3 modal-user-data-icon"
                                />
                                <span>
                                    {{ item.attributes.last_name }} {{ item.attributes.name }}
                                </span>
                                <div class="modal-user-data-id">
                                    ID-USER: {{ item.attributes.id }}
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container fluid class="pt-4">
                    <b-form-row>
                        <b-col cols="12" sm="6">
                            <b-card border-variant="light" >
                                <b-card-header class="px-0 py-0 pb-3">
                                    <pulse-loader
                                        :loading="item.createEmployeeLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-checkbox
                                        v-model="salesAdvisorChecked"
                                        switch
                                        size="sm"
                                        v-show="!item.createEmployeeLoading || false"
                                        >
                                        Asesor de ventas
                                    </b-form-checkbox>

                                    <b-form-invalid-feedback 
                                        v-for="(inputError, i) in showCheckBoxInputErrors('employee_permissions')"
                                        :key="`${i}-createEmploye-permissions`"
                                        :state="showCheckBoxInputStatus('employee_permissions')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-card-header>

                                <b-form-checkbox-group
                                    class="mt-3 pl-4 pl-sm-3"
                                    v-model="salesAdvisorPermissions"
                                    :options="advisorPermissions"
                                    stacked
                                    plain
                                    :state="showCheckBoxInputStatus('employee_permissions')"
                                    v-show="!item.createEmployeeLoading || false"
                                />
                            </b-card>
                        </b-col>

                        <b-col cols="12" sm="6" class="mt-3 mt-sm-0">
                            <b-card border-variant="light">
                                <b-card-header class="px-0 py-0 pb-3">
                                    <pulse-loader
                                        :loading="item.createEmployeeLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-checkbox v-model="culturalAdvisorChecked" switch size="sm" v-show="!item.createEmployeeLoading || false">
                                        Asesor cultural
                                    </b-form-checkbox>

                                    <b-form-invalid-feedback 
                                        v-for="(inputError, i) in showCheckBoxInputErrors('employee_permissions')"
                                        :key="`${i}-createEmploye-permissions`"
                                        :state="showCheckBoxInputStatus('employee_permissions')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-card-header>

                                <b-form-checkbox-group
                                    class="mt-3 pl-4 pl-sm-3"
                                    v-model="culturalAdvisorPermissions"
                                    :options="advisorPermissions"
                                    stacked
                                    plain
                                    :state="showCheckBoxInputStatus('employee_permissions')"
                                    v-show="!item.createEmployeeLoading || false"
                                />
                            </b-card>
                        </b-col>
                    </b-form-row>

                    <b-row
                        align-h="center"
                        class="mt-4 mb-1"
                    >
                        <b-col cols="auto">
                            <b-button
                                variant="primary"
                                @click="createEmployee(item)"
                                >
                                CREAR EMPLEADO
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { storeUserEmployee } from '@/api/User/UserEmployee'
import { usersSearch } from '@/api/User/UserSearch'
import swal from 'sweetalert'
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'EmployeeExistingUser',
    //components: { PulseLoader },
    data: () => ({
        //
        inputToSearch: '',
        inputToSearchErrors: {},
        searchFailed: true,
        //
        tableData: [],
        tableMeta: {},
        //
        initialInputValues: true,
        initialCheckBoxInputValues: true,
        loadingTableData: false,
        //
        inputErrors: {},
        //
        //
        advisorPermissions: [
            { value: 1, text: 'Work and travel'},
            { value: 2, text: 'Internship'},
            { value: 3, text: 'Trainee'},
            { value: 4, text: 'Aupair'},
            { value: 5, text: 'Teach'},
        ],
        salesAdvisorChecked: false,
        salesAdvisorPermissions: [],
        culturalAdvisorChecked: false,
        culturalAdvisorPermissions: [],
    }),

    watch: {
        salesAdvisorChecked() {
            if (this.salesAdvisorChecked) this.salesAdvisorPermissions = this.advisorPermissions.map(el => el.value)
            else this.salesAdvisorPermissions = []
        },
        culturalAdvisorChecked() {
            if (this.culturalAdvisorChecked) this.culturalAdvisorPermissions = this.advisorPermissions.map(el => el.value)
            else this.culturalAdvisorPermissions = []
        }
    },

    methods: {
        createEmployee (pUser) {
            this.inputErrors = {}
            this.initialCheckBoxInputValues = false

            this.$set(pUser, "createEmployeeLoading", true);

            let EmployeeFormData = new FormData()
            EmployeeFormData.append('description', `empleado ${pUser.attributes.name}`)

             // permissions
            if (this.salesAdvisorChecked || this.culturalAdvisorChecked) EmployeeFormData.append('employee_permissions', 'si')
            else EmployeeFormData.append('employee_permissions', 'no')

             // asesor-ventas
            if (this.salesAdvisorChecked) EmployeeFormData.append('create_sales_advisor_permission', 'si')
            else EmployeeFormData.append('create_sales_advisor_permission', 'no')

            // asesor-cultural
            if (this.culturalAdvisorChecked) EmployeeFormData.append('create_cultural_advisor_permission', 'si')
            else EmployeeFormData.append('create_cultural_advisor_permission', 'no')


            // sales-advisor-permissions
            if (this.salesAdvisorPermissions.includes(1)) EmployeeFormData.append('s_a_create_wat', 'si')
            else EmployeeFormData.append('s_a_create_wat', 'no')

            if (this.salesAdvisorPermissions.includes(2)) EmployeeFormData.append('s_a_create_internship', 'si')
            else EmployeeFormData.append('s_a_create_internship', 'no')

            if (this.salesAdvisorPermissions.includes(3)) EmployeeFormData.append('s_a_create_trainee', 'si')
            else EmployeeFormData.append('s_a_create_trainee', 'no')

            if (this.salesAdvisorPermissions.includes(4)) EmployeeFormData.append('s_a_create_aupair', 'si')
            else EmployeeFormData.append('s_a_create_aupair', 'no')
            
            if (this.salesAdvisorPermissions.includes(5)) EmployeeFormData.append('s_a_create_teach', 'si')
            else EmployeeFormData.append('s_a_create_teach', 'no')

            // cultural-advisor-permissions
            if (this.culturalAdvisorPermissions.includes(1)) EmployeeFormData.append('c_a_create_wat', 'si')
            else EmployeeFormData.append('c_a_create_wat', 'no')

            if (this.culturalAdvisorPermissions.includes(2)) EmployeeFormData.append('c_a_create_internship', 'si')
            else EmployeeFormData.append('c_a_create_internship', 'no')

            if (this.culturalAdvisorPermissions.includes(3)) EmployeeFormData.append('c_a_create_trainee', 'si')
            else EmployeeFormData.append('c_a_create_trainee', 'no')

            if (this.culturalAdvisorPermissions.includes(4)) EmployeeFormData.append('c_a_create_aupair', 'si')
            else EmployeeFormData.append('c_a_create_aupair', 'no')
            
            if (this.culturalAdvisorPermissions.includes(5)) EmployeeFormData.append('c_a_create_teach', 'si')
            else EmployeeFormData.append('c_a_create_teach', 'no')

            storeUserEmployee(EmployeeFormData, pUser.attributes.id)
                .then(() => {
                     swal('Creación correcta!', 'Nuevo empleado creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'admin-employees-list'})
                                    break;

                                default:
                                    console.log('employeeCreate-existingUser-storeUserEmployee-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    pUser.createEmployeeLoading = false
                    console.log('request create userEmployee end');
                })
        },

        searchUserWithoutRoleEmployee (pPage = 1) {
            this.initialInputValues = false
            this.loadingTableData = true
            this.inputToSearchErrors = {}

            let SearchFormData = new FormData()
            SearchFormData.append('input_to_search', this.inputToSearch)

            usersSearch(pPage, SearchFormData)
                .then(({data}) => {
                    this.tableData = data.data.filter(el => {
                        let hasEmployeeRole
                        for (const elem of el.attributes.user_roles) {
                             if (elem.attributes.role_name == 'empleado') {
                                hasEmployeeRole = false
                                break
                            } else {
                                hasEmployeeRole = true
                            }
                        }
                        return hasEmployeeRole
                    })

                    this.tableMeta = data.meta
                    this.searchFailed = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputToSearchErrors = err.response.data.errors
                        this.searchFailed = true
                    }
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('request userSearch end');
                })
        },

        paginationCLiked (e) {
            this.searchUserWithoutRoleEmployee(e)
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputToSearchErrors).includes(pInput)) return this.inputToSearchErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputToSearchErrors).includes(pInput)) return false
            else return true
        },

        showCheckBoxInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showCheckBoxInputStatus (pInput) {
            if (this.initialCheckBoxInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputs () {
            this.initialCheckBoxInputValues = true
            this.inputErrors = {}
            this.salesAdvisorChecked =  false
            this.salesAdvisorPermissions =  []
            this.culturalAdvisorChecked =  false
            this.culturalAdvisorPermissions =  []
        }
    },
}
</script>

<style scoped>
.modal-user-data-icon:hover ~ .modal-user-data-id {
    transform: scale(1);
}
.modal-user-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    border: 1px solid #5e72e4;
    border-radius: 8px;
    text-transform: uppercase;
    color: #5e72e4;
}
.modal-user-data-id {
    position: absolute;
    bottom: 80%;
    padding: 4px 8px;
    background: #000;
    border-radius: 5px;
    font-size: 10pt;
    color: white;
    opacity: .75;
    transition: .300s ease-in all;
    transform-origin: left bottom;
    transform: scale(0);
}
</style>