import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//get
function getAdmin (pAdminId) {
    const URI = `${BASE_URL}/api/admins/${pAdminId}`
    return get(URI)
}
function getAdmins (pPage) {
    const URI = `${BASE_URL}/api/admins?page=${pPage}`
    return get(URI)
}

function storeAdmin (pFormData) {
    const URI = `${BASE_URL}/api/admins`
    return post(URI, pFormData)
}

export {
    getAdmin,
    getAdmins,
    storeAdmin
}