<template>
    <b-row class="mb-4">
        <b-col cols="12" >
            <b-card bg-variant="transparent" no-body class="border-0">
                <b-card-header class="border-0 bg-transparent">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-white text-button">
                                TEMPORADA :
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="3">
                            <pulse-loader
                                :loading="seasonsLoading"
                                :color="'#fff'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #fff"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasons"
                                v-show="!seasonsLoading"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="seasonsLoading || loading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <b-row>
                    <b-col cols="12" lg="6" class="mb-5">
                        <b-card class="shadow">

                            <b-card-header>
                                <b-row>
                                    <!-- employeer : cultural advisor options -->
                                    <b-col cols="12" sm="6">
                                        <b-form-group class="mb-0 text-primary"  v-if="ui == 'admin'"
                                            label="Asesor Cultural" label-cols="auto" label-size="sm" 
                                        >
                                            <pulse-loader
                                                :loading="seasonsLoading || employeesOptionsLoading || loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                class="text-center"
                                                v-model="employeeSelected"
                                                :options="employeesOptions"
                                                v-show="!seasonsLoading && !employeesOptionsLoading && !loading"
                                                size="sm"
                                                :disabled="ui == 'employee'"
                                            >
                                                <template #first>
                                                    <b-form-select-option value="all">
                                                        -- Todos los leads --
                                                    </b-form-select-option>
                                                </template>>
                                            </b-form-select>
                                        </b-form-group>

                                        <span v-if="ui == 'employee'" class="text-primary">
                                            {{ getEmployeeName }}
                                        </span>
                                    </b-col>
                                </b-row>
                            </b-card-header>



                            <b-card-title>
                                Descargar los datos de pagos y vouchers registrados de los participantes y postulantes pertenecientes a un asesor cultural.
                            </b-card-title>

                            <b-card-sub-title class="mb-5">
                                Los datos permitidos para los asesores culturales son: ESTADO, CONCEPTO Y MONTO
                            </b-card-sub-title>


                            <!-- loading -->
                            <div style="height:60px; position:relative" v-show="seasonsLoading || employeesOptionsLoading || loading">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                            <div v-show="!seasonsLoading && !employeesOptionsLoading && !loading">
                                <b-col class="text-center">
                                    <b-button size="lg" variant="primary" @click="exportParticipantsVouchers">
                                        <b-icon icon="download" class="mr-2"></b-icon> DESCARGAR
                                    </b-button>
                                </b-col>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
import { getAllActiveCulturalAdvisors } from '@/api/CulturalAdvisor/CulturalAdvisor'
import { exportVouchers } from '@/api/Season/ProgramSeasonParticipant/Export/Vouchers'
import FileSaver from 'file-saver';

export default {
    name: "PaymentsVouchersExportComponent",

    props: {
        ui: {
            type: String,
            required: true
        }
    },

    data: () => ({
        loading: false,
        //
        seasons: [],
        seasonSelected: '',
        seasonsLoading: false,
        //
        employeesOptions: [],
        employeeSelected: 'all',
        employeesOptionsLoading: false,
    }),

    mounted() {
        this.getSeasons()
    },

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        },

        getEmployeeName() {
            let employee_name = ''
            for (const employee of this.employeesOptions) {
                if (employee.value == this.employeeSelected) {
                    employee_name = employee.text
                    break;
                }
            }
            return employee_name
        }
    },

    methods: {
        getSeasons() {
            this.seasonsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasons = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))

                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasons) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    // get cultural advisors with status active
                    this.getEmployeers()
                })
                .finally(() => {
                    this.seasonsLoading = false
                })
        },

        getEmployeers() {
            this.employeesOptionsLoading = true

            getAllActiveCulturalAdvisors()
                .then(({ data }) => {
                    this.employeesOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name}) )

                    if (this.ui == 'employee') {
                        this.employeeSelected = this.culturalAdvisorPermissionData.attributes.typeAdvisor_id
                    }
                })
                .finally(() => {
                    this.employeesOptionsLoading = false
                })
        },

        // export
        exportParticipantsVouchers () {
            this.loading = true

            let params = {
                culturalAdvisor_id : this.employeeSelected,
                ui_type : this.ui
            }

            exportVouchers(this.seasonSelected, params)
                .then(res => {
                    FileSaver.saveAs(res.data, 'Leads_vouchers.xlsx');
                })
                .finally(() => {
                    this.loading = false
                })
        },

        reloadData () {
            this.getSeasons()
        },
    }
}
</script>