<template>
    <div>
        <!-- has promotion -->
        <div v-if="row.has_english_promotion">
            <!-- payment_count:one -->
            <div v-if="ep.payment_count == 'one'">
                <!-- payment_own: current postulant -->
                <div v-if="ep.payment_owner_id == row.programSeasonPostulantID">
                    <div class="px-3">
                        <payment-status-component :payment="ep.payment_owner_payment"/>
                    </div>

                    <div
                        class="bg-primary-transparent border border-primary px-3 py-1 mt-2" style="border-radius: 5px; cursor: pointer"
                        @click="clickEnglishPromotionCard(`ep-card-${row.programSeasonPostulantID}-${row.english_promotion.attributes.id}`)"
                    >
                        <b-icon icon="people-fill" scale="1.3" variant="primary" class="mr-4"></b-icon>
                        <span class="text-caption text-muted">{{ ep.payment_no_owner_name }}</span>
                    </div>
                </div>

                <!-- payment_own: partner postulant -->
                <div v-if="ep.payment_owner_id != row.programSeasonPostulantID" class="bg-primary-transparent border border-primary px-3 pt-3 pb-1"  style="border-radius: 5px; cursor: pointer"
                    @click="clickEnglishPromotionCard(`ep-card-${row.programSeasonPostulantID}-${row.english_promotion.attributes.id}`)"
                >
                    <payment-status-component :payment="ep.payment_owner_payment"/>

                    <div class="mt-2">
                        <b-icon icon="people-fill" scale="1.3" variant="primary" class="mr-4"/>
                        <span class="text-caption text-muted">{{ ep.payment_owner_name }}</span>
                    </div>
                </div>
            </div>

            <!-- count:two -->
            <div v-if="ep.payment_count == 'two'">
                <!-- payment_own: both -->
                <!-- if current is partner_one  -->
                <div v-if="ep.partnerOne_id == row.programSeasonPostulantID">
                    <div class="px-3">
                        <payment-status-component :payment="ep.partnerOne_payment"/>
                    </div>

                    <div class="bg-primary-transparent border border-primary px-3 py-1 mt-2" style="border-radius: 5px; cursor: pointer"
                        @click="clickEnglishPromotionCard(`ep-card-${row.programSeasonPostulantID}-${row.english_promotion.attributes.id}`)"
                    >
                        <b-icon icon="people-fill" scale="1.3" variant="primary" class="mr-4"/>
                        <span class="text-caption text-muted">{{ ep.partnerTwo_name }}</span>
                    </div>
                </div>

                <!-- if current is partner_two  -->
                <div v-if="ep.partnerTwo_id == row.programSeasonPostulantID">
                    <div class="px-3">
                        <payment-status-component :payment="ep.partnerTwo_payment"/>
                    </div>

                    <div class="bg-primary-transparent border border-primary px-3 py-1 mt-2" style="border-radius: 5px; pointer:cursor; cursor: pointer"
                        @click="clickEnglishPromotionCard(`ep-card-${row.programSeasonPostulantID}-${row.english_promotion.attributes.id}`)"
                    >
                        <b-icon icon="people-fill" scale="1.3" variant="primary" class="mr-4"/>
                        <span class="text-caption text-muted">{{ ep.partnerOne_name }}</span>
                    </div>
                </div>
            </div>

            <!-- //ep-card -->
            <div class="mt-4 p-4 ep-card"
                :id="`ep-card-${row.programSeasonPostulantID}-${row.english_promotion.attributes.id}`"
            >
                <!-- close button -->
                <div class="text-right mb-4"><b-btn variant="magenta" size="sm"
                    @click="clickEnglishPromotionCard(`ep-card-${row.programSeasonPostulantID}-${row.english_promotion.attributes.id}`)"><b-icon icon="x" scale="1.3" /></b-btn>
                </div>

                <!-- data -->
                <div class="ep-card__items">
                    <div class="ep-card__item">
                        <span>PAGOS :</span> <span>{{ ep.payment_count == 'one' ? 'Uno' : 'Dos' }}</span> 
                    </div>

                    <div class="ep-card__item">
                        <span>RESP.:</span>
                        <span :class="ep.payment_own == 'partner_one' ? 'text-apk-light-green-3' : (ep.payment_own == 'partner_two' ? 'text-apk-dark-orange-3' : 'text-primary')">
                            {{ ep.payment_own == 'partner_one' ? 'Postulante 1' : (ep.payment_own == 'partner_two' ? 'Postulante 2' : 'Ambos') }}
                        </span> 
                    </div>
                </div>

                <!-- partners -->
                <!-- partner_one -->
                <div class="border border-light px-3 py-1 mt-2" style="border-radius: 5px">
                    <div v-if="ep.payment_own == 'partner_one' || ep.payment_own == 'both'">
                        <payment-status-component :payment="ep.partnerOne_payment" class="my-2"/>
                    </div>

                    <div>
                        <b-icon icon="people-fill" scale="1.3" :variant="ep.payment_count == 'two' ? 'primary' : 'apk-light-green-3'" class="mr-4" />
                        <span class="text-caption text-muted">{{ ep.partnerOne_name }}</span>
                    </div>
                </div>

                <!-- partner two -->
                <div class="border border-light px-3 py-1 mt-2" style="border-radius: 5px">
                    <div v-if="ep.payment_own == 'partner_two' || ep.payment_own == 'both'">
                        <payment-status-component :payment="ep.partnerTwo_payment" class="my-2"/>
                    </div>

                    <div>
                        <b-icon icon="people-fill" scale="1.3" :variant="ep.payment_count == 'two' ? 'primary' : 'apk-dark-orange-3'" class="mr-4"/>
                        <span class="text-caption text-muted">{{ ep.partnerTwo_name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- hasnt promotion -->
        <div v-if="!row.has_english_promotion" class="px-3 my-2">
            <b-iconstack font-scale="1.3" class="mr-3">
                <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                <b-icon
                    stacked icon="square-fill" scale="1.1"
                    :variant="row.postulantPayments[0].attributes.status == 'verde' ? 'green' : (row.postulantPayments[0].attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                ></b-icon>
            </b-iconstack>

            <span class="text-caption text-muted">
                {{ row.postulantPayments[0].attributes.amount_format }}
            </span>
        </div>
    </div>
</template>

<script>
import PaymentStatusComponent from '@/components/Admin-Employee/Postulant-Participant/List/PaymentStatusComponent.vue'


export default {
    name: 'PostulantPaymentCellComponent',
    components: { PaymentStatusComponent },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        ep: {},
    }),
    beforeMount() {
        if(this.row.has_english_promotion) this.ep = this.row.english_promotion.attributes
    },
    methods: {
        clickEnglishPromotionCard(pCard) {
            let el = document.querySelector(`#${pCard}`)
            if(el) el.classList.toggle('card-active')
        }
    }
}
</script>

<style scoped>
.ep-card {
    /* position: absolute; */
    /* z-index: 55; */
    display: none;
    box-shadow: 3px 4px 6px -2px rgba(0, 0, 0, 0.15),
    -2px -3px 6px -2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transform: scale(0);
    transform-origin: top center;
    transition: 300ms ease-in all;
}
.ep-card__item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.card-active {
    transform: scale(1);
    display: block;
}
</style>