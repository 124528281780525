<template>
    <b-row>
        <!-- job-offer-inputs -->
        <b-col cols="12" lg="6">
            <b-card class="shadow" no-body>
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-button">
                                <div v-show="sponsorJobOfferLoading">
                                    <pulse-loader :color="'#5e72e4'" :margin="'10px'" :size="10"></pulse-loader>
                                </div>

                                <div v-show="!sponsorJobOfferLoading" class="text-primary">
                                     campos de: <span class="text-naranja">
                                         {{ sponsorJobOffer.sponsor_name }} - {{ sponsorJobOffer.program_name }}
                                    </span>
                                </div>
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card 
                    no-body
                    v-show="!loadingTableData"
                    style="height:450px;"
                >
                    <div class="table-responsive" >
                        <table class="table tablesorter align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th></th>
                                    <th>Campo</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="list" id="job-offer-inputs">
                                <tr v-for="(item, i) in order" :key="i+'tableOne'" :data-id="item">
                                    <td>
                                        <b-button variant="transparent" size="sm"
                                            style="cursor:grab"
                                            class="handle"
                                        ><b-icon icon="chevron-bar-expand" variant="primary" scale="1.3"></b-icon>
                                        </b-button>
                                    </td>

                                    <td v-for="(data, j) in tableData" :key="j+'tableOneSub'" v-show="item == data.id">
                                        {{ data.attributes.input_name }}
                                    </td>

                                    <td v-for="(data, j) in tableData" :key="j+'tableOneSubtwo'" v-show="item == data.id">
                                        <pulse-loader
                                            :loading="data.loading || false"
                                            :color="'#5e72e4'"
                                            :margin="'2px'"
                                            :size="8"
                                            class="pt-2 pb-1"
                                        ></pulse-loader>

                                        <b-button
                                            v-show="!data.loading && true"
                                            size="sm"
                                            variant="magenta"
                                            @click="deleteJobOfferInput(data)"
                                        >
                                            <b-icon icon="trash" scale="1.1"></b-icon>
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-card>
            </b-card>
        </b-col>

        <!-- genreal-job--inputs -->
        <b-col cols="12" lg="6" class="mt-5 mt-lg-0">
            <b-card class="shadow" no-body>
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-primary text-button">
                                Campos GENERALES
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button
                                @click="mostrar = 'todos'"
                                :variant="mostrar == 'si' ? 'light' : 'cielo'"
                                size="sm"
                            >todos</b-button>

                            <b-button
                                @click="mostrar = 'si'"
                                :variant="mostrar == 'todos' ? 'light' : 'cielo'"
                                size="sm"
                            >faltantes</b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:250px;" v-show="genrealJobInputsLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card
                    no-body
                    v-if="!genrealJobInputsLoading"
                    style="height:450px;"
                >
                    <div class="table-responsive" >
                        <base-table class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="genrealJobInputsTableData"
                        >
                            <template slot="columns">
                                <th>#</th>
                                <th>Campo</th>
                                <th></th>
                            </template>

                            <template slot-scope="{row, index}" v-if="row.attributes.alredyHasInput != mostrar" >
                                <td>{{ index + 1 }}</td>

                                <td :class="row.attributes.alredyHasInput == 'si' ? 'text-green' : ''">
                                    {{ row.attributes.name }}
                                </td>

                                <td class="text-center">
                                    <b-button
                                        v-if="row.attributes.alredyHasInput == 'si'"
                                        size="sm"
                                        variant="green"
                                        disabled
                                    ><b-icon icon="check" scale="1.6" ></b-icon>
                                    </b-button>

                                    <span v-if="row.attributes.alredyHasInput == 'no'">
                                        <pulse-loader
                                            :loading="row.loading || false"
                                            :color="'#5e72e4'"
                                            :margin="'2px'"
                                            :size="8"
                                            class="pt-2 pb-1"
                                        ></pulse-loader>

                                        <b-button
                                            v-show="!row.loading && true"
                                            size="sm"
                                            variant="primary"
                                            @click="addJobOfferInput(row)"
                                        >
                                            <b-icon icon="plus" scale="1.6"></b-icon>
                                        </b-button>
                                    </span>
                                </td>
                            </template>
                        </base-table>
                    </div>
                </b-card>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// sponsotJobOffer.jobOfferInput
import { getSponsorJobOfferJobOfferInputs } from '@/api/SponsorJobOffer/JobOfferInput';
import { getSponsorJobOffer } from '@/api/SponsorJobOffer/SponsorJobOffer';
import { getGeneralJobInputs } from '@/api/GeneralJobInput/GeneralJobInput';
//
import { updateSponsorJobOfferInputsOrder } from '@/api/SponsorJobOffer/UpdateInputsOrder';
import { storeJobOfferInput, destroyJobOfferInput } from '@/api/JobOfferInput/JobOfferInput';
//
import Sortable from 'sortablejs';

export default {
    name: "JobOffersInputsComponents",

    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        sponsorJobOffer: {},
        sponsorJobOfferLoading: false,
        //
        genrealJobInputsTableData: [],
        genrealJobInputsLoading: false,
        //
        mostrar: 'si',
        //
        order: [],
        mySortable: '',
    }),

    created() {
        this.getData()
    },

    mounted () {
        this.setSortable()
    },

    methods: {
        getData () {
            let IdSponsorJobOffer = this.$route.params.IdSponsorJobOffer

            // job-inputs
            this.loadingTableData = true
            this.genrealJobInputsLoading = true

            getSponsorJobOfferJobOfferInputs(IdSponsorJobOffer)
                .then(({ data }) => {
                    this.tableData = data.data

                    //general-job-inputs
                    getGeneralJobInputs()
                        .then(({ data }) => {
                            this.genrealJobInputsTableData = data.data.map(el => {
                                let hasInputRegistered = 'no'
                                for (const jobOfferInput of this.tableData) {
                                    if (el.attributes.name == jobOfferInput.attributes.input_name ) {
                                        hasInputRegistered = 'si'
                                        break;
                                    }
                                }
                                return {
                                    'type': el.type,
                                    'id': el.id,
                                    'attributes': {
                                        ...el.attributes,
                                        'alredyHasInput': hasInputRegistered
                                    }
                                }
                            } )
                        })
                        .finally(() => {
                            this.genrealJobInputsLoading = false
                            // console.log('get-general-job-inputs request end');
                         })
                })
                .finally(() => {
                    this.loadingTableData = false
                })

            // sponsor-job-offer
            this.sponsorJobOfferLoading = true
            getSponsorJobOffer(IdSponsorJobOffer)
                .then(({ data }) => {
                    this.sponsorJobOffer = data.data.attributes
                    this.order = this.sponsorJobOffer.input_order.split('|')
                    this.mySortable.sort(this.order, false)
                })
                .finally(() => {
                    this.sponsorJobOfferLoading = false
                    this.mySortable.sort(this.order, false)
                })
        },
        paginationCLiked (e) {
            this.getAdminsData(e)
        },
        reloadData () {
            this.getData()
        },

        // SORTABLE
        setSortable () {
            let el = document.getElementById('job-offer-inputs');

            this.mySortable = Sortable.create(el, {
                animation: 150,
                group: "job-Offer-Inputs",
                dataIdAttr: 'data-id',
                //
                handle: '.handle',
                //
                ghostClass: "sortable-ghost",
                chosenClass: "sortable-chosen",
                dragClass: "sortable-drag",
                //
                onEnd: () =>  {
                    console.log('el. end');
                },
                //
                store: {
                    set: sortable => {
                        let orderJoined = sortable.toArray().join('|')
                        let orderForm = new FormData()
                        orderForm.append('.method', 'put')
                        orderForm.append('input_order', orderJoined)
                        updateSponsorJobOfferInputsOrder (orderForm, this.$route.params.IdSponsorJobOffer)
                    },
                },
            });
        },
        // END SORTABLE

        addJobOfferInput (pGeneralOfferInput) {
            this.$set(pGeneralOfferInput, 'loading', true)

            let inputForm = new FormData()
            inputForm.append('sponsorJobOffer_id', this.$route.params.IdSponsorJobOffer)
            inputForm.append('generalJobInput_id', pGeneralOfferInput.id)

            storeJobOfferInput(inputForm)
                .then(() => {
                    this.getData()
                })
                .finally(() => {
                    pGeneralOfferInput.loading = false
                })
        },

        deleteJobOfferInput (pJobOfferInput) {
            this.$set(pJobOfferInput, 'loading', true)

            destroyJobOfferInput(pJobOfferInput.id)
                .then(() => {
                    this.getData()
                })
                .finally(() => {
                    pJobOfferInput.loading = false
                })
        },


    }
}
</script>

<style scoped>
.sortable-ghost {
    background-color: #dfe1ee;
    cursor: grabbing;
}
.sortable-chosen {
    cursor: grabbing;
}
.sortable-drag {
    opacity: 0;
}
</style>