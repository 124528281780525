import { get } from 'axios'
import store from '@/store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { 
    'Cache-Control': 'no-store, no-cache',
    Authorization: `Bearer ${store.state.user.attributes.token}`
}

function exportDocuments (pIdSeason) {
    const URI = `${BASE_URL}/api/season/${pIdSeason}/programSeasonParticipant/export/documents`
    const responseType = 'blob'
    return get(URI, { headers, responseType })
}

export { exportDocuments }