export default {
    namespaced:  true,
    state: {
        /** enviroment */
        // env: 'dev',
        env: 'prod',

        /** */
        // baseUrl: 'http://127.0.0.1:8000',
        baseUrl: 'https://intranet-api.aupairkids.com.pe',

        /** */
        baseUrlToSignature: 'https://intranet-api.aupairkids.com.pe',
    }
}