<template>
    <svg
        x="0px" y="0px"
        viewBox="0 0 98.14 31.9" style="overflow:visible;enable-background:new 0 0 98.14 31.9;" xml:space="preserve"
        :class="[!status ? 'ghost' : '']"
    >
        <defs>
        </defs>
        <g id="semaforo_2_">
            <path class="st0" d="M3.25,2.86v26.17H2.8c-1.54,0-2.8-1.26-2.8-2.81V5.66c0-1.54,1.26-2.8,2.8-2.8H3.25z"/>
            <path class="st0" d="M98.14,5.66v20.56c0,1.55-1.26,2.81-2.8,2.81h-0.45V2.86h0.45C96.88,2.86,98.14,4.12,98.14,5.66z"/>
            <path class="st1" d="M93.02,0H5.12C4.09,0,3.25,0.83,3.25,1.87v28.15c0,1.03,0.84,1.87,1.87,1.87h87.9c1.03,0,1.87-0.84,1.87-1.87
                V1.87C94.89,0.83,94.05,0,93.02,0z M32.85,29.03C32.82,29.57,32.37,30,31.82,30H5.75c-0.54,0-0.99-0.43-1.01-0.97
                C4.72,29.01,4.72,29,4.72,28.98V2.86c0.02-0.54,0.47-0.98,1.02-0.98h26.08c0.55,0,1,0.44,1.02,0.98V29.03z M62.97,29.03
                C62.94,29.57,62.5,30,61.95,30H35.88c-0.55,0-0.99-0.43-1.01-0.97c-0.01-0.02-0.01-0.03-0.01-0.05V2.86
                c0.02-0.54,0.46-0.98,1.02-0.98h26.07c0.56,0,1,0.44,1.02,0.98V29.03z M93.11,29.03C93.08,29.57,92.63,30,92.09,30H66.01
                c-0.54,0-0.99-0.43-1.01-0.97c-0.01-0.02-0.01-0.03-0.01-0.05V2.86c0.02-0.54,0.47-0.98,1.02-0.98h26.08c0.55,0,1,0.44,1.02,0.98
                V29.03z"/>
            <path class="st2" d="M31.82,1.88H5.75c-0.55,0-1,0.44-1.02,0.98v26.12c0,0.02,0,0.03,0.01,0.05C4.75,29.57,5.2,30,5.75,30h26.08
                c0.54,0,0.99-0.43,1.02-0.97V2.86C32.83,2.32,32.38,1.88,31.82,1.88z M6.68,28.74c-0.34,0-0.6-0.27-0.6-0.6
                c0-0.33,0.27-0.6,0.6-0.6c0.33,0,0.6,0.28,0.6,0.6C7.28,28.47,7,28.74,6.68,28.74z M6.68,4.37c-0.34,0-0.6-0.27-0.6-0.6
                c0-0.33,0.27-0.6,0.6-0.6c0.33,0,0.6,0.28,0.6,0.6C7.28,4.1,7,4.37,6.68,4.37z M30.94,28.74c-0.33,0-0.6-0.27-0.6-0.6
                c0-0.33,0.28-0.6,0.6-0.6c0.34,0,0.6,0.28,0.6,0.6C31.55,28.47,31.28,28.74,30.94,28.74z M30.94,4.37c-0.33,0-0.6-0.27-0.6-0.6
                c0-0.33,0.28-0.6,0.6-0.6c0.34,0,0.6,0.28,0.6,0.6C31.55,4.1,31.28,4.37,30.94,4.37z"/>
            <path class="st2" d="M61.95,1.88H35.88c-0.56,0-1,0.44-1.02,0.98v26.12c0,0.02,0,0.03,0.01,0.05c0.02,0.54,0.46,0.97,1.01,0.97
                h26.07c0.55,0,0.99-0.43,1.02-0.97V2.86C62.95,2.32,62.51,1.88,61.95,1.88z M36.78,28.74c-0.34,0-0.6-0.28-0.6-0.6
                c0-0.34,0.27-0.6,0.6-0.6c0.33,0,0.6,0.27,0.6,0.6C37.38,28.46,37.11,28.74,36.78,28.74z M36.78,4.37c-0.34,0-0.6-0.28-0.6-0.6
                c0-0.34,0.27-0.6,0.6-0.6c0.33,0,0.6,0.27,0.6,0.6C37.38,4.09,37.11,4.37,36.78,4.37z M61.05,28.74c-0.33,0-0.6-0.28-0.6-0.6
                c0-0.34,0.28-0.6,0.6-0.6c0.34,0,0.6,0.27,0.6,0.6C61.65,28.46,61.39,28.74,61.05,28.74z M61.05,4.37c-0.33,0-0.6-0.28-0.6-0.6
                c0-0.34,0.28-0.6,0.6-0.6c0.34,0,0.6,0.27,0.6,0.6C61.65,4.09,61.39,4.37,61.05,4.37z"/>
            <path class="st2" d="M92.09,1.88H66.01c-0.55,0-1,0.44-1.02,0.98v26.12c0,0.02,0,0.03,0.01,0.05c0.02,0.54,0.47,0.97,1.01,0.97
                h26.08c0.54,0,0.99-0.43,1.02-0.97V2.86C93.09,2.32,92.64,1.88,92.09,1.88z M66.91,28.74c-0.34,0-0.6-0.27-0.6-0.6
                c0-0.33,0.27-0.6,0.6-0.6c0.34,0,0.6,0.28,0.6,0.6C67.51,28.47,67.25,28.74,66.91,28.74z M66.91,4.37c-0.34,0-0.6-0.27-0.6-0.6
                c0-0.33,0.27-0.6,0.6-0.6c0.34,0,0.6,0.28,0.6,0.6C67.51,4.1,67.25,4.37,66.91,4.37z M91.19,28.74c-0.34,0-0.6-0.27-0.6-0.6
                c0-0.33,0.27-0.6,0.6-0.6c0.33,0,0.6,0.28,0.6,0.6C91.79,28.47,91.51,28.74,91.19,28.74z M91.19,4.37c-0.34,0-0.6-0.27-0.6-0.6
                c0-0.33,0.27-0.6,0.6-0.6c0.33,0,0.6,0.28,0.6,0.6C91.79,4.1,91.51,4.37,91.19,4.37z"/>
            <g>
                <path class="st3" d="M6.68,27.53c-0.34,0-0.6,0.28-0.6,0.6c0,0.34,0.27,0.6,0.6,0.6c0.33,0,0.6-0.27,0.6-0.6
                    C7.28,27.81,7,27.53,6.68,27.53z M6.68,28.39c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26c0.14,0,0.26,0.11,0.26,0.26
                    C6.93,28.28,6.82,28.39,6.68,28.39z"/>
                <path class="st3" d="M6.68,3.16c-0.34,0-0.6,0.28-0.6,0.6c0,0.34,0.27,0.6,0.6,0.6c0.33,0,0.6-0.27,0.6-0.6
                    C7.28,3.43,7,3.16,6.68,3.16z M6.68,4.02c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26c0.14,0,0.26,0.11,0.26,0.26
                    C6.93,3.91,6.82,4.02,6.68,4.02z"/>
                <circle class="st4" cx="6.68" cy="3.76" r="0.26"/>
                <path class="st3" d="M30.94,27.53c-0.33,0-0.6,0.28-0.6,0.6c0,0.34,0.28,0.6,0.6,0.6c0.34,0,0.6-0.27,0.6-0.6
                    C31.55,27.81,31.28,27.53,30.94,27.53z M30.94,28.39c-0.13,0-0.26-0.11-0.26-0.26c0-0.14,0.12-0.26,0.26-0.26
                    c0.14,0,0.26,0.11,0.26,0.26C31.2,28.28,31.09,28.39,30.94,28.39z"/>
                <path class="st3" d="M30.94,3.16c-0.33,0-0.6,0.28-0.6,0.6c0,0.34,0.28,0.6,0.6,0.6c0.34,0,0.6-0.27,0.6-0.6
                    C31.55,3.43,31.28,3.16,30.94,3.16z M30.94,4.02c-0.13,0-0.26-0.11-0.26-0.26c0-0.14,0.12-0.26,0.26-0.26
                    c0.14,0,0.26,0.11,0.26,0.26C31.2,3.91,31.09,4.02,30.94,4.02z"/>
                <circle class="st4" cx="6.68" cy="28.13" r="0.26"/>
                <circle class="st4" cx="30.94" cy="28.13" r="0.26"/>
                <circle class="st4" cx="30.94" cy="3.76" r="0.26"/>
            </g>
            <g>
                <path class="st3" d="M36.78,27.53c-0.34,0-0.6,0.27-0.6,0.6c0,0.33,0.27,0.6,0.6,0.6c0.33,0,0.6-0.28,0.6-0.6
                    C37.38,27.8,37.11,27.53,36.78,27.53z M36.78,28.39c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26
                    c0.13,0,0.26,0.11,0.26,0.26C37.04,28.28,36.91,28.39,36.78,28.39z"/>
                <path class="st3" d="M36.78,3.16c-0.34,0-0.6,0.27-0.6,0.6c0,0.33,0.27,0.6,0.6,0.6c0.33,0,0.6-0.28,0.6-0.6
                    C37.38,3.42,37.11,3.16,36.78,3.16z M36.78,4.02c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26
                    c0.13,0,0.26,0.11,0.26,0.26C37.04,3.91,36.91,4.02,36.78,4.02z"/>
                <circle class="st4" cx="36.78" cy="3.76" r="0.26"/>
                <path class="st3" d="M61.05,27.53c-0.33,0-0.6,0.27-0.6,0.6c0,0.33,0.28,0.6,0.6,0.6c0.34,0,0.6-0.28,0.6-0.6
                    C61.65,27.8,61.39,27.53,61.05,27.53z M61.05,28.39c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26s0.26,0.11,0.26,0.26
                    C61.31,28.28,61.19,28.39,61.05,28.39z"/>
                <path class="st3" d="M61.05,3.16c-0.33,0-0.6,0.27-0.6,0.6c0,0.33,0.28,0.6,0.6,0.6c0.34,0,0.6-0.28,0.6-0.6
                    C61.65,3.42,61.39,3.16,61.05,3.16z M61.05,4.02c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26s0.26,0.11,0.26,0.26
                    C61.31,3.91,61.19,4.02,61.05,4.02z"/>
                <circle class="st4" cx="36.78" cy="28.13" r="0.26"/>
                <circle class="st4" cx="61.05" cy="28.13" r="0.26"/>
                <circle class="st4" cx="61.05" cy="3.76" r="0.26"/>
            </g>
            <g>
                <path class="st3" d="M66.91,27.53c-0.34,0-0.6,0.28-0.6,0.6c0,0.34,0.27,0.6,0.6,0.6c0.34,0,0.6-0.27,0.6-0.6
                    C67.51,27.81,67.25,27.53,66.91,27.53z M66.91,28.39c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26
                    c0.14,0,0.26,0.11,0.26,0.26C67.16,28.28,67.05,28.39,66.91,28.39z"/>
                <path class="st3" d="M66.91,3.16c-0.34,0-0.6,0.28-0.6,0.6c0,0.34,0.27,0.6,0.6,0.6c0.34,0,0.6-0.27,0.6-0.6
                    C67.51,3.43,67.25,3.16,66.91,3.16z M66.91,4.02c-0.14,0-0.26-0.11-0.26-0.26c0-0.14,0.11-0.26,0.26-0.26
                    c0.14,0,0.26,0.11,0.26,0.26C67.16,3.91,67.05,4.02,66.91,4.02z"/>
                <circle class="st4" cx="66.91" cy="3.76" r="0.26"/>
                <path class="st3" d="M91.19,27.53c-0.34,0-0.6,0.28-0.6,0.6c0,0.34,0.27,0.6,0.6,0.6c0.33,0,0.6-0.27,0.6-0.6
                    C91.79,27.81,91.51,27.53,91.19,27.53z M91.19,28.39c-0.14,0-0.27-0.11-0.27-0.26c0-0.14,0.12-0.26,0.27-0.26
                    c0.13,0,0.26,0.11,0.26,0.26C91.44,28.28,91.32,28.39,91.19,28.39z"/>
                <path class="st3" d="M91.19,3.16c-0.34,0-0.6,0.28-0.6,0.6c0,0.34,0.27,0.6,0.6,0.6c0.33,0,0.6-0.27,0.6-0.6
                    C91.79,3.43,91.51,3.16,91.19,3.16z M91.19,4.02c-0.14,0-0.27-0.11-0.27-0.26c0-0.14,0.12-0.26,0.27-0.26
                    c0.13,0,0.26,0.11,0.26,0.26C91.44,3.91,91.32,4.02,91.19,4.02z"/>
                <circle class="st4" cx="66.91" cy="28.13" r="0.26"/>
                <path class="st4" d="M91.44,28.13c0,0.14-0.12,0.26-0.26,0.26c-0.14,0-0.27-0.11-0.27-0.26c0-0.14,0.12-0.26,0.27-0.26
                    C91.32,27.88,91.44,27.99,91.44,28.13z"/>
                <path class="st4" d="M91.44,3.76c0,0.14-0.12,0.26-0.26,0.26c-0.14,0-0.27-0.11-0.27-0.26c0-0.14,0.12-0.26,0.27-0.26
                    C91.32,3.51,91.44,3.62,91.44,3.76z"/>
            </g>
            <g id="rigth-side_1_">
                <path class="st5" d="M79.05,3.23c-7.03,0-12.73,5.69-12.73,12.72s5.69,12.72,12.73,12.72c7.02,0,12.72-5.69,12.72-12.72
                    S86.08,3.23,79.05,3.23z M79.05,26.35c-5.76,0-10.42-4.66-10.42-10.41c0-5.76,4.66-10.42,10.42-10.42
                    c5.75,0,10.41,4.66,10.41,10.42C89.46,21.69,84.8,26.35,79.05,26.35z"/>
                <circle class="st6" cx="79.05" cy="15.94" r="10.79"/>

                <!-- green light -->
                <g id="green_1_" :class="[status === 'verde' ? '' : 'turnOff']">
                    <circle class="st7" cx="79.05" cy="15.94" r="9.97"/>
                    <g>
                        <path class="st8" d="M82.03,24.87c-0.94,0.32-1.94,0.49-2.98,0.49c-5.21,0-9.42-4.22-9.42-9.42c0-5.2,4.22-9.42,9.42-9.42
                            c0.84,0,1.64,0.1,2.41,0.31C80.88,7.55,74.43,15.93,82.03,24.87z"/>
                        <path class="st9" d="M88.47,15.94c0,4.15-2.68,7.67-6.4,8.93c-0.01,0.01-0.02,0.01-0.03,0.01c-7.6-8.94-1.15-17.32-0.57-18.04
                            c0.02,0,0.02,0.01,0.04,0.02C85.51,7.92,88.47,11.59,88.47,15.94z"/>
                    </g>
                </g>
            </g>
            <g id="center-side_1_">
                <path class="st5" d="M48.92,3.23c-7.03,0-12.73,5.69-12.73,12.72s5.69,12.72,12.73,12.72c7.02,0,12.72-5.69,12.72-12.72
                    S55.94,3.23,48.92,3.23z M48.92,26.35c-5.76,0-10.42-4.66-10.42-10.41c0-5.76,4.66-10.42,10.42-10.42
                    c5.75,0,10.41,4.66,10.41,10.42C59.33,21.69,54.67,26.35,48.92,26.35z"/>
                <circle class="st6" cx="48.92" cy="15.94" r="10.79"/>

                <!-- yellow light -->
                <g id="yellow_1_" :class="[status === 'amarillo' ? '' : 'turnOff']">
                    <circle class="st10" cx="48.92" cy="15.94" r="9.97"/>
                    <g>
                        <path class="st11" d="M51.9,24.87c-0.94,0.32-1.94,0.49-2.98,0.49c-5.21,0-9.42-4.22-9.42-9.42c0-5.2,4.22-9.42,9.42-9.42
                            c0.84,0,1.64,0.1,2.41,0.31C50.75,7.55,44.3,15.93,51.9,24.87z"/>
                        <path class="st12" d="M58.34,15.94c0,4.15-2.68,7.67-6.4,8.93c-0.01,0.01-0.02,0.01-0.03,0.01c-7.6-8.94-1.15-17.32-0.57-18.04
                            c0.02,0,0.02,0.01,0.04,0.02C55.38,7.92,58.34,11.59,58.34,15.94z"/>
                    </g>
                </g>
            </g>
            <g id="left-side_1_">
                <path class="st5" d="M18.79,3.23c-7.03,0-12.73,5.69-12.73,12.72s5.69,12.72,12.73,12.72c7.02,0,12.72-5.69,12.72-12.72
                    S25.81,3.23,18.79,3.23z M18.79,26.35c-5.76,0-10.42-4.66-10.42-10.41c0-5.76,4.66-10.42,10.42-10.42
                    c5.75,0,10.41,4.66,10.41,10.42C29.2,21.69,24.53,26.35,18.79,26.35z"/>
                <circle class="st6" cx="18.79" cy="15.94" r="10.79"/>
                
                <!-- red light -->
                <g id="red_1_" :class="[status === 'rojo' ? '' : 'turnOff']">
                    <circle class="st13" cx="18.79" cy="15.94" r="9.97"/>
                    <g>
                        <path class="st14" d="M21.77,24.87c-0.94,0.32-1.94,0.49-2.98,0.49c-5.21,0-9.42-4.22-9.42-9.42c0-5.2,4.22-9.42,9.42-9.42
                            c0.84,0,1.64,0.1,2.41,0.31C20.62,7.55,14.17,15.93,21.77,24.87z"/>
                        <path class="st15" d="M28.21,15.94c0,4.15-2.68,7.67-6.4,8.93c-0.01,0.01-0.02,0.01-0.03,0.01c-7.6-8.94-1.15-17.32-0.57-18.04
                            c0.02,0,0.02,0.01,0.04,0.02C25.25,7.92,28.21,11.59,28.21,15.94z"/>
                    </g>
                </g>
            </g>
        </g>
        </svg>
</template>

<script>
export default {
    name: 'TrafficLight',
    props: {
        status: {
            type: String
        }
    }
}
</script>
// type="text/css"
<style scoped>
    /*
    * BEHIND BOX
    */
    /* .st0{fill:#363647;} */
    .st0 {fill:#dfb52e;opacity: 0.2;}
    /* 
    * BOX 
    */
    /* .st1{fill:#51516D;} */
    .st1{fill:#dbb32e;opacity: 0.2;}
    /* 
    * BOX-SQUADS
    */
    /* .st2{fill:#414159;} */
    .st2{fill:#F9D276;opacity: 0.2;}
    /*
    * SCREWS
    */
    .st3{fill:#737384;opacity:0.25;}
    .st4{fill:#9898A8;opacity:0.25;}
    /* aros */
    .st5{opacity:0.035;fill:#747477;}
    .st6{opacity:0.05;fill:#2A2B2D;}
    /* lighter */
    .st7{fill:#009E83;}
    .st8{fill:#017F67;}
    .st9{fill:#28A58D;}
    .st10{fill:#FFB636;}
    .st11{fill:#E2A042;}
    .st12{fill:#F7B64D;}
    .st13{fill:#FF473E;}
    .st14{fill:#D42A2A;}
    .st15{fill:#F75C5C;}
    .turnOff {
        opacity: 0.075;
    }
    .ghost {
        filter: grayscale(100);
    }
</style>