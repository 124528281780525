<template>
    <div>
        <!-- count:one -->
        <div v-if="row.attributes.english_promotion.payment_count == 'one'">
            <!-- payment_own: both -->
            <!-- if current is payment_owner  -->
            <div v-if="$route.params.IdProgramSeasonPostulant == row.attributes.english_promotion.payment_owner_id"
                class="bg-primary-transparent border border-primary px-3 py-1" style="border-radius: 5px"
            >
                <div>
                    <b-btn size="sm" variant="transparent" @click="routeClick(row.attributes.english_promotion.payment_no_owner_id, row.attributes.english_promotion.payment_no_owner_programSeasonParticipant_id)">
                        <b-icon icon="people-fill" scale="1.3" variant="primary"/>
                    </b-btn>
                    <span class="text-caption text-muted">{{ row.attributes.english_promotion.payment_no_owner_name }}</span>
                </div>
            </div>

            <!-- if current isn't payment_owner  -->
            <div v-if="$route.params.IdProgramSeasonPostulant == row.attributes.english_promotion.payment_no_owner_id"
                class="bg-primary-transparent border border-primary px-3 py-1" style="border-radius: 5px"
            >
                <payment-status-component :payment="row.attributes.english_promotion.payment_owner_payment" class="mt-3 mb-2"/>

                <div>
                    <b-btn size="sm" variant="transparent" @click="routeClick(row.attributes.english_promotion.payment_owner_id, row.attributes.english_promotion.payment_owner_programSeasonParticipant_id)">
                        <b-icon icon="people-fill" scale="1.3" variant="primary"/>
                    </b-btn>
                    <span class="text-caption text-muted">{{ row.attributes.english_promotion.payment_owner_name }}</span>
                </div>
            </div>
        </div>

        <!-- count:two -->
        <div v-if="row.attributes.english_promotion.payment_count == 'two'">
            <!-- payment_own: both -->
            <!-- if current is partner_one  -->
            <div v-if="$route.params.IdProgramSeasonPostulant == row.attributes.english_promotion.partnerOne_id"
                class="bg-primary-transparent border border-primary px-3 py-1" style="border-radius: 5px"
            >
                <payment-status-component :payment="row.attributes.english_promotion.partnerTwo_payment" class="mt-3 mb-2"/>

                <div>
                    <b-btn size="sm" variant="transparent" @click="routeClick(row.attributes.english_promotion.partnerTwo_id, row.attributes.english_promotion.partnerTwo_programSeasonParticipant_id)">
                        <b-icon icon="people-fill" scale="1.3" variant="primary"/>
                    </b-btn>
                    <span class="text-caption text-muted">{{ row.attributes.english_promotion.partnerTwo_name }}</span>
                </div>
            </div>

            <!-- if current is partner_two  -->
            <div v-if="$route.params.IdProgramSeasonPostulant == row.attributes.english_promotion.partnerTwo_id"
                class="bg-primary-transparent border border-primary px-3 py-1" style="border-radius: 5px"
            >
                <payment-status-component :payment="row.attributes.english_promotion.partnerOne_payment" class="mt-3 mb-2"/>
                <div>
                    <b-btn size="sm" variant="transparent" @click="routeClick(row.attributes.english_promotion.partnerOne_id, row.attributes.english_promotion.partnerOne_programSeasonParticipant_id)">
                        <b-icon icon="people-fill" scale="1.3" variant="primary"/>
                    </b-btn>
                    <span class="text-caption text-muted">{{ row.attributes.english_promotion.partnerOne_name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentStatusComponent from '@/components/Admin-Employee/Postulant-Participant/List/PaymentStatusComponent.vue'

export default {
    name: 'PostulantPaymentVoucherCellComponent',
    components: { PaymentStatusComponent },
    props: {
        row: {  // PostulantPaymentResource
            type: Object,
            required: true
        },
        leadType: {
            type: String,
            required: true
        },
        paymentVoucherDetailsRoute: {
            type: String,
            required: true
        }
    },
    data: () => ({}),

    methods: {
        routeClick(pProgramSeasonPostulant, pProgramSeasonParticipant = null) {
            if(this.leadType == 'postulant') {
                let route = this.$router.resolve({ name: this.paymentVoucherDetailsRoute, params: { 'IdProgramSeasonPostulant': pProgramSeasonPostulant } });
                window.open(route.href, '_blank');
            }

            if(this.leadType == 'participant') {
                let route = this.$router.resolve({ name: this.paymentVoucherDetailsRoute, params: { 'IdProgramSeasonPostulant': pProgramSeasonPostulant, 'IdProgramSeasonParticipant': pProgramSeasonParticipant } });
                window.open(route.href, '_blank');
            }
        }
    },

}
</script>