import { mergeAttributes } from '@tiptap/core'
import Image from '@tiptap/extension-image'

export default Image.extend({
    name: 'ApkSignatureSmall',

    inline() {
        return true
    },

    group() {
        return 'inline'
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            kind: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-kind'),
                renderHTML: () => ({
                    'data-kind': this.name,
                    // style: 'width: auto;height: 50px;border-bottom: #000 2px solid;position: relative;bottom: 10px;',
                    style: 'width: auto;height: 50px;border-bottom: rgba(0,0,0, 0.6) 1px solid',
                    class: "apk-signature"
                }),
            },
        }
    },
    parseHTML() {
        return [
          {
            tag: 'img[data-type="ApkSignatureSmall"]'
          },
        ]
      },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes({ 'data-type': "ApkSignatureSmall" }, this.options.HTMLAttributes, HTMLAttributes)]
    },

    addCommands() {
        return {
          setSignatureSmall: options => ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            })
          },
        }
    },
})