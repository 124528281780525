<template>
    <b-row align-h="center">
        <!-- payments -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow" no-body>
                <!-- card header -->
                <b-card-header header-border-variant="white">
                    <b-row>
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Pagos
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding postulantPayments loading -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table - paymments -->
                <b-card no-body v-if="!loadingTableData">
                    <div class="table-responsive" >
                        <base-table class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="tableData"
                        >
                            <template slot="columns">
                                <th>#</th>
                                <th>estado</th>
                                <th>concepto</th>
                                <th>monto</th>
                                <th>fecha límite</th>
                                <th></th>
                                <th class="bg-apk-light-green-3 text-white">PROM. 2x1</th>
                            </template>

                            <template slot-scope="{row, index}">
                                <td>
                                    <!-- numeración descendente -->
                                    {{ (index + 1) }}
                                </td>

                                <td>
                                    <traffic-light :status="row.attributes.status"/>
                                </td>

                                <td>{{ row.attributes.payment_concept}}</td>

                                <td>{{ row.attributes.amount_format}}</td>


                                <td>{{ row.attributes.expiration_at}}</td>

                                <!-- postulant.payment -> postulant.voucher -->
                                <td class="text-right"  v-show="row.type == 'PostulantPayment'">
                                    <b-btn
                                        size="sm"
                                        variant="outline-primary"
                                        @click="createPostulantVoucherCliked(row.attributes)"
                                        v-show="row.attributes.status != 'rojo' ? false : true"
                                    >
                                        Registrar voucher
                                    </b-btn>
                                </td>

                                <!-- contract.payment -> contract.voucher -->
                                <td class="text-right" v-show="row.type == 'ContractPayment'">
                                    <pulse-loader
                                        :loading="registerLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-btn
                                        v-show="row.attributes.status == 'verde' && programSeasonPostulant.attributes.hasParticipant == 'no' && !registerLoading"
                                        :disabled="registerLoading"
                                        size="sm"
                                        variant="outline-primary"
                                        @click="openModalRegister"
                                    >
                                        Registrar como PARTICIPANTE
                                    </b-btn>

                                    <b-btn
                                        size="sm"
                                        variant="outline-primary"
                                        @click="createContractVoucherCliked(row.attributes)"
                                        v-show="row.attributes.status != 'rojo' ? false : true"
                                    >
                                        Registrar voucher
                                    </b-btn>
                                </td>

                                <td v-if="row.attributes.has_english_promotion">
                                    <postulant-payment-voucher-cell-component
                                        :row="row" :leadType="'postulant'"
                                        :paymentVoucherDetailsRoute="'admin-postulants-payments-vouchers'"
                                    />
                                </td>
                            </template>
                        </base-table>
                    </div>
                </b-card>
            </b-card>
        </b-col>

        <!-- 
            Modal postulant voucher create
            -->
        <b-modal
            id="modal-postulant-voucher-create"
            centered
            hide-footer
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">Registrar voucher</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                clearInputsAndStates()
                                close()
                                }">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- voucher form -->
                        <b-col cols="12" lg="6" xl="7">
                            <b-form @submit.prevent="createPostulantVoucher">
                                <b-form-row>
                                    <!-- voucher_concept -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Concepto del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="text"
                                                disabled
                                                v-model="postulantPaymentSelected.payment_concept"
                                                :state="showModalInputStatus('voucher_concept')"
                                                v-show="!postulantPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('voucher_concept')"
                                                :key="`${i}-createVoucher-concept`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                   <!-- amount -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Monto:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="number"
                                                step="0.01"
                                                disabled
                                                v-model="postulantPaymentSelected.amount"
                                                :state="showModalInputStatus('amount')"
                                                v-show="!postulantPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('amount')"
                                                :key="`${i}-createVoucher-amount`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- image -->
                                <b-form-row>
                                    <!-- preview image -->
                                    <b-col cols="12" lg="6" xl="5" class="mb-4 d-block d-lg-none" >
                                        <b-img
                                            :src="preview_image"
                                            fluid
                                            class="voucher-preview"
                                            rounded=""
                                        ></b-img>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group
                                            label="Imagen del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-file
                                                v-model="voucher_image"
                                                :state="showModalInputStatus('image')"
                                                v-show="!postulantPaymentSelected.createVoucherLoading || false"
                                                style="overflow:hidden;"
                                                @change="showImagePreview"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('image')"
                                                :key="`${i}-createVoucher-image`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-form-row >
                                     <!-- type -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Tipo de pago:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="voucher_type"
                                                :options="voucherTypeOptions"
                                                :state="showModalInputStatus('type')"
                                                v-show="!postulantPaymentSelected.createVoucherLoading || false"
                                                @change="voucherTypeChange"
                                            >
                                                <template #first>
                                                    <b-form-select-option value="" disabled>
                                                        -- Seleccione el tipo de pago --
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('type')"
                                                :key="`${i}-createVoucher-type`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- code -->
                                    <b-col cols="12" xl="6" v-show="showCode">
                                        <b-form-group
                                            label="Código de operación del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                placeholder="Ingrese el código de operación"
                                                v-model="voucher_code"
                                                :state="showModalInputStatus('code')"
                                                v-show="!postulantPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('code')"
                                                :key="`${i}-createVoucher-code`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-form-row>
                                    <!-- date -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Fecha de operación del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                 selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="voucher_date"
                                                :state="showModalInputStatus('date')"
                                                v-if="!postulantPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('date')"
                                                :key="`${i}-createVoucher-date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                     <!-- time -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Hora de operación del voucher:"
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                show-seconds
                                                v-model="voucher_time"
                                                :state="showModalInputStatus('time')"
                                                v-if="!postulantPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('time')"
                                                :key="`${i}-createVoucher-time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="postulantPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="postulantPaymentSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!postulantPaymentSelected.createVoucherLoading || false"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-updateVoucher-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="postulantPaymentSelected.createVoucherLoading || false"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>

                        <!-- voucher image -->
                        <b-col cols="12" lg="6" xl="5" class="mb-4 d-none d-lg-block" >
                            <b-img
                                :src="preview_image"
                                fluid
                                class="voucher-preview"
                                rounded=""
                            ></b-img>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- 
            Modal contract voucher create
            -->
        <b-modal
            id="modal-contract-voucher-create"
            centered
            hide-footer
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">Registrar voucher</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                clearInputsAndStates()
                                close()
                                }">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- voucher form -->
                        <b-col cols="12" lg="6" xl="7">
                            <b-form @submit.prevent="createContractVoucher">
                                <b-form-row>
                                    <!-- voucher_concept -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Concepto del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="text"
                                                disabled
                                                v-model="contractPaymentSelected.payment_concept"
                                                :state="showModalInputStatus('voucher_concept')"
                                                v-show="!contractPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('voucher_concept')"
                                                :key="`${i}-createVoucher-concept`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                   <!-- amount -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Monto:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="number"
                                                step="0.01"
                                                disabled
                                                v-model="contractPaymentSelected.amount"
                                                :state="showModalInputStatus('amount')"
                                                v-show="!contractPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('amount')"
                                                :key="`${i}-createVoucher-amount`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- image -->
                                <b-form-row>
                                    <!-- preview image -->
                                    <b-col cols="12" lg="6" xl="5" class="mb-4 d-block d-lg-none" >
                                        <b-img
                                            :src="preview_image"
                                            fluid
                                            class="voucher-preview"
                                            rounded=""
                                        ></b-img>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group
                                            label="Imagen del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-file
                                                v-model="voucher_image"
                                                :state="showModalInputStatus('image')"
                                                v-show="!contractPaymentSelected.createVoucherLoading || false"
                                                style="overflow:hidden;"
                                                @change="showImagePreview"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('image')"
                                                :key="`${i}-createVoucher-image`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-form-row >
                                     <!-- type -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Tipo de pago:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="voucher_type"
                                                :options="voucherTypeOptions"
                                                :state="showModalInputStatus('type')"
                                                v-show="!contractPaymentSelected.createVoucherLoading || false"
                                                @change="voucherTypeChange"
                                            >
                                                <template #first>
                                                    <b-form-select-option value="" disabled>
                                                        -- Seleccione el tipo de pago --
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('type')"
                                                :key="`${i}-createVoucher-type`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- code -->
                                    <b-col cols="12" xl="6" v-show="showCode">
                                        <b-form-group
                                            label="Código de operación del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                placeholder="Ingrese el código de operación"
                                                v-model="voucher_code"
                                                :state="showModalInputStatus('code')"
                                                v-show="!contractPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('code')"
                                                :key="`${i}-createVoucher-code`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-form-row>
                                    <!-- date -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Fecha de operación del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                 selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="voucher_date"
                                                :state="showModalInputStatus('date')"
                                                v-if="!contractPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('date')"
                                                :key="`${i}-createVoucher-date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                     <!-- time -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Hora de operación del voucher:"
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                show-seconds
                                                v-model="voucher_time"
                                                :state="showModalInputStatus('time')"
                                                v-if="!contractPaymentSelected.createVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('time')"
                                                :key="`${i}-createVoucher-time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="contractPaymentSelected.createVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="contractPaymentSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!contractPaymentSelected.createVoucherLoading || false"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-updateVoucher-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="contractPaymentSelected.createVoucherLoading || false"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>

                        <!-- voucher image -->
                        <b-col cols="12" lg="6" xl="5" class="mb-4 d-none d-lg-block" >
                            <b-img
                                :src="preview_image"
                                fluid
                                class="voucher-preview"
                                rounded=""
                            ></b-img>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- 
            Modal register participant
            -->
            <b-modal
            id="modal-register-participant"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">Registrar Participante</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="closeModalRegister">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- voucher form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="registrarParticipante">
                                <b-form-row>
                                    <!-- switch -->
                                    <b-col>
                                        <b-form-group label="¿Registrar pagos de SEVIS y ROCOJO/VISA ?">
                                            <pulse-loader
                                                :loading="registerLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-checkbox
                                                v-show="!registerLoading"
                                                v-model="switchPayments"
                                                switch
                                                value="si"
                                                unchecked-value="no"
                                                class="text-uppercase text-primary"
                                            >{{ switchPayments }}</b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <transition name="slide-fade">
                                <b-form-row class="mt-1" v-if="switchPayments == 'si'">
                                    <!-- sevis -->
                                    <b-col cols="12" class="mb-1">
                                        <pulse-loader
                                            :loading="registerLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-input-group v-show="!registerLoading">
                                            <b-input-group-prepend>
                                                <b-input-group-text class="text-primary"> PAGO DE SEVIS : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="cuota_sevis_amount"
                                                :state="showModalRegisterInputStatus('cuota_sevis_amount')"
                                                number type="number" min="0.00" step="0.1" class="text-center"
                                            />
                                            
                                            <!-- expiration_date * at -->
                                            <b-input-group-append>
                                                <b-form-datepicker
                                                    required
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="cuota_sevis_expiration_date"
                                                    :state="showModalRegisterInputStatus(`cuota_sevis_expiration_date`)"
                                                />
                                            </b-input-group-append>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalRegisterInputErrors('cuota_sevis_expiration_date')"
                                                :key="`${i}-sevis-exp-date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalRegisterInputErrors('cuota_sevis_amount')"
                                                :key="`${i}-sevis-amount`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-col>

                                    <!-- visa -->
                                    <b-col cols="12" class="mb-1">
                                        <pulse-loader
                                            :loading="registerLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-input-group v-show="!registerLoading">
                                            <b-input-group-prepend>
                                                <b-input-group-text class="text-primary"> RECOJO DE VISA : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="cuota_visa_amount"
                                                :state="showModalRegisterInputStatus('cuota_visa_amount')"
                                                number type="number" min="0.00" step="0.1" class="text-center"
                                            />
                                            
                                            <!-- expiration_date * at -->
                                            <b-input-group-append>
                                                <b-form-datepicker
                                                    required
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="cuota_visa_expiration_date"
                                                    :state="showModalRegisterInputStatus(`cuota_visa_expiration_date`)"
                                                />
                                            </b-input-group-append>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalRegisterInputErrors('cuota_visa_expiration_date')"
                                                :key="`${i}-visa-exp-date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalRegisterInputErrors('cuota_visa_amount')"
                                                :key="`${i}-visa-amount`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                                </transition>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto" class="mt-4">
                                        <b-button variant="primary" type="submit"
                                            :disabled="contractPaymentSelected.createVoucherLoading || false"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getPostulantPayments } from '@/api/ProgramSeasonPostulant/PostulantPayment'
import { storePostulantVoucher } from '@/api/ProgramSeasonPostulant/PostulantVoucher'
//
import { getPostulantContractPayments } from '@/api/ProgramSeasonPostulant/ContractPayment'
import { storeContractVoucher } from '@/api/ProgramSeasonPostulant/ContractVoucher'
//
import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant'
import { programSeasonParticipantRegister } from '@/api/ProgramSeasonParticipant/Register'

import TrafficLight from '@/layout/Icons/TrafficLight.vue'
import swal from 'sweetalert'
import PostulantPaymentVoucherCellComponent from '@/components/Admin-Employee/Postulant-Participant/PaymentsVouchers/PostulantPaymentVoucherCellComponent.vue'

export default {
    name: 'Payments',
    components: {
        TrafficLight,
        PostulantPaymentVoucherCellComponent
    },
    props: {
        reload : {
            type: Number
        }
    },
    data: () => ({
        //
        switchPayments: 'no',
        //
        programSeasonPostulant: {
            attributes: {}
        },
        programSeasonPostulantLoading: false,
        // Payments
        tableData: [],
        loadingTableData: false,
        // Crate Voucher
        postulantPaymentSelected: {},
        contractPaymentSelected: {},
        voucher_type: '',
        voucher_code: '',
        voucher_image: null,
        voucher_date: '',
        voucher_time: '',
        //
        voucherTypeOptions: [
            { value: 'pago normal', text: 'Pago Normal' },
            { value: 'pago por aplicativo', text: 'Pago por Yape o Plin' },
            { value: 'nota de crédito', text: 'Nota de crédito' },
        ],
        showCode: true,
        preview_image: '/img/theme/voucher_info.jpg',
        //
        initialModalInputValues: true,
        modalInputErrors: {},
        //
        cuota_sevis_expiration_date: '',
        cuota_sevis_amount: 0,
        cuota_visa_expiration_date: '',
        cuota_visa_amount: 0,
        initialModalRegisterInputValues: true,
        modalRegisterInputErrors: {},
        registerLoading: false

    }),
    created () {
        this.getData()
        this.getPaymentsData()
    },
    watch: {
        reload () {
                this.getData()
                this.getPaymentsData()
        }
    },
    methods: {
        // programSeasonPosulant
        getData () {
            this.programSeasonPostulantLoading = true

            getProgramSeasonPostulant(this.$route.params.IdProgramSeasonPostulant)
                .then(({data}) => {
                    this.programSeasonPostulant = data.data
                })
                .finally(() => {
                    this.programSeasonPostulantLoading = false
                })
        },
        // Payments
        getPaymentsData (pPage = 1) {
            this.loadingTableData = true

            let IdprogramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant

            getPostulantPayments(pPage, IdprogramSeasonPostulant)
                .then(({data}) => {
                    this.tableData = data.data

                    getPostulantContractPayments(pPage, IdprogramSeasonPostulant)
                        .then(({data}) => {
                            for (const contractPayment of data.data) {
                                if (contractPayment.attributes.payment_concept == 'Primera Cuota') this.tableData.push(contractPayment)
                            }
                        })
                        .finally(() => {
                            this.loadingTableData = false
                        })
                })
        },

        paginationCLiked (e) {
            this.getPaymentsData(e)
            this.getData()
        },

        //
        voucherTypeChange (e) {
            if(e == 'pago normal' || e == 'nota de crédito') this.showCode = true
            else this.showCode = false
        },

        showImagePreview (e) {
            if (e.target.files.length >= 1) {
                // capturar img del input
                let image = e.target.files[0]
                // crear reader
                let reader = new FileReader()
                // código al cargar la img
                reader.onload = (ee) => {
                    this.preview_image = ee.target.result
                }
                // asignar la img
                reader.readAsDataURL(image)
            }
        },

        /**
         * Modal Postulant Voucher Create
         */
        createPostulantVoucherCliked (pPostulantPaymentData) {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            //
            this.showCode = true
            this.voucher_type = ''
            this.voucher_code = ''
            this.voucher_image = null
            this.voucher_date = ''
            this.voucher_time = ''
            //
            this.preview_image = '/img/theme/voucher_info.jpg'
            //
            this.postulantPaymentSelected = {...pPostulantPaymentData}
            this.$bvModal.show('modal-postulant-voucher-create')
        },

        createPostulantVoucher () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.$set(this.postulantPaymentSelected, 'createVoucherLoading', true)

            let VoucherForm = new FormData()

            VoucherForm.append('postulantPayment_id', this.postulantPaymentSelected.id)
            VoucherForm.append('programSeasonPostulant_id', this.postulantPaymentSelected.programSeasonPostulant_id)
            VoucherForm.append('voucher_concept', this.postulantPaymentSelected.payment_concept)
            VoucherForm.append('amount', this.postulantPaymentSelected.amount)
            VoucherForm.append('type', this.voucher_type)

            if(this.showCode) VoucherForm.append('code', this.voucher_code)
            else VoucherForm.append('code', '')

            VoucherForm.append('image', this.voucher_image)
            VoucherForm.append('date', this.voucher_date)
            VoucherForm.append('time', this.voucher_time)
            VoucherForm.append('description', this.postulantPaymentSelected.description)

            storePostulantVoucher(VoucherForm)
                .then(({ data }) => {
                    console.log('createPostulantVoucher res: ', data.data);
                    swal('Creación correcta!', 'Voucher del postulante creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getPaymentsData()
                                    this.getData()
                                    this.$emit('voucherCreated')
                                    this.$bvModal.hide('modal-postulant-voucher-create')
                                    break;

                                default:
                                    console.log('createPostulantVoucher--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('createPostulantVoucher err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.$set(this.postulantPaymentSelected, 'createVoucherLoading', false)
                    console.log('request createPostulantVoucher end');
                })
        },
        
        /**
         * Modal Contract Voucher Create
         */
        createContractVoucherCliked (pContractPaymentData) {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            //
            this.showCode = true
            this.voucher_type = ''
            this.voucher_code = ''
            this.voucher_image = null
            this.voucher_date = ''
            this.voucher_time = ''
            //
            this.preview_image = '/img/theme/voucher_info.jpg'
            //
            this.contractPaymentSelected = {...pContractPaymentData}
            this.$bvModal.show('modal-contract-voucher-create')
        },

        createContractVoucher () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.$set(this.contractPaymentSelected, 'createVoucherLoading', true)

            let VoucherForm = new FormData()

            VoucherForm.append('contractPayment_id', this.contractPaymentSelected.id)
            VoucherForm.append('contract_id', this.contractPaymentSelected.contract_id)

            VoucherForm.append('voucher_concept', this.contractPaymentSelected.payment_concept)
            VoucherForm.append('amount', this.contractPaymentSelected.amount)
            VoucherForm.append('type', this.voucher_type)

            if(this.showCode) VoucherForm.append('code', this.voucher_code)
            else VoucherForm.append('code', '')

            VoucherForm.append('image', this.voucher_image)
            VoucherForm.append('date', this.voucher_date)
            VoucherForm.append('time', this.voucher_time)
            VoucherForm.append('description', this.contractPaymentSelected.description)

            storeContractVoucher(VoucherForm)
                .then(({ data }) => {
                    console.log('createContractVoucher res: ', data.data);
                    swal('Creación correcta!', 'Voucher del postulante creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getPaymentsData()
                                    this.getData()
                                    this.$emit('voucherCreated')
                                    this.$bvModal.hide('modal-contract-voucher-create')
                                    break;

                                default:
                                    console.log('createContractVoucher--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('createContractVoucher err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.$set(this.contractPaymentSelected, 'createVoucherLoading', false)
                    console.log('request createContractVoucher end');
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            this.postulantPaymentSelected = {}
            //
            this.showCode = true
            this.voucher_type = ''
            this.voucher_code = ''
            this.voucher_image = null
            this.voucher_date = ''
            this.voucher_time = ''
            //
            this.preview_image = '/img/theme/voucher_info.jpg'
        },

        // modal register participant
        openModalRegister () {
            this.modalRegisterClearInputsAndStates()
            this.$bvModal.show('modal-register-participant')
        },
        closeModalRegister () {
            this.$bvModal.hide('modal-register-participant')
            this.modalRegisterClearInputsAndStates()
        },

        registrarParticipante () {
            this.registerLoading = true
            let IdprogramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant
            this.initialModalRegisterInputValues = false

            let Register = new FormData()
            Register.append('registrar_pagos', this.switchPayments)
            Register.append('cuota_sevis_expiration_date', this.cuota_sevis_expiration_date)
            Register.append('cuota_sevis_amount', this.cuota_sevis_amount == 0 ? '' : this.cuota_sevis_amount)
            Register.append('cuota_visa_expiration_date', this.cuota_visa_expiration_date)
            Register.append('cuota_visa_amount', this.cuota_visa_amount == 0 ? '' : this.cuota_visa_amount)

            programSeasonParticipantRegister(Register, IdprogramSeasonPostulant)
                .then(() => {
                    swal('Creación correcta!', 'Participante Registrado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.modalRegisterClearInputsAndStates()
                                    this.getPaymentsData()
                                    this.getData()
                                    this.$emit('voucherCreated')
                                    this.$bvModal.hide('modal-register-participant')
                                    break;

                                default:
                                    console.log('createPostulantVoucher--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('register err : ', err.response)
                    if (err.response.status === 422) {
                        this.modalRegisterInputErrors = err.response.data.errors
                    }
                })
                .finally(() =>{
                    this.registerLoading = false
                })
        },

        showModalRegisterInputErrors (pInput) {
            if (Object.keys(this.modalRegisterInputErrors).includes(pInput)) return this.modalRegisterInputErrors[pInput]
            else return []
        },

        showModalRegisterInputStatus (pInput) {
            if (this.initialModalRegisterInputValues) return null 
            else if (Object.keys(this.modalRegisterInputErrors).includes(pInput)) return false
            else return true
        },

        modalRegisterClearInputsAndStates () {
            //
            this.initialModalRegisterInputValues = true
            this.modalRegisterInputErrors = {}
            //
            this.switchPayments = 'no'
            //
            this.cuota_sevis_expiration_date = ''
            this.cuota_sevis_amount =  0
            this.cuota_visa_expiration_date = ''
            this.cuota_visa_amount =  0
        },

        reloadData () {
            this.getPaymentsData()
            this.getData()
            this.$emit('voucherCreated')
        }
    }
}
</script>

<style scoped>
.voucher-preview {
    border:1px solid rgba(0, 0, 0, 0.15);
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>