<template>
    <!-- create employee with new user -->
    <b-form @submit.prevent="createAdmin">
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Nombres del admin."
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese los nombres"
                        v-model="name"
                        :state="showInputStatus('name')"
                        v-show="!createAdminLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('name')" :key="`${i}-createAdmin-name`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Apellidos del admin."
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese los apellidos"
                        v-model="lastName"
                        :state="showInputStatus('last_name')"
                        v-show="!createAdminLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('last_name')" :key="`${i}-createAdmin-lastName`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>
    
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Tipo de documento"
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-select 
                        v-model="documentType"
                        :options="documentTypeOptions"
                        :state="showInputStatus('document_type')"
                        v-show="!createAdminLoading"
                    >
                        <template #first>
                            <b-form-select-option value="" disabled>
                                -- Seleccione el tipo de documento --
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('document_type')" :key="`${i}-createAdmin-documentType`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Número de documento"
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="number"
                        placeholder="Ingrese el número de documento"
                        v-model="documentNumber"
                        :state="showInputStatus('document_number')"
                        v-show="!createAdminLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('document_number')" :key="`${i}-createAdmin-documentNumber`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Número de celular"
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="number"
                        placeholder="Ingrese el número de celular"
                        v-model="phoneNumber"
                        :state="showInputStatus('phone_number')"
                        v-show="!createAdminLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('phone_number')" :key="`${i}-createAdmin-phoneNumber`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>
    
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Correo"
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="email"
                        placeholder="Ingrese el correo"
                        v-model="email"
                        :state="showInputStatus('email')"
                        v-show="!createAdminLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('email')" :key="`${i}-createAdmin-email`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>
    
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Descripción"
                >
                    <pulse-loader
                        :loading="createAdminLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-textarea 
                        placeholder="Ingrese una descripción (opcional)"
                        v-model="description"
                        :state="showInputStatus('description')"
                        v-show="!createAdminLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('description')" :key="`${i}-createAdmin-description`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row class="justify-content-center">
            <b-col cols="12" sm="8">
                <b-button
                    class="w-100 my-2"
                    variant="primary"
                    type="submit"
                    :disabled="createAdminLoading"
                >CREAR</b-button>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<script>
import { storeAdmin } from '@/api/Admin/Admin'
import swal from 'sweetalert'

export default {
    name: 'AdminNewUser',
    data: () => ({
        //form
        name: '',
        lastName: '',
        documentType: '',
        documentNumber: '',
        phoneNumber: '',
        email: '',
        description: '',
        //
        createAdminLoading: false,
        //
        documentTypeOptions: [
            { value: 'extranjeria', text: 'carnet de extranjería' },
            { value: 'dni', text: 'dni' }
        ],
        //}
        initialInputValues: true,
        inputErrors: {},
    }),
    methods: {
        createAdmin () {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createAdminLoading = true
            
            let AdminFormData = new FormData()
            AdminFormData.append('name', this.name)
            AdminFormData.append('last_name', this.lastName)
            AdminFormData.append('document_type', this.documentType)
            AdminFormData.append('document_number', this.documentNumber)
            AdminFormData.append('phone_number', this.phoneNumber)
            AdminFormData.append('email', this.email)
            AdminFormData.append('description', this.description)

            storeAdmin(AdminFormData)
                .then(() => {
                     swal('Creación correcta!', 'Nuevo admin. creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'admin-admins-list'})
                                    break;

                                default:
                                    console.log('adminCreate-newUser-storeAdmin-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createAdminLoading = false
                    console.log('request create admin end');
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        }
    },
}
</script>