import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function updateProgramSeasonPostulantCulturalAdvisor (pFormdata ,pIdProgramSeasonPostulant) {
    const URI = `${BASE_URL}/api/programSeasonPostulant/${pIdProgramSeasonPostulant}/update-culturalAdvisor`
    return post(URI, pFormdata, { headers })
}

export { updateProgramSeasonPostulantCulturalAdvisor }