import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getHtmlLayout (pIdHtmlLayout) {
    const URI = `${BASE_URL}/api/htmlLayouts/${pIdHtmlLayout}`
    return axios.get(URI,{ headers })
}

function getHtmlLayouts () {
    const URI = `${BASE_URL}/api/htmlLayouts`
    return axios.get(URI,{ headers })
}

function storeHtmlLayout (pFormData) {
    const URI = `${BASE_URL}/api/htmlLayouts`
    return axios.post(URI, pFormData, { headers })
}

function updateHtmlLayout (pFormData, pIdHtmlLayout) {
    const URI = `${BASE_URL}/api/htmlLayouts/${pIdHtmlLayout}`
    return axios.post(URI, pFormData, { headers })
}

function destroyHtmlLayout (pIdHtmlLayout) {
    const URI = `${BASE_URL}/api/htmlLayouts/${pIdHtmlLayout}`
    return axios.delete(URI, { headers })
}

function getSeasonHtmlLayouts (pSeasonId) {
    const URI = `${BASE_URL}/api/seasons/${pSeasonId}/htmlLayouts`
    return axios.get(URI, { headers })
}

export {
    getHtmlLayout,
    getHtmlLayouts,
    storeHtmlLayout,
    updateHtmlLayout,
    destroyHtmlLayout,
    getSeasonHtmlLayouts
}