<template>
    <payments-vouchers-export-component
        :ui="ui"
    ></payments-vouchers-export-component>
</template>

<script>
import PaymentsVouchersExportComponent from '@/components/Admin-Employee/Export/Vouchers/PaymentsVouchersExportComponent.vue'

export default {
    name: 'PaymentsVouchers',
    components: { PaymentsVouchersExportComponent },
    data: () => ({
        ui: 'admin'
    })
}
</script>