<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Contract Layouts', path: { name: 'admin-layouts-contracts-list' } }"/>
            <intranet-nav-content-link :link="{ name: 'Crear', path: { name: 'admin-layouts-contracts-create' } }"/>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';

export default {
    name: 'ContractLayouts',
    components: { 
        IntranetNavContent,
        IntranetNavContentLinkExact,
        IntranetNavContentLink
    },
};
</script>
