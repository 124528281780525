<template>
    <b-card class="shadow">
        <b-card-header>
            <h3 class="mb-0 text-button text-primary">
                Roles del usuario
            </h3>
        </b-card-header>

        <!-- get user-roles loading -->
        <div style="height:300px;" v-show="getUserRolesLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

        <b-card
            no-body
            border-variant="lighter"
            style="overflow:hidden"
            v-show="!getUserRolesLoading"
            class="mt-6"
        >
            <div class="table-responsive mb-0">
                <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="userRoles"
                >
                    <template slot="columns">
                        <th>role</th>
                        <th>estado</th>
                        <th></th>
                    </template>

                    <template slot-scope="{ row }">
                        <td>
                            {{ row.attributes.role_name }}
                        </td>

                        <td>
                            <pulse-loader
                                :loading="row.updateLoading || false"
                                :color="'#5e72e4'"
                                :margin="'2px'"
                                :size="10"
                            />

                            <span
                                :class="row.attributes.status === 'activado' ? 'text-green' : 'text-red'"
                                v-show="!row.updateLoading || false"
                            >
                                {{ row.attributes.status }}
                            </span>
                        </td>

                        <td>
                            <b-form-checkbox
                                v-show="row.attributes.role_name === role ? true : false"
                                switch
                                :checked="row.attributes.status === 'activado' ? true : false"
                                @change="(e) => updateRoleStatus(row, e)"
                                :disabled="row.updateLoading || false"
                            ></b-form-checkbox>

                            <b-button
                                size="sm"
                                v-show="row.attributes.role_name === role ? false : true"
                                variant="outline-primary"
                                :to="{ 
                                    name:`admin-${row.attributes.roleNameToManage}-details`,
                                    params: { 'id': row.attributes.idToManage } }"
                            >
                                gestionar
                            </b-button>
                        </td>
                    </template>
                </base-table>
            </div>
        </b-card>

        
    </b-card>
</template>

<script>
import { getUserUserRoles, updateUserUserRoleStatus } from '@/api/User/UserUserRole'

export default {
    name: 'ManageRoles',
    props: {
        userId: {
            type: Number
        },
        role: {
            type: String
        }
    },
    data: () => ({
        userRoles: [],
        //
        getUserRolesLoading: false
    }),
    created () {
        this.getRoles()
    },
    methods: {
        getRoles () {
            this.getUserRolesLoading = true

            getUserUserRoles(this.userId)
                .then(({data}) => {
                    console.log('user userRoles' ,data);
                    this.userRoles = data.data
                })
                .catch(err => {
                    console.log('getUserUserRoles err', err)
                    console.log('getUserUserRoles err.response', err.response)
                })
                .finally(() => {
                    this.getUserRolesLoading = false
                    console.log('request getUserUserRoles end')
                })
        },

        updateRoleStatus (pUserRole, event) {
            this.$set(pUserRole, "updateLoading", true);

            let UpdateFormData = new FormData()
            UpdateFormData.append('.method', 'put')

            if (event) UpdateFormData.append('process', 'activar')
            else UpdateFormData.append('process', 'desactivar')

            updateUserUserRoleStatus(
                pUserRole.attributes.user_id,
                pUserRole.attributes.id,
                UpdateFormData)
                .then(({ data }) => {
                    console.log('updateRoleStatus: ', data);
                    pUserRole.attributes.status = data.data.attributes.status
                })
                .catch(err => {
                    console.log('updateRoleStatus error: ', err);
                })
                .finally(() => {
                    pUserRole.updateLoading = false
                })
        }
    }
}
</script>