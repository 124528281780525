import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js'
import ApkContractMentionList from '@/components/Tiptap/components/ApkContractMentionList.vue'

export default {
    items: ({ query }) => {
        return ['fullname', 'dni', 'address', 'day', 'month']
            .filter((item) =>
                item.toLowerCase().startsWith(query.toLowerCase())
            )
            .slice(0, 5)
    },

    render: () => {
        let component
        let popup

        return {
            onStart: (props) => {
                component = new VueRenderer(ApkContractMentionList, {
                    // using vue 2:
                    parent: this,
                    propsData: props,

                    // using vue 3:
                    // props,
                    // editor: props.editor,
                })

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key == 'Escape') {
                    popup[0].hide()
                    return true
                }
                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}
