<template>
    <b-row>
        <b-col>
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                list
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button size="sm" variant="green" @click="exportExcel"><b-icon icon="download"></b-icon></b-button>

                            <b-button variant="naranja" size="sm" @click="openModalEditNumPost">
                                <b-icon icon="pencil" scale="1" class="mr-1"></b-icon> NUM. POST.
                            </b-button>

                            <create-attempt-component @created='reloadData' :IdProgramSeasonParticipant="$route.params.IdProgramSeasonParticipant" class="mr-2">
                            </create-attempt-component>

                            <b-button variant="primary" size="sm" @click="reloadData"><b-icon icon="arrow-repeat" scale="1.3"></b-icon></b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>N° post.</th>
                            <th>sponsor</th>
                            <th>oferta laboral</th>
                            <th>estado</th>
                            <th class="text-center">detalles</th>
                            <th></th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.attributes.subject }}</td>
                            <td>{{ row.attributes.sponsor_name }}</td>
                            <td>{{ row.attributes.programSponsorJob.attributes.job_name }}</td>
                            <td>{{ row.attributes.status }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: routeNameAtemptDetails,
                                        params: { 'IdPostulation': row.id }    //IdPostulation
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                   <b-icon icon="three-dots" scale="1.3"></b-icon>
                                </b-btn>
                            </td>

                            <td class="text-center">
                                <b-btn @click="deleteAttempt(index, row)" variant="outline-magenta" size="sm" :disabled="row.deleteLoading"><b-icon icon="trash" scale="1.3" :animation="row.deleteLoading ? 'throb' : ''"></b-icon></b-btn>
                            </td>

                            <td class="text-center">
                                <edit-attempt-component :order="index" :postulation="row"  @updated='reloadData'></edit-attempt-component>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- modal edit num post -->
        <b-modal
            id="modal-edit-num-post"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-uppercase">N° Postulaciones</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="closeModalEditNumPost"
                                :disabled="editNumPostLoading"
                            ><b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- loading -->
                        <div style="height:200px;" v-show="participantLoading">
                            <pulse-loader
                                :color="'#5e72e4'"
                                :margin="'10px'"
                                :size="20"
                                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                        </div>

                        <!-- form -->
                        <b-col cols="12" v-show="!participantLoading">
                            <b-form @submit.prevent="editNumPost">
                                <b-form-row>
                                    <!-- first -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group label="Primera postulación:" >
                                            <pulse-loader
                                                :loading="editNumPostLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select 
                                                v-model="programSeasonParticipant.first_attempt"
                                                :options="firstOptions"
                                                :state="showModalInputStatus('first_attempt')"
                                                v-show="!editNumPostLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('first_attempt')"
                                                :key="`${i}-interview-first_attempt`"
                                            >{{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- second -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group label="Segunda postulación:" >
                                            <pulse-loader
                                                :loading="editNumPostLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select 
                                                v-model="programSeasonParticipant.second_attempt"
                                                :options="secondOptions"
                                                :state="showModalInputStatus('second_attempt')"
                                                v-show="!editNumPostLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('second_attempt')"
                                                :key="`${i}-interview-second_attempt`"
                                            >{{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- third -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group label="Tercera postulación:" >
                                            <pulse-loader
                                                :loading="editNumPostLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select 
                                                v-model="programSeasonParticipant.third_attempt"
                                                :options="thirdOptions"
                                                :state="showModalInputStatus('third_attempt')"
                                                v-show="!editNumPostLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('third_attempt')"
                                                :key="`${i}-interview-third_attempt`"
                                            >{{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- fourth -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group label="Cuarta postulación:" >
                                            <pulse-loader
                                                :loading="editNumPostLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select 
                                                v-model="programSeasonParticipant.fourth_attempt"
                                                :options="fourthOptions"
                                                :state="showModalInputStatus('fourth_attempt')"
                                                v-show="!editNumPostLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('fourth_attempt')"
                                                :key="`${i}-interview-fourth_attempt`"
                                            >{{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- button create -->
                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button
                                            class="text-uppercase"
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                            :disabled="editNumPostLoading"
                                            >Actualizar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
// programSeasonParticipant.postulation
import { getProgramSeasonParticipantPostulations } from '@/api/ProgramSeasonParticipant/Postulation';
import { destroyPostulation } from '@/api/Postulation/Postulation';
import { getProgramSeasonParticipant, updateNumberOfPostulations } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant';
import { programSeasonParticipantPostulationExport } from '@/api/ProgramSeasonParticipant/PostulationExport';
import FileSaver from 'file-saver';
import swal from 'sweetalert';
//
import EditAttemptComponent from './EditAttemptComponent.vue';
import CreateAttemptComponent from './CreateAttemptComponent.vue';

export default {
    name: "AttemptsListComponent",
    props: {
        routeNameAtemptDetails : {
            type: String
        }
    },

    components: {
        EditAttemptComponent,
        CreateAttemptComponent
    },

    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        programSeasonParticipant: {},
        participantLoading: false,
        //
        firstOptions: [ { value: 'si', text:'si' }, { value: 'no', text:'no' }],
        secondOptions: [ { value: 'si', text:'si' }, { value: 'no', text:'no' }],
        thirdOptions: [ { value: 'si', text:'si' }, { value: 'no', text:'no' }],
        fourthOptions: [ { value: 'si', text:'si' }, { value: 'no', text:'no' }],
        //
        editNumPostLoading: false,
        initialModalInputValues: true,
        modalInputErrors: {},
        //
        exportLoading: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.loadingTableData = true
            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant    // ok
            getProgramSeasonParticipantPostulations(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.tableData = data.data
                    /** to delete because the name alredy comes in  attributesd.programSeasonJob.attributes.jobName*/
                    /** 25 july 2022 -> delete in 5 motnhs*/
                    // this.tableData = this.tableData.map(el => {
                    //     let job_offer_title_name = ''
                    //     let job_title_order = el.attributes.programSeasonSponsor.job_title_order.split('|')
                    //     let jobFields = el.attributes.programSponsorJob.attributes.jobFields

                    //     for (const titleOrder of job_title_order) {
                    //         for (const field of jobFields) {
                    //             if (titleOrder == field.attributes.programJobField_id) {
                    //                 if (job_title_order.indexOf(titleOrder) == 0) job_offer_title_name = field.attributes.field_value
                    //                 else job_offer_title_name += ` - ${field.attributes.field_value}`
                    //                 break;
                    //             }
                    //         }
                    //     }
                    //     return { ...el, job_offer_title_name }
                    // })
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },
        reloadData() {
            this.getData()
        },

        // MODAL : EDIT-NUM-POST
        openModalEditNumPost () {
            this.clearInputsAndStates()
            this.getNumPostData()
            this.$bvModal.show('modal-edit-num-post')
        },
        closeModalEditNumPost () {
            this.$bvModal.hide('modal-edit-num-post')
            this.clearInputsAndStates()
        },
        getNumPostData () {
            this.participantLoading = true
            getProgramSeasonParticipant(this.$route.params.IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.programSeasonParticipant = data.data.attributes
                })
                .finally(() => {
                    this.participantLoading = false
                })
        },
        editNumPost () {
            this.editNumPostLoading = true
            this.initialModalInputValues = false

            let NumPostForm = new FormData()
            NumPostForm.append('.method', 'put')
            NumPostForm.append('first_attempt', this.programSeasonParticipant.first_attempt)
            NumPostForm.append('second_attempt', this.programSeasonParticipant.second_attempt)
            NumPostForm.append('third_attempt', this.programSeasonParticipant.third_attempt)
            NumPostForm.append('fourth_attempt', this.programSeasonParticipant.fourth_attempt)

            updateNumberOfPostulations(NumPostForm, this.programSeasonParticipant.id)
                .then(() => {
                    swal('Actualización correcta!', 'N° postulaciones actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalEditNumPost()
                                    break;
                                default:
                                    console.log('edit updatePostulation swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editNumPostLoading = false
                })
        },

        // globar input methods
        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            this.programSeasonParticipant = {}
        },

        exportExcel() {
            this.exportLoading = true
            programSeasonParticipantPostulationExport(this.$route.params.IdProgramSeasonParticipant)
                .then(res => {
                    FileSaver.saveAs(res.data, 'postulaciones.xlsx');
                })
                .finally(() => {
                    this.exportLoading = false
                })
        },

        deleteAttempt(i,pPostulation) {
            swal('Se eliminará la postulacion.', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pPostulation, 'deleteLoading', true)
                            destroyPostulation(pPostulation.attributes.id)
                                .then(() => {
                                    swal('Se eliminó correctamente.', '', 'success')
                                        .then(val => {
                                            switch(val) {
                                                case true:
                                                case null:
                                                    this.tableData.splice(i, 1)
                                                    break;
                                                default:
                                                    console.log('delete-attempt-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    pPostulation.deleteLoading = false
                                })
                            break;

                        case null:
                            break;

                        default:
                            console.log('delete-attempt-break');
                            break;
                    }
                })
        },
    }
}
</script>