<template>
    <div>
        <b-iconstack font-scale="1.3" class="mr-3">
            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
            <b-icon
                stacked icon="square-fill" scale="1.1"
                :variant="payment.attributes.status == 'verde' ? 'green' : (payment.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
            ></b-icon>
        </b-iconstack>

        <span class="text-caption text-muted">
            {{ payment.attributes.amount_format }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'PaymentStatusComponent',
    props: {
        payment: {
            type: Object,
            required: true
        }
    },
    data: () => ({}),
}
</script>