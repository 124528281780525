<template>
    <div>
        <b-btn variant="outline-sponsor-interview-3" size="sm" @click="modalClick">
            <b-icon icon="clock-history" scale="1.1"></b-icon>
        </b-btn>

        <!-- modal document actions -->
        <b-modal
            :id="`modal-document-actions-${order}`"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-sponsor-interview-3 mb-0 text-button">ACCIONES</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default class="pt-0">
                <b-container>
                    <b-row >
                        <b-col cols="12" class="mb-5 text-center">
                            <h3 class="text-primary">{{ document.name  }}</h3>
                            <h5 class="text-primary text-muted m-0">{{ document.updated_at  }}</h5>
                            <h5 class="text-primary text-muted m-0">last modif.</h5>
                        </b-col>
                        <b-col cols="12" style="overflow-y:auto">
                            <!-- laodingTableData -->
                            <div style="height:150px;" v-show="loading">
                                <pulse-loader
                                    :color="'#2F8F9D'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                            <b-list-group style="min-width: max-content" v-show="!loading">
                                <b-list-group-item v-for="(action, i) in data" :key="`ac-${i}`">
                                    <b-row>
                                        <b-col cols="4">
                                            <h4 class="text-sponsor-interview-3 text-uppercase m-0">{{ action.attributes.personable_type_formated }} </h4>
                                            <h5 class="text-sponsor-interview-2 text-subtitle m-0">{{ action.attributes.created_at }} </h5>
                                            <h6 class="text-sponsor-interview-1 text-subtitle m-0">{{ action.attributes.personable_full_name }} </h6>
                                        </b-col>

                                        <b-col cols="1" class="text-center">:</b-col>

                                        <b-col cols="7">
                                            <div v-for="(act, i) in action.attributes.action.split(',')" :key="`ac-ac-${i}`">{{ act }}</div>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { participantDocumentActions } from '@/api/ParticipantDocument/Action'

export default {
    name: "ParticipantDocumentActionsComponent",
    props: {
        document: {
            type: Object
        },
        order: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        data: [],
        participantActions: [],
        asesorActions: [],
        loading: false
    }),
    methods: {
        modalClick () {
            this.getData()
            this.$bvModal.show(`modal-document-actions-${this.order}`)
        },
        modalClose () {
            this.$bvModal.hide(`modal-document-actions-${this.order}`)
        },
        getData() {
            this.loading = true
            participantDocumentActions(this.document.id)
                .then(({ data }) => {
                    this.data = data.data
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },

}
</script>
