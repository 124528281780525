<template>
    <general-emails-component></general-emails-component>
</template>

<script>
import GeneralEmailsComponent from '@/components/Managment/Emails/GeneralEmailsComponent.vue'

export default {
    name: 'GeneralEmails',
    components: { GeneralEmailsComponent },
}
</script>