import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getHtmlContract (pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContracts/${pIdHtmlContract}`
    return axios.get(URI, { headers })
}

function destroyHtmlContract (pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContracts/${pIdHtmlContract}`
    return axios.delete(URI, { headers })
}

function updateHtmlContract (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContracts/${pIdHtmlContract}`
    return axios.post(URI, pFormData, { headers })
}

function updateSignature (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContract/${pIdHtmlContract}/update-signature`
    return axios.post(URI, pFormData, { headers })
}

function updateFingerprint (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContract/${pIdHtmlContract}/update-fingerprint`
    return axios.post(URI, pFormData, { headers })
}

function updateDniAnverso (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContract/${pIdHtmlContract}/update-dniAnverso`
    return axios.post(URI, pFormData, { headers })
}

function updateDniReverso (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContract/${pIdHtmlContract}/update-dniReverso`
    return axios.post(URI, pFormData, { headers })
}

function sentToVerifyContract (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContract/${pIdHtmlContract}/send-to-verify-contract`
    return axios.post(URI, pFormData, { headers })
}

function setVerifyData (pFormData, pIdHtmlContract) {
    const URI = `${BASE_URL}/api/htmlContract/${pIdHtmlContract}/set-verify-data`
    return axios.post(URI, pFormData, { headers })
}

export {
    getHtmlContract,
    destroyHtmlContract,
    updateHtmlContract,
    updateSignature,
    updateFingerprint,
    updateDniAnverso,
    updateDniReverso,
    sentToVerifyContract,
    setVerifyData
}