<template>
    <div>
         <base-header class="header pb-17 pt-6 pt-lg-7 d-flex align-items-center border-0 border-cielo"
            style="border-bottom-left-radius:0.5rem;border-bottom-right-radius:0.5rem;
            background-image: url(/img/theme/profile-cover.jpg);background-size:cover;background-position:center 70%;"
        >
        <!-- default b-containter-fluid - -->
        <!-- default header-body - -->

            <!-- Mask -->
            <span
                class="mask opacity-7"
                style="border-bottom-left-radius:0.5rem;border-bottom-right-radius:0.5rem;"
                :class="`bg-gradient-${bgColor}`"
            />
            <!-- <span
                class="mask glass"
                style="border-bottom-left-radius:0.5rem;border-bottom-right-radius:0.5rem;"
            /> -->
            <!-- Header container -->
            <b-row>
                <b-col md="12" lg="12" class="mt-15 border-0 border-naranja">
                    <slot name="links"/>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--16-b border-0 border-magenta" style="min-height:500px">
            <slide-x-right-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-right-transition>
        </b-container>
    </div>
</template>
<script>
import { SlideXRightTransition } from 'vue2-transitions';

export default {
    name: 'IntranetNavContentLink',
    components: { SlideXRightTransition },
    props: {
        bgColor: {
            type: String,
            default: 'primary'
        }
    }
};
</script>

<style scoped>

.glass {
    /* background-color: rgba(255, 255, 255, .075) !important; */
    backdrop-filter: blur(2px) !important;
}
</style>
