<template>
    <!-- card -->
    <b-card class="shadow" no-body>
        <!-- header -->
        <b-card-header>
            <b-row class="align-items-center">
                <b-col>
                    <h3 class="mb-0 text-primary text-button">
                        TEMPORADAS
                    </h3>
                </b-col>
                <b-col class="text-right">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="modalCreateSeasonClick"
                    >CREAR
                    </b-button>
                </b-col>

                <b-col cols="auto">
                    <b-button variant="primary" size="sm" @click="reloadData">
                        <b-icon icon="arrow-repeat"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-header>

        <!-- laodingTableData -->
        <div style="height:200px;" v-show="loadingTableData">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
        </div>

        <!-- table -->
        <div class="table-responsive" v-show="!loadingTableData">
            <base-table class="table align-items-center table-flush"
                thead-classes="thead-light"
                tbody-classes="list"
                :data="tableData"
            >
                <template slot="columns">
                    <th>#</th>
                    <th>temporada</th>
                    <th>descripción</th>
                    <th>estado</th>
                </template>

                <template slot-scope="{row, index}">
                    <td>
                        <!-- numeración descendente -->
                        {{ index + 1 }}
                    </td>

                    <td>
                        {{ row.attributes.season}}
                    </td>

                    <td>
                        {{ row.attributes.description }}
                    </td>

                    <td :class="row.attributes.status === 'activado' ? 'text-green' : 'text-red'">
                        {{ row.attributes.status}}
                    </td>
                </template>
            </base-table>
        </div>

        <!-- pagination -->
        <div class="card-footer" v-show="!loadingTableData">
            <base-pagination
                align="center"
                :pageCount="tableMeta.last_page" 
                :perPage="tableMeta.per_page"
                :value="tableMeta.current_page"
                @input="paginationCLiked"
            />
        </div>

        <!-- modal: create-season -->
        <b-modal
            id="modal-create-season"
            centered
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">CREAR TEMPORADA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                    close()
                                    clearInputsAndStates()
                                }"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="createSeason" >
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Temporada"
                                >
                                    <pulse-loader
                                        :loading="createSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el nombre de la temporada"
                                        v-model="season"
                                        :state="showInputStatus('season')"
                                        v-show="!createSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('season')" :key="`${i}-createSeason-name`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Descripción"
                                >
                                    <pulse-loader
                                        :loading="createSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                      <b-form-textarea 
                                        placeholder="Ingrese una descripción (opcional)"
                                        v-model="description"
                                        :state="showInputStatus('description')"
                                        v-show="!createSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('description')" :key="`${i}-createSeason-description`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="8">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    :disabled="createSeasonLoading"
                                >CREAR</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>
    </b-card>
</template>

<script>
import { getSeasons, storeSeason } from '@/api/Season/Season'
//import { PulseLoader } from '@saeris/vue-spinners'
import swal from 'sweetalert'


export default {
    name: 'SeasonsList',
    //components: { PulseLoader },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //form
        season: '',
        description: '',
        //
        inputErrors: {},
        initialInputValues: true,
        createSeasonLoading: false,
    }),
    created() {
        this.getSeasonsData()
    },
    methods: {
        // TABLE SEASONS
        getSeasonsData (pPage = 1) {
            this.loadingTableData = true

            getSeasons(pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch( err => {
                    console.log('getSeasons Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getSeasons request end');
                })
        },

        paginationCLiked (e) {
            this.getSeasonsData(e)
        },

        // MODAL METHODS
        modalCreateSeasonClick() {
            this.$bvModal.show('modal-create-season')
        },

        createSeason() {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createSeasonLoading = true

            let ProgramSeason = new FormData()
           ProgramSeason.append('season', this.season.toUpperCase());
           ProgramSeason.append('description', this.description);

            storeSeason(ProgramSeason)
                .then(({data}) => {
                    console.log('store season success', data);
                    swal('Creación correcta!', 'Nueva temporada creada', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getSeasonsData()
                                    this.$bvModal.hide('modal-create-season')
                                    break;

                                default:
                                    console.log('createSeason-storeSeason-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createSeasonLoading = false
                    console.log('request create season end');
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
            this.season = ''
            this.description = ''
        },

        reloadData () {
            this.getSeasonsData()
        }
    }
}
</script>