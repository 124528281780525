<template>
    <page-information-component
        :code="code"
        :paragraphOne="text_one"
        :paragraphTwo="text_two"
    ></page-information-component>
</template>

<script>
import PageInformationComponent from '@/components/UI/PageInformationComponent.vue'

export default {
    name: 'NotFound',
    components: { PageInformationComponent },
    data: () => ({
        code: '404',
        text_one: 'No se encontro el',
        text_two: 'recurso solicitado.',
    })
}
</script>