<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Sponsors', path: { name: 'admin-sponsor-list' } }"/>
            <intranet-nav-content-link :link="{ name: 'Ofertas laborales - Campos Generales', path: { name: 'admin-sponsor-general-job-inputs' } }"/>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';

export default {
    name: 'Sponsor',
    components: { 
        IntranetNavContent,
        IntranetNavContentLinkExact,
        IntranetNavContentLink
    },
};
</script>
