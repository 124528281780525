<template>
    <div>
        <!-- get sales-advisor-permissions loading -->
        <div style="height:200px;" v-show="getCAPermissionsLoading">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)" />
        </div>

        <div class="table-responsive" v-show="!getCAPermissionsLoading">
            <base-table class="table align-items-center table-flush"
                thead-classes="thead-light"
                tbody-classes="list"
                :data="culturalAdvisorPermissions"
            >
                <template slot="columns">
                    <th>permiso</th>
                    <th>estado</th>
                    <th></th>
                </template>

                <template slot-scope="{ row }">
                    <td>
                        {{ row.attributes.program_name }}
                    </td>

                    <td>
                        <pulse-loader
                            :loading="row.updateLoading || false"
                            :color="'#5e72e4'"
                            :margin="'2px'"
                            :size="10"
                        />

                        <span
                            :class="row.attributes.status === 'activado' ? 'text-green' : 'text-red'"
                            v-show="!row.updateLoading || false"
                        >
                            {{ row.attributes.status }}
                        </span>
                    </td>

                    <td class="text-right">
                        <b-form-checkbox
                            switch
                            size="sm"
                            :checked="row.attributes.status === 'activado' ? true : false"
                            @change="(e) => updatePermissionStatus(row, e)"
                            :disabled="row.updateLoading || false"
                        ></b-form-checkbox>
                    </td>
                </template>
            </base-table>
        </div>
    
    </div>
</template>

<script>
import { getCulturalAdvisorCulturalAdvisorPermissions, updateCulturalAdvisorCulturalAdvisorPermissions } from '@/api/CulturalAdvisor/CulturalAdvisorCulturalAdvisorPermission'

export default {
    name: 'ManageCAPermissions',
    props: {
        culturalAdvisorId: {
            type: Number
        }
    },
    data: () => ({
        culturalAdvisorPermissions: [],
        //
        getCAPermissionsLoading: false
    }),
    created () {
        this.getCAPermissions()
    },
    methods: {
        getCAPermissions () {
            this.getCAPermissionsLoading = true

            getCulturalAdvisorCulturalAdvisorPermissions(this.culturalAdvisorId)
                .then(({data}) => {
                    console.log('CAPermissions data: ' ,data);
                    this.culturalAdvisorPermissions = data.data
                })
                .catch(err => {
                    console.log('CAPermissions err', err)
                    console.log('CAPermissions err.response', err.response)
                })
                .finally(() => {
                    this.getCAPermissionsLoading = false
                    console.log('request CAPermissions end')
                })
        },

        updatePermissionStatus (pCAPermission, event) {
            this.$set(pCAPermission, "updateLoading", true);

            let UpdateFormData = new FormData()
            UpdateFormData.append('.method', 'put')

            if (event) UpdateFormData.append('process', 'activar')
            else UpdateFormData.append('process', 'desactivar')

            updateCulturalAdvisorCulturalAdvisorPermissions(
                UpdateFormData,
                pCAPermission.attributes.culturalAdvisor_id,
                pCAPermission.attributes.id)
                .then(({ data }) => {
                    console.log('update CAPermission status : ', data);
                    pCAPermission.attributes.status = data.data.attributes.status
                })
                .catch(err => {
                    console.log('update CAPermission status error: ', err);
                })
                .finally(() => {
                    pCAPermission.updateLoading = false
                })
        }
    }
}
</script>