<template>
    <span
        class="dropdown-item"
        @click="cerrarSesion"
        style="cursor:pointer;"
    ><b-icon icon="box-arrow-right"></b-icon> Cerrar sesión
    </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'IntranetLogout',
    methods: {
        cerrarSesion () {
            this.$store.dispatch('user/userLogout', null, { root: true })
        }
    },
    computed: {
        ...mapState('user', [ 'sessionStatus' ])
    },

    watch: {
        sessionStatus () {
            if (this.sessionStatus && this.sessionStatus == 'logout') {
                this.$router.push({ name : 'login' }).catch(() => {});
            }
        }
    }
}
</script>