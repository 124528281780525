<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <div v-if="sponsorLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="5">
                    </pulse-loader>
                </div>

                <div v-else>
                    <intranet-nav-content-link-exact :link="{ name: `${sponsor.name}`, path: { name: 'admin-sponsor-jobs-offer-list' , params : { 'IdSponsor' : sponsor.id }} }"></intranet-nav-content-link-exact>
                </div>
            </div>
        </b-col>

        <b-col>
            <slide-y-down-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>
        </b-col>
    </b-row>

</template>

<script>
import { getSponsor } from '@/api/Sponsor/Sponsor'
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideYDownTransition  } from 'vue2-transitions';

export default {
    name: 'SponsorJobOffer',
    components: { 
        IntranetNavContentLinkExact,
        SlideYDownTransition,
    },
    data: () => ({
        sponsor: {},
        sponsorLoading: false
    }),
    created () {
        this.getData()
    },
    methods: {
        getData () {
            this.sponsorLoading = true

            let IdSponsor = this.$route.params.IdSponsor

            getSponsor(IdSponsor)
                .then(({data}) => {
                    this.sponsor = data.data.attributes
                })
                .catch(err => {
                    console.log('get-Sponsor err', err);
                })
                .finally(() => {
                    this.sponsorLoading = false
                    console.log('request get-Sponsor end');
                })
        }
    }
};
</script>