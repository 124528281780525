import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

// //get
// function getContract (pIdContract) {
//     const URI = `${BASE_URL}/api/contracts/${pIdContract}`
//     return axios.get(URI, { headers })
// }

//post
function storeContractPayment (pFormData) {
    const URI = `${BASE_URL}/api/contractPayments`
    return axios.post(URI, pFormData, { headers })
}
// put
function updateContractPayment (pFormData, pIdContractPayment) {
    const URI = `${BASE_URL}/api/contractPayments/${pIdContractPayment}`
    return axios.post(URI, pFormData, { headers })
}

//get
function destroyContractPayment (pIdContractPayment) {
    const URI = `${BASE_URL}/api/contractPayments/${pIdContractPayment}`
    return axios.delete(URI, { headers })
}

export {
    // storeContract,
    storeContractPayment,
    updateContractPayment,
    destroyContractPayment
}