import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function seasonProgramSeasonPostulantSearch (pIdSeason, pFormData, pPage) {
    const URI = `${BASE_URL}/api/season/${pIdSeason}/programSeasonPostulant-search?page=${pPage}`
    return post(URI, pFormData, { headers })
}

export { seasonProgramSeasonPostulantSearch }