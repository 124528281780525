import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function downloadParticipantDocument (pIdParticipantDocument) {
    const URI = `${BASE_URL}/api/participantDocument/${pIdParticipantDocument}/download`
    const responseType = 'blob'
    return axios.get(URI, { headers, responseType })
}

export { downloadParticipantDocument }