import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getInvalidVouchers () {
    const URI = `${BASE_URL}/api/InvalidVouchers`
    return axios.get(URI, { headers })
}

function storeInvalidVoucher (pFormdata) {
    const URI = `${BASE_URL}/api/InvalidVouchers`
    return axios.post(URI, pFormdata, { headers })
}

export {
    getInvalidVouchers,
    storeInvalidVoucher
}