<template>
    <b-row>
        <b-col>
            <b-card class="shadow">
                <b-card-header class="border-0 px-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-uppercase" :class="isLayoutSelected == 'si' ? 'text-primary' : 'text-contract-1'">
                                {{ isLayoutSelected == 'si' ? 'Nuevo layout' : 'Seleccionar una base:'}}
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="getData"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button size="sm" variant="contract-1" @click="resetLayout" v-show="isLayoutSelected == 'si'" title="Seleccionar otra base."><b-icon icon="chevron-left"></b-icon></b-button>
                            <b-button size="sm" variant="contract-1" @click="reloadData" v-show="isLayoutSelected == 'no'"><b-icon icon="arrow-counterclockwise"></b-icon></b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- layout cards -->
                <transition name="slide-fade">
                <b-container v-if="isLayoutSelected == 'no'">
                    <b-row class="border border-contract-1 mb-5" style="border-radius:5px">
                        <!-- loading -->
                        <b-col v-show="dataLoading">
                            <div style="height:175px;">
                                    <pulse-loader 
                                    :color="'#1572A1'"
                                    :margin="'10px'"
                                    :size="16"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <!-- cards -->
                        <b-col cols="12" style="display:inline-flex; overflow:auto" v-show="!dataLoading">
                            <div style="display:inline-flex;" class="my-3">
                                <b-card class="layout-card" no-body @click="setLayout(blank_document)">
                                    <svg class="layout-card--icon" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                    </svg>
                                    <span class="layout-card--name">Documento <br> en blanco</span>
                                </b-card>

                                <b-card class="layout-card" no-body v-for="(layout, i) in data" :key="`${i}-contract-layout`" @click="setLayout(layout)">
                                    <svg class="layout-card--icon" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                    </svg>
                                    <span class="layout-card--name">{{ layout.attributes.name }}</span>
                                </b-card>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                </transition>

                <!-- form -->
                <transition name="slide-fade">
                <b-form @submit.prevent="getCurrentEditorOutput" v-if="isLayoutSelected == 'si'">
                      <b-row align-v="center" align-h="center" class="my-2">
                        <b-col cols="12">
                            <b-form-row>
                                <!-- name -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label="Nombre:"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el nombre del layout."
                                            v-model="name"
                                            :state="showInputStatus('name')"
                                            v-show="!createLoading"
                                            spellcheck="fale"
                                            autocomplete="off"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('name')"
                                            :key="`${i}-create-layout-name`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- name -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label="Temporada: "
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select
                                            class="text-center"
                                            size="sm"
                                            v-model="seasonSelected"
                                            :options="seasonOptions"
                                            v-show="!createLoading"
                                            :state="showInputStatus('season_id')"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('season_id')"
                                            :key="`${i}-create-layout-season_id`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label="Descripción:"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea 
                                            placeholder="Ingrese una descripción (opcional)"
                                            v-model="description"
                                            :state="showInputStatus('description')"
                                            v-show="!createLoading"
                                            rows="3"
                                            max-rows="6"
                                            spellcheck="false"
                                            autocomplete="off"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('description')"
                                            :key="`${i}-create-layout-description`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <tiptap
                                :getHtml="editorOutputRequest"
                                :initialBodyValue="contractBodyHtmlValue"
                                :initialFooterValue="contractFooterHtmlValue"
                                @output="createLayout" :uiMode="'test'"></tiptap>
                        </b-col>
                    </b-row>

                    <b-row align-v="center" align-h="center" class="mt-5">
                        <b-col cols="auto">
                            <b-button variant="primary" type="submit">
                                CREAR LAYOUT
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
                </transition>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Tiptap from '@/components/Tiptap/components/Tiptap.vue'
import { getSeasonHtmlLayouts, storeHtmlLayout } from '@/api/HtmlLayout/HtmlLayout'
import { getAllSeasons } from '@/api/Season/Season'
import swal from 'sweetalert'

export default {
    name: 'ContractLayoutCreateComponent',
    components: { Tiptap },
    data: () => ({
        data: {},
        dataLoading: false,
        // blank document
        blank_document: {
            attributes: {
                header: '',
                body: '',
                footer: '',
            }
        },
        isLayoutSelected: 'no',
        // form
        name: '',
        description: '',
        createLoading: false,
        initialInputValues: true,
        inputErrors: {},
        // to tiptap
        editorOutputRequest: 1,
        contractFooterHtmlValue: '',
        contractBodyHtmlValue: '',
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,

    }),
    created() {
        this.getSeasonsData()
    },
    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    this.getData()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getData() {
            this.dataLoading = true
            getSeasonHtmlLayouts(this.seasonSelected)
                .then(({ data }) => {
                    this.data = data.data.filter(el => el.attributes.type == 'contract')
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },
        //
        setLayout(pLayout) {
            this.contractBodyHtmlValue = pLayout.attributes.body
            this.contractFooterHtmlValue = pLayout.attributes.footer
            this.isLayoutSelected = 'si'
        },
        resetLayout() {
            swal('Se perderá el avance realizado.', '¿Desea seleccionar otra base?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, Seleccionar otra base']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.contractBodyHtmlValue = ''
                            this.contractFooterHtmlValue = ''
                            this.isLayoutSelected = 'no'
                            this.clearInputsAndStates()
                            this.getData()
                            break;
                        case null:
                            break;
                        default:
                            console.log('select other layout');
                            break;
                    }
                })
        },
        //
        getCurrentEditorOutput() {
            this.editorOutputRequest += 1
        },

        createLayout(pOutput) {
            this.createLoading = true
            this.initialInputValues = false

            let LayoutForm = new FormData()
            LayoutForm.append('season_id', this.seasonSelected)
            LayoutForm.append('name', this.name)
            LayoutForm.append('type', 'contract')
            LayoutForm.append('header', '')
            LayoutForm.append('body', pOutput.body)
            LayoutForm.append('footer', pOutput.footer)
            LayoutForm.append('description', this.description)

            storeHtmlLayout(LayoutForm)
                .then(({ data }) => {
                    swal('Creación correcta!', `Layout: ${data.data.attributes.name} creado`, 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'employee-layouts-contracts-list'}) 
                                    break;

                                default:
                                    console.log('createContract--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })

        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
            //
            this.name = ''
            this.description = ''
        },

        reloadData() {
            this.getData()
        }
    }
}
</script>

<style scoped>
.layout-card {
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;
    padding: 8px 10px ;
    margin-right: 15px;
    border-radius: 5px;
    border: 2px dashed #1572A1;
    color: #1572A1;
    cursor: pointer;
    background: rgba(94, 114, 228, 0.0);
    background: rgba(21, 114, 161, 0.0);
    transition: all 360ms ease-in-out;
}
.layout-card:hover {
    background: rgba(21, 114, 161, 0.1);
}
.layout-card--icon {
    margin-bottom: 10px;
}
.layout-card--name {
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
/* .slide-fade-leave-active {
  transition: all .5s ease;
} */
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
</style>

