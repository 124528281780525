import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

// get
function getEnglishInterviewSchedule (pEnglishInterviewSchedule) {
    const URI = `${BASE_URL}/api/englishInterviewSchedules/${pEnglishInterviewSchedule}`
    return axios.get(URI, { headers })
}

// post
function storeEnglishInterviewSchedule (pFormData) {
    const URI = `${BASE_URL}/api/englishInterviewSchedules`
    return axios.post(URI, pFormData, { headers })
}

// put
function updateEnglishInterviewSchedule (pFormData, pIdEnglishInterviewSchedule) {
    const URI = `${BASE_URL}/api/englishInterviewSchedules/${pIdEnglishInterviewSchedule}`
    return axios.post(URI, pFormData, { headers })
}

// delete
function destroyEnglishInterviewSchedule (pIdEnglishInterviewSchedule) {
    const URI = `${BASE_URL}/api/englishInterviewSchedules/${pIdEnglishInterviewSchedule}`
    return axios.delete(URI, { headers })
}

export {
    getEnglishInterviewSchedule,
    storeEnglishInterviewSchedule,
    updateEnglishInterviewSchedule,
    destroyEnglishInterviewSchedule,
}