import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//get
function getParticipant (pAdminId) {
    const URI = `${BASE_URL}/api/participants/${pAdminId}`
    return get(URI)
}
function getParticipants (pPage) {
    const URI = `${BASE_URL}/api/participants?page=${pPage}`
    return get(URI)
}

export {
    getParticipant,
    getParticipants,
}