import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//get
function getUserUserRoles (pUserId) {
    const URI = `${BASE_URL}/api/users/${pUserId}/userRoles`
    return get(URI)
}

//post-put
function updateUserUserRoleStatus (pUserId, pUserRole, pFormData) {
    const URI = `${BASE_URL}/api/users/${pUserId}/userRoles/${pUserRole}`
    return post(URI, pFormData)
}

// function storeEmployee (pFormData) {
//     const URI = `${BASE_URL}/api/employees`
//     return post(URI, pFormData)
// }

export {
    getUserUserRoles,
    updateUserUserRoleStatus
}