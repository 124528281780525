import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getSponsorJobOffer (IdSponsorJobOffer) {
    const URI = `${BASE_URL}/api/sponsorJobOffers/${IdSponsorJobOffer}`
    return axios.get(URI, { headers })
}

function getSponsorJobOffers () {
    const URI = `${BASE_URL}/api/sponsorJobOffers`
    return axios.get(URI, { headers })
}

function storeSponsorJobOffer (pFormData) {
    const URI = `${BASE_URL}/api/sponsorJobOffers`
    return axios.post(URI, pFormData, { headers })
}

function destroySponsorJobOffer (IdSponsorJobOffer) {
    const URI = `${BASE_URL}/api/sponsorJobOffers/${IdSponsorJobOffer}`
    return axios.delete(URI, { headers })
}

export {
    getSponsorJobOffer,
    getSponsorJobOffers,
    storeSponsorJobOffer,
    destroySponsorJobOffer
}