<template>
    <search-user-component
        :routeToPostulantsDetails="'admin-postulants-detail'"
        :routeToParticipantsDetails="'admin-participants-detail'">
    </search-user-component>
</template>

<script>
import SearchUserComponent from '@/components/Admin-Employee/Postulant-Participant/Search/SearchUserComponent.vue'

export default {
    name: 'SearchUser',
    components: { SearchUserComponent },
    data: () => ({})
}
</script>