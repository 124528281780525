import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getGeneralJobInputs () {
    const URI = `${BASE_URL}/api/generalJobInputs`
    return axios.get(URI, { headers })
}

function storeGeneralJobInput (pFormData) {
    const URI = `${BASE_URL}/api/generalJobInputs`
    return axios.post(URI, pFormData, { headers })
}

function destroyGeneralJobInput (pIdGeneralJobInput) {
    const URI = `${BASE_URL}/api/generalJobInputs/${pIdGeneralJobInput}`
    return axios.delete(URI, { headers })
}

export {
    getGeneralJobInputs,
    storeGeneralJobInput,
    destroyGeneralJobInput
}