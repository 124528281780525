<template>
    <contract-layout-create-component></contract-layout-create-component>
</template>

<script>
import ContractLayoutCreateComponent from '@/components/ContractLayout/ContractLayoutCreateComponent.vue'

export default {
    name: 'ContractLayoutCreate',
    components: { ContractLayoutCreateComponent },
    data: () => ({})
}
</script>