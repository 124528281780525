<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                               
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="postulantLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        class="text-center"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <b-container v-show="!postulantLoading" fluid>
                    <b-row>
                        <b-col >
                            <v-card flat>
                                <v-tabs v-model="tab" grow>
                                    <v-tab>Contrato [HTML]</v-tab>
                                    <v-tab>Contrato [PDF]</v-tab>
                                </v-tabs>

                                <v-tabs-items v-model="tab" touchless style="overflow:unset" >
                                    <!-- contract HTML -->
                                    <v-tab-item>
                                        <v-card flat class="mt-3">
                                                <b-row class="align-items-center mb-3">
                                                    <b-col>
                                                        <h3 class="mb-0 text-uppercase" :class="isLayoutSelected == 'si' ? 'text-primary' : 'text-contract-1'">
                                                            {{ isLayoutSelected == 'si' ? '' : 'Seleccionar una plantilla:'}}
                                                        </h3>
                                                    </b-col>

                                                    <b-col cols="auto">
                                                        <b-button size="sm" variant="contract-1" @click="resetLayout" v-show="isLayoutSelected == 'si'" title="Seleccionar otra plantilla."><b-icon icon="chevron-left"></b-icon></b-button>
                                                        <b-button size="sm" variant="contract-1" @click="reloadData" v-show="isLayoutSelected == 'no'"><b-icon icon="arrow-counterclockwise"></b-icon></b-button>
                                                    </b-col>
                                                </b-row>

                                                <!-- layout cards -->
                                                <transition name="slide-fade">
                                                <b-container v-if="isLayoutSelected == 'no'">
                                                    <b-row class="border border-contract-1 mb-5" style="border-radius:5px">
                                                        <!-- loading -->
                                                        <b-col v-show="layoutsLoading">
                                                            <div style="height:175px;">
                                                                    <pulse-loader 
                                                                    :color="'#1572A1'"
                                                                    :margin="'10px'"
                                                                    :size="16"
                                                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                                                            </div>
                                                        </b-col>

                                                        <!-- cards -->
                                                        <b-col cols="auto" v-show="!layoutsLoading" class="text-right mt-3">
                                                            <pulse-loader
                                                                :loading="getSeasonOptionsLoading"
                                                                :color="'#5e72e4'"
                                                                :margin="'5px'"
                                                                :size="8"
                                                                class="pt-2 text-center"
                                                                style="border-radius:5px; border:1px solid #5e72e4"
                                                            />

                                                            <b-form-select
                                                                class="text-center"
                                                                size="sm"
                                                                v-model="seasonSelected"
                                                                :options="seasonOptions"
                                                                v-show="!getSeasonOptionsLoading"
                                                                @change="loadHtmlLayouts"
                                                            />
                                                        </b-col>

                                                        <b-col cols="12" style="display:inline-flex; overflow:auto" v-show="!layoutsLoading">
                                                            <div style="display:inline-flex;" class="my-3">
                                                                <b-card class="layout-card" no-body v-for="(layout, i) in contractLayouts" :key="`${i}-contract-layout`" @click="setLayout(layout)">
                                                                    <svg class="layout-card--icon" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                                                    </svg>
                                                                    <span class="layout-card--name">{{ layout.attributes.name }}</span>
                                                                </b-card>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-container>
                                                </transition>

                                                <!-- form -->
                                                <transition name="slide-fade">
                                                <b-form @submit.prevent="getCurrentEditorOutput" v-if="isLayoutSelected == 'si'">
                                                    <b-row>
                                                        <b-col>
                                                            <tiptap
                                                                :getHtml="editorOutputRequest"
                                                                :initialBodyValue="contractBodyHtmlValue"
                                                                :initialFooterValue="contractFooterHtmlValue"
                                                                @output="createContract" :uiMode="'employee'"></tiptap>
                                                        </b-col>
                                                    </b-row>

                                                    <b-row class="mt-8">
                                                        <b-col cols="12" md="6">
                                                            <!-- expiration_date -->
                                                            <b-form-row>
                                                                <b-col cols="12">
                                                                    <b-form-group
                                                                        label="Expiración de contracto :"
                                                                    >
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            v-model="expiration_date"
                                                                            :state="showInputStatus('expiration_date')"
                                                                            v-if="!createContractLoading"
                                                                        />

                                                                        <b-form-invalid-feedback
                                                                            v-for="(inputError, i) in showInputErrors('expiration_date')"
                                                                            :key="`${i}-contract-expiration_date`"
                                                                        >
                                                                            {{ inputError }}
                                                                        </b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- description -->
                                                            <b-form-row>
                                                                <b-col cols="12">
                                                                    <b-form-group
                                                                        label="Descripción:" 
                                                                    >
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-textarea 
                                                                            placeholder="Ingrese una descripción (opcional)"
                                                                            v-model="description"
                                                                            :state="showInputStatus('description')"
                                                                            v-show="!createContractLoading"
                                                                            rows="2"
                                                                            max-rows="6"
                                                                        />

                                                                        <b-form-invalid-feedback
                                                                            v-for="(inputError, i) in showInputErrors('description')"
                                                                            :key="`${i}-create-contract-description`"
                                                                        >
                                                                            {{ inputError }}
                                                                        </b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>
                                                        </b-col>

                                                        <b-col cols="12" md="6">
                                                            <!-- total cost -->
                                                            <b-form-row>
                                                                <b-col cols="12">
                                                                    <b-form-group label="Costo">
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-radio-group
                                                                            required
                                                                            v-model="total_cost"
                                                                            class="mt-3 mb-1"
                                                                            v-if="!createContractLoading"
                                                                            stacked
                                                                            plain
                                                                            >
                                                                            <b-form-radio
                                                                                :value="programSeasonPostulant.program_full"
                                                                                >FULL P. : {{ programSeasonPostulant.program_full_formated }}</b-form-radio>
                                                                            <b-form-radio
                                                                                v-show="programSeasonPostulant.program_self"
                                                                                :value="programSeasonPostulant.program_self"
                                                                                >SELF P. : {{ programSeasonPostulant.program_self_formated }}</b-form-radio>
                                                                            <b-form-radio
                                                                                v-for="(cost, i) in programSeasonPostulant.costs" :key="`css-${i}`"
                                                                                :value="cost.attributes.amount"
                                                                                >{{ cost.attributes.name }} : {{ cost.attributes.amount_formated }}</b-form-radio>
                                                                        </b-form-radio-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- num coutas -->
                                                            <b-form-row class="mt-2">
                                                                <b-col>
                                                                    <b-form-group>
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-input-group class="border border-primary" v-show="!createContractLoading">
                                                                            <b-input-group-prepend>
                                                                                <b-input-group-text class="text-primary"> Número de cuotas : </b-input-group-text>
                                                                            </b-input-group-prepend>

                                                                            <b-form-input type="number" min="1" step="1" max="5" autocomplete="off"
                                                                            class="text-center text-magenta" v-model="numCuotas" number/>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- coutas -->
                                                            <b-form-row class="mt-3">
                                                                <b-col
                                                                    cols="12"
                                                                    class="mb-1"
                                                                    v-for="(couta, i) in numCuotas"
                                                                    :key="`${i}-cuota`"
                                                                >
                                                                    <pulse-loader
                                                                        :loading="createContractLoading"
                                                                        :color="'#5e72e4'"
                                                                        :margin="'5px'"
                                                                        :size="10"
                                                                        class="text-center pt-2 pb-1 pl-2"
                                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                                    />

                                                                    <b-input-group v-show="!createContractLoading" style="min-width:max-content">
                                                                        <b-input-group-prepend>
                                                                            <b-input-group-text v-if="(i + 1) == 1"><span class="d-none d-lg-block">Primera Cuota </span> <span class="d-block d-lg-none"> 1</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 2"><span class="d-none d-lg-block">Segunda Cuota </span> <span class="d-block d-lg-none"> 2</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 3"><span class="d-none d-lg-block">Tercera Cuota </span> <span class="d-block d-lg-none"> 3</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 4"><span class="d-none d-lg-block">cuarta Cuota </span> <span class="d-block d-lg-none"> 4</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 5"><span class="d-none d-lg-block">Quinta Cuota </span> <span class="d-block d-lg-none"> 5</span>:</b-input-group-text>
                                                                        </b-input-group-prepend>

                                                                        <!-- amount -->
                                                                        <b-form-input v-model="cuota_1_amount" v-if="(i + 1) == 1" :state="showInputStatus('cuota_1_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_2_amount" v-if="(i + 1) == 2" :state="showInputStatus('cuota_2_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_3_amount" v-if="(i + 1) == 3" :state="showInputStatus('cuota_3_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_4_amount" v-if="(i + 1) == 4" :state="showInputStatus('cuota_4_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_5_amount" v-if="(i + 1) == 5" :state="showInputStatus('cuota_5_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>

                                                                        <!-- expiration_date * at -->
                                                                        <b-form-datepicker
                                                                                required
                                                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                                selected-variant="primary"
                                                                                today-variant="naranja"
                                                                                locale="es"

                                                                                today-button
                                                                                label-today-button="today"
                                                                                today-button-variant="outline-naranja"

                                                                                reset-button
                                                                                label-reset-button="reset"
                                                                                reset-button-variant="outline-magenta"

                                                                                close-button
                                                                                label-close-button="ok"
                                                                                close-button-variant="primary"

                                                                                placeholder="Fecha"
                                                                                style="width:min-content"

                                                                                v-model="cuota_1_expiration_date"
                                                                                v-if="!createContractLoading && (i + 1) == 1"
                                                                                :state="showInputStatus(`cuota_1_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_2_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 2"
                                                                            :state="showInputStatus(`cuota_2_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_3_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 3"
                                                                            :state="showInputStatus(`cuota_3_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_4_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 4"
                                                                            :state="showInputStatus(`cuota_4_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_5_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 5"
                                                                            :state="showInputStatus(`cuota_5_expiration_date`)"
                                                                        />
                                                                    </b-input-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <b-row align-v="center" align-h="center" class="my-5">
                                                                <b-col cols="auto">
                                                                    <b-button variant="primary" type="submit">
                                                                        REGISTRAR
                                                                    </b-button>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-form>
                                                </transition>
                                        </v-card>
                                    </v-tab-item>

                                    <!-- contract PDF -->
                                    <v-tab-item>
                                        <v-card flat>
                                                <b-form @submit.prevent="createContract">
                                                    <b-row class="mt-8">
                                                        <b-col cols="12" md="6">
                                                            <!-- generated_file -->
                                                            <b-form-row>
                                                                <b-col>
                                                                    <b-form-group
                                                                        label="Contrato [PDF]:" 
                                                                    >
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-file
                                                                            v-model="generated_file"
                                                                            :state="showInputStatus('generated_file')"
                                                                            v-show="!createContractLoading"
                                                                            style="overflow:hidden;"
                                                                        />

                                                                        <b-form-invalid-feedback
                                                                            v-for="(inputError, i) in showInputErrors('generated_file')"
                                                                            :key="`${i}-create-contract-generated_file`"
                                                                        >
                                                                            {{ inputError }}
                                                                        </b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- expiration_date -->
                                                            <b-form-row>
                                                                <b-col cols="12">
                                                                    <b-form-group
                                                                        label="Expiración de contracto :"
                                                                    >
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            v-model="expiration_date"
                                                                            :state="showInputStatus('expiration_date')"
                                                                            v-if="!createContractLoading"
                                                                        />

                                                                        <b-form-invalid-feedback
                                                                            v-for="(inputError, i) in showInputErrors('expiration_date')"
                                                                            :key="`${i}-contract-expiration_date`"
                                                                        >
                                                                            {{ inputError }}
                                                                        </b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- description -->
                                                            <b-form-row>
                                                                <b-col cols="12">
                                                                    <b-form-group
                                                                        label="Descripción:" 
                                                                    >
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-textarea 
                                                                            placeholder="Ingrese una descripción (opcional)"
                                                                            v-model="description"
                                                                            :state="showInputStatus('description')"
                                                                            v-show="!createContractLoading"
                                                                            rows="2"
                                                                            max-rows="6"
                                                                        />

                                                                        <b-form-invalid-feedback
                                                                            v-for="(inputError, i) in showInputErrors('description')"
                                                                            :key="`${i}-create-contract-description`"
                                                                        >
                                                                            {{ inputError }}
                                                                        </b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>
                                                        </b-col>

                                                        <b-col cols="12" md="6">
                                                            <!-- total cost -->
                                                            <b-form-row>
                                                                <b-col cols="12">
                                                                    <b-form-group label="Costo">
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-form-radio-group
                                                                            required
                                                                            v-model="total_cost"
                                                                            class="mt-3 mb-1"
                                                                            v-if="!createContractLoading"
                                                                            stacked
                                                                            plain
                                                                            >
                                                                            <b-form-radio
                                                                                :value="programSeasonPostulant.program_full"
                                                                                >FULL P. : {{ programSeasonPostulant.program_full_formated }}</b-form-radio>
                                                                            <b-form-radio
                                                                                v-show="programSeasonPostulant.program_self"
                                                                                :value="programSeasonPostulant.program_self"
                                                                                >SELF P. : {{ programSeasonPostulant.program_self_formated }}</b-form-radio>
                                                                            <b-form-radio
                                                                                v-for="(cost, i) in programSeasonPostulant.costs" :key="`cs-${i}`"
                                                                                :value="cost.attributes.amount"
                                                                                >{{ cost.attributes.name }} : {{ cost.attributes.amount_formated }}</b-form-radio>
                                                                        </b-form-radio-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- num coutas -->
                                                            <b-form-row class="mt-2">
                                                                <b-col>
                                                                    <b-form-group>
                                                                        <pulse-loader
                                                                            :loading="createContractLoading"
                                                                            :color="'#5e72e4'"
                                                                            :margin="'5px'"
                                                                            :size="10"
                                                                            class="text-center pt-2 pb-1 pl-2"
                                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                                        />

                                                                        <b-input-group class="border border-primary" v-show="!createContractLoading">
                                                                            <b-input-group-prepend>
                                                                                <b-input-group-text class="text-primary"> Número de cuotas : </b-input-group-text>
                                                                            </b-input-group-prepend>

                                                                            <b-form-input type="number" min="1" step="1" max="5" autocomplete="off"
                                                                            class="text-center text-magenta" v-model="numCuotas" number/>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <!-- coutas -->
                                                            <b-form-row class="mt-3">
                                                                <b-col
                                                                    cols="12"
                                                                    class="mb-1"
                                                                    v-for="(couta, i) in numCuotas"
                                                                    :key="`${i}-cuota`"
                                                                >
                                                                    <pulse-loader
                                                                        :loading="createContractLoading"
                                                                        :color="'#5e72e4'"
                                                                        :margin="'5px'"
                                                                        :size="10"
                                                                        class="text-center pt-2 pb-1 pl-2"
                                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                                    />

                                                                    <b-input-group v-show="!createContractLoading" style="min-width:max-content">
                                                                        <b-input-group-prepend>
                                                                           <b-input-group-text v-if="(i + 1) == 1"><span class="d-none d-lg-block">Primera Cuota </span> <span class="d-block d-lg-none"> 1</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 2"><span class="d-none d-lg-block">Segunda Cuota </span> <span class="d-block d-lg-none"> 2</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 3"><span class="d-none d-lg-block">Tercera Cuota </span> <span class="d-block d-lg-none"> 3</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 4"><span class="d-none d-lg-block">cuarta Cuota </span> <span class="d-block d-lg-none"> 4</span>:</b-input-group-text>
                                                                            <b-input-group-text v-if="(i + 1) == 5"><span class="d-none d-lg-block">Quinta Cuota </span> <span class="d-block d-lg-none"> 5</span>:</b-input-group-text>
                                                                        </b-input-group-prepend>

                                                                        <!-- amount -->
                                                                        <b-form-input v-model="cuota_1_amount" v-if="(i + 1) == 1" :state="showInputStatus('cuota_1_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_2_amount" v-if="(i + 1) == 2" :state="showInputStatus('cuota_2_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_3_amount" v-if="(i + 1) == 3" :state="showInputStatus('cuota_3_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_4_amount" v-if="(i + 1) == 4" :state="showInputStatus('cuota_4_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>
                                                                        <b-form-input v-model="cuota_5_amount" v-if="(i + 1) == 5" :state="showInputStatus('cuota_5_amount')" number type="number" min="0.00" step="0.01" class="text-center" style="max-width:100px"/>

                                                                        <!-- expiration_date * at -->
                                                                        <b-form-datepicker
                                                                                required
                                                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                                selected-variant="primary"
                                                                                today-variant="naranja"
                                                                                locale="es"

                                                                                today-button
                                                                                label-today-button="today"
                                                                                today-button-variant="outline-naranja"

                                                                                reset-button
                                                                                label-reset-button="reset"
                                                                                reset-button-variant="outline-magenta"

                                                                                close-button
                                                                                label-close-button="ok"
                                                                                close-button-variant="primary"

                                                                                placeholder="Fecha"
                                                                                style="width:min-content"

                                                                                v-model="cuota_1_expiration_date"
                                                                                v-if="!createContractLoading && (i + 1) == 1"
                                                                                :state="showInputStatus(`cuota_1_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_2_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 2"
                                                                            :state="showInputStatus(`cuota_2_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_3_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 3"
                                                                            :state="showInputStatus(`cuota_3_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_4_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 4"
                                                                            :state="showInputStatus(`cuota_4_expiration_date`)"
                                                                        />

                                                                        <b-form-datepicker
                                                                            required
                                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                            selected-variant="primary"
                                                                            today-variant="naranja"
                                                                            locale="es"

                                                                            today-button
                                                                            label-today-button="today"
                                                                            today-button-variant="outline-naranja"
                                                                            
                                                                            reset-button
                                                                            label-reset-button="reset"
                                                                            reset-button-variant="outline-magenta"

                                                                            close-button
                                                                            label-close-button="ok"
                                                                            close-button-variant="primary"

                                                                            placeholder="Fecha"
                                                                            style="width:min-content"

                                                                            v-model="cuota_5_expiration_date"
                                                                            v-if="!createContractLoading && (i + 1) == 5"
                                                                            :state="showInputStatus(`cuota_5_expiration_date`)"
                                                                        />
                                                                    </b-input-group>
                                                                </b-col>
                                                            </b-form-row>

                                                            <b-row align-v="center" align-h="center" class="my-5">
                                                                <b-col cols="auto">
                                                                    <b-button variant="primary" type="submit">
                                                                        REGISTRAR
                                                                    </b-button>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-form>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card>
                           
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant'
import { storeContract } from '@/api/Contract/Contract'
import { getSeasonHtmlLayouts } from '@/api/HtmlLayout/HtmlLayout'
import { getAllSeasons } from '@/api/Season/Season'
import Tiptap from '@/components/Tiptap/components/Tiptap.vue'
import swal from 'sweetalert'

export default {
    name: "ContractCreateComponent",
    props: {
        routeNameToContractsList: {
            type: String,
            required: true
        },
        advisor: {
            type: String,
            required: true
        }
    },
    components: { Tiptap },
    data: () => ({
        // programSeasonPostulant
        programSeasonPostulant: {},
        postulantLoading: false,
        //
        contractLayouts: {},
        layoutsLoading: false,
        isLayoutSelected: 'no',
        //
        tab: null,
        // FORM
        // culturalAdvisor_id,
        // programSeasonPostulant_id,
        // programSeasonParticipant_id,
        total_cost: '', /**hacer peticion del program seaosnm para sacar los costos */
        generated_file: null,
        expiration_date: '',
        description: '',
        //
        numCuotas: 1,
        cuota_1_expiration_date: '',
        cuota_1_amount: 0,
        cuota_2_expiration_date: '',
        cuota_2_amount: 0,
        cuota_3_expiration_date: '',
        cuota_3_amount: 0,
        cuota_4_expiration_date: '',
        cuota_4_amount: 0,
        cuota_5_expiration_date: '',
        cuota_5_amount: 0,
        //
        inputErrors: {},
        initialInputValues: true,
        createContractLoading: false,
        //
        contractBodyHtmlValue: '',
        contractFooterHtmlValue: '',
        editorOutputRequest: 1,
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
    }),

    created() {
        this.getSeasonsData()
    },

    mounted() {
        this.tab = 0
    },

    watch: {
        tab() {
            this.initialInputValues = true
            this.inputErrors = {}
        }
    },

    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    this.getData()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getData () {
            // programSeasonPostulant
            this.postulantLoading = true
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok
            getProgramSeasonPostulant(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.programSeasonPostulant = data.data.attributes

                })
                .finally(() => {
                    this.postulantLoading = false
                })

            // htmlLayouts
            this.loadHtmlLayouts()
        },

        loadHtmlLayouts () {
            // htmlLayouts
            this.layoutsLoading = true
            getSeasonHtmlLayouts(this.seasonSelected)
                .then(({ data }) => {
                    this.contractLayouts = data.data.filter(el => el.attributes.type == 'contract')
                })
                .finally(() => {
                    this.layoutsLoading = false
                })
        },

        setLayout(pLayout) {
            this.contractBodyHtmlValue = pLayout.attributes.body
            this.contractFooterHtmlValue = pLayout.attributes.footer
            this.isLayoutSelected = 'si'
        },
        resetLayout() {
            swal('Se perderá el avance realizado.', '¿Desea seleccionar otra plantilla?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, Seleccionar otra plantilla']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.isLayoutSelected = 'no'
                            this.clearInputsAndStates()
                            this.getData()
                            break;
                        case null:
                            break;
                        default:
                            console.log('select other layout');
                            break;
                    }
                })
        },

        closeCreateModal () {
            this.initialInputValues = true
            this.inputErrors = {}
        },

        getCurrentEditorOutput() {
            this.editorOutputRequest += 1
        },

        createContract (pOutput = {}) {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createContractLoading = true
            //
            let IdSalesAdvisor = this.programSeasonPostulant.salesAdvisor_id
            let IdCulturalAdvisor = this.programSeasonPostulant.culturalAdvisor_id || this.programSeasonPostulant.culturalAdvisorAssigned_id      // ok
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant          // ok

            let ContractForm = new FormData()

            if(this.advisor == 'SA') {
                ContractForm.append('salesAdvisor_id', IdSalesAdvisor)
                ContractForm.append('culturalAdvisor_id', '')
            } else if (this.advisor == 'CA') {
                ContractForm.append('salesAdvisor_id', '')
                ContractForm.append('culturalAdvisor_id', IdCulturalAdvisor)
            }

            ContractForm.append('programSeasonPostulant_id', IdProgramSeasonPostulant)
            // ContractForm.append('programSeasonParticipant_id', IdProgramSeasonParticipant)  // nullable - se asigna despues al pagar primera cuota
            ContractForm.append('total_cost', this.total_cost)
            ContractForm.append('expiration_date', this.expiration_date)
            ContractForm.append('type', this.tab == 0 ? 'html' : 'pdf')
            ContractForm.append('creator', this.$store.state.user.attributes.full_name)
            ContractForm.append('description', this.description) 
            /* contract-vouchers */
            ContractForm.append('num_cuotas', this.numCuotas)
            ContractForm.append('cuota_1_expiration_date', this.cuota_1_expiration_date)
            if (this.cuota_1_amount != 0) ContractForm.append('cuota_1_amount', this.cuota_1_amount)
            if (this.numCuotas >= 2) {
                ContractForm.append('cuota_2_expiration_date', this.cuota_2_expiration_date)
                if (this.cuota_2_amount != 0) ContractForm.append('cuota_2_amount', this.cuota_2_amount)

                if (this.numCuotas >= 3) {
                    ContractForm.append('cuota_3_expiration_date', this.cuota_3_expiration_date)
                    if (this.cuota_3_amount != 0) ContractForm.append('cuota_3_amount', this.cuota_3_amount)

                    if (this.numCuotas >= 4) {
                        ContractForm.append('cuota_4_expiration_date', this.cuota_4_expiration_date)
                        if (this.cuota_4_amount != 0) ContractForm.append('cuota_4_amount', this.cuota_4_amount)

                        if(this.numCuotas == 5) {
                            ContractForm.append('cuota_5_expiration_date', this.cuota_5_expiration_date)
                            if (this.cuota_5_amount != 0) ContractForm.append('cuota_5_amount', this.cuota_5_amount)
                        }
                    }
                }
            }
            /* html-contract */
            if (this.tab == 0) {
                ContractForm.append('body' , pOutput.body)
                ContractForm.append('footer' , pOutput.footer)
            }
            if (this.tab == 1) {
                ContractForm.append('generated_file', this.generated_file == null ? '' : this.generated_file)
            }


            storeContract(ContractForm)
                .then(() => {
                    swal('Creación correcta!', 'Contrato creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: this.routeNameToContractsList}) 
                                    break;

                                default:
                                    console.log('createContract--swal-break');
                                break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createContractLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
            //
            this.total_cost = ''
            this.generated_file = null
            this.expiration_date = ''
            this.description = ''
            //
            this.numCuotas = 1
            this.cuota_1_amount = 0
            this.cuota_1_expiration_date = ''

            this.cuota_2_amount = 0
            this.cuota_2_expiration_date = ''

            this.cuota_3_amount = 0
            this.cuota_3_expiration_date = ''

            this.cuota_4_amount = 0
            this.cuota_4_expiration_date = ''

            this.cuota_5_amount = 0
            this.cuota_5_expiration_date = ''
            //
            this.contractBodyHtmlValue = ''
            this.contractFooterHtmlValue = ''
        },

        reloadData () {
            this.getSeasonsData()
        }
    }
}
</script>

<style scoped>
.layout-card {
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;
    padding: 8px 10px ;
    margin-right: 15px;
    border-radius: 5px;
    border: 2px dashed #1572A1;
    color: #1572A1;
    cursor: pointer;
    background: rgba(94, 114, 228, 0.0);
    background: rgba(21, 114, 161, 0.0);
    transition: all 360ms ease-in-out;
}
.layout-card:hover {
    background: rgba(21, 114, 161, 0.1);
}
.layout-card--icon {
    margin-bottom: 10px;
}
.layout-card--name {
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
/* .slide-fade-leave-active {
  transition: all .5s ease;
} */
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
</style>