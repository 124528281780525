<template>
    <b-row >
        <!-- EnglishInterviewSchedule details -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles de la Entrevista
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonPostulant -->
                <div style="height:300px;" v-show="getEnglishInterviewLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- programSeasonPostulant details -->
                <div v-show="!getEnglishInterviewLoading" class="mt-8">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col>Estado de la entrevista</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col :class="englishInterviewSchedule.attributes.schedule_status == 'pendiente' ? 'text-yellow' : 'text-primary'">
                                    {{ englishInterviewSchedule.attributes.schedule_status }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Link</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <b-btn
                                        :href="englishInterviewSchedule.attributes.link" target="_blanck"
                                        size="sm"
                                        variant="outline-primary"
                                    ><b-icon icon="link"></b-icon> url 
                                    </b-btn>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Fecha</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    {{ englishInterviewSchedule.attributes.start_date }}
                                    </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Hora</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.start_time }} a {{ englishInterviewSchedule.attributes.end_time }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <b-list-group v-if="englishInterviewSchedule.attributes.postulant_status" class="mt-5">
                        <b-list-group-item variant="primary">
                            <b-row>
                                <b-col class="text-upper text-center">Resultados</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Postulante</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.postulant_status }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Resultado de la entrevista</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <b-iconstack font-scale="1.3" class="mr-3">
                                        <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon stacked scale="1.6"
                                            v-if="englishInterviewSchedule.attributes.interview_status"
                                            :icon="englishInterviewSchedule.attributes.interview_status == 'aprobado' ? 'check' : 'x'"
                                            :variant="englishInterviewSchedule.attributes.interview_status == 'aprobado' ? 'green' : 'danger'"
                                        ></b-icon>
                                        <b-icon stacked icon="square-fill" scale="1.1" variant="lighter" v-else></b-icon>
                                    </b-iconstack>

                                    <span :class="englishInterviewSchedule.attributes.interview_status == 'aprobado' ? 'text-green' : 'text-danger'">
                                        {{ englishInterviewSchedule.attributes.interview_status }}
                                    </span>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                            <b-row>
                                <b-col>Listening</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>{{ englishInterviewSchedule.attributes.listening_nivel }}</div>
                                    <div>{{ englishInterviewSchedule.attributes.listening_comment }}</div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                            <b-row>
                                <b-col>Speaking</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>{{ englishInterviewSchedule.attributes.speaking_nivel }}</div>
                                    <div>{{ englishInterviewSchedule.attributes.speaking_comment }}</div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                            <b-row>
                                <b-col>Vocabulary</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>{{ englishInterviewSchedule.attributes.vocabulary_nivel }}</div>
                                    <div>{{ englishInterviewSchedule.attributes.vocabulary_comment }}</div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.interview_details">
                            <b-row>
                                <b-col>Comentarios</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.interview_details }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.interview_observations">
                            <b-row>
                                <b-col>Observaciones</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.interview_observations }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>

                <b-row align-h="center" class="mt-8 mb-4" v-show="!getEnglishInterviewLoading">
                    <b-col cols="12" md="auto">
                        <b-button size="lg" class="w-100" variant="primary"
                            @click="openModalShedule"
                        >EDITAR HORARIO</b-button>
                    </b-col>

                    <b-col cols="12" md="auto">
                        <b-button size="lg" class="w-100" variant="primary"
                            @click="openModalResult"
                        >{{ englishInterviewSchedule.attributes.schedule_status == 'pendiente' ? 'REGISTRAR RESULTADOS' : 'ACTUALIZAR RESULTADOS' }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>

            <!-- 
                MODAL: SET-RESULTS
             -->
            <b-modal
                id="modal-setResults"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="lg"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0">Registar resultados de entrevista</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalResult"
                                >
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <b-form @submit.prevent="registerResult">
                            <!-- schedule_status: default 'culminado' -->

                            <!-- postulant_status -->
                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Asistencia :">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-radio-group
                                            plain
                                            v-model="englishInterviewSchedule.attributes.postulant_status"
                                            :state="showModalSetResultsInputStatus('postulant_status')"
                                            v-show="!setResultsLoading"
                                            @change="asistenciaChange"
                                        >
                                            <b-form-radio value="asistio">Asistió</b-form-radio>
                                            <b-form-radio value="no asistio">No asistió</b-form-radio>
                                        </b-form-radio-group>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('postulant_status')"
                                            :key="`${i}-createPostulant-description`"
                                            :state="showModalSetResultsInputStatus('postulant_status')"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- interview_status -->
                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Resultado :">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-radio-group
                                            plain
                                            v-model="englishInterviewSchedule.attributes.interview_status"
                                            :state="showModalSetResultsInputStatus('interview_status')"
                                            v-show="!setResultsLoading"
                                        >
                                            <b-form-radio value="aprobado">Aprobado</b-form-radio>
                                            <b-form-radio value="reprobado">Reprobado</b-form-radio>
                                        </b-form-radio-group>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('interview_status')"
                                            :key="`${i}-createPostulant-description`"
                                            :state="showModalSetResultsInputStatus('interview_status')"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- listening -->
                            <b-form-row v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                                <b-col>
                                    <b-form-group label="Listening (nivel):">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2  text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select
                                            :options="englishLevel"
                                            v-model="englishInterviewSchedule.attributes.listening_nivel"
                                            :state="showModalSetResultsInputStatus('listening_nivel')"
                                            v-show="!setResultsLoading" 
                                        >
                                            <template #first>
                                                <b-form-select-option value=null disabled>-- seleciona un nivel --</b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('listening_nivel')"
                                            :key="`${i}-createPostulant-listening_nivel`"
                                            :state="showModalSetResultsInputStatus('listening_nivel')"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group label="Listening (comentarios):">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea
                                            placeholder="Ingrese los comentarios."
                                            v-model="englishInterviewSchedule.attributes.listening_comment"
                                            :state="showModalSetResultsInputStatus('listening_comment')"
                                            v-show="!setResultsLoading" 
                                        ></b-form-textarea>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('listening_comment')"
                                            :key="`${i}-createPostulant-listening_comment`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                             <!-- speaking -->
                            <b-form-row v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                                <b-col>
                                    <b-form-group label="Speaking (nivel):">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2  text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select
                                            :options="englishLevel"
                                            v-model="englishInterviewSchedule.attributes.speaking_nivel"
                                            :state="showModalSetResultsInputStatus('speaking_nivel')"
                                            v-show="!setResultsLoading" 
                                        >
                                            <template #first>
                                                <b-form-select-option value=null disabled>-- seleciona un nivel --</b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('speaking_nivel')"
                                            :key="`${i}-createPostulant-speaking_nivel`"
                                            :state="showModalSetResultsInputStatus('speaking_nivel')"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group label="Speaking (comentarios):">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea
                                            placeholder="Ingrese los comentarios."
                                            v-model="englishInterviewSchedule.attributes.speaking_comment"
                                            :state="showModalSetResultsInputStatus('speaking_comment')"
                                            v-show="!setResultsLoading" 
                                        ></b-form-textarea>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('speaking_comment')"
                                            :key="`${i}-createPostulant-speaking_comment`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                             <!-- vocabulary -->
                            <b-form-row v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                                <b-col>
                                    <b-form-group label="Vocabulary (nivel):">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2  text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select
                                            :options="englishLevel"
                                            v-model="englishInterviewSchedule.attributes.vocabulary_nivel"
                                            :state="showModalSetResultsInputStatus('vocabulary_nivel')"
                                            v-show="!setResultsLoading" 
                                        >
                                            <template #first>
                                                <b-form-select-option value=null disabled>-- seleciona un nivel --</b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('vocabulary_nivel')"
                                            :key="`${i}-createPostulant-vocabulary_nivel`"
                                            :state="showModalSetResultsInputStatus('vocabulary_nivel')"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group label="Vocabulary (comentarios):">
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea
                                            placeholder="Ingrese los comentarios."
                                            v-model="englishInterviewSchedule.attributes.vocabulary_comment"
                                            :state="showModalSetResultsInputStatus('vocabulary_comment')"
                                            v-show="!setResultsLoading" 
                                        ></b-form-textarea>

                                        <b-form-invalid-feedback 
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('vocabulary_comment')"
                                            :key="`${i}-createPostulant-vocabulary_comment`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- interview_details -->
                            <b-form-row>
                                <b-col cols="12">
                                    <b-form-group 
                                        label="Comentarios generales :"
                                    >
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea
                                            placeholder="Ingrese los comentarios generales."
                                            v-model="englishInterviewSchedule.attributes.interview_details"
                                            :state="showModalSetResultsInputStatus('interview_details')"
                                            v-show="!setResultsLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('interview_details')"
                                            :key="`${i}-createPostulant-description`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- interview_observations -->
                            <b-form-row>
                                <b-col cols="12">
                                    <b-form-group 
                                        label="Observaciones :"
                                    >
                                        <pulse-loader
                                            :loading="setResultsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2  text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea
                                            placeholder="Ingrese las observaciones."
                                            v-model="englishInterviewSchedule.attributes.interview_observations"
                                            :state="showModalSetResultsInputStatus('interview_observations')"
                                            v-show="!setResultsLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalSetResultsInputErrors('interview_observations')"
                                            :key="`${i}-createPostulant-description`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- btn registrar -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto">
                                    <b-button
                                        class="w-100 my-2"
                                        variant="primary"
                                        type="submit"
                                        size="lg"
                                        :disabled="setResultsLoading"
                                    >ACTUALIZAR RESULTADOS</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
            </b-modal>

            <!-- 
                MODAL: UPDATE - SCHEDULE
             -->
            <b-modal
                id="modal-update-schedule"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="lg"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0">Actualizar horarios</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalShedule"
                                >
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <b-form @submit.prevent="updateSchedule">
                            <b-form-row  class="my-2">
                                <!-- start_date -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Fecha :"
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-datepicker
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                            today-variant="naranja"
                                            locale="es"

                                            today-button
                                            label-today-button="today"
                                            today-button-variant="outline-naranja"
                                            
                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="englishInterviewSchedule.attributes.start_date"
                                            :state="showModalScheduleInputStatus('start_date')"
                                            v-if="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('start_date')"
                                            :key="`${i}-interview-start_date`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- start_time -->
                                <b-col cols="12" lg="6">
                                    <b-form-group
                                        label="Hora de inicio :"
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-timepicker
                                            locale="en"

                                            now-button
                                            label-now-button="now"
                                            now-button-variant="outline-naranja"

                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="englishInterviewSchedule.attributes.start_time"
                                            :state="showModalScheduleInputStatus('start_time')"
                                            v-if="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('start_time')"
                                            :key="`${i}-interview-start_time`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- end_time -->
                                <b-col cols="12" lg="6">
                                    <b-form-group
                                        label="Hora de finalización :"
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-timepicker
                                            locale="en"

                                            now-button
                                            label-now-button="now"
                                            now-button-variant="outline-naranja"

                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="englishInterviewSchedule.attributes.end_time"
                                            :state="showModalScheduleInputStatus('end_time')"
                                            v-if="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('end_time')"
                                            :key="`${i}-interview-end_time`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- link -->
                                <b-col cols="12">
                                    <b-form-group label="Link :" >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="url"
                                            placeholder="Ingrese el link de la reunión."
                                            v-model="englishInterviewSchedule.attributes.link"
                                            :state="showModalScheduleInputStatus('link')"
                                            v-show="!updateScheduleLoading"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('link')"
                                            :key="`${i}-createVoucher-link`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción:" 
                                    >
                                        <pulse-loader
                                            :loading="updateScheduleLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea 
                                            placeholder="Ingrese una descripción (opcional)"
                                            v-model="englishInterviewSchedule.attributes.schedule_description"
                                            :state="showModalScheduleInputStatus('schedule_description')"
                                            v-show="!updateScheduleLoading"
                                            rows="3"
                                            max-rows="6"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showModalScheduleInputErrors('schedule_description')"
                                            :key="`${i}-interview-schedule_description`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- btn actualizar -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto">
                                    <b-button
                                        class="w-100 my-2"
                                        variant="primary"
                                        type="submit"
                                        size="lg"
                                        :disabled="updateScheduleLoading"
                                    >ACTUALIZAR</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import { getEnglishInterviewSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewSchedule';
import { setInterviewResults } from '@/api/EnglishInterviewSchedule/EnglishInterviewResults';
import { englishInterviewUpdateSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewUpdateSchedule';
import swal from 'sweetalert'

export default {
    name: "EnglishInterviewDetailsComponent",
    data: () => ({
        englishInterviewSchedule: {
            attributes: {}
        },
        getEnglishInterviewLoading: false,
        /**
         * MODAL: SET-RESULTS
         */
        englishLevel: [
            { value: 'nivel 1', text: 'nivel 1 - muy básico'},
            { value: 'nivel 2', text: 'nivel 2 - básico'},
            { value: 'nivel 3', text: 'nivel 3 - intermedio'},
            { value: 'nivel 4', text: 'nivel 4 - bueno'},
            { value: 'nivel 5', text: 'nivel 5 - muy bueno'}
        ],
        //
        inputModalSetResultsErrors: {},
        initialModalSetResultsInputValues: true,
        setResultsLoading: false,

        /**
         * MODAL: SCHEDULE
         */
        inputModalScheduleErrors: {},
        initialModalScheduleInputValues: true,
        updateScheduleLoading: false,
    }),

    created() {
        this.getData()
    },

    // computed: {
    //     culturalAdvisorPermissionData () {
    //         return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
    //     },

    //     canEdit () {
    //         if (this.englishInterviewSchedule.attributes.culturalAdvisor_id == this.culturalAdvisorPermissionData.attributes.typeAdvisor_id) return true
    //         else return false
    //     },
    // },

    methods: {
        getData () {
            this.getEnglishInterviewLoading = true
            let IdEnglishInterviewSchedule = this.$route.params.IdEnglishInterviewSchedule // ok
            getEnglishInterviewSchedule(IdEnglishInterviewSchedule)
                .then(({ data }) => {
                    this.englishInterviewSchedule = data.data
                })
                .finally(() => {
                    this.getEnglishInterviewLoading = false
                })
        },

        /**
         * MODAL-SET-RESULTS
         */
        openModalResult () {
            this.$bvModal.show('modal-setResults')
        },
        closeModalResult () {
            this.clearInputsAndStates()
            this.$bvModal.hide('modal-setResults')
            this.getData()
        },

        asistenciaChange (e) {
            console.log(e);
            if(e == 'no asistio') {
                this.englishInterviewSchedule.attributes.interview_status = 'reprobado'
            } 
        },

        registerResult () {
            this.setResultsLoading = true
            this.initialModalSetResultsInputValues = false
            this.inputModalSetResultsErrors = {}
            //
            let IdEnglishInterviewSchedule = this.$route.params.IdEnglishInterviewSchedule // ok

            const ResultsFormData = new FormData()
            ResultsFormData.append('.method', 'put')
            ResultsFormData.append('schedule_status', 'culminado')
            ResultsFormData.append('postulant_status', this.englishInterviewSchedule.attributes.postulant_status)
            ResultsFormData.append('interview_status', this.englishInterviewSchedule.attributes.interview_status)

            if(this.englishInterviewSchedule.attributes.postulant_status == 'no asistio') {
                ResultsFormData.append('listening_nivel', '')
                ResultsFormData.append('listening_comment', '')
                ResultsFormData.append('speaking_nivel', '')
                ResultsFormData.append('speaking_comment', '')
                ResultsFormData.append('vocabulary_nivel', '')
                ResultsFormData.append('vocabulary_comment', '')
            } else {
                ResultsFormData.append('listening_nivel', this.englishInterviewSchedule.attributes.listening_nivel)
                ResultsFormData.append('listening_comment', this.englishInterviewSchedule.attributes.listening_comment == null ? '' : this.englishInterviewSchedule.attributes.listening_comment)
                ResultsFormData.append('speaking_nivel', this.englishInterviewSchedule.attributes.speaking_nivel)
                ResultsFormData.append('speaking_comment', this.englishInterviewSchedule.attributes.speaking_comment == null ? '' : this.englishInterviewSchedule.attributes.speaking_comment)
                ResultsFormData.append('vocabulary_nivel', this.englishInterviewSchedule.attributes.vocabulary_nivel)
                ResultsFormData.append('vocabulary_comment', this.englishInterviewSchedule.attributes.vocabulary_comment == null ? '' : this.englishInterviewSchedule.attributes.vocabulary_comment)
            }

            ResultsFormData.append('interview_details', this.englishInterviewSchedule.attributes.interview_details == null ? '' : this.englishInterviewSchedule.attributes.interview_details)
            ResultsFormData.append('interview_observations', this.englishInterviewSchedule.attributes.interview_observations == null ? '' : this.englishInterviewSchedule.attributes.interview_observations)

            setInterviewResults (ResultsFormData, IdEnglishInterviewSchedule)
                .then(()=>{
                     swal('Datos registrados!', 'Los resultados de la entrevista fueron registrados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalResult()
                                    break;

                                default:
                                    console.log('setInterviewResults-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log(err.response);
                    if (err.response.status == 422) {
                        this.inputModalSetResultsErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.setResultsLoading = false
                })
        },

        showModalSetResultsInputErrors (pInput) {
            if (Object.keys(this.inputModalSetResultsErrors).includes(pInput)) return this.inputModalSetResultsErrors[pInput]
            else return []
        },

        showModalSetResultsInputStatus (pInput) {
            if (this.initialModalSetResultsInputValues) return null
            else if (Object.keys(this.inputModalSetResultsErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.inputModalSetResultsErrors = {}
            this.initialModalSetResultsInputValues = true
        },

        /**
         * MODAL: UPDATE - SCHEDULE
         */
        openModalShedule () {
            this.$bvModal.show('modal-update-schedule')
        },
        closeModalShedule () {
            this.clearModalScheduleInputsAndStates()
            this.$bvModal.hide('modal-update-schedule')
            this.getData()
        },

        updateSchedule () {
            console.log('update-shedule');
            this.updateScheduleLoading = true
            this.initialModalScheduleInputValues = false
            this.inputModalScheduleErrors = {}
            //
            let IdEnglishInterviewSchedule = this.$route.params.IdEnglishInterviewSchedule // ok

            const ResultsFormData = new FormData()
            ResultsFormData.append('.method', 'put')
            ResultsFormData.append('start_date', this.englishInterviewSchedule.attributes.start_date)
            ResultsFormData.append('start_time', this.englishInterviewSchedule.attributes.start_time)
            ResultsFormData.append('end_date', this.englishInterviewSchedule.attributes.start_date)
            ResultsFormData.append('end_time', this.englishInterviewSchedule.attributes.end_time)
            ResultsFormData.append('link', this.englishInterviewSchedule.attributes.link)
            ResultsFormData.append('schedule_description', this.englishInterviewSchedule.attributes.schedule_description)

            englishInterviewUpdateSchedule (ResultsFormData, IdEnglishInterviewSchedule)
                .then(()=>{
                    swal('Actualización correcta!', 'Los horarios fueron actualizados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalShedule()
                                    break;

                                default:
                                    console.log('updateSchedule-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log(err.response);
                    if (err.response.status == 422) {
                        this.inputModalScheduleErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateScheduleLoading = false
                })
        },

        showModalScheduleInputErrors (pInput) {
            if (Object.keys(this.inputModalScheduleErrors).includes(pInput)) return this.inputModalScheduleErrors[pInput]
            else return []
        },

        showModalScheduleInputStatus (pInput) {
            if (this.initialModalScheduleInputValues) return null
            else if (Object.keys(this.inputModalScheduleErrors).includes(pInput)) return false
            else return true
        },

        clearModalScheduleInputsAndStates () {
            this.inputModalScheduleErrors = {}
            this.initialModalScheduleInputValues = true
        },

        whatIsEnglishNivel(pNivel) {
            if (pNivel == 'nivel 1') {
                return 'Nivel 1 - muy básico'

            } else if (pNivel == 'nivel 2'){
                return 'Nivel 2 - básico'

            } else if (pNivel == 'nivel 3'){
                return 'Nivel 3 - intermedio'

            } else if (pNivel == 'nivel 4'){
                return 'Nivel 4 - bueno'

            } else if (pNivel == 'nivel 5') {
                return 'Nivel 5 - muy bueno'
                
            } else {
                return '...'
            }
        },

        reloadData () {
            this.getData()
        }
    }
}
</script>