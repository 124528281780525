<template>
    <b-row class="mb-4">
        <b-col>

            <b-card class="shadow" no-body>
               <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                             <h3 class="mb-0 text-primary text-button">
                               Forms
                             </h3>
                         </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="modalAddFormClick"  :disabled="dataLoading" v-if="ui == 'admin' || ui == 'employee'">
                                <b-icon icon="plus" scale="1.2"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="dataLoading">
                            <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="dataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!dataLoading">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="forms"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Nombre</th>
                            <th v-if="ui == 'admin' || ui == 'employee'">Estado</th>
                            <th>Formulario</th>
                            <th v-if="ui == 'admin' || ui == 'employee'"></th>
                        </template>

                        <template slot-scope="{row, index}">
                        <td>{{ index + 1 }}</td>
                        <td>{{ row.attributes.name }}</td>
                        <td v-if="ui == 'admin' || ui == 'employee'">{{ row.attributes.status == 1 ? 'Activado' : 'Desactivado' }}</td>
                        <td>
                            <b-button variant="primary" size="sm" @click="modalShowFormClick(row)" :disabled="dataLoading">
                                <b-icon icon="eye" scale="1.2"></b-icon>
                            </b-button>
                        </td>

                        <td v-if="ui == 'admin' || ui == 'employee'">
                            <b-button size="sm" @click="modalEditFormClick(index, row)">
                                <b-icon icon="pencil" variant="naranja"></b-icon>
                            </b-button>

                            <b-button size="sm" @click="deleteForm(index, row)" :disabled="row.attributes.deleteLoading">
                                <pulse-loader :color="'#EF4A81'" :margin="'2px'" :size="8" v-if="row.attributes.deleteLoading"/>
                                <b-icon icon="trash" variant="magenta" v-else></b-icon>
                            </b-button>
                        </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- MODAL ADD FORM -->
        <b-modal
            id="modal-add-form"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">ADD DOC.</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddFormClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="addForm">
                        <!-- nombre -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Nombre :"
                                >
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el nombre."
                                        v-model="name"
                                        v-show="!createLoading"
                                        :state="showInputStatus('name')"
                                    />
                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('name')"
                                        :key="`${i}-create-form-name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>

                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- identifier -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Identificador (valor que va entre '/d/e/' y '/viewform?' de la URL compartida del Formulario) :"
                                    description="Ejemplo: '1A23C4D5E6' de src='https://docs.google.com/forms/d/e/1A23C4D5E6/viewform?'"
                                >
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="1A23C4D5E6"
                                        v-model="identifier"
                                        :state="showInputStatus('identifier')"
                                        v-show="!createLoading"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('identifier')"
                                        :key="`${i}-create-form-identifier`"
                                    >
                                    {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- status -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Estado :"
                                >
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <!-- archivo -->
                                    <b-form-radio-group
                                        v-model="status"
                                        :state="showInputStatus('status')"
                                        v-show="!createLoading"
                                        :options="[{value: 0, text: 'Desactivado'}, {value: 1, text: 'Activado'}]"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('status')"
                                        :key="`${i}-create-form-status`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-row align-v="center" align-h="center">
                            <b-col cols="auto">
                                <b-button variant="primary" type="submit"
                                    :disabled="createLoading"
                                >
                                    GUARDAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>

        <!-- MODAL EDIT FORM -->
        <b-modal
            id="modal-edit-form"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDIT FORM</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalEditFormClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="editForm">
                        <!-- nombre -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Nombre :"
                                >
                                    <pulse-loader
                                        :loading="editLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el nombre."
                                        v-model="formSelected.attributes.name"
                                        v-show="!editLoading"
                                        :state="showInputStatus('name')"
                                    />
                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('name')"
                                        :key="`${i}-create-form-name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>

                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- identifier -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Identificador (valor que va entre '/d/e/' y '/viewform?' de la URL compartida del Formulario) :"
                                    description="Ejemplo: '1A23C4D5E6' de src='https://docs.google.com/forms/d/e/1A23C4D5E6/viewform?'"
                                >
                                    <pulse-loader
                                        :loading="editLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="1A23C4D5E6"
                                        v-model="formSelected.attributes.identifier"
                                        :state="showInputStatus('identifier')"
                                        v-show="!editLoading"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('identifier')"
                                        :key="`${i}-create-form-identifier`"
                                    >
                                    {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- status -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Estado :"
                                >
                                    <pulse-loader
                                        :loading="editLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <!-- archivo -->
                                    <b-form-radio-group
                                    v-model="formSelected.attributes.status"
                                    :state="showInputStatus('status')"
                                    v-show="!editLoading"
                                    :options="[{value: 0, text: 'Desactivado'}, {value: 1, text: 'Activado'}]"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('status')"
                                        :key="`${i}-create-form-status`"
                                    >
                                    {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-row align-v="center" align-h="center">
                            <b-col cols="auto">
                                <b-button variant="primary" type="submit"
                                :disabled="editLoading"
                                >
                                GUARDAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>

        <!-- MODAL SHOW FORM -->
        <b-modal
            id="modal-show-form"
            centered
            hide-footer
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">FORM</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalShowFormClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <iframe id="apk-form"
                        :src="`https://docs.google.com/forms/d/e/${formSelected.attributes.identifier}/viewform?embedded=true`"
                        frameborder="0" marginheight="0" marginwidth="0" width="100%">Cargando…
                    </iframe>
                </b-container>
            </template>
        </b-modal>

    </b-row>
</template>

<script>
import { getForms, storeForm, updateForm, destroyForm } from '@/api/Form/Form'
import swal from 'sweetalert'

export default {
    name: "FormsListComponent",
    props: {
        ui: {
            type: String,
            required: true
        }
    },
    data: () => ({
        forms: [],
        dataLoading: false,
        // create form
        name: '',
        identifier: '',
        status: 1,
        createLoading:false,
        // edit form
        formSelected: {
            attributes: {}
        },
        editLoading: false,
        // form
        initialInputValues: true,
        inputErrors: []
    }),

    created() {
        this.getData()
    },

    methods: {
        getData() {
            this.dataLoading = true
            getForms()
                .then(({ data }) => {
                    if (this.ui == 'admin' || this.ui == 'employee') this.forms = data.data
                    if (this.ui == 'participant') this.forms = data.data.filter(el => el.attributes.status == 1)
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },

        // create
        modalAddFormClick() {
            this.$bvModal.show('modal-add-form')
        },
        modalAddFormClose() {
            this.$bvModal.hide('modal-add-form')
            this.clearInputsAndStates()
        },
        addForm() {
            this.createLoading = true
            this.initialInputValues = false

            let ApkFormdata = new FormData()
            ApkFormdata.append('name', this.name)
            ApkFormdata.append('identifier', this.identifier)
            ApkFormdata.append('status', this.status)

            storeForm(ApkFormdata)
                .then(({ data }) => {
                    this.forms.push(data.data)
                    this.modalAddFormClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        // edit
        modalEditFormClick(pIndex, pForm) {
            this.formSelected = JSON.parse(JSON.stringify({...pForm, index: pIndex}))
            this.$bvModal.show('modal-edit-form')
        },
        modalEditFormClose() {
            this.$bvModal.hide('modal-edit-form')
            this.clearInputsAndStates()
        },
        editForm(){
            this.editLoading = true
            this.initialInputValues = false

            let ApkFormdata = new FormData()
            ApkFormdata.append('.method', 'put')
            ApkFormdata.append('name', this.formSelected.attributes.name)
            ApkFormdata.append('identifier', this.formSelected.attributes.identifier)
            ApkFormdata.append('status', this.formSelected.attributes.status)


            updateForm(this.formSelected.id, ApkFormdata)
                .then(({ data }) => {
                    this.forms.splice(this.formSelected.index, 1, data.data)
                    this.modalEditFormClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editLoading = false
                })
        },

        // SHOW FORM
        modalShowFormClick(pForm) {
            this.formSelected = JSON.parse(JSON.stringify(pForm))
            this.$bvModal.show('modal-show-form')
            setTimeout(() => {
                document.querySelector('#apk-form').height =  0.75 * window.innerHeight
            }, 0);
        },
        modalShowFormClose() {
            this.$bvModal.hide('modal-show-form')
            this.clearInputsAndStates()
        },

        clearInputsAndStates() {
            this.formSelected = {
                attributes: {}
            }

            this.initialInputValues = true
            this.inputErrors = {}

            this.name = ''
            this.identifier = ''
            this.status = 1
        },

        // globar input methods
        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        // delete
        deleteForm (pIndex, pForm) {
            swal(`Se eliminará.`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pForm, 'deleteLoading', true)
                            destroyForm(pForm.id)
                                .then(() => {
                                    this.forms.splice(pIndex, 1)
                                })
                                .finally(() => {
                                    pForm.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-form-swal-break');
                            break;
                    }
                })
        },

        reloadData() {
            this.getData()
        }
    }
}
</script>