<template>
    <b-row>
        <b-col cols="12" lg="6" class="mt-5 mt-lg-0">
            <b-card class="shadow" no-body>
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-primary text-button">
                                Campos GENERALES
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalCreateGeneralInput">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="genrealJobInputsLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card
                    no-body
                    v-if="!genrealJobInputsLoading"
                    style="height:500px;"
                >
                    <div class="table-responsive" >
                        <base-table class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="genrealJobInputsTableData"
                        >
                            <template slot="columns">
                                <th>#</th>
                                <th>Campo</th>
                                <th></th>
                            </template>

                            <template slot-scope="{row, index}" >
                                <td>{{ index + 1 }}</td>

                                <td :class="row.attributes.alredyHasInput == 'si' ? 'text-green' : ''">
                                    {{ row.attributes.name }}
                                </td>

                                <td>
                                    <div v-show="row.attributes.jobOfferInputs.length == 0">
                                        <pulse-loader
                                            :loading="row.deleteLoading || false"
                                            :color="'#5e72e4'"
                                            :margin="'2px'"
                                            :size="8"
                                            class="pt-2 pb-1"
                                        ></pulse-loader>

                                        <b-btn variant="transparent" size="sm" 
                                            v-show="!row.deleteLoading && true"
                                            @click="deleteGeneralJobInput(row)"
                                        ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                        </b-btn>
                                    </div>
                                </td>
                            </template>
                        </base-table>
                    </div>
                </b-card>
            </b-card>
        </b-col>

        <!-- modal create-->
        <b-modal
                id="modal-create-general-input"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    Crear Campo general
                                </h3>
                            </b-col>

                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalCreateGeneralInput">
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <b-form @submit.prevent="createGeneralInput">
                            <b-form-row>
                                <!-- name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Nombre :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el nombre del campo."
                                            v-model="name"
                                            :state="showInputStatus('name')"
                                            v-if="!createLoading"
                                        ></b-form-input>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('name')"
                                            :key="`${i}-name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- btn actualizar -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto" class="py-0">
                                    <b-button
                                        class="w-100 my-2"
                                        variant="primary"
                                        type="submit"
                                        size="md"
                                        :disabled="createLoading"
                                    >Crear</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
        </b-modal>
    </b-row>
</template>

<script>
// sponsotJobOffer.jobOfferInput
import { getGeneralJobInputs, storeGeneralJobInput, destroyGeneralJobInput } from '@/api/GeneralJobInput/GeneralJobInput';
import swal from 'sweetalert'

export default {
    name: "GeneralJobInputsComponent",
    data: () => ({
        genrealJobInputsTableData: [],
        genrealJobInputsLoading: false,
        //
        name: '',
        //
        createLoading: false,
        initialInputValues: true,
        inputErrors: {},
    }),

    created() {
        this.getData()
    },

    methods: {
        getData () {
            this.genrealJobInputsLoading = true

            getGeneralJobInputs()
                .then(({ data }) => {
                    this.genrealJobInputsTableData = data.data
                })
                .finally(() => {
                    this.genrealJobInputsLoading = false
                })
        },

        reloadData () {
            this.getData()
        },

        // modal
        openModalCreateGeneralInput () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-create-general-input')
        },

        closeModalCreateGeneralInput () {
            this.$bvModal.hide('modal-create-general-input')
            this.clearInputsAndStates()
        },

        createGeneralInput () {
            this.createLoading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let InputForm = new FormData()
            InputForm.append('name', this.name)

            storeGeneralJobInput(InputForm)
                .then(() => {
                    swal('Creación correcta!', 'Campo general creado', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalCreateGeneralInput()
                                    this.getData()
                                    break;

                                default:
                                    console.log('create-general-input-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.inputErrors = {}
            this.initialInputValues = true

            this.name = ''
        },

        //
        deleteGeneralJobInput (pGeneralJobInput) {
            swal('Se eliminará el campo', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pGeneralJobInput, 'deleteLoading', true)
                            destroyGeneralJobInput(pGeneralJobInput.attributes.id)
                                .then(() => {
                                    this.getData()
                                })
                                .finally(() => {
                                    pGeneralJobInput.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-GeneralJobInput-swal-break');
                            break;
                    }
                })
        }
    }
}
</script>