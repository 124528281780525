import Vue from 'vue'
import Vuex from 'vuex'

import api from '@/store/modules/api'
import user from '@/store/modules/user'
import notification from '@/store/modules/notification'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        api,
        user,
        notification
    }
})