<template>
    <span>
        <b-btn @click="modalClick" variant="outline-naranja" size="sm"><b-icon icon="pencil" scale="1.2"></b-icon></b-btn>

        <!-- modal edit postulation -->
        <b-modal
            :id="`modal-edit-postulation-${order}`"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-uppercase">Editar Postulación</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose" :disabled="loading">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col>
                            <b-alert show class="text-center" variant="primary">
                            {{ postulation_data.attributes.subject }} - {{ postulation_data.attributes.sponsor_name }} - {{ postulation_data.attributes.programSponsorJob.attributes.job_name }}
                            </b-alert>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <b-form @submit.prevent="editPostulation">
                                <b-form-row>
                                    <b-col cols="12">
                                        <!-- programSeasonSponsors by programSeason -->
                                        <b-form-group label="Sponsor:" >
                                            <pulse-loader
                                                :loading="sponsorsLoading || loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="postulation_data.attributes.programSeasonSponsor_id"
                                                :options="programSeasonSponsorsOptions"
                                                :state="showInputStatus('fake')"
                                                v-show="!sponsorsLoading && !loading"
                                                @change="searchJobs"
                                            >
                                                <!-- <template #first>
                                                    <b-form-select-option value="" class="text-primary" disabled>
                                                        {{ postulation_data.attributes.sponsor_name }}
                                                    </b-form-select-option>
                                                </template> -->
                                            </b-form-select>

                                            <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('fake')" :key="`${i}-up-fake`">{{ inputError }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12">
                                        <!-- programSponsorJobs by programSeasonSponsor -->
                                        <b-form-group label="Oferta laboral:" >
                                            <pulse-loader
                                                :loading="sponsorsLoading || jobsLoading || loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="postulation_data.attributes.programSponsorJob_id"
                                                :options="programSponsorJobsOptions"
                                                :state="showInputStatus('programSponsorJob_id')"
                                                v-show="!sponsorsLoading && !jobsLoading && !loading"
                                            >
                                                <template #first>
                                                    <b-form-select-option value="" disabled>
                                                        - - Selecciona una oferta laboral - -
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('programSponsorJob_id')" :key="`${i}-up-programSponsorJob_id`">{{ inputError }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-form-row>
                                    <b-col>
                                        <b-form-group label="Posición:" >
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="postulation_data.attributes.subject"
                                                :options="subjectOptions"
                                                :state="showInputStatus('subject')"
                                                v-show="!loading"
                                            >
                                                <!-- <template #first>
                                                    <b-form-select-option :value="postulation_data.attributes.subject" disabled>
                                                        {{ postulation_data.attributes.subject }}
                                                    </b-form-select-option>
                                                </template> -->
                                            </b-form-select>

                                            <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('subject')" :key="`${i}-up-subject`">{{ inputError }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button class="text-uppercase" variant="primary" type="submit" size="lg" :disabled="loading"
                                            >Actualizar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </span>
</template>

<script>
import { getProgramSeasonParticipantLite } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant'
// ProgramSeason.ProgramSeasonSponsors
import { getProgramSeasonProgramSeasonSponsors } from '@/api/ProgramSeason/ProgramSeasonProgramSeasonSponsor'
// ProgramSeasonSponsor.ProgramSponsorJobs
import { getProgramSeasonSponsorProgramSponsorJobsLite } from '@/api/ProgramSeasonSponsor/ProgramSponsorJob'

import { updateSubject } from '@/api/Postulation/Postulation';
import swal from 'sweetalert'

export default {
    name: "EditAttemptComponent",
    props: {
        postulation: {
            type: Object,
            required: true
        },
        order: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        postulation_data: {
            attributes: {}
        },
        //
        sponsorsLoading: false,
        programSeasonSponsorsOptions: [],
        // sponsorIdSelected: '',
        //
        jobsLoading: false,
        programSponsorJobsOptions: [],
        // jobIdSelected: '',
        //
        loading: false,
        inputErrors: {},
        initialInputValues: true,

        subjectOptions: [
            {value: 'Primera opción', text: 'Primera opción'},
            {value: 'Segunda opción', text: 'Segunda opción'},
            {value: 'Tercera opción', text: 'Tercera opción'},
            {value: 'Cuarta opción', text: 'Cuarta opción'},
        ]
    }),

    methods: {
        modalClick () {
            this.postulation_data = JSON.parse(JSON.stringify(this.postulation))
            this.getData()
            this.$bvModal.show(`modal-edit-postulation-${this.order}`)
        },

        getData () {
            // to get IdProgramSeason
            this.sponsorsLoading = true
            getProgramSeasonParticipantLite(this.postulation_data.attributes.programSeasonParticipant_id)
                .then(({ data }) => {
                    // to get SPONSORS(programSeasonSponsor) by programSeason
                    getProgramSeasonProgramSeasonSponsors(1, data.data.attributes.programSeason_id)
                        .then(({ data }) => {
                            this.programSeasonSponsorsOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.sponsor_name }))
                            this.getJobsBySponsor(this.postulation_data.attributes.programSeasonSponsor_id)
                        })
                        .finally(() => {
                            this.sponsorsLoading = false
                        })

                })
        },

        getJobsBySponsor (pProgramSeasonSponsorId) {
            // Si se el sponsor es el mismo, la oferta se toma del dato original, caso contrario el valor es vacio
            if (pProgramSeasonSponsorId == this.postulation.attributes.programSeasonSponsor_id) this.postulation_data.attributes.programSponsorJob_id = this.postulation.attributes.programSponsorJob_id
            else this.postulation_data.attributes.programSponsorJob_id = ''

            //to get JOBS(programSponsorJobs) by SPONSOR(programSeasonSponsor)
            this.jobsLoading = true
            getProgramSeasonSponsorProgramSponsorJobsLite(pProgramSeasonSponsorId)
                .then(({ data }) => {
                    this.programSponsorJobsOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.job_name}))
                })
                .finally(() => {
                    this.jobsLoading = false
                })
        },

        searchJobs (e) {
            this.getJobsBySponsor(e)
        },

        modalClose () {
            this.$bvModal.hide(`modal-edit-postulation-${this.order}`)
            this.clearInputsAndStates()
        },


        editPostulation() {
            this.loading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let PostulationForm = new FormData()
            PostulationForm.append('.method', 'put')
            PostulationForm.append('programSponsorJob_id', this.postulation_data.attributes.programSponsorJob_id)
            PostulationForm.append('subject', this.postulation_data.attributes.subject)

            updateSubject(PostulationForm, this.postulation_data.attributes.id)
                .then(() => {
                    swal('Actualización correcta!', '', 'success')
                        .then(val => {
                            switch(val) {
                                case true:
                                case null:
                                    this.$emit('updated')
                                    this.modalClose()
                                    break;
                                default:
                                    console.log('update-postulation-subject-breck');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.inputErrors = {}
            this.initialInputValues = true
        },
    }
}
</script>