import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/registerServiceWorker'

import './plugins/vue-spinners'
// Vuetify
import vuetify from './plugins/vuetify'
// BootstrapVue
import './plugins/bootstrapVue'
// ArgonDashboard
import ArgonDashboard from './plugins/argon-dashboard'
//
import 'remixicon/fonts/remixicon.css'
// cropperjs
import './plugins/cropperjs'

Vue.config.productionTip = false

/** */
let maintenaince = 'no'
router.beforeEach((to, from, next) => {
    if (maintenaince == 'yes' && to.name != 'maintenance') next({ name: 'maintenance' })
    else if(maintenaince == 'no' && to.name == 'maintenance') next({name: 'login'})
    else  next()
})

Vue.use(ArgonDashboard)
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
