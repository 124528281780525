<template>
    <visa-documents-component></visa-documents-component>
</template>

<script>
import VisaDocumentsComponent from '@/components/Managment/Visa/VisaDocumentsComponent.vue';

export default {
    name: 'VisaDocuments',
    components: { VisaDocumentsComponent },
}
</script>