<template>
    <div>
        <b-button variant="outline-magenta" size="sm" @click="modalClick"> <b-icon icon="pencil"  scale="1.3" class="mr-2"></b-icon>Abandonar</b-button>

        <b-modal
            id="modal-create-abandonment-program"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">Abandonar Programa</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="create">
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group label="Comentario:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea
                                                autocomplete="off"
                                                placeholder="Ingrese un cometario."
                                                v-model="comments"
                                                :state="showInputStatus('comments')"
                                                v-show="!loading"
                                                rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('comments')"
                                                :key="`${i}-post-comments`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group label="Bloquear credenciales de acceso a la INTRANET:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-radio-group
                                                :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                                v-model="block_access_credentials"
                                                :state="showInputStatus('block_access_credentials')"
                                                v-show="!loading"
                                                stacked plain
                                            ></b-form-radio-group>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('block_access_credentials')"
                                                :key="`${i}-post-block_access_credentials`"
                                                :state="showInputStatus('block_access_credentials')">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit" :disabled="loading">
                                            ABANDONAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { createAbandonment } from '@/api/Abandonment/Abandonment'
import swal from 'sweetalert'

export default {
    name: 'AbandonProgramComponent',
    props: {
        IdProgramSeasonPostulant: {
            type: Number,
            required: true
        },
        IdProgramSeasonParticipant: {
            type: Number,
        },
        haveParticipant: {
            type: String,
            required: true
        },
        ui: {
            type: String,
            required: true
        }
    },
    data: () => ({
        comments: '',
        block_access_credentials: '',
        loading: false,
        //
        inputErrors: {},
        initialInputValues: true
    }),

    methods: {
        modalClick() {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-create-abandonment-program')
        },
        modalClose() {
            this.$bvModal.hide('modal-create-abandonment-program')
            this.clearInputsAndStates()
        },

        create() {
            swal('Se abandonará el programa.', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, abandonar.']})
                .then(value => {
                    let Form = new FormData()

                    switch (value) {
                        case true:
                            this.loading = true
                            this.initialInputValues = false
                            this.inputErrors = {}
                            Form.append('programSeasonPostulant_id', this.IdProgramSeasonPostulant)
                            Form.append('have_participant', this.haveParticipant)
                            Form.append('programSeasonParticipant_id', this.IdProgramSeasonParticipant ? this.IdProgramSeasonParticipant : '')
                            Form.append('comments', this.comments)
                            Form.append('block_access_credentials', this.block_access_credentials)

                            this.ui == 'admin' ? Form.append('user_action_type', this.ui) : null
                            this.ui == 'cultural_advisor' ? Form.append('user_action_type', this.ui) : null
                            Form.append('user_action_name', this.$store.state.user.attributes.full_name)

                            createAbandonment(Form)
                                .then(() => {
                                    swal('Programa Abandonado!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.modalClose()
                                                    this.$emit('created')
                                                    break;

                                                default:
                                                    console.log('create-abandonment--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .catch(err => {
                                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;

                        case null:
                            this.modalClose()
                            break;

                        default:
                            console.log('abandon-alert-break');
                            break;
                    }
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.comments = ''
            this.block_access_credentials = ''
            this.initialInputValues = true
            this.inputErrors = {}
        },
    }
}
</script>