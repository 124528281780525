<template>
    <b-card class="editor border border-primary" no-body>
        <b-container>
            <b-row>
                <!-- body -->
                <b-col cols="12" v-if="editor" class="editor--header">
                    <b-row>
                        <b-col cols="12">
                            <!-- EXTENSIONS -->
                            <b-button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().undo()" :variant="!editor.can().undo() ? 'outline-light' : 'outline-primary'" size="sm" title="Deshacer (Ctrl + z)">
                                <i class="ri-arrow-go-back-fill ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().redo()" :variant="!editor.can().redo() ? 'outline-light' : 'outline-primary'" size="sm" title="Rehacer (Ctrl + Shift + z)">
                                <i class="ri-arrow-go-forward-fill ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- text color -->
                            <b-button-group class="mr-2">
                                <b-button  @click="editor.chain().focus().unsetColor().run()" variant="outline-primary" size="sm" title="Color del texto">
                                    <i class="ri-font-color ri-lg tiptap-icon__fw"></i>
                                </b-button>
                                <input
                                    class="tiptap-dropdonw__color"
                                    type="color"
                                    @input="editor.chain().focus().setColor($event.target.value).run()"
                                    :value="editor.getAttributes('textStyle').color"
                                >

                                <b-dropdown right text="" variant="outline-primary" size="sm" menu-class="p-0 tiptap-dropdonw">
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#5e72e4').run()" >
                                        <i class="ri-font-color ri-lg  text-primary tiptap-icon__fw">Morado</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#000000').run()">
                                        <i class="ri-font-color ri-lg  text-dark tiptap-icon__fw">Negro</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#ff0000').run()">
                                        <i class="ri-font-color ri-lg  text-red tiptap-icon__fw">Rojo</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#685BC7').run()" >
                                        <i class="ri-font-color ri-lg  text-violeta tiptap-icon__fw">Violeta</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#41B6E6').run()" >
                                        <i class="ri-font-color ri-lg  text-cielo tiptap-icon__fw">Cielo</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#FFAD00').run()" >
                                        <i class="ri-font-color ri-lg  text-naranja tiptap-icon__fw">Naranaja</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#EF4A81').run()" >
                                        <i class="ri-font-color ri-lg  text-magenta tiptap-icon__fw">Magenta</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().setColor('#64CCC9').run()" >
                                        <i class="ri-font-color ri-lg  text-turquesa tiptap-icon__fw">Turquesa</i>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>

                            <!-- text-align -->
                            <b-button :class="{ 'active': editor.isActive({ textAlign: 'left' }) }" @click="editor.chain().focus().setTextAlign('left').run()" variant="outline-primary" size="sm" title="Alinear a la izquierda (Ctrl + Shift + L)">
                                <i class="ri-align-left ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive({ textAlign: 'center' }) }" @click="editor.chain().focus().setTextAlign('center').run()" variant="outline-primary" size="sm" title="Centrar (Ctrl + Shift + E)">
                                <i class="ri-align-center ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive({ textAlign: 'right' }) }" @click="editor.chain().focus().setTextAlign('right').run()" variant="outline-primary" size="sm" title="Alinear a la izquierda (Ctrl + Shift + R)">
                                <i class="ri-align-right ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive({ textAlign: 'justify' }) }" @click="editor.chain().focus().setTextAlign('justify').run()" variant="outline-primary" size="sm" title="Justificar (Ctrl + Shift + J)">
                                <i class="ri-align-justify ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- MARKS -->
                            <!-- bold -->
                            <b-button :class="{ 'active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()" variant="outline-primary" size="sm" title="Negrita (Ctrl + b)">
                                <i class="ri-bold ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- italic -->
                            <b-button :class="{ 'active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()" variant="outline-primary" size="sm" title="Cursiva (Ctrl + i)">
                                <i class="ri-italic ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- underline -->
                            <b-button :class="{ 'active': editor.isActive('underline') }" @click="editor.chain().focus().toggleUnderline().run()" variant="outline-primary" size="sm" title="Subrayar (Ctrl + u)">
                                <i class="ri-underline ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- strike -->
                            <b-button :class="{ 'active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()" variant="outline-primary" size="sm" title="Tachado (Ctrl + Shift + x)">
                                <i class="ri-strikethrough-2 ri-xl tiptap-icon__fw"></i>
                            </b-button>

                            <!-- highlight -->
                            <b-button-group class="mr-2">
                                <b-button :class="{ 'active': editor.isActive('highlight') }" @click="editor.chain().focus().toggleHighlight({ color: colorHighlight }).run()" variant="outline-primary" size="sm" title="Color de resaltado">
                                    <i class="ri-mark-pen-fill ri-lg tiptap-icon__fw"></i>
                                </b-button>
                                <input
                                    class="tiptap-dropdonw__color"
                                    type="color"
                                    :v-model="colorHighlight"
                                    @input="($event) => {
                                        editor.chain().focus().toggleHighlight({ color: $event.target.value }).run()
                                        colorHighlight = $event.target.value
                                    }"
                                >

                                <b-dropdown right text="" variant="outline-primary" size="sm" menu-class="p-0 tiptap-dropdonw">
                                    <b-dropdown-item @click.stop="editor.chain().focus().toggleHighlight({ color: '#ffff00' }).run()">
                                        <i class="ri-mark-pen-fill ri-lg text-yellow tiptap-icon__fw">Amarillo</i>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>

                            <!-- link -->
                            <b-button @click="setLink('boddy')" :class="{ 'active': editor.isActive('link') }" variant="outline-primary" size="sm" title="Link">
                                <i class="ri-link ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')" variant="outline-primary" size="sm" title="Unlink">
                                <i class="ri-link-unlink ri-xl tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- NODOS -->
                            <!-- heading -->
                            <b-button :class="{ 'active': editor.isActive('heading', { level: 1 }) }" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" variant="outline-primary" size="sm" title="H1 (Ctrl + Alt + 1)">
                                <i class="ri-h-1 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive('heading', { level: 2 }) }" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" variant="outline-primary" size="sm" title="H2 (Ctrl + Alt + 2)">
                                <i class="ri-h-2 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive('heading', { level: 3 }) }" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" variant="outline-primary" size="sm" title="H3 (Ctrl + Alt + 3)">
                                <i class="ri-h-3 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive('heading', { level: 4 }) }" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" variant="outline-primary" size="sm" title="H4 (Ctrl + Alt + 4)">
                                <i class="ri-h-4 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive('heading', { level: 5 }) }" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" variant="outline-primary" size="sm" title="H5 (Ctrl + Alt + 5)">
                                <i class="ri-h-5 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editor.isActive('heading', { level: 6 }) }" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" variant="outline-primary" size="sm" title="H6 (Ctrl + Alt + 6)">
                                <i class="ri-h-6 ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>
                        </b-col>

                        <b-col cols="12" class="mt-3">
                            <!-- TABLE -->
                            <b-button @click="agregarTablaNormal('body')" variant="outline-primary" size="sm" title="Insertar tabla normal">
                                <i class="ri-table-2 ri-xl tiptap-icon__fw"></i> Tabla
                            </b-button>
                            <b-button @click="agregarTablaGrid('body')" variant="outline-schedule-talk-2" size="sm" title="Insertar cuadrícula">
                                <i class="ri-table-2 ri-xl tiptap-icon__fw"></i> Grid
                            </b-button>
                            <!-- cols -->
                            <b-button @click="editor.chain().focus().addColumnBefore().run()" variant="outline-primary" size="sm" title="Insertar columna a la izquierda">
                                <i class="ri-insert-column-left ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editor.chain().focus().addColumnAfter().run()" variant="outline-primary" size="sm" title="Insertar columna a la derecha">
                                <i class="ri-insert-column-right ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editor.chain().focus().deleteColumn().run()" variant="outline-primary" size="sm" title="Eliminar columna">
                                <i class="ri-delete-column ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- rows -->
                            <b-button @click="editor.chain().focus().addRowBefore().run()" variant="outline-primary" size="sm" title="Insertar fila encima">
                                <i class="ri-insert-row-top ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editor.chain().focus().addRowAfter().run()" variant="outline-primary" size="sm" title="Insertar fila debajo">
                                <i class="ri-insert-row-bottom ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editor.chain().focus().deleteRow().run()" variant="outline-primary" size="sm" title="Eliminar fila">
                                <i class="ri-delete-row ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- delete table -->
                            <b-button @click="editor.chain().focus().deleteTable().run()" variant="outline-primary" size="sm" title="Eliminar tabla">
                                <i class="ri-delete-bin-2-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- merge -->
                            <b-button @click="editor.chain().focus().mergeCells().run()" variant="outline-primary" size="sm" title="Combinar celdas">
                                <i class="ri-merge-cells-horizontal ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- split -->
                            <b-button @click="editor.chain().focus().splitCell().run()" variant="outline-primary" size="sm" title="Separar celdas">
                                <i class="ri-split-cells-horizontal ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- toggle header column -->
                            <b-button @click="editor.chain().focus().toggleHeaderColumn().run()" variant="outline-primary" size="sm" title="Toggle encabezado de columna">
                                <i class="ri-layout-column-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- toggle header roe -->
                            <b-button @click="editor.chain().focus().toggleHeaderRow().run()" variant="outline-primary" size="sm" title="Toggle encabezado de fila">
                                <i class="ri-layout-row-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- toggle header cell -->
                            <b-button @click="editor.chain().focus().toggleHeaderCell().run()" variant="outline-primary" size="sm" title="Toggle encabezado en celda">
                                <i class="ri-artboard-2-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- lists -->
                            <b-button-group class="mr-2">
                                <b-button :class="{ 'active': editor.isActive('bulletList') }" @click="editor.chain().focus().toggleBulletList().run()" variant="outline-primary" size="sm" title="Lista con viñetas">
                                    <i class="ri-list-unordered ri-lg tiptap-icon__fw"></i>
                                </b-button>

                                <b-button :class="{ 'active': editor.isActive('orderedList') }" @click="editor.chain().focus().toggleOrderedList().run()" variant="outline-primary" size="sm" title="Lista enumerada">
                                    <i class="ri-list-ordered ri-lg tiptap-icon__fw"></i>
                                </b-button>

                                <b-dropdown right text="" variant="outline-primary" size="sm" menu-class="p-0 tiptap-dropdonw">
                                    <b-dropdown-item @click="editor.chain().focus().sinkListItem('listItem').run()" :disabled="!editor.can().sinkListItem('listItem')" title="Aumentar sangría (Tab)">
                                        <i class="ri-indent-increase tiptap-icon__fw"></i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editor.chain().focus().liftListItem('listItem').run()" :disabled="!editor.can().liftListItem('listItem')" title="Reducir sangría (Shift + Tab)">
                                        <i class="ri-indent-decrease tiptap-icon__fw"></i>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>
                            <!-- horizontal rule -->
                            <b-button @click="editor.chain().focus().setHorizontalRule().run()" variant="outline-primary" size="sm" title="Separador">
                                <i class="ri-separator ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <!-- hard break -->
                            <b-button @click="editor.chain().focus().setHardBreak().run()" variant="outline-primary" size="sm" title="Salto de linea en párrafo (Shift + Enter)">
                                <i class="ri-text-wrap ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>
                        </b-col>

                        <b-col cols="12" class="mt-3">
                            <!-- image -->
                            <b-button @click="addInlineImage('body')" variant="outline-primary" size="sm" title="Imagen">
                                <i class="ri-image-2-line ri-lg tiptap-icon__fw"> (linea)</i>
                            </b-button>
                            <b-button @click="addBlockImage('body')" variant="outline-primary" size="sm" title="Imagen">
                                <i class="ri-image-fill ri-lg tiptap-icon__fw"> (Bloque)</i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- signatures -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-contract-1" size="sm" title="Postulante">
                                    Postulante firma:
                                </b-button>
                                <b-button @click="addSignatureSmall('body')" variant="outline-contract-1" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                                <b-button @click="addSignatureMedium('body')" variant="outline-contract-1" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-lg tiptap-icon__fw"> md</i>
                                </b-button>
                                <b-button @click="addSignatureLarge('body')" variant="outline-contract-1" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-xl tiptap-icon__fw"> lg</i>
                                </b-button>
                            </b-button-group>

                            <!-- admin signatures -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-schedule-english-3" size="sm" title="Postulante">
                                    Gerencia - firma:
                                </b-button>
                                <b-button @click="addAdminSignatureMedium('body')" variant="outline-schedule-english-3" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-lg tiptap-icon__fw"> md</i>
                                </b-button>
                            </b-button-group>

                            <!-- fingerprint -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-employer-interview-3" size="sm" title="Postulante">
                                    Postulante - huella:
                                </b-button>
                                <b-button @click="addFirgerprint('body')" variant="outline-employer-interview-3" size="sm" title="Huella">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>

                            <!-- document -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-employer-interview-3" size="sm" title="Postulante">
                                    DNI-anverso:
                                </b-button>
                                <b-button @click="addDniAnverso('body')" variant="outline-employer-interview-3" size="sm" title="anverso">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                            </b-button-group>

                            <b-button-group class="mr-2">
                                <b-button variant="outline-employer-interview-3" size="sm" title="Postulante">
                                    DNI-reverso:
                                </b-button>
                                <b-button @click="addDniReverso('body')" variant="outline-employer-interview-3" size="sm" title="reverso">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>

                            <b-button @click="showPreview" variant="outline-cielo" size="sm" title="Preview">
                                <i class="ri-eye-fill ri-lg tiptap-icon__fw"> Preview (mode: {{ uiMode }})</i>
                            </b-button>

                            <span class="tiptap-divider">|</span>
                        </b-col>
                    </b-row>
                </b-col>

                <div class="editor--divider border-top border-primary"></div>
                <div class="text-center text-primary text-uppercase w-100">body</div>
                <div class="editor--divider border-top border-primary"></div>

                <b-col cols="12" class="editor--body">
                    <editor-content :editor="editor" spellcheck="false" class="editor--body__layout"/>
                </b-col>

                <!-- footer -->
                <div class="editor--divider border-top border-primary"></div>

                <b-col cols="12" v-if="editorFooter" class="editor--header">
                    <b-row>
                        <b-col cols="12">
                            <!-- EXTENSIONS -->
                            <b-button @click="editorFooter.chain().focus().undo().run()" :disabled="!editorFooter.can().undo()" :variant="!editorFooter.can().undo() ? 'outline-light' : 'outline-primary'" size="sm" title="Deshacer (Ctrl + z)">
                                <i class="ri-arrow-go-back-fill ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editorFooter.chain().focus().redo().run()" :disabled="!editorFooter.can().redo()" :variant="!editorFooter.can().redo() ? 'outline-light' : 'outline-primary'" size="sm" title="Rehacer (Ctrl + Shift + z)">
                                <i class="ri-arrow-go-forward-fill ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- text color -->
                            <b-button-group class="mr-2">
                                <b-button  @click="editorFooter.chain().focus().unsetColor().run()" variant="outline-primary" size="sm" title="Color del texto">
                                    <i class="ri-font-color ri-lg tiptap-icon__fw"></i>
                                </b-button>
                                <input
                                    class="tiptap-dropdonw__color"
                                    type="color"
                                    @input="editorFooter.chain().focus().setColor($event.target.value).run()"
                                    :value="editorFooter.getAttributes('textStyle').color"
                                >

                                <b-dropdown right text="" variant="outline-primary" size="sm" menu-class="p-0 tiptap-dropdonw">
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#5e72e4').run()" >
                                        <i class="ri-font-color ri-lg  text-primary tiptap-icon__fw">Morado</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#000000').run()">
                                        <i class="ri-font-color ri-lg  text-dark tiptap-icon__fw">Negro</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#ff0000').run()">
                                        <i class="ri-font-color ri-lg  text-red tiptap-icon__fw">Rojo</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#685BC7').run()" >
                                        <i class="ri-font-color ri-lg  text-violeta tiptap-icon__fw">Violeta</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#41B6E6').run()" >
                                        <i class="ri-font-color ri-lg  text-cielo tiptap-icon__fw">Cielo</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#FFAD00').run()" >
                                        <i class="ri-font-color ri-lg  text-naranja tiptap-icon__fw">Naranaja</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#EF4A81').run()" >
                                        <i class="ri-font-color ri-lg  text-magenta tiptap-icon__fw">Magenta</i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().setColor('#64CCC9').run()" >
                                        <i class="ri-font-color ri-lg  text-turquesa tiptap-icon__fw">Turquesa</i>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>

                            <!-- text-align -->
                            <b-button :class="{ 'active': editorFooter.isActive({ textAlign: 'left' }) }" @click="editorFooter.chain().focus().setTextAlign('left').run()" variant="outline-primary" size="sm" title="Alinear a la izquierda (Ctrl + Shift + L)">
                                <i class="ri-align-left ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive({ textAlign: 'center' }) }" @click="editorFooter.chain().focus().setTextAlign('center').run()" variant="outline-primary" size="sm" title="Centrar (Ctrl + Shift + E)">
                                <i class="ri-align-center ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive({ textAlign: 'right' }) }" @click="editorFooter.chain().focus().setTextAlign('right').run()" variant="outline-primary" size="sm" title="Alinear a la izquierda (Ctrl + Shift + R)">
                                <i class="ri-align-right ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive({ textAlign: 'justify' }) }" @click="editorFooter.chain().focus().setTextAlign('justify').run()" variant="outline-primary" size="sm" title="Justificar (Ctrl + Shift + J)">
                                <i class="ri-align-justify ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- MARKS -->
                            <!-- bold -->
                            <b-button :class="{ 'active': editorFooter.isActive('bold') }" @click="editorFooter.chain().focus().toggleBold().run()" variant="outline-primary" size="sm" title="Negrita (Ctrl + b)">
                                <i class="ri-bold ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- italic -->
                            <b-button :class="{ 'active': editorFooter.isActive('italic') }" @click="editorFooter.chain().focus().toggleItalic().run()" variant="outline-primary" size="sm" title="Cursiva (Ctrl + i)">
                                <i class="ri-italic ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- underline -->
                            <b-button :class="{ 'active': editorFooter.isActive('underline') }" @click="editorFooter.chain().focus().toggleUnderline().run()" variant="outline-primary" size="sm" title="Subrayar (Ctrl + u)">
                                <i class="ri-underline ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- strike -->
                            <b-button :class="{ 'active': editorFooter.isActive('strike') }" @click="editorFooter.chain().focus().toggleStrike().run()" variant="outline-primary" size="sm" title="Tachado (Ctrl + Shift + x)">
                                <i class="ri-strikethrough-2 ri-xl tiptap-icon__fw"></i>
                            </b-button>

                            <!-- highlight -->
                            <b-button-group class="mr-2">
                                <b-button :class="{ 'active': editorFooter.isActive('highlight') }" @click="editorFooter.chain().focus().toggleHighlight({ color: colorHighlight }).run()" variant="outline-primary" size="sm" title="Color de resaltado">
                                    <i class="ri-mark-pen-fill ri-lg tiptap-icon__fw"></i>
                                </b-button>
                                <input
                                    class="tiptap-dropdonw__color"
                                    type="color"
                                    :v-model="colorHighlight"
                                    @input="($event) => {
                                        editorFooter.chain().focus().toggleHighlight({ color: $event.target.value }).run()
                                        colorHighlight = $event.target.value
                                    }"
                                >

                                <b-dropdown right text="" variant="outline-primary" size="sm" menu-class="p-0 tiptap-dropdonw">
                                    <b-dropdown-item @click.stop="editorFooter.chain().focus().toggleHighlight({ color: '#ffff00' }).run()">
                                        <i class="ri-mark-pen-fill ri-lg text-yellow tiptap-icon__fw">Amarillo</i>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>

                            <!-- link -->
                            <b-button @click="setLink('footer')" :class="{ 'active': editorFooter.isActive('link') }" variant="outline-primary" size="sm" title="Link">
                                <i class="ri-link ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editorFooter.chain().focus().unsetLink().run()" :disabled="!editorFooter.isActive('link')" variant="outline-primary" size="sm" title="Unlink">
                                <i class="ri-link-unlink ri-xl tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- NODOS -->
                            <!-- heading -->
                            <b-button :class="{ 'active': editorFooter.isActive('heading', { level: 1 }) }" @click="editorFooter.chain().focus().toggleHeading({ level: 1 }).run()" variant="outline-primary" size="sm" title="H1 (Ctrl + Alt + 1)">
                                <i class="ri-h-1 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive('heading', { level: 2 }) }" @click="editorFooter.chain().focus().toggleHeading({ level: 2 }).run()" variant="outline-primary" size="sm" title="H2 (Ctrl + Alt + 2)">
                                <i class="ri-h-2 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive('heading', { level: 3 }) }" @click="editorFooter.chain().focus().toggleHeading({ level: 3 }).run()" variant="outline-primary" size="sm" title="H3 (Ctrl + Alt + 3)">
                                <i class="ri-h-3 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive('heading', { level: 4 }) }" @click="editorFooter.chain().focus().toggleHeading({ level: 4 }).run()" variant="outline-primary" size="sm" title="H4 (Ctrl + Alt + 4)">
                                <i class="ri-h-4 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive('heading', { level: 5 }) }" @click="editorFooter.chain().focus().toggleHeading({ level: 5 }).run()" variant="outline-primary" size="sm" title="H5 (Ctrl + Alt + 5)">
                                <i class="ri-h-5 ri-lg tiptap-icon__fw"></i>
                            </b-button>
                            <b-button :class="{ 'active': editorFooter.isActive('heading', { level: 6 }) }" @click="editorFooter.chain().focus().toggleHeading({ level: 6 }).run()" variant="outline-primary" size="sm" title="H6 (Ctrl + Alt + 6)">
                                <i class="ri-h-6 ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>
                        </b-col>

                        <b-col cols="12" class="mt-3">
                            <!-- TABLE -->
                            <b-button @click="agregarTablaNormal('footer')" variant="outline-primary" size="sm" title="Insertar tabla normal">
                                <i class="ri-table-2 ri-xl tiptap-icon__fw"></i> Tabla
                            </b-button>
                            <b-button @click="agregarTablaGrid('footer')" variant="outline-schedule-talk-2" size="sm" title="Insertar cuadrícula">
                                <i class="ri-table-2 ri-xl tiptap-icon__fw"></i> Grid
                            </b-button>
                            <!-- cols -->
                            <b-button @click="editorFooter.chain().focus().addColumnBefore().run()" variant="outline-primary" size="sm" title="Insertar columna a la izquierda">
                                <i class="ri-insert-column-left ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editorFooter.chain().focus().addColumnAfter().run()" variant="outline-primary" size="sm" title="Insertar columna a la derecha">
                                <i class="ri-insert-column-right ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editorFooter.chain().focus().deleteColumn().run()" variant="outline-primary" size="sm" title="Eliminar columna">
                                <i class="ri-delete-column ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- rows -->
                            <b-button @click="editorFooter.chain().focus().addRowBefore().run()" variant="outline-primary" size="sm" title="Insertar fila encima">
                                <i class="ri-insert-row-top ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editorFooter.chain().focus().addRowAfter().run()" variant="outline-primary" size="sm" title="Insertar fila debajo">
                                <i class="ri-insert-row-bottom ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <b-button @click="editorFooter.chain().focus().deleteRow().run()" variant="outline-primary" size="sm" title="Eliminar fila">
                                <i class="ri-delete-row ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- delete table -->
                            <b-button @click="editorFooter.chain().focus().deleteTable().run()" variant="outline-primary" size="sm" title="Eliminar tabla">
                                <i class="ri-delete-bin-2-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- merge -->
                            <b-button @click="editorFooter.chain().focus().mergeCells().run()" variant="outline-primary" size="sm" title="Combinar celdas">
                                <i class="ri-merge-cells-horizontal ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- split -->
                            <b-button @click="editorFooter.chain().focus().splitCell().run()" variant="outline-primary" size="sm" title="Separar celdas">
                                <i class="ri-split-cells-horizontal ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- toggle header column -->
                            <b-button @click="editorFooter.chain().focus().toggleHeaderColumn().run()" variant="outline-primary" size="sm" title="Toggle encabezado de columna">
                                <i class="ri-layout-column-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- toggle header roe -->
                            <b-button @click="editorFooter.chain().focus().toggleHeaderRow().run()" variant="outline-primary" size="sm" title="Toggle encabezado de fila">
                                <i class="ri-layout-row-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>
                            <!-- toggle header cell -->
                            <b-button @click="editorFooter.chain().focus().toggleHeaderCell().run()" variant="outline-primary" size="sm" title="Toggle encabezado en celda">
                                <i class="ri-artboard-2-fill ri-xl tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- lists -->
                            <b-button-group class="mr-2">
                                <b-button :class="{ 'active': editorFooter.isActive('bulletList') }" @click="editorFooter.chain().focus().toggleBulletList().run()" variant="outline-primary" size="sm" title="Lista con viñetas">
                                    <i class="ri-list-unordered ri-lg tiptap-icon__fw"></i>
                                </b-button>

                                <b-button :class="{ 'active': editorFooter.isActive('orderedList') }" @click="editorFooter.chain().focus().toggleOrderedList().run()" variant="outline-primary" size="sm" title="Lista enumerada">
                                    <i class="ri-list-ordered ri-lg tiptap-icon__fw"></i>
                                </b-button>

                                <b-dropdown right text="" variant="outline-primary" size="sm" menu-class="p-0 tiptap-dropdonw">
                                    <b-dropdown-item @click="editorFooter.chain().focus().sinkListItem('listItem').run()" :disabled="!editorFooter.can().sinkListItem('listItem')" title="Aumentar sangría (Tab)">
                                        <i class="ri-indent-increase tiptap-icon__fw"></i>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="editorFooter.chain().focus().liftListItem('listItem').run()" :disabled="!editorFooter.can().liftListItem('listItem')" title="Reducir sangría (Shift + Tab)">
                                        <i class="ri-indent-decrease tiptap-icon__fw"></i>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>
                            <!-- horizontal rule -->
                            <b-button @click="editorFooter.chain().focus().setHorizontalRule().run()" variant="outline-primary" size="sm" title="Separador">
                                <i class="ri-separator ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <!-- hard break -->
                            <b-button @click="editorFooter.chain().focus().setHardBreak().run()" variant="outline-primary" size="sm" title="Salto de linea en párrafo (Shift + Enter)">
                                <i class="ri-text-wrap ri-lg tiptap-icon__fw"></i>
                            </b-button>

                            <span class="tiptap-divider">|</span>
                        </b-col>

                        <b-col cols="12" class="mt-3">
                            <!-- image -->
                            <b-button @click="addInlineImage('footer')" variant="outline-primary" size="sm" title="Imagen">
                                <i class="ri-image-2-line ri-lg tiptap-icon__fw"> (linea)</i>
                            </b-button>
                            <b-button @click="addBlockImage('footer')" variant="outline-primary" size="sm" title="Imagen">
                                <i class="ri-image-fill ri-lg tiptap-icon__fw"> (Bloque)</i>
                            </b-button>

                            <span class="tiptap-divider">|</span>

                            <!-- signatures -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-contract-1" size="sm" title="Postulante">
                                    Postulante - firma : 
                                </b-button>
                                <b-button @click="addSignatureSmall('footer')" variant="outline-contract-1" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                                <b-button @click="addSignatureMedium('footer')" variant="outline-contract-1" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-lg tiptap-icon__fw"> md</i>
                                </b-button>
                                <b-button @click="addSignatureLarge('footer')" variant="outline-contract-1" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-xl tiptap-icon__fw"> lg</i>
                                </b-button>
                            </b-button-group>

                            <!-- admin signatures -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-schedule-english-3" size="sm" title="Gerencia">
                                    Gerencia - firma:
                                </b-button>
                                <b-button @click="addAdminSignatureMedium('footer')" variant="outline-schedule-english-3" size="sm" title="Imagen">
                                    <i class="ri-edit-fill ri-lg tiptap-icon__fw"> md</i>
                                </b-button>
                            </b-button-group>

                            <!-- fingerprint -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-employer-interview-3" size="sm" title="Postulante">
                                    Postulante - huella:
                                </b-button>
                                <b-button @click="addFirgerprint('footer')" variant="outline-employer-interview-3" size="sm" title="Huella">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                            </b-button-group>

                            <span class="tiptap-divider">|</span>

                            <!-- document -->
                            <b-button-group class="mr-2">
                                <b-button variant="outline-employer-interview-3" size="sm" title="Postulante">
                                    DNI-anverso:
                                </b-button>
                                <b-button @click="addDniAnverso('footer')" variant="outline-employer-interview-3" size="sm" title="anverso">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                            </b-button-group>

                            <b-button-group class="mr-2">
                                <b-button variant="outline-employer-interview-3" size="sm" title="Postulante">
                                    DNI-reverso:
                                </b-button>
                                <b-button @click="addDniReverso('footer')" variant="outline-employer-interview-3" size="sm" title="reverso">
                                    <i class="ri-edit-fill ri-1x tiptap-icon__fw"> sm</i>
                                </b-button>
                            </b-button-group>


                        </b-col>
                    </b-row>
                </b-col>

                <div class="editor--divider border-top border-primary"></div>
                <div class="text-center text-primary text-uppercase w-100">footer</div>
                <div class="editor--divider border-top border-primary"></div>

                <b-col cols="12" class="editor--footer">
                    <editor-content :editor="editorFooter" spellcheck="false" class="editor--footer__layout"/>
                </b-col>
            </b-row>
        </b-container>

        <contract-preview-component :preview="preview" :html="output_html_to_preview" :ui="uiMode"></contract-preview-component>
    </b-card>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'

import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Focus from '@tiptap/extension-focus'
import FontFamily from '@tiptap/extension-font-family'

import History from '@tiptap/extension-history'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import TextAlign from '@tiptap/extension-text-align'

import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import Highlight from '@tiptap/extension-highlight'

import Link from '@tiptap/extension-link'

import TableCustom from '@/components/Tiptap/extensions/nodes/TableCustom'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Gapcursor from '@tiptap/extension-gapcursor'

import Heading from '@tiptap/extension-heading'

import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'

import ImageBlockCustom from '@/components/Tiptap/extensions/nodes/ImageBlockCustom'
import ImageInlineCustom from '@/components/Tiptap/extensions/nodes/ImageInlineCustom'
import ApkSignatureSmall from '@/components/Tiptap/extensions/nodes/ApkSignatureSmall'
import ApkSignatureMedium from '@/components/Tiptap/extensions/nodes/ApkSignatureMedium'
import ApkSignatureLarge from '@/components/Tiptap/extensions/nodes/ApkSignatureLarge'
import ApkAdminSignatureMedium from '@/components/Tiptap/extensions/nodes/ApkAdminSignatureMedium'
import ApkFingerprint from '@/components/Tiptap/extensions/nodes/ApkFingerprint'
import ApkDniReverso from '@/components/Tiptap/extensions/nodes/ApkDniReverso'
import ApkDniAnverso from '@/components/Tiptap/extensions/nodes/ApkDniAnverso'

import Dropcursor from '@tiptap/extension-dropcursor'

import HorizontalRule from '@tiptap/extension-horizontal-rule'
import HardBreak from '@tiptap/extension-hard-break'
// custom extensions
import ApkContractMention from '@/components/Tiptap/extensions/nodes/ApkContractMention'
import ApkContractSuggestion from '@/components/Tiptap/extensions/nodes/ApkContractSuggestion'
import ContractPreviewComponent from '@/components/Tiptap/components/ContractPreviewComponent.vue'

export default {
    name: 'Tiptap',
    components: {
        EditorContent,
        ContractPreviewComponent
    },
    props: {
        uiMode: {
            type: String
        },
        initialBodyValue: {
            type: String,
        },
        initialFooterValue: {
            type: String,
        },
        getHtml: {
            type: Number,
        }
    },
    data: () => ({
        editor: null,
        editorFooter: null,
        colorHighlight: '#000000',
        //
        preview: 1,
        output_html_to_preview: {}
    }),

    mounted() {
        this.tiptapEditorInit()
        this.editor.commands.setContent(this.initialBodyValue)
        //
        this.tiptapEditorFooterInit()
        this.editorFooter.commands.setContent(this.initialFooterValue)
    },

    watch: {
        getHtml() {
            this.$emit('output', {
                body: this.editor.getHTML(),
                footer: this.editorFooter.getHTML(),
            })
        }
    },

    methods: {
        tiptapEditorInit() {
            this.editor = new Editor({
                extensions:[
                    Document,   
                    Paragraph.extend({
                        addAttributes() {
                            return {
                                ...this.parent?.(),
                                style: {
                                    default: null,
                                    parseHTML: element => element.getAttribute('style'),
                                    renderHTML: () => ({
                                        style: 'margin: 0px 0px 2px 0px;'
                                    })
                                },
                            }
                        }
                    }),
                    Text,
                    Focus.configure({
                        className: 'has-focus',
                        mode: 'all',
                    }),
                    FontFamily,
                    //
                    History,
                    TextStyle,
                    Color,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    //
                    Bold.extend({
                        addAttributes() {
                            return {
                                ...this.parent?.(),
                                style: {
                                    default: null,
                                    parseHTML: element => element.getAttribute('style'),
                                    renderHTML: () => ({
                                        style: 'font-weight:700;'
                                    })
                                },
                            }
                        }
                    }),
                    Italic,
                    Underline,
                    Strike,
                    Highlight.configure({ multicolor: true }),
                    Link,
                    // Table,
                    TableCustom,
                    //     resizable: true,
                    //     HTMLAttributes: {
                    //         class: 'apk-table',
                    //     },
                    // }),
                    TableRow,
                    TableHeader,
                    TableCell,
                    Gapcursor,
                    //
                    Heading.configure({
                        HTMLAttributes: {
                            class: 'tiptap-heading',
                        },
                    }),
                    //
                    BulletList,
                    ListItem,
                    OrderedList,
                    //
                    ImageInlineCustom,
                    ImageBlockCustom,
                    ApkSignatureSmall,
                    ApkSignatureMedium,
                    ApkSignatureLarge,
                    ApkAdminSignatureMedium,
                    //
                    ApkFingerprint,
                    ApkDniReverso,
                    ApkDniAnverso,
                    //
                    Dropcursor,
                    //
                    HorizontalRule,
                    HardBreak,
                    //custom extensions
                    ApkContractMention.configure({
                        HTMLAttributes: {
                            class: 'mentiones',
                        },
                    suggestion: ApkContractSuggestion,
                }),
                ]
            })
        },

        tiptapEditorFooterInit() {
            this.editorFooter = new Editor({
                extensions:[
                    Document,   
                    Paragraph.extend({
                        addAttributes() {
                            return {
                                ...this.parent?.(),
                                style: {
                                    default: null,
                                    parseHTML: element => element.getAttribute('style'),
                                    renderHTML: () => ({
                                        style: 'margin: 0px 0px 2px 0px;'
                                    })
                                },
                            }
                        }
                    }),
                    Text,
                    Focus.configure({
                        className: 'has-focus',
                        mode: 'all',
                    }),
                    FontFamily,
                    //
                    History,
                    TextStyle,
                    Color,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    //
                    Bold.extend({
                        addAttributes() {
                            return {
                                ...this.parent?.(),
                                style: {
                                    default: null,
                                    parseHTML: element => element.getAttribute('style'),
                                    renderHTML: () => ({
                                        style: 'font-weight:600;'
                                    })
                                },
                            }
                        }
                    }),
                    Italic,
                    Underline,
                    Strike,
                    Highlight.configure({ multicolor: true }),
                    Link,
                    //
                    TableCustom,
                    TableRow,
                    TableHeader,
                    TableCell,
                    Gapcursor,
                    //
                    Heading.configure({
                        HTMLAttributes: {
                            class: 'tiptap-heading',
                        },
                    }),
                    //
                    BulletList,
                    ListItem,
                    OrderedList,
                    //
                    ImageInlineCustom,
                    ImageBlockCustom,
                    ApkSignatureSmall,
                    ApkSignatureMedium,
                    ApkSignatureLarge,
                    ApkAdminSignatureMedium,
                    //
                    ApkFingerprint,
                    //
                    Dropcursor,
                    //
                    HorizontalRule,
                    HardBreak,
                    //custom extensions
                    ApkContractMention.configure({
                        HTMLAttributes: {
                            class: 'mentiones',
                        },
                    suggestion: ApkContractSuggestion,
                }),
                ]
            })
        },

        setLink(pTiptapType) {
            let previousUrl = ''
            let url = ''
            switch (pTiptapType) {
                case 'body':
                    previousUrl = this.editor.getAttributes('link').href
                    url = window.prompt('URL', previousUrl)
                    // cancelled
                    if (url === null) return
                    // empty
                    if (url === '') {
                        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
                        return
                    }
                    // update link
                    this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
                    break;

                case 'footer':
                    previousUrl = this.editorFooter.getAttributes('link').href
                    url = window.prompt('URL', previousUrl)
                    // cancelled
                    if (url === null) return
                    // empty
                    if (url === '') {
                        this.editorFooter.chain().focus().extendMarkRange('link').unsetLink().run()
                        return
                    }
                    // update link
                    this.editorFooter.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
                    break;

                default:
                    break;
            }

        },
        addInlineImage(pTiptapType) {
            const url = window.prompt('URL')
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setInlineImage({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setInlineImage({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        addBlockImage(pTiptapType) {
            const url = window.prompt('URL')
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setBlockImage({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setBlockImage({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        addSignatureSmall(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/sign/apk-firma.jpg"
            // const url = "/img/sign/apk-firma.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setSignatureSmall({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setSignatureSmall({ src: url }).run()
                    break;

                default:
                    break;
            }
            
        },
        addSignatureMedium(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/sign/apk-firma.jpg"
            // const url = "/img/sign/apk-firma.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setSignatureMedium({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setSignatureMedium({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        addSignatureLarge(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/sign/apk-firma.jpg"
            // const url = "/img/sign/apk-firma.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setSignatureLarge({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setSignatureLarge({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        // admin signature
        addAdminSignatureMedium(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/sign/admin-sign-3-S-edit-record-resize.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setAdminSignatureMedium({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setAdminSignatureMedium({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        // postulante fingerprint
        addFirgerprint(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/fingerprint/apk-fingerprint.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setFingerprint({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setFingerprint({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        // postulante fingerprint
        addDniAnverso(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-anverso.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setDniAnverso({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setDniAnverso({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        // postulante fingerprint
        addDniReverso(pTiptapType) {
            const url="https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-reverso.jpg"
            switch (pTiptapType) {
                case 'body':
                    if (url) this.editor.chain().focus().setDniReverso({ src: url }).run()
                    break;

                case 'footer':
                    if (url) this.editorFooter.chain().focus().setDniReverso({ src: url }).run()
                    break;

                default:
                    break;
            }
        },
        //
        agregarTablaNormal(pTiptapType) {
            switch (pTiptapType) {
                case 'body':
                    this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
                    this.editor.chain().focus().updateAttributes('TableCustom', { 'data-table-kind': 'normal-table' }).run()
                    break;

                case 'footer':
                    this.editorFooter.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
                    this.editorFooter.chain().focus().updateAttributes('TableCustom', { 'data-table-kind': 'normal-table' }).run()
                    break;

                default:
                    break;
            }
        },
        agregarTablaGrid(pTiptapType) {
            switch (pTiptapType) {
                case 'body':
                    this.editor.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: false }).run()
                    this.editor.chain().focus().updateAttributes('TableCustom', { 'data-table-kind': 'grid-table' }).run()
                    break;

                case 'footer':
                    this.editorFooter.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: false }).run()
                    this.editorFooter.chain().focus().updateAttributes('TableCustom', { 'data-table-kind': 'grid-table' }).run()
                    break;

                default:
                    break;
            }
            
        },

        /** */
        showPreview() {
            this.preview += 1
            this.output_html_to_preview = {
                body: this.editor.getHTML(),
                footer: this.editorFooter.getHTML(),
            }
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    }
}
</script>

<style>
/**editor */
.editor{
    border-width: 3px!important;
    overflow: hidden;
}
.editor--header{
    margin-top: 15px;
    margin-bottom: 15px;
}
.editor--divider {
    width: 100%;
    border-width: 3px!important;;
}
.editor--body {
    display: flex;
    justify-content: center;
    max-height: 65vh;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow-y: auto;
}
.editor--body__layout {
    width: 240mm;
}
.editor--footer{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.editor--footer__layout {
    width: 240mm;
}
.tiptap-divider {
    position: relative;
    margin-right: 8px;
    color: lightgray;
}
.tiptap-dropdonw {
    min-width: max-content;
}
.tiptap-dropdonw__color {
    height: auto;
    width: 25px;
    border: 1px solid  #5e72e4;
    background-color: transparent;
}
.has-focus {
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(94, 114, 228, .4);
}
.tiptap-icon__fw {
    font-weight: 100;
}
.mentiones {
    border: 1px solid #EF4A81;
    border-style: dashed;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
    color:#EF4A81;
}
/* CONTENT */
.ProseMirror{
    padding-top: 1px;
    padding-bottom: 1px;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}
.ProseMirror:focus{
    outline: none;
}
.ProseMirror p{
    margin: 0px 0px 2px 0px;
}
.tiptap-heading {
    color: #000;
}
/* table Prose Mirror */
.ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
}
.ProseMirror table td, .ProseMirror table th {
    min-width: 1em;
    /* border: 2px solid #ced4da; */
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
}
.ProseMirror table.tiptap-table.tiptap-normal-table td, .ProseMirror table.tiptap-table.tiptap-normal-table th {
    border: 2px solid #ced4da!important;
}
.ProseMirror table.tiptap-table.tiptap-grid-table td, .ProseMirror table.tiptap-table.tiptap-grid-table th {
    border: 2px solid #FF8243!important;
    border-style: dotted!important;
}
.ProseMirror table td > *, .ProseMirror table th > * {
    margin-bottom: 0;
}
.ProseMirror table th, .ProseMirror table th p {
    font-weight: 600;
    text-align: left;
    background-color: #f1f3f5;
}
.ProseMirror table .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
}
.ProseMirror table .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    pointer-events: none;
}
.ProseMirror table p {
    margin: 0;
}
.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}
.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}
/* end table Prose Mirror */
</style>
