<template>
    <canvas :id="id" width="400" height="400"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    name: 'GeneralBarChartComponent',

    props: {
        id: {
            type: String,
            required: true,
        },
        graphType: {
            type: String,
        },
        labels: {
            type: Array,
        },
        datasets: {
            type: Array,
        },
        options: {
            type: Object,
        },
        moreData: {
            type: Object,
            default: () => ({
                ticks: {
                    color: '#fff',
                    display: true
                },
                stacked: false
            })
        },
    },

    data: () => ({
        graph: null,
    }),

    mounted() {
        this.chartInit()
    },

    methods: {
        chartInit() {
            let ctx = document.querySelector('#' + this.id)
            this.graph = new Chart(ctx, {
                type: this.graphType,

                data: {
                    labels: this.labels,
                    datasets: this.datasets
                },

                options: {
                    ...this.options,
                    //
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: this.moreData.stacked,
                            grid: {
                                borderColor: 'rgb(255,255,255)',
                                color: 'rgba(255,255,255, 0.15)',
                                tickColor: 'rgba(255, 0,0, 0)',
                            },
                            ticks: {
                                display: this.moreData.ticks.display,
                                padding: 10,
                                color: this.moreData.ticks.color,
                            }
                        },
                        y: {
                            stacked: this.moreData.stacked,
                            grid: {
                                borderColor: 'rgb(255,255,255)',
                                color: 'rgba(255,255,255, 0.15)',
                                tickColor: 'rgba(255, 0,0, 0)'
                            },
                            ticks: {
                                display: this.moreData.ticks.display,
                                padding: 10,
                                color: this.moreData.ticks.color,
                            }
                        }
                    },
                }
            })
        }
    }
}
</script>