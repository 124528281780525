import Vue from 'vue'
// import Router from 'vue-router'
import VueRouter from 'vue-router';
//
// import Page from '@/views/Page'
// import Page from './views/Page'
// import Admin from './views/Admin'
// import Employee from './views/Employee'
// import Participant from './views/Participant'
// import Postulant from './views/Postulant'
// import NotActiveRoles from './views/NotActiveRoles'
import NotFound from './views/DefaultViews/NotFound.vue'
import OnePage from './views/DefaultViews/OnePage.vue'
//
import store from './store'

// Vue.use(Router)
Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
        /**
         * page
         */
        {
            path: '/',
            component: () => import(/* webpackChunkName: "Page" */ '@/views/Page'),
            beforeEnter: (to, from, next) => {
                if (Object.keys(store.state.user.attributes).length != 0) {
                    if (store.getters['user/getActiveRoles'].length != 0) {
                        switch (store.getters['user/getActiveRoles'][0].value) {
                            case 'admin':
                                next({ name: 'admin-dashboard' })
                                break;
                            case 'empleado':
                                next({ name: 'employee-dashboard' })
                                break;
                            case 'participante':
                                next({ name: 'participant-dashboard' })
                                break;
                            case 'postulante':
                                next({ name: 'postulant-dashboard' })
                                break;
                            default:
                                console.log('break page route')
                                break;
                        }
                    } else next({ name: 'notActiveRoles-dashboard' })
                }
                else next()
            },
            children: [
                {
                    path: '',
                    name: 'login',
                    component: () => import(/* webpackChunkName: "page-login" */ '@/views/Page/Login')
                },
            ]
        },

        /**
         * ADMIN
         */
        {
            path: '/admin',
            component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin'),
            beforeEnter: (to, from, next) => {
                if (Object.keys(store.state.user.attributes).length != 0) {
                    if (store.getters['user/hasRoleAndIsActive']('admin')) next()
                    else {
                        switch (store.state.user.attributes.roles[0].attributes.role_name) {
                            case 'admin':
                                next({ name: 'admin-dashboard' })
                                break;
                            case 'empleado':
                                next({ name: 'employee-dashboard' })
                                break;
                            case 'postulante':
                                next({ name: 'postulant-dashboard' })
                                break;
                            case 'participante':
                                next({ name: 'participant-dashboard' })
                                break;
                            default:
                                console.log('break page route')
                                break;
                        }
                    }
                } else next({ name: 'login' })
            },
            children: [
                {
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/Admin/Dashboard/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-dashboard',
                            component: () => import(/* webpackChunkName: "admin-dashboard-home" */ '@/views/Admin/Dashboard/Home.vue')
                        },
                        // {
                        //     path: 'test',
                        //     name: 'admin-dashboard-test',
                        //     component: () => import(/* webpackChunkName: "admin-dashboard-test" */ '@/views/Admin/Dashboard/Test.vue')
                        // },
                    ]
                },
            

                // admin-backups
                {
                    path: 'admin-backups',
                    component: () => import(/* webpackChunkName: "admin-backups" */ '@/views/Admin/Backups'),
                    children: [
                        {
                            path: '',
                            name: 'admin-backups-backup',
                            component: () => import(/* webpackChunkName: "admin-backups-backup" */ '@/views/Admin/Backups/Backup.vue')
                        },
                    ]
                },

                // admin-search
                {
                    path: 'admin-search',
                    component: () => import(/* webpackChunkName: "admin-search" */ '@/views/Admin/Search/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-search-user',
                            component: () => import(/* webpackChunkName: "admin-search-user" */ '@/views/Admin/Search/SearchUser.vue')
                        },
                        {
                            path: 'voucher',
                            name: 'admin-search-voucher',
                            component: () => import(/* webpackChunkName: "admin-search-voucher" */ '@/views/Admin/Search/SearchVoucher.vue')
                        },
                    ]
                },

                // admin-export
                {
                    path: 'admin-export',
                    component: () => import(/* webpackChunkName: "admin-export" */ '@/views/Admin/Export/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-export',
                            component: () => import(/* webpackChunkName: "admin-export-home" */ '@/views/Admin/Export/Home.vue')
                        },
                        {
                            path: 'participant-information',
                            name: 'admin-export-participant-information',
                            component: () => import(/* webpackChunkName: "admin-export-participant-information" */ '@/views/Admin/Export/ParticipantInformation.vue')
                        },
                        {
                            path: 'payments-vouchers',
                            name: 'admin-export-payments-vouchers',
                            component: () => import(/* webpackChunkName: "admin-export-payments-vouchers" */ '@/views/Admin/Export/PaymentsVouchers.vue')
                        },
                        {
                            path: 'participant-documents',
                            name: 'admin-export-participant-documents',
                            component: () => import(/* webpackChunkName: "admin-export-participant-documents" */ '@/views/Admin/Export/ParticipantDocuments.vue')
                        },
                    ]
                },

                // admin-employees
                {
                    path: 'admin-employees',
                    component: () => import(/* webpackChunkName: "admin-employees" */ '@/views/Admin/Employees'),
                    children: [
                        {
                            path: '',
                            name: 'admin-employees-list',
                            component: () => import(/* webpackChunkName: "admin-employees-list" */ '@/views/Admin/Employees/EmployeesList.vue')
                        },
                        {
                            path: 'details/:id', // IdEmployee
                            name: 'admin-employees-details',
                            component: () => import(/* webpackChunkName: "admin-employees-details" */ '@/views/Admin/Employees/EmployeesDetails.vue')
                        },
                        {
                            path: 'create',
                            name: 'admin-employees-create',
                            component: () => import(/* webpackChunkName: "admin-employees-create" */ '@/views/Admin/Employees/EmployeesCreate')
                        },
                    ]
                },

                // admin-admins
                {
                    path: 'admin-admins',
                    component: () => import(/* webpackChunkName: "admin-admins"*/ '@/views/Admin/Admins'),
                    children: [
                        {
                            path: '',
                            name: 'admin-admins-list',
                            component: () => import(/* webpackChunkName: "admin-admins-list" */ '@/views/Admin/Admins/AdminsList.vue')

                        },
                        {
                            path: 'details/:id', // IdAdmin
                            name: 'admin-admins-details',
                            component: () => import(/* webpackChunkName: "admin-admins-details" */ '@/views/Admin/Admins/AdminsDetails.vue')
                        },
                        {
                            path: 'create',
                            name: 'admin-admins-create',
                            component: () => import(/* webpackChunkName: "admin-admins-create" */ '@/views/Admin/Admins/AdminsCreate')
                        },
                    ]
                },

                // admin-participants
                {
                    path: 'admin-participants',
                    component: () => import(/* webpackChunkName: "admin-participants"*/ '@/views/Admin/Participants'),
                    children: [
                        {
                            path: '',
                            name: 'admin-participants-list',
                            component: () => import(/* webpackChunkName: "admin-participants-list" */ '@/views/Admin/Participants/ParticipantsList.vue')
                        },
                        {
                            path: 'admin-participants-filters',
                            component: () => import(/* webpackChunkName: "admin-participants-filters" */ '@/views/Admin/Participants/Filters/index.vue'),
                            children: [
                                {
                                    path: '',
                                    name: 'admin-participants-filter-documents',
                                    component: () => import(/* webpackChunkName: "admin-participants-filter-documents" */ '@/views/Admin/Participants/Filters/DocumentsFilter.vue'),
                                },
                                {
                                    path: 'filter-documents-last-uploads',
                                    name: 'admin-participants-filter-documents-last-uploads',
                                    component: () => import(/* webpackChunkName: "admin-participants-filter-documents-last-uploads" */ '@/views/Admin/Participants/Filters/LastDocuments.vue'),
                                },
                                {
                                    path: 'filter-payments',
                                    name: 'admin-participants-filter-payments',
                                    component: () => import(/* webpackChunkName: "admin-participants-filter-payments" */ '@/views/Admin/Participants/Filters/PaymentsFilter.vue'),
                                },
                            ]
                        },
                        {
                            path: 'test',
                            name: 'admin-participants-list-test',
                            component: () => import(/* webpackChunkName: "admin-participants-list-test" */ '@/views/Admin/Participants/ParticipantsListTest.vue')
                        },
                        //
                        {
                            path: 'details/:id', // IdParticipant
                            name: 'admin-participants-details',
                            component: () => import(/* webpackChunkName: "admin-participants-details" */ '@/views/Admin/Participants/ParticipantsDetails.vue')
                        },
                        {
                            path: 'participant/:IdProgramSeasonParticipant/:IdProgramSeasonPostulant',
                            component: () => import(/* webpackChunkName: "admin-participants" */ '@/views/Admin/Participants/Participant'),
                            children: [
                                {
                                    path: '',
                                    name: 'admin-participants-detail',
                                    component: () => import(/* webpackChunkName: "admin-participants-detail" */ '@/views/Admin/Participants/Participant/ParticipantDetails.vue'),
                                },
                                {
                                    path: 'payments-vouchers',
                                    name: 'admin-participants-payments-vouchers',
                                    component: () => import(/* webpackChunkName: "admin-participants-payments-vouchers" */ '@/views/Admin/Participants/Participant/ParticipantPaymentsVouchers.vue'),
                                },
                                {
                                    path: 'ficha',
                                    name: 'admin-participants-ficha',
                                    component: () => import(/* webpackChunkName: "admin-participants-ficha" */ '@/views/Admin/Participants/Participant/ParticipantFicha.vue'),
                                },
                                // interviews-list
                                {
                                    path: 'interviews',
                                    name: 'admin-participants-interviews',
                                    component: () => import(/* webpackChunkName: "admin-participants-interviews" */ '@/views/Admin/Participants/Participant/InterviewList.vue'),
                                },
                                 // interviews-details
                                {
                                    path: 'interviews/:IdEnglishInterviewSchedule/details',  // englishInterviewShedule_id
                                    name: 'admin-participants-interview-details',
                                    component: () => import(/* webpackChunkName: "admin-participants-interview-details" */ '@/views/Admin/Participants/Participant/InterviewDetails.vue'),
                                },
                                {
                                    path: 'contracts',
                                    name: 'admin-participants-contracts',
                                    component: () => import(/* webpackChunkName: "admin-participants-contracts" */ '@/views/Admin/Participants/Participant/ContractsList.vue'),
                                },
                                {
                                    path: 'contracts/:IdContract/details',
                                    name: 'admin-participants-contract-details',
                                    component: () => import(/* webpackChunkName: "admin-participants-contract-details" */ '@/views/Admin/Participants/Participant/ContractDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'admin-participants-documents',
                                    component: () => import(/* webpackChunkName: "admin-participants-documents" */ '@/views/Admin/Participants/Participant/ParticipantDocuments.vue'),
                                },
                                //
                                {
                                    path: 'capacitations',
                                    name: 'admin-participant-capacitations',
                                    component: () => import(/* webpackChunkName: "admin-participant-capacitations" */ '@/views/Admin/Participants/Participant/Capacitations'),
                                },
                                {
                                    path: 'talks',
                                    name: 'admin-participant-talks',
                                    component: () => import(/* webpackChunkName: "admin-participant-talks" */ '@/views/Admin/Participants/Participant/Talks'),
                                },
                                {
                                    path: 'visa',
                                    name: 'admin-participant-visa',
                                    component: () => import(/* webpackChunkName: "admin-participant-visa" */ '@/views/Admin/Participants/Participant/Visa'),
                                },
                                {
                                    path: 'attempts',
                                    component: () => import(/* webpackChunkName: "admin-participants-attempts" */ '@/views/Admin/Participants/Participant/ParticipantAttempts/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'admin-participants-attempts-list',   //postulaciones
                                            component: () => import(/* webpackChunkName: "admin-participants-attempts-list" */ '@/views/Admin/Participants/Participant/ParticipantAttempts/AttemptsList.vue')
                                        },
                                        {
                                            path: 'attempt/:IdPostulation',
                                            component: () => import(/* webpackChunkName: "admin-participants-attempt" */ '@/views/Admin/Participants/Participant/ParticipantAttempts/Attempt/index.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'admin-participants-attempt-details',
                                                    component: () => import(/* webpackChunkName: "admin-participants-attempt-details" */ '@/views/Admin/Participants/Participant/ParticipantAttempts/Attempt/AttemptDetails.vue')
                                                },
                                            ]
                                        },
                                    ]

                                },
                            ]
                        },
                    ]
                },

                // admin-postulants
                {
                    path: 'admin-postulants',
                    component: () => import(/* webpackChunkName: "admin-postulants"*/ '@/views/Admin/Postulants'),
                    children: [
                        {
                            path: '',
                            name: 'admin-postulants-list',
                            component: () => import(/* webpackChunkName: "admin-postulants-list" */ '@/views/Admin/Postulants/PostulantsList.vue')
                        },
                        {
                            path: 'test',
                            name: 'admin-postulants-list-test',
                            component: () => import(/* webpackChunkName: "admin-postulants-list-test" */ '@/views/Admin/Postulants/PostulantsListTest.vue')
                        },
                        {
                            path: 'details/:id', // IdPostulant
                            name: 'admin-postulants-details',
                            component: () => import(/* webpackChunkName: "admin-postulants-details" */ '@/views/Admin/Postulants/PostulantsDetails.vue')
                        },
                        {
                            path: 'postulant/:IdProgramSeasonPostulant', // IdProgramSeasonPostulant
                            component: () => import(/* webpackChunkName: "admin-postulants" */ '@/views/Admin/Postulants/Postulant'),
                            children: [
                                {
                                    path: '',
                                    name: 'admin-postulants-detail',
                                    component: () => import(/* webpackChunkName: "admin-postulants-detail" */ '@/views/Admin/Postulants/Postulant/PostulantDetails.vue'),
                                },
                                {
                                    path: 'payments-vouchers',
                                    name: 'admin-postulants-payments-vouchers',
                                    component: () => import(/* webpackChunkName: "admin-postulants-payments-vouchers" */ '@/views/Admin/Postulants/Postulant/PostulantPaymentsVouchers.vue'),
                                },
                                // interviews-list
                                {
                                    path: 'interviews',
                                    name: 'admin-postulants-interviews',
                                    component: () => import(/* webpackChunkName: "admin-postulants-interviews" */ '@/views/Admin/Postulants/Postulant/InterviewList.vue'),
                                },
                                 // interviews-details
                                {
                                    path: 'interviews/:IdEnglishInterviewSchedule/details',  // englishInterviewShedule_id
                                    name: 'admin-postulants-interview-details',
                                    component: () => import(/* webpackChunkName: "admin-postulants-interview-details" */ '@/views/Admin/Postulants/Postulant/InterviewDetails.vue'),
                                },
                                // contracts-list
                                {
                                    path: 'contracts',
                                    name: 'admin-postulants-contracts',
                                    component: () => import(/* webpackChunkName: "admin-postulants-contracts" */ '@/views/Admin/Postulants/Postulant/ContractsList.vue'),
                                },
                                {
                                    path: 'contracts/create',
                                    name: 'admin-postulants-contract-create',
                                    component: () => import(/* webpackChunkName: "admin-postulants-contract-create" */ '@/views/Admin/Postulants/Postulant/ContractCreate.vue'),
                                },
                                {
                                    path: 'contracts/:IdContract/details',
                                    name: 'admin-postulants-contract-details',
                                    component: () => import(/* webpackChunkName: "admin-postulants-contract-details" */ '@/views/Admin/Postulants/Postulant/ContractDetails.vue'),
                                },
                            ]
                        },
                    ]
                },

                // admin-programs
                {
                    path: 'admin-programs',
                    component: () => import(/* webpackChunkName: "admin-programs"*/ '@/views/Admin/Programs'),
                    children: [
                        {
                            path: '',
                            name: 'admin-programs-seasons',
                            component: () => import(/* webpackChunkName: "admin-programs-seasons" */ '@/views/Admin/Programs/ProgramsSeasons.vue')
                        },
                        {
                            path: 'program-season-details/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "admin-programs-season-details" */ '@/views/Admin/Programs/ProgramSeason/index.vue'),
                            children: [
                                {
                                    path: '',
                                    name: 'admin-program-season-details',
                                    component: () => import(/* webpackChunkName: "admin-programs-season-details" */ '@/views/Admin/Programs/ProgramSeason/Details.vue'),
                                },
                                {
                                    path: 'sponsors',
                                    name: 'admin-program-sponsors-list',
                                    component: () => import(/* webpackChunkName: "admin-program-sponsors-list" */ '@/views/Admin/Programs/ProgramSeason/SponsorsList.vue'),
                                },
                                {
                                    path: 'sponsor/:IdProgramSeasonSponsor',
                                    component: () => import(/* webpackChunkName: "employee-wat-sponsors-details" */ '@/views/Admin/Programs/ProgramSeason/Sponsor/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'admin-program-sponsor-details',
                                            component: () => import(/* webpackChunkName: "admin-program-sponsor-details" */ '@/views/Admin/Programs/ProgramSeason/Sponsor/SponsorDetails.vue'),
                                        },
                                        {
                                            path: 'joboffers',
                                            name: 'admin-program-sponsor-joboffers',
                                            component: () => import(/* webpackChunkName: "admin-program-sponsor-joboffers" */ '@/views/Admin/Programs/ProgramSeason/Sponsor/SponsorJoboffers.vue'),
                                        },
                                        {
                                            path: 'postulations',
                                            name: 'admin-program-sponsor-postulations',
                                            component: () => import(/* webpackChunkName: "admin-program-sponsor-postulations" */ '@/views/Admin/Programs/ProgramSeason/Sponsor/SponsorPostulations.vue'),
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },

                // admin-invalidVouchers
                {
                    path: 'admin-invalidVouchers',
                    component: () => import(/* webpackChunkName: "admin-invalidVouchers"*/ '@/views/Admin/InvalidVouchers/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-invalidVouchers-list',
                            component: () => import(/* webpackChunkName: "admin-invalidVouchers-list" */ '@/views/Admin/InvalidVouchers/InvalidVoucherList.vue')
                        },
                        // {
                        //     path: 'invalidVoucher/:IdSponsor',
                        //     name: 'admin-sponsor-jobs-offer-inputs',
                        //     component: () => import(/* webpackChunkName: "admin-invalidVouchers-details" */ '@/views/Admin/InvalidVoucher/InvalidVoucherDetails.vue'),
                        // },
                    ]
                },
               
                // admin-postponements
                {
                    path: 'admin-postponements',
                    component: () => import(/* webpackChunkName: "admin-postponements"*/ '@/views/Admin/Postponements/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-postponements-list',
                            component: () => import(/* webpackChunkName: "admin-postponements-list" */ '@/views/Admin/Postponements/PostponementsList.vue')
                        },
                    ]
                },

                // admin-abandonment
                {
                    path: 'admin-abandonments',
                    component: () => import(/* webpackChunkName: "admin-abandonments"*/ '@/views/Admin/Abandonments/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-abandonments-list',
                            component: () => import(/* webpackChunkName: "admin-abandonments-list" */ '@/views/Admin/Abandonments/AbandonmentsList.vue')
                        },
                    ]
                },

                // admin-sponsor
                {
                    path: 'admin-sponsor',
                    component: () => import(/* webpackChunkName: "admin-sponsor"*/ '@/views/Admin/Sponsor'),
                    children: [
                        {
                            path: '',
                            name: 'admin-sponsor-list',
                            component: () => import(/* webpackChunkName: "admin-sponsor-list" */ '@/views/Admin/Sponsor/SponsorList.vue')
                        },
                        {
                            path: 'sponsor/:IdSponsor',
                            component: () => import(/* webpackChunkName: "admin-sponsor-jobs-offer" */ '@/views/Admin/Sponsor/SponsorJobOffers/index.vue'),
                            children: [
                                {
                                    path: '',
                                    name: 'admin-sponsor-jobs-offer-list',
                                    component: () => import(/* webpackChunkName: "admin-sponsor-jobs-offer-list" */ '@/views/Admin/Sponsor/SponsorJobOffers/SponsorJobOffersList.vue'),
                                },
                                {
                                    path: 'sponsor-job-offer/:IdSponsorJobOffer/inputs',
                                    name: 'admin-sponsor-jobs-offer-inputs',
                                    component: () => import(/* webpackChunkName: "admin-sponsor-jobs-offer-inputs" */ '@/views/Admin/Sponsor/SponsorJobOffers/JobOffersInputs.vue'),
                                }
                            ]
                        },
                        {
                            path: 'admin-sponsor-general-job-inputs',
                            name: 'admin-sponsor-general-job-inputs',
                            component: () => import(/* webpackChunkName: "admin-sponsor-general-job-inputs" */ '@/views/Admin/Sponsor/GeneralJobInputs.vue')
                        },
                    ]
                },

                // LAYOUTS
                // CONTRACTS
                {
                    path: 'admin-layouts-contracts',
                    component: () => import(/* webpackChunkName: "admin-layouts-contracts"*/ '@/views/Admin/Layouts/Contracts/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-layouts-contracts-list',
                            component: () => import(/* webpackChunkName: "admin-layouts-contracts-list" */ '@/views/Admin/Layouts/Contracts/ContractLayoutList.vue')
                        },
                        {
                            path: 'contract-layouts/:IdContractLayout', //~ IdHtmlLayout
                            name: 'admin-layouts-contracts-contractLayout',
                            component: () => import(/* webpackChunkName: "admin-layouts-contracts-contractLayout" */ '@/views/Admin/Layouts/Contracts/ContractLayout.vue'),
                        },
                        {
                            path: 'create',
                            name: 'admin-layouts-contracts-create',
                            component: () => import(/* webpackChunkName: "admin-layouts-contracts-create" */ '@/views/Admin/Layouts/Contracts/ContractLayoutCreate.vue')
                        },
                    ]
                },

                // MANAGEMENT
                // DOCUMENTS
                {
                    path: 'admin-documents',
                    component: () => import(/* webpackChunkName: "admin-documents"*/ '@/views/Admin/Documents/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-general-documents-list',
                            component: () => import(/* webpackChunkName: "admin-general-documents-list" */ '@/views/Admin/Documents/GeneralDocuments.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // EMAILs
                {
                    path: 'admin-emails',
                    component: () => import(/* webpackChunkName: "admin-email"*/ '@/views/Admin/Emails/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-general-emails-list',
                            component: () => import(/* webpackChunkName: "admin-general-documents-list" */ '@/views/Admin/Emails/GeneralEmails.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // Process
                {
                    path: 'admin-process',
                    component: () => import(/* webpackChunkName: "admin-process"*/ '@/views/Admin/Process/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-general-process',
                            component: () => import(/* webpackChunkName: "admin-general-process" */ '@/views/Admin/Process/GeneralProcess.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // Visa
                {
                    path: 'admin-visa',
                    component: () => import(/* webpackChunkName: "admin-visa"*/ '@/views/Admin/Visa/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-visa',
                            component: () => import(/* webpackChunkName: "admin-visa" */ '@/views/Admin/Visa/VisaDocuments.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // Forms
                {
                    path: 'admin-forms',
                    component: () => import(/* webpackChunkName: "admin-forms"*/ '@/views/Admin/Forms/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-forms',
                            component: () => import(/* webpackChunkName: "admin-forms" */ '@/views/Admin/Forms/FormsList.vue')
                        },
                    ]
                },

                // HOT FIX
                // CONTRACTS
                {
                    path: 'admin-hotfix-contracts',
                    component: () => import(/* webpackChunkName: "admin-hotfix-contracts"*/ '@/views/Admin/HotFix/Contracts/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'admin-hotfix-contracts-pdfs',
                            component: () => import(/* webpackChunkName: "admin-hotfix-contracts-pdfs" */ '@/views/Admin/HotFix/Contracts/ContractPdfList.vue')
                        },
                        {
                            path: ':IdContract/update-html',            // register html
                            name: 'admin-hotfix-contracts-register-html',
                            component: () => import(/* webpackChunkName: "admin-hotfix-contracts-register-html" */ '@/views/Admin/HotFix/Contracts/ContractRegisterHtml.vue')
                        },
                    ]
                },
            ]
        },

        /**
         * EMPLOYEE
         */
        {
            path: '/employee',
            component: () => import(/* webpackChunkName: "Employee" */ '@/views/Employee'),
            beforeEnter: (to, from, next) => {
                if (Object.keys(store.state.user.attributes).length != 0) {
                    if (store.getters['user/hasRoleAndIsActive']('empleado')) next()
                    else {
                        if (store.getters['user/getActiveRoles'].length != 0) {
                            switch (store.getters['user/getActiveRoles'][0].value) {
                                case 'admin':
                                    next({ name: 'admin-dashboard' })
                                    break;
                                case 'empleado':
                                    next({ name: 'employee-dashboard' })
                                    break;
                                case 'participante':
                                    next({ name: 'participant-dashboard' })
                                    break;
                                case 'postulante':
                                    next({ name: 'postulant-dashboard' })
                                    break;
                                default:
                                    console.log('break page route')
                                    break;
                            }
                        } else next({ name: 'notActiveRoles-dashboard' })
                    }
                } else next({ name: 'login' })
            },
            children: [
                {
                    path: '',
                    component: () => import(/* webpackChunkName: "employee-dashboard" */ '@/views/Employee/Dashboard/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-dashboard',
                            component: () => import(/* webpackChunkName: "employee-dashboard-home" */ '@/views/Employee/Dashboard/Home.vue')
                        }
                    ]
                },

                // shedule
                {
                    path: 'employee-schedule',
                    component: () => import(/* webpackChunkName: "employee-schedule" */ '@/views/Employee/Schedule'),
                    children: [
                        {
                            path: '',
                            name: 'employee-schedule-dashboard',
                            component: () => import(/* webpackChunkName: "employee-schedule-dashboard" */ '@/views/Employee/Schedule/ScheduleDashboard.vue')
                        },
                        {
                            path: 'create',
                            component: () => import(/* webpackChunkName: "employee-schedule-create" */ '@/views/Employee/Schedule/ScheduleCreate'),
                            children: [
                                // SCHEDULE - SA
                                {
                                    path: '', // SA
                                    component: () => import(/* webpackChunkName: "employee-schedule-SA" */ '@/views/Employee/Schedule/ScheduleCreate/ScheduleSA'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-schedule-SA-interview',
                                            component: () => import(/* webpackChunkName: "employee-schedule-SA-interview" */ '@/views/Employee/Schedule/ScheduleCreate/ScheduleSA/CreateInterview.vue'),
                                        }
                                    ]
                                },

                                // SCHEDULE - CA
                                {
                                    path: 'CA',
                                    component: () => import(/* webpackChunkName: "employee-schedule-CA" */ '@/views/Employee/Schedule/ScheduleCreate/ScheduleCA'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-schedule-CA-interview',
                                            component: () => import(/* webpackChunkName: "employee-schedule-CA-interview" */ '@/views/Employee/Schedule/ScheduleCreate/ScheduleCA/CreateInterview.vue'),
                                        },
                                        {
                                            path: 'capacitations',
                                            name: 'employee-schedule-CA-capacitations',
                                            component: () => import(/* webpackChunkName: "employee-schedule-CA-capacitations" */ '@/views/Employee/Schedule/ScheduleCreate/ScheduleCA/CreateCapacitations.vue'),
                                        },
                                        {
                                            path: 'talks',
                                            name: 'employee-schedule-CA-talks',
                                            component: () => import(/* webpackChunkName: "employee-schedule-CA-talks" */ '@/views/Employee/Schedule/ScheduleCreate/ScheduleCA/CreateTalks.vue'),
                                        },
                                    ]
                                }
                            ]
                        },
                    ]
                },

                // export
                {
                    path: 'employee-export',
                    component: () => import(/* webpackChunkName: "employee-export" */ '@/views/Employee/Export/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-export',
                            component: () => import(/* webpackChunkName: "employee-export-home" */ '@/views/Employee/Export/Home.vue')
                        },
                        {
                            path: 'participant-information',
                            name: 'employee-export-participant-information',
                            component: () => import(/* webpackChunkName: "employee-export-participant-information" */ '@/views/Employee/Export/ParticipantInformation.vue')
                        },
                        {
                            path: 'payments-vouchers',
                            name: 'employee-export-payments-vouchers',
                            component: () => import(/* webpackChunkName: "employee-export-payments-vouchers" */ '@/views/Employee/Export/PaymentsVouchers.vue')
                        },
                        {
                            path: 'participant-documents',
                            name: 'employee-export-participant-documents',
                            component: () => import(/* webpackChunkName: "employee-export-participant-documents" */ '@/views/Employee/Export/ParticipantDocuments.vue')
                        },
                    ]
                },

                // postulants
                  {
                    path: 'employee-postulants',
                    component: () => import(/* webpackChunkName: "employee-postulants" */ '@/views/Employee/Postulants'),
                    children: [
                        {
                            path: '',
                            name: 'employee-postulants-dashboard',
                            component: () => import(/* webpackChunkName: "employee-postulants-dashboard" */ '@/views/Employee/Postulants/PostulantsDashboard.vue')
                        },
                        // Sales Advisor ~ SA
                        {
                            path: 'SA',
                            component: () => import(/* webpackChunkName: "employee-postulants-SA" */ '@/views/Employee/Postulants/SalesAdvisor'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-postulants-list-SA',
                                    component: () => import(/* webpackChunkName: "employee-postulants-list-SA" */ '@/views/Employee/Postulants/SalesAdvisor/PostulantsList.vue'),
                                },
                                {
                                    path: 'create',
                                    name: 'employee-postulants-create-SA',
                                    component: () => import(/* webpackChunkName: "employee-postulants-create-SA" */ '@/views/Employee/Postulants/SalesAdvisor/PostulantsCreate'),
                                },
                                {
                                    path: 'postulants/:IdProgramSeasonPostulant',
                                    component: () => import(/* webpackChunkName: "employee-postulants-postulant-SA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-postulants-details-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-details-SA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/PostulantDetails.vue'),
                                        },
                                        {
                                            path: 'payments-vouchers',
                                            name: 'employee-postulants-payments-vouchers-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-payments-vouchers-SA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/PostulantPaymentsVouchers.vue'),
                                        },
                                        {
                                            path: 'interview',
                                            name: 'employee-postulants-interviews-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-interviews-SA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/PostulantInterviewList.vue'),
                                        },
                                        {
                                            path: 'interview/:IdEnglishInterviewSchedule/details-SA',  // englishInterviewShedule_id
                                            name: 'employee-postulants-interview-details-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-interview-SA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/InterviewDetails.vue'),
                                        },
                                        // contracts-list
                                        // {
                                        //     path: 'contract',
                                        //     name: 'employee-postulants-contract-SA',
                                        //     component: () => import(/* webpackChunkName: "employee-postulants-contract-SA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/PostulantContract.vue'),
                                        // },

                                        {
                                            path: 'contracts',
                                            name: 'employee-postulants-contracts-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-contracts-CA" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/ContractsList.vue'),
                                        },
                                        {
                                            path: 'contracts/create',
                                            name: 'employee-postulants-contract-create-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-contract-create" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/ContractCreate.vue'),
                                        },
                                        {
                                            path: 'contracts/:IdContract/details',
                                            name: 'employee-postulants-contracts-details-SA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-contracts-details" */ '@/views/Employee/Postulants/SalesAdvisor/Postulant/ContractDetails.vue'),
                                        },
                                    ]

                                },
                            ]
                        },
                        // Cultural Advisor ~ CA
                        {
                            path: 'CA', 
                            component: () => import(/* webpackChunkName: "employee-postulants-CA" */ '@/views/Employee/Postulants/CulturalAdvisor'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-postulants-list-CA',
                                    component: () => import(/* webpackChunkName: "employee-postulants-list-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/PostulantsList.vue'),
                                },
                                {
                                    path: 'test',
                                    name: 'employee-postulants-list-test-CA',
                                    component: () => import(/* webpackChunkName: "employee-postulants-list-test-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/PostulantsListTest.vue'),
                                },
                                {
                                    path: 'postulants/:IdProgramSeasonPostulant',
                                    component: () => import(/* webpackChunkName: "employee-postulants-postulant-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-postulants-details-CA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-details-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/PostulantDetails.vue'),
                                        },
                                        {
                                            path: 'payments-vouchers',
                                            name: 'employee-postulants-payments-vouchers-CA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-payments-vouchers-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/PostulantPaymentsVouchers.vue'),
                                        },
                                        // interviews-list
                                        {
                                            path: 'interviews',
                                            name: 'employee-postulants-interviews-CA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-interviews-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/InterviewList.vue'),
                                        },
                                         // interviews-details
                                        {
                                            path: 'interviews/:IdEnglishInterviewSchedule/details-CA',  // englishInterviewShedule_id
                                            name: 'employee-postulants-interview-details-CA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-interview-details-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/InterviewDetails.vue'),
                                        },
                                        // contracts-list
                                        {
                                            path: 'contracts',
                                            name: 'employee-postulants-contracts-CA',
                                            component: () => import(/* webpackChunkName: "employee-postulants-contracts-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/ContractsList.vue'),
                                        },
                                        {
                                            path: 'contracts/create',
                                            name: 'employee-postulants-contract-create',
                                            component: () => import(/* webpackChunkName: "employee-postulants-contract-create" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/ContractCreate.vue'),
                                        },
                                        {
                                            path: 'contracts/:IdContract/details',
                                            name: 'employee-postulants-contracts-details',
                                            component: () => import(/* webpackChunkName: "employee-postulants-contracts-details" */ '@/views/Employee/Postulants/CulturalAdvisor/Postulant/ContractDetails.vue'),
                                        },
                                    ]
                                },
                                {
                                    path: 'create',
                                    name: 'employee-postulants-create-CA',
                                    component: () => import(/* webpackChunkName: "employee-postulants-create-CA" */ '@/views/Employee/Postulants/CulturalAdvisor/PostulantsCreate'),
                                },
                            ]
                        },
                    ]
                },

                //participants
                {
                    path: 'employee-participants',
                    component: () => import(/* webpackChunkName: "employee-participants" */ '@/views/Employee/Participants'),
                    children: [
                        {
                            path: '',
                            name: 'employee-participants-dashboard',
                            component: () => import(/* webpackChunkName: "employee-participants-dashboard" */ '@/views/Employee/Participants/ParticipantDashboard.vue')
                        },
                        {
                            path: 'employee-participants',
                            name: 'employee-participants-list',
                            component: () => import(/* webpackChunkName: "employee-participants-list" */ '@/views/Employee/Participants/ParticipantsList.vue')
                        },
                        {
                            path: 'employee-participants-filters',
                            component: () => import(/* webpackChunkName: "employee-participants-filters" */ '@/views/Employee/Participants/Filters/index.vue'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-participants-filter-documents',
                                    component: () => import(/* webpackChunkName: "employee-participants-filter-documents" */ '@/views/Employee/Participants/Filters/DocumentsFilter.vue'),
                                },
                                {
                                    path: 'filter-documents-last-uploads',
                                    name: 'employee-participants-filter-documents-last-uploads',
                                    component: () => import(/* webpackChunkName: "employee-participants-filter-documents-last-uploads" */ '@/views/Employee/Participants/Filters/LastDocuments.vue'),
                                },
                                {
                                    path: 'filter-all-participants',
                                    name: 'employee-participants-filter-all-participants',
                                    component: () => import(/* webpackChunkName: "employee-participants-filter-all-participants" */ '@/views/Employee/Participants/Filters/AllParticipantsFilter.vue'),
                                },
                                {
                                    path: 'filter-payments',
                                    name: 'employee-participants-filter-payments',
                                    component: () => import(/* webpackChunkName: "employee-participants-filter-payments" */ '@/views/Employee/Participants/Filters/PaymentsFilter.vue'),
                                },
                            ]
                        },
                        {
                            path: 'test',
                            name: 'employee-participants-list-test',
                            component: () => import(/* webpackChunkName: "employee-participants-list-test" */ '@/views/Employee/Participants/ParticipantsListTest.vue')
                        },
                        //
                        {
                            path: 'employee-participants/participant/:IdProgramSeasonParticipant/:IdProgramSeasonPostulant',
                            component: () => import(/* webpackChunkName: "employee-participants-participant" */ '@/views/Employee/Participants/Participant'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-participant-details',
                                    component: () => import(/* webpackChunkName: "employee-participant-details" */ '@/views/Employee/Participants/Participant/ParticipantDetails.vue'),
                                },
                                {
                                    path: 'ficha',
                                    name: 'employee-participant-ficha',
                                    component: () => import(/* webpackChunkName: "employee-participant-ficha" */ '@/views/Employee/Participants/Participant/ParticipantFicha.vue'),
                                },
                                {
                                    path: 'payments-vouchers',
                                    name: 'employee-participant-payments-vouchers',
                                    component: () => import(/* webpackChunkName: "employee-participant-payments" */ '@/views/Employee/Participants/Participant/ParticipantPaymentsVouchers.vue'),
                                },
                                // interviews-list
                                {
                                    path: 'interviews',
                                    name: 'employee-participant-interviews',
                                    component: () => import(/* webpackChunkName: "employee-participant-interviews" */ '@/views/Employee/Participants/Participant/InterviewList.vue'),
                                },
                                 // interviews-details
                                {
                                    path: 'interviews/:IdEnglishInterviewSchedule/details',  // englishInterviewShedule_id
                                    name: 'employee-participant-interview-details',
                                    component: () => import(/* webpackChunkName: "employee-participant-interview-details" */ '@/views/Employee/Participants/Participant/InterviewDetails.vue'),
                                },
                                {
                                    path: 'contracts',
                                    name: 'employee-participant-contracts',
                                    component: () => import(/* webpackChunkName: "employee-participant-contracts" */ '@/views/Employee/Participants/Participant/ContractsList.vue'),
                                },
                                {
                                    path: 'contracts/:IdContract/details',
                                    name: 'employee-participant-contract-details',
                                    component: () => import(/* webpackChunkName: "employee-participant-contract-details" */ '@/views/Employee/Participants/Participant/ContractDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'employee-participant-documents',
                                    component: () => import(/* webpackChunkName: "employee-participant-documents" */ '@/views/Employee/Participants/Participant/ParticipantDocuments.vue'),
                                },
                                //
                                {
                                    path: 'capacitations',
                                    name: 'employee-participant-capacitations',
                                    component: () => import(/* webpackChunkName: "employee-participant-capacitations" */ '@/views/Employee/Participants/Participant/Capacitations'),
                                },
                                {
                                    path: 'talks',
                                    name: 'employee-participant-talks',
                                    component: () => import(/* webpackChunkName: "employee-participant-talks" */ '@/views/Employee/Participants/Participant/Talks'),
                                },
                                {
                                    path: 'visa',
                                    name: 'employee-participant-visa',
                                    component: () => import(/* webpackChunkName: "employee-participant-visa" */ '@/views/Employee/Participants/Participant/Visa'),
                                },
                                {
                                    path: 'attempts',
                                    component: () => import(/* webpackChunkName: "employee-participants-attempts" */ '@/views/Employee/Participants/Participant/Attempts/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-participants-attempts-list',   //postulaciones
                                            component: () => import(/* webpackChunkName: "employee-participants-attempts-list" */ '@/views/Employee/Participants/Participant/Attempts/AttemptsList.vue')
                                        },
                                        {
                                            path: 'attempt/:IdPostulation',
                                            component: () => import(/* webpackChunkName: "employee-participants-attempt" */ '@/views/Employee/Participants/Participant/Attempts/Attempt/index.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'employee-participants-attempt-details',
                                                    component: () => import(/* webpackChunkName: "employee-participants-attempt-details" */ '@/views/Employee/Participants/Participant/Attempts/Attempt/AttemptDetails.vue')
                                                },
                                            ]
                                        },
                                    ]

                                },
                            ]
                        },
                    ]
                },

                // employee-postponements
                {
                    path: 'employee-postponements',
                    component: () => import(/* webpackChunkName: "employee-postponements"*/ '@/views/Employee/Postponements/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-postponements-list',
                            component: () => import(/* webpackChunkName: "employee-postponements-list" */ '@/views/Employee/Postponements/PostponementsList.vue')
                        },
                    ]
                },

                // employee-abandonment
                {
                    path: 'employee-abandonments',
                    component: () => import(/* webpackChunkName: "employee-abandonments"*/ '@/views/Employee/Abandonments/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-abandonments-list',
                            component: () => import(/* webpackChunkName: "employee-abandonments-list" */ '@/views/Employee/Abandonments/AbandonmentsList.vue')
                        },
                    ]
                },

                /**
                 * Programs
                 */
                // WAT
                {
                    path: 'employee-wat',
                    component: () => import(/* webpackChunkName: "employee-wat" */ '@/views/Employee/Wat'),
                    children: [
                        {
                            path: '',
                            name: 'employee-wat-list',
                            component: () => import(/* webpackChunkName: "employee-wat-list" */ '@/views/Employee/Wat/WatList.vue'),
                        },
                        {
                            path: 'wat/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "employee-wats" */ '@/views/Employee/Wat/WatDetails'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-wat-details',
                                    component: () => import(/* webpackChunkName: "employee-wat-details" */ '@/views/Employee/Wat/WatDetails/WatDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'employee-wat-documents',
                                    component: () => import(/* webpackChunkName: "employee-wat-documents" */ '@/views/Employee/Wat/WatDetails/WatDocuments.vue'),
                                },
                                {
                                    path: 'postulants',
                                    name: 'employee-wat-postulants',
                                    component: () => import(/* webpackChunkName: "employee-wat-postulants" */ '@/views/Employee/Wat/WatDetails/WatPostulants.vue'),
                                },
                                {
                                    path: 'participants',
                                    name: 'employee-wat-participants',
                                    component: () => import(/* webpackChunkName: "employee-wat-participants" */ '@/views/Employee/Wat/WatDetails/WatParticipants.vue'),
                                },
                                {
                                    path: 'resources',
                                    name: 'employee-wat-resources',
                                    component: () => import(/* webpackChunkName: "employee-wat-resources" */ '@/views/Employee/Wat/WatDetails/WatResources.vue'),
                                },
                                {
                                    path: 'capacitations',
                                    component: () => import(/* webpackChunkName: "employee-wat-capacitations" */ '@/views/Employee/Wat/WatDetails/WatCapacitations/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-wat-capacitations-topics',
                                            component: () => import(/* webpackChunkName: "employee-wat-capacitations-topics" */ '@/views/Employee/Wat/WatDetails/WatCapacitations/WatCapacitationsTopics.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'talks',
                                    component: () => import(/* webpackChunkName: "employee-wat-talks" */ '@/views/Employee/Wat/WatDetails/WatTalks/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-wat-talks-topics',
                                            component: () => import(/* webpackChunkName: "employee-wat-talks-topics" */ '@/views/Employee/Wat/WatDetails/WatTalks/WatTalksTopics.vue'),
                                        }
                                    ]
                                },
                                // sponsor
                                {
                                    path: 'sponsors',
                                    component: () => import(/* webpackChunkName: "employee-wat-sponsors" */ '@/views/Employee/Wat/WatDetails/WatSponsors'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-wat-sponsors',
                                            component: () => import(/* webpackChunkName: "employee-wat-sponsors-list" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorsList.vue'),
                                        },
                                        {
                                            path: 'sponsor/:IdProgramSeasonSponsor',
                                            component: () => import(/* webpackChunkName: "employee-wat-sponsors-details" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorDetails'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'employee-wat-sponsor-programation',
                                                    component: () => import(/* webpackChunkName: "employee-wat-sponsors-programation" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorDetails/WatSponsorProgramation.vue'),
                                                },
                                                {
                                                    path: 'details',
                                                    name: 'employee-wat-sponsor-details',
                                                    component: () => import(/* webpackChunkName: "employee-wat-sponsors-detail" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorDetails/WatSponsorDetails.vue'),
                                                },
                                                {
                                                    path: 'joboffers',
                                                    name: 'employee-wat-sponsor-joboffers',
                                                    component: () => import(/* webpackChunkName: "employee-wat-sponsors-joboffers" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorDetails/WatSponsorJoboffers.vue'),
                                                },
                                                {
                                                    path: 'postulations',
                                                    name: 'employee-wat-sponsor-postulations',
                                                    component: () => import(/* webpackChunkName: "employee-wat-sponsors-postulations" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorDetails/WatSponsorPostulations.vue'),
                                                },
                                                // {
                                                //     path: 'interviews',
                                                //     name: 'employee-wat-sponsor-interviews',
                                                //     component: () => import(/* webpackChunkName: "employee-wat-sponsors-interviews" */ '@/views/Employee/Wat/WatDetails/WatSponsors/WatSponsorDetails/WatSponsorInterviews.vue'),
                                                // },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },

                // INTERNSHIP
                {
                    path: 'employee-internship',
                    component: () => import(/* webpackChunkName: "employee-internship" */ '@/views/Employee/Internship'),
                    children: [
                        {
                            path: '',
                            name: 'employee-internship-list',
                            component: () => import(/* webpackChunkName: "employee-internship-list" */ '@/views/Employee/Internship/InternshipList.vue'),
                            
                        },
                        {
                            path: 'internship/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "employee-internships" */ '@/views/Employee/Internship/InternshipDetails'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-internship-details',
                                    component: () => import(/* webpackChunkName: "employee-internship-details" */ '@/views/Employee/Internship/InternshipDetails/InternshipDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'employee-internship-documents',
                                    component: () => import(/* webpackChunkName: "employee-internship-documents" */ '@/views/Employee/Internship/InternshipDetails/InternshipDocuments.vue'),
                                },
                                {
                                    path: 'postulants',
                                    name: 'employee-internship-postulants',
                                    component: () => import(/* webpackChunkName: "employee-internship-postulants" */ '@/views/Employee/Internship/InternshipDetails/InternshipPostulants.vue'),
                                },
                                {
                                    path: 'participants',
                                    name: 'employee-internship-participants',
                                    component: () => import(/* webpackChunkName: "employee-internship-participants" */ '@/views/Employee/Internship/InternshipDetails/InternshipParticipants.vue'),
                                },
                                {
                                    path: 'resources',
                                    name: 'employee-internship-resources',
                                    component: () => import(/* webpackChunkName: "employee-internship-resources" */ '@/views/Employee/Internship/InternshipDetails/InternshipResources.vue'),
                                },
                                {
                                    path: 'capacitations',
                                    component: () => import(/* webpackChunkName: "employee-internship-capacitations" */ '@/views/Employee/Internship/InternshipDetails/InternshipCapacitations/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-internship-capacitations-topics',
                                            component: () => import(/* webpackChunkName: "employee-internship-capacitations" */ '@/views/Employee/Internship/InternshipDetails/InternshipCapacitations/InternshipCapacitationsTopics.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'talks',
                                    component: () => import(/* webpackChunkName: "employee-internship-talks" */ '@/views/Employee/Internship/InternshipDetails/InternshipTalks/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-internship-talks-topics',
                                            component: () => import(/* webpackChunkName: "employee-internship-talks-topics" */ '@/views/Employee/Internship/InternshipDetails/InternshipTalks/InternshipTalksTopics.vue'),
                                        }
                                    ]
                                },
                                //sponsors
                                {
                                    path: 'sponsors',
                                    component: () => import(/* webpackChunkName: "employee-internship-sponsors" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-internship-sponsors',
                                            component: () => import(/* webpackChunkName: "employee-internship-sponsors-list" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors/InternshipSponsorsList.vue'),
                                        },
                                        {
                                            path: 'details/:IdProgramSeasonSponsor',
                                            component: () => import(/* webpackChunkName: "employee-internship-sponsors-details" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors/InternshipSponsorDetails'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'employee-internship-sponsor-details',
                                                    component: () => import(/* webpackChunkName: "employee-internship-sponsors-detail" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors/InternshipSponsorDetails/InternshipSponsorDetails.vue'),
                                                },
                                                {
                                                    path: 'joboffers',
                                                    name: 'employee-internship-sponsor-joboffers',
                                                    component: () => import(/* webpackChunkName: "employee-internship-sponsors-joboffers" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors/InternshipSponsorDetails/InternshipSponsorJoboffers.vue'),
                                                },
                                                {
                                                    path: 'postulations',
                                                    name: 'employee-internship-sponsor-postulations',
                                                    component: () => import(/* webpackChunkName: "employee-internship-sponsors-postulations" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors/InternshipSponsorDetails/InternshipSponsorPostulations.vue'),
                                                },
                                                // {
                                                //     path: 'interviews',
                                                //     name: 'employee-internship-sponsor-interviews',
                                                //     component: () => import(/* webpackChunkName: "employee-internship-sponsors-interviews" */ '@/views/Employee/Internship/InternshipDetails/InternshipSponsors/InternshipSponsorDetails/InternshipSponsorInterviews.vue'),
                                                // },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },

                // TRAINEE
                {
                    path: 'employee-trainee',
                    component: () => import(/* webpackChunkName: "employee-trainee" */ '@/views/Employee/Trainee'),
                    children: [
                        {
                            path: '',
                            name: 'employee-trainee-list',
                            component: () => import(/* webpackChunkName: "employee-trainee-list" */ '@/views/Employee/Trainee/TraineeList.vue'),
                            
                        },
                        {
                            path: 'trainee/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "employee-trainees" */ '@/views/Employee/Trainee/TraineeDetails'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-trainee-details',
                                    component: () => import(/* webpackChunkName: "employee-trainee-details" */ '@/views/Employee/Trainee/TraineeDetails/TraineeDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'employee-trainee-documents',
                                    component: () => import(/* webpackChunkName: "employee-trainee-documents" */ '@/views/Employee/Trainee/TraineeDetails/TraineeDocuments.vue'),
                                },
                                {
                                    path: 'postulants',
                                    name: 'employee-trainee-postulants',
                                    component: () => import(/* webpackChunkName: "employee-trainee-postulants" */ '@/views/Employee/Trainee/TraineeDetails/TraineePostulants.vue'),
                                },
                                {
                                    path: 'participants',
                                    name: 'employee-trainee-participants',
                                    component: () => import(/* webpackChunkName: "employee-trainee-participants" */ '@/views/Employee/Trainee/TraineeDetails/TraineeParticipants.vue'),
                                },
                                {
                                    path: 'resources',
                                    name: 'employee-trainee-resources',
                                    component: () => import(/* webpackChunkName: "employee-trainee-resources" */ '@/views/Employee/Trainee/TraineeDetails/TraineeResources.vue'),
                                },
                                {
                                    path: 'capacitations',
                                    component: () => import(/* webpackChunkName: "employee-trainee-capacitations" */ '@/views/Employee/Trainee/TraineeDetails/TraineeCapacitations/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-trainee-capacitations-topics',
                                            component: () => import(/* webpackChunkName: "employee-trainee-capacitations-topics" */ '@/views/Employee/Trainee/TraineeDetails/TraineeCapacitations/TraineeCapacitationsTopics.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'talks',
                                    component: () => import(/* webpackChunkName: "employee-trainee-talks" */ '@/views/Employee/Trainee/TraineeDetails/TraineeTalks/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-trainee-talks-topics',
                                            component: () => import(/* webpackChunkName: "employee-trainee-talks-topics" */ '@/views/Employee/Trainee/TraineeDetails/TraineeTalks/TraineeTalksTopics.vue'),
                                        }
                                    ]
                                },
                                //sponsor
                                {
                                    path: 'sponsors',
                                    component: () => import(/* webpackChunkName: "employee-trainee-sponsors" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-trainee-sponsors',
                                            component: () => import(/* webpackChunkName: "employee-trainee-sponsors-list" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors/TraineeSponsorsList.vue'),
                                        },
                                        {
                                            path: 'details/:IdProgramSeasonSponsor',
                                            component: () => import(/* webpackChunkName: "employee-trainee-sponsors-detail" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors/TraineeSponsorDetails'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'employee-trainee-sponsor-details',
                                                    component: () => import(/* webpackChunkName: "employee-trainee-sponsors-details" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors/TraineeSponsorDetails/TraineeSponsorDetails.vue'),
                                                },
                                                {
                                                    path: 'joboffers',
                                                    name: 'employee-trainee-sponsor-joboffers',
                                                    component: () => import(/* webpackChunkName: "employee-trainee-sponsors-joboffers" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors/TraineeSponsorDetails/TraineeSponsorJoboffers.vue'),
                                                },
                                                {
                                                    path: 'postulations',
                                                    name: 'employee-trainee-sponsor-postulations',
                                                    component: () => import(/* webpackChunkName: "employee-trainee-sponsors-postulations" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors/TraineeSponsorDetails/TraineeSponsorPostulations.vue'),
                                                },
                                                // {
                                                //     path: 'interviews',
                                                //     name: 'employee-trainee-sponsor-interviews',
                                                //     component: () => import(/* webpackChunkName: "employee-trainee-sponsors-interviews" */ '@/views/Employee/Trainee/TraineeDetails/TraineeSponsors/TraineeSponsorDetails/TraineeSponsorInterviews.vue'),
                                                // },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },

                // AUPAIR
                {
                    path: 'employee-aupair',
                    component: () => import(/* webpackChunkName: "employee-aupair" */ '@/views/Employee/Aupair'),
                    children: [
                        {
                            path: '',
                            name: 'employee-aupair-list',
                            component: () => import(/* webpackChunkName: "employee-aupair-list" */ '@/views/Employee/Aupair/AupairList.vue'),
                            
                        },
                        {
                            path: 'aupair/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "employee-aupairs" */ '@/views/Employee/Aupair/AupairDetails'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-aupair-details',
                                    component: () => import(/* webpackChunkName: "employee-aupair-details" */ '@/views/Employee/Aupair/AupairDetails/AupairDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'employee-aupair-documents',
                                    component: () => import(/* webpackChunkName: "employee-aupair-documents" */ '@/views/Employee/Aupair/AupairDetails/AupairDocuments.vue'),
                                },
                                {
                                    path: 'postulants',
                                    name: 'employee-aupair-postulants',
                                    component: () => import(/* webpackChunkName: "employee-aupair-postulants" */ '@/views/Employee/Aupair/AupairDetails/AupairPostulants.vue'),
                                },
                                {
                                    path: 'participants',
                                    name: 'employee-aupair-participants',
                                    component: () => import(/* webpackChunkName: "employee-aupair-participants" */ '@/views/Employee/Aupair/AupairDetails/AupairParticipants.vue'),
                                },
                                {
                                    path: 'resources',
                                    name: 'employee-aupair-resources',
                                    component: () => import(/* webpackChunkName: "employee-aupair-resources" */ '@/views/Employee/Aupair/AupairDetails/AupairResources.vue'),
                                },
                                {
                                    path: 'capacitations',
                                    component: () => import(/* webpackChunkName: "employee-aupair-capacitations" */ '@/views/Employee/Aupair/AupairDetails/AupairCapacitations/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-aupair-capacitations-topics',
                                            component: () => import(/* webpackChunkName: "employee-aupair-capacitations-topics" */ '@/views/Employee/Aupair/AupairDetails/AupairCapacitations/AupairCapacitationsTopics.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'talks',
                                    component: () => import(/* webpackChunkName: "employee-aupair-talks" */ '@/views/Employee/Aupair/AupairDetails/AupairTalks/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-aupair-talks-topics',
                                            component: () => import(/* webpackChunkName: "employee-aupair-talks-topics" */ '@/views/Employee/Aupair/AupairDetails/AupairTalks/AupairTalksTopics.vue'),
                                        }
                                    ]
                                },
                                // sponsor
                                {
                                    path: 'sponsors',
                                    component: () => import(/* webpackChunkName: "employee-aupair-sponsors" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-aupair-sponsors',
                                            component: () => import(/* webpackChunkName: "employee-aupair-sponsors-list" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors/AupairSponsorsList.vue'),
                                        },
                                        {
                                            path: 'details/:IdProgramSeasonSponsor',
                                            component: () => import(/* webpackChunkName: "employee-aupair-sponsors-details" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors/AupairSponsorsDetails'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'employee-aupair-sponsor-details',
                                                    component: () => import(/* webpackChunkName: "employee-aupair-sponsors-detail" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors/AupairSponsorsDetails/AupairSponsorDetails.vue'),
                                                },
                                                {
                                                    path: 'joboffers',
                                                    name: 'employee-aupair-sponsor-joboffers',
                                                    component: () => import(/* webpackChunkName: "employee-aupair-sponsors-joboffers" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors/AupairSponsorsDetails/AupairSponsorJoboffers.vue'),
                                                },
                                                {
                                                    path: 'postulations',
                                                    name: 'employee-aupair-sponsor-postulations',
                                                    component: () => import(/* webpackChunkName: "employee-aupair-sponsors-postulations" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors/AupairSponsorsDetails/AupairSponsorPostulations.vue'),
                                                },
                                                // {
                                                //     path: 'interviews',
                                                //     name: 'employee-aupair-sponsor-interviews',
                                                //     component: () => import(/* webpackChunkName: "employee-aupair-sponsors-interviews" */ '@/views/Employee/Aupair/AupairDetails/AupairSponsors/AupairSponsorsDetails/AupairSponsorInterviews.vue'),
                                                // },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },

                // TEACH
                {
                    path: 'employee-teach',
                    component: () => import(/* webpackChunkName: "employee-teach" */ '@/views/Employee/Teach'),
                    children: [
                        {
                            path: '',
                            name: 'employee-teach-list',
                            component: () => import(/* webpackChunkName: "employee-teach-list" */ '@/views/Employee/Teach/TeachList.vue'),
                            
                        },
                        {
                            path: 'teach/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "employee-teachs" */ '@/views/Employee/Teach/TeachDetails'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-teach-details',
                                    component: () => import(/* webpackChunkName: "employee-teach-details" */ '@/views/Employee/Teach/TeachDetails/TeachDetails.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'employee-teach-documents',
                                    component: () => import(/* webpackChunkName: "employee-teach-documents" */ '@/views/Employee/Teach/TeachDetails/TeachDocuments.vue'),
                                },
                                {
                                    path: 'postulants',
                                    name: 'employee-teach-postulants',
                                    component: () => import(/* webpackChunkName: "employee-teach-postulants" */ '@/views/Employee/Teach/TeachDetails/TeachPostulants.vue'),
                                },
                                {
                                    path: 'participants',
                                    name: 'employee-teach-participants',
                                    component: () => import(/* webpackChunkName: "employee-teach-participants" */ '@/views/Employee/Teach/TeachDetails/TeachParticipants.vue'),
                                },
                                {
                                    path: 'resources',
                                    name: 'employee-teach-resources',
                                    component: () => import(/* webpackChunkName: "employee-teach-resources" */ '@/views/Employee/Teach/TeachDetails/TeachResources.vue'),
                                },
                                {
                                    path: 'capacitations',
                                    component: () => import(/* webpackChunkName: "employee-teach-capacitations" */ '@/views/Employee/Teach/TeachDetails/TeachCapacitations/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-teach-capacitations-topics',
                                            component: () => import(/* webpackChunkName: "employee-teach-capacitations" */ '@/views/Employee/Teach/TeachDetails/TeachCapacitations/TeachCapacitationsTopics.vue'),
                                        }
                                    ]
                                },
                                
                                {
                                    path: 'talks',
                                    component: () => import(/* webpackChunkName: "employee-teach-talks" */ '@/views/Employee/Teach/TeachDetails/TeachTalks/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-teach-talks-topics',
                                            component: () => import(/* webpackChunkName: "employee-teach-talks-topics" */ '@/views/Employee/Teach/TeachDetails/TeachTalks/TeachTalksTopics.vue'),
                                        }
                                    ]
                                },
                                // sponosr
                                {
                                    path: 'sponsors',
                                    component: () => import(/* webpackChunkName: "employee-teach-sponsors" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'employee-teach-sponsors',
                                            component: () => import(/* webpackChunkName: "employee-teach-sponsors-list" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors/TeachSponsorsList.vue'),
                                        },
                                        {
                                            path: 'details/:IdProgramSeasonSponsor',
                                            component: () => import(/* webpackChunkName: "employee-teach-sponsors-details" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors/TeachSponsorsDetails'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'employee-teach-sponsor-details',
                                                    component: () => import(/* webpackChunkName: "employee-teach-sponsors-detail" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors/TeachSponsorsDetails/TeachSponsorDetails.vue'),
                                                },
                                                {
                                                    path: 'joboffers',
                                                    name: 'employee-teach-sponsor-joboffers',
                                                    component: () => import(/* webpackChunkName: "employee-teach-sponsors-joboffers" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors/TeachSponsorsDetails/TeachSponsorJoboffers.vue'),
                                                },
                                                {
                                                    path: 'postulations',
                                                    name: 'employee-teach-sponsor-postulations',
                                                    component: () => import(/* webpackChunkName: "employee-teach-sponsors-postulations" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors/TeachSponsorsDetails/TeachSponsorPostulations.vue'),
                                                },
                                                // {
                                                //     path: 'interviews',
                                                //     name: 'employee-teach-sponsor-interviews',
                                                //     component: () => import(/* webpackChunkName: "employee-teach-sponsors-interviews" */ '@/views/Employee/Teach/TeachDetails/TeachSponsors/TeachSponsorsDetails/TeachSponsorInterviews.vue'),
                                                // },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },

                // MANAGEMENT
                // SPONSOR
                {
                    path: 'employee-sponsor',
                    component: () => import(/* webpackChunkName: "employee-sponsor"*/ '@/views/Employee/Sponsor'),
                    children: [
                        {
                            path: '',
                            name: 'employee-sponsor-list',
                            component: () => import(/* webpackChunkName: "employee-sponsor-list" */ '@/views/Employee/Sponsor/SponsorList.vue')
                        },
                        {
                            path: 'sponsor/:IdSponsor',
                            component: () => import(/* webpackChunkName: "employee-sponsor-jobs-offer" */ '@/views/Employee/Sponsor/SponsorJobOffers/index.vue'),
                            children: [
                                {
                                    path: '',
                                    name: 'employee-sponsor-jobs-offer-list',
                                    component: () => import(/* webpackChunkName: "employee-sponsor-jobs-offer-list" */ '@/views/Employee/Sponsor/SponsorJobOffers/SponsorJobOffersList.vue'),
                                },
                                {
                                    path: 'sponsor-job-offer/:IdSponsorJobOffer/inputs',
                                    name: 'employee-sponsor-jobs-offer-inputs',
                                    component: () => import(/* webpackChunkName: "employee-sponsor-jobs-offer-inputs" */ '@/views/Employee/Sponsor/SponsorJobOffers/JobOffersInputs.vue'),
                                }
                            ]
                        },
                        {
                            path: 'employee-sponsor-general-job-inputs',
                            name: 'employee-sponsor-general-job-inputs',
                            component: () => import(/* webpackChunkName: "employee-sponsor-general-job-inputs" */ '@/views/Employee/Sponsor/GeneralJobInputs.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // DOCUMENTS
                {
                    path: 'employee-documents',
                    component: () => import(/* webpackChunkName: "employee-documents"*/ '@/views/Employee/Documents/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-general-documents-list',
                            component: () => import(/* webpackChunkName: "employee-general-documents-list" */ '@/views/Employee/Documents/GeneralDocuments.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // EMAILs
                {
                    path: 'employee-emails',
                    component: () => import(/* webpackChunkName: "employee-email"*/ '@/views/Employee/Emails/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-general-emails-list',
                            component: () => import(/* webpackChunkName: "employee-general-emails-list" */ '@/views/Employee/Emails/GeneralEmails.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // Process
                {
                    path: 'employee-process',
                    component: () => import(/* webpackChunkName: "employee-process"*/ '@/views/Employee/Process/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-general-process',
                            component: () => import(/* webpackChunkName: "employee-general-process" */ '@/views/Employee/Process/GeneralProcess.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // Visa
                {
                    path: 'employee-visa',
                    component: () => import(/* webpackChunkName: "employee-visa"*/ '@/views/Employee/Visa/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-visa',
                            component: () => import(/* webpackChunkName: "employee-visa" */ '@/views/Employee/Visa/VisaDocuments.vue')
                        },
                    ]
                },
                // MANAGEMENT
                // Forms
                {
                    path: 'employee-forms',
                    component: () => import(/* webpackChunkName: "employee-forms"*/ '@/views/Employee/Forms/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-forms',
                            component: () => import(/* webpackChunkName: "employee-forms" */ '@/views/Employee/Forms/FormsList.vue')
                        },
                    ]
                },

                // LAYOUTS
                // CONTRACTS
                {
                    path: 'employee-layouts-contracts',
                    component: () => import(/* webpackChunkName: "employee-layouts-contracts"*/ '@/views/Employee/Layouts/Contracts/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-layouts-contracts-list',
                            component: () => import(/* webpackChunkName: "employee-layouts-contracts-list" */ '@/views/Employee/Layouts/Contracts/ContractLayoutList.vue')
                        },
                        {
                            path: 'contract-layouts/:IdContractLayout', //~ IdHtmlLayout
                            name: 'employee-layouts-contracts-contractLayout',
                            component: () => import(/* webpackChunkName: "employee-layouts-contracts-contractLayout" */ '@/views/Employee/Layouts/Contracts/ContractLayout.vue'),
                        },
                        {
                            path: 'create',
                            name: 'employee-layouts-contracts-create',
                            component: () => import(/* webpackChunkName: "employee-layouts-contracts-create" */ '@/views/Employee/Layouts/Contracts/ContractLayoutCreate.vue')
                        },
                    ]
                },

                // HOT FIX
                // CONTRACTS
                {
                    path: 'employee-hotfix-contracts',
                    component: () => import(/* webpackChunkName: "employee-hotfix-contracts"*/ '@/views/Employee/HotFix/Contracts/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'employee-hotfix-contracts-pdfs',
                            component: () => import(/* webpackChunkName: "employee-hotfix-contracts-pdfs" */ '@/views/Employee/HotFix/Contracts/ContractPdfList.vue')
                        },
                        {
                            path: ':IdContract/update-html',            // register html
                            name: 'employee-hotfix-contracts-register-html',
                            component: () => import(/* webpackChunkName: "employee-hotfix-contracts-register-html" */ '@/views/Employee/HotFix/Contracts/ContractRegisterHtml.vue')
                        },
                    ]
                },
            ],
        },

        /**
         * PARTICIPANT
         */
        {
            path: '/participant',
            component: () => import(/* webpackChunkName: "Participant" */ '@/views/Participant'),
            beforeEnter: (to, from, next) => {
                if (Object.keys(store.state.user.attributes).length != 0) {
                    if (store.getters['user/hasRoleAndIsActive']('participante')) next()
                    else {
                        if (store.getters['user/getActiveRoles'].length != 0) {
                            switch (store.getters['user/getActiveRoles'][0].value) {
                                case 'admin':
                                    next({ name: 'admin-dashboard' })
                                    break;
                                case 'empleado':
                                    next({ name: 'employee-dashboard' })
                                    break;
                                case 'participante':
                                    next({ name: 'participant-dashboard' })
                                    break;
                                case 'postulante':
                                    next({ name: 'postulant-dashboard' })
                                    break;
                                default:
                                    console.log('break page route')
                                    break;
                            }
                        } else next({ name: 'notActiveRoles-dashboard' })
                    }
                } else next({ name: 'login' })
            },
            children: [
                {
                    path: '',
                    name: 'participant-dashboard',
                    component: () => import(/* webpackChunkName: "participant-dashboard" */ '@/views/Participant/Dashboard')
                },
                {
                    path: 'programs', // programSeasonsPostulants as programs
                    component: () => import(/* webpackChunkName: "participant-programs" */ '@/views/Participant/Programs'),
                    children: [
                        {
                            path: '',
                            name: 'participant-programs-list', //same
                            component: () => import(/* webpackChunkName: "participant-program-list" */ '@/views/Participant/Programs/ProgramsList.vue'),
                        },
                        {
                            path: 'program/:IdProgramSeasonParticipant/:IdProgramSeasonPostulant/programSeason/:IdProgramSeason',
                            component: () => import(/* webpackChunkName: "participant-" */ '@/views/Participant/Programs/Program'),
                            children: [
                                {
                                    path: '',
                                    name: 'participant-program-details', //same
                                    component: () => import(/* webpackChunkName: "participant-program-details" */ '@/views/Participant/Programs/Program/ProgramDetails.vue')
                                },
                                //
                                {
                                    path: 'payments-ficha',
                                    name: 'participant-program-ficha',
                                    component: () => import(/* webpackChunkName: "participant-program-ficha" */ '@/views/Participant/Programs/Program/ParticipantFicha.vue'),
                                },
                                {
                                    path: 'payments-vouchers',
                                    name: 'participant-program-payments-vouchers', //same
                                    component: () => import(/* webpackChunkName: "participant-program-payments-vouchers" */ '@/views/Participant/Programs/Program/ParticipantPaymentsVouchers.vue'),
                                },
                                {
                                    path: 'contracts', //same
                                    name: 'participant-program-contracts',
                                    component: () => import(/* webpackChunkName: "participant-program-contracts" */ '@/views/Participant/Programs/Program/ContractsList.vue'),
                                },
                                {
                                    path: 'documents',
                                    name: 'participant-program-documents',
                                    component: () => import(/* webpackChunkName: "participant-program-documents" */ '@/views/Participant/Programs/Program/ParticipantDocuments.vue'),
                                },
                                {
                                    path: 'program-resources',
                                    name: 'participant-program-resources',
                                    component: () => import(/* webpackChunkName: "participant-program-resources" */ '@/views/Participant/Programs/Program/Resources')
                                },
                                {
                                    path: 'program-capacitations',
                                    component: () => import(/* webpackChunkName: "participant-program-capacitations" */ '@/views/Participant/Programs/Program/Capacitations'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'participant-program-capacitations-topics',
                                            component: () => import(/* webpackChunkName: "participant-program-capacitations-topics" */ '@/views/Participant/Programs/Program/Capacitations/CapacitationsTopics.vue'),
                                        },
                                        {
                                            path: 'capacitations-schedules',
                                            name: 'participant-program-capacitations-schedules',
                                            component: () => import(/* webpackChunkName: "participant-program-capacitations-schedules" */ '@/views/Participant/Programs/Program/Capacitations/CapacitationsSchedules.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'program-talks',
                                    component: () => import(/* webpackChunkName: "participant-program-talks" */ '@/views/Participant/Programs/Program/Talks'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'participant-program-talks-topics',
                                            component: () => import(/* webpackChunkName: "participant-program-talks-topics" */ '@/views/Participant/Programs/Program/Talks/TalksTopics.vue'),
                                        },
                                        {
                                            path: 'talks-schedules',
                                            name: 'participant-program-talks-schedules',
                                            component: () => import(/* webpackChunkName: "participant-program-talks-schedules" */ '@/views/Participant/Programs/Program/Talks/TalksSchedules.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'visa',
                                    name: 'participant-program-visa',
                                    component: () => import(/* webpackChunkName: "participant-program-visa" */ '@/views/Participant/Programs/Program/Visa'),
                                },
                                {
                                    path: 'forms',
                                    name: 'participant-forms',
                                    component: () => import(/* webpackChunkName: "forms"*/ '@/views/Participant/Programs/Program/Forms')
                                },
                                //
                                {
                                    path: 'program-sponsors',
                                    component: () => import(/* webpackChunkName: "participant-program-sponsors" */ '@/views/Participant/Programs/Program/Sponsors/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'participant-program-sponsors-list',
                                            component: () => import(/* webpackChunkName: "participant-program-sponsors-list" */ '@/views/Participant/Programs/Program/Sponsors/SponsorsList.vue')
                                        },
                                        {
                                            path: ':IdProgramSeasonSponsor/joboffers',
                                            name: 'participant-program-sponsor-joboffers',
                                            component: () => import(/* webpackChunkName: "participant-program-sponsors-joboffers" */ '@/views/Participant/Programs/Program/Sponsors/SponsorJoboffers.vue')
                                        },
                                    ]
                                },
                                {
                                    path: 'program-attempts',
                                    component: () => import(/* webpackChunkName: "participant-program-attempts" */ '@/views/Participant/Programs/Program/Attempts/index.vue'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'participant-program-attempts-list',   //postulaciones
                                            component: () => import(/* webpackChunkName: "participant-program-attempts-list" */ '@/views/Participant/Programs/Program/Attempts/AttemptsList')
                                        },
                                        {
                                            path: 'attempt/:IdPostulation',
                                            component: () => import(/* webpackChunkName: "participant-program-attempts-list" */ '@/views/Participant/Programs/Program/Attempts/Attempt/index.vue'),
                                            children: [
                                                {
                                                    path: '',
                                                    name: 'participant-program-attempt-details',
                                                    component: () => import(/* webpackChunkName: "participant-program-attempt-details" */ '@/views/Participant/Programs/Program/Attempts/Attempt/AttemptDetails.vue')
                                                },
                                            ]
                                        },
                                    ]
                                },
                                //
                            ]
                        }
                    ]
                },
                {
                    path: 'apk',
                    name: 'participant-apk',
                    component: () => import(/* webpackChunkName: "participant-apk" */ '@/views/Participant/Apk')
                },

                // process
                {
                    path: 'participant-process',
                    component: () => import(/* webpackChunkName: "participant-email"*/ '@/views/Participant/Process/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'participant-process',
                            component: () => import(/* webpackChunkName: "participant-process" */ '@/views/Participant/Process/GeneralProcess.vue')
                        },
                    ]
                },
            ],
        },

        /**
         * POSTULANT
         */
        {
            path: '/postulant',
            component: () => import(/* webpackChunkName: "Postulant" */ '@/views/Postulant'),
            beforeEnter: (to, from, next) => {
                if (Object.keys(store.state.user.attributes).length != 0) {
                    if (store.getters['user/hasRoleAndIsActive']('postulante')) next()
                    else {
                        if (store.getters['user/getActiveRoles'].length != 0) {
                            switch (store.getters['user/getActiveRoles'][0].value) {
                                case 'admin':
                                    next({ name: 'admin-dashboard' })
                                    break;
                                case 'empleado':
                                    next({ name: 'employee-dashboard' })
                                    break;
                                case 'participante':
                                    next({ name: 'participant-dashboard' })
                                    break;
                                case 'postulante':
                                    next({ name: 'postulant-dashboard' })
                                    break;
                                default:
                                    console.log('break page route')
                                    break;
                            }
                        } else next({ name: 'notActiveRoles-dashboard' })
                    }
                } else next({ name: 'login' })
            },
            children: [
                {
                    path: '',
                    name: 'postulant-dashboard',
                    component: () => import(/* webpackChunkName: "postulant-dashboard" */ '@/views/Postulant/Dashboard')
                },
                {
                    path: 'programs',
                    component: () => import(/* webpackChunkName: "postulant-programs" */ '@/views/Postulant/Programs'),
                    children: [
                        {
                            path: '',
                            name: 'postulant-programs-list', //same
                            component: () => import(/* webpackChunkName: "postulant-programs-list" */ '@/views/Postulant/Programs/ProgramsList.vue'),
                        },
                        {
                            path: 'program/:IdProgramSeasonPostulant', // 'programSeasonPostulant' as 'program'
                            component: () => import(/* webpackChunkName: "postulant-programs-program" */ '@/views/Postulant/Programs/Program'),
                            children: [
                                {
                                    path: '',
                                    name: 'postulant-program-details', //same
                                    component: () => import(/* webpackChunkName: "postulant-program-details" */ '@/views/Postulant/Programs/Program/ProgramDetails.vue'),
                                },
                                {
                                    path: 'payments-vouchers',
                                    name: 'postulant-program-payments-vouchers',
                                    component: () => import(/* webpackChunkName: "postulant-program-payments-vouchers" */ '@/views/Postulant/Programs/Program/ProgramPaymentsVouchers.vue'),
                                },
                                {
                                    path: 'interviews',
                                    component: () => import(/* webpackChunkName: "postulant-program-interviews" */ '@/views/Postulant/Programs/Program/ProgramInterview'),
                                    children: [
                                        {
                                            path: '',
                                            name: 'postulant-program-interviews-list',
                                            component: () => import(/* webpackChunkName: "postulant-program-interviews-list" */ '@/views/Postulant/Programs/Program/ProgramInterview/InterviewsList.vue'),
                                        },
                                        {
                                            path: 'schedule',   // agendar
                                            name: 'postulant-program-interviews-schedule',
                                            component: () => import(/* webpackChunkName: "postulant-program-interviews-schedule" */ '@/views/Postulant/Programs/Program/ProgramInterview/ScheduleInterview.vue'),
                                        },
                                        {
                                            path: 'interview/:IdEnglishInterviewSchedule/details',  // englishInterviewShedule_id
                                            name: 'postulant-program-interviews-interview-details',
                                            component: () => import(/* webpackChunkName: "postulant-interviews-interview-details" */ '@/views/Postulant/Programs/Program/ProgramInterview/InterviewDetails.vue'),
                                        }
                                    ]
                                },
                                {
                                    path: 'contract',
                                    name: 'postulant-program-contracts', //same
                                    component: () => import(/* webpackChunkName: "postulant-programs-program-contract" */ '@/views/Postulant/Programs/Program/ProgramContracts.vue'),
                                },
                            ]
                        }
                    ]
                },
                {
                    path: 'apk',
                    name: 'postulant-apk',
                    component: () => import(/* webpackChunkName: "postulant-apk" */ '@/views/Postulant/Apk')
                },

                // process
                {
                    path: 'postulant-process',
                    component: () => import(/* webpackChunkName: "postulant-email"*/ '@/views/Postulant/Process/index.vue'),
                    children: [
                        {
                            path: '',
                            name: 'postulant-process',
                            component: () => import(/* webpackChunkName: "postulant-process" */ '@/views/Postulant/Process/GeneralProcess.vue')
                        },
                    ]
                },
            ],
        },

        /**
         * NOT ROLES ACTIVE
         */
        {
            path: '/not-active-roles',
            component: () => import(/* webpackChunkName: "NotActiveRoles" */ '@/views/NotActiveRoles'),
            beforeEnter: (to, from, next) => {
                if (Object.keys(store.state.user.attributes).length != 0) {
                    if (store.getters['user/getActiveRoles'].length == 0) next()
                    else {
                        switch (store.getters['user/getActiveRoles'][0].value) {
                            case 'admin':
                                next({ name: 'admin-dashboard' })
                                break;
                            case 'empleado':
                                next({ name: 'employee-dashboard' })
                                break;
                            case 'participante':
                                next({ name: 'participant-dashboard' })
                                break;
                            case 'postulante':
                                next({ name: 'postulant-dashboard' })
                                break;
                            default:
                                console.log('break page route')
                                break;
                        }
                    }
                } else next({ name: 'login' })
            },
            children: [
                {
                    path: '',
                    name: 'notActiveRoles-dashboard',
                    component: () => import(/* webpackChunkName: "notActiveRoles-dashboard" */ '@/views/NotActiveRoles/Dashboard')
                },
            ],
        },

        /**
         * NOT FOUND
         */
        { path: '*', name:"not-found",component: NotFound },

        /**
         * ONLY ONE PAGE
         */
        { path: '/only-one-page', name:"only-one-page",component: OnePage },

        /**
         * MANTENAINCE
         */
        {
            path: '/maintenance',
            name: 'maintenance',
            component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance/index.vue')
        },
    ],
})
