import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function updateProgramSeasonParticipantVisaData (pFormdata ,pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/${pIdProgramSeasonParticipant}/update-visa-data`
    return post(URI, pFormdata, { headers })
}

export { updateProgramSeasonParticipantVisaData }