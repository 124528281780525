import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getSponsorSponsorJobOffers (pPage, IdSponsor) {
    const URI = `${BASE_URL}/api/sponsors/${IdSponsor}/sponsorJobOffers?page=${pPage}`
    return get(URI, { headers })
}

// function getAllSeasons () {
//     const URI = `${BASE_URL}/api/all-seasons`
//     return get(URI, { headers })
// }

// function storeSeason (pFormData) {
//     const URI = `${BASE_URL}/api/seasons`
//     return post(URI, pFormData)
// }

export {
    getSponsorSponsorJobOffers,
}