import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getParticipantVisaDocuments (pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipants/${pIdProgramSeasonParticipant}/participantVisaDocuments`
    return axios.get(URI, { headers })
}

function storeParticipantVisaDocument (pFormData) {
    const URI = `${BASE_URL}/api/participantVisaDocuments`
    return axios.post(URI, pFormData, { headers })
}

function updateParticipantVisaDocument (pIdParticipantVisaDocument, pFormData) {
    const URI = `${BASE_URL}/api/participantVisaDocuments/${pIdParticipantVisaDocument}`
    return axios.post(URI, pFormData, { headers })
}

function destroyParticipantVisaDocument (pIdParticipantVisaDocument) {
    const URI = `${BASE_URL}/api/participantVisaDocuments/${pIdParticipantVisaDocument}`
    return axios.delete(URI, { headers })
}

export {
    getParticipantVisaDocuments,
    storeParticipantVisaDocument,
    updateParticipantVisaDocument,
    destroyParticipantVisaDocument
}