<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'list', path: { name: 'admin-admins-list' } }"/>
            <intranet-nav-content-link :link="{ name: 'create', path: { name: 'admin-admins-create' } }"/>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent'
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'AdminAdmins',
    components: {
        IntranetNavContent,
        IntranetNavContentLink,
        IntranetNavContentLinkExact
    }
}
</script>