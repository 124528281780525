import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getSeasons (pPage) {
    const URI = `${BASE_URL}/api/seasons?page=${pPage}`
    return get(URI)
}

function getAllSeasons () {
    const URI = `${BASE_URL}/api/all-seasons`
    return get(URI, { headers })
}

function storeSeason (pFormData) {
    const URI = `${BASE_URL}/api/seasons`
    return post(URI, pFormData)
}

export {
    getSeasons,
    getAllSeasons,
    storeSeason
}