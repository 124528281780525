<template>
    <b-row class="mb-4">
        <b-col cols="12" >
            <b-card bg-variant="transparent" no-body class="border-0">
                <b-card-header class="border-0 bg-transparent">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-white text-button">
                                Temporada :
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="3">
                            <pulse-loader
                                :loading="seasonsLoading"
                                :color="'#fff'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #fff"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasons"
                                v-show="!seasonsLoading"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="seasonsLoading || dataLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loading data -->
                <div style="height:300px;" v-show="seasonsLoading || dataLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <b-row v-if="!seasonsLoading && !dataLoading">
                    <b-col cols="12" class="mb-5">
                        <b-card class="shadow">
                            <b-card-title>
                                Seleccionar y descargar los datos seleccionados de la Ficha de Registro y Ficha de postulación, de los participantes que tengan registrado al menos una Postulación
                            </b-card-title>

                            <div>
                                <b-form-group label="Información de Postulación" label-class="text-uppercase text-primary">
                                    <b-form-checkbox-group
                                        stacked
                                        v-model="fieldsSelected"
                                        :options="postulationInformationFields"
                                    ></b-form-checkbox-group>
                                </b-form-group>

                                <b-form-group label="Ficha de Registro" label-class="text-uppercase text-primary">
                                    <b-form-row>
                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Datos Personales">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="personalDataFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Datos de los padres">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="parentsDataFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Educación">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="educationFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Pasaporte">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="passportFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Visa">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="visaFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Trabajos">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="workFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Anteriores viajes">
                                                <b-form-checkbox-group stacked v-model="fieldsSelected" :options="previouslyTraveledFields"></b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                </b-form-group>
                            </div>

                            <div>
                                <b-col class="text-center">
                                    <b-button size="lg" variant="primary" @click="exportInformation">
                                        <b-icon icon="download" class="mr-2"></b-icon> DESCARGAR
                                    </b-button>
                                </b-col>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
import { participantPostulationInformationExport } from '@/api/Export/Season/Information/ParticipantPostulationInformation'
import FileSaver from 'file-saver';

export default {
    name: "ParticipantPostulationInformationExportComponent",
    data: () => ({
        data: [],
        dataLoading: false,
        //
        seasons: [],
        seasonSelected: '',
        seasonsLoading: false,
        //
        // postulation  information
        postulationInformationFields: [
            { value: 'postulations', text: 'Postulaciones', selected: true},
            { value: 'start_date', text: 'Fecha de inicio' },
            { value: 'end_date', text: 'Fecha de término' },
            { value: 'gender', text: 'Género' },
            { value: 'country', text: 'País' },
            { value: 'english_level', text: 'Nivel de inglés' },
            { value: 'fellow_traveler', text: '¿VIAJAS CON ALGUIEN?' },
            { value: 'have_medical_problems', text: 'Do you have any medical problems?' },
            { value: 'explain_medical_problems', text: 'If was Yes, explain (If was not just set N/A)' },
            { value: 'have_ever_been_convicted', text: 'Have you ever been convicted of a crime? ' },
            { value: 'explain_ever_been_convicted', text: 'If was Yes, explain (If was not just set N/A)' },
            { value: 'have_ever_participated_wat_Program', text: 'Have you ever participated in the J-1 Work/Travel Program? *' },
            { value: 'have_ever_been_denied_visa_by_embassy', text: 'Have you ever been denied a visa by a U.S. Embassy? ' },
            { value: 'how_find_about_wat_program', text: 'How did you find out about this J-1 Work/Travel Program?' },
        ],
        // FICHA DE REGISTRO
        // participant information
        personalDataFields: [
            { value: 'date_birth', text: 'Fecha de nacimiento' },
            { value: 'age', text: 'Edad' },
            { value: 'city_birth', text: 'Ciudad de nacimiento' },
            { value: 'country_birth', text: 'País de nacimiento' },
            { value: 'document_number', text: 'DNI' },

            { value: 'address', text: 'Dirección' },
            { value: 'distric', text: 'Distrito' },
            { value: 'province', text: 'Provincia' },

            { value: 'emer_contact_relationship', text: 'emer. contact relationship' },
            { value: 'emer_contact_fullname', text: 'emer. contact fullname' },
            { value: 'emer_contact_cellphone', text: 'emer. contact cellphone' },

            { value: 'land_line', text: 'Teléfono Fijo' },
            { value: 'phone_number', text: 'Celular' },
            { value: 'email', text: 'Correo electrónico' },
            { value: 'profile_link', text: 'Perfil' },
            { value: 'skype_id', text: 'Skype ID' },
        ],

        parentsDataFields: [
            { value: 'father_full_name', text: 'Apellidos y Nombres (Papá)' },
            { value: 'father_date_birth', text: 'Fecha de nacimiento (Papá)' },
            { value: 'father_cell_phone', text: 'Número Celular (Papá)' },
            { value: 'father_email', text: 'Correo electrónico (Papá)' },

            { value: 'mother_full_name', text: 'Apellidos y Nombres (Mamá)' },
            { value: 'mother_date_birth', text: 'Fecha de nacimiento (Mamá)' },
            { value: 'mother_cell_phone', text: 'Número Celular (Mamá)' },
            { value: 'mother_email', text: 'Correo electrónico (Mamá)' },
        ],

        educationFields: [
            { value: 'career', text: 'Major Field of Study (Career of study)' },
            { value: 'university_institute', text: 'Education Institution (Name of the University or Institute)' },
            { value: 'university_institute_city', text: 'City (Where is the University or Institute)' },
            { value: 'current_semester', text: 'How many semesters of university will be completed at the time of participation? ' },
            { value: 'career_end_date', text: 'When do you expect the participant to receive his/her degree?' },
            { value: 'vacation_start_date', text: 'Scheduled start date for student’s official summer vacation' },
            { value: 'vacation_end_date', text: 'Scheduled end date for student’s official summer vacation' },
        ],

        passportFields: [
            { value: 'passport_number', text: 'Nº Pasaporte' },
            { value: 'passport_emit_date', text: 'Fecha emisión de pasaporte' },
            { value: 'passport_expiration_date', text: 'Fecha expiración de pasaporte' },
        ],

        visaFields: [
            { value: 'visa_denied_why', text: '¿Te negaron la visa a USA alguna vez, cuándo?' },

            { value: 'had_american_visa', text: '¿Tuviste algun tipo de VISA Americana?' },
            { value: 'last_visa_number', text: 'Número de última VISA' },
            { value: 'last_visa_emit_date', text: 'Fecha de emisión de última VISA' },
            { value: 'last_visa_expiration_date', text: 'Fecha de expiración de última VISA' },
        ],

        workFields: [
            { value: 'work_experience_one_company', text: 'Nombre de empresa (Exp. 1)' },
            { value: 'work_experience_one_position', text: 'Cargo (Exp. 1)' },

            { value: 'work_experience_two_position', text: 'Nombre de empresa (Exp. 2)' },
            { value: 'work_experience_two_company', text: 'Cargo (Exp. 2)' },

            { value: 'work_experience_total_time', text: 'Tiempo total de experiencia Laboral' },
            { value: 'type_of_work', text: '¿Que tipo de trabajo deseas realizar en USA?' },
        ],
        previouslyTraveledFields: [
            { value: 'previously_traveled_program', text: '¿Has viajado anteriormente por el programa Work and Travel?' },
            { value: 'agency_name', text: 'Nombre de la agencia' },
            { value: 'why_change_agency', text: '¿Por qué cambiaste de agencia?' },
            { value: 'company_did_work', text: '¿En qué empresa laboraste?' },
            { value: 'company_state_city', text: 'Indica en qué estado y ciudad estaba ubicada la empresa' },
            { value: 'company_position', text: '¿Qué posición ocupabas?' },
            { value: 'company_hourly_wage', text: '¿Cuál era tu salario por hora?' },
            { value: 'ssn', text: 'Social Security Number' },
        ],
        fieldsSelected: ['postulations']
    }),

    mounted() {
        this.getSeasons()
    },

    methods: {
        getSeasons() {
            this.seasonsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasons = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))

                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasons) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                })
                .finally(() => {
                    this.seasonsLoading = false
                })
        },

        exportInformation() {
            this.dataLoading = true
            let fieldstoRequest = this.fieldsSelected.join(',')
            participantPostulationInformationExport(this.seasonSelected, { fields: fieldstoRequest })
                .then(res => {
                    FileSaver.saveAs(res.data, 'Leads_vouchers.xlsx');
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },

        reloadData () {
            this.getSeasons()
        },
    }
}
</script>