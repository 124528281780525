<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button d-none d-sm-block">
                                LISTA DE PARTICIPANTES
                            </h3>

                            <h3 class="mb-0 text-primary text-button d-block d-sm-none">
                                PART.
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchParticipant(1)">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col >
                                        <b-form-group class="mb-0">
                                            <b-button
                                                class="w-100"
                                                size="sm"
                                                variant="primary"
                                                type="submit"
                                            ><b-icon icon="search" class="m-0 p-0"/></b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>

                        <b-col cols="auto" class="mt-3 mt-sm-0">
                            <b-button size="sm" variant="green" @click="exportExcel">
                                <b-icon icon="download"></b-icon>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'created' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('created')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-numeric-down-alt" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98z"/>
                                    <path d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'a-z' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('a-z')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                                    <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'z-a' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('z-a')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-alpha-down-alt" viewBox="0 0 16 16">
                                    <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V7z"/>
                                    <path fill-rule="evenodd" d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371h-1.781zm1.57-.785L11 9.688h-.047l-.652 2.156h1.351z"/>
                                    <path d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>fecha - hora</th>

                            <!-- WWCE AND GEOVISION -->
                            <th>APELLIDOS</th>
                            <th>NOMBRES</th>
                            <th>CELULAR</th>
                            <th>SKYPE ID</th>
                            <th>CORREO</th>
                            <th>FECHA DE INICIO</th>
                            <th>FECHA DE TÉRMINO</th>
                            <th>GÉNERO</th>
                            <th>PAÍS</th>
                            <th>NIVEL DE INGLÉS</th>
                            <th>¿VIAJAS CON ALGUIEN?</th>

                            <th class="text-center bg-primary text-white">PRIMERA OPCIÓN </th>

                            <th class="text-center bg-cielo text-white">SEGUNDA OPCIÓN </th>

                            <th class="text-center bg-naranja text-white">Tercera OPCIÓN </th>

                            <th class="text-center bg-magenta text-white">Cuarta OPCIÓN </th>

                            <!-- more info -->
                            <th>Full name (first, middle and last name)</th>
                            <th>Do you have any medical problems?</th>
                            <th>If was Yes, explain (If was not just set N/A)</th>
                            <th>Have you ever been convicted of a crime? </th>
                            <th>If was Yes, explain (If was not just set N/A)</th>
                            <th>Have you ever participated in the J-1 Work/Travel Program? *</th>
                            <th>Have you ever been denied a visa by a U.S. Embassy? </th>
                            <th>How did you find out about this J-1 Work/Travel Program?</th>

                            <th>Major Field of Study (Career of study)</th>
                            <th>Education Institution (Name of the University or Institute)</th>
                            <th>City (Where is the University or Institute)</th>
                            <th>Country (Where is the University or Institute)</th>
                            <th>How many semesters of university will be completed at the time of participation? </th>
                            <th>When do you expect the participant to receive his/her degree?</th>
                            <th>Scheduled start date for student’s official summer vacation</th>
                            <th>Scheduled end date for student’s official summer vacation</th>
                            <th>Scheduled start date for the job</th>
                            <th>Scheduled end date for the job</th>
                            <th>I agree that APK uses this information as part of the process of participation in the WORK AND TRAVEL 2020-2021 program.</th>
                            
                            <td class="bg-light"></td>
                            <th>datebirth</th>
                            <th>age</th>
                            <th>city birth</th>
                            <th>country birth</th>
                            <th>DNI</th>

                            <!-- address -->
                            <th>address</th>
                            <th>address district</th>
                            <th>address province</th>

                            <!-- emer. contact -->
                            <th>emer. contact relationship</th>
                            <th>emer. contact fullname</th>
                            <th>emer. contact cellphone</th>

                            <!-- passport -->
                            <th>Nº Pasaporte</th>
                            <th>Fecha emisión de pasaporte</th>
                            <th>Fecha expiración de pasaporte</th>

                            <!-- contact -->
                            <th>Teléfono Fijo</th>
                            <th>Celular</th>
                            <th>Correo electrónico</th>
                            <th>Perfil</th>
                            <th>Skype ID</th>

                            <!-- father -->
                            <th>Apellidos y Nombres (Papá)</th>
                            <th>Fecha de nacimiento (Papá)</th>
                            <th>Número Celular (Papá)</th>
                            <th>Correo electrónico (Papá)</th>

                            <!-- mother -->
                            <th>Apellidos y Nombres (Mamá)</th>
                            <th>Fecha de nacimiento (Mamá)</th>
                            <th>Número Celular (Mamá)</th>
                            <th>Correo electrónico (Mamá)</th>

                            <!-- exp lab 1 -->
                            <th>Nombre de empresa (Exp. 1)</th>
                            <th>Cargo (Exp. 1)</th>
                            <!-- exp lab 2 -->
                            <th>Nombre de empresa (Exp. 2)</th>
                            <th>Cargo (Exp. 2)</th>
                            <!--  -->
                            <th>Tiempo total de experiencia Laboral</th>
                            <th>¿Que tipo de trabajo deseas realizar en USA?</th>

                            <th>¿Te negaron la visa a USA alguna vez, cuándo?</th>

                            <!-- had visa -->
                            <th>¿Tuviste algun tipo de VISA Americana?</th>
                            <th>Número de última VISA</th>
                            <th>Fecha de emisión de última VISA</th>
                            <th>Fecha de expiración de última VISA</th>

                            <!-- previous traveled -->
                            <th>¿Has viajado anteriormente por el programa Work and Travel?</th>
                            <th>Nombre de la agencia</th>
                            <th>¿Por qué cambiaste de agencia?</th>
                            <th>¿En qué empresa laboraste?</th>
                            <th>Indica en qué estado y ciudad estaba ubicada la empresa</th>
                            <th>¿Qué posición ocupabas?</th>
                            <th>¿Cuál era tu salario por hora?</th>
                            <th>Social Security Number</th>


                            <!-- <th class="text-center bg-primary text-white"><div>pago de</div><div>entrevista</div></th>
                            <th class="text-center bg-primary text-white"><div>contrato</div></th> -->
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                             <td>
                                <span class="mr-2 text-primary">{{ row.attributes.postulation_information_created_at }}</span>
                            </td>

                            <td>{{ row.attributes.user_last_name }}</td>
                            <td>{{ row.attributes.user_name }}</td>
                            <td>{{ row.attributes.user_phone_number }}</td>
                            <td>{{ row.attributes.participant_information_skype_id }}</td>
                            <td>{{ row.attributes.user_email }}</td>
                            <td>{{ row.attributes.postulation_information_start_date_formated }}</td>
                            <td>{{ row.attributes.postulation_information_end_date_formated }}</td>
                            <td>{{ row.attributes.postulation_information_gender }}</td>
                            <td>{{ row.attributes.postulation_information_country }}</td>
                            <td>{{ row.attributes.postulation_information_english_level }}</td>
                            <td>{{ row.attributes.postulation_information_fellow_traveler }}</td>

                            <td>
                                <span class="mr-2 text-primary">{{ row.attributes.postulation_1 ? row.attributes.postulation_1.attributes.created_at : null }}</span>
                                <span class="mr-2 text-contract-1">{{ row.attributes.postulation_1 ? row.attributes.postulation_1.attributes.status : null }}</span>
                                <span class="mr-2">{{ row.attributes.postulation_1 ? row.attributes.postulation_1.attributes.programSponsorJob.attributes.job_name : null }}</span>
                                <b-btn v-if="row.attributes.postulation_1"
                                    @click="deleteAttempt(row.attributes, 'postulation_1', row.attributes.postulation_1)" variant="outline-magenta" size="sm" :disabled="row.attributes.postulation_1.deleteLoading"><b-icon icon="trash" scale="1.3" :animation="row.attributes.postulation_1.deleteLoading ? 'throb' : ''"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <span class="mr-2 text-primary">{{ row.attributes.postulation_2 ? row.attributes.postulation_2.attributes.created_at : null }}</span>
                                <span class="mr-2 text-contract-1">{{ row.attributes.postulation_2 ? row.attributes.postulation_2.attributes.status : null }}</span>
                                <span class="mr-2">{{ row.attributes.postulation_2 ? row.attributes.postulation_2.attributes.programSponsorJob.attributes.job_name : null }}</span>
                                <b-btn v-if="row.attributes.postulation_2"
                                    @click="deleteAttempt(row.attributes, 'postulation_2',row.attributes.postulation_2)" variant="outline-magenta" size="sm" :disabled="row.attributes.postulation_2.deleteLoading"><b-icon icon="trash" scale="1.3" :animation="row.attributes.postulation_2.deleteLoading ? 'throb' : ''"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <span class="mr-2 text-primary">{{ row.attributes.postulation_3 ? row.attributes.postulation_3.attributes.created_at : null }}</span>
                                <span class="mr-2 text-contract-1">{{ row.attributes.postulation_3 ? row.attributes.postulation_3.attributes.status : null }}</span>
                                <span class="mr-2">{{ row.attributes.postulation_3 ? row.attributes.postulation_3.attributes.programSponsorJob.attributes.job_name : null }}</span>
                                <b-btn v-if="row.attributes.postulation_3"
                                    @click="deleteAttempt(row.attributes, 'postulation_3',row.attributes.postulation_3)" variant="outline-magenta" size="sm" :disabled="row.attributes.postulation_3.deleteLoading"><b-icon icon="trash" scale="1.3" :animation="row.attributes.postulation_3.deleteLoading ? 'throb' : ''"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <span class="mr-2 text-primary">{{ row.attributes.postulation_4 ? row.attributes.postulation_4.attributes.created_at : null }}</span>
                                <span class="mr-2 text-contract-1">{{ row.attributes.postulation_4 ? row.attributes.postulation_4.attributes.status : null }}</span>
                                <span class="mr-2">{{ row.attributes.postulation_4 ? row.attributes.postulation_4.attributes.programSponsorJob.attributes.job_name : null }}</span>
                                <b-btn v-if="row.attributes.postulation_4"
                                    @click="deleteAttempt(row.attributes, 'postulation_4',row.attributes.postulation_4)" variant="outline-magenta" size="sm" :disabled="row.attributes.postulation_4.deleteLoading"><b-icon icon="trash" scale="1.3" :animation="row.attributes.postulation_4.deleteLoading ? 'throb' : ''"></b-icon>
                                </b-btn>
                            </td>

                            <!-- more info -->
                            <td>{{ row.attributes.postulation_information_first_middle_last_name }}</td>
                            <td>{{ row.attributes.postulation_information_have_medical_problems }}</td>
                            <td>{{ row.attributes.postulation_information_explain_medical_problems }}</td>
                            <td>{{ row.attributes.postulation_information_have_ever_been_convicted }}</td>
                            <td>{{ row.attributes.postulation_information_explain_ever_been_convicted }}</td>
                            <td>{{ row.attributes.postulation_information_have_ever_participated_wat_Program }}</td>
                            <td>{{ row.attributes.postulation_information_have_ever_been_denied_visa_by_embassy }}</td>
                            <td>{{ row.attributes.postulation_information_how_find_about_wat_program }}</td>

                            <td>{{ row.attributes.participant_information_career }}</td>
                            <td>{{ row.attributes.participant_information_university_institute }}</td>
                            <td>{{ row.attributes.participant_information_university_institute_city }}</td>
                            <td>{{ row.attributes.postulation_information_country }}</td>
                            <td>{{ row.attributes.participant_information_current_semester }}</td>
                            <td>{{ row.attributes.participant_information_career_end_date_year }}</td>
                            <td>{{ row.attributes.participant_information_vacation_start_date_formated }}</td>
                            <td>{{ row.attributes.participant_information_vacation_end_date_formated }}</td>
                            <td>{{ row.attributes.postulation_information_start_date_formated }}</td>
                            <td>{{ row.attributes.postulation_information_end_date_formated }}</td>
                            <td>{{ row.attributes.postulation_information_agree }}</td>

                            <td class="bg-light"></td>
                            <td>{{ row.attributes.participant_date_birth_formated }}</td>
                            <td>{{ row.attributes.participant_age }}</td>
                            <td>{{ row.attributes.participant_city_birth }}</td>
                            <td>{{ row.attributes.participant_country_birth }}</td>
                            <td>{{ row.attributes.user_document_number }}</td>

                            <!-- address -->
                            <td>{{ row.attributes.participant_information_address }}</td>
                            <td>{{ row.attributes.participant_information_distric }}</td>
                            <td>{{ row.attributes.participant_information_province }}</td>

                            <!-- emer. contact -->
                            <td>{{ row.attributes.participant_information_emer_contact_relationship }}</td>
                            <td>{{ row.attributes.participant_information_emer_contact_fullname }}</td>
                            <td>{{ row.attributes.participant_information_emer_contact_cellphone }}</td>

                            <!-- passport -->
                            <td>{{ row.attributes.participant_information_passport_number }}</td>
                            <td :class="[ row.attributes.participant_information_passport_number == 'No tengo' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_passport_emit_date }}</td>
                            <td :class="[ row.attributes.participant_information_passport_number == 'No tengo' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_passport_expiration_date }}</td>

                            <!-- contact -->
                            <td>{{ row.attributes.participant_information_land_line }}</td>
                            <td>{{ row.attributes.user_phone_number }}</td>
                            <td>{{ row.attributes.user_email }}</td>
                            <td>{{ row.attributes.participant_information_profile_link }}</td>
                            <td>{{ row.attributes.participant_information_skype_id }}</td>

                            <!-- father -->
                            <td>{{ row.attributes.participant_father_full_name }}</td>
                            <td>{{ row.attributes.participant_father_date_birth }}</td>
                            <td>{{ row.attributes.participant_father_cell_phone }}</td>
                            <td>{{ row.attributes.participant_father_email }}</td>

                            <!-- mother -->
                            <td>{{ row.attributes.participant_mother_full_name }}</td>
                            <td>{{ row.attributes.participant_mother_date_birth }}</td>
                            <td>{{ row.attributes.participant_mother_cell_phone }}</td>
                            <td>{{ row.attributes.participant_mother_email }}</td>

                            <!-- exp lab 1 -->
                            <td>{{ row.attributes.participant_information_work_experience_one_company }}</td>
                            <td>{{ row.attributes.participant_information_work_experience_one_position }}</td>
                            <!-- exp lab 2 -->
                            <td>{{ row.attributes.participant_information_work_experience_two_company }}</td>
                            <td>{{ row.attributes.participant_information_work_experience_two_position }}</td>
                            <!--  -->
                            <td>{{ row.attributes.participant_information_work_experience_total_time }}</td>
                            <td>{{ row.attributes.participant_information_type_of_work }}</td>

                            <td>{{ row.attributes.participant_information_visa_denied_why }}</td>

                            <!-- had visa -->
                            <td>{{ row.attributes.participant_information_had_american_visa }}</td>
                            <td :class="[ row.attributes.participant_information_had_american_visa == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_last_visa_number }}</td>
                            <td :class="[ row.attributes.participant_information_had_american_visa == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_last_visa_emit_date }}</td>
                            <td :class="[ row.attributes.participant_information_had_american_visa == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_last_visa_expiration_date }}</td>

                            <!-- previous traveled -->
                            <td>{{ row.attributes.participant_information_previously_traveled_program }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_agency_name }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_why_change_agency }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_company_did_work }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_company_state_city }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_company_position }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_company_hourly_wage }}</td>
                            <td :class="[ row.attributes.participant_information_previously_traveled_program == 'no' ? 'bg-schedule-capacitation-0' : '' ]">{{ row.attributes.participant_information_ssn }}</td>

                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { programSeasonPostulationSearch, programSeasonPostulationExport } from '@/api/ProgramSeason/ProgramSeasonPostulationSearch';
import { destroyPostulation } from '@/api/Postulation/Postulation';
import FileSaver from 'file-saver';
import swal from 'sweetalert';

export default {
    name: "ProgramSponsorPostulations",
    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        input_to_search: '',
        order_type: 'created',
    }),
    created() {
        this.searchParticipant()
    },
    methods: {
        /**search */
        searchParticipant () {
            this.loadingTableData = true

            const SearchForm = new FormData()
            SearchForm.append('input_to_search', this.input_to_search)
            SearchForm.append('order_type', this.order_type)
            //
            SearchForm.append('test', 'no')

            programSeasonPostulationSearch(this.$route.params.IdProgramSeason, this.$route.params.IdProgramSeasonSponsor, SearchForm)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        exportExcel() {
            this.loadingTableData = true

            const SearchForm = new FormData()
            SearchForm.append('input_to_search', this.input_to_search)
            SearchForm.append('order_type', this.order_type)
            //
            SearchForm.append('test', 'no')

            programSeasonPostulationExport(this.$route.params.IdProgramSeason, this.$route.params.IdProgramSeasonSponsor, SearchForm)
                .then(res => {
                    FileSaver.saveAs(res.data, 'postulaciones.xlsx');
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        listOrderBy(pType) {
            this.order_type = pType
            this.searchParticipant()
        },

        reloadData () {
            this.searchParticipant()
        },

        deleteAttempt(pObject, pSubject, pPostulation) {
            swal('Se eliminará la postulacion.', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pPostulation, 'deleteLoading', true)
                            destroyPostulation(pPostulation.attributes.id)
                                .then(() => {
                                    swal('Se eliminó correctamente.', '', 'success')
                                        .then(val => {
                                            switch(val) {
                                                case true:
                                                case null:
                                                    this.$set(pObject, `${pSubject}`, null)
                                                    if(pObject.postulation_1 == null && pObject.postulation_2 == null && pObject.postulation_3 == null && pObject.postulation_4 == null) this.reloadData()
                                                    break;
                                                default:
                                                    console.log('delete-attempt-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    pPostulation.deleteLoading = false
                                })
                            break;

                        case null:
                            break;

                        default:
                            console.log('delete-attempt-break');
                            break;
                    }
                })
        }
    }
}
</script>