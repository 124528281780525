function dateFormatedToUE (pdate) {
    let yyyy  = pdate.getFullYear()

    let mm = pdate.getMonth() + 1
    if (mm < 10) mm = '0' + mm

    let dd = pdate.getDate()
    if (dd < 10) dd = '0' + dd

    return yyyy + '-' + mm + '-' + dd
}

export { 
    dateFormatedToUE
}