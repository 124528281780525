<template>
    <!-- nlv 1 -->
    <intranet-dashboard :ui="'postulant'">
        <template slot="routes">
            <sidebar-item-exact :link="{ name: 'Dashboard', icon: 'display', color: 'magenta', path: { name: 'postulant-dashboard' } }"/>
            <sidebar-item :link="{ name: 'Programs', icon: 'box', color: 'primary', path: { name: 'postulant-programs-list' } }"/>
            <sidebar-item :link="{ name: 'APK', icon: 'credit-card', color: 'cielo', path: { name: 'postulant-apk' } }"/>
            <sidebar-item :link="{ name: 'Proceso Programa 2023-2024', icon: 'credit-card', color: 'primary', path: { name: 'postulant-process' } }"/>
        </template>
    </intranet-dashboard>
</template>


<script>
import IntranetDashboard from '@/layout/Dashboard/IntranetDashboard.vue';

export default {
    components: { IntranetDashboard }
}
</script>