<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Backups', path: { name: 'admin-backups-backup' } }"/>
            <!-- <intranet-nav-content-link :link="{ name: 'create', path: { name: 'admin-employees-create' } }"/> -->
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'AdminBackups',
    components: { 
        IntranetNavContent,
        // IntranetNavContentLink,
        IntranetNavContentLinkExact
    },
};
</script>
