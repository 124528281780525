import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

// get-contract-vouchers - without pagiante
function getContractVouchers (pIdProgramSeasonPostulant) {
    const URI = `${BASE_URL}/api/programSeasonPostulants/${pIdProgramSeasonPostulant}/contractVouchers`
    return get(URI , { headers })
}

function updateContractVoucher (pFormData, pIdContractVoucher) {
    const URI = `${BASE_URL}/api/contractVouchers/${pIdContractVoucher}`
    return post(URI, pFormData, { headers })
}

function storeContractVoucher (pFormData) {
    const URI = `${BASE_URL}/api/contractVouchers`
    return post(URI, pFormData , { headers })
}
export {
    getContractVouchers,
    updateContractVoucher,
    storeContractVoucher
}