import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getVisaDocuments () {
    const URI = `${BASE_URL}/api/visaDocuments`
    return axios.get(URI, { headers })
}

function storeVisaDocument (pFormData) {
    const URI = `${BASE_URL}/api/visaDocuments`
    return axios.post(URI, pFormData, { headers })
}

function updateVisaDocument (pIdVisaDocument, pFormData) {
    const URI = `${BASE_URL}/api/visaDocuments/${pIdVisaDocument}`
    return axios.post(URI, pFormData, { headers })
}

function destroyVisaDocument (pIdVisaDocument) {
    const URI = `${BASE_URL}/api/visaDocuments/${pIdVisaDocument}`
    return axios.delete(URI, { headers })
}

export {
    getVisaDocuments,
    storeVisaDocument,
    updateVisaDocument,
    destroyVisaDocument
}