<template>
    <b-row class="mb-4">
        <b-col cols="12" >
            <b-card bg-variant="transparent" no-body class="border-0">
                <b-card-header class="border-0 bg-transparent">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-white text-button d-none d-sm-block">
                                LEADS
                            </h3>

                            <h3 class="mb-0 text-white text-button d-block d-sm-none">
                                PART.
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="3">
                            <pulse-loader
                                :loading="seasonsLoading"
                                :color="'#fff'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #fff"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasons"
                                v-show="!seasonsLoading"
                                @change="getData"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="seasonsLoading || dataLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loading data -->
                <div style="height:300px;" v-show="seasonsLoading || dataLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- charts -->
                <b-row v-if="!seasonsLoading && !dataLoading">
                    <!-- GRAPH ONE -->
                    <b-col cols="12" lg="12" class="mb-5">
                        <b-card class="shadow" bg-variant="apk-darker">
                            <!-- <b-card-header header-bg-variant="transparent">
                                <b-row>
                                    <b-col>General</b-col>
                                    <b-col>Por Asesor</b-col>
                                </b-row>
                            </b-card-header> -->

                            <div>
                                <general-bar-chart-component
                                    :id="chartDataOne.id"
                                    :graphType="chartDataOne.graph_type"
                                    :labels="chartDataOne.labels"
                                    :datasets="chartDataOne.datasets"
                                    :options="chartDataOne.options"
                                    :moreData="chartDataOne.moreData"
                                ></general-bar-chart-component>
                            </div>
                        </b-card>
                    </b-col>

                    <!-- GRAPH TWO -->
                    <b-col cols="12" lg="6" class="mb-5">
                        <b-card class="shadow" bg-variant="apk-darker">
                            <general-bar-chart-component
                                :id="chartDataTwo.id"
                                :graphType="chartDataTwo.graph_type"
                                :labels="chartDataTwo.labels"
                                :datasets="chartDataTwo.datasets"
                                :options="chartDataTwo.options"
                                :moreData="chartDataTwo.moreData"
                            ></general-bar-chart-component>
                        </b-card>
                    </b-col>

                    

                    <!-- GRAPH THREE -->
                    <b-col cols="12" lg="6">
                        <b-card class="shadow graph-three" bg-variant="apk-darker">
                            <general-doughnut-chart-component
                                :id="chartDataThree.id"
                                :graphType="chartDataThree.graph_type"
                                :labels="chartDataThree.labels"
                                :datasets="chartDataThree.datasets"
                                :options="chartDataThree.options"
                                :moreData="chartDataThree.moreData"
                            ></general-doughnut-chart-component>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getStatisticsAdminHome } from '@/api/Statistics/Admin/Home';
import { getAllSeasons } from '@/api/Season/Season'
import GeneralBarChartComponent from '../../../components/Chart/GeneralBarChartComponent.vue';
import GeneralDoughnutChartComponent from '../../../components/Chart/GeneralDoughnutChartComponent.vue';

import Chart from 'chart.js/auto'

export default {
  components: { GeneralBarChartComponent, GeneralDoughnutChartComponent },
    name: "Home",
    props: {
      chartId: {
        type: String,
        default: 'bar-chart'
        },
        datasetIdKey: {
        type: String,
        default: 'label'
        },
        width: {
        type: Number,
        default: 400
        },
        height: {
        type: Number,
        default: 400
        },
        cssClasses: {
        default: '',
        type: String
        },
        styles: {
        type: Object,
        default: () => {}
        },
        plugins: {
        type: Object,
        default: () => {}
        }
  },
    data: () => ({
        data: [],
        dataLoading: false,
        //
        seasons: [],
        seasonSelected: '',
        seasonsLoading: false,
        //
        chartDataOne: { id: 'home-graph-one'},
        chartDataTwo: { id: 'home-graph-two'},
        chartDataThree: { id: 'home-graph-three'}
    }),

    mounted() {
        this.getSeasons()
    },

    methods: {
        getSeasons() {
            this.seasonsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasons = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))

                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasons) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }

                    // request to data by season
                    this.getData()
                })
                .finally(() => {
                    this.seasonsLoading = false
                })
        },

        getData() {
            this.dataLoading = true
            getStatisticsAdminHome(this.seasonSelected)
                .then(({ data }) => {
                    // DATA ONE
                    this.setDataOne(data.data.graph_one)

                    // DATA TWO - bar
                    this.setDataTwo(data.data.graph_two)

                    // DATA THREE - doughnut
                    this.setDataThree(data.data.graph_three)

                })
                .finally(() => {
                    this.dataLoading = false
                })
        },

        setDataOne(pGrapOne) {
            this.chartDataOne = {
                id: 'home-graph-one',
                graph_type: 'bar',
                labels: pGrapOne.labels,
                datasets: [
                    {
                        label: 'Leads',
                        data: pGrapOne.dataset,
                        backgroundColor: 'rgba(94, 139, 126, 0.2)',
                        borderColor: 'rgba(94, 139, 126, 1)',
                        borderRadius: 5,
                        borderWidth: 2
                    },
                    //         // 'rgba(21, 114, 161, 1)',
                    //         // 'rgba(219, 107, 151, 1)',
                    //         'rgba(94, 139, 126, 1)',
                    //         // 'rgba(255, 159, 64, 1)'
                ],
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'LEADS REGISTRADOS POR MES',
                            color: 'rgba(255, 255, 255)',
                            padding: { top: 0, bottom: 20 }
                        },
                        subtitle: {
                            display: true,
                            position: 'top',
                            align: 'end',
                            font: {
                                weight: 'bold'
                            },
                            text: 'TOTAL : ' + pGrapOne.total + ' LEADS',
                            color: 'rgba(255, 255, 255)',
                            padding: { top: 0, bottom: 10 }
                        },
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                color: 'rgb(255,255,255)',
                            }
                        },
                        tooltip: {
                            titleAlign: 'center',
                            footerAlign: 'center',
                            footerColor: 'rgba(255,255,255,0.5)',
                            footerMarginTop: 20,
                            footerFont: {size: 10},
                            callbacks: {
                                footer: (tooltipItems) => {
                                    let sum = 0
                                    tooltipItems.forEach(tooltipItem => {
                                        console.log(tooltipItem);
                                        if (tooltipItem.parsed._stacks) {
                                            for(let i = 0; i < Object.keys(tooltipItem.parsed._stacks.y).length - 2; i++) {
                                                sum += tooltipItem.parsed._stacks.y[i]
                                            }
                                        }
                                    })
                                    return 'TOTAL: ' + sum
                                }
                            }
                            
                        }
                    },
                },
                moreData: {
                    ticks: {
                        color: 'rgba(255,255,255, 1)'
                    },
                    stacked: true
                }
            }
        },

        setDataTwo(pGrapTwo) {
            this.chartDataTwo = {
                id: 'home-graph-two',
                graph_type: 'bar',
                labels: pGrapTwo.labels,
                datasets: [
                    {
                        label: 'Leads',
                        data: pGrapTwo.dataset,
                        backgroundColor: [
                            'rgba(21, 114, 161, 0.2)',
                            'rgba(219, 107, 151, 0.2)',
                            'rgba(94, 139, 126, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(21, 114, 161, 1)',
                            'rgba(219, 107, 151, 1)',
                            'rgba(94, 139, 126, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderRadius: 5,
                        borderWidth: 2
                    }
                ],
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'TIPOS DE LEADS',
                            color: 'rgba(255, 255, 255)',
                            padding: { top: 0, bottom: 10 }
                        },
                        subtitle: {
                            display: true,
                            position: 'top',
                            align: 'end',
                            font: { weight: 'bold' },
                            text: 'TOTAL : ' + pGrapTwo.total + ' LEADS',
                            color: 'rgba(255, 255, 255)',
                            padding: { top: 0, bottom: 10 }
                        },
                        legend: {
                            display: false,
                            labels: {
                                color: 'rgb(255,255,255)',
                                boxWidth: 0
                            }
                        }
                    },
                },
                moreData: {
                    ticks: {
                        color: 'rgba(255,255,255, 1)'
                    }
                }
            }
        },

        setDataThree (pGrapThree) {
            let backgroundColorPost = ['rgba(153, 102, 255, 0.57)','rgba(65, 182, 230, 0.57)','rgba(75, 192, 192, 0.57)','rgba(255, 206, 86, 0.57)','rgba(239, 74, 129, 0.57)']
            let backgroundColorPart = ['rgba(153, 102, 255, 0.2)','rgba(65, 182, 230, 0.2)','rgba(75, 192, 192, 0.2)','rgba(255, 206, 86, 0.2)','rgba(239, 74, 129, 0.2)']
            let borderColor = ['rgba(153, 102, 255, 1)','rgba(65, 182, 230,1)','rgba(75, 192, 192,1)','rgba(255, 206, 86,1)','rgba(239, 74, 129,1)']

            this.chartDataThree = {
                id: 'home-graph-three',
                graph_type: 'doughnut',
                labels: pGrapThree.labels,
                datasets: [
                    {
                        label: 'Postulantes : ' + pGrapThree.dataset.postulants.total,
                        data: pGrapThree.dataset.postulants.data,
                        backgroundColor: backgroundColorPost,
                        borderColor,
                        borderWidth: 2
                    },
                    {
                        label: 'Participantes : ' + pGrapThree.dataset.participants.total,
                        data: pGrapThree.dataset.participants.data,
                        backgroundColor: backgroundColorPart,
                        borderColor,
                        borderWidth: 2
                    }
                ],
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'POSTULANTES Y PARTICIPANTES POR PROGRAMA',
                            color: 'rgba(255, 255, 255)',
                            padding: { top: 0, bottom: 10 }
                        },
                        legend: {
                            position: 'bottom',
                            labels: {
                                color: 'rgb(255,255,255)',
                                generateLabels: function(chart) {
                                    // Get the default label list
                                    const original = Chart.overrides.doughnut.plugins.legend.labels.generateLabels;
                                    const labelsOriginal = original.call(this, chart);

                                    // Build an array of colors used in the datasets of the chart
                                    let datasetColors = chart.data.datasets.map(e => e.backgroundColor);
                                    datasetColors = datasetColors.flat();

                                    let datasetBorderColors = chart.data.datasets.map(e => e.borderColor);
                                    datasetBorderColors = datasetBorderColors.flat();

                                    // Modify the color and hide state of each label
                                    labelsOriginal.forEach(label => {
                                        // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                                        label.datasetIndex = (label.index - label.index % 5) / 5;

                                        // The hidden state must match the dataset's hidden state
                                        label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                                        // Change the color to match the dataset
                                        label.fillStyle = datasetColors[label.index];
                                        label.strokeStyle = datasetBorderColors[label.index];
                                    });

                                    return labelsOriginal;
                                }
                            },
                            onClick: function(mouseEvent, legendItem, legend) {
                                // toggle the visibility of the dataset from what it currently is
                                legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                                legend.chart.update();
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const labelIndex = (context.datasetIndex * 5) + context.dataIndex;
                                    return context.chart.data.labels[labelIndex] + ': ' + context.formattedValue;
                                }
                            }
                        },
                    },
                },
                moreData: {
                    ticks: {
                        color: 'rgba(255,255,255, 0.0)',
                        display: false
                    }
                }
            }
        },

        reloadData () {
            this.getSeasons()
        },

    }
}
</script>