import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//get
function getContract (pIdContract) {
    const URI = `${BASE_URL}/api/contracts/${pIdContract}`
    return axios.get(URI, { headers })
}

//post
function storeContract (pFormData) {
    const URI = `${BASE_URL}/api/contracts`
    return axios.post(URI, pFormData, { headers })
}

//put
function updateContract (pFormData, pIdContract) {
    const URI = `${BASE_URL}/api/contracts/${pIdContract}`
    return axios.post(URI, pFormData, { headers })
}

//delete
function destroyContract (pIdContract) {
    const URI = `${BASE_URL}/api/contracts/${pIdContract}`
    return axios.delete(URI, { headers })
}

export {
    getContract,
    storeContract,
    updateContract,
    destroyContract
}