<template>
    <b-row class="justify-content-center">
        <b-col cols="12" sm="10" lg="9" xl="8">
            <b-card class="shadow">
                <b-card-header class="mx-0 px-0">
                    <b-row align-h="between">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-primary">
                                CREAR ADMIN.
                            </h3>
                        </b-col>

                        <b-col cols="auto" class="align-self-center" >
                            <b-form-checkbox
                                switch
                                size="sm"
                                v-model="newUser"
                                @change="checkNewUser"
                                class="text-overline"
                                :class="newUser ? 'text-primary' : 'text-muted'"
                            >nuevo usuario</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card-header>

                <br>

                <!-- create admin with admin-new-user-view default -->
                <transition name="fade" mode="out-in" duration="10"  appear>
                    <component :is="adminCreateView" />
                </transition>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import AdminNewUser from '@/views/Admin/Admins/AdminsCreate/AdminNewUser'
import AdminExistingUser from '@/views/Admin/Admins/AdminsCreate/AdminExistingUser'

export default {
    name: "AdmisCreate",
    components: {
        AdminNewUser,
        AdminExistingUser,
    },
    data: () => ({
       adminCreateView: 'admin-new-user',
       newUser: true,
    }),
    methods: {
        checkNewUser (e) {
            if (e) this.adminCreateView = 'admin-new-user'
            else this.adminCreateView = 'admin-existing-user'
        }
    },
}
</script>