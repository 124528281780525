import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function block (pProgramSeasonPostulantID, pFormData = {} ) {
    const URI = `${BASE_URL}/api/access-credentials/${pProgramSeasonPostulantID}/block`
    return axios.post(URI, pFormData, { headers })
}

function activate (pProgramSeasonPostulantID, pFormData = {}) {
    const URI = `${BASE_URL}/api/access-credentials/${pProgramSeasonPostulantID}/activate`
    return axios.post(URI, pFormData, { headers })
}

export {
    block,
    activate,
}