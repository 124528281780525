<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                LISTA
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="searchCulturalAdvisorParticipant(1)"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="getSeasonOptionsLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <!-- input to search -->
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchCulturalAdvisorParticipant(1)">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col >
                                        <b-form-group class="mb-0">
                                            <b-button class="w-100" size="sm" variant="primary" type="submit" :disabled="getSeasonOptionsLoading || loadingTableData">
                                                <b-icon icon="search" class="m-0 p-0"/>
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>

                        <!-- order_by -->
                        <b-col cols="auto" class="mt-3 mt-lg-0">
                            <b-button size="sm" :variant="order_type != 'created' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('created')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-numeric-down-alt" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98z"/>
                                    <path d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'a-z' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('a-z')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                                    <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'z-a' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('z-a')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-alpha-down-alt" viewBox="0 0 16 16">
                                    <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V7z"/>
                                    <path fill-rule="evenodd" d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371h-1.781zm1.57-.785L11 9.688h-.047l-.652 2.156h1.351z"/>
                                    <path d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>
                        </b-col>

                        <!-- employeer : cultural advisor options -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3">
                            <b-form-group class="mb-0 text-primary"
                                label="Asesor Cultural" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="getSeasonOptionsLoading || employeersOptionsLoading || loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    v-model="employerSelected"
                                    :options="employeersOptions"
                                    v-show="!getSeasonOptionsLoading && !employeersOptionsLoading && !loadingTableData"
                                    size="sm"
                                    @change="searchCulturalAdvisorParticipant(1)"
                                    :disabled="ui == 'employee'"
                                >
                                    <template #first v-if="ui == 'admin'">
                                        <b-form-select-option value="" disabled>
                                            -- Seleccione un asesor cultural --
                                        </b-form-select-option>
                                    </template>>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive mb-0" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="alwaysShow details"></th>
                            <th class="alwaysShow name">Participante</th>
                            <th>Respons. Cultural</th>
                            <th>pagos pendientes</th>
                            <th>programa</th>
                            <th>temporada</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <span v-if="order_type == 'created'">{{ tableData.length - index }}</span>
                                <span v-else>{{ index + 1 }}</span>
                            </td>

                            <td class="alwaysShow details text-right">
                                  <b-btn 
                                    :to="{
                                        name: routePayments,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    target="_blank"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>

                            <td class="alwaysShow name">{{ row.attributes.participant_full_name }}</td>

                            <!-- payments -->
                            <td class="apk-pay-group">
                                <div v-for="(payment, i) in row.attributes.pending_payments" :key="`${i}-paym`" class="paym">
                                    <div class="paym-item">
                                        <b-iconstack font-scale="1.3" class="mr-3">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1" :variant="payment.status == 'rojo' ? 'danger' : 'amarillo'"></b-icon>
                                        </b-iconstack>
                                    </div>

                                    <div class="paym-item text-left">{{ payment.payment_concept }}</div>
                                </div>
                            </td>

                            <td>{{ row.attributes.culturalAdvisor_full_name }}</td>
                            <td>{{ row.attributes.program }}</td>
                            <td>{{ row.attributes.season }}</td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
import { getAllActiveCulturalAdvisors } from '@/api/CulturalAdvisor/CulturalAdvisor'
import { filterPayment } from '@/api/ProgramSeasonParticipant/Filters/Payment';

import { updateStatusDocument } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant.js'
import swal from 'sweetalert'

export default {
    name: "PaymentsFilterComponent",
    props: {
        ui: {
            type: String,
            required: true
        },
        routeDetails: {
            type: String,
            required: true
        },
        routePayments: {
            type: String,
            required: true
        },
        
    },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        input_to_search: '',
        order_type: 'created',
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
        //
        employeersOptions: [],
        employerSelected: '',
        employeersOptionsLoading: false,
        //
        programDocumentsSorted: []
    }),

    created() {
        this.getSeasonsData()
    },

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        }
    },

    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    // get cultural advisors with status active
                    this.getEmployeers()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getEmployeers() {
            this.employeersOptionsLoading = true

            getAllActiveCulturalAdvisors()
                .then(({ data }) => {
                    this.employeersOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name}) )

                    if (this.ui == 'admin' && this.employerSelected) {
                        this.searchCulturalAdvisorParticipant()
                    }

                    if (this.ui == 'employee') {
                        this.employerSelected = this.culturalAdvisorPermissionData.attributes.typeAdvisor_id
                        this.searchCulturalAdvisorParticipant()
                    }
                })
                .finally(() => {
                    this.employeersOptionsLoading = false
                })
        },

        /**search */
        searchCulturalAdvisorParticipant () {
            this.loadingTableData = true
            let IdCulturalAdvisor = this.employerSelected   // ok
            let IdSeason = this.seasonSelected

            let params = {
                culturalAdvisor_id    :  IdCulturalAdvisor,
                season_id             :  IdSeason,

                input_to_search       :  this.input_to_search,
                order_type            :  this.order_type,
            }

            filterPayment(params)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        listOrderBy(pType) {
            this.order_type = pType
            this.searchCulturalAdvisorParticipant()
        },

        reloadData () {
            this.getSeasonsData()
        },

        editGroupOneStatus(pParticipant, e) {
            this.$set(pParticipant, 'documentEditLoading', true)

            let StatusForm = new FormData()
            StatusForm.append('.method', 'put')
            StatusForm.append('doc_group_one_status', e)
            StatusForm.append('doc_group_one_comments', pParticipant.attributes.doc_group_one_comments == null ? '' : pParticipant.attributes.doc_group_one_comments)
            StatusForm.append('doc_group_two_status', pParticipant.attributes.doc_group_two_status)
            StatusForm.append('doc_group_two_comments', pParticipant.attributes.doc_group_two_comments == null ? '' : pParticipant.attributes.doc_group_two_comments)

            updateStatusDocument (StatusForm, pParticipant.attributes.id)
                .then(() => {
                    swal('Actualización correcta!', 'Estados actualizados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    break;
                                default:
                                    console.log('swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        if(e == 'presentado') pParticipant.attributes.doc_group_one_status = 'no presentado'
                        if(e == 'no presentado') pParticipant.attributes.doc_group_one_status = 'presentado'
                    }
                })
                .finally(() => {
                    pParticipant.documentEditLoading = false
                })
        },
    }
}
</script>

<style scoped>
.apk-pay-group {
    position: relative;
}
/*  */
.alwaysShow {
    /* background-color: rgb(94, 114, 228, .05); */
    z-index: 10;
}
@media (min-width: 576px) {
    .alwaysShow {
        position: sticky;
        background-color: #f7f8fe;
        border-bottom: 2px solid #e9ecef !important;
    }
}
.alwaysShow.details {
    left: 0px;
}
.alwaysShow.name {
    left: 80px;
}
.paym {
    display: flex;
}
.paym-item {
    margin-right: 8px;
}
</style>