<template>
    <b-row>
        <b-col>
            <!-- card -->
            <b-card class="shadow" no-body>
                <!-- card header -->
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Administradores
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Nombres</th>
                            <th>Correo</th>
                            <th>Celular</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <!-- numeración descendente -->
                                {{ tableMeta.total - ((tableMeta.per_page * (tableMeta.current_page - 1)) + (index + 1)) + 1 }}
                            </td>

                            <td>
                                {{ row.attributes.last_name}}, {{ row.attributes.name}}
                            </td>

                            <td>
                                {{ row.attributes.email}}
                            </td>

                            <td>
                                {{ row.attributes.phone_number}}
                            </td>   

                            <td class="text-right">
                                <b-btn 
                                    :to="{
                                        name: 'admin-admins-details',
                                        params: { 'id': row.id }    //IdAdmin
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAdmins } from '@/api/Admin/Admin';
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: "AdminsList",
    //components: { PulseLoader },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false
    }),
    created() {
        this.getAdminsData()
    },
    methods: {
        getAdminsData (pPage = 1) {
            this.loadingTableData = true

            getAdmins(pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch( err => {
                    console.log('getAdmins Error:', err.response)
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getAdmis request end');
                })
        },
        paginationCLiked (e) {
            this.getAdminsData(e)
        },
        reloadData () {
            this.getAdminsData()
        }
    }
}
</script>