import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getProgramSeasonSponsor (pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsors/${pIdProgramSeasonSponsor}`
    return axios.get(URI, { headers })
}

function storeProgramSeasonSponsor (pFormdata) {
    const URI = `${BASE_URL}/api/programSeasonSponsors`
    return axios.post(URI, pFormdata, { headers })
}

function updateProgramSeasonSponsor (pFormdata, pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsors/${pIdProgramSeasonSponsor}`
    return axios.post(URI, pFormdata, { headers })
}

function destroyProgramSeasonSponsor (pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsors/${pIdProgramSeasonSponsor}`
    return axios.delete(URI, { headers })
}

export {
    getProgramSeasonSponsor,
    storeProgramSeasonSponsor,
    updateProgramSeasonSponsor,
    destroyProgramSeasonSponsor
}