<template>
    <div>
        <!-- MODAL CONTRACT GENERATE -->
        <b-modal
            id="modal-contract-preview"
            centered
            hide-footer
            size="xl"
            header-class="pb-0"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Vista previa <span class="text-naranja" v-show="ui == 'test'">* test</span></h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalContractPreviewClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row v-show="ui == 'test'">
                        <b-col class="text-naranja" style="font-size:12px">
                            <span>(*) Al rellenar los datos o firmar, no se guardará en la base de datos.</span>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row style="overflow-x:auto">
                        <b-col v-show="loading">
                            <div style="height:250px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col cols="12" v-show="!loading && (ui == 'postulant' || ui == 'test' )" class="text-green mb-4" style="font-size:14px">
                            <span>(*) Rellenar para poder enviar a validar.</span>
                        </b-col>


                        <b-col cols="12" v-show="!loading && (ui == 'postulant' || ui == 'test' )" class="mb-5">
                            <b-button @click="modalGeneralFillClick" variant="green" size="sm" >Rellenar</b-button>

                            <!-- <b-button @click="modalContractFillClick" variant="outline-primary" size="sm" >Rellenar</b-button>
                            <b-button @click="modalSignatureClick" variant="outline-primary" size="sm" >Firmar</b-button>
                            <b-button @click="modalFingerprintClick" variant="outline-primary" size="sm" >Huella</b-button>
                            <b-button @click="modalDniAnversoClick" variant="outline-primary" size="sm" >DNI: anverso</b-button>
                            <b-button @click="modalDniReversoClick" variant="outline-primary" size="sm" >DNI: reverso</b-button> -->

                            <b-button @click="enviarPdfGenerado" :variant="isReadyToVerify ? 'primary' : 'light'" size="sm" 
                            :disabled="!isReadyToVerify" v-show="html.verification_status != 'verde'">
                                <div v-show="!verifyLoading" >Enviar a validar</div>

                                <div v-show="verifyLoading" style="width:100px; height:18px">
                                    <pulse-loader :color="'#fff'" :margin="'4px'" :size="6" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-36%)"/>
                                </div>
                            </b-button>


                            <b-button @click="downloadContractGenerated" variant="primary" size="sm" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-contrato-pdf-fill" viewBox="0 0 16 16">
                                    <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                    <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                </svg>
                            </b-button>

                            <!-- <b-button @click="downloadToSing" variant="outline-primary" size="sm" title="Descargar para firmar." v-show="ui == 'postulant'">
                                <b-icon icon="download" class="m-0"></b-icon>
                                <b-icon icon="pencil"></b-icon>
                            </b-button> -->
                        </b-col>

                        <b-col cols="12" v-show="!loading" class="view-wraper">
                            <vue-pdf-embed :source="src" />
                        </b-col>
                    </b-row>
                </b-container>
            </template>

        </b-modal>

        <!-- MODAL CONTRACT FILL -->
        <b-modal
            id="modal-general-fill"
            centered hide-footer size="md" header-class="pb-0" no-close-on-backdrop no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Rellenar</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalGeneralFillClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12" class="text-naranja mb-4" style="font-size:14px">
                            <span>(*) Rellenar los siguientes datos para poder enviar a validar.</span>
                        </b-col>

                        <b-col cols="12">
                            <b-list-group >
                                <b-list-group-item :variant="isDataFill">
                                    <b-row>
                                        <b-col>Datos</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col class="text-center">
                                            <b-button @click="modalContractFillClick" variant="outline-primary" size="sm" >
                                                <b-icon icon="pencil" scale="1.2" variant="primary"></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item :variant="isSignatureFill">
                                    <b-row>
                                        <b-col>Firma</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col class="text-center">
                                            <b-button @click="modalSignatureClick" variant="outline-primary" size="sm" >
                                                <b-icon icon="pencil" scale="1.2" variant="primary"></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item :variant="isFingerprintFill">
                                    <b-row>
                                        <b-col>Huella</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col class="text-center">
                                            <b-button @click="modalFingerprintClick" variant="outline-primary" size="sm" >
                                                <b-icon icon="pencil" scale="1.2" variant="primary"></b-icon>

                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item :variant="isDniAnversoFill">
                                    <b-row>
                                        <b-col>DNI: anverso</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col class="text-center">
                                            <b-button @click="modalDniAnversoClick" variant="outline-primary" size="sm" >
                                                <b-icon icon="pencil" scale="1.2" variant="primary"></b-icon>

                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item :variant="isDniReversoFill">
                                    <b-row>
                                        <b-col>DNI: reverso</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col class="text-center">
                                            <b-button @click="modalDniReversoClick" variant="outline-primary" size="sm" >
                                                <b-icon icon="pencil" scale="1.2" variant="primary"></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- MODAL CONTRACT FILL -->
        <b-modal
            id="modal-contract-fill"
            centered hide-footer size="md" header-class="pb-0" no-close-on-backdrop no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Rellenar datos</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalContractFillClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row style="overflow-x:auto">
                        <b-col>
                            <b-form @submit.prevent="registerPostulantData">
                                <b-row>
                                    <b-col>
                                        <!-- fullname -->
                                        <b-form-row>
                                            <b-col>
                                                <b-form-group
                                                    label="Apellidos y nombres: "
                                                >
                                                    <pulse-loader
                                                        :loading="registerDataLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="text-center pt-2 pb-1 pl-2"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        type="text"
                                                        placeholder="Ingrese sus apellidos y nombres."
                                                        v-model="input_fullname"
                                                        :state="showInputStatus('fullname')"
                                                        v-show="!registerDataLoading"
                                                        style="text-transform: capitalize"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('fullname')"
                                                        :key="`${i}-fill-contract-fullname`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <!-- dni -->
                                        <b-form-row>
                                            <b-col>
                                                <b-form-group
                                                    label="DNI: "
                                                >
                                                    <pulse-loader
                                                        :loading="registerDataLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="text-center pt-2 pb-1 pl-2"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        type="number"
                                                        placeholder="Ingrese dni."
                                                        v-model="input_dni"
                                                        :state="showInputStatus('dni')"
                                                        v-show="!registerDataLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('dni')"
                                                        :key="`${i}-fill-contract-dni`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <!-- address -->
                                        <b-form-row>
                                            <b-col>
                                                <b-form-group
                                                    label="Dirección: "
                                                >
                                                    <pulse-loader
                                                        :loading="registerDataLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="text-center pt-2 pb-1 pl-2"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        type="text"
                                                        placeholder="Ingrese su dirección."
                                                        v-model="input_address"
                                                        :state="showInputStatus('address')"
                                                        v-show="!registerDataLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('address')"
                                                        :key="`${i}-fill-contract-address`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <!-- fecha -->
                                        <b-form-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    label="Fecha :"
                                                >
                                                    <pulse-loader
                                                        :loading="registerDataLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="text-center pt-2 pb-1 pl-2"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-datepicker
                                                        required
                                                        :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
                                                        selected-variant="primary"
                                                        today-variant="naranja"
                                                        locale="es"

                                                        today-button
                                                        label-today-button="today"
                                                        today-button-variant="outline-naranja"

                                                        reset-button
                                                        label-reset-button="reset"
                                                        reset-button-variant="outline-magenta"

                                                        close-button
                                                        label-close-button="ok"
                                                        close-button-variant="primary"

                                                        v-model="input_fecha"
                                                        :state="showInputStatus('date')"
                                                        v-if="!registerDataLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('date')"
                                                        :key="`${i}-fill-contract-date`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <!-- start btn -->
                                        <b-row>
                                            <b-col class="text-center">
                                                <b-button variant="primary" type="submit">RELLENAR</b-button>
                                            </b-col>
                                        </b-row>
                                        <!-- end btn -->
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- MODAL SIGNATURE -->
        <b-modal
            id="modal-signature"
            centered hide-footer size="md" header-class="pb-0" no-close-on-backdrop no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Firma</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalSignatureClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                    <b-row style="overflow-x:auto">
                        <b-col cols="12">
                            <div class="signature--pad--header text-center">
                                <b-button size="sm" variant="contract-1" @click="undo"><b-icon icon="arrow-90deg-left" class="mr-1"></b-icon><span style="font-size:10px">UNDO</span></b-button>
                                <b-button size="sm" variant="contract-1" @click="clear"><b-icon icon="trash2" class="mr-1"></b-icon><span style="font-size:10px">CLEAR</span></b-button>
                            </div>
                        </b-col>

                        <b-col cols="12" class="p-0 mt-3 pt-1" >
                            <div class="signature--pad--body">
                                <canvas id="signature-canvas"></canvas>
                            </div>
                        </b-col>

                        <b-col cols="12" class="mt-5 text-center" v-show="!registerDataLoading">
                            <b-button variant="primary" @click="registerPostulantSignature" class="text-uppercase">
                                Firmar
                            </b-button>
                        </b-col>

                        <b-col v-show="registerDataLoading">
                            <div style="height:80px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>
                    </b-row>
            </template>
        </b-modal>

        <!-- MODAL FINGERPRINT -->
        <b-modal
            id="modal-fingerprint"
            centered hide-footer size="mf" header-class="pb-0" no-close-on-backdrop no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Huella digital</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalFingerprintClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                    <b-row style="overflow-x:auto">
                        <b-col cols="12">
                            <b-col class="text-naranja mb-2 text-center" style="font-size:14px" v-show="!show_fingerprint_cropper">
                                <span>Click en el rectángulo para cargar la imagen.</span>
                            </b-col>

                            <div class="participant--fingerprint--container" v-show="!show_fingerprint_cropper">
                                <img :src="fingerprint_url" id="participant--fingerprint--image">
                                <input type="file" name="fingerprint--input--file" id="fingerprint--input--file" accept=".jpeg,.jpg,.png" @change="fingerprintFileSelected">
                                <label for="fingerprint--input--file" class="fingerprint--input--label">
                                    <div class="fingerprint--edit-button" variant="contract-1" size="lg" ><b-icon icon="pencil" class="icon"/></div>
                                </label>
                            </div>
                        </b-col>

                        <b-col cols="12" class="p-0" v-show="show_fingerprint_cropper && !registerDataLoading">
                            <div>
                                <b-col class="text-naranja" style="font-size:14px">
                                    <span>(*) Sitúa la firma dentro del rectángulo.</span>
                                </b-col>
                            </div>

                            <div class="cropper--content">
                                <img id="cropper--content--image">
                            </div>
                        </b-col>

                        <b-col cols="12" class="mt-5 text-center" v-show="show_fingerprint_cropper && !registerDataLoading">
                            <b-button variant="primary" @click="saveFingerprint">GUARDAR</b-button>
                            <b-button variant="magenta" @click="clearFingerprintCropper">CANCELAR</b-button>
                        </b-col>

                        <b-col v-show="registerDataLoading">
                            <div style="height:80px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>
                    </b-row>
            </template>
        </b-modal>

        <!-- MODAL DNI ANVERSO-->
        <b-modal
            id="modal-dni-anverso"
            centered hide-footer size="lg" header-class="pb-0" no-close-on-backdrop no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">DNI: anverso</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalDniAnversoClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                    <b-row style="overflow-x:auto">
                        <b-col cols="12">
                            <b-col class="text-naranja mb-2 text-center" style="font-size:14px" v-show="!show_dni_anverso_cropper">
                                <span>Click en el rectángulo para cargar la imagen.</span>
                            </b-col>

                            <div class="participant--dni_anverso--container" v-show="!show_dni_anverso_cropper">
                                <img :src="dni_anverso_url" id="participant--dni_anverso--image">
                                <input type="file" name="dni_anverso--input--file" id="dni_anverso--input--file" accept=".jpeg,.jpg,.png" @change="dniAnversoFileSelected">
                                <label for="dni_anverso--input--file" class="dni_anverso--input--label">
                                    <div class="dni_anverso--edit-button" variant="contract-1" size="lg" ><b-icon icon="pencil" class="icon"/></div>
                                </label>
                            </div>
                        </b-col>

                        <b-col cols="12" class="p-0" v-show="show_dni_anverso_cropper && !registerDataLoading">
                            <div>
                                <b-col class="text-naranja" style="font-size:14px">
                                    <span>(*) Sitúa el dni dentro del rectángulo.</span>
                                </b-col>
                            </div>

                            <div class="cropper--content">
                                <img id="cropper--dniAnverso--content--image">
                            </div>
                        </b-col>

                        <b-col cols="12" class="mt-5 text-center" v-show="show_dni_anverso_cropper && !registerDataLoading">
                            <b-button variant="primary" @click="saveDniAnverso">GUARDAR</b-button>
                            <b-button variant="magenta" @click="clearDniAnversoCropper">CANCELAR</b-button>
                        </b-col>

                        <b-col v-show="registerDataLoading">
                            <div style="height:80px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>
                    </b-row>
            </template>
        </b-modal>

        <!-- MODAL DNI REVERSO-->
        <b-modal
            id="modal-dni-reverso"
            centered hide-footer size="lg" header-class="pb-0" no-close-on-backdrop no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">DNI: reverso</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalDniReversoClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                    <b-row style="overflow-x:auto">
                        <b-col cols="12">
                            <b-col class="text-naranja mb-2 text-center" style="font-size:14px" v-show="!show_dni_reverso_cropper">
                                <span>Click en el rectángulo para cargar la imagen. Utiliza el zoom pa</span>
                            </b-col>

                            <div class="participant--dni_reverso--container" v-show="!show_dni_reverso_cropper">
                                <img :src="dni_reverso_url" id="participant--dni_reverso--image">
                                <input type="file" name="dni_reverso--input--file" id="dni_reverso--input--file" accept=".jpeg,.jpg,.png" @change="dniReversoFileSelected">
                                <label for="dni_reverso--input--file" class="dni_reverso--input--label">
                                    <div class="dni_reverso--edit-button" variant="contract-1" size="lg" ><b-icon icon="pencil" class="icon"/></div>
                                </label>
                            </div>
                        </b-col>

                        <b-col cols="12" class="p-0" v-show="show_dni_reverso_cropper && !registerDataLoading">
                            <div>
                                <b-col class="text-naranja" style="font-size:14px">
                                    <span>(*) Sitúa el dni dentro del rectángulo.</span>
                                </b-col>
                            </div>

                            <div class="cropper--content">
                                <img id="cropper--dniReverso--content--image">
                            </div>
                        </b-col>

                        <b-col cols="12" class="mt-5 text-center" v-show="show_dni_reverso_cropper && !registerDataLoading">
                            <b-button variant="primary" @click="saveDniReverso">GUARDAR</b-button>
                            <b-button variant="magenta" @click="clearDniReversoCropper">CANCELAR</b-button>
                        </b-col>

                        <b-col v-show="registerDataLoading">
                            <div style="height:80px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>
                    </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { generateContract } from '@/api/Contract/Generate'
import { storeSignature, storeFingerprint, storeDniAnverso, storeDniReverso } from '@/api/Contract/Signature'
import { getHtmlContract, updateHtmlContract, updateSignature, updateFingerprint, updateDniAnverso, updateDniReverso, sentToVerifyContract } from '@/api/HtmlContract/HtmlContract'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import SignaturePad from 'signature_pad'
import Cropper from 'cropperjs'
import swal from 'sweetalert'

export default {
    name: 'ContractPreviewComponent',

    components: { VuePdfEmbed },
    
    props: {
        preview: {
            type: Number
        },
        html: {
            type: Object
        },
        ui: {
            type: String
        }
    },

    watch: {
        preview() {
            this.modalContractPreviewClick()
        }
    },

    computed: {
        isDataFill() {
            let rtn = 'light'
            if(this.fullname && this.dni && this.address && this.fecha && this.day && this.month) rtn = 'green'
            return rtn
        },
        isSignatureFill() {
            let rtn = 'light'
            if(this.signature_url) rtn = 'green'
            return rtn
        },
        isFingerprintFill() {
            let rtn = 'light'
            if(this.fingerprint_url && this.fingerprint_url != 'https://intranet-api.aupairkids.com.pe/storage/image/fingerprint/apk-fingerprint.jpg') rtn = 'green'
            return rtn
        },
        isDniAnversoFill() {
            let rtn = 'light'
            if(this.dni_anverso_url && this.dni_anverso_url != 'https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-anverso.jpg') rtn = 'green'
            return rtn
        },
        isDniReversoFill() {
            let rtn = 'light'
            if(this.dni_reverso_url && this.dni_reverso_url != 'https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-reverso.jpg') rtn = 'green'
            return rtn
        },

        //
        isReadyToVerify () {
            let rtn = false
            if (this.isDataFill == 'green' && 
            this.isSignatureFill == 'green' && 
            this.isFingerprintFill == 'green' && 
            this.isDniAnversoFill == 'green' && 
            this.isDniReversoFill == 'green') {
                rtn = true
            }
            return rtn
        }
    },

    //
    data: () => ({
        footerContainer: '',
        bodyContainer: '',
        //
        url_blob: '',
        src: '',
        html_pdf_generated: null,
        //
        htmlContractData: {},
        // data
        fullname: '',
        dni: '',
        address: '',
        fecha: null,
        signature_url: '',
        fingerprint_url: '',
        dni_anverso_url: '',
        dni_reverso_url: '',
        day: '',
        month: '',
        loading: false,
        // form
        input_fullname: '',
        input_dni: '',
        input_address: '',
        input_fecha: null,
        //
        inputsDisable: false,
        // input_signature: null,
        //
        canvas: null,
        signature: null,
        //
        fingerprint_cropper: null,
        show_fingerprint_cropper: false,
        //
        dni_anverso_cropper: null,
        show_dni_anverso_cropper: false,
        //
        dni_reverso_cropper: null,
        show_dni_reverso_cropper: false,
        //
        registerDataLoading: false,
        inputErrors: {},
        initialInputValues: true,
        //
        verifyLoading: false,
    }),

    methods: {
        setHtmlContractData(pData) {
            this.htmlContractData = pData.data.attributes
            this.fullname = this.htmlContractData.fullname
            this.dni = this.htmlContractData.dni
            this.address = this.htmlContractData.address
            if (this.htmlContractData.date) {
                this.fecha = this.htmlContractData.date
                this.day = this.fecha.split('-')[2]
                this.month = this.parseMonth(this.fecha.split('-')[1])
            } else {
                this.fecha = null
                this.day = ''
                this.month = ''
            }
            this.signature_url = this.htmlContractData.signature
            this.fingerprint_url = this.htmlContractData.fingerprint ? this.htmlContractData.fingerprint : 'https://intranet-api.aupairkids.com.pe/storage/image/fingerprint/apk-fingerprint.jpg'
            this.dni_anverso_url = this.htmlContractData.dni_anverso ? this.htmlContractData.dni_anverso : 'https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-anverso.jpg'
            this.dni_reverso_url = this.htmlContractData.dni_reverso ? this.htmlContractData.dni_reverso : 'https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-reverso.jpg'
        },

        getData() {
            getHtmlContract(this.html.id) // htmlContractId
                .then(({ data }) => {
                    this.setHtmlContractData(data)
                    this.$bvModal.show('modal-contract-preview')
                    this.getPdfGEnerated()
                })
        },
        modalContractPreviewClick () {
            if (this.ui == 'postulant') {
                this.getData()
            } else {
                this.$bvModal.show('modal-contract-preview')
                this.getPdfGEnerated()
            }
        },

        modalContractPreviewClose () {
            this.$bvModal.hide('modal-contract-preview')
            this.clearInputsAndStates()
        },
        getPdfGEnerated() {
            this.src = ''
            this.loading = true

            this.footerContainer = document.createElement('div')
            this.footerContainer.innerHTML = this.html.footer
            this.fillData(this.footerContainer)

            this.bodyContainer = document.createElement('div')
            this.bodyContainer.innerHTML = this.html.body
            this.fillData(this.bodyContainer)

            let HtmlForm = new FormData()

            HtmlForm.append('footer', this.footerContainer.innerHTML)
            HtmlForm.append('body', this.bodyContainer.innerHTML)

            // Mostrar el pdf generado, creando un objeto Url a partir del Blob de la respuesta.
            generateContract(HtmlForm)
                .then(res => {
                    this.html_pdf_generated = res.data
                    this.url_blob = window.URL.createObjectURL(new Blob([res.data]))
                    this.src = this.url_blob
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fillData(pContainer) {
            pContainer.querySelectorAll("[data-id='fullname']").forEach(el => {
                if (this.fullname) {
                    el.textContent = this.fullname
                    el.classList.replace('mentiones', 'mentiones-fill')
                }
            })
            pContainer.querySelectorAll("[data-id='dni']").forEach(el => {
                if (this.dni) {
                    el.textContent = this.dni
                    el.classList.replace('mentiones', 'mentiones-fill')
                }
            })
            pContainer.querySelectorAll("[data-id='address']").forEach(el => {
                if (this.address) {
                    el.textContent = this.address
                    el.classList.replace('mentiones', 'mentiones-fill')
                }
            })
            pContainer.querySelectorAll("[data-id='day']").forEach(el => {
                if (this.day) {
                    el.textContent = this.day
                    el.classList.replace('mentiones', 'mentiones-fill')
                }
            })
            pContainer.querySelectorAll("[data-id='month']").forEach(el => {
                if (this.month) {
                    el.textContent = this.month
                    el.classList.replace('mentiones', 'mentiones-fill')
                }
            })
            pContainer.querySelectorAll(".apk-signature").forEach(el => {
                if (this.signature_url) {
                    el.src = this.signature_url
                }
            })
            pContainer.querySelectorAll(".apk-fingerprint").forEach(el => {
                if (this.fingerprint_url) {
                    el.src = this.fingerprint_url
                }
            })
            pContainer.querySelectorAll(".apk-dniAnverso").forEach(el => {
                if (this.dni_anverso_url) {
                    el.src = this.dni_anverso_url
                }
            })
            pContainer.querySelectorAll(".apk-dniReverso").forEach(el => {
                if (this.dni_reverso_url) {
                    el.src = this.dni_reverso_url
                }
            })
        },

        // GENERAL FILL
        modalGeneralFillClick () {
            this.$bvModal.show('modal-general-fill')
        },
        modalGeneralFillClose () {
            this.$bvModal.hide('modal-general-fill')
            //
            if (this.ui == 'postulant') this.getData()
            else this.getPdfGEnerated()
        },

        // FILL - POSTULANT - DATA
        modalContractFillClick() {
            this.input_fullname = this.fullname
            this.input_dni = this.dni
            this.input_address = this.address
            this.input_fecha = this.fecha

            if (this.ui == 'postulant') {
                this.input_fullname = this.parseToCapitalize(this.htmlContractData.postulant_user_full_name)
                this.input_dni = this.htmlContractData.postulant_user_dni
                this.inputsDisable = true
            }

            this.$bvModal.show('modal-contract-fill')
        },
        modalContractFillClose() {
            this.$bvModal.hide('modal-contract-fill')
            this.clearInputsAndStates()
        },
        registerPostulantData() {
            this.registerDataLoading = true

            if (this.ui == 'test') {
                setTimeout(() => {
                    // this.fullname = this.input_fullname.split(' ').map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(' ')
                    this.fullname = this.parseToCapitalize(this.input_fullname)
                    this.dni = this.input_dni
                    this.address = this.input_address
                    if (this.input_fecha) {
                        this.fecha = this.input_fecha
                        this.day = this.fecha.split('-')[2]
                        this.month = this.parseMonth(this.fecha.split('-')[1])
                    }

                    this.registerDataLoading = false
                    this.modalContractFillClose()
                    // this.getPdfGEnerated()  // to delete
                }, 500);

            }

            if (this.ui == 'postulant') {
                this.initialInputValues = false
                let ContractDataForm = new FormData()
                ContractDataForm.append('.method', 'put')
                ContractDataForm.append('fullname', this.parseToCapitalize(this.input_fullname))
                ContractDataForm.append('dni', this.input_dni)
                ContractDataForm.append('address', this.input_address)
                ContractDataForm.append('date', this.input_fecha)

                updateHtmlContract(ContractDataForm, this.html.id)
                    .then(({ data }) => {
                        this.setHtmlContractData(data)
                        this.msgSuccessModal(this.modalContractFillClose)
                    })
                    .catch(err => {
                        if (err.response.status == 422) {
                            this.inputErrors = err.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.registerDataLoading = false
                    })
            }
        },

        // FILL - POSTULANT - SIGNATURE - PAD SIGNATURE
        // canvas
        initCanvas() {
            this.canvas = document.querySelector('#signature-canvas')
        },
        resizeCanvas() {
            let canvasParent = document.querySelector('.signature--pad--body')
            this.canvas.setAttribute('width', canvasParent.clientWidth)
            this.canvas.setAttribute('height', (canvasParent.clientWidth * 13) / 23 )
            this.signature = new SignaturePad(this.canvas, { backgroundColor: 'rgb(255, 255, 255)', maxWidth: 2 })
        },
        modalSignatureClick() {
            this.$bvModal.show('modal-signature')
            setTimeout(() => {
                this.initCanvas()
                window.onresize = this.resizeCanvas
                this.resizeCanvas()
            }, 350)

        },
        modalSignatureClose() {
            this.$bvModal.hide('modal-signature')
            window.onresize = null
        },
        registerPostulantSignature() {
            if (this.signature.isEmpty()) {
                alert('Please provide a signature first.')
            } else {
                this.registerDataLoading = true
                let signature_dataURL = this.signature.toDataURL('image/jpeg')
                let signature_blob = this.dataURLToBlob(signature_dataURL)

                let SignatureForm = new FormData()
                SignatureForm.append('signature_image', signature_blob)
                //
                if (this.signature_url) SignatureForm.append('old_signature', this.signature_url.split('https://intranet-api.aupairkids.com.pe/storage/')[1])
                //
                if (this.ui == 'test') SignatureForm.append('save_signature', 'no')
                if (this.ui == 'postulant' && this.$store.state.api.env == 'dev') SignatureForm.append('save_signature', 'si-env-dev')
                if (this.ui == 'postulant' && this.$store.state.api.env == 'prod') SignatureForm.append('save_signature', 'si')

                storeSignature(SignatureForm)
                    .then(res => {
                        this.signature_url = res.data.signature_url
                        //
                        if (this.ui == 'postulant') {
                            let UpdateForm = new FormData()
                            UpdateForm.append('.method', 'put')
                            UpdateForm.append('signature', res.data.signature_url)
                            updateSignature(UpdateForm, this.html.id) // htmlContractId
                                .then(({ data }) => {
                                    this.setHtmlContractData(data)
                                })
                        }
                        this.msgSuccessModal(this.modalSignatureClose)
                        // this.getPdfGEnerated() // to delete
                    })
                    .finally(() => {
                        this.registerDataLoading = false
                    })
            }

        },
        dataURLToBlob(pDataURL) {
            let parts = pDataURL.split(';base64,')
            let contentType = parts[0].split(':')[1]
            let raw = window.atob(parts[1])
            let rawLength = raw.length
            let uInt8Array = new Uint8Array(rawLength)

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i)
            }
            return new Blob([uInt8Array], { type: contentType })
        },
        clear() {
            this.signature.clear()
        },
        undo() {
            var data = this.signature.toData();
            if (data) {
                data.pop(); // remove the last dot or line
                this.signature.fromData(data);
            }
        },
        // end canvas

        // fingerprint methods
        modalFingerprintClick() {
            this.$bvModal.show('modal-fingerprint')
        },
        modalFingerprintClose() {
            this.$bvModal.hide('modal-fingerprint')
            this.clearFingerprintCropper()
        },

        fingerprintFileSelected(e) {
            let fingerprint_input_file = e.target.files[0]
            let cropper_content_image = document.querySelector('#cropper--content--image')

            this.clearFingerprintCropper()

            if(fingerprint_input_file) {
                cropper_content_image.src = window.URL.createObjectURL(fingerprint_input_file)

                this.fingerprint_cropper = new Cropper(cropper_content_image, {
                    dragMode: 'move',
                    viewMode: 1,
                    aspectRatio: 17 / 20,
                    autoCropArea: 0.65,
                    restore: false,
                    guides: true,
                    center: true,
                    highlight: true,
                    cropBoxMovable: false,
                    cropBoxResizable: true,
                    toggleDragModeOnDblclick: false,
                })

                this.show_fingerprint_cropper = true
           } else {
                cropper_content_image.src = ''
           }
        },

        saveFingerprint() {
            this.registerDataLoading = true
            let fingerprint_dataURL = this.fingerprint_cropper.getCroppedCanvas({ fillColor: '#ffffff', width: 170, height: 200,}).toDataURL('image/jpeg')
            let fingerprint_blob = this.dataURLToBlob(fingerprint_dataURL)

            // to show into img element
            // this.fingerprint_url = window.URL.createObjectURL(fingerprint_blob)

            // to save in DB
            let FingerprintForm = new FormData()
            FingerprintForm.append('fingerprint_image', fingerprint_blob)
            //
            if (this.fingerprint_url && this.fingerprint_url !== 'https://intranet-api.aupairkids.com.pe/storage/image/fingerprint/apk-fingerprint.jpg') {
                FingerprintForm.append('old_fingerprint', this.fingerprint_url.split('https://intranet-api.aupairkids.com.pe/storage/')[1])
            }
            //
            if (this.ui == 'test') FingerprintForm.append('save_fingerprint', 'no')             // to temp
            if (this.ui == 'postulant' && this.$store.state.api.env == 'dev') FingerprintForm.append('save_fingerprint', 'si-env-dev')  // save to dev env directory
            if (this.ui == 'postulant' && this.$store.state.api.env == 'prod') FingerprintForm.append('save_fingerprint', 'si')         // save to fingerprint directory

            storeFingerprint(FingerprintForm)
                .then(res => {
                    this.fingerprint_url = res.data.fingerprint_url
                    //
                    if (this.ui == 'postulant') {
                        let UpdateForm = new FormData()
                        UpdateForm.append('.method', 'put')
                        UpdateForm.append('fingerprint', res.data.fingerprint_url)
                        updateFingerprint(UpdateForm, this.html.id) // htmlContractId
                            .then(({ data }) => {
                                this.setHtmlContractData(data)
                            })
                    }
                    this.msgSuccessModal(this.modalFingerprintClose)
                    // this.getPdfGEnerated() // to delete
                })
                .finally(() => {
                    this.registerDataLoading = false
                })
        },

        clearFingerprintCropper() {
            document.querySelector('#fingerprint--input--file').value  = ''
            this.show_fingerprint_cropper = false
            if(this.fingerprint_cropper) this.fingerprint_cropper.destroy()
        },
        // end fingerprint methods

        // DNI ANVERSO METHODS
        modalDniAnversoClick() {
            this.$bvModal.show('modal-dni-anverso')
        },
        modalDniAnversoClose() {
            this.$bvModal.hide('modal-dni-anverso')
            this.clearDniAnversoCropper()
        },

        dniAnversoFileSelected(e) {
            let dniAnverso_input_file = e.target.files[0]
            let cropper_dniAnverso_content_image = document.querySelector('#cropper--dniAnverso--content--image')

            this.clearDniAnversoCropper()

            if(dniAnverso_input_file) {
                cropper_dniAnverso_content_image.src = window.URL.createObjectURL(dniAnverso_input_file)

                this.dni_anverso_cropper = new Cropper(cropper_dniAnverso_content_image, {
                    dragMode: 'move',
                    viewMode: 1,
                    aspectRatio: 17 / 11,
                    autoCropArea: 0.65,
                    restore: false,
                    guides: true,
                    center: true,
                    highlight: true,
                    cropBoxMovable: false,
                    cropBoxResizable: true,
                    toggleDragModeOnDblclick: false,
                })

                this.show_dni_anverso_cropper = true
           } else {
                cropper_dniAnverso_content_image.src = ''
           }
        },

        saveDniAnverso() {
            this.registerDataLoading = true
            let dniAnverso_dataURL = this.dni_anverso_cropper.getCroppedCanvas({ fillColor: '#ffffff', width: 465, height: 300,}).toDataURL('image/jpeg')
            let dniAnverso_blob = this.dataURLToBlob(dniAnverso_dataURL)

            // to show into img element
            // this.dni_anverso_url = window.URL.createObjectURL(dniAnverso_blob)

            // to save in DB
            let DniAnversoForm = new FormData()
            DniAnversoForm.append('dni_anverso_image', dniAnverso_blob)
            //
            if (this.dni_anverso_url && this.dni_anverso_url !== 'https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-anverso.jpg') {
                DniAnversoForm.append('old_dni_anverso', this.dni_anverso_url.split('https://intranet-api.aupairkids.com.pe/storage/')[1])
            }
            //
            if (this.ui == 'test') DniAnversoForm.append('save_dni_anverso', 'no')             // to temp
            if (this.ui == 'postulant' && this.$store.state.api.env == 'dev') DniAnversoForm.append('save_dni_anverso', 'si-env-dev')  // save to dev env directory
            if (this.ui == 'postulant' && this.$store.state.api.env == 'prod') DniAnversoForm.append('save_dni_anverso', 'si')         // save to dni directory

            storeDniAnverso(DniAnversoForm)
                .then(res => {
                    this.dni_anverso_url = res.data.dni_anverso_url
                    //
                    if (this.ui == 'postulant') {
                        let UpdateForm = new FormData()
                        UpdateForm.append('.method', 'put')
                        UpdateForm.append('dni_anverso', res.data.dni_anverso_url)
                        updateDniAnverso(UpdateForm, this.html.id) // htmlContractId
                            .then(({ data }) => {
                                this.setHtmlContractData(data)
                            })
                    }
                    this.msgSuccessModal(this.modalDniAnversoClose)
                    // this.getPdfGEnerated() // to delete
                })
                .finally(() => {
                    this.registerDataLoading = false
                })
        },

        clearDniAnversoCropper() {
            document.querySelector('#dni_anverso--input--file').value  = ''
            this.show_dni_anverso_cropper = false
            if(this.dni_anverso_cropper) this.dni_anverso_cropper.destroy()
        },
        // end DNI ANVERSO METHODS

        // DNI REVERSO METHODS
        modalDniReversoClick() {
            this.$bvModal.show('modal-dni-reverso')
        },
        modalDniReversoClose() {
            this.$bvModal.hide('modal-dni-reverso')
            this.clearDniReversoCropper()
        },

        dniReversoFileSelected(e) {
            let dniReverso_input_file = e.target.files[0]
            let cropper_dniReverso_content_image = document.querySelector('#cropper--dniReverso--content--image')

            this.clearDniReversoCropper()

            if(dniReverso_input_file) {
                cropper_dniReverso_content_image.src = window.URL.createObjectURL(dniReverso_input_file)

                this.dni_reverso_cropper = new Cropper(cropper_dniReverso_content_image, {
                    dragMode: 'move',
                    viewMode: 1,
                    aspectRatio: 17 / 11,
                    autoCropArea: 0.65,
                    restore: false,
                    guides: true,
                    center: true,
                    highlight: true,
                    cropBoxMovable: false,
                    cropBoxResizable: true,
                    toggleDragModeOnDblclick: false,
                })

                this.show_dni_reverso_cropper = true
           } else {
                cropper_dniReverso_content_image.src = ''
           }
        },

        saveDniReverso() {
            this.registerDataLoading = true
            let dniReverso_dataURL = this.dni_reverso_cropper.getCroppedCanvas({ fillColor: '#ffffff', width: 465, height: 300,}).toDataURL('image/jpeg')
            let dniReverso_blob = this.dataURLToBlob(dniReverso_dataURL)

            // to show into img element
            // this.dni_reverso_url = window.URL.createObjectURL(dniReverso_blob)

            // to save in DB
            let DniReversoForm = new FormData()
            DniReversoForm.append('dni_reverso_image', dniReverso_blob)
            //
            if (this.dni_reverso_url && this.dni_reverso_url !== 'https://intranet-api.aupairkids.com.pe/storage/image/dni/apk-reverso.jpg') {
                DniReversoForm.append('old_dni_reverso', this.dni_reverso_url.split('https://intranet-api.aupairkids.com.pe/storage/')[1])
            }
            //
            if (this.ui == 'test') DniReversoForm.append('save_dni_reverso', 'no')             // to temp
            if (this.ui == 'postulant' && this.$store.state.api.env == 'dev') DniReversoForm.append('save_dni_reverso', 'si-env-dev')  // save to dev env directory
            if (this.ui == 'postulant' && this.$store.state.api.env == 'prod') DniReversoForm.append('save_dni_reverso', 'si')         // save to dni directory

            storeDniReverso(DniReversoForm)
                .then(res => {
                    this.dni_reverso_url = res.data.dni_reverso_url
                    //
                    if (this.ui == 'postulant') {
                        let UpdateForm = new FormData()
                        UpdateForm.append('.method', 'put')
                        UpdateForm.append('dni_reverso', res.data.dni_reverso_url)
                        updateDniReverso(UpdateForm, this.html.id) // htmlContractId
                            .then(({ data }) => {
                                this.setHtmlContractData(data)
                            })
                    }
                    this.msgSuccessModal(this.modalDniReversoClose)
                    // this.getPdfGEnerated() // to delete
                })
                .finally(() => {
                    this.registerDataLoading = false
                })
        },

        clearDniReversoCropper() {
            document.querySelector('#dni_reverso--input--file').value  = ''
            this.show_dni_reverso_cropper = false
            if(this.dni_reverso_cropper) this.dni_reverso_cropper.destroy()
        },
        // end DNI REVERSO METHODS 

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },
        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },
        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
            //
            this.input_fullname = ''
            this.input_dni = ''
            this.input_address = ''
            this.input_fecha = null
        },

        parseMonth(pNumericMonth) {
            switch(pNumericMonth) {
                case '01': {
                    return 'enero'
                }
                case '02': {
                    return 'febrero'
                }
                case '03': {
                    return 'marzo'
                }
                case '04': {
                    return 'abril'
                }
                case '05': {
                    return 'mayo'
                }
                case '06': {
                    return 'junio'
                }
                case '07': {
                    return 'julio'
                }
                case '08': {
                    return 'agosto'
                }
                case '09': {
                    return 'septiembre'
                }
                case '10': {
                    return 'octubre'
                }
                case '11': {
                    return 'noviembre'
                }
                case '12': {
                    return 'diciembre'
                }
            }
        },
        parseToCapitalize(pString) {
            return pString.split(' ').map(el => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase()).join(' ')
        },

        downloadToSing() {
            this.signature_url = 'https://intranet-api.aupairkids.com.pe/storage/image/sign/empty-sign.jpg'

            // this.loading = true

            this.footerContainer = document.createElement('div')
            this.footerContainer.innerHTML = this.html.footer
            this.fillData(this.footerContainer)

            this.bodyContainer = document.createElement('div')
            this.bodyContainer.innerHTML = this.html.body
            this.fillData(this.bodyContainer)

            let HtmlForm = new FormData()

            HtmlForm.append('footer', this.footerContainer.innerHTML)
            HtmlForm.append('body', this.bodyContainer.innerHTML)

            generateContract(HtmlForm)
                .then(res => {
                    this.url_blob = window.URL.createObjectURL(new Blob([res.data]))
                    this.downloadContractGenerated()
                    this.getData()
                })
                // .finally(() => {
                //     this.loading = false
                // })
            
        },

        // contract generated
        downloadContractGenerated() {
            const link = document.createElement('a');
            link.href = this.url_blob;
            link.setAttribute('download', 'contrato.pdf');
            document.body.appendChild(link);
            link.click();
        },

        // sent to validation
        enviarPdfGenerado () {
            this.verifyLoading = true
            let SendForm = new FormData()
            SendForm.append('html_pdf_generated', this.html_pdf_generated)
            sentToVerifyContract(SendForm, this.html.id)
                .then(() => {
                    this.msgSuccessModal(()=>{})
                })
                .finally(() => {
                    this.verifyLoading = false
                })
        },

        // msgSuccessModal -
        msgSuccessModal(pFn) {
            swal('Success', ' ', 'success')
                .then(value => {
                    switch (value) {
                        case true:
                        case null:
                            pFn()
                            break;
                        default:
                            console.log('mgs-scss-modal');
                            break;
                    }
                })
        }
        // end msgSuccessModal -
    },

    destroyed() {
        window.URL.revokeObjectURL(this.url_blob);
    },
}
</script>

<style >
.view-wraper {
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 5px;
    background-color: #ccc;
    height: 72vh;
    overflow: auto;
}
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
/* embed {
    width: 100%;
    height: 75vh;
} */
.signature--pad--body {
    margin: 0 auto;
    border: 1px dashed #1572A1;
    border-radius: 5px;
}
@media (min-width: 320px) {
    .signature-pad--boddy {
        width: 90%;
    }
}
@media (min-width: 800px) {
    .signature-pad--boddy {
        width: 690px;
        /* height: 390px; */
    }
}
.participant--fingerprint--container {
    position: relative;
    width: 170px;
    height: 200px;
    margin: 0 auto;
    border: 2px dashed #FFAD00;
    border-radius: 5px;
    overflow: hidden;
}

.participant--dni_anverso--container,
.participant--dni_reverso--container {
    position: relative;
    width: 465px;
    height: 300px;
    margin: 0 auto;
    border: 2px dashed #FFAD00;
    border-radius: 5px;
    overflow: hidden;
}

.participant--fingerprint--container #participant--fingerprint--image,
.participant--dni_reverso--container #participant--dni_reverso--image,
.participant--dni_anverso--container #participant--dni_anverso--image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    transform: translate(-50%,-50%);
    transition: 300ms ease-in-out;
}

.participant--fingerprint--container #fingerprint--input--file,
.participant--dni_reverso--container #dni_reverso--input--file,
.participant--dni_anverso--container #dni_anverso--input--file {
    display: none;
}
.participant--fingerprint--container .fingerprint--input--label,
.participant--dni_reverso--container .dni_reverso--input--label,
.participant--dni_anverso--container .dni_anverso--input--label {
    position: relative;
    width: 100%;
    height: 100%;
    transition: 300ms ease-in-out;
    background-color: rgba(0, 0, 0, 0.0);
}
.participant--fingerprint--container .fingerprint--input--label:hover,
.participant--dni_reverso--container .dni_reverso--input--label:hover,
    .participant--dni_anverso--container .dni_anverso--input--label:hover {
    background-color: rgba(0, 0, 0, 0.35);
}

.participant--fingerprint--container .fingerprint--input--label .fingerprint--edit-button,
.participant--dni_anverso--container .dni_anverso--input--label .dni_anverso--edit-button,
.participant--dni_reverso--container .dni_reverso--input--label .dni_reverso--edit-button {
    position: relative;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    background-color: #FFAD00;
    transform: translate(-50%, -50%) scale(0);
}
.fingerprint--edit-button .icon,
.dni_anverso--edit-button .icon,
.dni_reverso--edit-button .icon {
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%) scale(0.6);
    color: #fff;
}
.participant--fingerprint--container .fingerprint--input--label:hover .fingerprint--edit-button,
.participant--dni_anverso--container .dni_anverso--input--label:hover .dni_anverso--edit-button,
.participant--dni_reverso--container .dni_reverso--input--label:hover .dni_reverso--edit-button {
    transform: translate(-50%, -50%) scale(1);
}

/* cropper */

.cropper--content {
    overflow: hidden;
    border-radius: 8px;
}

/* Ensure the size of the image fit the container perfectly */
#cropper--content--image,
#cropper--dniAnverso--content--image,
#cropper--dniReverso--content--image {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
  margin: 0 auto;
}

</style>