import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function fillInformation (pFormdata, pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/fill-information/${pIdProgramSeasonParticipant}`
    return post(URI, pFormdata, { headers })
}
export { fillInformation }