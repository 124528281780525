import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// delete
function destroyCapacitationScheduleParticipant (pIdCapacitationScheduleParticipant) {
    const URI = `${BASE_URL}/api/capacitationScheduleParticipants/${pIdCapacitationScheduleParticipant}`
    return axios.delete(URI, { headers })
}

export { 
    destroyCapacitationScheduleParticipant
 }