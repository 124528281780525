import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//get
function getEmployeeEmployeePermissions (pEmployeeId) {
    const URI = `${BASE_URL}/api/employees/${pEmployeeId}/employeePermissions`
    return get(URI)
}

//post-put
function updateEmployeeEmployeePermissionStatus (pEmployeeId, pEmployeePermissionId, pFormData) {
    const URI = `${BASE_URL}/api/employees/${pEmployeeId}/employeePermissions/${pEmployeePermissionId}`
    return post(URI, pFormData)
}

export {
    getEmployeeEmployeePermissions,
    updateEmployeeEmployeePermissionStatus
}