<template>
    <b-row align-h="center">
        <b-col cols="12" lg="9">
            <!-- FORM - PERSONAL-INFORMATION -->
            <b-form @submit.prevent="fillFicha">
                <b-col cols="12" class="mb-5">
                    <b-card
                        class="shadow"
                        img-src="/img/theme/wat.png"
                        overlay
                    >
                        <b-card-text class="d-flex">
                            <b-col class="text-left" cols="12">
                                <b-button
                                    variant="schedule-talk-1" size="sm"
                                    @click="modalFichaStatusClick"
                                    :disabled="fillDataloading"
                                    v-show="ui != 'participant'"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                    </svg>
                                </b-button>

                                <b-button variant="primary" size="sm" @click="reloadData">
                                    <b-icon icon="arrow-repeat"></b-icon>
                                </b-button>
                            </b-col>

                            <!-- <div style="position:absolute; bottom:11%;left:50%;transform:translateX(-50%);
                            height:80px;width:70%; display:block;background:white;border-radius:8px; color:red;text-align:center;font-size:40px;line-height:80px
                            font-weight:bold;"
                                class="text-uppercase" :class="`text-${program_color}`"
                            >
                                {{  program_name }}
                            </div> -->
                        </b-card-text>
                    </b-card>
                </b-col>

                <b-col cols="12" class="mb-5 text-white">
                    (*) Campos obligatorios
                </b-col>

                <!-- informacion personal -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- nombre -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Nombres *"
                                    description="Tal y como está en tu DNI. Respetar mayúsculas y minúsculas Ejm: Angelica"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese sus nombres."
                                        type="text"
                                        v-model="name"
                                        :state="showInputStatus('name')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('name')"
                                        :key="`${i}-ficha-name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- document_number -->
                            <b-col cols="12" xl="6">
                                <b-form-group
                                    label="D.N.I. *"
                                    description="Documento Nacional de Identificación."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="number"
                                        step="1"
                                        min="0"
                                        v-model="document_number"
                                        :state="showInputStatus('document_number')"
                                        v-show="!fillDataloading"
                                        disabled
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('document_number')"
                                        :key="`${i}-ficha-document_number`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- apellido paterno -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Apellido Paterno *" 
                                    description="Tal y como está en tu DNI. Respetar mayúsculas y minúsculas Ejm: Porras"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su apellido paterno."
                                        type="text"
                                        v-model="p_last_name"
                                        :state="showInputStatus('p_last_name')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('p_last_name')"
                                        :key="`${i}-ficha-p_last_name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- apellido paterno -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Apellido Materno *" 
                                    description="Tal y como está en tu DNI. Respetar mayúsculas y minúsculas Ejm: Torres"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su apellido materno."
                                        type="text"
                                        v-model="m_last_name"
                                        :state="showInputStatus('m_last_name')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('m_last_name')"
                                        :key="`${i}-ficha-m_last_name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- fecha de nacimiento -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de Nacimiento *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="date_birth" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{  day: 'numeric', month: 'numeric', year: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="date_birth"
                                                :state="showInputStatus('date_birth')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('date_birth')"
                                        v-for="(inputError, i) in showInputErrors('date_birth')"
                                        :key="`${i}-ficha-date_birth`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- edad -->
                            <b-col cols="12" xl="6">
                                <b-form-group
                                    label="Edad *" 
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su edad."
                                        type="number"
                                        step="1"
                                        min="0"
                                        v-model="age"
                                        :state="showInputStatus('age')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('age')"
                                        :key="`${i}-ficha-age`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- cuidad de nacimiento -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Ciudad de Nacimiento *" 
                                    description="Como figura en tu DNI."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su ciudad de nacimiento."
                                        type="text"
                                        v-model="city_birth"
                                        :state="showInputStatus('city_birth')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('city_birth')"
                                        :key="`${i}-ficha-city_birth`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- pais de nacimiento -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="País de Nacimiento *" 
                                    description="Como figura en tu DNI."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su país de nacimiento."
                                        type="text"
                                        v-model="country_birth"
                                        :state="showInputStatus('country_birth')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('country_birth')"
                                        :key="`${i}-ficha-country_birth`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- sexo -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Sexo *" 
                                    description="Como figura en tu DNI."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-radio-group
                                        :options="[{ value: 'male', text: 'Masculino'}, { value: 'female', text: 'Femenino'}]"
                                        v-model="sex"
                                        :state="showInputStatus('sex')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('sex')"
                                        :key="`${i}-ficha-sex`"
                                        :state="showInputStatus('sex')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- marital status -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Estado civil *" 
                                    description="Como figura en tu DNI."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-radio-group
                                        :options="[{ value: 'single', text: 'Soltero(a)'}, { value: 'married', text: 'Casado(a)'}, { value: 'separated', text: 'Separado(a)'}, { value: 'divorced', text: 'Divorciado(a)'}]"
                                        v-model="marital_status"
                                        :state="showInputStatus('marital_status')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('marital_status')"
                                        :key="`${i}-ficha-marital_status`"
                                        :state="showInputStatus('marital_status')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- other_nationality -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tiene o ha tenido alguna nacionalidad distinta a la indicada arriba sobre la nacionalidad?*" 
                                    description="Si no tiene escriba 'no'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos."
                                        type="text"
                                        v-model="other_nationality"
                                        :state="showInputStatus('other_nationality')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('other_nationality')"
                                        :key="`${i}-ficha-other_nationality`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- other_permanent_resident -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Es usted residente permanente de un país/región que no sea su país/región de origen (nacionalidad)?*" 
                                    description="Si no tiene escriba 'no'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos."
                                        type="text"
                                        v-model="other_permanent_resident"
                                        :state="showInputStatus('other_permanent_resident')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('other_permanent_resident')"
                                        :key="`${i}-ficha-other_permanent_resident`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- informacion ubicacion -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- domicilio -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Domicilio actual *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su dirección."
                                        type="text"
                                        v-model="address"
                                        :state="showInputStatus('address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('address')"
                                        :key="`${i}-ficha-address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- distrito  -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Distrito *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el distrito."
                                        type="text"
                                        v-model="distric"
                                        :state="showInputStatus('distric')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('distric')"
                                        :key="`${i}-ficha-distric`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- provincia -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Provincia *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la provincia."
                                        type="text"
                                        v-model="province"
                                        :state="showInputStatus('province')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('province')"
                                        :key="`${i}-ficha-province`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- departamento -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Departamento *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="department"
                                        :state="showInputStatus('department')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('department')"
                                        :key="`${i}-ficha-department`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- COD. POSTAL -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Código postal *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el cod. postal."
                                        type="text"
                                        v-model="zip_code"
                                        :state="showInputStatus('zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('zip_code')"
                                        :key="`${i}-ficha-zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- COUNTRY -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="País *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="country"
                                        :state="showInputStatus('country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('country')"
                                        :key="`${i}-ficha-country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- social media -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- sm_plataform_one -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Plataforma de red social (1)*"
                                    description="Ejemplo: Facebbok, Instagram, etc.. "
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre de la plataforma."
                                        type="text"
                                        v-model="sm_plataform_one"
                                        :state="showInputStatus('sm_plataform_one')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('sm_plataform_one')"
                                        :key="`${i}-ficha-sm_plataform_one`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- sm_identifier_one  -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Identificador de red social (1) *"
                                    description="Nombre con el cual apareces en la red social"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el identificador."
                                        type="text"
                                        v-model="sm_identifier_one"
                                        :state="showInputStatus('sm_identifier_one')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('sm_identifier_one')"
                                        :key="`${i}-ficha-sm_identifier_one`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- sm_plataform_two -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Plataforma de red social (2)*"
                                    description="Ejemplo: Facebbok, Instagram, etc.. "
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre de la plataforma."
                                        type="text"
                                        v-model="sm_plataform_two"
                                        :state="showInputStatus('sm_plataform_two')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('sm_plataform_two')"
                                        :key="`${i}-ficha-sm_plataform_two`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- sm_identifier_two  -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Identificador de red social (2) *"
                                    description="Nombre con el cual apareces en la red social"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el identificador."
                                        type="text"
                                        v-model="sm_identifier_two"
                                        :state="showInputStatus('sm_identifier_two')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('sm_identifier_two')"
                                        :key="`${i}-ficha-sm_identifier_two`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- informacion pasaporte -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- numero pasaporte -->
                            <b-col cols="12">
                                <b-form-group
                                    label="Nº Pasaporte *"
                                    description="En caso de no tener; escribir: Tengo cita en migraciones"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de pasaporte."
                                        type="text"
                                        v-model="passport_number"
                                        :state="showInputStatus('passport_number')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_number')"
                                        :key="`${i}-ficha-passport_number`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- emision pasaporte -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                               <b-form-group
                                    label="Fecha emisión de pasaporte * (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="passport_emit_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="passport_emit_date"
                                                :state="showInputStatus('passport_emit_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_emit_date')"
                                        :key="`${i}-ficha-passport_emit_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- expiracion pasaporte -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                               <b-form-group
                                    label="Fecha expiración de pasaporte * (AÑO-MES-DÍA)"
                                    description="Fecha máxima octubre del 2023."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="passport_expiration_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="passport_expiration_date"
                                                :state="showInputStatus('passport_expiration_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_expiration_date')"
                                        :key="`${i}-ficha-passport_expiration_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- passport_city_issued -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                                <b-form-group
                                    label="Ciudad donde se emitió el pasaporte *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la ciudad."
                                        type="text"
                                        v-model="passport_city_issued"
                                        :state="showInputStatus('passport_city_issued')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_city_issued')"
                                        :key="`${i}-ficha-passport_city_issued`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- passport_state_issued -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                                <b-form-group
                                    label="Departamento donde se emitió el pasaporte *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="passport_state_issued"
                                        :state="showInputStatus('passport_state_issued')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_state_issued')"
                                        :key="`${i}-ficha-passport_state_issued`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- passport_country_issued -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                                <b-form-group
                                    label="País donde se emitió el pasaporte *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="passport_country_issued"
                                        :state="showInputStatus('passport_country_issued')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_country_issued')"
                                        :key="`${i}-ficha-passport_country_issued`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- passport_lost -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                                <b-form-group
                                    label="¿Alguna vez has perdido el pasaporte o te lo han robado? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="passport_lost"
                                        :state="showInputStatus('passport_lost')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_lost')"
                                        :key="`${i}-ficha-passport_lost`"
                                        :state="showInputStatus('passport_lost')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- passport_image -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'">
                                <b-form-group
                                    label="Imagen del pasaporte *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-file
                                        @change="showImagePreview"
                                        style="overflow:hidden;"
                                        v-model="passport_image"
                                        :state="showInputStatus('passport_image')"
                                        v-show="!fillDataloading"
                                    ></b-form-file>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('passport_image')"
                                        :key="`${i}-ficha-passport_image`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>


                            <!-- preview image -->
                              <!-- passport_image -->
                            <b-col cols="12" lg="6" v-if="passport_number != 'Tengo cita en migraciones'" class="text-center border border-primary py-3" style="border-radius:8px">
                                <b-img
                                    :src="passport_image_preview"
                                    fluid
                                    class="voucher-preview"
                                    rounded=""
                                ></b-img>
                            </b-col>

                            <!-- immigration appointment date -->
                            <b-col cols="12" lg="6" v-if="passport_number == 'Tengo cita en migraciones'">
                               <b-form-group
                                    label="Fecha cita - migraciones * (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="immigration_appointment_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="immigration_appointment_date"
                                                :state="showInputStatus('immigration_appointment_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('immigration_appointment_date')"
                                        v-for="(inputError, i) in showInputErrors('immigration_appointment_date')"
                                        :key="`${i}-ficha-immigration_appointment_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- informacion contacto -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- tel fijo -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Teléfono Fijo *"
                                    description="Ejm: 064-222222"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de telefono fijo."
                                        type="text"
                                        v-model="land_line"
                                        :state="showInputStatus('land_line')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('land_line')"
                                        :key="`${i}-ficha-land_line`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- celular -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Celular *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese su n° de celular."
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="phone_number"
                                        :state="showInputStatus('phone_number')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('phone_number')"
                                        :key="`${i}-ficha-phone_number`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- correo -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Correo electrónico *"
                                    description="Colocar el correo de uso frecuente. Ejm: dianavm28@hotmail.com"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="email"
                                        v-model="email"
                                        :state="showInputStatus('email')"
                                        v-show="!fillDataloading"
                                        disabled
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('email')"
                                        :key="`${i}-ficha-email`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- perfil -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Perfil *"
                                    description="Colocar el link de la red social de uso frecuente. Ejm: https://www.facebook.com/lercc"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la url de su perfil."
                                        type="url"
                                        v-model="profile_link"
                                        :state="showInputStatus('profile_link')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('profile_link')"
                                        :key="`${i}-ficha-profile_link`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- skype -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Skype ID *"
                                    description="Importante para tus entrevista de trabajo. Cuenta de skype debe estar activo."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el skype ID."
                                        type="text"
                                        v-model="skype_id"
                                        :state="showInputStatus('skype_id')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('skype_id')"
                                        :key="`${i}-ficha-skype_id`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- informacion padre -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- Apellidos y Nombres -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Apellidos y Nombres (Del papá) *" 
                                    description="Datos importantes para el rellenado del Formulario DS-160"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="father_full_name"
                                        :state="showInputStatus('father_full_name')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('father_full_name')"
                                        :key="`${i}-ficha-father_full_name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- fecha de nacimiento -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de nacimiento (Del papá) *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="father_date_birth" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="father_date_birth"
                                                :state="showInputStatus('father_date_birth')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('father_date_birth')"
                                        v-for="(inputError, i) in showInputErrors('father_date_birth')"
                                        :key="`${i}-ficha-father_date_birth`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- celular -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Número Celular (Del papá) *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="father_cell_phone"
                                        :state="showInputStatus('father_cell_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('father_cell_phone')"
                                        :key="`${i}-ficha-father_cell_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- correo -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Correo electrónico (Del papá) *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el correo"
                                        type="email"
                                        v-model="father_email"
                                        :state="showInputStatus('father_email')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('father_email')"
                                        :key="`${i}-ficha-father_email`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- father_us -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Está tu padre en los Estados Unidos?  *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="father_us"
                                        :state="showInputStatus('father_us')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('father_us')"
                                        :key="`${i}-ficha-father_us`"
                                        :state="showInputStatus('father_us')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- informacion madre -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- Apellidos y Nombres -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Apellidos y Nombres (De la mamá) *" 
                                    description="Datos importantes para el rellenado del Formulario DS-160"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="mother_full_name"
                                        :state="showInputStatus('mother_full_name')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('mother_full_name')"
                                        :key="`${i}-ficha-mother_full_name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- fecha de nacimiento -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de nacimiento (De la mamá) *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="mother_date_birth" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="mother_date_birth"
                                                :state="showInputStatus('mother_date_birth')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('mother_date_birth')"
                                        v-for="(inputError, i) in showInputErrors('mother_date_birth')"
                                        :key="`${i}-ficha-mother_date_birth`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- celular -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Número Celular (De la mamá) *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="mother_cell_phone"
                                        :state="showInputStatus('mother_cell_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('mother_cell_phone')"
                                        :key="`${i}-ficha-mother_cell_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- correo -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Correo electrónico (De la mamá) *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el correo"
                                        type="email"
                                        v-model="mother_email"
                                        :state="showInputStatus('mother_email')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('mother_email')"
                                        :key="`${i}-ficha-mother_email`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- mother_us -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Está tu madre en los Estados Unidos?  *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="mother_us"
                                        :state="showInputStatus('mother_us')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('mother_us')"
                                        :key="`${i}-ficha-mother_us`"
                                        :state="showInputStatus('mother_us')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- us relation ship  -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- have_illegal_parents_in_us -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tienes parientes directos ilegales en EE. UU.? *"
                                    description="Si marcas 'si', menciónalos en el campo Partientes ilegales"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_illegal_parents_in_us"
                                        :state="showInputStatus('have_illegal_parents_in_us')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_illegal_parents_in_us')"
                                        :key="`${i}-ficha-have_illegal_parents_in_us`"
                                        :state="showInputStatus('have_illegal_parents_in_us')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- illegal_parents -->
                            <b-col cols="12" lg="6" v-if="have_illegal_parents_in_us == 'si'">
                                <b-form-group
                                    label="Parientes ilegales *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres"
                                        type="text"
                                        v-model="illegal_parents"
                                        :state="showInputStatus('illegal_parents')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('illegal_parents')"
                                        :key="`${i}-ficha-illegal_parents`"
                                        :state="showInputStatus('illegal_parents')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_immediate_relatives_in_us -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tienes algún pariente inmediato, sin incluir a los padres en los EE. UU.? *"
                                    description="Si no tienes, escribe 'no'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos"
                                        type="text"
                                        v-model="have_immediate_relatives_in_us"
                                        :state="showInputStatus('have_immediate_relatives_in_us')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_immediate_relatives_in_us')"
                                        :key="`${i}-ficha-have_immediate_relatives_in_us`"
                                        :state="showInputStatus('have_immediate_relatives_in_us')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_other_relatives_in_us -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tienes otros parientes en los Estados Unidos? *"
                                    description="Si no tienes, escribe 'no'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos"
                                        type="text"
                                        v-model="have_other_relatives_in_us"
                                        :state="showInputStatus('have_other_relatives_in_us')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_other_relatives_in_us')"
                                        :key="`${i}-ficha-have_other_relatives_in_us`"
                                        :state="showInputStatus('have_other_relatives_in_us')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- paying trip  -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- paying_trip -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Persona que paga su viaje *" 
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="paying_trip_person"
                                        :state="showInputStatus('paying_trip_person')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('paying_trip_person')"
                                        :key="`${i}-ficha-paying_trip_person`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- paying_trip_phone -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Número Celular*"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="paying_trip_phone"
                                        :state="showInputStatus('paying_trip_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('paying_trip_phone')"
                                        :key="`${i}-ficha-paying_trip_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- paying_trip_email -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Correo electrónico *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el correo"
                                        type="email"
                                        v-model="paying_trip_email"
                                        :state="showInputStatus('paying_trip_email')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('paying_trip_email')"
                                        :key="`${i}-ficha-paying_trip_email`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- paying_trip_relationship -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Parentesco *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos"
                                        type="text"
                                        v-model="paying_trip_relationship"
                                        :state="showInputStatus('paying_trip_relationship')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('paying_trip_relationship')"
                                        :key="`${i}-ficha-paying_trip_relationship`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- Contacto de Emergencia -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- apellidos y nombres -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Contacto de Emergencia *" 
                                    description="Apellidos y nombres del contacto de emergencia."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="emer_contact_fullname"
                                        :state="showInputStatus('emer_contact_fullname')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('emer_contact_fullname')"
                                        :key="`${i}-ficha-emer_contact_fullname`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- relacion -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Parentesco *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el parentesco con ud."
                                        type="text"
                                        v-model="emer_contact_relationship"
                                        :state="showInputStatus('emer_contact_relationship')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('emer_contact_relationship')"
                                        :key="`${i}-ficha-emer_contact_relationship`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- celular -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Número Telefónico del Contacto de Emergencia *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="emer_contact_cellphone"
                                        :state="showInputStatus('emer_contact_cellphone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('emer_contact_cellphone')"
                                        :key="`${i}-ficha-emer_contact_cellphone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- educacion -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- univer -inst -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Universidad o Instituto donde estudias *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-select
                                        :options="universidades"
                                        v-model="university_institute"
                                        :state="showInputStatus('university_institute')"
                                        v-show="!fillDataloading"
                                        @change="university_institute != 'Otro' ? u_i_otro = '' : null"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione  --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute')"
                                        :key="`${i}-ficha-university_institute`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- u_i_otro -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    :label="university_institute == 'Otro' ? '(Otro) Universidad o instituto  *' : '.'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        :placeholder="university_institute == 'Otro' ? 'Ingrese el nombre de la universidad o instituto' : ''"
                                        v-model="u_i_otro"
                                        :state="showInputStatus('u_i_otro')"
                                        v-show="!fillDataloading"
                                        :disabled="university_institute != 'Otro'"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('u_i_otro')"
                                        :key="`${i}-ficha-u_i_otro`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- univer - inst - cuidad-->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Ciudad donde se encuentra tu universidad o instituto *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-select
                                        :options="cuidades"
                                        v-model="university_institute_city"
                                        :state="showInputStatus('university_institute_city')"
                                        v-show="!fillDataloading"
                                        @change="university_institute_city != 'Otro' ? u_i_c_otro = '' : null"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione  --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute_city')"
                                        :key="`${i}-ficha-university_institute_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- u_i_c_otro -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    :label="university_institute_city == 'Otro' ? '(Otro) Cuidad  *' : '.'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        :placeholder="university_institute_city == 'Otro' ? 'Ingrese el nombre de la cuidad' : ''"
                                        v-model="u_i_c_otro"
                                        :state="showInputStatus('u_i_c_otro')"
                                        v-show="!fillDataloading"
                                        :disabled="university_institute_city != 'Otro'"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('u_i_c_otro')"
                                        :key="`${i}-ficha-u_i_c_otro`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- university_institute_address -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Dirección *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la dirección."
                                        type="text"
                                        v-model="university_institute_address"
                                        :state="showInputStatus('university_institute_address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute_address')"
                                        :key="`${i}-ficha-university_institute_address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- university_institute_department -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Departamento *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="university_institute_department"
                                        :state="showInputStatus('university_institute_department')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute_department')"
                                        :key="`${i}-ficha-university_institute_department`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- university_institute_zip_code -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Código postal *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el código postal."
                                        type="text"
                                        v-model="university_institute_zip_code"
                                        :state="showInputStatus('university_institute_zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute_zip_code')"
                                        :key="`${i}-ficha-university_institute_zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- university_institute_country -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="País *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="university_institute_country"
                                        :state="showInputStatus('university_institute_country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute_country')"
                                        :key="`${i}-ficha-university_institute_country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- university_institute_phone -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Teléfono *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el teléfono."
                                        type="text"
                                        v-model="university_institute_phone"
                                        :state="showInputStatus('university_institute_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('university_institute_phone')"
                                        :key="`${i}-ficha-university_institute_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- carrera -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Carrera profesional que estudias *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre de la carrera."
                                        type="text"
                                        v-model="career"
                                        :state="showInputStatus('career')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('career')"
                                        :key="`${i}-ficha-career`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- semestre-->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Semestre actual *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-select
                                        :options="semestres"
                                        v-model="current_semester"
                                        :state="showInputStatus('current_semester')"
                                        v-show="!fillDataloading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione  --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('current_semester')"
                                        :key="`${i}-ficha-current_semester`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- career_end_date -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha en la que culminas tus estudios Universitarios *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="career_end_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="career_end_date"
                                                :state="showInputStatus('career_end_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('career_end_date')"
                                        v-for="(inputError, i) in showInputErrors('career_end_date')"
                                        :key="`${i}-ficha-career_end_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- vacation_start_date -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de inicio de tus vacaciones *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="vacation_start_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="vacation_start_date"
                                                :state="showInputStatus('vacation_start_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('vacation_start_date')"
                                        v-for="(inputError, i) in showInputErrors('vacation_start_date')"
                                        :key="`${i}-ficha-vacation_start_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- vacation_end_date -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de finalización de tus vacaciones *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="vacation_end_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="vacation_end_date"
                                                :state="showInputStatus('vacation_end_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('vacation_end_date')"
                                        v-for="(inputError, i) in showInputErrors('vacation_end_date')"
                                        :key="`${i}-ficha-vacation_end_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- more ed. -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- have_ed_secondary -->
                            <b-col cols="12">
                                <b-form-group
                                    label="¿Ha asistido a alguna institución educativa de nivel secundario?*"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_ed_secondary"
                                        :state="showInputStatus('have_ed_secondary')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_ed_secondary')"
                                        :key="`${i}-ficha-have_ed_secondary`"
                                        :state="showInputStatus('have_ed_secondary')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_institute -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Nombre de la institución *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre de la institución."
                                        type="text"
                                        v-model="ed_s_institute"
                                        :state="showInputStatus('ed_s_institute')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_institute')"
                                        :key="`${i}-ficha-ed_s_institute`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_address -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Dirección *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la dirección."
                                        type="text"
                                        v-model="ed_s_address"
                                        :state="showInputStatus('ed_s_address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_address')"
                                        :key="`${i}-ficha-ed_s_address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_city -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Ciudad *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la ciudad."
                                        type="text"
                                        v-model="ed_s_city"
                                        :state="showInputStatus('ed_s_city')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_city')"
                                        :key="`${i}-ficha-ed_s_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_department -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Departamento *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="ed_s_department"
                                        :state="showInputStatus('ed_s_department')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_department')"
                                        :key="`${i}-ficha-ed_s_department`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_zip_code -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Código postal *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el código postal."
                                        type="text"
                                        v-model="ed_s_zip_code"
                                        :state="showInputStatus('ed_s_zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_zip_code')"
                                        :key="`${i}-ficha-ed_s_zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_country -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="País *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="ed_s_country"
                                        :state="showInputStatus('ed_s_country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_country')"
                                        :key="`${i}-ficha-ed_s_country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_course -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Área de estudio: *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos."
                                        type="text"
                                        v-model="ed_s_course"
                                        :state="showInputStatus('ed_s_course')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ed_s_course')"
                                        :key="`${i}-ficha-ed_s_course`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_date_from -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                               <b-form-group
                                    label="Fecha de Inicio *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="ed_s_date_from" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="ed_s_date_from"
                                                :state="showInputStatus('ed_s_date_from')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('ed_s_date_from')"
                                        v-for="(inputError, i) in showInputErrors('ed_s_date_from')"
                                        :key="`${i}-ficha-ed_s_date_from`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ed_s_date_to -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                               <b-form-group
                                    label="Fecha de finalización*"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="ed_s_date_to" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="ed_s_date_to"
                                                :state="showInputStatus('ed_s_date_to')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('ed_s_date_to')"
                                        v-for="(inputError, i) in showInputErrors('ed_s_date_to')"
                                        :key="`${i}-ficha-ed_s_date_to`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- more data -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- have_traveled_any_countries_last_five_years -->z
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha viajado a algún país o región en los últimos cinco años? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_traveled_any_countries_last_five_years"
                                        :state="showInputStatus('have_traveled_any_countries_last_five_years')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_traveled_any_countries_last_five_years')"
                                        :key="`${i}-ficha-have_traveled_any_countries_last_five_years`"
                                        :state="showInputStatus('have_traveled_any_countries_last_five_years')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- provide_list_countries_visited -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Proporcionar una lista de países/regiones visitadas *"
                                    description="Si no tienes, escribe 'ninguno'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los datos."
                                        type="text"
                                        v-model="provide_list_countries_visited"
                                        :state="showInputStatus('provide_list_countries_visited')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('provide_list_countries_visited')"
                                        :key="`${i}-ficha-provide_list_countries_visited`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_belong_charitable_org -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha pertenecido, contribuido o trabajado para algún profesional,organización social o caritativa? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_belong_charitable_org"
                                        :state="showInputStatus('have_belong_charitable_org')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_belong_charitable_org')"
                                        :key="`${i}-ficha-have_belong_charitable_org`"
                                        :state="showInputStatus('have_belong_charitable_org')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- hace_specialized_skills -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tiene alguna habilidad o capacitación especializada, como armas de fuego, explosivos, experiencia nuclear, biológica o química? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="hace_specialized_skills"
                                        :state="showInputStatus('hace_specialized_skills')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('hace_specialized_skills')"
                                        :key="`${i}-ficha-hace_specialized_skills`"
                                        :state="showInputStatus('hace_specialized_skills')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_served_in_military -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha servido en el ejército? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_served_in_military"
                                        :state="showInputStatus('have_served_in_military')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_served_in_military')"
                                        :key="`${i}-ficha-have_served_in_military`"
                                        :state="showInputStatus('have_served_in_military')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_served_in_paramilitary -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha servido, ha sido miembro o ha estado involucrado con una unidad paramilitar, unidad de vigilancia, grupo rebelde, grupo guerrillero u organización insurgente? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_served_in_paramilitary"
                                        :state="showInputStatus('have_served_in_paramilitary')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_served_in_paramilitary')"
                                        :key="`${i}-ficha-have_served_in_paramilitary`"
                                        :state="showInputStatus('have_served_in_paramilitary')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- how_found_program -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- how_found_program -->
                            <b-col cols="12" lg="7">
                                <b-form-group
                                    :label="`¿Cómo te enteraste de nuestro programa ${program_name}? *`"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-select
                                        :options="howToKnow"
                                        v-model="how_found_program"
                                        :state="showInputStatus('how_found_program')"
                                        v-show="!fillDataloading"
                                        @change="how_found_program != 'Otro' ? h_f_p_otro = '' : null"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione  --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('how_found_program')"
                                        :key="`${i}-ficha-how_found_program`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- h_f_p_otro -->
                            <b-col cols="12" lg="5">
                                <b-form-group
                                    :label="how_found_program == 'Otro' ? '(Otro) ...*' : '.'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        :placeholder="how_found_program == 'Otro' ? 'Ingrese cómo te enteraste de nuestro programa.' : ''"
                                        v-model="h_f_p_otro"
                                        :state="showInputStatus('h_f_p_otro')"
                                        v-show="!fillDataloading"
                                        :disabled="how_found_program != 'Otro'"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('h_f_p_otro')"
                                        :key="`${i}-ficha-h_f_p_otro`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- how_found_program_friend -->
                            <b-col cols="12" v-if="how_found_program == 'Por un amig@'">
                                <b-form-group
                                    label="Si te enteraste por un amig@; indícanos su nombre y apellidos."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="how_found_program_friend"
                                        :state="showInputStatus('how_found_program_friend')"
                                        v-show="!fillDataloading"
                                        
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('how_found_program_friend')"
                                        :key="`${i}-ficha-how_found_program_friend`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- experiencia de tabajo 1 -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-card-header class="text-center text-button" header-border-variant="0">
                            Experiencia Laboral 1
                        </b-card-header>

                        <b-form-row>
                            <!-- work_experience_one_company -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Nombre de la empresa"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre de la empresa."
                                        type="text"
                                        v-model="work_experience_one_company"
                                        :state="showInputStatus('work_experience_one_company')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_company')"
                                        :key="`${i}-ficha-work_experience_one_company`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_position -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Cargo"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre del cargo."
                                        type="text"
                                        v-model="work_experience_one_position"
                                        :state="showInputStatus('work_experience_one_position')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_position')"
                                        :key="`${i}-ficha-work_experience_one_position`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_address -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Dirección"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la dirección."
                                        type="text"
                                        v-model="work_experience_one_address"
                                        :state="showInputStatus('work_experience_one_address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_address')"
                                        :key="`${i}-ficha-work_experience_one_address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_city -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Ciudad"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la ciudad."
                                        type="text"
                                        v-model="work_experience_one_city"
                                        :state="showInputStatus('work_experience_one_city')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_city')"
                                        :key="`${i}-ficha-work_experience_one_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_state -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Departamento"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="work_experience_one_state"
                                        :state="showInputStatus('work_experience_one_state')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_state')"
                                        :key="`${i}-ficha-work_experience_one_state`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_zip_code -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Código postal"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el código postal."
                                        type="text"
                                        v-model="work_experience_one_zip_code"
                                        :state="showInputStatus('work_experience_one_zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_zip_code')"
                                        :key="`${i}-ficha-work_experience_one_zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_country -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="País"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="work_experience_one_country"
                                        :state="showInputStatus('work_experience_one_country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_country')"
                                        :key="`${i}-ficha-work_experience_one_country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_phone -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Número celular"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="work_experience_one_phone"
                                        :state="showInputStatus('work_experience_one_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_phone')"
                                        :key="`${i}-ficha-work_experience_one_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_sup_fullname -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Apellidos y Nombres" 
                                    description="Datos importantes para el rellenado del Formulario DS-160"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="work_experience_one_sup_fullname"
                                        :state="showInputStatus('work_experience_one_sup_fullname')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_sup_fullname')"
                                        :key="`${i}-ficha-work_experience_one_sup_fullname`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_from -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de empleo (Desde)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="work_experience_one_from" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="work_experience_one_from"
                                                :state="showInputStatus('work_experience_one_from')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('work_experience_one_from')"
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_from')"
                                        :key="`${i}-ficha-work_experience_one_from`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_one_to -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de empleo (Hasta)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="work_experience_one_to" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="work_experience_one_to"
                                                :state="showInputStatus('work_experience_one_to')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('work_experience_one_to')"
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_to')"
                                        :key="`${i}-ficha-work_experience_one_to`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                             <!-- work_experience_one_duties -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Describa brevemente sus funciones" 
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-textarea
                                        placeholder="Ingrese los funciones"
                                        v-model="work_experience_one_duties"
                                        :state="showInputStatus('work_experience_one_duties')"
                                        v-show="!fillDataloading"
                                    ></b-form-textarea>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_one_duties')"
                                        :key="`${i}-ficha-work_experience_one_duties`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- experiencia de tabajo 2-->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-card-header class="text-center text-button" header-border-variant="0">
                            Experiencia Laboral 2
                        </b-card-header>

                        <b-form-row>
                            <!-- work_experience_two_company -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Nombre de empresa"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre de la empresa."
                                        type="text"
                                        v-model="work_experience_two_company"
                                        :state="showInputStatus('work_experience_two_company')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_company')"
                                        :key="`${i}-ficha-work_experience_two_company`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_position -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Cargo"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el nombre del cargo."
                                        type="text"
                                        v-model="work_experience_two_position"
                                        :state="showInputStatus('work_experience_two_position')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_position')"
                                        :key="`${i}-ficha-work_experience_two_position`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_address -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Dirección"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la dirección."
                                        type="text"
                                        v-model="work_experience_two_address"
                                        :state="showInputStatus('work_experience_two_address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_address')"
                                        :key="`${i}-ficha-work_experience_two_address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_city -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Ciudad"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la ciudad."
                                        type="text"
                                        v-model="work_experience_two_city"
                                        :state="showInputStatus('work_experience_two_city')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_city')"
                                        :key="`${i}-ficha-work_experience_two_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_state -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Departamento"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="work_experience_two_state"
                                        :state="showInputStatus('work_experience_two_state')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_state')"
                                        :key="`${i}-ficha-work_experience_two_state`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_zip_code -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="Código postal"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el código postal."
                                        type="text"
                                        v-model="work_experience_two_zip_code"
                                        :state="showInputStatus('work_experience_two_zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_zip_code')"
                                        :key="`${i}-ficha-work_experience_two_zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_country -->
                            <b-col cols="12" lg="6" v-if="have_ed_secondary == 'si'">
                                <b-form-group
                                    label="País"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="work_experience_two_country"
                                        :state="showInputStatus('work_experience_two_country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_country')"
                                        :key="`${i}-ficha-work_experience_two_country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_phone -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Número celular"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="work_experience_two_phone"
                                        :state="showInputStatus('work_experience_two_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_phone')"
                                        :key="`${i}-ficha-work_experience_two_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_sup_fullname -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Apellidos y Nombres" 
                                    description="Datos importantes para el rellenado del Formulario DS-160"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="work_experience_two_sup_fullname"
                                        :state="showInputStatus('work_experience_two_sup_fullname')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_sup_fullname')"
                                        :key="`${i}-ficha-work_experience_two_sup_fullname`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_from -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de empleo (Desde)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="work_experience_two_from" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="work_experience_two_from"
                                                :state="showInputStatus('work_experience_two_from')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('work_experience_two_from')"
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_from')"
                                        :key="`${i}-ficha-work_experience_two_from`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- work_experience_two_to -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de empleo (Hasta)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="work_experience_two_to" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="work_experience_two_to"
                                                :state="showInputStatus('work_experience_two_to')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('work_experience_two_to')"
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_to')"
                                        :key="`${i}-ficha-work_experience_two_to`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                             <!-- work_experience_two_duties -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Describa brevemente sus funciones" 
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-textarea
                                        placeholder="Ingrese los funciones"
                                        v-model="work_experience_two_duties"
                                        :state="showInputStatus('work_experience_two_duties')"
                                        v-show="!fillDataloading"
                                    ></b-form-textarea>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_two_duties')"
                                        :key="`${i}-ficha-work_experience_two_duties`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- tiempo de experiencia -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- work_experience_total_time -->
                            <b-col cols="12" lg="7">
                                <b-form-group
                                    label="Tiempo total de experiencia Laboral"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-select
                                        :options="totalTimeExp"
                                        v-model="work_experience_total_time"
                                        :state="showInputStatus('work_experience_total_time')"
                                        v-show="!fillDataloading"
                                        @change="work_experience_total_time != 'Otro' ? w_e_t_t_otro = '' : null"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione  --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('work_experience_total_time')"
                                        :key="`${i}-ficha-work_experience_total_time`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- w_e_t_t_otro -->
                            <b-col cols="12" lg="5">
                                <b-form-group
                                    :label="work_experience_total_time == 'Otro' ? '(Otro) Tiempo total de experiencia ' : '.'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        :placeholder="work_experience_total_time == 'Otro' ? 'Ingrese el tiempo de experiencia.' : ''"
                                        v-model="w_e_t_t_otro"
                                        :state="showInputStatus('w_e_t_t_otro')"
                                        v-show="!fillDataloading"
                                        :disabled="work_experience_total_time != 'Otro'"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('w_e_t_t_otro')"
                                        :key="`${i}-ficha-w_e_t_t_otro`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- tipo de trabajo -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- type_of_work -->
                            <b-col cols="12" lg="7">
                                <b-form-group
                                    label="¿Que tipo de trabajo deseas realizar en USA?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-select
                                        :options="tipoDeTrabajo"
                                        v-model="type_of_work"
                                        :state="showInputStatus('type_of_work')"
                                        v-show="!fillDataloading"
                                        @change="type_of_work != 'Otro' ? t_o_w_otro = '' : null"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione  --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('type_of_work')"
                                        :key="`${i}-ficha-type_of_work`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <!-- t_o_w_otro -->
                            <b-col cols="12" lg="5">
                                <b-form-group
                                    :label="type_of_work == 'Otro' ? '(Otro) Tipo de trabajo ' : '.'"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        :placeholder="type_of_work == 'Otro' ? 'Tipo de trabajo.' : ''"
                                        v-model="t_o_w_otro"
                                        :state="showInputStatus('t_o_w_otro')"
                                        v-show="!fillDataloading"
                                        :disabled="type_of_work != 'Otro'"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('t_o_w_otro')"
                                        :key="`${i}-ficha-t_o_w_otro`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                 <!-- has been in us. -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- have_ever_been_in_us -->
                            <b-col cols="12">
                                <b-form-group
                                    label="¿Alguna vez has estado en los EE.UU.? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_ever_been_in_us"
                                        :state="showInputStatus('have_ever_been_in_us')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_ever_been_in_us')"
                                        :key="`${i}-ficha-have_ever_been_in_us`"
                                        :state="showInputStatus('have_ever_been_in_us')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- us_date_arrived_one -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                               <b-form-group
                                    label="Fecha de llegada (1)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="us_date_arrived_one" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="us_date_arrived_one"
                                                :state="showInputStatus('us_date_arrived_one')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('us_date_arrived_one')"
                                        v-for="(inputError, i) in showInputErrors('us_date_arrived_one')"
                                        :key="`${i}-ficha-us_date_arrived_one`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- us_length_stay_one -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                                <b-form-group
                                    label="Duración de la estancia (1)"
                                    description="Ejemplo: 15 semanas"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la duración en semanas."
                                        type="text"
                                        v-model="us_length_stay_one"
                                        :state="showInputStatus('us_length_stay_one')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('us_length_stay_one')"
                                        :key="`${i}-ficha-us_length_stay_one`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- us_date_arrived_two -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                               <b-form-group
                                    label="Fecha de llegada (2)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="us_date_arrived_two" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="us_date_arrived_two"
                                                :state="showInputStatus('us_date_arrived_two')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('us_date_arrived_two')"
                                        v-for="(inputError, i) in showInputErrors('us_date_arrived_two')"
                                        :key="`${i}-ficha-us_date_arrived_two`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- us_length_stay_two -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                                <b-form-group
                                    label="Duración de la estancia (2)"
                                    description="Ejemplo: 15 semanas"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la duración en semanas."
                                        type="text"
                                        v-model="us_length_stay_two"
                                        :state="showInputStatus('us_length_stay_two')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('us_length_stay_two')"
                                        :key="`${i}-ficha-us_length_stay_two`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- us_date_arrived_three -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                               <b-form-group
                                    label="Fecha de llegada (3)"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="us_date_arrived_three" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="us_date_arrived_three"
                                                :state="showInputStatus('us_date_arrived_three')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('us_date_arrived_three')"
                                        v-for="(inputError, i) in showInputErrors('us_date_arrived_three')"
                                        :key="`${i}-ficha-us_date_arrived_three`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- us_length_stay_three -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                                <b-form-group
                                    label="Duración de la estancia (3)"
                                    description="Ejemplo: 15 semanas"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la duración en semanas."
                                        type="text"
                                        v-model="us_length_stay_three"
                                        :state="showInputStatus('us_length_stay_three')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('us_length_stay_three')"
                                        :key="`${i}-ficha-us_length_stay_three`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- hold_us_driver_license -->
                            <b-col cols="12" lg="6" v-if="have_ever_been_in_us == 'si'">
                                <b-form-group
                                    label="¿Tiene o tuvo una licencia de conducir de los EE. UU.?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="hold_us_driver_license"
                                        :state="showInputStatus('hold_us_driver_license')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('hold_us_driver_license')"
                                        :key="`${i}-ficha-hold_us_driver_license`"
                                        :state="showInputStatus('hold_us_driver_license')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- visa denegada -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- visa_denied_why -->
                            <b-col cols="12">
                                <b-form-group
                                    label="¿Te negaron la visa a USA alguna vez, cuándo?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="visa_denied_why"
                                        :state="showInputStatus('visa_denied_why')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('visa_denied_why')"
                                        :key="`${i}-ficha-visa_denied_why`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- ¿Tuviste VISA Americana -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- had_american_visa -->
                            <b-col cols="12">
                                <b-form-group
                                    label="¿Tuviste algun tipo de VISA Americana? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="optionsAmericanVisa"
                                        v-model="had_american_visa"
                                        :state="showInputStatus('had_american_visa')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('had_american_visa')"
                                        :key="`${i}-ficha-had_american_visa`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- *** start ****** had_american_visa == si *********** -->
                <b-col cols="12" class="mb-4" v-if="had_american_visa == 'si'">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- previously_traveled_program -->
                            <b-col cols="12">
                                <b-form-group
                                    :label="`¿Has viajado anteriormente por el programa ${program_name}?`"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="optionsPreviousProgramTraveled"
                                        v-model="previously_traveled_program"
                                        :state="showInputStatus('previously_traveled_program')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('previously_traveled_program')"
                                        :key="`${i}-ficha-previously_traveled_program`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>
                <!-- *** end ****** had_american_visa == si *********** -->


                <!-- *** start ****** previously_traveled_program == si *********** -->
                <b-col cols="12" class="mb-4" v-if="had_american_visa == 'si' && previously_traveled_program == 'si'">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- agency_name -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Indícanos el nombre de la agencia "
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="agency_name"
                                        :state="showInputStatus('agency_name')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('agency_name')"
                                        :key="`${i}-ficha-agency_name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- why_change_agency -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Por qué cambiaste de agencia?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="why_change_agency"
                                        :state="showInputStatus('why_change_agency')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('why_change_agency')"
                                        :key="`${i}-ficha-why_change_agency`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- company_did_work -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿En qué empresa laboraste?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="company_did_work"
                                        :state="showInputStatus('company_did_work')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('company_did_work')"
                                        :key="`${i}-ficha-company_did_work`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- company_state_city -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Indica en qué estado y ciudad estaba ubicada la empresa"
                                    description="Estado - Ciudad"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="company_state_city"
                                        :state="showInputStatus('company_state_city')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('company_state_city')"
                                        :key="`${i}-ficha-company_state_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- company_position -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Qué posición ocupabas?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="company_position"
                                        :state="showInputStatus('company_position')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('company_position')"
                                        :key="`${i}-ficha-company_position`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- company_hourly_wage -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Cuál era tu salario por hora?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="text"
                                        v-model="company_hourly_wage"
                                        :state="showInputStatus('company_hourly_wage')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('company_hourly_wage')"
                                        :key="`${i}-ficha-company_hourly_wage`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- ssn -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="Social Security Number"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        type="number"
                                        v-model="ssn"
                                        :state="showInputStatus('ssn')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('ssn')"
                                        :key="`${i}-ficha-ssn`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>
                <!-- *** end ****** previously_traveled_program == si *********** -->

                <!-- VISA -->
                <b-col cols="12" class="mb-4" v-if="had_american_visa == 'si'">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- last_visa_number -->
                            <b-col cols="12" xl="6">
                                <b-form-group
                                    label="Número de última VISA *"
                                    description="Este se encuentra en la parte superior derecha y esta de color rojo."
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        v-model="last_visa_number"
                                        :state="showInputStatus('last_visa_number')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('last_visa_number')"
                                        :key="`${i}-ficha-last_visa_number`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- last_visa_emit_date -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de emisión de última VISA *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="last_visa_emit_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="last_visa_emit_date"
                                                :state="showInputStatus('last_visa_emit_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <b-form-invalid-feedback
                                        :state="showInputStatus('last_visa_emit_date')"
                                        v-for="(inputError, i) in showInputErrors('last_visa_emit_date')"
                                        :key="`${i}-ficha-last_visa_emit_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- last_visa_expiration_date -->
                            <b-col cols="12" lg="6">
                               <b-form-group
                                    label="Fecha de expiración de última VISA *"
                                    description="YYYY-MM-DD (AÑO-MES-DÍA)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-input-group v-if="!fillDataloading">
                                        <b-form-input v-model="last_visa_expiration_date" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>

                                        <b-input-group-append>
                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="last_visa_expiration_date"
                                                :state="showInputStatus('last_visa_expiration_date')"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>


                                    <b-form-invalid-feedback
                                        :state="showInputStatus('last_visa_expiration_date')"
                                        v-for="(inputError, i) in showInputErrors('last_visa_expiration_date')"
                                        :key="`${i}-ficha-last_visa_expiration_date`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- applying_same_visa -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Está solicitando el mismo tipo de visa?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="applying_same_visa"
                                        :state="showInputStatus('applying_same_visa')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('applying_same_visa')"
                                        :key="`${i}-ficha-applying_same_visa`"
                                        :state="showInputStatus('applying_same_visa')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- applying_same_country -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Está solicitando en el mismo país donde se emitió la visa anterior? y es este país su principal país de residencia?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="applying_same_country"
                                        :state="showInputStatus('applying_same_country')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('applying_same_country')"
                                        :key="`${i}-ficha-applying_same_country`"
                                        :state="showInputStatus('applying_same_country')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_been_ten_printed -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Le han tomado las huellas dactilares de los diez dedos? "
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_been_ten_printed"
                                        :state="showInputStatus('have_been_ten_printed')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_been_ten_printed')"
                                        :key="`${i}-ficha-have_been_ten_printed`"
                                        :state="showInputStatus('have_been_ten_printed')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- has_visa_lost -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha perdido o le han robado su visa estadounidense?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="has_visa_lost"
                                        :state="showInputStatus('has_visa_lost')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('has_visa_lost')"
                                        :key="`${i}-ficha-has_visa_lost`"
                                        :state="showInputStatus('has_visa_lost')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- has_visa_revoked -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez se ha cancelado o revocado su visa estadounidense?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="has_visa_revoked"
                                        :state="showInputStatus('has_visa_revoked')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('has_visa_revoked')"
                                        :key="`${i}-ficha-has_visa_revoked`"
                                        :state="showInputStatus('has_visa_revoked')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- has_visa_refused -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez le han negado una visa estadounidense o le han negado la admisión a los Estados Unidos, o retiró su solicitud de admisión en el ¿Puerto de entrada?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="has_visa_refused"
                                        :state="showInputStatus('has_visa_refused')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('has_visa_refused')"
                                        :key="`${i}-ficha-has_visa_refused`"
                                        :state="showInputStatus('has_visa_refused')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- has_immigrant_petition -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguien ha presentado alguna vez una petición de inmigrante en su nombre ante Servicios de Ciudadanía e Inmigración de los Estados Unidos?"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="has_immigrant_petition"
                                        :state="showInputStatus('has_immigrant_petition')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('has_immigrant_petition')"
                                        :key="`${i}-ficha-has_immigrant_petition`"
                                        :state="showInputStatus('has_immigrant_petition')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- HAVE ADDITIONAL CONTACT INFO -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- have_additional_contact_information -->
                            <b-col cols="12">
                                <b-form-group
                                    label="¿Tienes datos de contacto adicionales? *"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_additional_contact_information"
                                        :state="showInputStatus('have_additional_contact_information')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_additional_contact_information')"
                                        :key="`${i}-ficha-have_additional_contact_information`"
                                        :state="showInputStatus('have_additional_contact_information')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_fullname -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Apellidos y Nombres (1)" 
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="aci_one_fullname"
                                        :state="showInputStatus('aci_one_fullname')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_fullname')"
                                        :key="`${i}-ficha-aci_one_fullname`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_address -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Dirección (1)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la dirección."
                                        type="text"
                                        v-model="aci_one_address"
                                        :state="showInputStatus('aci_one_address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_address')"
                                        :key="`${i}-ficha-aci_one_address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_city -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Ciudad (1)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la ciudad."
                                        type="text"
                                        v-model="aci_one_city"
                                        :state="showInputStatus('aci_one_city')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_city')"
                                        :key="`${i}-ficha-aci_one_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_department -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Departamento (1)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="aci_one_department"
                                        :state="showInputStatus('aci_one_department')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_department')"
                                        :key="`${i}-ficha-aci_one_department`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_zip_code -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Código postal (1)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el código postal."
                                        type="text"
                                        v-model="aci_one_zip_code"
                                        :state="showInputStatus('aci_one_zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_zip_code')"
                                        :key="`${i}-ficha-aci_one_zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_country -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="País (1)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="aci_one_country"
                                        :state="showInputStatus('aci_one_country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_country')"
                                        :key="`${i}-ficha-aci_one_country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_phone -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Número de celular (1)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="aci_one_phone"
                                        :state="showInputStatus('aci_one_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_phone')"
                                        :key="`${i}-ficha-aci_one_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_one_email -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group label="Correo electrónico (1)">
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el correo"
                                        type="email"
                                        v-model="aci_one_email"
                                        :state="showInputStatus('aci_one_email')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_one_email')"
                                        :key="`${i}-ficha-aci_one_email`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" class="my-6"></b-col>

                            <!-- aci_two_fullname -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Apellidos y Nombres (2)" 
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese los apellidos y nombres."
                                        type="text"
                                        v-model="aci_two_fullname"
                                        :state="showInputStatus('aci_two_fullname')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_fullname')"
                                        :key="`${i}-ficha-aci_two_fullname`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_address -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Dirección (2)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la dirección."
                                        type="text"
                                        v-model="aci_two_address"
                                        :state="showInputStatus('aci_two_address')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_address')"
                                        :key="`${i}-ficha-aci_two_address`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_city -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Ciudad (2)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese la ciudad."
                                        type="text"
                                        v-model="aci_two_city"
                                        :state="showInputStatus('aci_two_city')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_city')"
                                        :key="`${i}-ficha-aci_two_city`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_department -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Departamento (2)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el departamento."
                                        type="text"
                                        v-model="aci_two_department"
                                        :state="showInputStatus('aci_two_department')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_department')"
                                        :key="`${i}-ficha-aci_two_department`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_zip_code -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Código postal (2)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el código postal."
                                        type="text"
                                        v-model="aci_two_zip_code"
                                        :state="showInputStatus('aci_two_zip_code')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_zip_code')"
                                        :key="`${i}-ficha-aci_two_zip_code`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_country -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="País (2)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el país."
                                        type="text"
                                        v-model="aci_two_country"
                                        :state="showInputStatus('aci_two_country')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_country')"
                                        :key="`${i}-ficha-aci_two_country`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_phone -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group
                                    label="Número de celular (2)"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el n° de celular"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="aci_two_phone"
                                        :state="showInputStatus('aci_two_phone')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_phone')"
                                        :key="`${i}-ficha-aci_two_phone`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- aci_two_email -->
                            <b-col cols="12" lg="6" v-if="have_additional_contact_information == 'si'">
                                <b-form-group label="Correo electrónico (2)">
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                    <b-form-input
                                        placeholder="Ingrese el correo"
                                        type="email"
                                        v-model="aci_two_email"
                                        :state="showInputStatus('aci_two_email')"
                                        v-show="!fillDataloading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('aci_two_email')"
                                        :key="`${i}-ficha-aci_two_email`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                        </b-form-row>
                    </b-card>
                </b-col>

                <!-- SECURITY - BACKGROUND INFORMATION -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            <!-- have_communicable_disease_health -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tiene una enfermedad transmisible de importancia para la salud pública? (Las enfermedades transmisibles de importancia pública incluyen chancroide, gonorrea, granuloma inguinal, lepra infecciosa, linfogranuloma venéreo, sífilis en estadio infeccioso, tuberculosis activa y otras enfermedades según lo determine el Departamento de Salud y Servicios Humanos). *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_communicable_disease_health"
                                        :state="showInputStatus('have_communicable_disease_health')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_communicable_disease_health')"
                                        :key="`${i}-ficha-have_communicable_disease_health`"
                                        :state="showInputStatus('have_communicable_disease_health')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_mental_disorder -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Tiene un trastorno mental o físico que representa o es probable que represente una amenaza para la seguridad o el bienestar suyo o de los demás? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_mental_disorder"
                                        :state="showInputStatus('have_mental_disorder')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_mental_disorder')"
                                        :key="`${i}-ficha-have_mental_disorder`"
                                        :state="showInputStatus('have_mental_disorder')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_been_drug_abuser -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Eres o alguna vez has sido drogadicto(a) o adicto(a)? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_been_drug_abuser"
                                        :state="showInputStatus('have_been_drug_abuser')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_been_drug_abuser')"
                                        :key="`${i}-ficha-have_been_drug_abuser`"
                                        :state="showInputStatus('have_been_drug_abuser')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_been_arrested -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha sido arrestado o condenado por alguna ofensa o delito, aunque sea objeto de un indulto, amnistía u otra acción similar? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_been_arrested"
                                        :state="showInputStatus('have_been_arrested')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_been_arrested')"
                                        :key="`${i}-ficha-have_been_arrested`"
                                        :state="showInputStatus('have_been_arrested')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_been_violated -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha violado o participado en una conspiración para violar alguna ley relacionada con sustancias controladas? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_been_violated"
                                        :state="showInputStatus('have_been_violated')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_been_violated')"
                                        :key="`${i}-ficha-have_been_violated`"
                                        :state="showInputStatus('have_been_violated')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- coming_united_engage_in_prostitution -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Viene a los Estados Unidos para ejercer la prostitución o el vicio comercializado ilegalmente o se ha dedicado a la prostitución o ha procurado prostitutas en los últimos 10 años? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="coming_united_engage_in_prostitution"
                                        :state="showInputStatus('coming_united_engage_in_prostitution')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('coming_united_engage_in_prostitution')"
                                        :key="`${i}-ficha-coming_united_engage_in_prostitution`"
                                        :state="showInputStatus('coming_united_engage_in_prostitution')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_been_involved_money_laundering -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha estado involucrado o busca involucrarse en el lavado de dinero? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_been_involved_money_laundering"
                                        :state="showInputStatus('have_been_involved_money_laundering')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_been_involved_money_laundering')"
                                        :key="`${i}-ficha-have_been_involved_money_laundering`"
                                        :state="showInputStatus('have_been_involved_money_laundering')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_committed_human_trafficking -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha cometido o conspirado para cometer un delito de trata de personas en los Estados Unidos o fuera de los Estados Unidos? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_committed_human_trafficking"
                                        :state="showInputStatus('have_committed_human_trafficking')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_committed_human_trafficking')"
                                        :key="`${i}-ficha-have_committed_human_trafficking`"
                                        :state="showInputStatus('have_committed_human_trafficking')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- are_you_son_of_individual_who_trafficking -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Es usted cónyuge, hijo o hija de una persona que ha cometido o conspirado para cometer un delito de trata de personas en los Estados Unidos o fuera de los Estados Unidos y en los últimos cinco años se ha beneficiado a sabiendas de las actividades de trata? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="are_you_son_of_individual_who_trafficking"
                                        :state="showInputStatus('are_you_son_of_individual_who_trafficking')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('are_you_son_of_individual_who_trafficking')"
                                        :key="`${i}-ficha-are_you_son_of_individual_who_trafficking`"
                                        :state="showInputStatus('are_you_son_of_individual_who_trafficking')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_assisted_of_individual_who_trafficking -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha ayudado, instigado, asistido o conspirado a sabiendas con una persona que ha cometido o conspirado para cometer un delito grave de trata de personas en los Estados Unidos o fuera de los Estados Unidos? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_assisted_of_individual_who_trafficking"
                                        :state="showInputStatus('have_assisted_of_individual_who_trafficking')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_assisted_of_individual_who_trafficking')"
                                        :key="`${i}-ficha-have_assisted_of_individual_who_trafficking`"
                                        :state="showInputStatus('have_assisted_of_individual_who_trafficking')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- do_you_engage_espionage -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Busca participar en espionaje, sabotaje, violaciones de control de exportaciones o cualquier otra actividad ilegal mientras se encuentra en los Estados Unidos? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="do_you_engage_espionage"
                                        :state="showInputStatus('do_you_engage_espionage')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('do_you_engage_espionage')"
                                        :key="`${i}-ficha-do_you_engage_espionage`"
                                        :state="showInputStatus('do_you_engage_espionage')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- do_you_engage_terrorist_activities -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Busca participar en actividades terroristas mientras está en los Estados Unidos o alguna vez ha participado en actividades terroristas? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="do_you_engage_terrorist_activities"
                                        :state="showInputStatus('do_you_engage_terrorist_activities')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('do_you_engage_terrorist_activities')"
                                        :key="`${i}-ficha-do_you_engage_terrorist_activities`"
                                        :state="showInputStatus('do_you_engage_terrorist_activities')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_provide_financial_to_terrorists -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha brindado o tiene la intención de brindar asistencia financiera u otro tipo de apoyo a terroristas u organizaciones terroristas? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_provide_financial_to_terrorists"
                                        :state="showInputStatus('have_provide_financial_to_terrorists')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_provide_financial_to_terrorists')"
                                        :key="`${i}-ficha-have_provide_financial_to_terrorists`"
                                        :state="showInputStatus('have_provide_financial_to_terrorists')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- are_you_member_terrorist -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Es usted miembro o representante de una organización terrorista? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="are_you_member_terrorist"
                                        :state="showInputStatus('are_you_member_terrorist')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('are_you_member_terrorist')"
                                        :key="`${i}-ficha-are_you_member_terrorist`"
                                        :state="showInputStatus('are_you_member_terrorist')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- are_you_son_of_individual_who_engaged_terrorist -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Es usted cónyuge, hijo o hija de una persona que se ha involucrado en actividades terroristas, incluida la prestación de asistencia financiera u otro tipo de apoyo a terroristas u organizaciones terroristas, en los últimos cinco años? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="are_you_son_of_individual_who_engaged_terrorist"
                                        :state="showInputStatus('are_you_son_of_individual_who_engaged_terrorist')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('are_you_son_of_individual_who_engaged_terrorist')"
                                        :key="`${i}-ficha-are_you_son_of_individual_who_engaged_terrorist`"
                                        :state="showInputStatus('are_you_son_of_individual_who_engaged_terrorist')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_committed_genocide -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha ordenado, incitado, cometido, asistido o participado de algún otro modo en un genocidio? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_committed_genocide"
                                        :state="showInputStatus('have_committed_genocide')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_committed_genocide')"
                                        :key="`${i}-ficha-have_committed_genocide`"
                                        :state="showInputStatus('have_committed_genocide')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_committed_torture -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha cometido, ordenado, incitado, asistido o participado de otra manera en la tortura? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_committed_torture"
                                        :state="showInputStatus('have_committed_torture')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_committed_torture')"
                                        :key="`${i}-ficha-have_committed_torture`"
                                        :state="showInputStatus('have_committed_torture')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_committed_extrajudicial_killings -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha cometido, ordenado, incitado, asistido o de algún otro modo participado en ejecuciones extrajudiciales, matanzas políticas u otros actos de violencia? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_committed_extrajudicial_killings"
                                        :state="showInputStatus('have_committed_extrajudicial_killings')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_committed_extrajudicial_killings')"
                                        :key="`${i}-ficha-have_committed_extrajudicial_killings`"
                                        :state="showInputStatus('have_committed_extrajudicial_killings')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_engaged_recruitment -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha participado en el reclutamiento o el uso de niños soldados? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_engaged_recruitment"
                                        :state="showInputStatus('have_engaged_recruitment')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_engaged_recruitment')"
                                        :key="`${i}-ficha-have_engaged_recruitment`"
                                        :state="showInputStatus('have_engaged_recruitment')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_serving_government_official -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha sido usted, mientras se desempeñaba como funcionario del gobierno, responsable o directamente perpetrador, en algún momento, de violaciones particularmente graves de la libertad religiosa? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_serving_government_official"
                                        :state="showInputStatus('have_serving_government_official')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_serving_government_official')"
                                        :key="`${i}-ficha-have_serving_government_official`"
                                        :state="showInputStatus('have_serving_government_official')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_involved_in_population_controls -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha estado directamente involucrado en el establecimiento o la aplicación de los controles de población que obligan a una mujer a someterse a una abortar en contra de su libre elección o que un hombre o una mujer se sometan a una esterilización en contra de su libre albedrío? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_involved_in_population_controls"
                                        :state="showInputStatus('have_involved_in_population_controls')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_involved_in_population_controls')"
                                        :key="`${i}-ficha-have_involved_in_population_controls`"
                                        :state="showInputStatus('have_involved_in_population_controls')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_involved_in_coercive_transplantation -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha estado directamente involucrado en el trasplante coercitivo de órganos humanos o tejido corporal? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_involved_in_coercive_transplantation"
                                        :state="showInputStatus('have_involved_in_coercive_transplantation')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_involved_in_coercive_transplantation')"
                                        :key="`${i}-ficha-have_involved_in_coercive_transplantation`"
                                        :state="showInputStatus('have_involved_in_coercive_transplantation')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_been_deportation_hearing -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha sido objeto de una audiencia de remoción o deportación? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_been_deportation_hearing"
                                        :state="showInputStatus('have_been_deportation_hearing')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_been_deportation_hearing')"
                                        :key="`${i}-ficha-have_been_deportation_hearing`"
                                        :state="showInputStatus('have_been_deportation_hearing')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_assist_others_to_obtain_visa -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha tratado de obtener o ayudar a otros a obtener una visa, entrada a los Estados Unidos o cualquier otro beneficio de inmigración de los Estados Unidos mediante fraude o tergiversación deliberada u otros medios ilegales? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_assist_others_to_obtain_visa"
                                        :state="showInputStatus('have_assist_others_to_obtain_visa')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_assist_others_to_obtain_visa')"
                                        :key="`${i}-ficha-have_assist_others_to_obtain_visa`"
                                        :state="showInputStatus('have_assist_others_to_obtain_visa')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_failed_to_attend_hearing -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha dejado de asistir a una audiencia sobre remoción o inadmisibilidad en los últimos cinco años? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_failed_to_attend_hearing"
                                        :state="showInputStatus('have_failed_to_attend_hearing')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_failed_to_attend_hearing')"
                                        :key="`${i}-ficha-have_failed_to_attend_hearing`"
                                        :state="showInputStatus('have_failed_to_attend_hearing')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_present_amount_time_granted -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha estado presente ilegalmente, se ha quedado más tiempo que el otorgado por un oficial de inmigración o ha violado los términos de una visa estadounidense? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_present_amount_time_granted"
                                        :state="showInputStatus('have_present_amount_time_granted')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_present_amount_time_granted')"
                                        :key="`${i}-ficha-have_present_amount_time_granted`"
                                        :state="showInputStatus('have_present_amount_time_granted')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_removed_from_any_country -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha sido expulsado o deportado de algún país? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_removed_from_any_country"
                                        :state="showInputStatus('have_removed_from_any_country')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_removed_from_any_country')"
                                        :key="`${i}-ficha-have_removed_from_any_country`"
                                        :state="showInputStatus('have_removed_from_any_country')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_custody_us_citizen -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha negado la custodia de un niño ciudadano estadounidense fuera de los Estados Unidos a una persona a la que un tribunal de los Estados Unidos le otorgó la custodia legal? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_custody_us_citizen"
                                        :state="showInputStatus('have_custody_us_citizen')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_custody_us_citizen')"
                                        :key="`${i}-ficha-have_custody_us_citizen`"
                                        :state="showInputStatus('have_custody_us_citizen')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_voted_in_us_violation -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha votado en los Estados Unidos en violación de alguna ley o reglamento? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_voted_in_us_violation"
                                        :state="showInputStatus('have_voted_in_us_violation')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_voted_in_us_violation')"
                                        :key="`${i}-ficha-have_voted_in_us_violation`"
                                        :state="showInputStatus('have_voted_in_us_violation')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_renounced_us_citizenship -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Alguna vez ha renunciado a la ciudadanía de los Estados Unidos con el fin de evitar impuestos? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_renounced_us_citizenship"
                                        :state="showInputStatus('have_renounced_us_citizenship')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_renounced_us_citizenship')"
                                        :key="`${i}-ficha-have_renounced_us_citizenship`"
                                        :state="showInputStatus('have_renounced_us_citizenship')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- have_attended_public_elementary_school -->
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    label="¿Ha asistido a una escuela primaria pública en condición de estudiante o a una escuela secundaria pública después del 30 de noviembre de 1996 sin reembolsar la escuela? *"
                                    label-class="text-justify"
                                >
                                    <pulse-loader
                                        :loading="fillDataloading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    ></pulse-loader>

                                   <b-form-radio-group
                                        :options="[{ value: 'si', text: 'Si' }, { value: 'no', text: 'No' }]"
                                        v-model="have_attended_public_elementary_school"
                                        :state="showInputStatus('have_attended_public_elementary_school')"
                                        v-show="!fillDataloading"
                                        stacked
                                        plain
                                    ></b-form-radio-group>

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('have_attended_public_elementary_school')"
                                        :key="`${i}-ficha-have_attended_public_elementary_school`"
                                        :state="showInputStatus('have_attended_public_elementary_school')"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-card>
                </b-col>


                <!-- Authorization -->
                <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-card-header class="text-center text-button">
                            autorización *
                        </b-card-header>

                        <b-card-text style="text-align: justify;">
                            Con la finalidad de brindarle mayor información que pueda ser de su interés sobre los distintos productos y servicios que ofrecen APK y en forma extensiva sus socios estratégicos, usted nos autoriza a registrar y tratar sus datos de contacto, los mismos que serán almacenados en nuestra base de datos por tiempo indefinido o hasta que lo revoque. Sin su consentimiento, no podrá recibir información que puede ser de su interés. En caso de consultas sobre su información personal, puede dirigirse a <b :class="`text-${program_color}`">{{ program_email }}</b> *
                        </b-card-text>

                        <b-form-group
                        >
                            <pulse-loader
                                :loading="fillDataloading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="10"
                                class="pt-2 pb-1 pl-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            ></pulse-loader>

                            <b-form-checkbox
                                v-model="authorization"
                                value="si"
                                unchecked-value="no"
                                :state="showInputStatus('authorization')"
                                v-show="!fillDataloading"
                                plain
                            > Si
                            </b-form-checkbox>

                            <b-form-invalid-feedback
                                v-for="(inputError, i) in showInputErrors('authorization')"
                                :key="`${i}-ficha-authorization`"
                                :state="showInputStatus('authorization')"
                            >
                                {{ inputError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-card>
                </b-col>

                <!-- <b-col cols="12" class="mb-4">
                    <b-card class="shadow">
                        <b-form-row>
                            plantilla
                        </b-form-row>
                    </b-card>
                </b-col> -->

                <b-col cols="12" class="mb-2" v-if="Object.keys(inputErrors).length > 0">
                    <v-alert border="top" elevation="1" outlined prominent text type="error" >
                            Existen campos sin rellenar.
                        </v-alert>
                </b-col>
                

                <b-row align-v="center" align-h="center">
                    <b-col cols="auto">
                        <b-button 
                            variant="primary"
                            type="submit"
                            size="lg"
                            :disabled="fillDataloading"
                        >
                            REGISTRAR
                        </b-button>
                    </b-col>
                    
                </b-row>
            </b-form>
        </b-col>

           <!--
            modal ficha status
        -->
        <b-modal
            id="modal-ficha-status"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">VALIDACIÓN DE LA FICHA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalFichaStatusClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editStatusFicha">
                                <!-- ficha status -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Estado de la Ficha :"
                                        >
                                            <pulse-loader
                                                :loading="fichaEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="participant.information_status"
                                                :options="statusOptions"
                                                :state="showModalInputStatus('status')"
                                                v-show="!fichaEditLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('status')"
                                                :key="`${i}-updateVoucher-status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="fichaEditLoading"
                                        >
                                            GUARDAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getParticipantInformation } from '@/api/ProgramSeasonParticipant/ParticipantInformation';
import { getProgramSeasonParticipant, updateStatusFicha } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant.js'
import { fillInformation } from '@/api/ProgramSeasonParticipant/FillInformation';
import swal from 'sweetalert'

export default {
    name: "ParticipantFichaComponent",
    props: {
        ui: {
            type: String,
            required: true
        }
    },
    data: () => ({
        programSeasonParticipant: {},
        participant: {},
        statusOptions: [
            { value: 'rellenado', text: 'rellenado' },
            { value: 'no rellenado', text: 'no rellenado' },
        ],
        //
        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        fichaEditLoading: false,
        //
        IdProgram: '',
        //
        program_email: '',
        program_name: '',
        program_color: '',
        // form
        name: '',
        p_last_name: '',
        m_last_name: '',
        date_birth: '',
        age: '',
        city_birth: '',
        country_birth: '',
        document_number: '',
        sex: '',
        marital_status: '',
        other_nationality: '',
        other_permanent_resident: '',
        // 
        address: '',
        distric: '',
        province: '',
        department: '',
        zip_code: '',
        country: '',
        //
        sm_plataform_one: '',
        sm_identifier_one: '',
        sm_plataform_two: '',
        sm_identifier_two: '',
        //
        passport_number: '',
        passport_image: null,
        passport_image_preview: '',
        passport_emit_date: '',
        passport_expiration_date: '',
        passport_city_issued: '',
        passport_state_issued: '',
        passport_country_issued: '',
        passport_lost: '',
        immigration_appointment_date: '',
        //
        land_line: '',
        phone_number: '',
        email: '',
        profile_link: '',
        skype_id: '',
        //
        father_full_name: '',
        father_date_birth: '',
        father_cell_phone: '',
        father_email: '',
        father_us: '',
        //
        mother_full_name: '',
        mother_date_birth: '',
        mother_cell_phone: '',
        mother_email: '',
        mother_us: '',

        have_illegal_parents_in_us: '',
        illegal_parents: '',
        have_immediate_relatives_in_us: '',
        have_other_relatives_in_us: '',
        // paying trip
        paying_trip_person: '',
        paying_trip_phone: '',
        paying_trip_email: '',
        paying_trip_relationship: '',
        //
        emer_contact_relationship: '',
        emer_contact_fullname: '',
        emer_contact_cellphone: '',
        //
        university_institute: '',
        u_i_otro: '',
        university_institute_city: '',
        u_i_c_otro: '',
        university_institute_address: '',
        university_institute_department: '',
        university_institute_zip_code: '',
        university_institute_country: '',
        university_institute_phone: '',
        career: '',
        current_semester: '',
        career_end_date: '',
        vacation_start_date: '',
        vacation_end_date: '',
        // more ed.
        have_ed_secondary: 'si',
        ed_s_institute: '',
        ed_s_address: '',
        ed_s_city: '',
        ed_s_department: '',
        ed_s_zip_code: '',
        ed_s_country: '',
        ed_s_course: '',
        ed_s_date_from: '',
        ed_s_date_to: '',
        // more data
        have_traveled_any_countries_last_five_years: '',
        provide_list_countries_visited: '',
        have_belong_charitable_org: '',
        hace_specialized_skills: '',
        have_served_in_military: '',
        have_served_in_paramilitary: '',
        //
        how_found_program: '',
        h_f_p_otro: '',
        how_found_program_friend: '',
        //
        work_experience_one_company: '',
        work_experience_one_position: '',
        work_experience_one_address: '',
        work_experience_one_city: '',
        work_experience_one_state: '',
        work_experience_one_zip_code: '',
        work_experience_one_country: '',
        work_experience_one_phone: '',
        work_experience_one_sup_fullname: '',
        work_experience_one_from: '',
        work_experience_one_to: '',
        work_experience_one_duties: '',

        work_experience_two_company: '',
        work_experience_two_position: '',
        work_experience_two_address: '',
        work_experience_two_city: '',
        work_experience_two_state: '',
        work_experience_two_zip_code: '',
        work_experience_two_country: '',
        work_experience_two_phone: '',
        work_experience_two_sup_fullname: '',
        work_experience_two_from: '',
        work_experience_two_to: '',
        work_experience_two_duties: '',

        work_experience_total_time: '',
        w_e_t_t_otro: '',
        type_of_work: '',
        t_o_w_otro: '',
        // HAS BEEN IN US
        have_ever_been_in_us: '',
        us_date_arrived_one: '',
        us_length_stay_one: '',
        us_date_arrived_two: '',
        us_length_stay_two: '',
        us_date_arrived_three: '',
        us_length_stay_three: '',
        hold_us_driver_license: '',
        //
        visa_denied_why: '',
        had_american_visa: 'no',

        // if had_america_visa : si
        previously_traveled_program: 'no',

        // if previously_traveled_program : si
        agency_name: '',
        why_change_agency: '',
        company_did_work: '',
        company_state_city: '',
        company_position: '',
        company_hourly_wage: '',
        ssn: '',

        // if (had_america_visa : si)
        last_visa_number: '',
        last_visa_emit_date: '',
        last_visa_expiration_date: '',
        applying_same_visa: '',
        applying_same_country: '',
        have_been_ten_printed: '',
        has_visa_lost: '',
        has_visa_revoked: '',
        has_visa_refused: '',
        has_immigrant_petition: '',

        // HAVE ADDITIONAL CONTACT INFO
        have_additional_contact_information: '',
        aci_one_fullname: '',
        aci_one_address: '',
        aci_one_city: '',
        aci_one_department: '',
        aci_one_zip_code: '',
        aci_one_country: '',
        aci_one_phone: '',
        aci_one_email: '',
        aci_two_fullname: '',
        aci_two_address: '',
        aci_two_city: '',
        aci_two_department: '',
        aci_two_zip_code: '',
        aci_two_country: '',
        aci_two_phone: '',
        aci_two_email: '',

        have_communicable_disease_health: '',
        have_mental_disorder: '',
        have_been_drug_abuser: '',
        have_been_arrested: '',
        have_been_violated: '',
        coming_united_engage_in_prostitution: '',
        have_been_involved_money_laundering: '',
        have_committed_human_trafficking: '',
        are_you_son_of_individual_who_trafficking: '',
        have_assisted_of_individual_who_trafficking: '',
        do_you_engage_espionage: '',
        do_you_engage_terrorist_activities: '',
        have_provide_financial_to_terrorists: '',
        are_you_member_terrorist: '',
        are_you_son_of_individual_who_engaged_terrorist: '',
        have_committed_genocide: '',
        have_committed_torture: '',
        have_committed_extrajudicial_killings: '',
        have_engaged_recruitment: '',
        have_serving_government_official: '',
        have_involved_in_population_controls: '',
        have_involved_in_coercive_transplantation: '',
        have_been_deportation_hearing: '',
        have_assist_others_to_obtain_visa: '',
        have_failed_to_attend_hearing: '',
        have_present_amount_time_granted: '',
        have_removed_from_any_country: '',
        have_custody_us_citizen: '',
        have_voted_in_us_violation: '',
        have_renounced_us_citizenship: '',
        have_attended_public_elementary_school: '',

        authorization: '',

        //**optionns */
        universidades: [
            { value: 'Universidad Nacional Mayor de San Marcos', text: 'Universidad Nacional Mayor de San Marcos' },
            { value: 'Universidad Nacional de Ingeniería', text: 'Universidad Nacional de Ingeniería' },
            { value: 'Universidad de Lima', text: 'Universidad de Lima' },
            { value: 'Universidad del Pacífico', text: 'Universidad del Pacífico' },
            { value: 'Universidad San Ignacio de Loyola', text: 'Universidad San Ignacio de Loyola' },
            { value: 'Pontificia Universidad Católica del Perú', text: 'Pontificia Universidad Católica del Perú' },
            { value: 'Universidad Alas Peruanas', text: 'Universidad Alas Peruanas' },
            { value: 'Universidad Ricardo Palma', text: 'Universidad Ricardo Palma' },
            { value: 'Universidad Nacional del Centro del Perú', text: 'Universidad Nacional del Centro del Perú' },
            { value: 'Universidad Continental', text: 'Universidad Continental' },
            { value: 'Universidad Peruana Los Andes', text: 'Universidad Peruana Los Andes' },
            { value: 'Universidad Católica Santa María', text: 'Universidad Católica Santa María' },
            { value: 'Universidad Nacional Hermilio Valdizàn', text: 'Universidad Nacional Hermilio Valdizàn' },
            { value: 'Universidad Privada Antenor Orrego', text: 'Universidad Privada Antenor Orrego' },
            { value: 'Universidad Cesar Vallejo', text: 'Universidad Cesar Vallejo' },
            { value: 'Universidad Nacional de Trujillo', text: 'Universidad Nacional de Trujillo' },
            { value: 'Universidad Privada San Pedro', text: 'Universidad Privada San Pedro' },
            { value: 'Universidad Privada del Norte', text: 'Universidad Privada del Norte' },
            { value: 'Universidad Nacional de San Cristobal de Huamanga', text: 'Universidad Nacional de San Cristobal de Huamanga' },
            { value: 'Universidad Franklin Roosevelt', text: 'Universidad Franklin Roosevelt' },
            { value: 'Instituto Continental', text: 'Instituto Continental' },
            { value: 'Instituto Franklin Roosevelt', text: 'Instituto Franklin Roosevelt' },
            { value: 'Otro', text: 'Otro' },
        ],
        cuidades: [
            { value: 'Lima', text: 'Lima' },
            { value: 'Huancayo', text: 'Huancayo' },
            { value: 'Arequipa', text: 'Arequipa' },
            { value: 'Trujillo', text: 'Trujillo' },
            { value: 'Chiclayo', text: 'Chiclayo' },
            { value: 'Piura', text: 'Piura' },
            { value: 'Huánuco', text: 'Huánuco' },
            { value: 'Chimbote', text: 'Chimbote' },
            { value: 'Cusco', text: 'Cusco' },
            { value: 'Puno', text: 'Puno' },
            { value: 'Ayacucho', text: 'Ayacucho' },
            { value: 'Otro', text: 'Otro' },
        ],
        semestres: [
            { value: 'I', text: 'I' },
            { value: 'II', text: 'II' },
            { value: 'III', text: 'III' },
            { value: 'IV', text: 'IV' },
            { value: 'V', text: 'V' },
            { value: 'VI', text: 'VI' },
            { value: 'VII', text: 'VII' },
            { value: 'VIII', text: 'VIII' },
            { value: 'IX', text: 'IX' },
            { value: 'X', text: 'X' },
            { value: 'XI - XII', text: 'XI - XII' },
            { value: 'XIII - XIV', text: 'XIII - XIV' },
        ],
        howToKnow: [
            { value: 'Por un amig@', text: 'Por un amig@' },
            { value: 'Facebook', text: 'Facebook' },
            { value: 'Página web', text: 'Página web' },
            { value: 'Familiares', text: 'Familiares' },
            { value: 'Por mi universidad', text: 'Por mi universidad' },
            { value: 'Feria laboral', text: 'Feria laboral' },
            { value: 'Otro', text: 'Otro' },
        ],
        totalTimeExp: [
            { value: '3 meses', text: '3 meses' },
            { value: '6 meses', text: '6 meses' },
            { value: '1 año', text: '1 año' },
            { value: '2 años', text: '2 años' },
            { value: 'Otro', text: 'Otro' },
        ],
        tipoDeTrabajo: [
            { value: 'Housekeeper', text: 'Housekeeper' },
            { value: 'Food and Beverage', text: 'Food and Beverage' },
            { value: 'Lift operator', text: 'Lift operator' },
            { value: 'Lifeguard', text: 'Lifeguard' },
            { value: 'Customer service', text: 'Customer service' },
            { value: 'Otro', text: 'Otro' },
        ],
        optionsAmericanVisa: [
            { value: 'si', text: 'si' },
            { value: 'no', text: 'no' },
        ],
        optionsPreviousProgramTraveled: [
            { value: 'si', text: 'si' },
            { value: 'no', text: 'no' },
        ],

        fillDataloading: false,
        initialInputValues: true,
        inputErrors: {},
    }),

    created () {
        this.getInformation()
    },

    methods: {
        getInformation() {
            this.fillDataloading = true

            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant  // ok

            getParticipantInformation(IdProgramSeasonParticipant)
                .then(({data}) => {
                    // console.log(data);
                    if (data.data) {
                        this.IdProgram = data.data.attributes.program_id
                        this.programSeasonEvaluation()
                        let information = data.data.attributes
                        //
                        this.name = information.name == null ? '' : information.name
                        this.p_last_name = information.p_last_name == null ? '' : information.p_last_name
                        this.m_last_name = information.m_last_name == null ? '' : information.m_last_name
                        this.date_birth = information.date_birth == null ? '' : information.date_birth
                        this.age = information.age == null ? '' : information.age
                        this.city_birth = information.city_birth == null ? '' : information.city_birth
                        this.country_birth = information.country_birth == null ? '' : information.country_birth
                        this.sex = information.sex == null ? '' : information.sex
                        this.marital_status = information.marital_status == null ? '' : information.marital_status
                        this.other_nationality = information.other_nationality == null ? '' : information.other_nationality
                        this.other_permanent_resident = information.other_permanent_resident == null ? '' : information.other_permanent_resident
                        //
                        this.document_number = information.document_number == null ? '' : information.document_number
                        this.address = information.address == null ? '' : information.address
                        this.distric = information.distric == null ? '' : information.distric
                        this.province = information.province == null ? '' : information.province
                        this.department = information.department == null ? '' : information.department
                        this.zip_code = information.zip_code == null ? '' : information.zip_code
                        this.country = information.country == null ? '' : information.country

                        this.sm_plataform_one = information.sm_plataform_one == null ? '' : information.sm_plataform_one
                        this.sm_identifier_one = information.sm_identifier_one == null ? '' : information.sm_identifier_one
                        this.sm_plataform_two = information.sm_plataform_two == null ? '' : information.sm_plataform_two
                        this.sm_identifier_two = information.sm_identifier_two == null ? '' : information.sm_identifier_two

                        this.passport_number = information.passport_number == null ? '' : information.passport_number
                        this.passport_image_preview = information.passport_image == null ? '/img/theme/passport.jpg' : information.passport_image
                        this.passport_emit_date = information.passport_emit_date == null ? '' : information.passport_emit_date
                        this.passport_expiration_date = information.passport_expiration_date == null ? '' : information.passport_expiration_date
                        this.passport_city_issued = information.passport_city_issued == null ? '' : information.passport_city_issued
                        this.passport_state_issued = information.passport_state_issued == null ? '' : information.passport_state_issued
                        this.passport_country_issued = information.passport_country_issued == null ? '' : information.passport_country_issued
                        this.passport_lost = information.passport_lost == null ? '' : information.passport_lost

                        this.immigration_appointment_date = information.immigration_appointment_date == null ? '' : information.immigration_appointment_date

                        this.land_line = information.land_line == null ? '' : information.land_line
                        this.phone_number = information.phone_number == null ? '' : information.phone_number
                        this.email = information.email == null ? '' : information.email
                        this.profile_link = information.profile_link == null ? '' : information.profile_link
                        this.skype_id = information.skype_id == null ? '' : information.skype_id

                        this.father_full_name = information.father_full_name == null ? '' : information.father_full_name
                        this.father_date_birth = information.father_date_birth == null ? '' : information.father_date_birth
                        this.father_cell_phone = information.father_cell_phone == null ? '' : information.father_cell_phone
                        this.father_email = information.father_email == null ? '' : information.father_email
                        this.father_us = information.father_us == null ? '' : information.father_us

                        this.mother_full_name = information.mother_full_name == null ? '' : information.mother_full_name
                        this.mother_date_birth = information.mother_date_birth == null ? '' : information.mother_date_birth
                        this.mother_cell_phone = information.mother_cell_phone == null ? '' : information.mother_cell_phone
                        this.mother_email = information.mother_email == null ? '' : information.mother_email
                        this.mother_us = information.mother_us == null ? '' : information.mother_us

                        this.have_illegal_parents_in_us = information.have_illegal_parents_in_us == null ? '' : information.have_illegal_parents_in_us
                        this.illegal_parents = information.illegal_parents == null ? '' : information.illegal_parents
                        this.have_immediate_relatives_in_us = information.have_immediate_relatives_in_us == null ? '' : information.have_immediate_relatives_in_us
                        this.have_other_relatives_in_us = information.have_other_relatives_in_us == null ? '' : information.have_other_relatives_in_us

                        this.paying_trip_person = information.paying_trip_person == null ? '' : information.paying_trip_person
                        this.paying_trip_phone = information.paying_trip_phone == null ? '' : information.paying_trip_phone
                        this.paying_trip_email = information.paying_trip_email == null ? '' : information.paying_trip_email
                        this.paying_trip_relationship = information.paying_trip_relationship == null ? '' : information.paying_trip_relationship

                        this.emer_contact_relationship = information.emer_contact_relationship == null ? '' : information.emer_contact_relationship
                        this.emer_contact_fullname = information.emer_contact_fullname == null ? '' : information.emer_contact_fullname
                        this.emer_contact_cellphone = information.emer_contact_cellphone == null ? '' : information.emer_contact_cellphone

                        this.university_institute = information.university_institute == '' ? '' : (this.universidades.filter(el => el.value == information.university_institute).length == 0 ? 'Otro' : information.university_institute)
                        this.u_i_otro = this.universidades.filter(el => el.value == information.university_institute).length == 0 ? information.university_institute : ''

                        this.university_institute_city = information.university_institute_city == '' ? '' : (this.cuidades.filter(el => el.value == information.university_institute_city).length == 0 ? 'Otro' : information.university_institute_city)
                        this.u_i_c_otro = this.cuidades.filter(el => el.value == information.university_institute_city).length == 0 ? information.university_institute_city : ''
                        this.university_institute_address = information.university_institute_address == null ? '' : information.university_institute_address
                        this.university_institute_department = information.university_institute_department == null ? '' : information.university_institute_department
                        this.university_institute_zip_code = information.university_institute_zip_code == null ? '' : information.university_institute_zip_code
                        this.university_institute_country = information.university_institute_country == null ? '' : information.university_institute_country
                        this.university_institute_phone = information.university_institute_phone == null ? '' : information.university_institute_phone
                        this.career = information.career == null ? '' : information.career
                        this.current_semester = information.current_semester == null ? '' : information.current_semester
                        this.career_end_date = information.career_end_date == null ? '' : information.career_end_date
                        this.vacation_start_date = information.vacation_start_date == null ? '' : information.vacation_start_date
                        this.vacation_end_date = information.vacation_end_date == null ? '' : information.vacation_end_date

                        this.have_ed_secondary = information.have_ed_secondary == null ? 'si' : information.have_ed_secondary
                        this.ed_s_institute = information.ed_s_institute == null ? '' : information.ed_s_institute
                        this.ed_s_address = information.ed_s_address == null ? '' : information.ed_s_address
                        this.ed_s_city = information.ed_s_city == null ? '' : information.ed_s_city
                        this.ed_s_department = information.ed_s_department == null ? '' : information.ed_s_department
                        this.ed_s_zip_code = information.ed_s_zip_code == null ? '' : information.ed_s_zip_code
                        this.ed_s_country = information.ed_s_country == null ? '' : information.ed_s_country
                        this.ed_s_course = information.ed_s_course == null ? '' : information.ed_s_course
                        this.ed_s_date_from = information.ed_s_date_from == null ? '' : information.ed_s_date_from
                        this.ed_s_date_to = information.ed_s_date_to == null ? '' : information.ed_s_date_to

                        this.have_traveled_any_countries_last_five_years = information.have_traveled_any_countries_last_five_years == null ? '' : information.have_traveled_any_countries_last_five_years
                        this.provide_list_countries_visited = information.provide_list_countries_visited == null ? '' : information.provide_list_countries_visited
                        this.have_belong_charitable_org = information.have_belong_charitable_org == null ? '' : information.have_belong_charitable_org
                        this.hace_specialized_skills = information.hace_specialized_skills == null ? '' : information.hace_specialized_skills
                        this.have_served_in_military = information.have_served_in_military == null ? '' : information.have_served_in_military
                        this.have_served_in_paramilitary = information.have_served_in_paramilitary == null ? '' : information.have_served_in_paramilitary

                        this.how_found_program = information.how_found_program == '' ? '' : (this.howToKnow.filter(el => el.value == information.how_found_program).length == 0 ? 'Otro' : information.how_found_program)
                        this.h_f_p_otro = this.howToKnow.filter(el => el.value == information.how_found_program).length == 0 ? information.how_found_program : ''

                        this.how_found_program_friend = information.how_found_program_friend == null ? '' : information.how_found_program_friend
                        this.work_experience_one_company = information.work_experience_one_company == null ? '' : information.work_experience_one_company
                        this.work_experience_one_position = information.work_experience_one_position == null ? '' : information.work_experience_one_position
                        this.work_experience_one_address = information.work_experience_one_address == null ? '' : information.work_experience_one_address
                        this.work_experience_one_city = information.work_experience_one_city == null ? '' : information.work_experience_one_city
                        this.work_experience_one_state = information.work_experience_one_state == null ? '' : information.work_experience_one_state
                        this.work_experience_one_zip_code = information.work_experience_one_zip_code == null ? '' : information.work_experience_one_zip_code
                        this.work_experience_one_country = information.work_experience_one_country == null ? '' : information.work_experience_one_country
                        this.work_experience_one_phone = information.work_experience_one_phone == null ? '' : information.work_experience_one_phone
                        this.work_experience_one_sup_fullname = information.work_experience_one_sup_fullname == null ? '' : information.work_experience_one_sup_fullname
                        this.work_experience_one_from = information.work_experience_one_from == null ? '' : information.work_experience_one_from
                        this.work_experience_one_to = information.work_experience_one_to == null ? '' : information.work_experience_one_to
                        this.work_experience_one_duties = information.work_experience_one_duties == null ? '' : information.work_experience_one_duties

                        this.work_experience_two_company = information.work_experience_two_company == null ? '' : information.work_experience_two_company
                        this.work_experience_two_position = information.work_experience_two_position == null ? '' : information.work_experience_two_position
                        this.work_experience_two_address = information.work_experience_two_address == null ? '' : information.work_experience_two_address
                        this.work_experience_two_city = information.work_experience_two_city == null ? '' : information.work_experience_two_city
                        this.work_experience_two_state = information.work_experience_two_state == null ? '' : information.work_experience_two_state
                        this.work_experience_two_zip_code = information.work_experience_two_zip_code == null ? '' : information.work_experience_two_zip_code
                        this.work_experience_two_country = information.work_experience_two_country == null ? '' : information.work_experience_two_country
                        this.work_experience_two_phone = information.work_experience_two_phone == null ? '' : information.work_experience_two_phone
                        this.work_experience_two_sup_fullname = information.work_experience_two_sup_fullname == null ? '' : information.work_experience_two_sup_fullname
                        this.work_experience_two_from = information.work_experience_two_from == null ? '' : information.work_experience_two_from
                        this.work_experience_two_to = information.work_experience_two_to == null ? '' : information.work_experience_two_to
                        this.work_experience_two_duties = information.work_experience_two_duties == null ? '' : information.work_experience_two_duties

                        this.work_experience_total_time = information.work_experience_total_time == '' || information.work_experience_total_time == null ? '' : (this.totalTimeExp.filter(el => el.value == information.work_experience_total_time).length == 0 ? 'Otro' : information.work_experience_total_time)
                        this.w_e_t_t_otro = this.totalTimeExp.filter(el => el.value == information.work_experience_total_time).length == 0 ? information.work_experience_total_time : ''

                        this.type_of_work = information.type_of_work == '' || information.type_of_work == null ? '' : (this.tipoDeTrabajo.filter(el => el.value == information.type_of_work).length == 0 ? 'Otro' : information.type_of_work)
                        this.t_o_w_otro = this.tipoDeTrabajo.filter(el => el.value == information.type_of_work).length == 0 ? information.type_of_work : ''

                        this.have_ever_been_in_us = information.have_ever_been_in_us == null ? '' : information.have_ever_been_in_us
                        this.us_date_arrived_one = information.us_date_arrived_one == null ? '' : information.us_date_arrived_one
                        this.us_length_stay_one = information.us_length_stay_one == null ? '' : information.us_length_stay_one
                        this.us_date_arrived_two = information.us_date_arrived_two == null ? '' : information.us_date_arrived_two
                        this.us_length_stay_two = information.us_length_stay_two == null ? '' : information.us_length_stay_two
                        this.us_date_arrived_three = information.us_date_arrived_three == null ? '' : information.us_date_arrived_three
                        this.us_length_stay_three = information.us_length_stay_three == null ? '' : information.us_length_stay_three
                        this.hold_us_driver_license = information.hold_us_driver_license == null ? '' : information.hold_us_driver_license

                        this.visa_denied_why = information.visa_denied_why == null ? '' : information.visa_denied_why
                        this.had_american_visa = information.had_american_visa == null || information.had_american_visa == ''  ? 'no' : information.had_american_visa

                        this.previously_traveled_program = information.previously_traveled_program == null || information.previously_traveled_program == '' ? 'no' : information.previously_traveled_program

                        this.agency_name = information.agency_name == null ? '' : information.agency_name
                        this.why_change_agency = information.why_change_agency == null ? '' : information.why_change_agency
                        this.company_did_work = information.company_did_work == null ? '' : information.company_did_work
                        this.company_state_city = information.company_state_city == null ? '' : information.company_state_city
                        this.company_position = information.company_position == null ? '' : information.company_position
                        this.company_hourly_wage = information.company_hourly_wage == null ? '' : information.company_hourly_wage
                        this.ssn = information.ssn == null ? '' : information.ssn

                        this.last_visa_number = information.last_visa_number == null ? '' : information.last_visa_number
                        this.last_visa_emit_date = information.last_visa_emit_date == null ? '' : information.last_visa_emit_date
                        this.last_visa_expiration_date = information.last_visa_expiration_date == null ? '' : information.last_visa_expiration_date
                        this.applying_same_visa = information.applying_same_visa == null ? '' : information.applying_same_visa
                        this.applying_same_country = information.applying_same_country == null ? '' : information.applying_same_country
                        this.have_been_ten_printed = information.have_been_ten_printed == null ? '' : information.have_been_ten_printed
                        this.has_visa_lost = information.has_visa_lost == null ? '' : information.has_visa_lost
                        this.has_visa_revoked = information.has_visa_revoked == null ? '' : information.has_visa_revoked
                        this.has_visa_refused = information.has_visa_refused == null ? '' : information.has_visa_refused
                        this.has_immigrant_petition = information.has_immigrant_petition == null ? '' : information.has_immigrant_petition

                        this.have_additional_contact_information = information.have_additional_contact_information == null ? '' : information.have_additional_contact_information
                        this.aci_one_fullname = information.aci_one_fullname == null ? '' : information.aci_one_fullname
                        this.aci_one_address = information.aci_one_address == null ? '' : information.aci_one_address
                        this.aci_one_city = information.aci_one_city == null ? '' : information.aci_one_city
                        this.aci_one_department = information.aci_one_department == null ? '' : information.aci_one_department
                        this.aci_one_zip_code = information.aci_one_zip_code == null ? '' : information.aci_one_zip_code
                        this.aci_one_country = information.aci_one_country == null ? '' : information.aci_one_country
                        this.aci_one_phone = information.aci_one_phone == null ? '' : information.aci_one_phone
                        this.aci_one_email = information.aci_one_email == null ? '' : information.aci_one_email
                        this.aci_two_fullname = information.aci_two_fullname == null ? '' : information.aci_two_fullname
                        this.aci_two_address = information.aci_two_address == null ? '' : information.aci_two_address
                        this.aci_two_city = information.aci_two_city == null ? '' : information.aci_two_city
                        this.aci_two_department = information.aci_two_department == null ? '' : information.aci_two_department
                        this.aci_two_zip_code = information.aci_two_zip_code == null ? '' : information.aci_two_zip_code
                        this.aci_two_country = information.aci_two_country == null ? '' : information.aci_two_country
                        this.aci_two_phone = information.aci_two_phone == null ? '' : information.aci_two_phone
                        this.aci_two_email = information.aci_two_email == null ? '' : information.aci_two_email

                        this.have_communicable_disease_health = information.have_communicable_disease_health == null ? '' : information.have_communicable_disease_health
                        this.have_mental_disorder = information.have_mental_disorder == null ? '' : information.have_mental_disorder
                        this.have_been_drug_abuser = information.have_been_drug_abuser == null ? '' : information.have_been_drug_abuser
                        this.have_been_arrested = information.have_been_arrested == null ? '' : information.have_been_arrested
                        this.have_been_violated = information.have_been_violated == null ? '' : information.have_been_violated
                        this.coming_united_engage_in_prostitution = information.coming_united_engage_in_prostitution == null ? '' : information.coming_united_engage_in_prostitution
                        this.have_been_involved_money_laundering = information.have_been_involved_money_laundering == null ? '' : information.have_been_involved_money_laundering
                        this.have_committed_human_trafficking = information.have_committed_human_trafficking == null ? '' : information.have_committed_human_trafficking
                        this.are_you_son_of_individual_who_trafficking = information.are_you_son_of_individual_who_trafficking == null ? '' : information.are_you_son_of_individual_who_trafficking
                        this.have_assisted_of_individual_who_trafficking = information.have_assisted_of_individual_who_trafficking == null ? '' : information.have_assisted_of_individual_who_trafficking
                        this.do_you_engage_espionage = information.do_you_engage_espionage == null ? '' : information.do_you_engage_espionage
                        this.do_you_engage_terrorist_activities = information.do_you_engage_terrorist_activities == null ? '' : information.do_you_engage_terrorist_activities
                        this.have_provide_financial_to_terrorists = information.have_provide_financial_to_terrorists == null ? '' : information.have_provide_financial_to_terrorists
                        this.are_you_member_terrorist = information.are_you_member_terrorist == null ? '' : information.are_you_member_terrorist
                        this.are_you_son_of_individual_who_engaged_terrorist = information.are_you_son_of_individual_who_engaged_terrorist == null ? '' : information.are_you_son_of_individual_who_engaged_terrorist
                        this.have_committed_genocide = information.have_committed_genocide == null ? '' : information.have_committed_genocide
                        this.have_committed_torture = information.have_committed_torture == null ? '' : information.have_committed_torture
                        this.have_committed_extrajudicial_killings = information.have_committed_extrajudicial_killings == null ? '' : information.have_committed_extrajudicial_killings
                        this.have_engaged_recruitment = information.have_engaged_recruitment == null ? '' : information.have_engaged_recruitment
                        this.have_serving_government_official = information.have_serving_government_official == null ? '' : information.have_serving_government_official
                        this.have_involved_in_population_controls = information.have_involved_in_population_controls == null ? '' : information.have_involved_in_population_controls
                        this.have_involved_in_coercive_transplantation = information.have_involved_in_coercive_transplantation == null ? '' : information.have_involved_in_coercive_transplantation
                        this.have_been_deportation_hearing = information.have_been_deportation_hearing == null ? '' : information.have_been_deportation_hearing
                        this.have_assist_others_to_obtain_visa = information.have_assist_others_to_obtain_visa == null ? '' : information.have_assist_others_to_obtain_visa
                        this.have_failed_to_attend_hearing = information.have_failed_to_attend_hearing == null ? '' : information.have_failed_to_attend_hearing
                        this.have_present_amount_time_granted = information.have_present_amount_time_granted == null ? '' : information.have_present_amount_time_granted
                        this.have_removed_from_any_country = information.have_removed_from_any_country == null ? '' : information.have_removed_from_any_country
                        this.have_custody_us_citizen = information.have_custody_us_citizen == null ? '' : information.have_custody_us_citizen
                        this.have_voted_in_us_violation = information.have_voted_in_us_violation == null ? '' : information.have_voted_in_us_violation
                        this.have_renounced_us_citizenship = information.have_renounced_us_citizenship == null ? '' : information.have_renounced_us_citizenship
                        this.have_attended_public_elementary_school = information.have_attended_public_elementary_school == null ? '' : information.have_attended_public_elementary_school

                        this.authorization = 'si'
                    }
                    //
                    getProgramSeasonParticipant(this.$route.params.IdProgramSeasonParticipant)
                        .then(({data}) => {
                            this.programSeasonParticipant = data.data
                        })
                        .finally(() => {
                              this.fillDataloading = false
                        })
                })
                .catch(err => {
                    console.log('esrr: ', err);
                })
                .finally(() => {
                    // this.fillDataloading = false
                })
        },

        programSeasonEvaluation () {
            // let IdProgramSeason = this.$route.params.IdProgramSeason
            switch (this.IdProgram.toString()) {
                case '1':
                    this.program_name = 'Work and Travel'
                    this.program_email = 'workandtravel@aupairkids.com.pe'
                    this.program_color = 'primary'
                    break;
                case '2':
                    this.program_name = 'Internship'
                    this.program_email = 'internship@aupairkids.com.pe'
                    this.program_color = 'cielo'
                    break;
                case '3':
                    this.program_name = 'Trainee'
                    this.program_email = 'internship@aupairkids.com.pe'
                    this.program_color = 'cielo'
                    break;
                case '4':
                    this.program_name = 'Aupair'
                    this.program_email = 'info@aupairkids.com.pe'
                    this.program_color = 'naranja'
                    break;
                case '5':
                    this.program_name = 'Teach'
                    this.program_email = 'info@aupairkids.com.pe'
                    this.program_color = 'magenta'
                    break;
                default:
                    console.log('break', this.IdProgram);
                    break;
            }
        },

        fillFicha () {
            console.log('fill ficha!!: ', this.name);
            this.fillDataloading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant  // ok

            let InfoForm = new FormData()
            InfoForm.append('name', this.name)
            InfoForm.append('p_last_name', this.p_last_name)
            InfoForm.append('m_last_name', this.m_last_name)
            InfoForm.append('date_birth', this.date_birth)
            InfoForm.append('age', this.age)
            InfoForm.append('city_birth', this.city_birth)
            InfoForm.append('country_birth', this.country_birth)
            InfoForm.append('document_number', this.document_number)
            InfoForm.append('sex', this.sex)
            InfoForm.append('marital_status', this.marital_status)
            InfoForm.append('other_nationality', this.other_nationality)
            InfoForm.append('other_permanent_resident', this.other_permanent_resident)
            InfoForm.append('address', this.address)
            InfoForm.append('distric', this.distric)
            InfoForm.append('province', this.province)
            InfoForm.append('department', this.department)
            InfoForm.append('zip_code', this.zip_code)
            InfoForm.append('country', this.country)

            InfoForm.append('sm_plataform_one', this.sm_plataform_one)
            InfoForm.append('sm_identifier_one', this.sm_identifier_one)
            InfoForm.append('sm_plataform_two', this.sm_plataform_two)
            InfoForm.append('sm_identifier_two', this.sm_identifier_two)

            InfoForm.append('passport_number', this.passport_number)
            if(this.passport_image != null) InfoForm.append('passport_image', this.passport_image)
            InfoForm.append('passport_emit_date', this.passport_emit_date)
            InfoForm.append('passport_expiration_date', this.passport_expiration_date)
            InfoForm.append('passport_city_issued', this.passport_city_issued)
            InfoForm.append('passport_state_issued', this.passport_state_issued)
            InfoForm.append('passport_country_issued', this.passport_country_issued)
            InfoForm.append('passport_lost', this.passport_lost)
            InfoForm.append('immigration_appointment_date', this.immigration_appointment_date)

            InfoForm.append('land_line', this.land_line)
            InfoForm.append('phone_number', this.phone_number)
            InfoForm.append('email', this.email)
            InfoForm.append('profile_link', this.profile_link)
            InfoForm.append('skype_id', this.skype_id)
            InfoForm.append('father_full_name', this.father_full_name)
            InfoForm.append('father_date_birth', this.father_date_birth)
            InfoForm.append('father_cell_phone', this.father_cell_phone)
            InfoForm.append('father_email', this.father_email)
            InfoForm.append('father_us', this.father_us)
            InfoForm.append('mother_full_name', this.mother_full_name)
            InfoForm.append('mother_date_birth', this.mother_date_birth)
            InfoForm.append('mother_cell_phone', this.mother_cell_phone)
            InfoForm.append('mother_email', this.mother_email)
            InfoForm.append('mother_us', this.mother_us)

            InfoForm.append('have_illegal_parents_in_us', this.have_illegal_parents_in_us)
            InfoForm.append('illegal_parents', this.illegal_parents)
            InfoForm.append('have_immediate_relatives_in_us', this.have_immediate_relatives_in_us)
            InfoForm.append('have_other_relatives_in_us', this.have_other_relatives_in_us)

            InfoForm.append('paying_trip_person', this.paying_trip_person)
            InfoForm.append('paying_trip_phone', this.paying_trip_phone)
            InfoForm.append('paying_trip_email', this.paying_trip_email)
            InfoForm.append('paying_trip_relationship', this.paying_trip_relationship)

            InfoForm.append('emer_contact_relationship', this.emer_contact_relationship)
            InfoForm.append('emer_contact_fullname', this.emer_contact_fullname)
            InfoForm.append('emer_contact_cellphone', this.emer_contact_cellphone)

            InfoForm.append('university_institute', this.university_institute)
            InfoForm.append('u_i_otro', this.u_i_otro)
            InfoForm.append('university_institute_city', this.university_institute_city)
            InfoForm.append('u_i_c_otro', this.u_i_c_otro)
            InfoForm.append('university_institute_address', this.university_institute_address)
            InfoForm.append('university_institute_department', this.university_institute_department)
            InfoForm.append('university_institute_zip_code', this.university_institute_zip_code)
            InfoForm.append('university_institute_country', this.university_institute_country)
            InfoForm.append('university_institute_phone', this.university_institute_phone)
            InfoForm.append('career', this.career)
            InfoForm.append('current_semester', this.current_semester)
            InfoForm.append('career_end_date', this.career_end_date)
            InfoForm.append('vacation_start_date', this.vacation_start_date)
            InfoForm.append('vacation_end_date', this.vacation_end_date)
            
            InfoForm.append('have_ed_secondary', this.have_ed_secondary)
            InfoForm.append('ed_s_institute', this.ed_s_institute)
            InfoForm.append('ed_s_address', this.ed_s_address)
            InfoForm.append('ed_s_city', this.ed_s_city)
            InfoForm.append('ed_s_department', this.ed_s_department)
            InfoForm.append('ed_s_zip_code', this.ed_s_zip_code)
            InfoForm.append('ed_s_country', this.ed_s_country)
            InfoForm.append('ed_s_course', this.ed_s_course)
            InfoForm.append('ed_s_date_from', this.ed_s_date_from)
            InfoForm.append('ed_s_date_to', this.ed_s_date_to)

            InfoForm.append('have_traveled_any_countries_last_five_years', this.have_traveled_any_countries_last_five_years)
            InfoForm.append('provide_list_countries_visited', this.provide_list_countries_visited)
            InfoForm.append('have_belong_charitable_org', this.have_belong_charitable_org)
            InfoForm.append('hace_specialized_skills', this.hace_specialized_skills)
            InfoForm.append('have_served_in_military', this.have_served_in_military)
            InfoForm.append('have_served_in_paramilitary', this.have_served_in_paramilitary)

            InfoForm.append('how_found_program', this.how_found_program)
            InfoForm.append('h_f_p_otro', this.h_f_p_otro)
            InfoForm.append('how_found_program_friend', this.how_found_program_friend)
            InfoForm.append('work_experience_one_company', this.work_experience_one_company)
            InfoForm.append('work_experience_one_position', this.work_experience_one_position)
            InfoForm.append('work_experience_one_address', this.work_experience_one_address)
            InfoForm.append('work_experience_one_city', this.work_experience_one_city)
            InfoForm.append('work_experience_one_state', this.work_experience_one_state)
            InfoForm.append('work_experience_one_zip_code', this.work_experience_one_zip_code)
            InfoForm.append('work_experience_one_country', this.work_experience_one_country)
            InfoForm.append('work_experience_one_phone', this.work_experience_one_phone)
            InfoForm.append('work_experience_one_sup_fullname', this.work_experience_one_sup_fullname)
            InfoForm.append('work_experience_one_from', this.work_experience_one_from)
            InfoForm.append('work_experience_one_to', this.work_experience_one_to)
            InfoForm.append('work_experience_one_duties', this.work_experience_one_duties)

            InfoForm.append('work_experience_two_company', this.work_experience_two_company)
            InfoForm.append('work_experience_two_position', this.work_experience_two_position)
            InfoForm.append('work_experience_two_address', this.work_experience_two_address)
            InfoForm.append('work_experience_two_city', this.work_experience_two_city)
            InfoForm.append('work_experience_two_state', this.work_experience_two_state)
            InfoForm.append('work_experience_two_zip_code', this.work_experience_two_zip_code)
            InfoForm.append('work_experience_two_country', this.work_experience_two_country)
            InfoForm.append('work_experience_two_phone', this.work_experience_two_phone)
            InfoForm.append('work_experience_two_sup_fullname', this.work_experience_two_sup_fullname)
            InfoForm.append('work_experience_two_from', this.work_experience_two_from)
            InfoForm.append('work_experience_two_to', this.work_experience_two_to)
            InfoForm.append('work_experience_two_duties', this.work_experience_two_duties)

            InfoForm.append('work_experience_total_time', this.work_experience_total_time)
            InfoForm.append('w_e_t_t_otro', this.w_e_t_t_otro)
            InfoForm.append('type_of_work', this.type_of_work)
            InfoForm.append('t_o_w_otro', this.t_o_w_otro)

            InfoForm.append('have_ever_been_in_us', this.have_ever_been_in_us)
            InfoForm.append('us_date_arrived_one', this.us_date_arrived_one)
            InfoForm.append('us_length_stay_one', this.us_length_stay_one)
            InfoForm.append('us_date_arrived_two', this.us_date_arrived_two)
            InfoForm.append('us_length_stay_two', this.us_length_stay_two)
            InfoForm.append('us_date_arrived_three', this.us_date_arrived_three)
            InfoForm.append('us_length_stay_three', this.us_length_stay_three)
            InfoForm.append('hold_us_driver_license', this.hold_us_driver_license)

            InfoForm.append('visa_denied_why', this.visa_denied_why)
            InfoForm.append('had_american_visa', this.had_american_visa)

            InfoForm.append('previously_traveled_program', this.previously_traveled_program)
            InfoForm.append('agency_name', this.agency_name)
            InfoForm.append('why_change_agency', this.why_change_agency)
            InfoForm.append('company_did_work', this.company_did_work)
            InfoForm.append('company_state_city', this.company_state_city)
            InfoForm.append('company_position', this.company_position)
            InfoForm.append('company_hourly_wage', this.company_hourly_wage)
            InfoForm.append('ssn', this.ssn)

            InfoForm.append('last_visa_number', this.last_visa_number)
            InfoForm.append('last_visa_emit_date', this.last_visa_emit_date)
            InfoForm.append('last_visa_expiration_date', this.last_visa_expiration_date)
            InfoForm.append('applying_same_visa', this.applying_same_visa)
            InfoForm.append('applying_same_country', this.applying_same_country)
            InfoForm.append('have_been_ten_printed', this.have_been_ten_printed)
            InfoForm.append('has_visa_lost', this.has_visa_lost)
            InfoForm.append('has_visa_revoked', this.has_visa_revoked)
            InfoForm.append('has_visa_refused', this.has_visa_refused)
            InfoForm.append('has_immigrant_petition', this.has_immigrant_petition)
            
            InfoForm.append('have_additional_contact_information', this.have_additional_contact_information)
            InfoForm.append('aci_one_fullname', this.aci_one_fullname)
            InfoForm.append('aci_one_address', this.aci_one_address)
            InfoForm.append('aci_one_city', this.aci_one_city)
            InfoForm.append('aci_one_department', this.aci_one_department)
            InfoForm.append('aci_one_zip_code', this.aci_one_zip_code)
            InfoForm.append('aci_one_country', this.aci_one_country)
            InfoForm.append('aci_one_phone', this.aci_one_phone)
            InfoForm.append('aci_one_email', this.aci_one_email)
            InfoForm.append('aci_two_fullname', this.aci_two_fullname)
            InfoForm.append('aci_two_address', this.aci_two_address)
            InfoForm.append('aci_two_city', this.aci_two_city)
            InfoForm.append('aci_two_department', this.aci_two_department)
            InfoForm.append('aci_two_zip_code', this.aci_two_zip_code)
            InfoForm.append('aci_two_country', this.aci_two_country)
            InfoForm.append('aci_two_phone', this.aci_two_phone)
            InfoForm.append('aci_two_email', this.aci_two_email)

            InfoForm.append('have_communicable_disease_health', this.have_communicable_disease_health)
            InfoForm.append('have_mental_disorder', this.have_mental_disorder)
            InfoForm.append('have_been_drug_abuser', this.have_been_drug_abuser)
            InfoForm.append('have_been_arrested', this.have_been_arrested)
            InfoForm.append('have_been_violated', this.have_been_violated)
            InfoForm.append('coming_united_engage_in_prostitution', this.coming_united_engage_in_prostitution)
            InfoForm.append('have_been_involved_money_laundering', this.have_been_involved_money_laundering)
            InfoForm.append('have_committed_human_trafficking', this.have_committed_human_trafficking)
            InfoForm.append('are_you_son_of_individual_who_trafficking', this.are_you_son_of_individual_who_trafficking)
            InfoForm.append('have_assisted_of_individual_who_trafficking', this.have_assisted_of_individual_who_trafficking)
            InfoForm.append('do_you_engage_espionage', this.do_you_engage_espionage)
            InfoForm.append('do_you_engage_terrorist_activities', this.do_you_engage_terrorist_activities)
            InfoForm.append('have_provide_financial_to_terrorists', this.have_provide_financial_to_terrorists)
            InfoForm.append('are_you_member_terrorist', this.are_you_member_terrorist)
            InfoForm.append('are_you_son_of_individual_who_engaged_terrorist', this.are_you_son_of_individual_who_engaged_terrorist)
            InfoForm.append('have_committed_genocide', this.have_committed_genocide)
            InfoForm.append('have_committed_torture', this.have_committed_torture)
            InfoForm.append('have_committed_extrajudicial_killings', this.have_committed_extrajudicial_killings)
            InfoForm.append('have_engaged_recruitment', this.have_engaged_recruitment)
            InfoForm.append('have_serving_government_official', this.have_serving_government_official)
            InfoForm.append('have_involved_in_population_controls', this.have_involved_in_population_controls)
            InfoForm.append('have_involved_in_coercive_transplantation', this.have_involved_in_coercive_transplantation)
            InfoForm.append('have_been_deportation_hearing', this.have_been_deportation_hearing)
            InfoForm.append('have_assist_others_to_obtain_visa', this.have_assist_others_to_obtain_visa)
            InfoForm.append('have_failed_to_attend_hearing', this.have_failed_to_attend_hearing)
            InfoForm.append('have_present_amount_time_granted', this.have_present_amount_time_granted)
            InfoForm.append('have_removed_from_any_country', this.have_removed_from_any_country)
            InfoForm.append('have_custody_us_citizen', this.have_custody_us_citizen)
            InfoForm.append('have_voted_in_us_violation', this.have_voted_in_us_violation)
            InfoForm.append('have_renounced_us_citizenship', this.have_renounced_us_citizenship)
            InfoForm.append('have_attended_public_elementary_school', this.have_attended_public_elementary_school)


            InfoForm.append('authorization', this.authorization)

            fillInformation(InfoForm, IdProgramSeasonParticipant)
                .then(() => {
                    swal('Rellenado correcto!', 'Información guardada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.reloadData()
                                    this.initialInputValues = true
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch((err) => {
                    console.log(err.response.status)
                    console.log(err.response.data)

                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.fillDataloading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
            //
        },

        // MODAL FICHA STATUS
        modalFichaStatusClick () {
            this.participant = { ...this.programSeasonParticipant.attributes }
            this.$bvModal.show('modal-ficha-status')
        },
        modalFichaStatusClose () {
            this.$bvModal.hide('modal-ficha-status')
            this.clearModalInputsAndStates()
        },

        editStatusFicha () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.fichaEditLoading = true

            let IdProgramSeasonParticipant = this.participant.id  // ok

            let StausForm = new FormData()
            StausForm.append('.method', 'put')
            StausForm.append('information_status', this.participant.information_status)

            updateStatusFicha (StausForm, IdProgramSeasonParticipant)
                .then(() => {
                    swal('Actualización correcta!', 'Estado actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$bvModal.hide('modal-ficha-status')
                                    this.clearModalInputsAndStates()
                                    this.getInformation()
                                    break;

                                default:
                                    console.log('editStatus--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editStatus err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.fichaEditLoading = false
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearModalInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.participant = {}
        },

        showImagePreview (e) {
            if (e.target.files.length >= 1) {
                // capturar img del input
                let image = e.target.files[0]
                //crear reader
                let reader = new FileReader()
                // código al cargar la img
                reader.onload = (ee) => {
                    this.passport_image_preview = ee.target.result
                }
                // asignar la img
                reader.readAsDataURL(image)
            }
        },

        //
        reloadData () {
            this.getInformation()
        }
    }

}
</script>