import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getSponsors (pPage) {
    const URI = `${BASE_URL}/api/sponsors?page=${pPage}`
    return axios.get(URI, { headers })
}

function getSponsor (pIdSponsor) {
    const URI = `${BASE_URL}/api/sponsors/${pIdSponsor}`
    return axios.get(URI, { headers })
}

function storeSponsor (pFormData) {
    const URI = `${BASE_URL}/api/sponsors`
    return axios.post(URI, pFormData, { headers })
}

function destroySponsor (pIdSponsor) {
    const URI = `${BASE_URL}/api/sponsors/${pIdSponsor}`
    return axios.delete(URI, { headers })
}

export {
    getSponsors,
    getSponsor,
    storeSponsor,
    destroySponsor
}