<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content apk-nav-tabs">
                <intranet-nav-content-link-exact :link="{ name: 'Documentos', path: { name: 'admin-participants-filter-documents' } }"/>
                <intranet-nav-content-link :link="{ name: 'Ultimos Doc.', path: { name: 'admin-participants-filter-documents-last-uploads' } }"/>
                <intranet-nav-content-link :link="{ name: 'Deudas', path: { name: 'admin-participants-filter-payments' } }"/>
            </div>
        </b-col>

        <b-col>
            <slide-y-down-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>
        </b-col>
    </b-row>

</template>

<script>
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideYDownTransition  } from 'vue2-transitions';

export default {
    name: 'Filters',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideYDownTransition,
    },
    data: () => ({})

}
</script>