<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <!-- <b-button variant="green" size="sm" @click="openModalCreateSponsor">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button> -->

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingdata -->
                <div style="height:250px;" v-show="loadingdata">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- vouchers -->
                <div class="accordion" role="tablist" v-show="!loadingdata">
                    <b-list-group>
                        <b-list-group-item class="py-0" v-for="(invalidVoucher, i) in data" :key="`${i}-iv`">
                            <b-card border-variant="0" no-body>
                                <!-- btn -->
                                <b-card-header role="tab" header-border-variant="0">
                                    <b-row align-h="around">
                                        <b-col>
                                            {{ invalidVoucher.attributes.voucher.attributes.code }} -{{ invalidVoucher.attributes.comments }}
                                        </b-col>

                                        <b-col cols="auto">
                                            <b-button variant="magenta" size="sm" v-b-toggle="`accordion-${i}`">
                                                <b-icon icon="eye"></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-card-header>

                                <!-- collapse -->
                                <b-collapse :id="`accordion-${i}`" role="tabpanel" style="padding:0 24px">
                                    <b-row align-h="center">
                                        <b-col cols="12" md="10" class="mb-5">
                                            <b-card class="shadow">
                                                <b-row>
                                                    <b-col md="4" class="align-self-center">
                                                        <b-card-img
                                                            :src="invalidVoucher.attributes.voucher.attributes.image"
                                                            alt="voucher"
                                                            fluid
                                                            center
                                                            class="apk-voucher border border-magenta"
                                                            style="border: gray 1px solid"
                                                        ></b-card-img>
                                                    </b-col>

                                                    <b-col md="8">
                                                        <b-card-text>
                                                            <b-list-group>
                                                                <b-list-group-item class="text-body-1" variant="magenta">
                                                                    <b-icon icon="bookmark-fill" class="mr-1"></b-icon>
                                                                    {{ invalidVoucher.attributes.voucher.attributes.voucher_concept }}
                                                                </b-list-group-item>

                                                                <b-list-group-item class="text-body-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin mr-1" viewBox="0 0 16 16">
                                                                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                                                                    </svg>
                                                                    {{ invalidVoucher.attributes.voucher.attributes.amount_format }}
                                                                </b-list-group-item>

                                                                <b-list-group-item class="text-body-1" v-show="invalidVoucher.attributes.voucher.attributes.type == 'pago normal'">
                                                                    <b-iconstack class="mr-1">
                                                                        <b-icon stacked icon="square"></b-icon>
                                                                        <b-icon stacked icon="hash"></b-icon>
                                                                    </b-iconstack>
                                                                    {{ invalidVoucher.attributes.voucher.attributes.code }}
                                                                </b-list-group-item>

                                                                <b-list-group-item class="text-body-1">
                                                                    <b-icon icon="file-text" class="mr-1"></b-icon>
                                                                    {{ invalidVoucher.attributes.voucher.attributes.description }}
                                                                </b-list-group-item>

                                                                <b-list-group-item class="text-body-1 text-magenta" >
                                                                    <b-icon
                                                                        icon= 'x-square-fill' class="mr-1"
                                                                    ></b-icon> {{ invalidVoucher.attributes.voucher.attributes.status }}
                                                                </b-list-group-item>

                                                                <b-list-group-item class="text-body-1">
                                                                    <b-icon icon="calendar-fill" class="mr-1"></b-icon>
                                                                    {{ invalidVoucher.attributes.voucher.attributes.date }}
                                                                </b-list-group-item>

                                                                <b-list-group-item class="text-body-1">
                                                                    <b-icon icon="alarm-fill" class="mr-1"></b-icon>
                                                                    {{ invalidVoucher.attributes.voucher.attributes.time }}
                                                                </b-list-group-item>
                                                            </b-list-group>
                                                        </b-card-text>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </b-col>
                                    </b-row>
                                </b-collapse>
                            </b-card>
                        </b-list-group-item>
                    </b-list-group>


                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingdata">
                    <base-pagination
                        align="center"
                        :pageCount="dataMeta.last_page" 
                        :perPage="dataMeta.per_page"
                        :value="dataMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getInvalidVouchers } from '@/api/InvalidVoucher/InvalidVoucher'
// import swal from 'sweetalert'

export default {
    name: "InvalidVoucherList",
    data: () => ({
        data: [],
        dataMeta: {},
        loadingdata: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData (pPage = 1) {
            this.loadingdata = true
            getInvalidVouchers(pPage)
                .then(({ data }) => {
                    this.data = data.data
                    this.dataMeta = data.meta
                })
                .finally(() => {
                    this.loadingdata = false
                })
        },
        paginationCLiked (e) {
            this.getAdminsData(e)
        },
        reloadData () {
            this.getData()
        },

        //
        // deleteSponsor (pSponsor) {
        //     swal('Se eliminará el sponsor', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
        //         .then(value => {
        //             switch (value) {
        //                 case true:
        //                     this.$set(pSponsor, 'deleteLoading', true)
        //                     destroySponsor(pSponsor.attributes.id)
        //                         .then(() => {
        //                             this.getData()
        //                         })
        //                         .finally(() => {
        //                             pSponsor.deleteLoading = false
        //                         })
        //                     break;
        //                 case null:
        //                     break;
        //                 default:
        //                     console.log('delete-sponsor-swal-break');
        //                     break;
        //             }
        //         })
        // }
    }
}
</script>