<template>
    <div>
        <b-button variant="transparent" size="sm" @click="modalClick"> <b-icon icon="pencil" variant="naranja" scale="1.3"></b-icon></b-button>

        <b-modal
            id="modal-update-user-document"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">ACTUALIZAR</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="updateData">
                                <b-form-row>
                                    <!-- document_type -->
                                    <b-col cols="12">
                                        <b-form-group label="Tipo de documento:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select 
                                                v-model="user_data.user_document_type"
                                                :options="documentTypeOptions"
                                                :state="showInputStatus('document_type')"
                                                v-show="!loading"
                                            >
                                            </b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('document_type')"
                                                :key="`${i}-ud-document_type`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- num-doc -->
                                    <b-col cols="12">
                                        <b-form-group label="Número de documento:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="number"
                                                placeholder="Ingrese el número de documento."
                                                v-model="user_data.user_document_number"
                                                :state="showInputStatus('document_number')"
                                                v-show="!loading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('document_number')"
                                                :key="`${i}-ud-document_number`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            ACTUALIZAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { updateProgramSeasonPostulantUserDocument } from '@/api/ProgramSeasonPostulant/UpdateUserDocument'
import swal from 'sweetalert'

export default {
    name: 'UpdateUserDocumentComponent',
    props: {
        userData: {
            type: Object,
            required: true
        },
        IdProgramSeasonPostulant: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        user_data: {},
        loading: false,
        //
        documentTypeOptions: [
            { value: 'extranjeria', text: 'carnet de extranjería' },
            { value: 'dni', text: 'DNI' }
        ],
        //
        inputErrors: {},
        initialInputValues: true
    }),

    methods: {
        modalClick() {
            this.user_data = JSON.parse(JSON.stringify(this.userData))
            this.$bvModal.show('modal-update-user-document')
        },
        modalClose() {
            this.$bvModal.hide('modal-update-user-document')
            this.clearInputsAndStates()
        },

        updateData() {
            this.loading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let Form = new FormData()
            Form.append('.method', 'put')
            Form.append('document_type', this.user_data.user_document_type)
            Form.append('document_number', this.user_data.user_document_number)

            updateProgramSeasonPostulantUserDocument(Form, this.IdProgramSeasonPostulant)
                .then(() => {
                    swal('Actualización correcta!', 'Datos actualizados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.modalClose()
                                    this.$emit('updated')
                                    break;

                                default:
                                    console.log('updateUserData--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                })
                .finally(() => {
                    this.loading = false
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.initialInputValues = true
            this.inputErrors = {}
        },
    }
}
</script>

<style>

</style>