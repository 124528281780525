<template>
    <div>
        <b-button variant="outline-green" size="sm" @click="modalClick"> <b-icon icon="pencil"  scale="1.3" class="mr-2"></b-icon>Cambiar Programa</b-button>

        <b-modal
            id="modal-change-program"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">Cambiar Programa</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="updateData">
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group label="Programa - Temporada">
                                            <pulse-loader
                                                :loading="loading || false || getProgramSeasonOptionsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select 
                                                v-model="IdProgramSeason"
                                                :options="programSeasonOptions"
                                                :state="showInputStatus('programSeason_id')"
                                                v-show="!loading && true && !getProgramSeasonOptionsLoading"
                                            >
                                                <template #first>
                                                    <b-form-select-option value="" disabled>
                                                        -- Seleccione un programa * temporada --
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('programSeason_id')" :key="`${i}-rogramSeason_id`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group label="Comentario:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea
                                                autocomplete="off"
                                                placeholder="Ingrese un cometario."
                                                v-model="comments"
                                                :state="showInputStatus('comments')"
                                                v-show="!loading"
                                                rows="3"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('comments')"
                                                :key="`${i}-post-comments`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            CAMBIAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { getAllProgramSeasons } from '@/api/ProgramSeason/ProgramSeason'
import { changeProgram } from '@/api/ChangeProgram/ChangeProgram'
import swal from 'sweetalert'

export default {
    name: 'ChangeProgramComponent',
    props: {
        IdProgramSeasonPostulant: {
            type: Number,
            required: true
        },
        IdProgramSeasonParticipant: {
            type: Number,
        },
        haveParticipant: {
            type: String,
            required: true
        },
        ProgramSeasonID: {
            type: Number,
            required: true
        },
        Season: {
            type: String,
            required: true
        }
    },
    data: () => ({
        comments: '',
        loading: false,
        //
        inputErrors: {},
        initialInputValues: true,
        //
        programSeasonOptions: [],
        IdProgramSeason: '',
        getProgramSeasonOptionsLoading: false,
    }),

    methods: {
        modalClick() {
            this.clearInputsAndStates()
            this.getProgramSeasonsData()
            this.$bvModal.show('modal-change-program')
        },
        modalClose() {
            this.$bvModal.hide('modal-change-program')
            this.clearInputsAndStates()
        },

        updateData() {
            swal('Se cambiará de programa.', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, cambiar.']})
                .then(value => {
                    let Form = new FormData()

                    switch (value) {
                        case true:
                            this.loading = true
                            this.initialInputValues = false
                            this.inputErrors = {}
                            Form.append('programSeasonPostulant_id', this.IdProgramSeasonPostulant)
                            Form.append('have_participant', this.haveParticipant)
                            Form.append('programSeasonParticipant_id', this.IdProgramSeasonParticipant ? this.IdProgramSeasonParticipant : '')
                            Form.append('comments', this.comments)
                            Form.append('programSeason_id', this.IdProgramSeason)

                            changeProgram(Form)
                                .then(() => {
                                    swal('Programa Cambiado!!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.modalClose()
                                                    this.$emit('changed')
                                                    break;

                                                default:
                                                    console.log('change-program--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .catch(err => {
                                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;

                        case null:
                            this.modalClose()
                            break;

                        default:
                            console.log('change-alert-break');
                            break;
                    }
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.comments = ''
            this.initialInputValues = true
            this.inputErrors = {}
            //
            this.programSeasonOptions = []
            this.IdProgramSeason = ''
            this.getProgramSeasonOptionsLoading = false
        },

         // programSeason
        getProgramSeasonsData () {
            this.getProgramSeasonOptionsLoading = true

            getAllProgramSeasons()
                .then(({data}) => {
                    this.programSeasonOptions = data.data
                                                    .filter(el => el.attributes.id != this.ProgramSeasonID)
                                                    .map(el => ({ value: el.attributes.id, text: `${el.attributes.program_name} * ${el.attributes.season_name}` }))
                })
                .finally(() => {
                    this.getProgramSeasonOptionsLoading = false
                })
        },
    }
}
</script>