<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalCreateSponsor">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Sponsor</th>
                            <th>Descripción</th>
                            <th class="text-center">templates</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                            <td>{{ row.attributes.name }}</td>
                            <td>{{ row.attributes.description }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: routeName,
                                        params: { 'IdSponsor': row.id }    //IdSponsor
                                    }"
                                    variant="transparent"
                                    size="sm"
                                >
                                    <b-icon icon="card-list" scale="1.5"></b-icon>
                                </b-btn>
                            </td>

                            <td class="text-center">
                                <div v-show="row.attributes.programSeasonSponsors.length == 0 && row.attributes.sponsorJobOffers.length == 0">
                                    <pulse-loader
                                        :loading="row.deleteLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'2px'"
                                        :size="8"
                                        class="pt-2 pb-1"
                                    ></pulse-loader>

                                    <b-btn variant="transparent" size="sm" 
                                        v-show="!row.deleteLoading && true"
                                        @click="deleteSponsor(row)"
                                    ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                    </b-btn>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>

         <!-- modal create-->
        <b-modal
                id="modal-create-sponsor"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    Crear Sponsor
                                </h3>
                            </b-col>

                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalCreateSponsor">
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <b-form @submit.prevent="createSponsor">
                            <b-form-row>
                                <!-- template_name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Template name :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el nombre del sponsor."
                                            v-model="name"
                                            :state="showInputStatus('name')"
                                            v-if="!createLoading"
                                        ></b-form-input>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('name')"
                                            :key="`${i}-name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-textarea
                                            rows="3"
                                            placeholder="Ingrese una descripción(opcional)"
                                            v-model="description"
                                            :state="showInputStatus('description')"
                                            v-if="!createLoading"
                                        ></b-form-textarea>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputStatus('description')"
                                            :key="`${i}-description`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- btn actualizar -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto" class="py-0">
                                    <b-button
                                        class="w-100 my-2"
                                        variant="primary"
                                        type="submit"
                                        size="md"
                                        :disabled="createLoading"
                                    >Crear</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getSponsors, storeSponsor, destroySponsor } from '@/api/Sponsor/Sponsor';
import swal from 'sweetalert'

export default {
    name: "SponsorListComponent",
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        createLoading: false,
        name: '',
        description: '',
        //
        initialInputValues: true,
        inputErrors: {},
    }),
    props: {
        routeName: {
            type: String
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData (pPage = 1) {
            this.loadingTableData = true

            getSponsors(pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('get-sponsors request end');
                })
        },
        paginationCLiked (e) {
            this.getAdminsData(e)
        },
        reloadData () {
            this.getData()
        },

        // modal
        openModalCreateSponsor () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-create-sponsor')
        },

        closeModalCreateSponsor () {
            this.$bvModal.hide('modal-create-sponsor')
            this.clearInputsAndStates()
        },

        createSponsor () {
            this.createLoading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let SponsorForm = new FormData()
            SponsorForm.append('name', this.name)
            SponsorForm.append('description', this.description)

            storeSponsor(SponsorForm)
                .then(() => {
                    swal('Creación correcta!', 'sponsor creado', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalCreateSponsor()
                                    this.getData()
                                    break;

                                default:
                                    console.log('create-sponsor-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.inputErrors = {}
            this.initialInputValues = true

            this.name = ''
            this.description = ''
        },

        //
        deleteSponsor (pSponsor) {
            swal('Se eliminará el sponsor', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pSponsor, 'deleteLoading', true)
                            destroySponsor(pSponsor.attributes.id)
                                .then(() => {
                                    this.getData()
                                })
                                .finally(() => {
                                    pSponsor.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-sponsor-swal-break');
                            break;
                    }
                })
        }
    }
}
</script>