import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function storeSponsorInterview (pFormData) {
    const URI = `${BASE_URL}/api/sponsorInterviews`
    return axios.post(URI, pFormData, { headers })
}

function updateSponsorInterview (pFormData, pIdSponsorInterview) {
    const URI = `${BASE_URL}/api/sponsorInterviews/${pIdSponsorInterview}`
    return axios.post(URI, pFormData, { headers })
}

function destroySponsorInterview (pIdSponsorInterview) {
    const URI = `${BASE_URL}/api/sponsorInterviews/${pIdSponsorInterview}`
    return axios.delete(URI, { headers })
}

function updateResultsSponsorInterview (pFormData, pIdSponsorInterview) {
    const URI = `${BASE_URL}/api/sponsorInterview/${pIdSponsorInterview}/update-results`
    return axios.post(URI, pFormData, { headers })
}

export {
    storeSponsorInterview,
    updateSponsorInterview,
    destroySponsorInterview,
    updateResultsSponsorInterview
}