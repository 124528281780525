<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Charlas Agendadas
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>link</th>
                            <th>Materiales</th>
                            <th>Charla</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Capacitador</th>
                            <th>Estado</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                             <td>{{ index + 1 }}</td>

                            <td>
                                <b-btn
                                    :href="row.attributes.link" target="_blanck"
                                    size="sm"
                                    variant="primary"
                                ><b-icon icon="link" variant="white"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <b-btn
                                    @click="modalShowMaterialsClick(row.attributes.materials)"
                                    size="sm"
                                    variant="cielo"
                                    :disabled="row.attributes.materials.length == 0"
                                ><b-icon icon="files" variant="white"></b-icon>
                                </b-btn>
                            </td>

                            <td>{{ row.attributes.subject }}</td>
                            <td>{{ row.attributes.start_date }}</td>
                            <td>{{ row.attributes.start_time }} a {{ row.attributes.end_time }}</td>

                            <td>
                                {{ row.attributes.culturalAdvisor_full_name }}
                            </td>

                            <td :class="row.attributes.schedule_status == 'pendiente' ? 'text-yellow' : 'text-green'">
                                {{ row.attributes.schedule_status }}</td>

                            <td>
                                <b-button size="sm" @click="deleteTalkScheduleParticipant(row.attributes)">
                                    <pulse-loader :color="'#EF4A81'" :margin="'2px'" :size="8" v-if="row.attributes.deleteLoading"/>
                                    <b-icon icon="trash" variant="danger" v-else></b-icon>
                                </b-button>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!--
            modal show material
        -->
        <b-modal
            id="modal-show-talks"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">MATERIALES</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalShowMaterialsClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-card no-body>
                        <!-- table -->
                        <div class="table-responsive" v-show="!loadingTableData">
                            <base-table class="table align-items-center table-flush"
                                thead-classes="thead-light"
                                tbody-classes="list"
                                :data="moreMaterials"
                            >
                                <template slot="columns">
                                    <th>#</th>
                                    <th>Material</th>
                                    <th>Nombre</th>
                                    <th>link_compartido</th>
                                    <th>Descripción</th>
                                    <th></th>
                                </template>

                                <template slot-scope="{row, index}">
                                    <td>{{ index + 1 }}</td>

                                    <td>
                                        <b-btn
                                            :href="row.attributes.file" target="_blanck"
                                            size="sm"
                                            :variant="row.attributes.file ? 'cielo' : 'light'"
                                        ><b-icon icon="file-text" variant="white"></b-icon>
                                        </b-btn>
                                    </td>

                                    <td>{{ row.attributes.name }}</td>

                                    <td>
                                        <b-btn
                                            :href="row.attributes.shared_link" target="_blanck"
                                            size="sm"
                                            :variant="row.attributes.shared_link ? 'primary' : 'light'"
                                        ><b-icon icon="link" variant="white"></b-icon>
                                        </b-btn>
                                    </td>

                                    <td>{{ row.attributes.description }}</td>

                                    <td>
                                    </td>
                                </template>
                            </base-table>
                        </div>
                    </b-card>
                </b-container>
            </template>
        </b-modal>

        <!-- pendiente edit morematerial and capacitation -->

        <!-- pendiente edit 
            modal document edit
        -->
        <b-modal
            id="modal-document-edit"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDITAR DOCUMENTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalDocumentEditClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- resource form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editDocument">
                                <!-- group_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Grupo: "
                                        >
                                            <b-form-input
                                                type="text"
                                                v-model="documentSelected.group_name"
                                                disabled
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                <!-- name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre: "
                                        >
                                            <b-form-input
                                                type="text"
                                                v-model="documentSelected.name"
                                                disabled
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- file -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Archivo: "
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-file
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!documentEditLoading"
                                                style="overflow:hidden;"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-create-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- status -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Estado:" 
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="documentSelected.status"
                                                :options="statusOptions"
                                                :state="showModalInputStatus('estado')"
                                                v-show="!documentEditLoading"
                                            ></b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('estado')"
                                                :key="`${i}-updateVoucher-status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="documentSelected.participantDocumentDescription"
                                                :state="showModalInputStatus('description')"
                                                v-show="!documentEditLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="documentEditLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
// programSeasonParticipant.capacitationSchedule
import { getTalkSchedules } from '@/api/ProgramSeasonParticipant/TalksSchedules';
import { updateParticipantDocument } from '@/api/ParticipantDocument/ParticipantDocument'
import swal from 'sweetalert'
import { destroyTalkScheduleParticipant } from '@/api/TalkScheduleParticipant/TalkScheduleParticipant'

export default {
    name: "Talks",
    data: () => ({
        // table 
        tableData: [],
        loadingTableData: false,
        //
        moreMaterials: [],
        // modal edit form
        documentSelected: {},
        statusOptions: [
            { value: 'verde', text: 'verificado' },
            { value: 'amarillo', text: 'pendiente' },
            { value: 'rojo', text: 'no válido' },
        ],
        file : null,
        //
        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        documentEditLoading: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.loadingTableData = true

            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant  // ok

            getTalkSchedules(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .catch( err => {
                    console.log('getCapacitationSchedule Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getCapacitationSchedule request end');
                })
        },

        modalShowMaterialsClick (pMaterials) {
            this.moreMaterials = pMaterials.map(el => el)
            this.$bvModal.show('modal-show-talks')
        },
        modalShowMaterialsClose () {
            this.$bvModal.hide('modal-show-talks')
            this.clearInputsAndStates()
        },

        // Modal edit Methods
        editDocument () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.documentEditLoading = true

            let IdProgramDocument = this.documentSelected.id  // ok

            let DocumentForm = new FormData()
            DocumentForm.append('.method', 'put')
            DocumentForm.append('file', this.file)
            DocumentForm.append('estado', this.documentSelected.status)
            DocumentForm.append('description', this.documentSelected.participantDocumentDescription == null ? '' : this.documentSelected.participantDocumentDescription)

            updateParticipantDocument(DocumentForm, IdProgramDocument)
                .then(({ data }) => {
                    // console.log('editDocument res: ', data.data);
                    console.log('editDocument res: ', data);
                    swal('Actualización correcta!', 'Documento actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$bvModal.hide('modal-document-edit')
                                    this.clearInputsAndStates()
                                    this.getData()
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editDocument err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.documentEditLoading = false
                    console.log('request editDocument end');
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.moreMaterials = []
            //
            this.documentSelected = {}
            this.participant = {}
            this.file = null
        },

        // DELETE
        deleteTalkScheduleParticipant (pTalkSchedule) {
            swal('Se eliminará al participante de la charla.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                    .then(value => {
                        // buscar participante
                        let IdTalkScheduleParticipant
                        for (const el of pTalkSchedule.talkScheduleParticipants) {
                            if (el.attributes.programSeasonParticipant_id == this.$route.params.IdProgramSeasonParticipant) {
                                IdTalkScheduleParticipant = el.attributes.id
                            }
                        }

                        switch (value) {
                            case true:
                                this.$set(pTalkSchedule, 'deleteLoading', true)
                                destroyTalkScheduleParticipant(IdTalkScheduleParticipant)
                                    .then(() => {
                                         swal('Se eliminó al participante!', 'Eliminado', 'success')
                                            .then(value => {
                                                switch (value) {
                                                    case true:
                                                    case null:
                                                        this.getData()
                                                        break;
                                                    default:
                                                        console.log('delete-talk-swal-break');
                                                        break;
                                                }
                                            })
                                    })
                                    .catch(err => {
                                        if(err.response) swal('Error!', err.response.status + ' : ' + err.response.data.message, 'warning')
                                    })
                                    .finally(() => {
                                        pTalkSchedule.deleteLoading = false
                                    })
                                break;
                            case null:
                                break;
                            default:
                                console.log('delete-talk-swal-break');
                                break;
                        }
                    })
        },

        reloadData () {
            this.getData()
        }
    }
}
</script>