<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                LISTA
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="searchCulturalAdvisorParticipant(1)"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="getSeasonOptionsLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <!-- input to search -->
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchCulturalAdvisorParticipant(1)">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col >
                                        <b-form-group class="mb-0">
                                            <b-button class="w-100" size="sm" variant="primary" type="submit" :disabled="getSeasonOptionsLoading || loadingTableData">
                                                <b-icon icon="search" class="m-0 p-0"/>
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>

                        <!-- document G1 status -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3 mt-sm-0">
                            <b-form-group class="mb-0 text-primary"
                                label="Estado grupo uno" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    v-model="group_one_state"
                                    :options="[{ value: 'todos', text: 'Todos' }, {value: 'presentado', text:'Presentado'}, {value: 'no presentado', text:'No presentado'}]"
                                    v-show="!loadingTableData"
                                    size="sm"
                                    @change="searchCulturalAdvisorParticipant(1)"
                                />
                            </b-form-group>
                        </b-col>

                        <!-- order_by -->
                        <b-col cols="auto" class="mt-3 mt-lg-0">
                            <b-button size="sm" :variant="order_type != 'created' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('created')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-numeric-down-alt" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98z"/>
                                    <path d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'a-z' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('a-z')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                                    <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'z-a' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('z-a')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-alpha-down-alt" viewBox="0 0 16 16">
                                    <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V7z"/>
                                    <path fill-rule="evenodd" d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371h-1.781zm1.57-.785L11 9.688h-.047l-.652 2.156h1.351z"/>
                                    <path d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>
                        </b-col>

                        <!-- employeer : cultural advisor options -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3">
                            <b-form-group class="mb-0 text-primary"
                                label="Asesor Cultural" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="getSeasonOptionsLoading || employeersOptionsLoading || loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    v-model="employerSelected"
                                    :options="employeersOptions"
                                    v-show="!getSeasonOptionsLoading && !employeersOptionsLoading && !loadingTableData"
                                    size="sm"
                                    @change="searchCulturalAdvisorParticipant(1)"
                                    :disabled="ui == 'employee'"
                                >
                                    <template #first v-if="ui == 'admin'">
                                        <b-form-select-option value="" disabled>
                                            -- Seleccione un asesor cultural --
                                        </b-form-select-option>
                                    </template>>
                                </b-form-select>
                            </b-form-group>
                        </b-col>

                        <!-- pagination -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3">
                            <b-form-group class="mb-0 text-primary"
                                label="Paginación" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                        class="text-center"
                                    v-model="paginated_by"
                                    :options="[{ value: 'todos', text: 'Todos' }, {value: 10, text: 10}, {value: 30, text: 30}, {value: 50, text: 50}]"
                                    v-show="!loadingTableData"
                                    size="sm"
                                    @change="searchCulturalAdvisorParticipant(1)"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive mb-0" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="alwaysShow details"></th>
                            <th class="alwaysShow name">Participante</th>
                            <th>Respons. Cultural</th>
                            <th>programa</th>
                            <th>temporada</th>

                            <!-- documentos grupo uno -->
                            <th class="text-center bg-naranja text-white" v-for="(g1, i) in programDocumentsSorted.group_one" :key="`${i}-g1`">{{ g1.name }}</th>
                            <th class="text-center bg-naranja text-white"><div>grupo uno</div><div>estado</div></th>
                            <th class="text-center bg-naranja text-white"><div>grupo uno</div><div>comentarios</div></th>
                            <th class="text-center bg-naranja text-white"></th>

                            <!-- documentos grupo dos -->
                            <th class="text-center bg-cielo text-white" v-for="(g2, i) in programDocumentsSorted.group_two" :key="`${i}-g2`">{{ g2.name }}</th>
                            <th class="text-center bg-cielo text-white"><div>grupo dos</div><div>estado</div></th>
                            <th class="text-center bg-cielo text-white"><div>grupo dos</div><div>comentarios</div></th>
                            <th class="text-center bg-cielo text-white"></th>

                            <!-- documentos grupo tres -->
                            <th class="text-center bg-magenta text-white" v-for="(g3, i) in programDocumentsSorted.group_three" :key="`${i}-g3`">{{ g3.name }}</th>
                            <th class="text-center bg-magenta text-white">.</th>

                            <!-- documentos grupo cuatro -->
                            <th class="text-center bg-schedule-english-2 text-white" v-for="(g4, i) in programDocumentsSorted.group_four" :key="`${i}-g4`">{{ g4.name }}</th>
                            <th class="text-center bg-schedule-english-2 text-white">.</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <span v-if="order_type == 'created' && paginated_by != 'todos'">
                                    {{ tableMeta.total - ((tableMeta.per_page * (tableMeta.current_page - 1)) + (index + 1)) + 1 }}
                                </span>
                                <span v-else-if="order_type == 'created' && paginated_by == 'todos'">{{ tableData.length - index }}</span>
                                <span v-else>{{ index + 1 }}</span>
                            </td>

                            <td class="alwaysShow details text-right">
                                  <b-btn 
                                    :to="{
                                        name: routeDetails,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>

                            <td class="alwaysShow name">{{ row.attributes.participant_full_name }}</td>
                            <td>{{ row.attributes.culturalAdvisor_full_name }}</td>
                            <td>{{ row.attributes.program}}</td>
                            <td>{{ row.attributes.season}}</td>

                            <!-- Documentos grupo uno -->
                            <td class="text-center apk-doc-group" v-for="(g1, i) in programDocumentsSorted.group_one" :key="`${i}-g1-field`">
                                <div v-for="(docGroupOne, i) in row.attributes.grupo_uno" :key="`${i}-docGroupOne`">
                                    <div v-if="g1.name == docGroupOne.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGroupOne.attributes.status == 'verde' ? 'green' : (docGroupOne.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGroupOne.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <!-- group-one-status-->
                            <td class="text-center">
                                <pulse-loader
                                    :loading="row.documentEditLoading ? true : false"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #d1d7dc"
                                ></pulse-loader>

                                <b-iconstack font-scale="1.3" class="mr-3"  v-show="!row.documentEditLoading">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="x" scale="1.6" variant="primary" v-if="row.attributes.doc_group_one_status == 'no presentado'"></b-icon>
                                    <b-icon stacked icon="check" scale="1.6" variant="primary" v-else></b-icon>
                                </b-iconstack>

                                <b-form-select style="width:max-content"
                                    v-model="row.attributes.doc_group_one_status"
                                    :options="[{ value: 'presentado', text: 'presentado' }, { value: 'no presentado', text: 'no presentado' }]"
                                    v-show="!row.documentEditLoading"
                                    @change="(e) => editGroupOneStatus(row, e)"
                                ></b-form-select>
                            </td>

                            <td>
                                {{ row.attributes.doc_group_one_comments ? row.attributes.doc_group_one_comments : '...' }}
                            </td>

                            <td class="text-center">
                                  <b-btn 
                                    :to="{
                                        name: routeDocuments,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-naranja"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="naranja"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo dos -->
                            <td class="text-center apk-doc-group" v-for="(g2, i) in programDocumentsSorted.group_two" :key="`${i}-g2-field`">
                                <div v-for="(docGrouptwo, i) in row.attributes.grupo_dos" :key="`${i}-docGrouptwo`">
                                    <div v-if="g2.name == docGrouptwo.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGrouptwo.attributes.status == 'verde' ? 'green' : (docGrouptwo.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGrouptwo.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-center">
                                <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="x" scale="1.6" variant="primary" v-if="row.attributes.doc_group_two_status == 'no presentado'"></b-icon>
                                    <b-icon stacked icon="check" scale="1.6" variant="primary" v-else></b-icon>
                                </b-iconstack>
                            </td>

                            <td>
                                {{ row.attributes.doc_group_two_comments  ? row.attributes.doc_group_two_comments : '...'}}
                            </td>

                            <td class="text-center">
                                  <b-btn 
                                    :to="{
                                        name: routeDocuments,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-cielo"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="cielo"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo tres -->
                            <td class="text-center apk-doc-group" v-for="(g3, i) in programDocumentsSorted.group_three" :key="`${i}-g3-field`">
                                <div v-for="(docGroupthree, i) in row.attributes.grupo_tres" :key="`${i}-docGroupthree`">
                                    <div v-if="g3.name == docGroupthree.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGroupthree.attributes.status == 'verde' ? 'green' : (docGroupthree.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGroupthree.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-center">
                                  <b-btn 
                                    :to="{
                                        name: routeDocuments,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-magenta"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="magenta"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo cuatro -->
                            <td class="text-center apk-doc-group" v-for="(g4, i) in programDocumentsSorted.group_four" :key="`${i}-g4-field`">
                                <div v-for="(docGroupFour, i) in row.attributes.grupo_cuatro" :key="`${i}-docGroupFour`">
                                    <div v-if="g4.name == docGroupFour.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGroupFour.attributes.status == 'verde' ? 'green' : (docGroupFour.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGroupFour.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-center">
                                  <b-btn 
                                    :to="{
                                        name: routeDocuments,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-schedule-english-2"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="schedule-english-2"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-if="!loadingTableData && paginated_by != 'todos'">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
import { getAllActiveCulturalAdvisors } from '@/api/CulturalAdvisor/CulturalAdvisor'
import { getAllProgramDocumentsBySeason } from '@/api/ProgramDocument/ProgramDocument';
import { filterParticipantDocument } from '@/api/ProgramSeasonParticipant/Filters/ParticipantDocument';

import { updateStatusDocument } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant.js'
import swal from 'sweetalert'

export default {
    name: "DocumentsFilterComponent",
    props: {
        ui: {
            type: String,
            required: true
        },
        routeDetails: {
            type: String,
            required: true
        },
        routeDocuments: {
            type: String,
            required: true
        },
        
    },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        input_to_search: '',
        group_one_state: 'presentado',
        order_type: 'created',
        paginated_by: 30,
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
        //
        employeersOptions: [],
        employerSelected: '',
        employeersOptionsLoading: false,
        //
        programDocumentsSorted: []
    }),

    created() {
        this.getSeasonsData()
    },

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        }
    },

    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    // get cultural advisors with status active
                    this.getEmployeers()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getEmployeers() {
            this.employeersOptionsLoading = true

            getAllActiveCulturalAdvisors()
                .then(({ data }) => {
                    this.employeersOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name}) )

                    if (this.ui == 'admin' && this.employerSelected) {
                        this.searchCulturalAdvisorParticipant()
                    }

                    if (this.ui == 'employee') {
                        this.employerSelected = this.culturalAdvisorPermissionData.attributes.typeAdvisor_id
                        this.searchCulturalAdvisorParticipant()
                    }
                })
                .finally(() => {
                    this.employeersOptionsLoading = false
                })
        },

        setDocuments () {
            getAllProgramDocumentsBySeason(this.seasonSelected)
                .then(({data}) => {
                    this.programDocumentsSorted = data
                })
        },


        /**search */
        searchCulturalAdvisorParticipant (pPage = 1) {
            this.loadingTableData = true
            let IdCulturalAdvisor = this.employerSelected   // ok
            let IdSeason = this.seasonSelected

            // get program-document by season
            this.setDocuments()

            let params = {
                culturalAdvisor_id    :  IdCulturalAdvisor,
                season_id             :  IdSeason,

                input_to_search       :  this.input_to_search,
                group_one_state       :  this.group_one_state,
                order_type            :  this.order_type,
                paginated_by          :  this.paginated_by,
            }
            if(this.paginated_by != 'todos') params.page = pPage

            filterParticipantDocument(params)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        paginationCLiked (e) {
            this.searchCulturalAdvisorParticipant(e)
        },

        listOrderBy(pType) {
            this.order_type = pType
            this.searchCulturalAdvisorParticipant()
        },

        reloadData () {
            this.getSeasonsData()
        },

        editGroupOneStatus(pParticipant, e) {
            this.$set(pParticipant, 'documentEditLoading', true)

            let StatusForm = new FormData()
            StatusForm.append('.method', 'put')
            StatusForm.append('doc_group_one_status', e)
            StatusForm.append('doc_group_one_comments', pParticipant.attributes.doc_group_one_comments == null ? '' : pParticipant.attributes.doc_group_one_comments)
            StatusForm.append('doc_group_two_status', pParticipant.attributes.doc_group_two_status)
            StatusForm.append('doc_group_two_comments', pParticipant.attributes.doc_group_two_comments == null ? '' : pParticipant.attributes.doc_group_two_comments)

            updateStatusDocument (StatusForm, pParticipant.attributes.id)
                .then(() => {
                    swal('Actualización correcta!', 'Estados actualizados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    break;
                                default:
                                    console.log('swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        if(e == 'presentado') pParticipant.attributes.doc_group_one_status = 'no presentado'
                        if(e == 'no presentado') pParticipant.attributes.doc_group_one_status = 'presentado'
                    }
                })
                .finally(() => {
                    pParticipant.documentEditLoading = false
                })
        },
    }
}
</script>

<style scoped>
.apk-doc-group {
    position: relative;
}
.apk-doc-dump {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 4px 8px;
    background: #000;
    border-radius: 5px;
    font-size: 10pt;
    color: white;
    opacity: .75;
    transition: .300s ease-in all;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
}
.apk-doc:hover ~ .apk-doc-dump {
    transform: translate(-50%, -50%) scale(1);
}
/*  */
.alwaysShow {
    /* background-color: rgb(94, 114, 228, .05); */
    z-index: 10;
}
@media (min-width: 576px) {
    .alwaysShow {
        position: sticky;
        background-color: #f7f8fe;
        border-bottom: 2px solid #e9ecef !important;
    }
}
.alwaysShow.details {
    left: 0px;
}
.alwaysShow.name {
    left: 80px;
}
</style>