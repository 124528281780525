<template>
    <!-- nvl 1 -->
    <intranet-dashboard :ui="'participant'">
        <template slot="routes"> 
            <sidebar-item-exact :link="{ name: 'Dashboard', icon: 'display', color: 'magenta', path: { name: 'participant-dashboard' } }"/>
            <sidebar-item :link="{ name: 'Programas', icon: 'box', color: 'primary', path: { name: 'participant-programs-list' } }"/>
            <sidebar-item :link="{ name: 'APK', icon: 'credit-card', color: 'cielo', path: { name: 'participant-apk' } }"/>
            <sidebar-item :link="{ name: 'Proceso Programa 2023-2024', icon: 'credit-card', color: 'primary', path: { name: 'participant-process' } }"/>
        </template>
    </intranet-dashboard>
</template>

<script>
import IntranetDashboard from '@/layout/Dashboard/IntranetDashboard.vue';

export default {
    components: { IntranetDashboard }
}
</script>