import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//get
function getPostulant (pAdminId) {
    const URI = `${BASE_URL}/api/postulants/${pAdminId}`
    return get(URI)
}
function getPostulants (pPage) {
    const URI = `${BASE_URL}/api/postulants?page=${pPage}`
    return get(URI)
}

function storePostulant (pFormData) {
    const URI = `${BASE_URL}/api/postulants`
    return post(URI, pFormData, { headers })
}

export {
    getPostulant,
    getPostulants,
    storePostulant
}