<template>
    <participant-information-export-component></participant-information-export-component>
</template>

<script>
import ParticipantInformationExportComponent from '@/components/Admin-Employee/Export/Information/Participant/ParticipantInformationExportComponent.vue'

export default {
    name: 'ParticipantInformation',
    components: { ParticipantInformationExportComponent },
    data: () => ({})
}
</script>
