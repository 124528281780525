<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button
                                variant="naranja" size="sm" class="text-white"
                                :to="{ name: routeNameToContractCreate }"
                                v-show="!loadingTableData && tableData.length == 0"
                            >
                                CREAR <b-icon icon="file-earmark-text" scale="1"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        class="text-center"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- table salesAdvisor.season.programSeasonPostulants -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="text-center">CONTRATO - RELLENAR</th>
                            <th class="text-center">DOC. GENERADO - ESTADO</th>
                            <th>Costo</th>
                            <th>Expiración</th>
                            <th>Descripción</th>
                            <th class="text-center">detalles</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                {{ index + 1 }}
                            </td>

                            <td class="text-center">
                                <!-- pdf -->
                               <b-button variant="outline-primary" size="sm" :href="row.attributes.generated_file" target="_black" v-show="row.attributes.type == 'pdf'">
                                    <b-icon icon="file-earmark-text" class="m-0 p-0"></b-icon>
                                </b-button>

                                <!-- contract_html -->
                               <b-button variant="outline-primary" size="sm" @click="showPreviewFromContract(row.attributes.HtmlContract)" v-show="row.attributes.type == 'html'">
                                    <b-icon icon="pencil" scale="1.2" class="m-0 p-0"></b-icon>
                                </b-button>

                            </td>

                            <td>
                                <div class="mb-2 text-center">
                                    <!-- contract_pdf_generated -->
                                    <span v-show="row.attributes.type == 'html' && row.attributes.HtmlContract.verification_file">
                                        <b-button variant="outline-primary" size="sm" :href="row.attributes.HtmlContract.verification_file" target="_blank"
                                        ><b-icon icon="link" scale="1.2" class="m-0 p-0"></b-icon></b-button>

                                        <html-contract-generated-preview-component
                                            :htmlContract="row.attributes.HtmlContract"
                                            :id="1"
                                            :file="row.attributes.HtmlContract.verification_file"
                                            @setVerifyDone="reloadData"
                                        ></html-contract-generated-preview-component>
                                    </span>

                                    <!-- contract_pdf_generated -->
                                    <span v-show="row.attributes.type == 'html' && row.attributes.participantDocument_contrato" >
                                        <b-button variant="outline-primary" size="sm" :href="row.attributes.participantDocument_contrato" target="_blank"
                                        ><b-icon icon="link" scale="1.2" class="m-0 p-0"></b-icon></b-button>

                                        <html-contract-generated-preview-component
                                            :htmlContract="row.attributes.HtmlContract"
                                            :id="2"
                                            :file="row.attributes.participantDocument_contrato"
                                            @setVerifyDone="reloadData"
                                        ></html-contract-generated-preview-component>
                                    </span>

                                    <!-- status -->
                                    <span>
                                        <traffic-light :status="row.attributes.HtmlContract.verification_status" style="max-width: 110px"/>
                                    </span>
                                </div>

                                <div v-show="row.attributes.HtmlContract.verification_comments">
                                    {{ row.attributes.HtmlContract.verification_comments }}
                                </div>
                            </td>

                            <td>{{ row.attributes.total_cost }}</td>
                            <td>{{ row.attributes.expiration_date_formated }}</td>
                            <td>{{ row.attributes.description ? row.attributes.description : '- - - - - - - - - -'}}</td>

                            <td class="text-center">
                                <b-btn
                                    :to="{
                                        name: routeNameToContractDetails,
                                        params: { 'IdContract': row.id }    // IdContract
                                    }"
                                    variant="outline-violeta"
                                    size="sm"
                                >
                                    <b-icon icon="list-task" ></b-icon>
                                </b-btn>
                            </td>

                            <td class="text-center" v-show="programSeasonPostulant.hasParticipant == 'no' && programSeasonPostulant.contracts_contract_payments == 0">
                                <pulse-loader
                                    :loading="row.deleteLoading || false"
                                    :color="'#5e72e4'"
                                    :margin="'2px'"
                                    :size="8"
                                    class="pt-2 pb-1"
                                ></pulse-loader>

                                <b-btn variant="transparent" size="sm" 
                                    v-show="!row.deleteLoading && true"
                                    @click="deleteContract(row)"
                                ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- preview -->
        <contract-preview-component :preview="preview" :html="html_contract" :ui="'postulant'"></contract-preview-component>

    </b-row>
</template>

<script>
import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant'
import { getProgramSeasonPostulantContracts } from '@/api/ProgramSeasonPostulant/Contracts'
import { destroyContract } from '@/api/Contract/Contract'
import ContractPreviewComponent from '@/components/Tiptap/components/ContractPreviewComponent.vue'
import swal from 'sweetalert'
import TrafficLight from '@/layout/Icons/TrafficLight.vue'
import HtmlContractGeneratedPreviewComponent from '@/components/Admin-Employee/Postulant/Contracts/HtmlContractGeneratedPreviewComponent.vue'

export default {
    name: "ContractsListComponent",
    props: {
        routeNameToContractCreate: {
            type: String,
            required: true
        },
        routeNameToContractDetails: {
            type: String,
            required: true
        }
    },
    components: {
        ContractPreviewComponent,
        TrafficLight,
        HtmlContractGeneratedPreviewComponent
    },
    data: () => ({
        // programSeasonPostulant
        programSeasonPostulant: {},
        postulantLoading: false,
        // programSeasonPostulant.Contracts
        tableData: [],
        loadingTableData: false,
        //
        preview: 1,
        html_contract: {}
    }),

    created() {
        this.getProgramSeasonPostulantData()
        this.getPostulantContractsData()
    },

    methods: {
        getProgramSeasonPostulantData () {
            this.postulantLoading = true
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok
            getProgramSeasonPostulant(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.programSeasonPostulant = data.data.attributes
                })
                .finally(() => {
                    this.postulantLoading = false
                })
        },

        getPostulantContractsData () {
            this.loadingTableData = true
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok

            getProgramSeasonPostulantContracts(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        reloadData () {
            this.getProgramSeasonPostulantData()
            this.getPostulantContractsData()
        },

        showPreviewFromContract(pHtmlContract) {
            this.preview += 1
            this.html_contract = pHtmlContract
        },

        deleteContract (pContract) {
            swal(`Se eliminará el contrato:`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pContract, 'deleteLoading', true)
                            destroyContract(pContract.attributes.id)
                                .then(() => {
                                    this.reloadData()
                                })
                                .finally(() => {
                                    pContract.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-contract-swal-break');
                            break;
                    }
                })
        }
    }
}
</script>