import { mergeAttributes } from '@tiptap/core'
import Image from '@tiptap/extension-image'

export default Image.extend({
    name: 'ApkSignatureLarge',

    inline() {
      return true
    },

    group() {
        return 'inline'
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            kind: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-kind'),
                renderHTML: () => ({
                    'data-kind': this.name,
                    style: 'width: auto;height: 130px;',
                    class: 'apk-signature'
                }),
            },
        }
    },

    parseHTML() {
        return [
          {
            tag: 'img[data-type="ApkSignatureLarge"]'
          },
        ]
      },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes({ 'data-type': 'ApkSignatureLarge' }, this.options.HTMLAttributes, HTMLAttributes)]
    },

    addCommands() {
        return {
            setSignatureLarge: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },
})