import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getGeneralDocuments () {
    const URI = `${BASE_URL}/api/generalDocuments`
    return axios.get(URI, { headers })
}

function storeGeneralDocument (pFormData) {
    const URI = `${BASE_URL}/api/generalDocuments`
    return axios.post(URI, pFormData, { headers })
}

function updateGeneralDocument (pFormData, pIdGeneralDocument) {
    const URI = `${BASE_URL}/api/generalDocuments/${pIdGeneralDocument}`
    return axios.post(URI, pFormData, { headers })
}

function destroyGeneralDocument (pIdGeneralDocument) {
    const URI = `${BASE_URL}/api/generalDocuments/${pIdGeneralDocument}`
    return axios.delete(URI, { headers })
}

export {
    getGeneralDocuments,
    storeGeneralDocument,
    updateGeneralDocument,
    destroyGeneralDocument
}