import { get } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function getProgramSeasonPostulantContracts (pIdProgramSeasonPostulant) {
    const URI = `${BASE_URL}/api/programSeasonPostulants/${pIdProgramSeasonPostulant}/contracts`
    return get(URI, { headers })
}

export { getProgramSeasonPostulantContracts }