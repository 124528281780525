<template>
    <b-row>
        <!-- program sponsor job -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow" no-body>
                <div style="height:110px;" v-show="postulationLoading" >
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <div class="table-responsive mb-0" v-show="!postulationLoading">
                    <table class="table tablesorter align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>
                                    <b-button variant="outline-primary" size="sm" @click="reloadData">
                                        <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                                    </b-button>
                                </th>
                                <th v-for="(programJobField, i) in programJobFieldsSorted" :key="`${i}-p-s-field-th-sorted`">
                                    {{ programJobField }}
                                </th>
                            </tr>
                        </thead>

                        <tbody class="list">
                            <tr>
                                <td>{{ postulation.subject }}</td>

                                <td v-for="(jobField, index) in jobFieldsSorted" :key="`${index}-job-field`">
                                    {{ jobField }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-card>
        </b-col>

        <!-- postulation details -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow" header-class="py-2">
                <b-card-header class="py-2">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                estado-postulación
                            </h3>
                        </b-col>

                        <b-col class="text-right" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                            <b-button variant="naranja" size="sm" @click="openModalEditPostulation">
                                <b-icon icon="pencil"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:150px;" v-show="postulationLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!postulationLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item v-show="postulation.comments">
                            <b-row>
                                <b-col cols="5" md="3">Comentarios</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ postulation.comments }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item >
                            <b-row>
                                <b-col cols="5" md="3">Estado</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ postulation.status }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>

            <!-- modal edit postulation -->
            <b-modal
                id="modal-edit-postulation"
                centered
                hide-footer
                size="md"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Postulación</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalEditPostulation"
                                    :disabled="editPostulationLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="editCurrentPostulation">
                                    <b-form-row>
                                        <!-- subject -->
                                        <b-col cols="12">
                                            <b-form-group label="Subject:" >
                                                <pulse-loader
                                                    :loading="editPostulationLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    type="text"
                                                    placeholder="Ingrese el nombre de la capacitación."
                                                    v-model="selectecPostulation.subject"
                                                    disabled
                                                    :state="showModalInputStatus('subject')"
                                                    v-show="!editPostulationLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('subject')"
                                                    :key="`${i}-createVoucher-subject`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- description -->
                                        <b-col cols="12">
                                            <b-form-group label="Comentarios :" >
                                                <pulse-loader
                                                    :loading="editPostulationLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese una descripción (opcional)"
                                                    v-model="selectecPostulation.comments"
                                                    :state="showModalInputStatus('comments')"
                                                    v-show="!editPostulationLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('comments')"
                                                    :key="`${i}-interview-comments`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- estado -->
                                        <b-col cols="12">
                                            <b-form-group label="Comentarios :" >
                                                <pulse-loader
                                                    :loading="editPostulationLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-select 
                                                    v-model="selectecPostulation.status"
                                                    :options="postulationStatusOptions"
                                                    :state="showModalInputStatus('status')"
                                                    v-show="!editPostulationLoading"
                                                ></b-form-select>

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('status')"
                                                    :key="`${i}-interview-status`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="editPostulationLoading"
                                            >Editar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>
        </b-col>

        <!-- sponsor interview details -->
        <b-col cols="12" lg="6" class="mb-4">
            <b-card class="shadow" header-class="py-2">
                <b-card-header class="py-2 px-0">
                    <b-row class="align-items-center">
                        <b-col cols="12" sm="auto">
                            <h3 class="mb-0 text-primary text-button">
                                entrevistas con sponsor
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalAddInterview" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                                <b-icon icon="plus" scale="1.6"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="getSponsorInterviews" :disabled="sponsorInterviewsLoading">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:150px;" v-show="sponsorInterviewsLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!sponsorInterviewsLoading" class="mt-5">
                    <transition name="slide-fade" v-for="(interview, i) in interviews" :key="`${i}-inter`">
                    <b-list-group  class="mb-5 border border-primary" v-if="!interview.hide && true">
                        <!-- header -->
                        <b-list-group-item variant="primary">
                            <b-row align-v="center">
                                <b-col class="text-left text-primary">
                                        Entrevista con sponsor <b>{{ interview.attributes.sponsor_name}}</b>
                                </b-col>

                                <b-col class="text-right" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                                    <b-button variant="white" size="sm" @click="openModalEditInterview(interview)"
                                        :disabled="interview.deleteLoading || false"
                                    ><b-icon icon="pencil" scale="1.3" variant="naranja"></b-icon>
                                    </b-button>

                                    <b-button variant="white" size="sm" @click="deleteInterview(interview)"
                                        :disabled="interview.deleteLoading || false"
                                    ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- body -->
                        <b-list-group-item class="over">
                            <div style="height:50px;" v-show="interview.deleteLoading || false">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                           <b-list-group horizontal="md" style="min-width:max-content" v-show="!interview.deleteLoading && true">
                               <b-list-group-item>
                                   📆 {{ interview.attributes.start_date_formated}}
                               </b-list-group-item>

                               <b-list-group-item>
                                   ⌚ {{ interview.attributes.start_time_formated }}
                               </b-list-group-item>

                               <b-list-group-item>
                                   <b-button
                                        v-show="interview.attributes.room_type == 'url'"
                                        :href="interview.attributes.link" target="_blanck"
                                        :variant="interview.attributes.schedule_status == 'pendiente' || interview.attributes.schedule_status == 'en proceso' ? 'primary' : 'light'"
                                        :disabled="interview.attributes.schedule_status == 'pendiente' || interview.attributes.schedule_status == 'en proceso' ? false : true"
                                        size="sm" class="text-white"
                                    ><b-icon icon="link"></b-icon> LINK</b-button>

                                    <div  v-show="interview.attributes.room_type == 'skype'">
                                        COD. SKYPE : <span class="text-primary">{{ interview.attributes.link }}</span>
                                    </div>
                               </b-list-group-item>

                                <b-list-group-item>
                                   <span>{{ interview.attributes.schedule_description }}</span>
                                </b-list-group-item>
                           </b-list-group>
                        </b-list-group-item>

                        <!-- interview details -->
                        <b-list-group-item v-show="!interview.deleteLoading && true" class="over">
                            <b-row v-if="ui == 'admin' || ui == 'cultural_advisor'">
                                <b-col cols="12" class="text-right">
                                    <b-button variant="naranja" size="sm" @click="openModalSetResultsInterview(interview)" :disabled="interview.deleteLoading || false">
                                        <b-icon icon="pencil" scale="1.3"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" lg="4">
                                    ESTADO
                                </b-col>

                                <b-col cols="12" lg="4" :class="interview.attributes.schedule_status == 'rechazado' ? 'text-magenta' : (interview.attributes.schedule_status == 'aceptado' ? 'text-green' : 'text-primary')">
                                    : {{ interview.attributes.schedule_status }}
                                </b-col>
                            </b-row>

                            <br>

                            <b-row>
                                <b-col cols="12" lg="4">
                                    COMENTARIOS
                                </b-col>

                                <b-col cols="12" lg="8" style="text-align:justify">
                                    : <span style="">{{ interview.attributes.comments}}</span>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- email status -->
                        <b-list-group-item v-show="!interview.deleteLoading && true" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                            <b-row class="text-gray">
                                <b-col cols="6">
                                    correo-aceptado
                                </b-col>

                                <b-col cols="6">
                                    : {{ interview.attributes.email_approved}}
                                </b-col>
                            </b-row>

                            <b-row class="text-gray">
                                <b-col cols="6">
                                    correo-rechazado
                                </b-col>

                                <b-col cols="6">
                                    : {{ interview.attributes.email_disapproved}}
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                    </transition>
                </div>
            </b-card>

            <!-- modal add interview -->
            <b-modal
                id="modal-add-interview"
                centered
                hide-footer
                size="lg"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Registrar</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalAddInterview"
                                    :disabled="createIntreviewLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="createInterview('SponsorInterview')">
                                    <b-form-row>
                                        <!-- subject -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Asunto:" >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    type="text"
                                                    placeholder="Ingrese el nombre de la capacitación."
                                                    v-model="subject"
                                                    disabled
                                                    :state="showModalInputStatus('subject')"
                                                    v-show="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('subject')"
                                                    :key="`${i}-createVoucher-subject`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_date -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Fecha :"
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-datepicker
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="start_date"
                                                    :state="showModalInputStatus('start_date')"
                                                    v-if="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_date')"
                                                    :key="`${i}-interview-start_date`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de inicio :"
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="start_time"
                                                    :state="showModalInputStatus('start_time')"
                                                    v-if="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_time')"
                                                    :key="`${i}-interview-start_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- end_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de finalización :"
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="end_time"
                                                    :state="showModalInputStatus('end_time')"
                                                    v-if="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('end_time')"
                                                    :key="`${i}-interview-end_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- switch -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Tipo de sala."
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="pt-2 pb-1 pl-2 text-center"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                 <b-form-radio-group required v-model="room_type" v-if="!createIntreviewLoading" stacked plain 
                                                    :options="[{value: 'url', text: 'URL'}, {value: 'skype', text: 'SKYPE'}]">
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </b-col>

                                        <!-- link -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group :label="room_type == 'url' ? 'Link' : 'CÓD. SKYPE'" >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    :placeholder="`Ingrese el ${room_type == 'url' ? 'link' : 'cod. skype'} de la reunión.`"
                                                    v-model="link"
                                                    :state="showModalInputStatus('link')"
                                                    v-show="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('link')"
                                                    :key="`${i}-createVoucher-link`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- description -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Descripción:" 
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese una descripción (opcional)"
                                                    v-model="schedule_description"
                                                    :state="showModalInputStatus('schedule_description')"
                                                    v-show="!createIntreviewLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('schedule_description')"
                                                    :key="`${i}-interview-schedule_description`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- request error -->
                                    <b-row v-if="sponsorInterviewRequestError != ''">
                                        <b-col>
                                            <b-alert show variant="magenta">
                                                {{ sponsorInterviewRequestError }}
                                            </b-alert>
                                        </b-col>
                                    </b-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="createIntreviewLoading"
                                                >
                                                REGISTRAR
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>  

            <!-- modal edit interview -->
            <b-modal
                id="modal-edit-interview"
                centered
                hide-footer
                size="lg"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Editar entrevista</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalEditInterview"
                                    :disabled="editIntreviewLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="editInterview">
                                    <b-form-row>
                                        <!-- subject -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Asunto:" >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    type="text"
                                                    placeholder="Ingrese el nombre de la capacitación."
                                                    v-model="selectecInterview.attributes.subject"
                                                    disabled
                                                    :state="showModalInputStatus('subject')"
                                                    v-show="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('subject')"
                                                    :key="`${i}-createVoucher-subject`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_date -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Fecha :"
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-datepicker
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="selectecInterview.attributes.start_date"
                                                    :state="showModalInputStatus('start_date')"
                                                    v-if="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_date')"
                                                    :key="`${i}-interview-start_date`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de inicio :"
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="selectecInterview.attributes.start_time"
                                                    :state="showModalInputStatus('start_time')"
                                                    v-if="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_time')"
                                                    :key="`${i}-interview-start_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- end_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de finalización :"
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="selectecInterview.attributes.end_time"
                                                    :state="showModalInputStatus('end_time')"
                                                    v-if="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('end_time')"
                                                    :key="`${i}-interview-end_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- switch -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Tipo de sala."
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                 <b-form-radio-group required v-model="selectecInterview.attributes.room_type" v-if="!editIntreviewLoading" stacked plain 
                                                    :options="[{value: 'url', text: 'URL'}, {value: 'skype', text: 'SKYPE'}]">
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </b-col>

                                        <!-- link -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group :label="selectecInterview.attributes.room_type == 'url' ? 'Link' : 'CÓD. SKYPE'" >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    :placeholder="`Ingrese el ${selectecInterview.attributes.room_type == 'url' ? 'link' : 'cod. skype'} de la reunión.`"
                                                    v-model="selectecInterview.attributes.link"
                                                    :state="showModalInputStatus('link')"
                                                    v-show="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('link')"
                                                    :key="`${i}-createVoucher-link`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- description -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Descripción:" 
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese una descripción (opcional)"
                                                    v-model="selectecInterview.attributes.schedule_description"
                                                    :state="showModalInputStatus('schedule_description')"
                                                    v-show="!editIntreviewLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('schedule_description')"
                                                    :key="`${i}-interview-schedule_description`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="editIntreviewLoading"
                                                >
                                                Editar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>

            <!-- modal set results interview -->
            <b-modal
                id="modal-set-results-interview"
                centered
                hide-footer
                size="lg"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Resultados de entrevista</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalSetResultsInterview"
                                    :disabled="editIntreviewLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="setResultsInterview">
                                    <b-form-row>
                                        <!-- status -->
                                        <b-col cols="12">
                                            <b-form-group label="Estado:" >
                                                <pulse-loader
                                                    :loading="setResultsIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-select
                                                    :options="interviewStatusOptions"
                                                    v-model="selectecInterview.attributes.schedule_status"
                                                    :state="showModalInputStatus('schedule_status')"
                                                    v-show="!setResultsIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('schedule_status')"
                                                    :key="`${i}-interview-schedule_status`" :state="showModalInputStatus('schedule_status')"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- comments -->
                                        <b-col cols="12">
                                            <b-form-group
                                                label="Comentarios:" 
                                            >
                                                <pulse-loader
                                                    :loading="setResultsIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese los comentarios."
                                                    v-model="selectecInterview.attributes.comments"
                                                    :state="showModalInputStatus('comments')"
                                                    v-show="!setResultsIntreviewLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('comments')"
                                                    :key="`${i}-interview-comments`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- request error -->
                                    <b-row v-if="sponsorInterviewRequestError != ''">
                                        <b-col>
                                            <b-alert show variant="magenta">
                                                {{ sponsorInterviewRequestError }}
                                            </b-alert>
                                        </b-col>
                                    </b-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="setResultsIntreviewLoading"
                                                >
                                                Guardar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>
        </b-col>

        <!-- employer interview details -->
        <b-col cols="12" lg="6" class="mb-4" >
            <b-card class="shadow" header-class="py-2">
                <b-card-header class="py-2 px-0">
                    <b-row class="align-items-center">
                        <b-col cols="12" sm="auto">
                            <h3 class="mb-0 text-primary text-button">
                                entrevistas con empleador
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalAddEmployerInterview" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                                <b-icon icon="plus" scale="1.6"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="getEmployerInterviews" :disabled="employerInterviewsLoading">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:150px;" v-show="employerInterviewsLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!employerInterviewsLoading" class="mt-5">
                    <transition name="slide-fade" v-for="(interview, i) in employerInterviews" :key="`${i}-inter`">
                    <b-list-group  class="mb-5 border border-primary" v-if="!interview.hide && true">
                        <!-- header -->
                        <b-list-group-item variant="primary">
                            <b-row align-v="center">
                                <b-col class="text-left text-primary"><b>{{ interview.attributes.subject}}</b></b-col>

                                <b-col class="text-right" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                                    <b-button variant="white" size="sm" @click="openModalEditEmployerInterview(interview)"
                                        :disabled="interview.deleteLoading || false"
                                    ><b-icon icon="pencil" scale="1.3" variant="naranja"></b-icon>
                                    </b-button>

                                    <b-button variant="white" size="sm" @click="deleteEmployerInterview(interview)"
                                        :disabled="interview.deleteLoading || false"
                                    ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- body -->
                        <b-list-group-item class="over">
                            <div style="height:50px;" v-show="interview.deleteLoading || false">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                           <b-list-group horizontal="md" style="min-width:max-content" v-show="!interview.deleteLoading && true">
                               <b-list-group-item>
                                   📆 {{ interview.attributes.start_date_formated}}
                               </b-list-group-item>

                               <b-list-group-item>
                                   ⌚ {{ interview.attributes.start_time_formated }}
                               </b-list-group-item>

                               <b-list-group-item>
                                   <b-button
                                        v-show="interview.attributes.room_type == 'url'"
                                        :href="interview.attributes.link" target="_blanck"
                                        :variant="interview.attributes.schedule_status == 'pendiente' || interview.attributes.schedule_status == 'en proceso' ? 'primary' : 'light'"
                                        :disabled="interview.attributes.schedule_status == 'pendiente' || interview.attributes.schedule_status == 'en proceso' ? false : true"
                                        size="sm" class="text-white"
                                    ><b-icon icon="link"></b-icon> LINK</b-button>

                                    <div  v-show="interview.attributes.room_type == 'skype'">
                                        COD. SKYPE : <span class="text-primary">{{ interview.attributes.link }}</span>
                                    </div>
                               </b-list-group-item>

                                <b-list-group-item>
                                   <span>{{ interview.attributes.schedule_description }}</span>
                                </b-list-group-item>
                           </b-list-group>
                        </b-list-group-item>

                        <!-- interview details -->
                        <b-list-group-item v-show="!interview.deleteLoading && true" class="over">
                            <b-row v-if="ui == 'admin' || ui == 'cultural_advisor'">
                                <b-col cols="12" class="text-right">
                                    <b-button variant="naranja" size="sm" @click="openModalSetResultsEmployerInterview(interview)" :disabled="interview.deleteLoading || false">
                                        <b-icon icon="pencil" scale="1.3"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" lg="4">
                                    ESTADO
                                </b-col>

                                <b-col cols="12" lg="4" :class="interview.attributes.schedule_status == 'rechazado' ? 'text-magenta' : (interview.attributes.schedule_status == 'aceptado' ? 'text-green' : 'text-primary')">
                                    : {{ interview.attributes.schedule_status }}
                                </b-col>
                            </b-row>

                            <br>

                            <b-row>
                                <b-col cols="12" lg="4">
                                    COMENTARIOS
                                </b-col>

                                <b-col cols="12" lg="8" style="text-align:justify">
                                    : <span style="">{{ interview.attributes.comments}}</span>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- email status -->
                        <b-list-group-item v-show="!interview.deleteLoading && true" v-if="ui == 'admin' || ui == 'cultural_advisor'">
                            <!-- <b-row class="text-gray">
                                <b-col cols="6">
                                    correo-aceptado
                                </b-col>

                                <b-col cols="6">
                                    : {{ interview.attributes.email_approved}}
                                </b-col>
                            </b-row> -->

                            <b-row class="text-gray">
                                <b-col cols="6">
                                    correo-rechazado
                                </b-col>

                                <b-col cols="6">
                                    : {{ interview.attributes.email_disapproved}}
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                    </transition>
                </div>
            </b-card>

            <!-- modal add interview -->
            <b-modal
                id="modal-add-employer-interview"
                centered
                hide-footer
                size="lg"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Registrar</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalAddEmployerInterview"
                                    :disabled="createIntreviewLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="createEmployerInterview">
                                    <b-form-row>
                                        <!-- subject -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Asunto:" >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    type="text"
                                                    placeholder="Ingrese el nombre de la capacitación."
                                                    v-model="subject"
                                                    disabled
                                                    :state="showModalInputStatus('subject')"
                                                    v-show="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('subject')"
                                                    :key="`${i}-createVoucher-subject`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_date -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Fecha :"
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-datepicker
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="start_date"
                                                    :state="showModalInputStatus('start_date')"
                                                    v-if="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_date')"
                                                    :key="`${i}-interview-start_date`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de inicio :"
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="start_time"
                                                    :state="showModalInputStatus('start_time')"
                                                    v-if="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_time')"
                                                    :key="`${i}-interview-start_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- end_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de finalización :"
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="end_time"
                                                    :state="showModalInputStatus('end_time')"
                                                    v-if="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('end_time')"
                                                    :key="`${i}-interview-end_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- switch -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Tipo de sala."
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="pt-2 pb-1 pl-2 text-center"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                 <b-form-radio-group required v-model="room_type" v-if="!createIntreviewLoading" stacked plain 
                                                    :options="[{value: 'url', text: 'URL'}, {value: 'skype', text: 'SKYPE'}]">
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </b-col>

                                        <!-- link -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group :label="room_type == 'url' ? 'Link' : 'CÓD. SKYPE'" >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    :placeholder="`Ingrese el ${room_type == 'url' ? 'link' : 'cod. skype'} de la reunión.`"
                                                    v-model="link"
                                                    :state="showModalInputStatus('link')"
                                                    v-show="!createIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('link')"
                                                    :key="`${i}-createVoucher-link`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- description -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Descripción:" 
                                            >
                                                <pulse-loader
                                                    :loading="createIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese una descripción (opcional)"
                                                    v-model="schedule_description"
                                                    :state="showModalInputStatus('schedule_description')"
                                                    v-show="!createIntreviewLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('schedule_description')"
                                                    :key="`${i}-interview-schedule_description`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- request error -->
                                    <b-row v-if="employerInterviewRequestError != ''">
                                        <b-col>
                                            <b-alert show variant="magenta">
                                                {{ employerInterviewRequestError }}
                                            </b-alert>
                                        </b-col>
                                    </b-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="createIntreviewLoading"
                                                >
                                                REGISTRAR
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>  

            <!-- modal edit interview -->
            <b-modal
                id="modal-edit-employer-interview"
                centered
                hide-footer
                size="lg"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Registrar</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalEditEmployerInterview"
                                    :disabled="editIntreviewLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="editEmployerInterview">
                                    <b-form-row>
                                        <!-- subject -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group label="Asunto:" >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    type="text"
                                                    placeholder="Ingrese el nombre de la capacitación."
                                                    v-model="selectecInterview.attributes.subject"
                                                    disabled
                                                    :state="showModalInputStatus('subject')"
                                                    v-show="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('subject')"
                                                    :key="`${i}-createVoucher-subject`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_date -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Fecha :"
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-datepicker
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="selectecInterview.attributes.start_date"
                                                    :state="showModalInputStatus('start_date')"
                                                    v-if="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_date')"
                                                    :key="`${i}-interview-start_date`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- start_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de inicio :"
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="selectecInterview.attributes.start_time"
                                                    :state="showModalInputStatus('start_time')"
                                                    v-if="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('start_time')"
                                                    :key="`${i}-interview-start_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- end_time -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Hora de finalización :"
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="selectecInterview.attributes.end_time"
                                                    :state="showModalInputStatus('end_time')"
                                                    v-if="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('end_time')"
                                                    :key="`${i}-interview-end_time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- switch -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Tipo de sala."
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                 <b-form-radio-group required v-model="selectecInterview.attributes.room_type" v-if="!editIntreviewLoading" stacked plain 
                                                    :options="[{value: 'url', text: 'URL'}, {value: 'skype', text: 'SKYPE'}]">
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </b-col>

                                        <!-- link -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group :label="selectecInterview.attributes.room_type == 'url' ? 'Link' : 'CÓD. SKYPE'" >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    :placeholder="`Ingrese el ${selectecInterview.attributes.room_type == 'url' ? 'link' : 'cod. skype'} de la reunión.`"
                                                    v-model="selectecInterview.attributes.link"
                                                    :state="showModalInputStatus('link')"
                                                    v-show="!editIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('link')"
                                                    :key="`${i}-createVoucher-link`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- description -->
                                        <b-col cols="12" lg="6">
                                            <b-form-group
                                                label="Descripción:" 
                                            >
                                                <pulse-loader
                                                    :loading="editIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese una descripción (opcional)"
                                                    v-model="selectecInterview.attributes.schedule_description"
                                                    :state="showModalInputStatus('schedule_description')"
                                                    v-show="!editIntreviewLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('schedule_description')"
                                                    :key="`${i}-interview-schedule_description`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="editIntreviewLoading"
                                                >
                                                Editar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>

            <!-- modal set results employer interview -->
            <b-modal
                id="modal-set-results-employer-interview"
                centered
                hide-footer
                size="lg"
                no-close-on-backdrop
                no-close-on-esc
            >
                <!-- modal header -->
                <template #modal-header="">
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Resultados de entrevista</h3></b-col>
                            <b-col cols="auto">
                                <b-button size="sm" variant="magenta" @click="closeModalSetResultsEmployerInterview"
                                    :disabled="editIntreviewLoading"
                                ><b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal content -->
                <template #default>
                    <b-container>
                        <b-row>
                            <!-- resource form -->
                            <b-col cols="12">
                                <b-form @submit.prevent="setResultsEmployerInterview">
                                    <b-form-row>
                                        <!-- status -->
                                        <b-col cols="12">
                                            <b-form-group label="Estado:" >
                                                <pulse-loader
                                                    :loading="setResultsIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-select
                                                    :options="interviewStatusOptions"
                                                    v-model="selectecInterview.attributes.schedule_status"
                                                    :state="showModalInputStatus('schedule_status')"
                                                    v-show="!setResultsIntreviewLoading"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('schedule_status')"
                                                    :key="`${i}-interview-schedule_status`" :state="showModalInputStatus('schedule_status')"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>

                                        <!-- comments -->
                                        <b-col cols="12">
                                            <b-form-group
                                                label="Comentarios:" 
                                            >
                                                <pulse-loader
                                                    :loading="setResultsIntreviewLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="text-center pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese los comentarios."
                                                    v-model="selectecInterview.attributes.comments"
                                                    :state="showModalInputStatus('comments')"
                                                    v-show="!setResultsIntreviewLoading"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('comments')"
                                                    :key="`${i}-interview-comments`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>

                                    <!-- request error -->
                                    <b-row v-if="employerInterviewRequestError != ''">
                                        <b-col>
                                            <b-alert show variant="magenta">
                                                {{ employerInterviewRequestError }}
                                            </b-alert>
                                        </b-col>
                                    </b-row>

                                    <!-- button create -->
                                    <b-row align-v="center" align-h="center">
                                        <b-col cols="auto">
                                            <b-button
                                                variant="primary"
                                                type="submit"
                                                size="lg"
                                                :disabled="setResultsIntreviewLoading"
                                                >
                                                Guardar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-modal>


        </b-col>
    </b-row>

</template>

<script>
import { getPostulation, updatePostulation } from '@/api/Postulation/Postulation';
//
import { getPostulationSponsorInterviews } from '@/api/Postulation/SponsorInterview';
import { storeSponsorInterview, destroySponsorInterview, updateSponsorInterview, updateResultsSponsorInterview } from '@/api/SponsorInterview/SponsorInterview';
//
import { getPostulationEmployerInterviews } from '@/api/Postulation/EmployerInterview';
import { storeEmployerInterview, destroyEmployerInterview, updateEmployerInterview, updateResultsEmployerInterview } from '@/api/EmployerInterview/EmployerInterview';
//
import swal from 'sweetalert';

export default {
    name: 'AttemptDetailsComponent',
    props: {
        ui : {
            type: String,
            required: true
        }
    },
    data: () => ({
        postulation: {},
        postulationLoading: false,
        //
        programJobFieldsSorted:[],
        jobFieldsSorted: [],
        //
        interviews: [],
        employerInterviews: [],
        sponsorInterviewsLoading: false,
        employerInterviewsLoading: false,
        // add from
        subject: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        link: '',
        room_type: 'url',
        color: '#5E8B7E',
        schedule_description: '',
        createIntreviewLoading: false,
        //
        selectecInterview: {
            attributes: {}
        },
        editIntreviewLoading: false,
        setResultsIntreviewLoading: false,
        interviewStatusOptions: [{value:'pendiente', text: 'Pendiente'}, {value:'en proceso', text: 'En proceso'}, {value:'rechazado', text: 'Rechazado'}, {value:'aceptado', text: 'Aceptado'}],
        //
        postulationStatusOptions: [
            { value: 'nuevo', text :'nuevo' },
            { value: 'en proceso', text :'en proceso' },
            { value: 'aceptado', text :'aceptado' },
            { value: 'rechazado', text :'rechazado' },
            { value: 'pendiente', text :'pendiente' }
        ],
        selectecPostulation: {},
        editPostulationLoading: false,
        //
        initialModalInputValues : true,
        modalInputErrors: {},
        //
        sponsorInterviewRequestError: '',
        employerInterviewRequestError: '',
    }),
    created() {
        this.geData()
        this.getInterviews()
    },
    methods: {
        geData () {
            this.postulationLoading = true

            getPostulation(this.$route.params.IdPostulation)
                .then(({ data }) => {
                        this.postulation = data.data.attributes

                        let input_order = this.postulation.programSeasonSponsor.input_order.split('|')

                        // ordenando programJobFields and JobFields
                        this.programJobFieldsSorted = []
                        this.jobFieldsSorted = []
                        for (const inputOrder of input_order) {
                            for (const jobField of this.postulation.programSponsorJob.attributes.jobFields) {
                                if (inputOrder == jobField.attributes.programJobField_id) {
                                    this.programJobFieldsSorted.push(jobField.attributes.field_name)
                                    this.jobFieldsSorted.push(jobField.attributes.field_value)
                                    break
                                }
                            }
                        }

                })
                .finally(() => {
                    this.postulationLoading = false
                })
        },
        getInterviews () {
            this.getSponsorInterviews()
            this.getEmployerInterviews()
        },

        getSponsorInterviews() {
            this.sponsorInterviewsLoading = true
             getPostulationSponsorInterviews(this.$route.params.IdPostulation)
                .then(({ data }) => {
                    this.interviews = data.data
                })
                .finally(() => {
                    this.sponsorInterviewsLoading = false
                })
        },

        getEmployerInterviews() {
            this.employerInterviewsLoading = true
            getPostulationEmployerInterviews(this.$route.params.IdPostulation)
                .then(({ data }) => {
                    this.employerInterviews = data.data
                })
                .finally(() => {
                    this.employerInterviewsLoading = false
                })
        },

        reloadData () {
            this.geData()
            this.getInterviews()
        },

        // modal add sponsor intreview
        openModalAddInterview () {
            this.clearInputsAndStates()
            //
            this.subject = "Entrevista-Sponsor"
            this.schedule_description = "Entrevista con sponsor agendada"
            this.$bvModal.show('modal-add-interview')
        },
        closeModalAddInterview () {
            this.$bvModal.hide('modal-add-interview')
            this.clearInputsAndStates()
        },

        createInterview () {
            this.createIntreviewLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.sponsorInterviewRequestError = ''

            let InterviewForm = new FormData()
            InterviewForm.append('postulation_id', this.$route.params.IdPostulation)

            InterviewForm.append('start_date', this.start_date)
            InterviewForm.append('start_time', this.start_time)
            InterviewForm.append('end_date', this.start_date)    // this.start_date; la entrevista es el mismo dia
            InterviewForm.append('end_time', this.end_time)
            
            InterviewForm.append('subject', this.subject)
            InterviewForm.append('link', this.link)
            InterviewForm.append('room_type', this.room_type)
            InterviewForm.append('color', this.color)                               // '#5E8B7'
            InterviewForm.append('schedule_description', this.schedule_description) // 'Capacitación'

            storeSponsorInterview(InterviewForm)
                .then(() => {
                    swal('Creación correcta!', 'Entrevista creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalAddInterview()
                                    this.getSponsorInterviews()
                                    break;
                                default:
                                    console.log('create sponsor-interview swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    } else {
                        this.sponsorInterviewRequestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.createIntreviewLoading = false
                })
        },

        // modal add employer intreview
        openModalAddEmployerInterview () {
            this.clearInputsAndStates()
            //
            this.subject = "Entrevista-Empleador"
            this.schedule_description = "Entrevista con empleador agendada"
            this.$bvModal.show('modal-add-employer-interview')
        },
        closeModalAddEmployerInterview () {
            this.$bvModal.hide('modal-add-employer-interview')
            this.clearInputsAndStates()
        },
        createEmployerInterview () {
            this.createIntreviewLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.employerInterviewRequestError = ''

            let InterviewForm = new FormData()
            InterviewForm.append('postulation_id', this.$route.params.IdPostulation)

            InterviewForm.append('start_date', this.start_date)
            InterviewForm.append('start_time', this.start_time)
            InterviewForm.append('end_date', this.start_date)    // this.start_date; la entrevista es el mismo dia
            InterviewForm.append('end_time', this.end_time)
            
            InterviewForm.append('subject', this.subject)
            InterviewForm.append('link', this.link)
            InterviewForm.append('room_type', this.room_type)
            InterviewForm.append('color', this.color)                               // '#5E8B7'
            InterviewForm.append('schedule_description', this.schedule_description) // 'Capacitación'

            storeEmployerInterview(InterviewForm)
                .then(() => {
                    swal('Creación correcta!', 'Entrevista creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalAddEmployerInterview()
                                    this.getEmployerInterviews()
                                    break;
                                default:
                                    console.log('create employer-interview swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    } else {
                        this.employerInterviewRequestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.createIntreviewLoading = false
                })
        },

        // modal edit sponsor intreview
        openModalEditInterview (pSponsorIntreview) {
            this.clearInputsAndStates()
            this.selectecInterview = JSON.parse(JSON.stringify(pSponsorIntreview))
            this.$bvModal.show('modal-edit-interview')
        },
        closeModalEditInterview () {
            this.$bvModal.hide('modal-edit-interview')
            this.clearInputsAndStates()
        },
        editInterview () {
            this.editIntreviewLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false

            let InterviewForm = new FormData()
            InterviewForm.append('.method', 'put')
            InterviewForm.append('start_date', this.selectecInterview.attributes.start_date)
            InterviewForm.append('start_time', this.selectecInterview.attributes.start_time)
            InterviewForm.append('end_date', this.selectecInterview.attributes.start_date)
            InterviewForm.append('end_time', this.selectecInterview.attributes.end_time)
            InterviewForm.append('link', this.selectecInterview.attributes.link)
            InterviewForm.append('room_type', this.selectecInterview.attributes.room_type)
            InterviewForm.append('schedule_description', this.selectecInterview.attributes.schedule_description)

            updateSponsorInterview(InterviewForm, this.selectecInterview.id)
                .then(() => {
                    swal('Actualización correcta!', 'Entrevista actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalEditInterview()
                                    this.getSponsorInterviews()
                                    break;
                                default:
                                    console.log('edit sponsor-interview swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editIntreviewLoading = false
                })
        },

        // modal set results sponsor intreview
        openModalSetResultsInterview (pSponsorIntreview) {
            this.clearInputsAndStates()
            this.selectecInterview = JSON.parse(JSON.stringify(pSponsorIntreview))
            this.$bvModal.show('modal-set-results-interview')
        },
        closeModalSetResultsInterview () {
            this.$bvModal.hide('modal-set-results-interview')
            this.clearInputsAndStates()
        },
        setResultsInterview () {
            this.setResultsIntreviewLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.sponsorInterviewRequestError = ''

            let InterviewForm = new FormData()
            InterviewForm.append('.method', 'put')
            InterviewForm.append('schedule_status', this.selectecInterview.attributes.schedule_status)
            InterviewForm.append('comments', this.selectecInterview.attributes.comments)

            updateResultsSponsorInterview(InterviewForm, this.selectecInterview.id)
                .then(() => {
                    swal('Actualización correcta!', 'Resultados guardados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalSetResultsInterview()
                                    this.getSponsorInterviews()
                                    break;
                                default:
                                    console.log('set results sponsor-interview swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    } else {
                        this.sponsorInterviewRequestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.setResultsIntreviewLoading = false
                })
        },

        // modal set results employer intreview
        openModalSetResultsEmployerInterview (pEmployerIntreview) {
            this.clearInputsAndStates()
            this.selectecInterview = JSON.parse(JSON.stringify(pEmployerIntreview))
            this.$bvModal.show('modal-set-results-employer-interview')
        },
        closeModalSetResultsEmployerInterview () {
            this.$bvModal.hide('modal-set-results-employer-interview')
            this.clearInputsAndStates()
        },
        setResultsEmployerInterview () {
            this.setResultsIntreviewLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.employerInterviewRequestError = ''

            let InterviewForm = new FormData()
            InterviewForm.append('.method', 'put')
            InterviewForm.append('schedule_status', this.selectecInterview.attributes.schedule_status)
            InterviewForm.append('comments', this.selectecInterview.attributes.comments)

            updateResultsEmployerInterview(InterviewForm, this.selectecInterview.id)
                .then(() => {
                    swal('Actualización correcta!', 'Resultados guardados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalSetResultsEmployerInterview()
                                    this.getEmployerInterviews()
                                    break;
                                default:
                                    console.log('set results employer-interview swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    } else {
                        this.employerInterviewRequestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.setResultsIntreviewLoading = false
                })
        },

        // modal edit employer intreview
        openModalEditEmployerInterview (pEmployerIntreview) {
            this.clearInputsAndStates()
            this.selectecInterview = JSON.parse(JSON.stringify(pEmployerIntreview))
            this.$bvModal.show('modal-edit-employer-interview')
        },
        closeModalEditEmployerInterview () {
            this.$bvModal.hide('modal-edit-employer-interview')
            this.clearInputsAndStates()
        },
        editEmployerInterview () {
            this.editIntreviewLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false

            let InterviewForm = new FormData()
            InterviewForm.append('.method', 'put')
            InterviewForm.append('start_date', this.selectecInterview.attributes.start_date)
            InterviewForm.append('start_time', this.selectecInterview.attributes.start_time)
            InterviewForm.append('end_date', this.selectecInterview.attributes.start_date)
            InterviewForm.append('end_time', this.selectecInterview.attributes.end_time)
            InterviewForm.append('link', this.selectecInterview.attributes.link)
            InterviewForm.append('room_type', this.selectecInterview.attributes.room_type)
            InterviewForm.append('schedule_description', this.selectecInterview.attributes.schedule_description)

            updateEmployerInterview(InterviewForm, this.selectecInterview.id)
                .then(() => {
                    swal('Actualización correcta!', 'Entrevista actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalEditEmployerInterview()
                                    this.getEmployerInterviews()
                                    break;
                                default:
                                    console.log('edit employer-interview swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editIntreviewLoading = false
                })
        },

        // modal edit postulation
        openModalEditPostulation () {
            this.clearInputsAndStates()
            this.selectecPostulation = JSON.parse(JSON.stringify(this.postulation))
            this.$bvModal.show('modal-edit-postulation')
        },
        closeModalEditPostulation () {
            this.$bvModal.hide('modal-edit-postulation')
            this.clearInputsAndStates()
        },
        editCurrentPostulation () {
            this.editPostulationLoading = true
            this.modalInputErrors = {}
            this.initialModalInputValues = false

            let PostulationForm = new FormData()
            PostulationForm.append('.method', 'put')
            PostulationForm.append('comments', this.selectecPostulation.comments)
            PostulationForm.append('status', this.selectecPostulation.status)

            updatePostulation(PostulationForm, this.selectecPostulation.id)
                .then(({ data }) => {
                    this.postulation = data.data.attributes
                    swal('Actualización correcta!', 'Postulación actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalEditPostulation()
                                    break;
                                default:
                                    console.log('edit postulation swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editPostulationLoading = false
                })
        },

        // globar input methods
        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            // add interview
            this.start_date = ''
            this.start_time = ''
            this.end_date = ''
            this.end_time = ''
            this.subject = ''
            this.link = ''
            this.room_type = 'url'
            this.schedule_description = ''
            // edit interview
            this.selectecInterview = {
                attributes: {}
            }
            // edit postulation
            this.selectecPostulation = {}
            //
            this.sponsorInterviewRequestError = ''
            this.employerInterviewRequestError = ''
        },
        
        //
        deleteInterview (pSponsorIntreview) {
            swal(`Se eliminará.`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pSponsorIntreview, 'deleteLoading', true)
                            destroySponsorInterview(pSponsorIntreview.id)
                                .then(() => {
                                    this.$set(pSponsorIntreview, 'hide', true)
                                })
                                .finally(() => {
                                    pSponsorIntreview.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-deleteSponsorInterview-swal-break');
                            break;
                    }
                })
        },

        deleteEmployerInterview (pSponsorIntreview) {
            swal(`Se eliminará.`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pSponsorIntreview, 'deleteLoading', true)
                            destroyEmployerInterview(pSponsorIntreview.id)
                                .then(() => {
                                    this.$set(pSponsorIntreview, 'hide', true)
                                })
                                .finally(() => {
                                    pSponsorIntreview.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-employer-rInterview-swal-break');
                            break;
                    }
                })
        },
    }
};
</script>

<style scoped>
@media (min-width: 320px) {
    .over {
        overflow-x: auto;
    }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>