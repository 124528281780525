<template>
    <span>
        <b-button variant="transparent" title="Reembolsar" size="sm" @click="modalClick">
            <b-iconstack>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                    <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                    <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                    <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/></svg>
                <b-icon stacked icon="arrow-counterclockwise" variant="cielo" scale="2"></b-icon>
            </b-iconstack>
        </b-button>

        <b-modal
            :id="`modal-refund-voucher-${order}`"
            centered
            hide-footer
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">REGISTRAR REEMBOLSO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12" lg="6">
                            <b-form @submit.prevent="createRefundVoucher">
                                <b-row class="mb-4">
                                    <b-col>
                                        <b-list-group>
                                            <b-list-group-item variant="primary">
                                                <b-row>
                                                    <b-col cols="12">{{ voucher_data.attributes.voucher_concept }}</b-col>
                                                </b-row>
                                            </b-list-group-item>
                                       
                                            <b-list-group-item variant="outile-primary">
                                                <b-row>
                                                    <b-col cols="12">{{ voucher_data.attributes.amount_format }}</b-col>
                                                </b-row>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </b-col>
                                </b-row>

                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group label="Código de operación del voucher:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="number"
                                                placeholder="Ingrese el código de operación"
                                                v-model="code"
                                                :state="showInputStatus('code')"
                                                v-show="!loading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('code')"
                                                :key="`${i}-ud-code`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group label="Voucher:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-file
                                                v-model="voucher_image"
                                                :state="showInputStatus('image')"
                                                v-show="!loading"
                                                style="overflow:hidden;"
                                                @change="showImagePreview"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('image')"
                                                :key="`${i}-ud-image`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- preview -->
                                    <b-col cols="12" class="d-block d-lg-none mb-4">
                                        <b-img :src="preview_image" fluid class="voucher-preview" rounded="" ></b-img>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-form-group label="Comentarios:">
                                            <pulse-loader
                                                :loading="loading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese un commentario. (opcional)"
                                                v-model="comments"
                                                :state="showInputStatus('comments')"
                                                v-show="!loading"
                                                rows="3"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('comments')"
                                                :key="`${i}-ud-comments`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>

                        <!-- preview -->
                        <b-col cols="12" lg="6" class="d-none d-lg-block" >
                            <b-img
                                :src="preview_image"
                                fluid
                                class="voucher-preview"
                                rounded=""
                            ></b-img>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </span>
</template>

<script>
import { storeRefundVoucher } from '@/api/RefundedVoucher/RefundedVoucher'
import swal from 'sweetalert'

export default {
    name: 'RefundVoucherComponent',
    props: {
        voucherData: {
            type: Object,
            required: true
        },
        ui: {
            type: String,
            required: true
        },
        order: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        voucher_data: {
            attributes: {}
        },
        //
        code: '',
        voucher_image: null,
        preview_image: null,
        comments: '',
        loading: false,
        inputErrors: {},
        initialInputValues: true
    }),

    methods: {
        modalClick() {
            this.voucher_data = JSON.parse(JSON.stringify(this.voucherData))
            this.$bvModal.show(`modal-refund-voucher-${this.order}`)
        },
        modalClose() {
            this.$bvModal.hide(`modal-refund-voucher-${this.order}`)
            this.clearInputsAndStates()
        },

        createRefundVoucher() {
            this.loading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let Form = new FormData()
            Form.append('type', this.voucher_data.type)
            Form.append('type_id', this.voucher_data.id)
            Form.append('code', this.code)
            Form.append('image', this.voucher_image)
            Form.append('comments', this.comments)

            storeRefundVoucher(Form)
                .then(() => {
                    swal('Registrado!', '', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.modalClose()
                                    this.$emit('created')
                                    break;

                                default:
                                    console.log('create--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                })
                .finally(() => {
                    this.loading = false
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.initialInputValues = true
            this.inputErrors = {}

            this.code = ''
            this.voucher_image = null
            this.preview_image = null
            this.comments = ''
        },

        showImagePreview (e) {
            if (e.target.files.length >= 1) {
                // capturar img del input
                let image = e.target.files[0]
                //crear reader
                let reader = new FileReader()
                // código al cargar la img
                reader.onload = (ee) => {
                    this.preview_image = ee.target.result
                }
                // asignar la img
                reader.readAsDataURL(image)
            } else {
                this.preview_image = null
            }
        },
    }
}
</script>

<style scoped>
.voucher-preview {
    border:1px solid rgba(0, 0, 0, 0.15);
}
</style>