<template>
    <b-row>
        <!-- programs - seasons -->
        <b-col cols="12" class="mb-4">
            <program-seasons-list> </program-seasons-list>
        </b-col>

        <!-- programs -->
        <b-col cols="12" lg="5" class="mb-4">
            <programs-list> </programs-list>
        </b-col>

        <!-- seasons -->
        <b-col cols="12" lg="7">
            <seasons-list> </seasons-list>
        </b-col>

        
    </b-row>
</template>

<script>
// import SeasonsList  from '@/views/Admin/Programs/components/SeasonsList'
import SeasonsList  from './components/SeasonsList.vue'
import ProgramSeasonsList from './components/ProgramSeasonsList.vue'
import ProgramsList from './components/ProgramsList.vue'

export default {
    // name: 'ProgramsSeasons',
    components: {
        SeasonsList,
        ProgramSeasonsList,
        ProgramsList
    }
}
</script>