<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>

            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="..." />
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none pl-0">
                    <!-- notifications -->
                    <!-- <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <i class="ni ni-bell-55"></i>
                        </a>

                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Something else here</a>
                    </base-dropdown> -->

                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link" href="#" role="button">
                            <div class="media align-items-center">
                              <span class="avatar avatar-sm bg-lighter">
                                <img 
                                    alt="Image placeholder"
                                    src="/img/icons/apk/logo-programs-262x262.png"
                                    style="transform: scale(.7)">
                              </span>
                            </div>
                        </a>

                        <intranet-logout />
                    </base-dropdown>
                </ul>
            </slot>

            <slot></slot>

            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4 collapse-brand d-flex justify-content-center">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-4 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <!-- dashboard by role -->

                <b-form-row>
                    <b-input-group>
                        <b-form-select v-model="roleSelected" :options="currentRouteRoles" @change="roleRedirect" class="text-center">
                            <template #first>
                                <b-form-select-option
                                    value=""
                                    disabled
                                    class="text-muted text-violeta"
                                >
                                    {{ currentRouteRoleName }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-input-group>
                </b-form-row>
                <!--Divider-->
                <hr class="my-3">

                <ul class="navbar-nav pl-0">
                    <slot name="links">
                    </slot>
                </ul>

                <slot name="links-programs">
                </slot>

                <slot name="links-layouts-admin">
                </slot>

                <!--Divider-->
                <hr class="my-3">
            </div>
        </div>
    </nav>
</template>

<script>
import NavbarToggleButton from '@/components/NavbarToggleButton'
import IntranetLogout from '@/layout/Logout/IntranetLogout.vue'

  export default {
    name: 'sidebar',
    components: {
        NavbarToggleButton,
        IntranetLogout
    },
    props: {
        logo: {
            type: String,
            default: '/img/brand/imagotipo-w&t-524x170.png',
            description: 'Sidebar app logo'
        },
        autoClose: {
            type: Boolean,
            default: true,
            description: 'Whether sidebar should autoclose on mobile when clicking an item'
        }
    },
    data: () => ({
        roleSelected: '',
        currentRouteRoleName: '',
        currentRouteRoles: []
    }),

    computed: {
        roles () {
            return this.$store.getters['user/getActiveRoles']
        }
    },

    provide() {
        return {
            autoClose: this.autoClose
        };
    },

    beforeMount () {
        this.setCurrentRouteRoleName()
        this.setCurrentRouteRoleOptions()
    },

    methods: {
        closeSidebar() {
            this.$sidebar.displaySidebar(false)
        },

        showSidebar() {
            this.$sidebar.displaySidebar(true)
        },

        roleRedirect (e) {
            console.log('changed to : ', e);
            switch (this.roleSelected) {
                case 'admin':
                    this.$router.push({ name: 'admin-dashboard'})
                    break;

                case 'empleado':
                    this.$router.push({ name: 'employee-dashboard'})
                    break;

                case 'participante':
                    this.$router.push({ name: 'participant-dashboard'})
                    break;

                case 'postulante':
                    this.$router.push({ name: 'postulant-dashboard'})
                    break;

                default:
                    console.log('sideBar-roleRedirect-switch-default-break')
                    break;
            }
        },

        setCurrentRouteRoleOptions () {
            this.currentRouteRoles = this.roles.filter(e => {
                switch (e.value) {
                    case 'admin':
                        if (this.$route.matched[0].path == '/admin') return false
                        else return true

                    case 'empleado':
                        if (this.$route.matched[0].path == '/employee') return false
                        else return true

                    case 'participante':
                        if (this.$route.matched[0].path == '/participant') return false
                        else return true

                    case 'postulante':
                        if (this.$route.matched[0].path == '/postulant') return false
                        else return true

                    default:
                        console.log('sideBar-setCurrentRouteRoleOptions-switch-default-break')
                        break;
                }
            })
        },

        setCurrentRouteRoleName () {
            switch (this.$route.matched[0].path) {
                case '/admin':
                    this.currentRouteRoleName = 'ADMIN'
                    break;

                case '/employee':
                    this.currentRouteRoleName = 'EMPLEADO'
                    break;

                case '/participant':
                    this.currentRouteRoleName = 'PARTICIPANTE'
                    break;

                case '/postulant':
                    this.currentRouteRoleName = 'POSTULANTE'
                    break;

                default:
                    console.log('sideBar-setCurrentRouteRoleName-switch-default-break')
                    break;
            }
        }
    },

    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
