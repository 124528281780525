import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function destroyVoucher (pFormdata) {
    const URI = `${BASE_URL}/api/delete-voucher`
    return axios.post(URI, pFormdata, { headers })
}

export { destroyVoucher }