<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Documentos
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="modalAddParticipantDocumentClick">
                                <b-icon icon="plus"></b-icon>
                            </b-button>

                            <b-button
                                variant="schedule-talk-1" size="sm"
                                @click="modalDocumentStatusClick"
                                :disabled="loadingTableData"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                </svg>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableDataSorted"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="text-center">grupo</th>
                            <th>documento</th>
                            <th class="text-center">preview</th>
                            <th class="text-center">VERIFICAR</th>
                            <th>observaciones</th>
                            <th class="text-center">estado</th>
                            <th class="text-center">historial <br/>  de acciones</th>
                            <th class="text-center">eliminar</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                             <td :class="[row.attributes.group_name == 'UNO' ? 'text-naranja' : (row.attributes.group_name == 'DOS' ? 'text-cielo' : (row.attributes.group_name == 'TRES' ? 'text-contract-payment-1' : 'text-schedule-english-2'))]" class="text-center">
                                {{ row.attributes.group_name }}
                            </td>
                            
                            <td >{{ row.attributes.name }}</td>

                            <td class="text-center">
                                <b-button
                                    v-show="row.attributes.name != 'VIDEO' && row.attributes.name != 'VIDEO APLICACIÓN LIFEGUARD'"
                                    :variant="row.attributes.file ? 'outline-primary' : 'outline-light'"
                                    size="sm" @click="dowloadFIle(row)"
                                ><b-icon icon="download" scale="1.2"
                                    :animation="row.downloadLoading ? 'cylon-vertical' : ''"
                                    :variant="row.downloadLoading ? 'naranja' : ''"
                                ></b-icon></b-button>

                                <b-button
                                    v-show="row.attributes.name != 'VIDEO' && row.attributes.name != 'VIDEO APLICACIÓN LIFEGUARD'"
                                    :variant="row.attributes.file ? 'outline-contract-1' : 'outline-light'" :disabled="row.downloadLoading"
                                    size="sm" @click="modalContractPreviewClick(row.attributes.file)"
                                ><b-icon icon="eye" scale="1.2"></b-icon></b-button>

                                <b-button
                                    :variant="row.attributes.file ? 'outline-primary' : 'outline-light'"
                                    size="sm" :href="row.attributes.file" target="_black"
                                ><b-icon icon="link" scale="1.2"></b-icon>
                                </b-button>
                            </td>

                            <td class="text-center">
                                <b-btn size="sm" @click="modalDocumentEditClick(row.attributes)" v-if="row.attributes.name != 'CONTRATO'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                    </svg>
                                </b-btn>
                            </td>

                            <td>
                                <div v-if="row.attributes.participantDocumentDescription">
                                    {{ row.attributes.participantDocumentDescription }}
                                </div>
                            </td>

                            <td style="width:120px">
                                <traffic-light :status="row.attributes.status"/>
                            </td>

                            <td class="text-center">
                                <participant-document-actions-component :document="row.attributes" :order="row.attributes.id"/>
                            </td>

                            <td class="text-center">
                                <pulse-loader
                                    :loading="row.deleteLoading || false"
                                    :color="'#EF4A81'"
                                    :margin="'2px'"
                                    :size="8"
                                    class="pt-2 pb-1"
                                ></pulse-loader>

                                <b-btn variant="transparent" size="sm"
                                    v-show="!row.deleteLoading && row.attributes.status == 'rojo'"
                                    @click="deleteParticipantDocument(row, index)"
                                ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- modal document edit -->
        <b-modal
            id="modal-document-edit"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">VERIFICAR DOCUMENTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalDocumentEditClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- resource form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editDocument">
                                <!-- group_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Grupo: "
                                        >
                                            <b-form-input
                                                type="text"
                                                v-model="documentSelected.group_name"
                                                disabled
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                <!-- name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre: "
                                        >
                                            <b-form-input
                                                type="text"
                                                v-model="documentSelected.name"
                                                disabled
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- switch -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            :label="documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD' ? '¿Actualizar link?' : '¿Actualizar documento?'"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-checkbox
                                                v-model="switchDocument"
                                                v-show="!documentEditLoading"
                                                switch
                                                value="si"
                                                unchecked-value="no"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- file -->
                                <transition name="slide-fade">
                                <b-form-row v-if="switchDocument == 'si'">
                                    <b-col>
                                        <b-form-group
                                            :label="documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD' ? 'Link del video' : 'Archivo'"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <!-- video-url -->
                                            <b-form-input
                                                placeholder="Ingrese el link del video."
                                                v-model="link"
                                                type="url"
                                                :state="showModalInputStatus('link')"
                                                v-show="!documentEditLoading && (documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD')"
                                            />
                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-create-resource-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>

                                            <!-- archivo -->
                                            <b-form-file
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!documentEditLoading && documentSelected.name != 'VIDEO' && documentSelected.name != 'VIDEO APLICACIÓN LIFEGUARD'"
                                                style="overflow:hidden;"
                                            />
                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-create-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                </transition>

                                <!-- status -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Estado:" 
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="documentSelected.status"
                                                :options="statusOptions"
                                                :state="showModalInputStatus('estado')"
                                                v-show="!documentEditLoading"
                                            ></b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('estado')"
                                                :key="`${i}-updateVoucher-status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Observaciones :" 
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="documentSelected.participantDocumentDescription"
                                                :state="showModalInputStatus('description')"
                                                v-show="!documentEditLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="documentEditLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- modal document status -->
        <b-modal
            id="modal-document-status"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">ESTADOS DE  DOCUMENTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalDocumentStatusClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editStatusDocument">
                                <!-- grupo uno -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Estado - documentos grupo uno :"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="participant.doc_group_one_status"
                                                :options="GroupOneOptions"
                                                :state="showModalInputStatus('status')"
                                                v-show="!documentEditLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('status')"
                                                :key="`${i}-updateVoucher-status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description uno-->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción - documentos grupo uno "
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="participant.doc_group_one_comments"
                                                :state="showModalInputStatus('doc_group_one_comments')"
                                                v-show="!documentEditLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('doc_group_one_comments')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- grupo uno -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Estado - documentos grupo dos :"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="participant.doc_group_two_status"
                                                :options="GroupOneOptions"
                                                :state="showModalInputStatus('status')"
                                                v-show="!documentEditLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('status')"
                                                :key="`${i}-updateVoucher-status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description dos-->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción - documentos grupo uno "
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="participant.doc_group_two_comments"
                                                :state="showModalInputStatus('doc_group_two_comments')"
                                                v-show="!documentEditLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('doc_group_two_comments')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="documentEditLoading"
                                        >
                                            GUARDAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- MODAL DOCUMENT PREVIEW  -->
        <b-modal
            id="modal-document-preview"
            centered
            hide-footer
            size="xl"
            header-class="pb-0"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Vista previa</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalDocumentPreviewClose" :disabled="loadingPreview">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row style="overflow-x:auto">
                        <b-col v-show="loadingPreview">
                            <div style="height:250px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col cols="12" v-if="!loadingPreview && itsPdf == 'si'" class="view-wraper-vue-pdf">
                            <vue-pdf-embed :source="documentSelectedToPreview"></vue-pdf-embed>
                        </b-col>

                        <b-col cols="12" v-if="!loadingPreview && itsPdf == 'no'" class="view-wraper">
                            <embed :src="documentSelectedToPreview" class="view-document"/>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

        </b-modal>

        <!-- modal add participant document -->
        <b-modal
            id="modal-add-participant-document"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            body-class="mt-0 pt-0"
        >
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">AGREGAR DOCUMENTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddParticipantDocumentClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <div style="height:150px;" v-show="setLoading">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col v-if="!setLoading" cols="12">
                            <div v-for="(doc, i) in documentsToAdd" :key="`${i}-doc`">
                                <b-list-group>
                                    <b-list-group-item>
                                        <b-row v-show="doc.addLoading ? true : false">
                                            <div style="height:30px;">
                                                <pulse-loader :color="'#5e72e4'" :margin="'10px'" :size="12" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)">
                                                </pulse-loader>
                                            </div>
                                        </b-row>

                                        <b-row v-show="doc.addLoading ? false : true">
                                            <b-col>{{ doc.attributes.name }}</b-col>
                                            <b-col cols="auto" :class="[doc.attributes.group_name == 'UNO' ? 'text-naranja' : (doc.attributes.group_name == 'DOS' ? 'text-cielo' : (doc.attributes.group_name == 'TRES' ? 'text-contract-payment-1' : 'text-schedule-english-2'))]">{{ doc.attributes.group_name }}</b-col>
                                            <b-col cols="auto">
                                                <b-button variant="outline-primary" size="sm" @click="addProgram(doc, i)">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-list-group>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>


    </b-row>
</template>

<script>
// programSeasonParticipant.participantDocuments
import { getProgramSeasonParticipantParticipantDocuments } from '@/api/ProgramSeasonParticipant/ParticipantDocuments';
import { storeParticipantDocument, updateParticipantDocument, destroyParticipantDocument } from '@/api/ParticipantDocument/ParticipantDocument'
import { getProgramSeasonParticipant, updateStatusDocument } from '@/api/ProgramSeasonParticipant/ProgramSeasonParticipant.js'
import { downloadParticipantDocument } from '@/api/Download/ParticipantDocument.js'
import { getProgramDocuments } from '@/api/ProgramSeason/ProgramSeasonProgramDocuments';
import ParticipantDocumentActionsComponent from './ParticipantDocumentActionsComponent.vue';
import TrafficLight from '@/layout/Icons/TrafficLight.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import swal from 'sweetalert'

export default {
    name: "ParticipantDocumentsComponent",
    components: {
        TrafficLight,
        VuePdfEmbed,
        ParticipantDocumentActionsComponent
    },
    props: {
        ui: {
            type: String,
            require: true
        }
    },
    data: () => ({
        //
        switchDocument: 'no',
        // table 
        tableData: [],
        tableDataSorted: [],
        loadingTableData: false,
        // modal edit form
        documentSelected: {},
        statusOptions: [
            { value: 'verde', text: 'verificado' },
            { value: 'amarillo', text: 'pendiente' },
            { value: 'rojo', text: 'no válido' },
        ],
        file : null,
        link : '',
        //
        programSeasonParticipant: {
            attributes: {}
        },
        participant: {},
        GroupOneOptions: [
            { value: 'presentado', text: 'presentado' },
            { value: 'no presentado', text: 'no presentado' },
        ],
        GroupTwoOptions: [
            { value: 'presentado', text: 'presentado' },
            { value: 'no presentado', text: 'no presentado' },
        ],
        //
        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        documentEditLoading: false,
        //
        documentSelectedToPreview: '',
        loadingPreview: false,
        itsPdf: 'no',
        url_blob: '',
        //
        setLoading: false,
        documentsToAdd: [],
    }),
    created() {
        this.getParticipantDocumentsData()
    },

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        },
        adminUserRole () {
            return this.$store.getters['user/getUserRoleByRolname']('admin')
        }
    },

    methods: {
        getParticipantDocumentsData (pLoading = true) {
            if (pLoading) this.loadingTableData = true
            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant  // ok

            getProgramSeasonParticipantParticipantDocuments(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.tableDataSorted = data.data
                    this.sortTable()

                    getProgramSeasonParticipant(this.$route.params.IdProgramSeasonParticipant)
                        .then(({data}) => {
                            this.programSeasonParticipant = data.data
                        })
                        .finally(() => {
                            if (pLoading) this.loadingTableData = false
                        })
                })
        },

        sortTable() {
            this.tableDataSorted = [
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'UNO'),
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'DOS'),
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'TRES'),
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'CUATRO'),
            ]
        },

        modalDocumentEditClick (pDocument) {
            this.documentSelected = { ...pDocument }
            this.$bvModal.show('modal-document-edit')
        },
        modalDocumentEditClose () {
            this.$bvModal.hide('modal-document-edit')
            this.clearInputsAndStates()
        },
        // status modal
         modalDocumentStatusClick () {
            this.participant = { ...this.programSeasonParticipant.attributes }
            this.$bvModal.show('modal-document-status')
        },
        modalDocumentStatusClose () {
            this.$bvModal.hide('modal-document-status')
            this.clearInputsAndStates()
        },

        // Modal edit Methods
        editDocument () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.documentEditLoading = true

            let IdParticipantDocument = this.documentSelected.id  // ok

            let DocumentForm = new FormData()
            DocumentForm.append('.method', 'put')
            DocumentForm.append('file', this.file)
            
            DocumentForm.append('link', this.link)
            DocumentForm.append('participantDocument_id', IdParticipantDocument)
            DocumentForm.append('actualizar_documento', this.switchDocument)

            DocumentForm.append('estado', this.documentSelected.status)
            DocumentForm.append('description', this.documentSelected.participantDocumentDescription == null ? '' : this.documentSelected.participantDocumentDescription)
            //
            if (this.ui == 'employee') {
                DocumentForm.append('personable_type', "App\\Models\\CulturalAdvisor")
                DocumentForm.append('personable_id', this.culturalAdvisorPermissionData.attributes.typeAdvisor_id)
            }
            if (this.ui == 'admin') {
                DocumentForm.append('personable_type', "App\\Models\\Admin")
                DocumentForm.append('personable_id', this.adminUserRole.attributes.idToManage)
            }

            updateParticipantDocument(DocumentForm, IdParticipantDocument)
                .then(() => {
                    swal('Actualización correcta!', 'Documento actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$bvModal.hide('modal-document-edit')
                                    this.clearInputsAndStates()
                                    this.getParticipantDocumentsData(false)
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editDocument err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.documentEditLoading = false
                })
        },

        // Modal edit status  Methods
        editStatusDocument () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.documentEditLoading = true

            let IdProgramSeasonParticipant = this.participant.id  // ok

            let StausForm = new FormData()
            StausForm.append('.method', 'put')
            StausForm.append('doc_group_one_status', this.participant.doc_group_one_status)
            StausForm.append('doc_group_one_comments', this.participant.doc_group_one_comments == null ? '' : this.participant.doc_group_one_comments)
            StausForm.append('doc_group_two_status', this.participant.doc_group_two_status)
            StausForm.append('doc_group_two_comments', this.participant.doc_group_two_comments == null ? '' : this.participant.doc_group_two_comments)

            updateStatusDocument (StausForm, IdProgramSeasonParticipant)
                .then(({ data }) => {
                    // console.log('editDocument res: ', data.data);
                    console.log('editStatus res: ', data);
                    swal('Actualización correcta!', 'Estados actualizados.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$bvModal.hide('modal-document-status')
                                    this.clearInputsAndStates()
                                    this.getParticipantDocumentsData()
                                    break;

                                default:
                                    console.log('editStatus--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editStatus err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.documentEditLoading = false
                    console.log('request editStatus end');
                })
        },

        //
        modalAddParticipantDocumentClick () {
            this.setDocumentsToAdd()
            this.$bvModal.show('modal-add-participant-document')
        },
        modalAddParticipantDocumentClose () {
            this.$bvModal.hide('modal-add-participant-document')
        },

        setDocumentsToAdd() {
            this.setLoading = true
            getProgramDocuments(this.programSeasonParticipant.attributes.programSeason_id)
                .then(({ data }) => {
                    this.documentsToAdd = [
                        ...data.data.filter(el => el.attributes.group_name == 'UNO' && el.attributes.status == 'activado'),
                        ...data.data.filter(el => el.attributes.group_name == 'DOS' && el.attributes.status == 'activado'),
                        ...data.data.filter(el => el.attributes.group_name == 'TRES' && el.attributes.status == 'activado'),
                        ...data.data.filter(el => el.attributes.group_name == 'CUATRO' && el.attributes.status == 'activado')
                    ]
                    this.documentsToAdd = this.documentsToAdd.filter(el => {
                        let add = 'si'
                        this.tableDataSorted.forEach(el_table_sorted => {
                            if(el.attributes.name == el_table_sorted.attributes.name ) add = 'no'
                        })
                        return add == 'si'
                    })

                })
                .finally(() => {
                    this.setLoading = false
                })
        },

        addProgram(pParticipantDocument, pIndex) {
            this.$set(pParticipantDocument, 'addLoading', true)

            let DocumentForm = new FormData()
            DocumentForm.append('programSeasonParticipant_id', this.$route.params.IdProgramSeasonParticipant)
            DocumentForm.append('programDocument_id', pParticipantDocument.attributes.id)

            storeParticipantDocument(DocumentForm)
                .then(({ data }) => {
                    this.tableDataSorted.push(data.data)
                    this.sortTable()
                    this.documentsToAdd.splice(pIndex, 1)
                    if (this.documentsToAdd.length == 0 ) this.modalAddParticipantDocumentClose()
                    swal('Creación correcta!', '', 'success')
                })
                .finally(() => {
                    pParticipantDocument.addLoading = false
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.documentSelected = {}
            this.participant = {}
            this.switchDocument = 'no'
            this.file = null
            this.link = ''
        },
        reloadData () {
            this.getParticipantDocumentsData()
        },
        //
        deleteParticipantDocument(pParticipantProgram, pIndex) {
            swal('Se eliminará el documento.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pParticipantProgram, 'deleteLoading', true)
                            destroyParticipantDocument(pParticipantProgram.attributes.id)
                                .then(() => {
                                    this.tableDataSorted.splice(pIndex, 1)
                                })
                                .finally(() => {
                                    pParticipantProgram.deleteLoading = false
                                })
                            break;
                        default:
                            console.log('delete-part-doc-swal-break');
                            break;
                    }
                })
        },

        //
        modalContractPreviewClick (pDocumentToPreview, ) {
            this.$bvModal.show('modal-document-preview')
            this.setDocummentToshow(pDocumentToPreview)
        },
        modalDocumentPreviewClose () {
            this.$bvModal.hide('modal-document-preview')
        },
        setDocummentToshow(pDocumentToPreview) {
            this.loadingPreview = true

            console.log(pDocumentToPreview.split('.'));

            switch (pDocumentToPreview.split('.')[4]) {
                case 'pdf':
                    this.itsPdf = 'si'
                    break;
                case 'jpg':
                case 'jpeg':
                case 'svg':
                case 'jpe':
                case 'webp':
                case 'png':
                    this.itsPdf = 'no'
                    break;
                default:
                    this.itsPdf = 'si'
                    break;
            }

            setTimeout(() => {
                this.documentSelectedToPreview = pDocumentToPreview
                this.loadingPreview = false
            }, 1500);
        },

        dowloadFIle(pParticipantDocument) {
            this.$set(pParticipantDocument, 'downloadLoading', true)
            downloadParticipantDocument(pParticipantDocument.attributes.id)
                .then(res => {
                    console.log(res);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([res.data]));
                    link.setAttribute('download', `${this.programSeasonParticipant.attributes.participant_full_name}-${pParticipantDocument.attributes.name}.${res.data.type.split('/')[1]}`);
                    link.click();
                })
                .finally(() => {
                    pParticipantDocument.downloadLoading = false
                })
        }
    },

}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
  opacity: 0;
}

.view-wraper-vue-pdf {
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 5px;
    background-color: #ccc;
    height: 72vh;
    overflow: auto;
}
.view-wraper {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 5px;
    background-color: #ccc;
    height: 72vh;
    overflow: auto;
}
.vue-pdf-embed > div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.view-document {
    max-width: 100%;
    height: 100%;
}
</style>

