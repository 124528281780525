<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="getLastDocuments(1)"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="getSeasonOptionsLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <!-- employeer : cultural advisor options -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3">
                            <b-form-group class="mb-0 text-primary"
                                label="Asesor Cultural" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="employeersOptionsLoading || loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    v-model="employerSelected"
                                    :options="employeersOptions"
                                    v-show="!employeersOptionsLoading && !loadingTableData"
                                    size="sm"
                                    @change="getLastDocuments(1)"
                                    :disabled="ui == 'employee'"
                                >
                                    <template #first v-if="ui == 'admin'">
                                        <b-form-select-option value="" disabled>
                                            -- Seleccione un asesor cultural --
                                        </b-form-select-option>
                                    </template>>
                                </b-form-select>
                            </b-form-group>
                        </b-col>

                        <!-- personable_type :  -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3">
                            <b-form-group class="mb-0 text-primary"
                                label="Acción de:" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="employeersOptionsLoading || loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    v-model="personable_type"
                                    :options="[{ value: 'ProgramSeasonParticipant', text: 'Participante' }, { value: 'CulturalAdvisor', text: 'Asesor Cultural'}]"
                                    v-show="!employeersOptionsLoading && !loadingTableData"
                                    size="sm"
                                    @change="getLastDocuments(1)"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>

                        <!-- pagination -->
                        <b-col cols="12" sm="6" lg="4" class="mt-3">
                            <b-form-group class="mb-0 text-primary"
                                label="Paginación" label-cols="auto" label-size="sm" 
                                >
                                <pulse-loader
                                    :loading="employeersOptionsLoading || loadingTableData"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                        class="text-center"
                                    v-model="paginated_by"
                                    :options="[{ value: 'todos', text: 'Todos' }, {value: 10, text: 10}, {value: 30, text: 30}, {value: 50, text: 50}]"
                                    v-show="!employeersOptionsLoading && !loadingTableData"
                                    size="sm"
                                    @change="getLastDocuments(1)"
                                />
                            </b-form-group>
                        </b-col>

                        <!-- dates -->
                        <b-col cols="12" class="mt-3">
                            <b-form-row>
                            <!-- start_date -->
                                <b-col cols="12" sm="5" lg="4">
                                    <b-form-group class="mb-0 text-schedule-talk-3"
                                        label="Desde :" label-cols="auto" label-size="sm" 
                                        >
                                        <pulse-loader
                                            :loading="employeersOptionsLoading || loadingTableData"
                                            :color="'#E26A2C'"
                                            :margin="'5px'"
                                            :size="8"
                                            class="pt-2 text-center"
                                            style="border-radius:5px; border:1px solid #E26A2C"
                                        />

                                        <b-form-datepicker
                                            class="text-center"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            selected-variant="primary"
                                            today-variant="naranja"
                                            locale="es"

                                            size="sm"

                                            today-button
                                            label-today-button="today"
                                            today-button-variant="outline-naranja"
                                            
                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="start_date"
                                            v-if="!employeersOptionsLoading && !loadingTableData"
                                        />
                                    </b-form-group>
                                </b-col>

                                <!-- end_date -->
                                <b-col cols="12" sm="5" lg="4">
                                    <b-form-group class="mb-0 text-schedule-talk-3"
                                        label="Hasta :" label-cols="auto" label-size="sm" 
                                        >
                                        <pulse-loader
                                            :loading="employeersOptionsLoading || loadingTableData"
                                            :color="'#E26A2C'"
                                            :margin="'5px'"
                                            :size="8"
                                            class="pt-2 text-center"
                                            style="border-radius:5px; border:1px solid #E26A2C"
                                        />

                                        <b-form-datepicker
                                            class="text-center"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            selected-variant="primary"
                                            today-variant="naranja"
                                            locale="es"

                                            size="sm"

                                            today-button
                                            label-today-button="today"
                                            today-button-variant="outline-naranja"
                                            
                                            reset-button
                                            label-reset-button="reset"
                                            reset-button-variant="outline-magenta"

                                            close-button
                                            label-close-button="ok"
                                            close-button-variant="primary"

                                            v-model="end_date"
                                            v-if="!employeersOptionsLoading && !loadingTableData"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="2" lg="4">
                                    <b-button class="w-100" size="sm" variant="schedule-talk-1" type="submit"
                                        :disabled="employeersOptionsLoading || loadingTableData"
                                        @click="getLastDocuments(1)"
                                        ><b-icon icon="search" class="m-0 p-0"/>
                                    </b-button>
                                </b-col>
                            </b-form-row>
                        </b-col>

                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive mb-0" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="alwaysShow details"></th>
                            <th class="alwaysShow name">Participante</th>
                            <th class="text-center bg-employer-interview-2 text-white">Documentos</th>
                            <th>Respons. Cultural</th>
                            <th>programa</th>
                            <th>temporada</th>

                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                            <td class="text-center">
                                <b-btn
                                    :to="{
                                        name: routeDocuments,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                    target="_blank"
                                >
                                    <b-icon icon="three-dots" scale="1.4"></b-icon>
                                </b-btn>
                            </td>

                            <td class="alwaysShow name">{{ row.attributes.participant_full_name }}</td>

                            <!-- Documentos -->
                            <td class="apk-doc-group">
                                <div v-for="(doc, i) in row.attributes.participant_documents" :key="`${i}-parDoc`" class="doc">
                                    <div class="doc-item">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1" :variant="doc.attributes.status == 'verde' ? 'green' : (doc.attributes.status == 'amarillo' ? 'yellow' : 'danger')"></b-icon>
                                        </b-iconstack>
                                    </div>

                                    <div class="doc-item text-left">{{ doc.attributes.name }}</div>
                                    <div class="doc-item text-primary" v-show="personable_type == 'ProgramSeasonParticipant'">{{ doc.attributes.lastestParticipantAction_date }}</div>
                                    <div class="doc-item " v-show="personable_type == 'ProgramSeasonParticipant'">{{ doc.attributes.lastestParticipantAction_action }}</div>

                                    <div class="doc-item text-primary" v-show="personable_type == 'CulturalAdvisor'">{{ doc.attributes.lastestCulturalAdvisorAction_date }}</div>
                                    <div class="doc-item" v-show="personable_type == 'CulturalAdvisor'">{{ doc.attributes.lastestCulturalAdvisorAction_action }}</div>
                                </div>
                            </td>

                            <td>{{ row.attributes.culturalAdvisor_full_name }}</td>
                            <td>{{ row.attributes.program}}</td>
                            <td>{{ row.attributes.season}}</td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-if="!loadingTableData && paginated_by != 'todos'">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
import { getAllActiveCulturalAdvisors } from '@/api/CulturalAdvisor/CulturalAdvisor'
import { filterParticipantDocumentsLastUploads } from '@/api/ProgramSeasonParticipant/Filters/ParticipantDocument'

import { dateFormatedToUE } from '@/helpers/date'

export default {
    name: "LastDocumentsComponent",
    props: {
        ui: {
            type: String,
            required: true
        },
        routeDocuments: {
            type: String,
            required: true
        }
    },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        paginated_by: 30,
        personable_type: 'ProgramSeasonParticipant',
        start_date: '',
        end_date: '',
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
        //
        employeersOptions: [],
        employerSelected: '',
        employeersOptionsLoading: false,
    }),

    created() {
        this.setTimes()
        this.getSeasonsData()
    },

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        }
    },

    methods: {
        setTimes() {
            this.start_date = dateFormatedToUE(new Date())
            this.end_date = dateFormatedToUE(new Date())
        },

        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    // get cultural advisors with status active
                    this.getEmployeers()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getEmployeers() {
            this.employeersOptionsLoading = true

            getAllActiveCulturalAdvisors()
                .then(({ data }) => {
                    this.employeersOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name}) )

                    if (this.ui == 'admin' && this.employerSelected) {
                        this.getLastDocuments()
                    }

                    if (this.ui == 'employee') {
                        this.employerSelected = this.culturalAdvisorPermissionData.attributes.typeAdvisor_id
                        this.getLastDocuments()
                    }
                })
                .finally(() => {
                    this.employeersOptionsLoading = false
                })
        },

        /**search */
        getLastDocuments (pPage = 1) {
            this.loadingTableData = true
            let IdCulturalAdvisor = this.employerSelected   // ok

            let params = {
                culturalAdvisor_id    :  IdCulturalAdvisor,
                season_id             :  this.seasonSelected,

                paginated_by          :  this.paginated_by,
                personable_type       :  this.personable_type,
                start_date            :  this.start_date,
                end_date              :  this.end_date,
            }
            if(this.paginated_by != 'todos') params.page = pPage

            filterParticipantDocumentsLastUploads(params)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        paginationCLiked (e) {
            this.getLastDocuments(e)
        },


        reloadData () {
            this.getSeasonsData()
        },
    }
}
</script>

<style scoped>
.apk-doc-group {
    position: relative;
}
.apk-doc-dump {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 4px 8px;
    background: #000;
    border-radius: 5px;
    font-size: 10pt;
    color: white;
    opacity: .75;
    transition: .300s ease-in all;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
}
.apk-doc:hover ~ .apk-doc-dump {
    transform: translate(-50%, -50%) scale(1);
}
/*  */
.alwaysShow {
    /* background-color: rgb(94, 114, 228, .05); */
    z-index: 10;
}
@media (min-width: 576px) {
    .alwaysShow {
        position: sticky;
        background-color: #f7f8fe;
        border-bottom: 2px solid #e9ecef !important;
    }
}
.alwaysShow.details {
    left: 0px;
}
.alwaysShow.name {
    left: 80px;
}
.doc {
    display: flex;
}
.doc-item {
    margin-right: 8px;
}
</style>