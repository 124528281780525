<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header>
                    <h3 class="mb-3">
                        Detalles participante ({{ this.$route.params.id}})  ( usuario:{{ participantData.attributes.user_id }}, participante {{ participantData.attributes.id}} )
                    </h3>
                </b-card-header>

                <b-card-text>
                    <ul>
                        <li> nombre: {{ this.participantData.attributes.name }}</li>
                        <li> apellido: {{ this.participantData.attributes.last_name }}</li>
                        <li> tipo doc: {{ this.participantData.attributes.document_type }}</li>
                        <li> documento: {{ this.participantData.attributes.document_number }}</li>
                        <li> celular: {{ this.participantData.attributes.phone_number }}</li>
                        <li> correo: {{ this.participantData.attributes.email }}</li>
                        <li> descripción: {{ this.participantData.attributes.description }}</li>
                        <li> estado de usuario: {{ this.participantData.attributes.user_status }}</li>
                        <li> estado de empleado: {{ this.participantData.attributes.participant_status }}</li>
                    </ul>
                </b-card-text>
            </b-card>
        </b-col>

        <b-col cols="12" lg="7" class="mt-4 mt-lg-0">
            <manage-roles 
                v-if="showRoles"
                :userId="participantData.attributes.user_id"
                role="participante"
            />
        </b-col>
    </b-row>
</template>

<script>
import { getParticipant } from '@/api/Participant/Participant'
import ManageRoles from '@/layout/Admin/ManageRoles.vue'
import swal from 'sweetalert'

export default {
    name: 'ParticipantsDetails',
    components: { ManageRoles},
    data: () => ({
        participantData: {
            attributes: {
                //participant
                id: '',
                user_id: '',
                description: '',
                participant_status: '',
                //user
                name: '',
                last_name: '',
                document_type: '',
                document_number: '',
                phone_number: '',
                email: '',
                user_status: '',
            }
        },
        //
        loadingParticipantData: false,
        showRoles: false
    }),
    created () {
        this.getParticipantData()
    },
    methods: {
        getParticipantData () {
            this.loadingParticipantData = true

            getParticipant(this.$route.params.id) //IdParticipant
                .then(({data}) => {
                    console.log('admin data', data);
                    this.participantData = data.data
                    this.showRoles = true
                })
                .catch(err => {
                    console.log('err', err)
                    console.log('err.response', err.response)
                    if (err.response.status === 404) {
                        swal('404', 'Recurso no encontrado', 'warning')
                            .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.$router.push({ name: 'admin-participants-list' })
                                        break;

                                    default:
                                        console.log('adminDetails-getParticipant-swal-default-break');
                                        break;
                                }
                            })
                    }
                })
                .finally(() => {
                    this.loadingParticipantData = false
                    console.log('request getParticipant end')
                })
        },
    },

}
</script>