import { get } from 'axios'
import store from '@/store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function filterPayment (pParams) {
    let uri = `${BASE_URL}/api/programSeasonParticipant/filters/payment`
    return get(uri, { headers, params: pParams })
}

export { filterPayment }