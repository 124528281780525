<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                LISTA
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="searchLead(1)"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchLead(1)">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                placeholder="Búsqueda por nombres o apellidos o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col >
                                        <b-form-group class="mb-0">
                                            <b-button class="w-100" size="sm" variant="primary" type="submit" >
                                                <b-icon icon="search" class="m-0 p-0"/>
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th></th>
                            <th>lead</th>
                            <th>Tipo</th>
                            <th>Responsable</th>
                            <th>programa - temporada - estado</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <span>{{ index + 1 }}</span>
                            </td>

                            <td>
                                <b-btn v-if="row.type == 'ProgramSeasonPostulant-Lead'"
                                    :to="{
                                        name: routeToPostulantsDetails,
                                        params: { 
                                            'IdProgramSeasonPostulant': row.attributes.id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="contract-1" target="_blank"
                                    size="sm"><b-icon icon="three-dots" variant="white"></b-icon>
                                </b-btn>

                                <b-btn v-if="row.type == 'ProgramSeasonParticipant-Lead'"
                                    :to="{
                                        name: routeToParticipantsDetails,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="contract-payment-1" target="_blank"
                                    size="sm" ><b-icon icon="three-dots" variant="white"></b-icon>
                                </b-btn>
                            </td>

                            <td>{{ row.attributes.user_full_name }}</td>

                            <!-- tipo -->
                            <td :class="[row.type == 'ProgramSeasonPostulant-Lead' ? 'text-contract-1' : 'text-contract-payment-1']">
                                {{ row.type == 'ProgramSeasonPostulant-Lead' ? 'Postulante' : 'Participante' }}
                            </td>

                            <!-- asesor -->
                            <td>
                                <span  v-show="row.type == 'ProgramSeasonPostulant-Lead'">
                                    <div v-if="row.attributes.salesAdvisor_id">
                                        <span class="text-primary">ventas :</span> {{ row.attributes.salesAdvisor_full_name }}
                                    </div>

                                    <div v-if="row.attributes.culturalAdvisor_id || row.attributes.culturalAdvisorAssigned_id">
                                        <span class="text-primary">cultural :</span> {{ row.attributes.culturalAdvisor_id ? row.attributes.culturalAdvisor_full_name : row.attributes.culturalAdvisorAssigned_full_name }}
                                    </div>
                                </span>

                                <span  v-show="row.type == 'ProgramSeasonParticipant-Lead'">
                                    <div  v-if="row.attributes.postulant_salesAdvisor_id">
                                        <span class="text-primary">ventas :</span> {{ row.attributes.postulant_salesAdvisor_full_name }}
                                    </div>
                                    <div><span class="text-primary">cultural :</span> {{ row.attributes.culturalAdvisor_full_name }}</div>
                                </span>
                            </td>

                            <td class="text-uppercase">
                                <span :class="[row.attributes.program_id == 1 ? 'text-primary' : (row.attributes.program_id == 2 || row.attributes.program_id == 3 ? 'text-cielo' : (row.attributes.program_id == 4 ? 'text-naranja' : 'text-magenta')) ]">
                                    {{ row.attributes.program == 'work and travel' ? 'wat' : row.attributes.program}} - {{ row.attributes.season}} 
                                </span> - 
                                <span :class="[row.attributes.status == 'activo' ? 'text-green' : 'text-danger']">{{ row.attributes.status}}</span>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { seasonLeadSearch } from '@/api/Season/LeadSearch';
import { getAllSeasons } from '@/api/Season/Season'

export default {
    name: "PostulantsList",
    props: {
        routeToPostulantsDetails: {
            type: String,
            required: true
        },
        routeToParticipantsDetails: {
            type: String,
            required: true
        }
    },
    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        input_to_search: '',
        //
        seasonOptions: [],
        seasonSelected: 1,
        getSeasonOptionsLoading: false,
    }),
    created() {
        this.searchLead()
        this.getSeasonsData()
    },
    methods: {
        /**search */
        searchLead (pPage = 1) {
            this.loadingTableData = true
            let IdSeason = this.seasonSelected

            const SearchForm = new FormData()
            SearchForm.append('input_to_search', this.input_to_search)
            SearchForm.append('test', 'no')

            seasonLeadSearch(IdSeason, SearchForm, pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        // get all seasons
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        reloadData () {
            this.searchLead()
            this.getSeasonsData()
        }
    }
}
</script>