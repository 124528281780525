<template>
    <b-row>
        <b-col cols="12" lg="5" class="mb-5 mb-lg-0">
            <b-card class="shadow">
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Contrato
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="naranja" size="sm" @click="openEditContractModal">
                                <b-icon icon="pencil" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="dataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        class="text-center"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!dataLoading" class="mt-5">
                    <b-row v-show="contract.type == 'html'" class="mb-2">
                        <b-col class="text-right">
                                <pulse-loader
                                :loading="deleteHtmlContractLoading"
                                :color="'#EF4A81'"
                                :margin="'2px'"
                                :size="8"
                                class="pt-2 pb-1"
                            ></pulse-loader>

                            <b-button variant="danger" size="sm" @click="deleteHtmlContract" v-show="!deleteHtmlContractLoading">
                                <b-icon icon="trash" scale="1.3" class="mr-2"></b-icon>Borrar Contrato Html
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col>Estado</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col :class="contract.status == 'expirado' ? 'text-magenta' : 'text-green'">
                                    {{ contract.status == 'valido' ? 'válido' : contract.status }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Responsable</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ contract.culturalAdvisor_full_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Postulante</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ contract.programSeasonPostulant_full_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-show="contract.type == 'pdf'">
                            <b-row>
                                <b-col>Doc.</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <b-btn
                                        :href="contract.generated_file" target="_blanck"
                                        size="sm" variant="outline-primary"
                                    ><b-icon icon="file-earmark-text" scale="1.3"></b-icon></b-btn>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Costo</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ contract.total_cost_format }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Fecha de expiración</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <b-icon icon="calendar-fill" class="mr-3" variant="primary"></b-icon>
                                    {{ contract.expiration_date_formated }}
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Descripción</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ contract.description ? contract.description : '- - - - - - - - - -' }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <b-col cols="12" lg="7">
            <b-card class="shadow" body-class="px-0 pb-0">
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Pagos
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openAddPaymentModal" v-if="contractPayments.length != 12">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loading -->
                <div style="height:300px;" v-show="dataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        class="text-center"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table - paymments -->
                <b-card no-body v-show="!dataLoading" class="mt-5">
                    <div class="table-responsive mb-0" >
                        <base-table class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="contractPayments"
                        >
                            <template slot="columns">
                                <th>#</th>
                                <th>estado</th>
                                <th>concepto</th>
                                <th>monto</th>
                                <th>fecha límite</th>
                                <th></th>
                            </template>

                            <template slot-scope="{row, index}">
                                <td>{{ (index + 1) }}</td>
                                <td>
                                    <traffic-light :status="row.attributes.status"/>
                                </td>
                                <td>{{ row.attributes.payment_concept }}</td>
                                <td>{{ row.attributes.amount_format }}</td>
                                <td>{{ row.attributes.expiration_at_formated }}</td>

                                <td>
                                    <div v-show="row.loading || false">
                                        <pulse-loader
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center"
                                        ></pulse-loader>
                                    </div>

                                    <div v-show="!row.loading && true">
                                        <b-button
                                            variant="transparent" size="sm" @click="openEditPaymentModal(row.attributes)">
                                            <b-icon icon="pencil" scale="1.2" variant="naranja"></b-icon>
                                        </b-button>

                                        <b-button
                                            v-show="row.attributes.payment_concept != 'Primera Cuota' && row.attributes.status == 'rojo'"
                                            variant="transparent" size="sm" @click="deleteContractPayment(row)">
                                            <b-icon icon="trash" scale="1.2" variant="magenta"></b-icon>
                                        </b-button>
                                    </div>
                                </td>
                            </template>
                        </base-table>
                    </div>
                </b-card>
            </b-card>
        </b-col>

        <!-- 
            modal edit contract
            -->
        <b-modal
            id="modal-edit-contract"
            centered
            hide-footer
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
            body-class="mt-0 pt-0"
        >
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR CONTRATO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="closeEditContractModal">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="typeOfUpdate" >
                                <!-- html -->
                                <b-row v-if="contract.type == 'html'" class="mb-5">
                                    <!-- layout cards -->
                                    <transition name="slide-fade">
                                    <b-container v-if="isLayoutSelected == 'no'">
                                        <b-row class="border border-contract-1 mb-5" style="border-radius:5px">
                                            <!-- loading -->
                                            <b-col v-show="layoutsLoading">
                                                <div style="height:175px;">
                                                        <pulse-loader 
                                                        :color="'#1572A1'"
                                                        :margin="'10px'"
                                                        :size="16"
                                                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                                                </div>
                                            </b-col>

                                            <!-- cards -->
                                            <b-col cols="auto" v-show="!layoutsLoading" class="text-right mt-3">
                                                <pulse-loader
                                                    :loading="getSeasonOptionsLoading"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="8"
                                                    class="pt-2 text-center"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-select
                                                    class="text-center"
                                                    size="sm"
                                                    v-model="seasonSelected"
                                                    :options="seasonOptions"
                                                    v-show="!getSeasonOptionsLoading"
                                                    @change="loadHtmlLayouts"
                                                />
                                            </b-col>

                                            <!-- cards -->
                                            <b-col cols="12" style="display:inline-flex; overflow:auto" v-show="!layoutsLoading">
                                                <div style="display:inline-flex;" class="my-3">
                                                    <b-card class="layout-card" no-body v-for="(layout, i) in contractLayouts" :key="`${i}-contract-layout`" @click="setLayout(layout)">
                                                        <svg class="layout-card--icon" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                                        </svg>
                                                        <span class="layout-card--name">{{ layout.attributes.name }}</span>
                                                    </b-card>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    </transition>

                                    <b-col cols="12" class="text-right mb-4">
                                        <b-button size="sm" variant="contract-1" @click="resetLayout" v-show="isLayoutSelected == 'si'" title="Seleccionar otra plantilla."><b-icon icon="chevron-left" class="mr-2"></b-icon>Seleccionar otra plantilla</b-button>
                                    </b-col>

                                    <!-- tiptap -->
                                    <transition name="slide-fade">
                                    <tiptap
                                        :getHtml="editorOutputRequest"
                                        :initialBodyValue="contractBodyHtmlValue"
                                        :initialFooterValue="contractFooterHtmlValue"
                                        @output="editContract" :uiMode="'employee'"
                                        v-if="isLayoutSelected == 'si'"></tiptap>
                                    </transition>
                                </b-row>

                                <!-- pdf -->
                                <b-form-row v-if="contract.type == 'pdf'">
                                    <b-col>
                                        <b-form-group
                                            label="Contrato (opcional):" 
                                        >
                                            <pulse-loader
                                                :loading="contractLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-file
                                                v-model="generated_file"
                                                :state="showModalInputStatus('generated_file')"
                                                v-show="!contractLoading"
                                                style="overflow:hidden;"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('generated_file')"
                                                :key="`${i}-create-contract-generated_file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- expiration_date -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Expiración de contracto *:"
                                        >
                                            <pulse-loader
                                                :loading="contractLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-datepicker
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="selectedContract.expiration_date"
                                                :state="showModalInputStatus('expiration_date')"
                                                v-if="!contractLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('expiration_date')"
                                                :key="`${i}-contract-expiration_date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- total_cost -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Costo:" 
                                        >
                                            <pulse-loader
                                                :loading="contractLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="number"
                                                step="0.01"
                                                v-model="selectedContract.total_cost"
                                                :state="showModalInputStatus('total_cost')"
                                                v-show="!contractLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('total_cost')"
                                                :key="`${i}-update-contract-total_cost`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="contractLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="selectedContract.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!contractLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-contract-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center" class="mt-4">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            ACTUALIZAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- 
            modal add payment
            -->
        <b-modal
            id="modal-add-payment"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
            body-class="mt-0 pt-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">AGREGAR PAGO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="closeAddPaymentModal">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="addContractPayment('cuota_2')" v-if="cuota_2">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="cuota_2_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!cuota_2_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > Segunda Cuota : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="cuota_2_amount"
                                                :state="showModalInputStatusCuota2('cuota_2_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="cuota_2_expiration_date"
                                                :state="showModalInputStatusCuota2(`cuota_2_expiration_date`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('cuota_3')" v-if="cuota_3">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="cuota_3_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!cuota_3_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > Tercera Cuota : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="cuota_3_amount"
                                                :state="showModalInputStatusCuota3('cuota_3_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="cuota_3_expiration_date"
                                                :state="showModalInputStatusCuota3(`cuota_3_expiration_date`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('cuota_4')" v-if="cuota_4">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="cuota_4_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!cuota_4_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > Cuarta Cuota : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="cuota_4_amount"
                                                :state="showModalInputStatusCuota4('cuota_4_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="cuota_4_expiration_date"
                                                :state="showModalInputStatusCuota4(`cuota_4_expiration_date`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('cuota_5')" v-if="cuota_5">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="cuota_5_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!cuota_5_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > Quinta Cuota : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="cuota_5_amount"
                                                :state="showModalInputStatusCuota5('cuota_5_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="cuota_5_expiration_date"
                                                :state="showModalInputStatusCuota5(`cuota_5_expiration_date`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('sevis')" v-if="sevis">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="sevis_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!sevis_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > SEVIS : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="sevis_amount"
                                                :state="showModalInputStatusSevis('sevis_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="sevis_expiration_date"
                                                :state="showModalInputStatusSevis(`sevis_expiration_date`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('visa')" v-if="visa">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="visa_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!visa_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > RECOJO/VISA : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="visa_amount"
                                                :state="showModalInputStatusVisa('visa_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="visa_expiration_date"
                                                :state="showModalInputStatusVisa(`visa_expiration_date`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                             <b-form @submit.prevent="addContractPayment('derecho_consular')" v-if="derecho_consular">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="derecho_consular_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!derecho_consular_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > DERECHO CONSULAR : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="derecho_consular_amount"
                                                :state="showModalInputStatusDerechoConsular('derecho_consular_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="derecho_consular_expiration_date"
                                                :state="showModalInputStatusDerechoConsular('derecho_consular_expiration_date')"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                             <b-form @submit.prevent="addContractPayment('comportamiento')" v-if="comportamiento">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="comportamiento_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!comportamiento_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > BUEN COMPORTAMIENTO : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="comportamiento_amount"
                                                :state="showModalInputStatusComportamiento('comportamiento_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="comportamiento_expiration_date"
                                                :state="showModalInputStatusComportamiento('comportamiento_expiration_date')"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('wwce_fees')" v-if="wwce_fees">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="wwce_fees_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!wwce_fees_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > WWCE FEES : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="wwce_fees_amount"
                                                :state="showModalInputStatusWwceFees('wwce_fees_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="wwce_fees_expiration_date"
                                                :state="showModalInputStatusWwceFees('wwce_fees_expiration_date')"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('wwce_housing')" v-if="wwce_housing">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="wwce_housing_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!wwce_housing_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > WWCE HOUSING : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="wwce_housing_amount"
                                                :state="showModalInputStatusWwceHousing('wwce_housing_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="wwce_housing_expiration_date"
                                                :state="showModalInputStatusWwceHousing('wwce_housing_expiration_date')"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <b-form @submit.prevent="addContractPayment('iena_insurance')" v-if="iena_insurance">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="iena_insurance_loading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!iena_insurance_loading">
                                            <b-input-group-prepend>
                                                <b-input-group-text > IENA INSURANCE : </b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="iena_insurance_amount"
                                                :state="showModalInputStatusIenaInsurance('iena_insurance_amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="iena_insurance_expiration_date"
                                                :state="showModalInputStatusIenaInsurance('iena_insurance_expiration_date')"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-green" type="submit">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- 
            modal edit payment
            -->
        <b-modal
            id="modal-edit-payment"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
            body-class="mt-0 pt-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR PAGO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="closeEditPaymentModal">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="editPayment">
                                <b-form-row>
                                    <b-col cols="12" class="mb-3">
                                        <pulse-loader
                                            :loading="paymentLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-input-group v-show="!paymentLoading">
                                            <b-input-group-prepend>
                                                <b-input-group-text class="text-primary">{{ selectedPayment.payment_concept }} :</b-input-group-text>
                                            </b-input-group-prepend>

                                            <!-- amount -->
                                            <b-form-input
                                                v-model="selectedPayment.amount"
                                                :state="showModalInputStatus('amount')"
                                                number type="number" min="0.00" step="0.01" class="text-center"
                                            ></b-form-input>

                                            <!-- expiration_date * at -->
                                            <b-form-datepicker
                                                style="width:max-content"
                                                required
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="selectedPayment.expiration_at"
                                                :state="showModalInputStatus(`expiration_at`)"
                                            ></b-form-datepicker>

                                            <b-input-group-append>
                                                <b-button variant="outline-primary" type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save2" viewBox="0 0 16 16">
                                                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                                                    </svg>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getContract } from '@/api/Contract/Contract'
import { storeContractPayment,destroyContractPayment, updateContractPayment } from '@/api/ContractPayment/ContractPayment'

import { updateContract } from '@/api/Contract/Contract'
//
import TrafficLight from '@/layout/Icons/TrafficLight.vue'
import Tiptap from '@/components/Tiptap/components/Tiptap.vue'
//
import { getSeasonHtmlLayouts } from '@/api/HtmlLayout/HtmlLayout'
import { destroyHtmlContract } from '@/api/HtmlContract/HtmlContract'
//
import { getAllSeasons } from '@/api/Season/Season'

import swal from 'sweetalert'

export default {
    name: "ContractDetailsComponent",
    components: {
        TrafficLight,
        Tiptap,
    },
    data: () => ({
        contract: {},
        contractPayments: [],
        dataLoading: false,
        // to tiptap
        contractFooterHtmlValue: '',
        contractBodyHtmlValue: '',
        editorOutputRequest: 1,
        //
        contractLayouts: {},
        layoutsLoading: false,
        isLayoutSelected: 'si',
        //
        generated_file: null,
        //
        cuota_2: true,
        cuota_2_expiration_date: '',
        cuota_2_amount: 0,
        cuota_2_loading: false,

        cuota_3: true,
        cuota_3_expiration_date: '',
        cuota_3_amount: 0,
        cuota_3_loading: false,

        cuota_4: true,
        cuota_4_expiration_date: '',
        cuota_4_amount: 0,
        cuota_4_loading: false,

        cuota_5: true,
        cuota_5_expiration_date: '',
        cuota_5_amount: 0,
        cuota_5_loading: false,

        sevis: true,
        sevis_expiration_date: '',
        sevis_amount: 0,
        sevis_loading: false,

        visa: true,
        visa_expiration_date: '',
        visa_amount: 0,
        visa_loading: false,

        derecho_consular: true,
        derecho_consular_expiration_date: '',
        derecho_consular_amount: 0,
        derecho_consular_loading: false,

        comportamiento: true,
        comportamiento_expiration_date: '',
        comportamiento_amount: 0,
        comportamiento_loading: false,

        wwce_fees: true,
        wwce_fees_expiration_date: '',
        wwce_fees_amount: 0,
        wwce_fees_loading: false,

        wwce_housing: true,
        wwce_housing_expiration_date: '',
        wwce_housing_amount: 0,
        wwce_housing_loading: false,

        iena_insurance: true,
        iena_insurance_expiration_date: '',
        iena_insurance_amount: 0,
        iena_insurance_loading: false,
        //
        selectedPayment: {},
        paymentLoading: false,
        //
        selectedContract: {},
        contractLoading: false,
        //
        modalInputErrors: {},
        initialModalInputValues: true,
        initialModalInputValuesCuota2: true,
        initialModalInputValuesCuota3: true,
        initialModalInputValuesCuota4: true,
        initialModalInputValuesCuota5: true,
        initialModalInputValuesSevis: true,
        initialModalInputValuesVisa: true,
        initialModalInputValuesDerechoConsular: true,
        initialModalInputValuesComportamiento: true,
        initialModalInputValuesWwceFees: true,
        initialModalInputValuesWwceHousing: true,
        initialModalInputValuesIenaInsurance: true,
        createContractLoading: false,
        //
        preview: 1,
        html_contract: {},
        //
        deleteHtmlContractLoading: false,
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
    }),

    created() {
        this.getSeasonsData()
    },

    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    this.getContractData()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },
        
        getContractData () {
            this.dataLoading = true
            let IdContract = this.$route.params.IdContract    // ok
            getContract(IdContract)
                .then(({ data }) => {
                    this.contract = data.data.attributes
                    this.contractPayments = data.data.attributes.contractPayments
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },

    // htmlLayouts
        loadHtmlLayouts () {
            this.layoutsLoading = true
            getSeasonHtmlLayouts(this.seasonSelected)
                .then(({ data }) => {
                    this.contractLayouts = data.data.filter(el => el.attributes.type == 'contract')
                })
                .finally(() => {
                    this.layoutsLoading = false
                })
        },

        setLayout(pLayout) {
            this.contractBodyHtmlValue = pLayout.attributes.body
            this.contractFooterHtmlValue = pLayout.attributes.footer
            this.isLayoutSelected = 'si'
        },

        resetLayout() {
            swal('Se reemplazará el contenido.', '¿Desea seleccionar otra plantilla?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, Seleccionar otra plantilla']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.isLayoutSelected = 'no'
                            this.contractBodyHtmlValue = ''
                            this.contractFooterHtmlValue = ''
                            this.loadHtmlLayouts()
                            break;
                        case null:
                            break;
                        default:
                            console.log('select other layout');
                            break;
                    }
                })
        },

        // delete
        deleteContractPayment (pContractPayment) {
            this.$set(pContractPayment, "loading", true)
            destroyContractPayment(pContractPayment.attributes.id)
                .then(() => {
                    swal('Eliminación correcta!', 'Pago eliminado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getContractData()
                                    break;
                                default:
                                    console.log('delete-contract-peyment--swal-break');
                                    break;
                            }
                        })
                })
                .finally(()=>{
                    pContractPayment.loading = false
                })
        },

        // Modal ADD PAYMENT Methods
        openAddPaymentModal () {
            this.clearInputsAndStates()
            this.setPaymentOptions()
            this.$bvModal.show('modal-add-payment')
        },
        closeAddPaymentModal () {
            this.$bvModal.hide('modal-add-payment')
            this.clearInputsAndStates()
        },

        setPaymentOptions () {
            for (let payment of this.contractPayments) {
                switch (payment.attributes.payment_concept) {
                    case 'Segunda Cuota':
                        this.cuota_2 = false
                        break;

                    case 'Tercera Cuota':
                        this.cuota_3 = false
                        break;

                    case 'Cuarta Cuota':
                        this.cuota_4 = false
                        break;

                    case 'Quinta Cuota':
                        this.cuota_5 = false
                        break;

                    case 'SEVIS':
                        this.sevis = false
                        break;

                    case 'RECOJO/VISA':
                        this.visa = false
                        break;

                    case 'DERECHO CONSULAR':
                        this.derecho_consular = false
                        break;

                    case 'BUEN COMPORTAMIENTO':
                        this.comportamiento = false
                        break;

                    case 'WWCE FEES':
                        this.wwce_fees = false
                        break;

                    case 'WWCE HOUSING':
                        this.wwce_housing = false
                        break;

                    case 'IENA INSURANCE':
                        this.iena_insurance = false
                        break;

                    default:
                        break;
                }
            }
        },

        addContractPayment (pPaymentConcept) {
            let IdContract = this.$route.params.IdContract    // ok
            let PaymentForm  = new FormData()

            switch (pPaymentConcept) {
                case 'cuota_2':
                    this.cuota_2_loading = true
                    this.initialModalInputValuesCuota2 = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('cuota_2', 'si')
                    PaymentForm.append('cuota_2_amount', this.cuota_2_amount == 0 ? '' : this.cuota_2_amount)
                    PaymentForm.append('cuota_2_expiration_date', this.cuota_2_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            console.log('se creoo nuevo');
                            this.cuota_2 = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.cuota_2_loading = false
                        })
                    break;

                case 'cuota_3':
                    this.cuota_3_loading = true
                    this.initialModalInputValuesCuota3 = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('cuota_3', 'si')
                    PaymentForm.append('cuota_3_amount', this.cuota_3_amount == 0 ? '' : this.cuota_3_amount)
                    PaymentForm.append('cuota_3_expiration_date', this.cuota_3_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            console.log('se creoo nuevo');
                            this.cuota_3 = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.cuota_3_loading = false
                        })
                    break;
                
                case 'cuota_4':
                    this.cuota_4_loading = true
                    this.initialModalInputValuesCuota4 = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('cuota_4', 'si')
                    PaymentForm.append('cuota_4_amount', this.cuota_4_amount == 0 ? '' : this.cuota_4_amount)
                    PaymentForm.append('cuota_4_expiration_date', this.cuota_4_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            console.log('se creoo nuevo');
                            this.cuota_4 = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.cuota_4_loading = false
                        })
                    break;

                case 'cuota_5':
                    this.cuota_5_loading = true
                    this.initialModalInputValuesCuota5 = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('cuota_5', 'si')
                    PaymentForm.append('cuota_5_amount', this.cuota_5_amount == 0 ? '' : this.cuota_5_amount)
                    PaymentForm.append('cuota_5_expiration_date', this.cuota_5_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            console.log('se creoo nuevo');
                            this.cuota_5 = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.cuota_5_loading = false
                        })
                    break;

                case 'sevis':
                    this.sevis_loading = true
                    this.initialModalInputValuesSevis = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('sevis', 'si')
                    PaymentForm.append('sevis_amount', this.sevis_amount == 0 ? '' : this.sevis_amount)
                    PaymentForm.append('sevis_expiration_date', this.sevis_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            console.log('se creoo nuevo');
                            this.sevis = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.sevis_loading = false
                        })
                    break;

                case 'visa':
                    this.visa_loading = true
                    this.initialModalInputValuesVisa = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('visa', 'si')
                    PaymentForm.append('visa_amount', this.visa_amount == 0 ? '' : this.visa_amount)
                    PaymentForm.append('visa_expiration_date', this.visa_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            console.log('se creoo nuevo');
                            this.visa = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.visa_loading = false
                        })
                    break;

                case 'derecho_consular':
                    this.derecho_consular_loading = true
                    this.initialModalInputValuesDerechoConsular = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('derecho_consular', 'si')
                    PaymentForm.append('derecho_consular_amount', this.derecho_consular_amount == 0 ? '' : this.derecho_consular_amount)
                    PaymentForm.append('derecho_consular_expiration_date', this.derecho_consular_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            this.derecho_consular = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.derecho_consular_loading = false
                        })
                    break;

                case 'comportamiento':
                    this.comportamiento_loading = true
                    this.initialModalInputValuesComportamiento = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('comportamiento', 'si')
                    PaymentForm.append('comportamiento_amount', this.comportamiento_amount == 0 ? '' : this.comportamiento_amount)
                    PaymentForm.append('comportamiento_expiration_date', this.comportamiento_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            this.comportamiento = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.comportamiento_loading = false
                        })
                    break;

                case 'wwce_fees':
                    this.wwce_fees_loading = true
                    this.initialModalInputValuesWwceFees = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('wwce_fees', 'si')
                    PaymentForm.append('wwce_fees_amount', this.wwce_fees_amount == 0 ? '' : this.wwce_fees_amount)
                    PaymentForm.append('wwce_fees_expiration_date', this.wwce_fees_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            this.wwce_fees = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.wwce_fees_loading = false
                        })
                    break;

                case 'wwce_housing':
                    this.wwce_housing_loading = true
                    this.initialModalInputValuesWwceHousing = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('wwce_housing', 'si')
                    PaymentForm.append('wwce_housing_amount', this.wwce_housing_amount == 0 ? '' : this.wwce_housing_amount)
                    PaymentForm.append('wwce_housing_expiration_date', this.wwce_housing_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            this.wwce_housing = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.wwce_housing_loading = false
                        })
                    break;

                case 'iena_insurance':
                    this.iena_insurance_loading = true
                    this.initialModalInputValuesIenaInsurance = false
                    PaymentForm.append('contract_id', IdContract)
                    PaymentForm.append('iena_insurance', 'si')
                    PaymentForm.append('iena_insurance_amount', this.iena_insurance_amount == 0 ? '' : this.iena_insurance_amount)
                    PaymentForm.append('iena_insurance_expiration_date', this.iena_insurance_expiration_date)
                    storeContractPayment(PaymentForm)
                        .then(()=> {
                            this.iena_insurance = false
                            this.getContractData()
                        })
                        .catch(err => {
                            if (err.response.status == 422) {
                                Object.assign(this.modalInputErrors, err.response.data.errors)
                            }
                        })
                        .finally(() => {
                            this.iena_insurance_loading = false
                        })
                    break;

                default:
                    break;
            }

        },

        // Modal EDIT PAYMENT Methods
        openEditPaymentModal (pContractPayment) {
            this.clearInputsAndStates()
            this.selectedPayment = { ...pContractPayment }
            this.$bvModal.show('modal-edit-payment')
        },
        closeEditPaymentModal () {
            this.$bvModal.hide('modal-edit-payment')
            this.clearInputsAndStates()
        },

        editPayment() {
            this.paymentLoading = true
            this.initialModalInputValues = false

            let ContractPaymentForm = new FormData()
            ContractPaymentForm.append('.method', 'put')
            ContractPaymentForm.append('expiration_at', this.selectedPayment.expiration_at)
            ContractPaymentForm.append('amount', this.selectedPayment.amount == 0 ? '' : this.selectedPayment.amount)

            updateContractPayment(ContractPaymentForm, this.selectedPayment.id)
                .then(() => {
                    swal('Actualización correcta!', 'Pago actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getContractData()
                                    this.$bvModal.hide('modal-edit-payment')
                                    break;

                                default:
                                    console.log('edit-payment--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.paymentLoading = false
                })
        },

        // Modal EDIT CONTRACT Methods
        openEditContractModal () {
            this.clearInputsAndStates()
            this.selectedContract = { ...this.contract }
            //
            this.contractBodyHtmlValue = this.selectedContract.HtmlContract.body
            this.contractFooterHtmlValue = this.selectedContract.HtmlContract.footer
            //
            this.$bvModal.show('modal-edit-contract')
        },
        closeEditContractModal () {
            this.$bvModal.hide('modal-edit-contract')
            this.clearInputsAndStates()
            this.isLayoutSelected = 'si'
        },

        typeOfUpdate() {
            if (this.contract.type == 'html') this.getCurrentEditorOutput()
            if (this.contract.type == 'pdf') this.editContract()
        },

        getCurrentEditorOutput() {
            this.editorOutputRequest += 1
        },

        editContract (pOutput = {}) {
            this.initialModalInputValues = false
            this.contractLoading = true
            let IdContract = this.$route.params.IdContract    // ok
            let ContractForm = new FormData()
            ContractForm.append('.method', 'put')
            ContractForm.append('expiration_date', this.selectedContract.expiration_date)
            ContractForm.append('total_cost', this.selectedContract.total_cost)
            ContractForm.append('description', this.selectedContract.description)

            if (this.contract.type == 'pdf') {
                ContractForm.append('generated_file', this.generated_file)
            }
            if (this.contract.type == 'html') {
                ContractForm.append('body' , pOutput.body)
                ContractForm.append('footer' , pOutput.footer)
            }
            ContractForm.append('type', this.contract.type)

            updateContract(ContractForm, IdContract)
                .then(() => {
                    swal('Actualización correcta!', 'Contrato actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getContractData()
                                    this.$bvModal.hide('modal-edit-contract')
                                    break;

                                default:
                                    console.log('edit-Contract--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.contractLoading = false
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusCuota2 (pInput) {
            if (this.initialModalInputValuesCuota2) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusCuota3 (pInput) {
            if (this.initialModalInputValuesCuota3) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusCuota4 (pInput) {
            if (this.initialModalInputValuesCuota4) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusCuota5 (pInput) {
            if (this.initialModalInputValuesCuota5) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusSevis (pInput) {
            if (this.initialModalInputValuesSevis) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusVisa (pInput) {
            if (this.initialModalInputValuesVisa) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusDerechoConsular (pInput) {
            if (this.initialModalInputValuesDerechoConsular) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusComportamiento (pInput) {
            if (this.initialModalInputValuesComportamiento) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusWwceFees (pInput) {
            if (this.initialModalInputValuesWwceFees) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusWwceHousing (pInput) {
            if (this.initialModalInputValuesWwceHousing) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },
        showModalInputStatusIenaInsurance (pInput) {
            if (this.initialModalInputValuesIenaInsurance) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.initialModalInputValuesCuota2 = true
            this.initialModalInputValuesCuota3 = true
            this.initialModalInputValuesCuota4 = true
            this.initialModalInputValuesCuota5 = true
            this.initialModalInputValuesSevis = true
            this.initialModalInputValuesVisa = true
            this.initialModalInputValuesDerechoConsular = true
            this.initialModalInputValuesWwceFees = true
            this.initialModalInputValuesWwceHousing = true
            this.initialModalInputValuesIenaInsurance = true
            this.modalInputErrors = {}
            //
            this.generated_file = null
            //
            this.cuota_2 = true
            this.cuota_2_amount = 0
            this.cuota_2_expiration_date = ''

            this.cuota_3 = true
            this.cuota_3_amount = 0
            this.cuota_3_expiration_date = ''

            this.cuota_4 = true
            this.cuota_4_amount = 0
            this.cuota_4_expiration_date = ''

            this.cuota_5 = true
            this.cuota_5_amount = 0
            this.cuota_5_expiration_date = ''

            this.sevis = true
            this.sevis_amount = 0
            this.sevis_expiration_date = ''

            this.visa = true
            this.visa_amount = 0
            this.visa_expiration_date = ''

            this.derecho_consular = true
            this.derecho_consular_amount = 0
            this.derecho_consular_expiration_date = ''

            this.comportamiento = true,
            this.comportamiento_amount = 0,
            this.comportamiento_expiration_date = ''

            this.wwce_fees = true,
            this.wwce_fees_amount = 0,
            this.wwce_fees_expiration_date = ''
            
            this.wwce_housing = true,
            this.wwce_housing_amount = 0,
            this.wwce_housing_expiration_date = ''
            
            this.iena_insurance = true,
            this.iena_insurance_amount = 0,
            this.iena_insurance_expiration_date = ''
            //
            this.selectedPayment = {}
            //
            this.selectedContract = {}
        },

        reloadData () {
            this.getSeasonsData()
        },

        showPreviewFromContract(pHtmlContract) {
            this.preview += 1
            this.html_contract = pHtmlContract
        },

        deleteHtmlContract() {
            swal('Se eliminará el Contrato Html.', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar.']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.deleteHtmlContractLoading = true
                            destroyHtmlContract(this.contract.HtmlContract.id)
                                .then(()=> {
                                    this.reloadData()
                                })
                                .finally(() => {
                                    this.deleteHtmlContractLoading = false
                                })
                            break;

                        case null:
                            break;

                        default:
                            console.log('delete-alert-break');
                            break;
                    }
                })
        }
         
    }
}
</script>

<style scoped>
.layout-card {
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;
    padding: 8px 10px ;
    margin-right: 15px;
    border-radius: 5px;
    border: 2px dashed #1572A1;
    color: #1572A1;
    cursor: pointer;
    background: rgba(94, 114, 228, 0.0);
    background: rgba(21, 114, 161, 0.0);
    transition: all 360ms ease-in-out;
}
.layout-card:hover {
    background: rgba(21, 114, 161, 0.1);
}
.layout-card--icon {
    margin-bottom: 10px;
}
.layout-card--name {
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
/* .slide-fade-leave-active {
  transition: all .5s ease;
} */
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
</style>