import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// programSeasonSponsor.programSponsorJobs
function getProgramSeasonSponsorProgramSponsorJobs (pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsors/${pIdProgramSeasonSponsor}/programSponsorJobs`
    return axios.get(URI, { headers })
}

function getProgramSeasonSponsorProgramSponsorJobsLite (pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsor/${pIdProgramSeasonSponsor}/programSponsorJobs/lite-collection`
    return axios.get(URI, { headers })
}

export {
    getProgramSeasonSponsorProgramSponsorJobs,
    getProgramSeasonSponsorProgramSponsorJobsLite
}