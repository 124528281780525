<template>
    <b-row>
        <b-col cols="12" class="mt-5 mt-lg-0">
            <b-card class="shadow" no-body>
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-primary text-button">
                                DOCUMENTOS GENERALES
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalCreateGeneralDocument" :disabled="getLoading">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:250px;" v-show="getLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card
                    no-body
                    v-if="!getLoading"
                >
                    <div class="table-responsive" >
                        <table class="table tablesorter align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th></th>
                                    <th></th>
                                    <th class="text-center">estado</th>
                                    <th>Nombre de Doc. </th>
                                    <th class="text-center">grupo </th>
                                    <th class="text-center">auto creación </th>
                                    <th>descripción </th>
                                </tr>
                            </thead>

                            <tbody class="list">
                                <tr v-for="(item, i) in documentsSorted" :key="i+'docOne'">
                                    <td>{{ i + 1 }}</td>

                                    <td class="text-center">
                                        <b-btn variant="transparent" size="sm" @click="openModalEditGeneralDocument(item.attributes)"><b-icon icon="pencil" scale="1.1" variant="naranja"></b-icon></b-btn>
                                    </td>

                                    <td class="text-center">
                                        <pulse-loader
                                            :loading="item.deleteLoading || false"
                                            :color="'#EF4A81'"
                                            :margin="'2px'"
                                            :size="8"
                                            class="pt-2 pb-1"
                                        ></pulse-loader>

                                        <b-btn variant="transparent" size="sm"
                                            v-show="!item.deleteLoading && true"
                                            @click="deleteGeneralDocument(i, item)"
                                        ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                        </b-btn>
                                    </td>

                                    <td class="text-center">
                                        <badge class="badge-dot"><i :class="item.attributes.status == 'activado' ? 'bg-green' : 'bg-danger'"></i></badge>
                                    </td>

                                    <td>{{ item.attributes.name }}</td>

                                    <td :class="[item.attributes.group_name == 'UNO' ? 'text-naranja' : (item.attributes.group_name == 'DOS' ? 'text-cielo' : (item.attributes.group_name == 'TRES' ? 'text-contract-payment-1' : 'text-schedule-english-2'))]" class="text-center">
                                        {{ item.attributes.group_name }}
                                    </td>

                                    <td :class="[item.attributes.auto_create == 'si' ? 'text-primary' : 'text-light']" class="text-center">{{ item.attributes.auto_create }}</td>

                                    <td>{{ item.attributes.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-card>
            </b-card>
        </b-col>

        <!-- modal create-->
        <b-modal
                id="modal-create-general-document"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
            >
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    Crear
                                </h3>
                            </b-col>

                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalCreateGeneralDocument" :disabled="createLoading">
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <template #default>
                    <b-container>
                        <b-form @submit.prevent="createGeneralDocument">
                            <b-form-row>
                                <!-- name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Nombre :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-input
                                            class="text-uppercase"
                                            type="text"
                                            placeholder="Ingrese el nombre del documento."
                                            v-model="name"
                                            :state="showInputStatus('name')"
                                            v-if="!createLoading"
                                        ></b-form-input>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('name')"
                                            :key="`${i}-name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- group_name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Grupo :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-select
                                            v-model="group_name"
                                            :state="showInputStatus('group_name')"
                                            v-if="!createLoading"
                                            :options="[{ value: 'UNO', text: 'UNO'}, { value: 'DOS', text: 'DOS'}, { value: 'TRES', text: 'TRES'}, { value: 'CUATRO', text: 'CUATRO'}]"
                                            @change="evalAutoComplete"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>SELECCIONA UN GRUPO</b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('group_name')"
                                            :key="`${i}-group_name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-textarea
                                            v-model="description"
                                            :state="showInputStatus('description')"
                                            v-if="!createLoading"
                                            rows="3"
                                        ></b-form-textarea>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('description')"
                                            :key="`${i}-description`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- auto_create -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Registro automático en nuevos programas :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-radio-group
                                            :options="[{ value: 'no', text: 'No' },{ value: 'si', text: 'Si' },]"
                                            v-model="auto_create"
                                            :state="showInputStatus('auto_create')"
                                            v-show="!createLoading"
                                            stacked plain
                                            :disabled="group_name == 'TRES'"
                                        ></b-form-radio-group>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('auto_create')"
                                            :key="`${i}-auto_create`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <b-button class="w-100 my-2" variant="primary" type="submit" size="md" :disabled="createLoading">Crear</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
        </b-modal>

        <!-- modal edit-->
        <b-modal
                id="modal-edit-general-document"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
            >
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    Editar
                                </h3>
                            </b-col>

                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalEditGeneralDocument" :disabled="updateLoading">
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <template #default>
                    <b-container>
                        <b-form @submit.prevent="editGeneralDocument">
                            <b-form-row>
                                <!-- name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Nombre :"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-input
                                            class="text-uppercase"
                                            type="text"
                                            placeholder="Ingrese el nombre del documento."
                                            v-model="documentSelected.name"
                                            :state="showInputStatus('name')"
                                            v-if="!updateLoading"
                                        ></b-form-input>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('name')"
                                            :key="`${i}-name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- group_name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Grupo :"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-select
                                            v-model="documentSelected.group_name"
                                            :state="showInputStatus('group_name')"
                                            v-if="!updateLoading"
                                            :options="[{ value: 'UNO', text: 'UNO'}, { value: 'DOS', text: 'DOS'}, { value: 'TRES', text: 'TRES'}, { value: 'CUATRO', text: 'CUATRO'}]"
                                            @change="evalAutoComplete"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>SELECCIONA UN GRUPO</b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('group_name')"
                                            :key="`${i}-group_name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción :"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-textarea
                                            v-model="documentSelected.description"
                                            :state="showInputStatus('description')"
                                            v-if="!updateLoading"
                                            rows="3"
                                        ></b-form-textarea>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('description')"
                                            :key="`${i}-description`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- auto_create -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Registro automático en nuevos programas :"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-radio-group
                                            :options="[{ value: 'no', text: 'No' },{ value: 'si', text: 'Si' },]"
                                            v-model="documentSelected.auto_create"
                                            :state="showInputStatus('auto_create')"
                                            v-show="!createLoading"
                                            stacked plain
                                            :disabled="group_name == 'TRES'"
                                        ></b-form-radio-group>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('auto_create')"
                                            :key="`${i}-auto_create`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- status -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Estado :"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-select
                                            v-model="documentSelected.status"
                                            :state="showInputStatus('status')"
                                            v-if="!updateLoading"
                                            :options="[{ value: 'activado', text: 'Activado'}, { value: 'desactivado', text: 'Desactivado'}]"
                                        ></b-form-select>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('status')"
                                            :key="`${i}-status`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <b-button class="w-100 my-2" variant="primary" type="submit" size="md" :disabled="updateLoading">Actualizar</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
        </b-modal>
    </b-row>
</template>

<script>
// sponsotJobOffer.jobOfferInput
import { getGeneralDocuments, storeGeneralDocument, updateGeneralDocument, destroyGeneralDocument } from '@/api/GeneralDocument/GeneralDocument';
import swal from 'sweetalert'

export default {
    name: "GeneralDocumentsComponent",
    data: () => ({
        documents: [],
        documentsSorted: [],
        getLoading: false,
        //
        name: '',
        group_name: '',
        description: '',
        auto_create: 'si',
        createLoading: false,
        //
        documentSelected: {},
        updateLoading: false,
        //
        initialInputValues: true,
        inputErrors: {},
    }),

    created() {
        this.getData()
    },

    methods: {
        getData () {
            this.getLoading = true
            getGeneralDocuments()
                .then(({ data }) => {
                    this.documents = data.data
                    this.documentsSorted = [
                        ...this.documents.filter(el => el.attributes.group_name == 'UNO'),
                        ...this.documents.filter(el => el.attributes.group_name == 'DOS'),
                        ...this.documents.filter(el => el.attributes.group_name == 'TRES'),
                        ...this.documents.filter(el => el.attributes.group_name == 'CUATRO')
                    ]
                })
                .finally(() => {
                    this.getLoading = false
                })
        },

        reloadData () {
            this.getData()
        },

        // modal create genreal document
        openModalCreateGeneralDocument () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-create-general-document')
        },

        closeModalCreateGeneralDocument () {
            this.$bvModal.hide('modal-create-general-document')
            this.clearInputsAndStates()
        },

        evalAutoComplete(e) {
            if (e == 'TRES' || e == 'CUATRO') {
                this.auto_create = 'no'
                Object.keys(this.documentSelected).length > 0 ? this.documentSelected.auto_create = 'no' : null
            } else {
                this.auto_create = 'si'
                Object.keys(this.documentSelected).length > 0 ? this.documentSelected.auto_create = 'si' : null
            }
        },

        createGeneralDocument () {
            this.createLoading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let DocumentForm = new FormData()
            DocumentForm.append('name', this.name.toUpperCase())
            DocumentForm.append('group_name', this.group_name)
            DocumentForm.append('description', this.description)
            DocumentForm.append('auto_create', this.auto_create)

            storeGeneralDocument(DocumentForm)
                .then(() => {
                    swal('Creación correcta!', 'Documento general creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalCreateGeneralDocument()
                                    this.getData()
                                    break;

                                default:
                                    console.log('create-gen-doc-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        // modal edit genreal document
        openModalEditGeneralDocument (pGeneralDocument) {
            this.clearInputsAndStates()
            this.documentSelected = { ...pGeneralDocument }
            this.$bvModal.show('modal-edit-general-document')
        },

        closeModalEditGeneralDocument () {
            this.$bvModal.hide('modal-edit-general-document')
            this.clearInputsAndStates()
        },

        editGeneralDocument() {
            this.updateLoading = true
            this.initialInputValues = false
            this.inputErrors = {}

            let DocumentForm = new FormData()
            DocumentForm.append('.method', 'put')
            DocumentForm.append('name', this.documentSelected.name.toUpperCase())
            DocumentForm.append('group_name', this.documentSelected.group_name)
            DocumentForm.append('description', this.documentSelected.description)
            DocumentForm.append('auto_create', this.documentSelected.auto_create)
            DocumentForm.append('status', this.documentSelected.status)

            updateGeneralDocument(DocumentForm, this.documentSelected.id)
                .then(() => {
                    swal('Actualización correcta!', 'Documento actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalEditGeneralDocument()
                                    this.getData()
                                    break;

                                default:
                                    console.log('edit-gen-doc-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateLoading = false
                })
        },


        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.inputErrors = {}
            this.initialInputValues = true

            this.name = ''
            this.group_name = ''
            this.description = ''
            this.auto_create = 'si'

            this.documentSelected = {}

        },

        //
        deleteGeneralDocument (pIndex, pGeneralDocument) {
            swal('Se eliminará el documento.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pGeneralDocument, 'deleteLoading', true)
                            destroyGeneralDocument(pGeneralDocument.attributes.id)
                                .then(() => {
                                    this.documentsSorted.splice(pIndex, 1)
                                })
                                .finally(() => {
                                    pGeneralDocument.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-gen-doc-swal-break');
                            break;
                    }
                })
        }
    }
}
</script>