<template>
    <talks-component></talks-component>
</template>

<script>
import TalksComponent from '@/components/Admin-Employee/Talks/TalksComponent.vue';

export default {
    name: "Talks",
    components: { TalksComponent },
    data: () => ({}),
}
</script>