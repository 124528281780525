import { mergeAttributes } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'

export default Mention.extend({
    name: 'ApkContractMention',
    // addAttributes() {
    //     return {
    //         ...this.parent?.(),
    //         vtext: {
    //             default: null,
    //             parseHTML: (element) => element.getAttribute('v-text'),
    //             renderHTML: (attributes) => ({
    //                 // 'v-text': `${attributes.id}`,
    //                 // 'v-text': `${attributes.id} || '@${attributes.id}'`,
    //                 // ':class': `{ 'empty' : ${attributes.id}.length==0, 'notEmpty' : ${attributes.id}.length>0 }`,
    //             }),
    //         },
    //     }
    // },

    parseHTML() {
        return [
            {
                tag: `span[data-type="ApkContractMention"]`,
            },
        ]
      },

    renderHTML({ node, HTMLAttributes }) {
        return [
          'span',
          mergeAttributes({ 'data-type': 'ApkContractMention' }, this.options.HTMLAttributes, HTMLAttributes),
          this.options.renderLabel({
            options: this.options,
            node,
          }),
        ]
      },
})