<template>
    <general-documents-component></general-documents-component>
</template>

<script>
import GeneralDocumentsComponent from '@/components/Managment/Documents/GeneralDocumentsComponent.vue'

export default {
    name: 'GeneralDocuments',
    components: { GeneralDocumentsComponent },
}
</script>