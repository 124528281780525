import { post } from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function updateProgramSeasonPostulantUserEmail (pFormdata ,pIdProgramSeasonPostulant) {
    const URI = `${BASE_URL}/api/programSeasonPostulant/${pIdProgramSeasonPostulant}/update-user-email`
    return post(URI, pFormdata, { headers })
}

export { updateProgramSeasonPostulantUserEmail }