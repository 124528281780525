import { get } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

function getPrograms (pPage) {
    const URI = `${BASE_URL}/api/programs?page=${pPage}`
    const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }
    return get(URI, { headers })
}

function getAllPrograms () {
    const URI = `${BASE_URL}/api/all-programs`
    const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }
    return get(URI, { headers })
}

export {
    getPrograms,
    getAllPrograms
}