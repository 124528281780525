import { post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//post
function usersSearch (pPage, pFormData) {
    const URI = `${BASE_URL}/api/users-search?page=${pPage}`
    return post(URI, pFormData)
}

export {
    usersSearch,
}