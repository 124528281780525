import { mergeAttributes } from '@tiptap/core'
import Table from '@tiptap/extension-table'

export default Table.extend({
    name: 'TableCustom',

    resizable: true,

    addOptions() {
        return {
            ...this.parent?.(),
        }
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            'data-table-kind': {
                default: 'normal-table',
                parseHTML: (element) => element.getAttribute('data-table-kind'),
                renderHTML: attributes  => {
                    if (attributes['data-table-kind']  == 'grid-table') {
                        return {
                            'data-table-kind': 'grid-table',
                            class: 'tiptap-table tiptap-grid-table'
                        }
                    } else if (attributes['data-table-kind']  == 'normal-table') {
                        return {
                            'data-table-kind': 'normal-table',
                            class: 'tiptap-table tiptap-normal-table'
                        }
                    }
                },
            },
        }
    },

    parseHTML() {
        return [
          {
            tag: 'table[data-type="TableCustom"]'
          },
        ]
      },

    renderHTML({ HTMLAttributes }) {
        return ['table', mergeAttributes({ 'data-type': 'TableCustom' }, this.options.HTMLAttributes, HTMLAttributes), ['tbody', 0]]
    },
})