<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Formularios', path: { name: 'admin-forms' } }"/>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'Documents',
    components: { 
        IntranetNavContent,
        IntranetNavContentLinkExact,
    },
};
</script>
