<template>
    <forms-list-component :ui="ui"></forms-list-component>
</template>

<script>
import FormsListComponent from '@/components/Managment/Forms/FormsListComponent.vue';

export default {
    name: "VisaDocuments",
    components: { FormsListComponent },
    data: () => ({
        ui: 'admin'
    }),
}
</script>