<template>
    <!-- card -->
    <b-card class="shadow" no-body>
        <!-- header -->
        <b-card-header>
            <b-row class="align-items-center">
                <b-col>
                    <h3 class="mb-0 text-primary text-button">
                        PROGRAMAS - TEMPORADAS
                    </h3>
                </b-col>

                <b-col class="text-right">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="modalCreateProgramSeasonClick"
                    >CREAR
                    </b-button>
                </b-col>

                <b-col cols="auto">
                    <b-button variant="primary" size="sm" @click="reloadData">
                        <b-icon icon="arrow-repeat"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-header>

        <!-- laodingTableData -->
        <div style="height:200px;" v-show="loadingTableData">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
        </div>

        <!-- table: programSeasons-->
        <div class="table-responsive" v-show="!loadingTableData">
            <base-table class="table align-items-center table-flush"
                thead-classes="thead-light"
                tbody-classes="list"
                :data="tableData"
            >
                <template slot="columns">
                    <th>#</th>
                    <th>programa</th>
                    <th>temporada</th>
                    <th>full p.</th>
                    <th>self p.</th>
                    <th>descripción</th>
                    <th class="text-center">detalles</th>
                    <th>&nbsp;</th>
                </template>

                <template slot-scope="{row, index}">
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.attributes.program_name }}</td>
                    <td>{{ row.attributes.season_name }}</td>
                    <td>{{ row.attributes.full_placement_cost_formated }}</td>
                    <td class="text-center">
                        {{ row.attributes.self_placement_cost == '$ 0.00' || row.attributes.self_placement_cost == null ? '- - -' : row.attributes.self_placement_cost_formated }}
                    </td>
                    <td>{{ row.attributes.description }}</td>
                    <td class="text-center">
                        <b-btn 
                            :to="{
                                name: 'admin-program-season-details',
                                params: { 'IdProgramSeason': row.id }    //IdProgramSeason
                            }"
                            variant="outline-primary"
                            size="sm"
                        >
                            <b-icon icon="three-dots"></b-icon>
                        </b-btn>
                    </td>
                    <td>
                        <b-button
                            variant="outline-magenta"
                            size="sm"
                            @click="modalEditProgramSeasonClick(row.attributes)"
                        >editar
                        </b-button>
                    </td>
                </template>
            </base-table>
        </div>

        <!-- pagination -->
        <div class="card-footer" v-show="!loadingTableData">
            <base-pagination
                align="center"
                :pageCount="tableMeta.last_page" 
                :perPage="tableMeta.per_page"
                :value="tableMeta.current_page"
                @input="paginationCLiked"
            />
        </div>

        <!-- modal: create-programSeason -->
        <b-modal
            id="modal-create-programSeason"
            centered
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">CREAR PROGRAMA - TEMPORADA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                    clearInputsAndStates()
                                    close()
                                }"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="createProgramSeason" >
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Programa"
                                >
                                    <pulse-loader
                                        :loading="createProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-select 
                                        v-model="programSelected"
                                        :options="programOptions"
                                        :state="showInputStatus('program_id') && showInputStatus('program_code')"
                                        v-show="!createProgramSeasonLoading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione un programa --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_id')" :key="`${i}-createProgSea-program_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_code')" :key="`${i}-createProgSea-program_code`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Temporada"
                                >
                                    <pulse-loader
                                        :loading="createProgramSeasonLoading || getSeasonOptionsLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-select 
                                        v-model="seasonSelected"
                                        :options="seasonOptions"
                                        :state="showInputStatus('season_id') && showInputStatus('program_code')"
                                        v-show="!createProgramSeasonLoading && !getSeasonOptionsLoading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione una temporada --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('season_id')" :key="`${i}-createProgSea-season_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_code')" :key="`${i}-createProgSea-pro-co`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Full placement"
                                >
                                    <pulse-loader
                                        :loading="createProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese el costo de full placement."
                                        v-model="full_placement_cost"
                                        :state="showInputStatus('full_placement_cost')"
                                        v-show="!createProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('full_placement_cost')" :key="`${i}-createProgSea-cost`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Self placement"
                                >
                                    <pulse-loader
                                        :loading="createProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese el costo de self placement."
                                        v-model="self_placement_cost"
                                        :state="showInputStatus('self_placement_cost')"
                                        v-show="!createProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('self_placement_cost')" :key="`${i}-createProgSea-cost`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Descripción"
                                >
                                    <pulse-loader
                                        :loading="createProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-textarea 
                                        placeholder="Ingrese una descripción (opcional)"
                                        v-model="description"
                                        :state="showInputStatus('description')"
                                        v-show="!createProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('description')" :key="`${i}-createProgSea-description`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="8">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    :disabled="createProgramSeasonLoading"
                                >CREAR</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>

        <!-- modal: edit-programSeason -->
        <b-modal
            id="modal-edit-programSeason"
            centered
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR PROGRAMA - TEMPORADA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                    clearInputsAndStates()
                                    close()
                                }"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="editProgramSeason(programSeasonSelected)">
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Programa"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-select 
                                        v-model="programSeasonSelected.program_id"
                                        :state="showInputStatus('program_id') && showInputStatus('program_code')"
                                        v-show="!editProgramSeasonLoading"
                                        disabled
                                    >
                                        <template #first>
                                            <b-form-select-option :value="programSeasonSelected.program_id" disabled>
                                                {{ programSeasonSelected.program_name }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_id')" :key="`${i}-createProgSea-program_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_code')" :key="`${i}-createProgSea-program_code`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Temporada"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-select 
                                        v-model="programSeasonSelected.season_id"
                                        :state="showInputStatus('season_id') && showInputStatus('program_code')"
                                        v-show="!editProgramSeasonLoading"
                                        disabled
                                    >
                                        <template #first>
                                            <b-form-select-option :value="programSeasonSelected.season_id" disabled>
                                                {{ programSeasonSelected.season_name }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('season_id')" :key="`${i}-createProgSea-season_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_code')" :key="`${i}-createProgSea-pro-co`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Full placement"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese el costo de full placement."
                                        v-model="programSeasonSelected.full_placement_cost"
                                        :state="showInputStatus('full_placement_cost')"
                                        v-show="!editProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('full_placement_cost')" :key="`${i}-createProgSea-cost`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Self placement"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese el costo de self placement."
                                        v-model="programSeasonSelected.self_placement_cost"
                                        :state="showInputStatus('self_placement_cost')"
                                        v-show="!editProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('self_placement_cost')" :key="`${i}-createProgSea-cost`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Descripción"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-textarea 
                                        placeholder="Ingrese una descripción (opcional)"
                                        v-model="programSeasonSelected.description"
                                        :state="showInputStatus('description')"
                                        v-show="!editProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('description')" :key="`${i}-createProgSea-description`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="8">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    :disabled="editProgramSeasonLoading"
                                >ACTUALIZAR</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>
    </b-card>
</template>

<script>
import { getProgramSeasons, storeProgramSeason, updateProgramSeason } from '@/api/ProgramSeason/ProgramSeason'
import { getAllSeasons } from '@/api/Season/Season'
import swal from 'sweetalert'

export default {
    name: 'ProgramSeasonsList',
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,

        /**
         *  (create) - programSeason
         */
        // program_id
        programSelected: '',
        programOptions: [
            { value:1, text:'work and travel'},
            { value:2, text:'internship'},
            { value:3, text:'trainee'},
            { value:4, text:'au pair'},
            { value:5, text:'teach'},
        ],
        // season_id
        seasonSelected: '',
        seasonOptions: [],
        getSeasonOptionsLoading: false,
        //cost ~ description
        full_placement_cost: '',
        self_placement_cost: '',
        description: '',
        //
        inputErrors: {},
        initialInputValues: true,
        createProgramSeasonLoading: false,

        /**
         *  (edit) - programSeason
         */
        programSeasonSelected: {},
        //
        editProgramSeasonLoading: false
        
    }),
    created() {
        this.getProgramSeasonsData()
    },
    methods: {
        // TABLE PROGRAM SEASONS
        getProgramSeasonsData (pPage = 1) {
            this.loadingTableData = true

            getProgramSeasons(pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch( err => {
                    console.log('getProgramsSeasons Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getProgramsSeasons request end');
                })
        },

        paginationCLiked (e) {
            this.getProgramSeasonsData(e)
        },

        /** * MODAL METHODS */
        /**
         * Modal (create) programSeason
         */
        modalCreateProgramSeasonClick () {
            this.$bvModal.show('modal-create-programSeason')
            this.getSeasonsData()
        },

        getSeasonsData() {
            this.getSeasonOptionsLoading = true

            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                })
                .catch(err => {
                    console.log('getAllSeason error', err.response);
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        createProgramSeason() {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createProgramSeasonLoading = true

            let ProgramSeason = new FormData()
            ProgramSeason.append('program_id', this.programSelected);
            ProgramSeason.append('season_id', this.seasonSelected);
            ProgramSeason.append('program_code', `${this.programSelected}-${this.seasonSelected}`);
            ProgramSeason.append('full_placement_cost', this.full_placement_cost);
            ProgramSeason.append('self_placement_cost', this.self_placement_cost);
            ProgramSeason.append('description', this.description);

            storeProgramSeason(ProgramSeason)
                .then(({data}) => {
                    console.log('store program season success', data);
                    swal('Creación correcta!', 'Nuevo programa-temporada creada', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getProgramSeasonsData()
                                    this.$bvModal.hide('modal-create-programSeason')
                                    break;

                                default:
                                    console.log('createProgramSeason-storeProgramSeason-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createProgramSeasonLoading = false
                    console.log('request create programSeason end');
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            // modal (create)
            this.initialInputValues = true
            this.inputErrors = {}
            this.programSelected = ''
            this.seasonSelected = ''
            this.full_placement_cost = ''
            this.self_placement_cost = ''
            this.description = ''

            // modal (edit)
            this.programSeasonSelected = {}
        },

        /**
         *  Modal (edit) programSeason
         */
        modalEditProgramSeasonClick (pProgramSeason) {
            this.programSeasonSelected = pProgramSeason
            this.$bvModal.show('modal-edit-programSeason')
        },

        editProgramSeason(pProgramSeason) {
            this.inputErrors = {}
            this.initialInputValues = false
            this.editProgramSeasonLoading = true

            let ProgramSeason = new FormData()
            ProgramSeason.append('.method', 'put');
            ProgramSeason.append('full_placement_cost', pProgramSeason.full_placement_cost);
            ProgramSeason.append('self_placement_cost', pProgramSeason.self_placement_cost == null ? '' : pProgramSeason.self_placement_cost);
            ProgramSeason.append('description', pProgramSeason.description);

            updateProgramSeason(ProgramSeason, pProgramSeason.id)
                .then(( ) => {
                    swal('Actualización correcta!', 'Programa-temporada actualizado', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getProgramSeasonsData()
                                    this.$bvModal.hide('modal-edit-programSeason')
                                    break;

                                default:
                                    console.log('editProgramSeason-updateProgramSeason-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editProgramSeasonLoading = false
                })
        },

        reloadData () {
            this.getProgramSeasonsData()
        }
    }
}
</script>