import { get } from 'axios'
// import store from './../../store/index.js'
import store from '@/store'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getStatisticsAdminHome (pIdSeason) {
    const URI = `${BASE_URL}/api/statistics/season/${pIdSeason}/admin-home`
    return get(URI, { headers })
}

export { getStatisticsAdminHome }