<template>
    <capacitations-component></capacitations-component>
</template>

<script>
import CapacitationsComponent from '@/components/Admin-Employee/Capacitations/CapacitationsComponent.vue';


export default {
    name: "Capacitations",
    components: { CapacitationsComponent },
    data: () => ({}),
}
</script>