import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getProgramSeasons (pPage) {
    const URI = `${BASE_URL}/api/programSeasons?page=${pPage}`
    return get(URI, { headers })
}

function getProgramSeason (pIdProgramSeason) {
    const URI = `${BASE_URL}/api/programSeasons/${pIdProgramSeason}`
    return get(URI, { headers })
}

function getAllProgramSeasons () {
    const URI = `${BASE_URL}/api/all-programSeasons`
    return get(URI, { headers })
}

function storeProgramSeason (pFormData) {
    const URI = `${BASE_URL}/api/programSeasons`
    return post(URI, pFormData, { headers })
}

function updateProgramSeason (pFormData, pIdProgramSeason) {
    const URI = `${BASE_URL}/api/programSeasons/${pIdProgramSeason}`
    return post(URI, pFormData, { headers })
}

export {
    getProgramSeasons,
    getProgramSeason,
    getAllProgramSeasons,
    storeProgramSeason,
    updateProgramSeason
}