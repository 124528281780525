<template>
    <b-row >
        <!-- postulant details -->
        <b-col cols="12" lg="5" class="mb-4">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles del Postulante
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="programSeasonPostulantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-if="!programSeasonPostulantDataLoading" class="mt-5">
                    <!-- full name -->
                    <b-row>
                        <b-col class="text-right">
                            <div>
                                <update-user-data-component
                                    :userData="programSeasonPostulant"
                                    :IdProgramSeasonPostulant="programSeasonPostulant.id"
                                    @updated="reloadData">
                                </update-user-data-component>
                            </div>
                        </b-col>
                    </b-row>

                    <b-list-group class="mb-4">
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Nombres</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto">{{ programSeasonPostulant.user_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Apellidos</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto">{{ programSeasonPostulant.user_last_name }}</b-col>
                            </b-row>
                        </b-list-group-item>
                       
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Celular</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto">{{ programSeasonPostulant.user_phone_number }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- document number -->
                    <b-row>
                        <b-col class="text-right">
                            <update-user-document-component
                                :userData="programSeasonPostulant"
                                :IdProgramSeasonPostulant="programSeasonPostulant.id"
                                @updated="reloadData">
                            </update-user-document-component>
                        </b-col>
                    </b-row>

                    <b-list-group class="mb-4">
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Documento</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto">{{ programSeasonPostulant.user_document_number }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- email -->
                    <b-row>
                        <b-col class="text-right">
                            <update-user-email-component
                                :userData="programSeasonPostulant"
                                :IdProgramSeasonPostulant="programSeasonPostulant.id"
                                @updated="reloadData">
                            </update-user-email-component>
                        </b-col>
                    </b-row>

                    <b-list-group class="mb-4">
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Correo</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto">{{ programSeasonPostulant.user_email }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- profile and description -->
                    <b-list-group >
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Perfil</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto">
                                    <b-button :href="programSeasonPostulant.postulant__profile" target="_blanck" v-if="programSeasonPostulant.postulant__profile.includes('https')"
                                        size="sm" variant="outline-primary"><b-icon icon="person-square"></b-icon>
                                    </b-button>
                                    <span v-else>{{programSeasonPostulant.postulant__profile}}</span>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" md="4" class="apk-details-tag">Comentario</b-col>
                                <b-col cols="auto" md="1" class="apk-details-tag">:</b-col>
                                <b-col cols="12" md="auto" class="text-justify">{{ programSeasonPostulant.description }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <!-- programSeasonPostulant details -->
        <b-col cols="12" lg="7">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles del Programa
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="programSeasonPostulantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-if="!programSeasonPostulantDataLoading" class="mt-5">
                    <b-list-group class="mb-5">
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="auto" class="text-center" v-if="(ui == 'admin' || ui == 'cultural_advisor') && programSeasonPostulant.status == 'activo'">
                                    <postpond-program-component
                                        :IdProgramSeasonPostulant="programSeasonPostulant.id"
                                        :haveParticipant="'no'"
                                        :ui="ui" @created="reloadData">
                                    </postpond-program-component>
                                </b-col>

                                <b-col cols="auto" class="text-center" v-if="(ui == 'admin' || ui == 'cultural_advisor') && programSeasonPostulant.status == 'activo'">
                                    <abandon-program-component
                                        :IdProgramSeasonPostulant="programSeasonPostulant.id"
                                        :haveParticipant="'no'"
                                        :ui="ui" @created="reloadData">
                                    </abandon-program-component>
                                </b-col>

                                <b-col cols="auto" class="text-center" v-if="(ui == 'admin' || ui == 'cultural_advisor')">
                                    <change-program-component
                                        @changed="changeToList"
                                        :IdProgramSeasonPostulant="programSeasonPostulant.id"
                                        :ProgramSeasonID="programSeasonPostulant.programSeason_id" :Season="programSeasonPostulant.season"
                                        :haveParticipant="'no'"
                                        @updated="reloadData">
                                    </change-program-component>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- program status and comments -->
                    <b-list-group class="mb-5">
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="3" class="apk-details-tag" >Estado</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col :class="programSeasonPostulant.status == 'activo' ? 'text-primary' : 'text-magenta'">
                                    <b-row>
                                        <b-col>{{ programSeasonPostulant.status }}</b-col>

                                        <b-col v-if="programSeasonPostulant.status == 'abandonado'" cols="2" class="text-right" >
                                            <change-program-state-component
                                                :type="'abandonment'"
                                                :id="programSeasonPostulant.abandonment.id"
                                                :model="programSeasonPostulant.abandonment"
                                                @updated="reloadData"
                                            ></change-program-state-component>
                                        </b-col>

                                        <b-col v-if="programSeasonPostulant.status == 'postergado'" cols="2" class="text-right" >
                                            <change-program-state-component
                                                :type="'postponement'"
                                                :id="programSeasonPostulant.postponement.id"
                                                :model="programSeasonPostulant.postponement"
                                                @updated="reloadData"
                                            ></change-program-state-component>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-show="programSeasonPostulant.status == 'abandonado' || programSeasonPostulant.status == 'postergado'">
                            <b-row>
                                <b-col cols="3" class="apk-details-tag" >Comentarios</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col v-if="programSeasonPostulant.status == 'abandonado'">{{ programSeasonPostulant.abandonment.comments }}</b-col>
                                <b-col v-if="programSeasonPostulant.status == 'postergado'">{{ programSeasonPostulant.postponement.comments }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-show="programSeasonPostulant.status == 'abandonado' || programSeasonPostulant.status == 'postergado'">
                            <b-row>
                                <b-col cols="3" class="apk-details-tag">RESP. ACCIÓN</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col v-if="programSeasonPostulant.status == 'abandonado' && programSeasonPostulant.abandonment.user_action_type != null">{{ programSeasonPostulant.abandonment.user_action_type == 'admin' ? 'Admin' : 'Asesor Cultural' }} <br> {{ programSeasonPostulant.abandonment.user_action_name }} </b-col>
                                <b-col v-if="programSeasonPostulant.status == 'postergado' && programSeasonPostulant.postponement.user_action_type != null">{{ programSeasonPostulant.postponement.user_action_type == 'admin' ? 'Admin' : 'Asesor Cultural'}} <br> {{ programSeasonPostulant.postponement.user_action_name }} </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- access credentials -->
                    <b-list-group class="mb-5">
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="3" class="apk-details-tag" >Credenciales de acceso</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col :class="programSeasonPostulant.credentials_status == 'activados' ? 'text-green' : 'text-magenta'">
                                    <b-row>
                                        <b-col class="text-uppercase">{{ programSeasonPostulant.credentials_status }}</b-col>

                                        <b-col cols="2" class="text-right" >
                                            <change-credentials-state-component
                                                :currentStatus="programSeasonPostulant.credentials_status"
                                                :programSeasonPostulantId="programSeasonPostulant.id"
                                                @updated="reloadData"
                                            ></change-credentials-state-component>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <!-- program - season - advisors -->
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="3" class="apk-details-tag" >Programa</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col>{{ programSeasonPostulant.program }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="3" class="apk-details-tag">Temporada</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col>{{ programSeasonPostulant.season }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- SA -->
                        <b-list-group-item v-if="!programSeasonPostulant.culturalAdvisor_id">
                            <b-row>
                                <b-col cols="3" class="apk-details-tag">Asesor Ventas</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col>
                                    <b-row>
                                        <b-col v-if="programSeasonPostulant.salesAdvisor_id">
                                            {{ programSeasonPostulant.salesAdvisor_full_name }}
                                        </b-col>

                                        <b-col cols="2" class="text-right" v-show="ui == 'admin' || ui == 'cultural_advisor'">
                                            <b-button size="sm" variant="naranja" @click="openEditAdvisorsModal(1)">
                                                <b-icon icon="pencil" scale="1.2"></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- CA -->
                        <b-list-group-item v-if="!programSeasonPostulant.salesAdvisor_id">
                            <b-row>
                                <b-col cols="3" class="apk-details-tag">Asesor Cultural</b-col>
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col>
                                    <b-row>
                                        <b-col v-if="programSeasonPostulant.culturalAdvisor_id">
                                            {{ programSeasonPostulant.culturalAdvisor_full_name }}
                                        </b-col>

                                        <b-col cols="2" class="text-right" v-show="ui == 'admin' || ui == 'cultural_advisor'">
                                            <b-button variant="naranja" size="sm" @click="openEditAdvisorsModal(0)">
                                                <b-icon icon="pencil" scale="1.2" ></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <!-- asignado -->
                        <b-list-group-item v-if="!programSeasonPostulant.culturalAdvisor_id">
                            <b-row>
                                <b-col cols="3" class="apk-details-tag">Asesor Cultural Asignado</b-col> 
                                <b-col cols="1" class="apk-details-tag">:</b-col>
                                <b-col >
                                    <b-row>
                                        <b-col>
                                            {{ programSeasonPostulant.culturalAdvisorAssigned_id ? programSeasonPostulant.culturalAdvisorAssigned_full_name : '- - - - - -' }}
                                        </b-col>
 
                                        <b-col cols="2" class="text-right" v-show="ui == 'admin' || ui == 'cultural_advisor'">
                                            <b-button size="sm" variant="naranja" @click="openEditAdvisorsModal(1)">
                                                <b-icon icon="pencil" scale="1.2"></b-icon>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <!-- modal edit advisors-->
        <b-modal
            id="modal-edit-advisors"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            body-class="mt-0 pt-0"
        >
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR ASESOR:</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="closeEditAdvisorsModal">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <template #default>
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <v-card flat>
                                <v-tabs grow v-model="tab">
                                    <v-tab :disabled="advisorLoading">as. Cultural</v-tab>
                                    <v-tab :disabled="advisorLoading">as. Ventas</v-tab>
                                </v-tabs>

                                <v-tabs-items v-model="tab">
                                    <!-- CA -->
                                    <v-tab-item>
                                        <v-card >
                                            <v-card-text>
                                                <b-form @submit.prevent="editCulturalAdvisor">
                                                    <b-form-row>
                                                        <b-col>
                                                            <b-form-group
                                                                label="Asesor Cultural"
                                                            >
                                                                <pulse-loader
                                                                    :loading="advisorLoading || culturalAdvisorsLoading"
                                                                    :color="'#5e72e4'"
                                                                    :margin="'5px'"
                                                                    :size="10"
                                                                    class="text-center pt-2 pb-1 pl-2"
                                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                                ></pulse-loader>

                                                                <b-form-select
                                                                    v-model="culturalAdvisorSelected"
                                                                    :options="culturalAdvisorOptions"
                                                                    v-show="!advisorLoading && !culturalAdvisorsLoading"
                                                                    :state="showModalInputStatus('culturalAdvisor_id')"
                                                                >
                                                                    <template #first>
                                                                        <b-form-select-option value="" disabled>
                                                                            -- Seleccione un asesor cultural --
                                                                        </b-form-select-option>
                                                                    </template>
                                                                </b-form-select>

                                                                <b-form-invalid-feedback
                                                                    v-for="(inputError, i) in showModalInputErrors('culturalAdvisor_id')"
                                                                    :key="`${i}-culturalAdvisor_id`"
                                                                >
                                                                    {{ inputError }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-form-row>

                                                    <b-row align-v="center" align-h="center" class="mt-4">
                                                        <b-col cols="auto">
                                                            <b-button
                                                                v-show="programSeasonPostulant.culturalAdvisor_id != culturalAdvisorSelected"
                                                                variant="primary"
                                                                type="submit"
                                                            >
                                                                ACTUALIZAR
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-form>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <!-- SA -->
                                    <v-tab-item>
                                        <v-card>
                                            <v-card-text>
                                                <b-form @submit.prevent="editSalesAdvisor">
                                                    <b-form-row>
                                                        <b-col>
                                                            <b-form-group label="Asesor de Ventas:" 
                                                            >
                                                                <pulse-loader
                                                                    :loading="advisorLoading || salesAdvisorsLoading"
                                                                    :color="'#5e72e4'"
                                                                    :margin="'5px'"
                                                                    :size="10"
                                                                    class="text-center pt-2 pb-1 pl-2"
                                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                                ></pulse-loader>

                                                                <b-form-select
                                                                    v-model="salesAdvisorSelected"
                                                                    :options="salesAdvisorOptions"
                                                                    v-show="!advisorLoading && !salesAdvisorsLoading"
                                                                    :state="showModalInputStatus('salesAdvisor_id')"
                                                                >
                                                                    <template #first>
                                                                        <b-form-select-option value="" disabled>
                                                                            -- Seleccione un asesor de ventas --
                                                                        </b-form-select-option>
                                                                    </template>
                                                                </b-form-select>

                                                                <b-form-invalid-feedback
                                                                    v-for="(inputError, i) in showModalInputErrors('salesAdvisor_id')"
                                                                    :key="`${i}-salesAdvisor_id`"
                                                                >
                                                                    {{ inputError }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-form-row>

                                                    <b-form-row>
                                                        <b-col>
                                                            <b-form-group label="Asesor Cultural Asignado:" 
                                                            >
                                                                <pulse-loader
                                                                    :loading="advisorLoading || culturalAdvisorsLoading"
                                                                    :color="'#5e72e4'"
                                                                    :margin="'5px'"
                                                                    :size="10"
                                                                    class="text-center pt-2 pb-1 pl-2"
                                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                                ></pulse-loader>

                                                                <b-form-select
                                                                    v-model="culturalAdvisorAssignedSelected"
                                                                    :options="culturalAdvisorOptions"
                                                                    v-show="!advisorLoading && !culturalAdvisorsLoading"
                                                                    :state="showModalInputStatus('culturalAdvisorAssigned_id')"
                                                                >
                                                                    <template #first>
                                                                        <b-form-select-option value="" disabled>
                                                                            -- Seleccione un asesor cultural --
                                                                        </b-form-select-option>
                                                                    </template>
                                                                </b-form-select>

                                                                <b-form-invalid-feedback
                                                                    v-for="(inputError, i) in showModalInputErrors('culturalAdvisorAssigned_id')"
                                                                    :key="`${i}-culturalAdvisorAssigned_id`"
                                                                >
                                                                    {{ inputError }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-form-row>

                                                    <b-row align-v="center" align-h="center" class="mt-4">
                                                        <b-col cols="auto">
                                                            <b-button
                                                                v-show="programSeasonPostulant.culturalAdvisorAssigned_id != culturalAdvisorAssignedSelected || programSeasonPostulant.salesAdvisor_id != salesAdvisorSelected"
                                                                variant="primary"
                                                                type="submit"
                                                            >
                                                                ACTUALIZAR
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-form>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant';
//
import { getAllActiveCulturalAdvisorsByProgram } from '@/api/CulturalAdvisor/CulturalAdvisor';
import { getAllActiveSalesAdvisorsByProgram } from '@/api/SalesAdvisor/SalesAdvisor';
import { updateProgramSeasonPostulantCulturalAdvisor } from '@/api/ProgramSeasonPostulant/UpdateCulturalAdvisor';
import { updateProgramSeasonPostulantSalesAdvisor } from '@/api/ProgramSeasonPostulant/UpdateSalesAdvisor';
import swal from 'sweetalert';
import UpdateUserDataComponent from '@/components/Admin-Employee/Postulant-Participant/UpdateUserDataComponent.vue';
import UpdateUserDocumentComponent from '@/components/Admin-Employee/Postulant-Participant/UpdateUserDocumentComponent.vue';
import UpdateUserEmailComponent from '@/components/Admin-Employee/Postulant-Participant/UpdateUserEmailComponent.vue';
import PostpondProgramComponent from '@/components/Admin-Employee/Postulant-Participant/PostpondProgramComponent.vue';
import AbandonProgramComponent from '@/components/Admin-Employee/Postulant-Participant/AbandonProgramComponent.vue';
import ChangeProgramComponent from '@/components/Admin-Employee/Postulant-Participant/ChangeProgramComponent.vue';
import ChangeProgramStateComponent from '@/components/Admin-Employee/Postulant-Participant/ChangeProgramStateComponent.vue';
import ChangeCredentialsStateComponent from '@/components/Admin-Employee/Postulant-Participant/ChangeCredentialsStateComponent.vue';

export default {
    name: "PostulantDetailsComponent",
    components: {
        UpdateUserDataComponent,
        UpdateUserDocumentComponent,
        UpdateUserEmailComponent,
        PostpondProgramComponent,
        AbandonProgramComponent,
        ChangeProgramComponent,
        ChangeProgramStateComponent,
        ChangeCredentialsStateComponent,
    },
    props: {
        ui: {
            type: String,
            required: true
        },
        routeToList: {
            type: String,
            required: true
        }
    },
    data: () => ({
        programSeasonPostulant: {
            attributes: {}
        },
        programSeasonPostulantDataLoading: false,
        // modal
        tab:  null,
        modalInputErrors: {},
        initialModalInputValues: true,
        //
        culturalAdvisorOptions: [],
        culturalAdvisorsLoading: false,
        culturalAdvisorSelected: '',
        culturalAdvisorAssignedSelected: '',
        //
        salesAdvisorOptions: [],
        salesAdvisorsLoading: false,
        salesAdvisorSelected: '',
        //
        advisorLoading: false,
    }),

    created() {
        this.getData()
    },

    watch: {
        tab () {
            this.clearInputsAndStates()
        }
    },

    methods: {
        getData () {
            this.programSeasonPostulantDataLoading = true
            getProgramSeasonPostulant(this.$route.params.IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.programSeasonPostulant = data.data.attributes
                })
                .finally(() => {
                    this.programSeasonPostulantDataLoading = false
                })
        },

        // modal
        openEditAdvisorsModal (pTab) {
            this.tab = pTab
            this.clearInputsAndStates()
            this.setAdvisorOptions()
            this.$bvModal.show('modal-edit-advisors')
        },
        closeEditAdvisorsModal () {
            this.$bvModal.hide('modal-edit-advisors')
            this.clearInputsAndStates()
        },
        setAdvisorOptions() {
            this.culturalAdvisorsLoading = true
            this.salesAdvisorsLoading = true
            let IdProgram = this.programSeasonPostulant.program_id

            getAllActiveCulturalAdvisorsByProgram(IdProgram)
                .then(({ data }) => {
                    this.culturalAdvisorOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name }))
                })
                .finally(() => {
                    this.culturalAdvisorsLoading = false
                })

            getAllActiveSalesAdvisorsByProgram(IdProgram)
                .then(({ data }) => {
                    this.salesAdvisorOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name }))
                })
                .finally(() => {
                    this.salesAdvisorsLoading = false
                })
        },

        editCulturalAdvisor () {
            this.advisorLoading = true
            this.initialModalInputValues = false

            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant
            let CulturalAdvisorForm = new FormData()
            CulturalAdvisorForm.append('.method', 'put')
            CulturalAdvisorForm.append('culturalAdvisor_id', this.culturalAdvisorSelected)

            updateProgramSeasonPostulantCulturalAdvisor (CulturalAdvisorForm, IdProgramSeasonPostulant)
                .then(() => {
                    swal('Actualización correcta!', ' ', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getData()
                                    this.$bvModal.hide('modal-edit-advisors')
                                    break;
                                default:
                                    console.log('edit-cultural-advisor-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.advisorLoading = false
                })
        },

        editSalesAdvisor () {
            this.advisorLoading = true
            this.initialModalInputValues = false

            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant
            let SalesAdvisorForm = new FormData()
            SalesAdvisorForm.append('.method', 'put')
            SalesAdvisorForm.append('salesAdvisor_id', this.salesAdvisorSelected)
            SalesAdvisorForm.append('culturalAdvisorAssigned_id', this.culturalAdvisorAssignedSelected)

            updateProgramSeasonPostulantSalesAdvisor (SalesAdvisorForm, IdProgramSeasonPostulant)
                .then(() => {
                    swal('Actualización correcta!', ' ', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getData()
                                    this.$bvModal.hide('modal-edit-advisors')
                                    break;
                                default:
                                    console.log('edit-sales-advisor-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.advisorLoading = false
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates (){
            this.culturalAdvisorSelected = this.programSeasonPostulant.culturalAdvisor_id ? this.programSeasonPostulant.culturalAdvisor_id : ''
            //
            this.salesAdvisorSelected = this.programSeasonPostulant.salesAdvisor_id ? this.programSeasonPostulant.salesAdvisor_id : ''
            this.culturalAdvisorAssignedSelected = this.programSeasonPostulant.culturalAdvisorAssigned_id ? this.programSeasonPostulant.culturalAdvisorAssigned_id : ''

            this.initialModalInputValues = true
            this.modalInputErrors = {}
        },

        reloadData () {
            this.getData()
        },

        changeToList() {
            this.$router.push({ name: this.routeToList }) 
        }
    }
}
</script>

<style scoped>
.apk-details-tag {
    text-transform: uppercase;
    color: #b5b4b4;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 12px;
}
</style>