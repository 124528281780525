<template>
    <contract-layout-component :routeName="routeName"></contract-layout-component>
</template>

<script>
import ContractLayoutComponent from '@/components/ContractLayout/ContractLayoutComponent.vue'

export default {
    name: 'ContractLayout',
    components: { ContractLayoutComponent },
    data: () => ({
        routeName: 'admin-layouts-contracts-contractLayout'
    })
}
</script>