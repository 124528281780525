import { mergeAttributes } from '@tiptap/core'
import Image from '@tiptap/extension-image'

export default Image.extend({
    name: 'ApkAdminSignatureMedium',

    inline() {
      return true
    },

    group() {
        return 'inline'
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            kind: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-kind'),
                renderHTML: () => ({
                    'data-kind': this.name,
                    // style: 'width: auto;height: 100px;border-bottom: #000fff 2px solid;',
                    style: 'width: auto;height: 100px;',
                    class: 'apk-admin-signature'
                }),
            },
        }
    },

    parseHTML() {
        return [
          {
            tag: 'img[data-type="ApkAdminSignatureMedium"]'
          },
        ]
      },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes({ 'data-type': 'ApkAdminSignatureMedium' }, this.options.HTMLAttributes, HTMLAttributes)]
    },

    addCommands() {
        return {
          setAdminSignatureMedium: options => ({ commands }) => {
            return commands.insertContent({
                type: this.name,
                attrs: options,
            })
          },
        }
    },
})