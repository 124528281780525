<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Ofertas laborales
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <!-- EXCEL - BUTTONS -->
                            <transition name="slide-fade-icons">
                            <b-button 
                                v-show="showExcel"
                                variant="schedule-english-2"
                                size="sm" @click="modalEditExcelClick(programSeasonSponsor)">
                                <b-icon icon="pencil" scale="1.3"></b-icon>
                            </b-button>
                            </transition>

                            <transition name="slide-fade-icons">
                            <b-button 
                                v-show="showExcel"
                                variant="schedule-english-2"
                                size="sm" @click="reloadIframe">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                            </transition>

                            <transition name="slide-fade-icons">
                            <b-button 
                                v-show="showExcel"
                                variant="schedule-english-2"
                                @click="showExcel = !showExcel"
                                size="sm">
                                <b-icon icon="x" scale="1.3"></b-icon>
                            </b-button>
                            </transition>

                            <!-- JOBO.FERR - BUTTONS -->
                            <b-button
                                v-show="!showExcel"
                                variant="schedule-english-2 "
                                size="sm" @click="showExcel = !showExcel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                                    <path d="M6 12v-2h3v2H6z"/>
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z"/>
                                </svg>
                            </b-button>

                            <transition name="slide-fade-icons">
                            <b-button variant="green" size="sm"
                                v-show="!showExcel"
                                class="ml-2" @click="modalAddJobClick">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>
                            </transition>

                            <transition name="slide-fade-icons">
                            <b-button variant="cielo" size="sm"
                                v-show="!showExcel"
                                @click="modalLoadExcelClick">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>
                            </transition>

                            <transition name="slide-fade-icons">
                            <b-button variant="primary" size="sm"
                                v-show="!showExcel"
                                @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                            </transition>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData && !showExcel" >
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>
                <div style="height:65vh" v-show="showExcel && programSeasonSponsorLoading" >
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <transition name="slide-fade">
                <div class="table-responsive" v-show="!loadingTableData && !showExcel">
                    <table class="table tablesorter align-items-center table-flush"
                    >
                        <thead class="thead-light">
                            <tr>
                                <th class="alway-title-show">#</th>
                                <th class="alway-title-show">
                                    <b-button size="sm" variant="primary" disabled>{{jobStackChecked.length}}</b-button>
                                    <b-button size="sm" :variant="jobStackChecked.length == tableData.length ? 'primary' : 'light'" @click="addAllJobsToggle">All</b-button>
                                </th>

                                <th class="alway-title-show bg-lighter text-center">
                                    <pulse-loader
                                        :loading="editJobStatusLoading"
                                        :color="'#5e72e4'"
                                        :margin="'2px'"
                                        :size="8"
                                        class="pt-2 pb-1"
                                    ></pulse-loader>

                                    <b-btn size="sm" @click="editJobsStatus('activado')" v-show="!editJobStatusLoading && jobStackChecked.length > 0"><b-icon icon="circle-fill" scale="1.2" variant="green" class="mr-2"></b-icon>activar</b-btn>
                                    <b-btn size="sm" @click="editJobsStatus('desactivado')" v-show="!editJobStatusLoading && jobStackChecked.length > 0"><b-icon icon="circle-fill" scale="1.2" variant="magenta" class="mr-2"></b-icon>desactivar</b-btn>
                                </th>

                                <th class="alway-title-show bg-lighter text-center"></th>
                                <th class="alway-title-show bg-lighter text-center">
                                     <pulse-loader
                                        :loading="deleteJobsLoading"
                                        :color="'#5e72e4'"
                                        :margin="'2px'"
                                        :size="8"
                                        class="pt-2 pb-1"
                                    ></pulse-loader>

                                    <b-btn size="sm" @click="deleteJobs" v-show="!deleteJobsLoading && jobStackChecked.length > 0"><b-icon icon="trash" scale="1.2" variant="magenta" class="mr-2"></b-icon>eliminar</b-btn>
                                </th>
                                <th v-for="(field, i) in programJobFieldsSorted" :key="`${i}-field-th-sorted`" style="text-transform: none;" class="alway-title-show">
                                    {{ field.attributes.field_name }}
                                </th>
                            </tr>
                        </thead>

                        <tbody class="list">
                            <tr v-for="(row, index) in tableData" :key="`${index}-tabledata`">
                                <td>{{ index + 1 }}</td>

                                <td>
                                    <b-form-checkbox class="jobs-checkbox"
                                        :value="{...row.attributes, index}" @change="(e) => stackToJobChecked(e, index)">
                                    </b-form-checkbox>
                                </td>

                                <td class="text-center">
                                    <b-row>
                                        <b-col cols="8">
                                            <pulse-loader
                                                :loading="row.changeStatusLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'2px'"
                                                :size="8"
                                                class="pt-2 pb-1"
                                            ></pulse-loader>

                                            <span :class="row.attributes.status == 'activado' ? 'text-green' : 'text-magenta'" v-show="!row.changeStatusLoading">
                                                {{ row.attributes.status == 'activado' ? 'activo' : row.attributes.status }}
                                             </span>
                                        </b-col>

                                        <b-col cols="4">
                                            <b-form-checkbox
                                                switch size="sm"
                                                :checked="row.attributes.status == 'activado' ? true : false"
                                                @change="(e) => updateOfferStatus(row, e)"
                                                :disabled="row.changeStatusLoading || false"
                                            ></b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </td>

                                <td class="text-right">
                                    <b-btn size="sm" @click="modalEditJobClick(row.attributes)">
                                        <b-icon icon="pencil-square" scale="1.2" variant="primary">
                                        </b-icon>
                                    </b-btn>
                                </td>

                                <td>
                                    <div v-show="row.attributes.postulations.length == 0">
                                        <pulse-loader
                                            :loading="row.deleteLoading || false"
                                            :color="'#5e72e4'"
                                            :margin="'2px'"
                                            :size="8"
                                            class="pt-2 pb-1"
                                        ></pulse-loader>

                                        <b-btn variant="transparent" size="sm" 
                                            v-show="!row.deleteLoading && true"
                                            @click="deleteProgramSponsorJob(row, index)"
                                        ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                        </b-btn>
                                    </div>
                                </td>

                                <td  v-for="(order, j) in input_order" :key="`${j}-order`">
                                    <span
                                        v-for="(field, k) in row.attributes.jobFields" :key="`${k}-field-td`"
                                        v-show="order==field.attributes.programJobField_id"
                                    >
                                        {{ field.attributes.field_value }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </transition>

                <!-- google drive excel -->
                <transition name="slide-fade">
                <b-card no-body style="height: 65vh" v-if="!loadingTableData && showExcel && !programSeasonSponsorLoading">
                    <iframe
                        id="drive_iframe"
                        name="drive_iframe"
                        :src="`https://docs.google.com/spreadsheets/d/${programSeasonSponsor.excel_identifier}/htmlembed/sheet?gid=${programSeasonSponsor.gid}`" 
                        width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" ></iframe>
                </b-card>
                </transition>
                <!-- end google drive -->
            </b-card>
        </b-col>

        <!-- 
            modal add job 
        -->
        <b-modal
            id="modal-add-job"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">AGREGAR OFERTA LABORAL</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddJobClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="createJob">
                                <b-form-row
                                >
                                    <b-col cols="12" md="6"
                                        v-for="(field, i) in programJobFieldsSorted" :key="`${i}-field-create-sorted`"
                                    >
                                        <b-form-group
                                            :label="field.attributes.field_name"
                                        >
                                            <pulse-loader
                                                :loading="jobOfferLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Ingrese el valor del campo."
                                                v-model="field.value"
                                                v-show="!jobOfferLoading"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="jobOfferLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        <!-- end modal add job -->

        <!-- 
            modal edit job 
        -->
        <b-modal
            id="modal-edit-job"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDITAR OFERTA LABORAL</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalEditJobClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- resource form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editJob">
                                <b-form-row
                                >
                                    <b-col cols="12" md="6"
                                        v-for="(order, i) in input_order" :key="`${i}-order-edit`"
                                    >
                                        <b-form-group
                                            :label="field.attributes.field_name"
                                            v-for="(field, i) in selectedProgramSponsorJob.jobFields" :key="`${i}-field`"
                                            v-show="order==field.attributes.programJobField_id"
                                        >
                                            <pulse-loader
                                                :loading="jobOfferLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="field.attributes.field_value"
                                                v-show="!jobOfferLoading"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="jobOfferLoading"
                                        >
                                            EDITAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        <!-- end modal edit job -->

        <!-- 
            modal edit excel 
        -->
        <b-modal
            id="modal-edit-excel"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDITAR EXCEL COMPARTIDO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalEditExcelClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="editSponsor">
                                <b-form-row>
                                    <!-- switch -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="¿Mostrar a los participantes el excel de ofertas laborales?"
                                        >
                                            <pulse-loader
                                                :loading="jobOfferLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-checkbox
                                                v-model="selectedProgramSeasonSponsor.show_excel"
                                                v-show="!jobOfferLoading"
                                                switch
                                                value="si"
                                                unchecked-value="no"
                                            ></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>

                                    <!-- sheet_ID -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Sheet_ID: "
                                            description=" Ejemplo: https://docs.google.com/spreadsheets/d/SHEET_ID/edit#gid=315073237"
                                        >
                                            <pulse-loader
                                                :loading="jobOfferLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                v-model="selectedProgramSeasonSponsor.excel_identifier"
                                                placeholder="Código que esta después de: '/d/' de la url de su excel."
                                                :state="showModalInputStatus('excel_identifier')"
                                                v-show="!jobOfferLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('excel_identifier')"
                                                :key="`${i}-createPostulant-excel_identifier`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- gid -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="gid: "
                                            description=" Ejemplo: https://docs.google.com/spreadsheets/d/SHEET_ID/edit#gid=315073237"
                                        >
                                            <pulse-loader
                                                :loading="jobOfferLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                v-model="selectedProgramSeasonSponsor.gid"
                                                placeholder="Código gid de la url de su excel. ejm:315073237"
                                                :state="showModalInputStatus('gid')"
                                                v-show="!jobOfferLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('gid')"
                                                :key="`${i}-createPostulant-gid`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                
                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="jobOfferLoading"
                                        >
                                            ACTUALIZAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        <!-- end modal edit excel -->

        <!-- modal load excel -->
        <b-modal
            id="modal-load-excel"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">CARGAR EXCEL</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalLoadExcelClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="loadExcelData">
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Excel"
                                        >
                                            <pulse-loader
                                                :loading="loadLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-file
                                                v-model="excel_document"
                                                :state="showModalInputStatus('excel_document')"
                                                v-show="!loadLoading"
                                                style="overflow:hidden;"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('excel_document')"
                                                :key="`${i}-createPostulant-excel_document`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                
                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="loadLoading"
                                        >
                                            CARGAR DATOS
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        <!-- end modal edit excel -->


    </b-row>
</template>

<script>
// programSeasonSponsor.programSponsorJobs
import { getProgramSeasonSponsorProgramSponsorJobs} from '@/api/ProgramSeasonSponsor/ProgramSponsorJob';
// programSeasonSponsor.programJobField
import { getProgramSeasonSponsorProgramJobFields } from '@/api/ProgramSeasonSponsor/ProgramJobField';
import { getProgramSeasonSponsor, updateProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor'
import { storeProgramSponsorJob, updateProgramSponsorJob, destroyProgramSponsorJob } from '@/api/ProgramSponsorJob/ProgramSponsorJob'
import { stackProgramSponsorJobUpdateStatus } from '@/api/ProgramSponsorJob/StackUpdateStatus'
import { stackProgramSponsorJobDelete } from '@/api/ProgramSponsorJob/StackDelete'
import { updateProgramSponsorJobStatus } from '@/api/ProgramSponsorJob/UpdateStatus'
import { loadDataProgramSponsorJob } from '@/api/ProgramSeasonSponsor/LoadExcelData'
import swal from 'sweetalert'

export default {
    name: "ProgramSponsorJoboffers",
    data: () => ({
        // table 
        tableData: [],
        loadingTableData: false,
        //
        programJobFields: [],
        programJobFieldsSorted: [],
        programJobFieldsLoading: false,
        //
        input_order: [],
        //
        programSeasonSponsor: {},
        programSeasonSponsorLoading: false,
        selectedProgramSeasonSponsor: {},
        //
        showExcel: false,
        // modal edit form
        selectedProgramSponsorJob: {},
        //
        initialModalInputValues : true,
        modalInputErrors: {},
        jobOfferLoading: false,
        //
        excel_document: null,
        loadLoading: false,
        //
        jobStackChecked: [],
        editJobStatusLoading: false,
        deleteJobsLoading: false,
    }),
    created() {
        this.geData()
    },
    methods: {
        geData () {
            this.programJobFieldsLoading = true
            this.loadingTableData = true
            this.programSeasonSponsorLoading = true

            let IdProgramSeasonSponsor = this.$route.params.IdProgramSeasonSponsor  // ok

            getProgramSeasonSponsorProgramJobFields(IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.programJobFields = data.data.map(el => ({...el, 'value': ''}))

                    getProgramSeasonSponsorProgramSponsorJobs(IdProgramSeasonSponsor)
                        .then(({ data }) => {
                            this.tableData = data.data

                            getProgramSeasonSponsor(IdProgramSeasonSponsor)
                                .then(({ data }) => {
                                    this.programSeasonSponsor = data.data.attributes
                                    this.input_order = this.programSeasonSponsor.input_order.split('|')

                                    // ordenando programJobField
                                    this.programJobFieldsSorted = []
                                    for (const order of this.input_order) {
                                        for (const programJobField of this.programJobFields) {
                                            if (order == programJobField.id) {
                                                this.programJobFieldsSorted.push(JSON.parse(JSON.stringify(programJobField)))
                                                break
                                            }
                                        }
                                    }
                                
                                })
                                .finally(() => {
                                    this.programSeasonSponsorLoading = false
                                    console.log('getProgramSeasonSponsor request end');
                                })

                        })
                        .finally(() => {
                            this.loadingTableData = false
                            console.log('getProgramSeasonJobs request end');
                        })
                })
                .finally(() => {
                    this.programJobFieldsLoading = false
                    console.log('getProgramJobFields request end');
                })
        },

        reloadIframe() {
            this.programSeasonSponsorLoading = true

            var iframe = document.getElementById('drive_iframe');
            var tmp_src = iframe.src;
            iframe.src = '';
            iframe.src = tmp_src;

            setTimeout(() => {
                this.programSeasonSponsorLoading = false
            }, 1000)
        },

        getJobsWithoutLoading () {
            getProgramSeasonSponsorProgramSponsorJobs(this.$route.params.IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.tableData = data.data
                })
        },

        // Modal Add JOb
        modalAddJobClick () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-add-job')
        },
        modalAddJobClose () {
            this.$bvModal.hide('modal-add-job')
            this.clearInputsAndStates()
        },

        createJob () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.jobOfferLoading = true

            let IdProgramSeasonSponsor = this.$route.params.IdProgramSeasonSponsor  // ok

            let JobOfferUpdateForm = new FormData()

            JobOfferUpdateForm.append('programSeasonSponsor_id', IdProgramSeasonSponsor)
            JobOfferUpdateForm.append('numberOfFields', this.programJobFieldsSorted.length)

            this.programJobFieldsSorted.forEach((programJobField, i) => {
                JobOfferUpdateForm.append(`programJobField_id-${i}`, programJobField.attributes.id)
                JobOfferUpdateForm.append(`field_value-${i}`, programJobField.value)
            });

            storeProgramSponsorJob(JobOfferUpdateForm)
                .then(({ data }) => {
                    console.log('crado; ', data);
                    swal('Creación correcta!', 'Oferta laboral creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.geData()
                                    this.$bvModal.hide('modal-add-job')
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editDocument err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.jobOfferLoading = false
                    console.log('request editDocument end');
                })
        },

        // Modal Edit Job
        modalEditJobClick (pProgramSponsorJob) {
            this.clearInputsAndStates()
            this.selectedProgramSponsorJob = JSON.parse(JSON.stringify(pProgramSponsorJob))
            this.$bvModal.show('modal-edit-job')
        },
        modalEditJobClose () {
            this.$bvModal.hide('modal-edit-job')
            this.clearInputsAndStates()
        },

        editJob () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.jobOfferLoading = true

            let IdProgramSponsorJob = this.selectedProgramSponsorJob.id  // ok

            let JobOfferUpdateForm = new FormData()
            JobOfferUpdateForm.append('.method', 'put')
            JobOfferUpdateForm.append('numberOfFields', this.selectedProgramSponsorJob.jobFields.length)
            this.selectedProgramSponsorJob.jobFields.forEach((jobField, i) => {
                JobOfferUpdateForm.append(`IdJobField-${i}`, jobField.attributes.id)
                JobOfferUpdateForm.append(`field_value-${i}`, jobField.attributes.field_value)
            });

            updateProgramSponsorJob(JobOfferUpdateForm, IdProgramSponsorJob)
                .then(( data ) => {
                    console.log('update program Sponsor job', data);
                    swal('Actualización correcta!', 'Oferta laboral actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getJobsWithoutLoading()
                                    // this.geData()
                                    this.$bvModal.hide('modal-edit-job')
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editDocument err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.jobOfferLoading = false
                    console.log('request editDocument end');
                })
        },

        // Modal Edit Excel
        modalEditExcelClick (pProgramSeasonSponsor) {
            this.clearInputsAndStates()
            this.selectedProgramSeasonSponsor = { ...pProgramSeasonSponsor }
            this.$bvModal.show('modal-edit-excel')
        },
        modalEditExcelClose () {
            this.$bvModal.hide('modal-edit-excel')
            this.clearInputsAndStates()
        },

        editSponsor () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.jobOfferLoading = true

            let IdProgramSeasonSponsor = this.$route.params.IdProgramSeasonSponsor  // ok

            let SponsorUpdateForm = new FormData()
            SponsorUpdateForm.append('.method', 'put')
            SponsorUpdateForm.append('show_excel', this.selectedProgramSeasonSponsor.show_excel)
            SponsorUpdateForm.append('excel_identifier', this.selectedProgramSeasonSponsor.excel_identifier)
            SponsorUpdateForm.append('gid', this.selectedProgramSeasonSponsor.gid)

            updateProgramSeasonSponsor(SponsorUpdateForm, IdProgramSeasonSponsor)
                .then(() => {
                    swal('Actualización correcta!', 'Excel actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.programSeasonSponsorLoading = true
                                    getProgramSeasonSponsor(IdProgramSeasonSponsor)
                                        .then(({ data }) => {
                                            this.programSeasonSponsor = data.data.attributes
                                        })
                                        .finally(() => {
                                            this.programSeasonSponsorLoading = false
                                        })
                                    this.$bvModal.hide('modal-edit-excel')
                                    break;

                                default:
                                    console.log('editExcel--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editDocument err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.jobOfferLoading = false
                    console.log('request editDocument end');
                })
        },

        // Modal Load Excel
        modalLoadExcelClick () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-load-excel')
        },
        modalLoadExcelClose () {
            this.$bvModal.hide('modal-load-excel')
            this.clearInputsAndStates()
        },

        loadExcelData () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.loadLoading = true

            let LoadExcelForm = new FormData()
            LoadExcelForm.append('excel_document', this.excel_document)

            loadDataProgramSponsorJob(LoadExcelForm, this.$route.params.IdProgramSeasonSponsor)
                .then(({ data }) => {
                    console.log('crado; ', data);
                    swal('Creación correcta!', 'Oferta laborales creadas.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.geData()
                                    this.$bvModal.hide('modal-load-excel')
                                    break;

                                default:
                                    console.log('load--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.loadLoading = false
                })
        },

        //
        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}

            this.selectedProgramSponsorJob = {}
            this.selectedProgramSeasonSponsor = {}

            this.excel_document = null
        },

        reloadData () {
            this.jobStackChecked = []
            this.geData()
        },

        //
        deleteProgramSponsorJob (pProgramSponsorJob, pIndex) {
            swal('Se eliminará la oferta laboral', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramSponsorJob, 'deleteLoading', true)
                            destroyProgramSponsorJob(pProgramSponsorJob.attributes.id)
                                .then(() => {
                                    // this.geData()
                                    this.tableData.splice(pIndex, 1)
                                })
                                .finally(() => {
                                    pProgramSponsorJob.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-ProgramSponsorJob-swal-break');
                            break;
                    }
                })
            // this.$set(pProgramSponsorJob, 'deleteLoading', true)
            // destroyProgramSponsorJob(pProgramSponsorJob.attributes.id)
            //     .then(() => {
            //         // this.geData()
            //         this.tableData.splice(pIndex, 1)
            //     })
            //     .finally(() => {
            //         pProgramSponsorJob.deleteLoading = false
            //     })
        },

        //
        updateOfferStatus (pProgramSponsorJob, event) {
            this.$set(pProgramSponsorJob, "changeStatusLoading", true);

            let statusForm = new FormData()
            statusForm.append('.method', 'put')
            if (event) statusForm.append('status', 'activado')
            else statusForm.append('status', 'desactivado')

            updateProgramSponsorJobStatus(statusForm, pProgramSponsorJob.id)
                .then(() => {
                    this.getJobsWithoutLoading()
                    // pProgramSponsorJob.attributes.status == data.data.attributes.status
                })
                .finally(() => {
                    pProgramSponsorJob.changeStatusLoading = false;
                })
        },

        //
        stackToJobChecked(pJobOffer, pIndex) {
            if(pJobOffer) this.jobStackChecked.push({...pJobOffer})

            if(pJobOffer == false) {
                this.jobStackChecked.some((el, j) => {
                    if (el.index == pIndex) {
                        this.jobStackChecked.splice(j, 1)
                        return true
                    }
                })
            }
        },

        addAllJobsToggle() {
            this.jobStackChecked = []
            let isAllChecked = 'si'
            document.querySelectorAll('.jobs-checkbox > input').forEach(el => {
                if (!el.checked) {
                    isAllChecked = 'no'
                    el.click()
                } else {
                    el.click()
                    el.click()
                }
            })
            if (isAllChecked == 'si') document.querySelectorAll('.jobs-checkbox > input').forEach(el => el.click())
        },

        editJobsStatus(pStatus) {
            this.editJobStatusLoading = true
            let Statusorm = new FormData()
            Statusorm.append('programSponsorJobs', this.jobStackChecked.map(el => el.id))
            Statusorm.append('status', pStatus)
            stackProgramSponsorJobUpdateStatus(Statusorm)
                .then(() => {
                    this.reloadData()
                })
                .finally(() => {
                    this.editJobStatusLoading = false
                })
        },

        deleteJobs() {
            this.deleteJobsLoading = true
            let deleteForm = new FormData()
            deleteForm.append('programSponsorJobs', this.jobStackChecked.map(el => el.id))
            stackProgramSponsorJobDelete(deleteForm)
                .then(() => {
                    this.reloadData()
                })
                .finally(() => {
                    this.deleteJobsLoading = false
                })
        }


    }
}
</script>

<style scoped>
/* EXCEL - JOB OFFERS*/
.slide-fade-enter-active {
  transition: all .5s  cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-leave-active {
  transition: all .0000000000000000001s ease;
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}

/* BUTTONS */
.slide-fade-icons-enter-active {
  transition: all .5s  cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-icons-leave-active {
  transition: all .000000000000000000000000000000000003s ease;
}
.slide-fade-icons-enter{
  opacity: 0;
}
.slide-fade-icons-leave-to{
  opacity: 0;
  display: none;
}
.alway-title-show {
    position: sticky;
    background-color: #f7f8fe;
    border-bottom: 2px solid #e9ecef !important;
    z-index: 100;
    top: 0px;
}
</style>