import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// sponsortJobOffer
function updateSponsorJobOfferInputsOrder (pFormdata, IdSponsorJobOffer) {
    const URI = `${BASE_URL}/api/sponsorJobOffer/${IdSponsorJobOffer}/updateInputsOrder`
    return post(URI, pFormdata, { headers })
}

export { updateSponsorJobOfferInputsOrder }