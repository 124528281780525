<template>
    <b-row>
        <b-col cols="12" class="text-right mb-4">
            <b-button variant="white" size="sm" @click="reloadData"  class="mr-0 py-2">
                <b-icon icon="arrow-repeat" variant="primary" scale="1.2"></b-icon>
            </b-button>
        </b-col>

        <b-col cols="12" class="mt-5 mt-lg-0">
            <b-card no-body bg-variant="transparent">
                <b-row>
                    <b-col v-for="(img, i) in emails_images" :key="`${i}-email-img`" cols="12" md="6" lg="4" class="mb-4">
                        <b-card :img-src="img.src" text-variant="primary" img-bottom class="shadow" :img-alt="img.title"> <b-card-title class="m-0 text-center">{{img.title}}</b-card-title> </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "GeneralEmailsComponent",
    data: () => ({
      emails_images: [
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/english-interview.jpg', title: 'Entrevista de Inglés'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/capacitation.jpg', title: 'Capacitación'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/talk.jpg', title: 'Charla'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/sponsor-interview.jpg', title: 'Entrevista Sponsor'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/sponsor-interview-approved.jpg', title: 'Entrevista Sponsor Aprobado'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/sponsor-interview-disapproved.jpg', title: 'Entrevista Sponsor Rechazado'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/employer-interview.jpg', title: 'Entrevista Sponsor Rechazado'},
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/employer-interview-disapproved.jpg', title: 'Entrevista Sponsor Rechazado'}
      ]
    }),
    methods: {
        reloadData() {
            this.emails_images = [
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/english-interview.jpg', title: 'Entrevista de Inglés'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/capacitation.jpg', title: 'Capacitación'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/talk.jpg', title: 'Charla'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/sponsor-interview.jpg', title: 'Entrevista Sponsor'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/sponsor-interview-approved.jpg', title: 'Entrevista Sponsor Aprobado'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/sponsor-interview-disapproved.jpg', title: 'Entrevista Sponsor Rechazado'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/employer-interview.jpg', title: 'Entrevista Sponsor Rechazado'},
                { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalEmails/employer-interview-disapproved.jpg', title: 'Entrevista Sponsor Rechazado'}
            ]
        }
    }
}
</script>