<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Usuario', path: { name: 'admin-search-user' } }"/>
            <intranet-nav-content-link :link="{ name: 'Voucher', path: { name: 'admin-search-voucher' } }"/>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';

export default {
    name: 'AdminParticipants',
    components: { 
        IntranetNavContent,
        IntranetNavContentLinkExact,
        IntranetNavContentLink
    },
};
</script>
