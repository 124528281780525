<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
               <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                             <h3 class="mb-0 text-primary text-button">
                               Visa - Documentos
                             </h3>
                         </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="modalAddDocumentClick"  :disabled="dataLoading">
                                <b-icon icon="plus" scale="1.2"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="dataLoading">
                            <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

            <!-- loadingTableData -->
            <div style="height:300px;" v-show="dataLoading">
                <pulse-loader
                    :color="'#5e72e4'"
                    :margin="'10px'"
                    :size="20"
                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
            </div>

            <!-- table -->
            <div class="table-responsive" v-show="!dataLoading">
                <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="visaDocuments"
                >
                    <template slot="columns">
                        <th>#</th>
                        <th>DOCUMENTO</th>
                        <th>Auto Crea.</th>
                        <th>status</th>
                        <th></th>
                    </template>

                    <template slot-scope="{row, index}">
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.attributes.name }}</td>
                    <td>{{ row.attributes.auto_create }}</td>
                    <td>{{ row.attributes.status == 1 ? 'Activado' : 'Desactivado' }}</td>

                    <td>
                        <b-button size="sm" @click="modalEditDocumentClick(index, row)">
                            <b-icon icon="pencil" variant="naranja"></b-icon>
                        </b-button>

                        <b-button size="sm" @click="deleteVisaDoc(index, row)" :disabled="row.attributes.deleteLoading" v-if="row.attributes.canDelete">
                            <pulse-loader :color="'#EF4A81'" :margin="'2px'" :size="8" v-if="row.attributes.deleteLoading"/>
                            <b-icon icon="trash" variant="magenta" v-else></b-icon>
                        </b-button>
                    </td>
                    </template>
                </base-table>
            </div>
         </b-card>
     </b-col>

        <!-- MODAL ADD DOCUMENT -->
        <b-modal
            id="modal-add-document"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">ADD DOC.</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddDocumentClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="addDocument">
                        <!-- nombre -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Documento :"
                                >
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el nombre."
                                        v-model="name"
                                        v-show="!createLoading"
                                        :state="showInputStatus('name')"
                                    />
                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('name')"
                                        :key="`${i}-create-v-d-name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>

                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- auto create -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Auto Create :"
                                >
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <!-- archivo -->
                                    <b-form-radio-group
                                        v-model="auto_create"
                                        :state="showInputStatus('auto_create')"
                                        v-show="!createLoading"
                                        :options="[{value: 'si', text: 'Si'}, {value: 'no', text: 'No'}]"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('auto_create')"
                                        :key="`${i}-create-v-d-auto_create`"
                                    >
                                    {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- status -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Estado :"
                                >
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <!-- archivo -->
                                    <b-form-radio-group
                                        v-model="status"
                                        :state="showInputStatus('status')"
                                        v-show="!createLoading"
                                        :options="[{value: 0, text: 'Desactivado'}, {value: 1, text: 'Activado'}]"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('status')"
                                        :key="`${i}-create-v-d-status`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-row align-v="center" align-h="center">
                            <b-col cols="auto">
                                <b-button variant="primary" type="submit"
                                    :disabled="createLoading"
                                >
                                    GUARDAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>

        <!-- MODAL EDIT DOCUMENT -->
        <b-modal
            id="modal-edit-document"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDIT DOC.</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalEditDocumentClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="editVisaDoc">
                        <!-- nombre -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Documento :"
                                >
                                    <pulse-loader
                                        :loading="editLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el nombre."
                                        v-model="visaDocumentSelected.attributes.name"
                                        v-show="!editLoading"
                                        :state="showInputStatus('name')"
                                    />
                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('name')"
                                        :key="`${i}-create-v-d-name`"
                                    >
                                        {{ inputError }}
                                    </b-form-invalid-feedback>

                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- auto create -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Auto Create :"
                                >
                                    <pulse-loader
                                        :loading="editLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <!-- archivo -->
                                    <b-form-radio-group
                                        v-model="visaDocumentSelected.attributes.auto_create"
                                        :state="showInputStatus('auto_create')"
                                        v-show="!editLoading"
                                        :options="[{value: 'si', text: 'Si'}, {value: 'no', text: 'No'}]"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('auto_create')"
                                        :key="`${i}-create-v-d-auto_create`"
                                    >
                                    {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- status -->
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Estado :"
                                >
                                    <pulse-loader
                                        :loading="editLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <!-- archivo -->
                                    <b-form-radio-group
                                    v-model="visaDocumentSelected.attributes.status"
                                    :state="showInputStatus('status')"
                                    v-show="!editLoading"
                                    :options="[{value: 0, text: 'Desactivado'}, {value: 1, text: 'Activado'}]"
                                    />

                                    <b-form-invalid-feedback
                                        v-for="(inputError, i) in showInputErrors('status')"
                                        :key="`${i}-create-v-d-status`"
                                    >
                                    {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-row align-v="center" align-h="center">
                            <b-col cols="auto">
                                <b-button variant="primary" type="submit"
                                :disabled="editLoading"
                                >
                                GUARDAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getVisaDocuments, storeVisaDocument, updateVisaDocument,destroyVisaDocument } from '@/api/VisaDocument/VisaDocument'
import swal from 'sweetalert'

export default {
    name: "ParticipantVisaDocumentComponent",
    data: () => ({
        visaDocuments: [],
        dataLoading: false,
        // create visa document
        name: '',
        auto_create: 'si',
        status: 1,
        createLoading:false,
        // edit visa document
        visaDocumentSelected: {
            attributes: {}
        },
        editLoading: false,
        //
        initialInputValues: true,
        inputErrors: []
    }),

    created() {
        this.getData()
    },

    methods: {
        getData() {
            this.dataLoading = true
            getVisaDocuments()
                .then(({ data }) => {
                    this.visaDocuments = data.data
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },

        // create
        modalAddDocumentClick() {
            this.$bvModal.show('modal-add-document')
        },
        modalAddDocumentClose() {
            this.$bvModal.hide('modal-add-document')
            this.clearInputsAndStates()
        },
        addDocument() {
            this.createLoading = true
            this.initialInputValues = false

            let DocFormdata = new FormData()
            DocFormdata.append('name', this.name)
            DocFormdata.append('auto_create', this.auto_create)
            DocFormdata.append('status', this.status)

            storeVisaDocument(DocFormdata)
                .then(({ data }) => {
                    this.visaDocuments.push(data.data)
                    this.modalAddDocumentClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        // edit
        modalEditDocumentClick(pIndex, pVisaDocument) {
            this.visaDocumentSelected = JSON.parse(JSON.stringify({...pVisaDocument, index: pIndex}))
            this.$bvModal.show('modal-edit-document')
        },
        modalEditDocumentClose() {
            this.$bvModal.hide('modal-edit-document')
            this.clearInputsAndStates()
        },
        editVisaDoc(){
            this.editLoading = true
            this.initialInputValues = false

            let DocFormdata = new FormData()
            DocFormdata.append('.method', 'put')
            DocFormdata.append('name', this.visaDocumentSelected.attributes.name)
            DocFormdata.append('auto_create', this.visaDocumentSelected.attributes.auto_create)
            DocFormdata.append('status', this.visaDocumentSelected.attributes.status)


            updateVisaDocument(this.visaDocumentSelected.id, DocFormdata)
                .then(({ data }) => {
                    this.visaDocuments.splice(this.visaDocumentSelected.index, 1, data.data)
                    this.modalEditDocumentClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editLoading = false
                })
        },

        clearInputsAndStates() {
            this.visaDocumentSelected = {
                attributes: {}
            }

            this.initialInputValues = true
            this.inputErrors = {}

            this.name = ''
            this.auto_create = 'si'
            this.status = 1
        },

        // globar input methods
        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        // delete
        deleteVisaDoc (pIndex, pVisaDocument) {
            swal(`Se eliminará.`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pVisaDocument, 'deleteLoading', true)
                            destroyVisaDocument(pVisaDocument.id)
                                .then(() => {
                                    this.visaDocuments.splice(pIndex, 1)
                                })
                                .finally(() => {
                                    pVisaDocument.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-visaDoc-swal-break');
                            break;
                    }
                })
        },

        reloadData() {
            this.getData()
        }
    }
}
</script>