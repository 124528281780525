import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function storeRefundVoucher (pFormdata) {
    const URI = `${BASE_URL}/api/refundedVouchers`
    return axios.post(URI, pFormdata, { headers })
}

function destroyRefunderedVoucher (pIdRefunderedVoucher) {
    const URI = `${BASE_URL}/api/refundedVouchers/${pIdRefunderedVoucher}`
    return axios.delete(URI, { headers })
}

export {
    storeRefundVoucher,
    destroyRefunderedVoucher
}