<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Postulaciones', path: { name: 'admin-export' } }"/>
            <intranet-nav-content-link-exact :link="{ name: 'Info. Participantes', path: { name: 'admin-export-participant-information' } }"/>
            <intranet-nav-content-link :link="{ name: 'Vouchers', path: { name: 'admin-export-payments-vouchers' } }"/>
            <intranet-nav-content-link :link="{ name: 'Documentos', path: { name: 'admin-export-participant-documents' } }"/>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';

export default {
    name: 'AdminExport',
    components: {
        IntranetNavContent,
        IntranetNavContentLinkExact,
        IntranetNavContentLink
    },
};
</script>
