<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-cielo text-button">
                               {{ contractData.programSeasonPostulant_full_name }} <span class="text-magenta">|</span> {{ contractData.total_cost_format }} <span class="text-magenta">|</span> as. {{ contractData.culturalAdvisor_full_name }}
                            </h3>
                        </b-col>

                        <b-col class="text-right" cols="auto">
                            <b-btn
                                :href="contractData.generated_file" target="_blanck"
                                size="sm" variant="outline-cielo" v-if="!loadingContractData"
                            ><b-icon icon="file-earmark-text" scale="1.3" variant="cielo"></b-icon></b-btn>

                            <b-button variant="primary" size="sm" @click="reloadContractData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>


                <b-container fluid>
                    <b-row>
                        <b-col >
                            <!-- HTML Layouts -->
                            <v-card flat class="mt-3">
                                    <b-row class="align-items-center mb-3">
                                        <b-col>
                                            <h3 class="mb-0 text-uppercase" :class="isLayoutSelected == 'si' ? 'text-primary' : 'text-contract-1'">
                                                {{ isLayoutSelected == 'si' ? '' : 'Seleccionar una plantilla:'}}
                                            </h3>
                                        </b-col>

                                        <b-col cols="auto">
                                            <b-button size="sm" variant="contract-1" @click="resetLayout" v-show="isLayoutSelected == 'si'" title="Seleccionar otra plantilla."><b-icon icon="chevron-left" class="mr-2"></b-icon>Seleccionar otra plantilla</b-button>
                                            <b-button size="sm" variant="contract-1" @click="reloadData" v-show="isLayoutSelected == 'no'"><b-icon icon="arrow-counterclockwise"></b-icon></b-button>
                                        </b-col>
                                    </b-row>

                                    <!-- layout cards -->
                                    <transition name="slide-fade">
                                    <b-container v-if="isLayoutSelected == 'no'">
                                        <b-row class="border border-contract-1 mb-5" style="border-radius:5px">
                                            <!-- loading -->
                                            <b-col v-show="layoutsLoading">
                                                <div style="height:175px;">
                                                        <pulse-loader 
                                                        :color="'#1572A1'"
                                                        :margin="'10px'"
                                                        :size="16"
                                                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                                                </div>
                                            </b-col>

                                            <!-- cards -->
                                            <b-col cols="12" style="display:inline-flex; overflow:auto" v-show="!layoutsLoading">
                                                <div style="display:inline-flex;" class="my-3">
                                                    <b-card class="layout-card" no-body v-for="(layout, i) in contractLayouts" :key="`${i}-contract-layout`" @click="setLayout(layout)">
                                                        <svg class="layout-card--icon" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                                        </svg>
                                                        <span class="layout-card--name">{{ layout.attributes.name }}</span>
                                                    </b-card>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    </transition>

                                    <!-- form -->
                                    <transition name="slide-fade">
                                    <b-form @submit.prevent="getCurrentEditorOutput" v-if="isLayoutSelected == 'si'">
                                        <b-row>
                                            <b-col v-show="createContractLoading">
                                                <div style="height:250px;">
                                                        <pulse-loader 
                                                        :color="'#5e72e4'"
                                                        :margin="'10px'"
                                                        :size="16"
                                                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col v-show="!createContractLoading">
                                                <tiptap
                                                    :getHtml="editorOutputRequest"
                                                    :initialBodyValue="contractBodyHtmlValue"
                                                    :initialFooterValue="contractFooterHtmlValue"
                                                    @output="registerHtmlContract" :uiMode="'employee'"></tiptap>
                                            </b-col>
                                        </b-row>

                                        <b-row align-v="center" align-h="center" class="my-5">
                                            <b-col cols="auto">
                                                <b-button variant="primary" type="submit" :disabled="createContractLoading">
                                                    REGISTRAR CONTRATO
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                    </transition>
                            </v-card>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant'
import { updatePdfContract } from '@/api/Hotfix/Contract/ContractPdf'
import { getHtmlLayouts } from '@/api/HtmlLayout/HtmlLayout'
import { getContract } from '@/api/Contract/Contract'
import swal from 'sweetalert'
import Tiptap from '@/components/Tiptap/components/Tiptap.vue'



export default {
    name: "ContractRegisterHtmlComponent",
    props: {
        routeNameToContractPdfList: {
            type: String,
            required: true
        }
    },
    components: { Tiptap },
    data: () => ({
        // programSeasonPostulant
        programSeasonPostulant: {},
        //
        contractData: {},
        loadingContractData: false,
        //
        contractLayouts: {},
        layoutsLoading: false,
        isLayoutSelected: 'no',
        //
        createContractLoading: false,
        //
        contractBodyHtmlValue: '',
        contractFooterHtmlValue: '',
        editorOutputRequest: 1,
    }),

    created() {
        this.getData()
        this.getContractData()
    },

    methods: {
        getData () {
            // htmlLayouts
            this.layoutsLoading = true
            getHtmlLayouts()
                .then(({ data }) => {
                    this.contractLayouts = data.data.filter(el => el.attributes.type == 'contract')
                })
                .finally(() => {
                    this.layoutsLoading = false
                })
        },

        getContractData() {
            this.loadingContractData = true
            getContract(this.$route.params.IdContract)
                .then(({ data }) => {
                    this.contractData = data.data.attributes
                })
                .finally(() => {
                    this.loadingContractData = false
                })
        },

        setLayout(pLayout) {
            this.contractBodyHtmlValue = pLayout.attributes.body
            this.contractFooterHtmlValue = pLayout.attributes.footer
            this.isLayoutSelected = 'si'
        },
        resetLayout() {
            swal('Se perderá el avance realizado.', '¿Desea seleccionar otra plantilla?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, Seleccionar otra plantilla']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.isLayoutSelected = 'no'
                            this.clearInputsAndStates()
                            this.getData()
                            break;
                        case null:
                            break;
                        default:
                            console.log('select other layout');
                            break;
                    }
                })
        },

        getCurrentEditorOutput() {
            this.editorOutputRequest += 1
        },

        registerHtmlContract (pOutput = {}) {
            this.createContractLoading = true
            //
            let HtmlForm = new FormData()
            HtmlForm.append('.method', 'put')
            HtmlForm.append('body', pOutput.body)
            HtmlForm.append('footer', pOutput.footer)

            updatePdfContract(HtmlForm, this.$route.params.IdContract)
                .then(() => {
                    swal('Creación correcta!', 'Contrato creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: this.routeNameToContractPdfList}) 
                                    break;

                                default:
                                    console.log('register-html-contract--swal-break');
                                break;
                            }
                        })
                })
                .finally(() => {
                    this.createContractLoading = false
                })
        },

        clearInputsAndStates () {
            this.contractBodyHtmlValue = ''
            this.contractFooterHtmlValue = ''
        },

        reloadData () {
            this.getData()
        },

        reloadContractData () {
            this.getContractData()
        }
    }
}
</script>

<style scoped>
.layout-card {
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;
    padding: 8px 10px ;
    margin-right: 15px;
    border-radius: 5px;
    border: 2px dashed #1572A1;
    color: #1572A1;
    cursor: pointer;
    background: rgba(94, 114, 228, 0.0);
    background: rgba(21, 114, 161, 0.0);
    transition: all 360ms ease-in-out;
}
.layout-card:hover {
    background: rgba(21, 114, 161, 0.1);
}
.layout-card--icon {
    margin-bottom: 10px;
}
.layout-card--name {
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bolder;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
/* .slide-fade-leave-active {
  transition: all .5s ease;
} */
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
</style>