<template>
   <contract-details-component></contract-details-component>
</template>

<script>
import ContractDetailsComponent from '@/components/Admin-Employee/Postulant/Contracts/ContractDetailsComponent.vue'

export default {
    name: "ContractDetails",
    components: { ContractDetailsComponent }
}
</script>