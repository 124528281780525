import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

// programSeason.ProgramSeasonSponsor
function getProgramSeasonProgramSeasonSponsors (pPage, pIdProgramSeason) {
    const URI = `${BASE_URL}/api/programSeasons/${pIdProgramSeason}/programSeasonSponsors?page=${pPage}`
    return get(URI)
}
export {
    getProgramSeasonProgramSeasonSponsors,
}