<template>
    <span>
        <b-button variant="outline-magenta" title="Eliminar" size="sm" @click="deleteVoucher" v-show="voucherData.attributes.refundedVoucher == null">
            <b-icon icon="trash"  scale="1.1" v-show="!loading"></b-icon>

            <pulse-loader :loading="loading" :color="'#EF4A81'" :margin="'1px'" :size="4"></pulse-loader>
        </b-button>
    </span>
</template>

<script>
import { destroyVoucher } from '@/api/Voucher/DeleteVoucher'
import swal from 'sweetalert'

export default {
    name: 'DeleteVoucherComponent',
    props: {
        voucherData: {
            type: Object,
            required: true
        },
        ui: {
            type: String,
            required: true
        }
    },
    data: () => ({
        loading: false,
    }),

    methods: {
        deleteVoucher() {
            let Form = new FormData()
            swal('Se eliminará el voucher.', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.loading = true
                            Form.append('type', this.voucherData.type)
                            Form.append('type_id', this.voucherData.id)
                            destroyVoucher(Form)
                                .then(() => {
                                    swal('Eliminado!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.$emit('deleted')
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;
                    }
                })
        },
    }
}
</script>