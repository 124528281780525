<template>
    <contract-layout-list-component :routeName="routeName"></contract-layout-list-component>
</template>

<script>
import ContractLayoutListComponent from '@/components/ContractLayout/ContractLayoutListComponent.vue'

export default {
    name: 'ContractLayoutList',
    components: { ContractLayoutListComponent },
    data: () => ({
        routeName: 'admin-layouts-contracts-contractLayout'
    })
}
</script>