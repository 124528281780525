<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Aplicante</th>
                            <th>Asesor C.</th>
                            <th>pdf</th>
                            <th>Costo</th>
                            <th>programa</th>
                            <th>temporada</th>
                            <th class="text-center"></th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                            <td>{{ row.attributes.programSeasonPostulant_full_name }}</td>
                            <td>{{ row.attributes.culturalAdvisor_full_name }}</td>
                            <td>
                                <b-btn
                                    :href="row.attributes.generated_file" target="_blanck"
                                    size="sm" variant="outline-cielo"
                                ><b-icon icon="file-earmark-text" scale="1.3" variant="cielo"></b-icon></b-btn>
                            </td>
                            <td>{{ row.attributes.total_cost_format }}</td>
                            <td>{{ row.attributes.program }}</td>
                            <td>{{ row.attributes.season }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: routeNameToRegister,
                                        params: { 'IdContract': row.attributes.id }    // IdContract
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="layout-text-window-reverse" scale="1.2"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getpdfContracts } from '@/api/Hotfix/Contract/ContractPdf';

export default {
    name: "ContractPdfListComponent",
    data: () => ({
        tableData: [],
        loadingTableData: false,
    }),
    props: {
        routeNameToRegister: {
            type: String,
            required:  true
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.loadingTableData = true
            getpdfContracts()
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },
        reloadData () {
            this.getData()
        },
    }
}
</script>