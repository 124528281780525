<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Sponsor - Programa
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalCreate">
                                <b-icon icon="plus" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Template name</th>
                            <th>Sponsor</th>
                            <th>Programa</th>
                            <th class="text-center">campos</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <!-- numeración descendente -->
                                {{ index + 1 }}
                            </td>

                            <td>{{ row.attributes.template_name }}</td>
                            <td>{{ row.attributes.sponsor_name }}</td>
                            <td>{{ row.attributes.program_name }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: routeName,
                                        params: { 'IdSponsorJobOffer': row.id }    //IdSponsorJobOffer
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-view-stacked" viewBox="0 0 16 16">
                                        <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"/>
                                    </svg>
                                </b-btn>
                            </td>

                            <td>
                                <pulse-loader
                                    :loading="row.deleteLoading || false"
                                    :color="'#5e72e4'"
                                    :margin="'2px'"
                                    :size="8"
                                    class="pt-2 pb-1"
                                ></pulse-loader>

                                <b-btn
                                    variant="transparent" size="sm"
                                    v-show="!row.deleteLoading && true"
                                    @click="deleteSponsorJobOffer(row)"
                                ><b-icon icon="trash" variant="magenta" scale="1.1"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>

        <!-- modal create-->
        <b-modal
                id="modal-create-template"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    Agregar Template
                                </h3>
                            </b-col>

                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalCreate">
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <b-form @submit.prevent="createSponsorJobOffer">
                            <b-form-row>
                                <!-- template_name -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Template name :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-input
                                            type="text"
                                            v-model="templateName"
                                            :state="showInputStatus('template_name')"
                                            v-if="!createLoading"
                                            disabled
                                        ></b-form-input>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('template_name')"
                                            :key="`${i}-template_name`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                
                                <!-- program_id -->
                                <b-col cols="12">
                                    <b-form-group
                                        label="Programa :"
                                    >
                                        <pulse-loader
                                            :loading="createLoading || programsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        ></pulse-loader>

                                        <b-form-select
                                            v-model="program_id"
                                            :options="programOptions"
                                            :state="showInputStatus('program_id')"
                                            v-if="!createLoading && !programsLoading"
                                        >
                                            <template #first>
                                                <b-form-select-option value="">-- Selecciona un programa--</b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('program_id')"
                                            :key="`${i}-program_id`"
                                        >{{ inputError }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- btn actualizar -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto" class="py-0">
                                    <b-button
                                        class="w-100 my-2"
                                        variant="primary"
                                        type="submit"
                                        size="md"
                                        :disabled="createLoading"
                                    >Crear</b-button>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-container>
                </template>
        </b-modal>
    </b-row>
</template>

<script>
// sponsor.sponsorJobOffer
import { getSponsorSponsorJobOffers } from '@/api/Sponsor/SponsorJobOffer';
import { storeSponsorJobOffer, destroySponsorJobOffer } from '@/api/SponsorJobOffer/SponsorJobOffer';
import { getAllPrograms } from '@/api/Program/Program';
import { getSponsor } from '@/api/Sponsor/Sponsor';
import swal from 'sweetalert'

export default {
    name: "SponsorJobOffersListComponent",
    data: () => ({
        sponsor: {},
        //
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        programsLoading: false,
        //
        programOptions: [],
        program_id: '',
        createLoading: false,
        //
        initialInputValues: true, 
        inputErrors: {}
    }),
    props: {
        routeName: {
            type: String
        }
    },
    created() {
        this.getData()
        this.getSponsorData()
    },
    computed: {
        templateName () {
            let name = this.sponsor.name.toLowerCase()
            switch (this.program_id.toString()) {
                case '1':
                    name = `${name} - wat fields`
                    break;
                case '2':
                    name = `${name} - internship fields`
                    break;
                case '3':
                    name = `${name} - trainee fields`
                    break;
                case '4':
                    name = `${name} - aupair fields`
                    break;
                case '5':
                    name = `${name} - teach fields`
                    break;
                default:
                    break;
            }
            return name
        }
    },
    methods: {
        getData (pPage = 1) {
            this.loadingTableData = true
            let IdSponsor = this.$route.params.IdSponsor

            getSponsorSponsorJobOffers(pPage, IdSponsor)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch( err => {
                    console.log('get-sponsors-sponsor-job-offer Error:', err.response)
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('get-sponsors-sponsor-job-offer request end');
                })
        },
        paginationCLiked (e) {
            this.getAdminsData(e)
        },
        reloadData () {
            this.getData()
        },
        getSponsorData () {
            getSponsor(this.$route.params.IdSponsor)
                .then(({ data }) => {
                    this.sponsor = data.data.attributes
                })
        },

        //
        deleteSponsorJobOffer (pSponsorJobOffer) {
            swal('Se eliminará', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pSponsorJobOffer, 'deleteLoading', true)
                            destroySponsorJobOffer(pSponsorJobOffer.attributes.id)
                                .then(() => {
                                    this.getData()
                                })
                                .finally(() => {
                                    pSponsorJobOffer.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-template-swal-break');
                            break;
                    }
                })
        },

        //
        setOptions () {
            this.programsLoading = true
            getAllPrograms()
                .then(({data}) => {
                    this.programOptions = data.data.filter(el => {
                        let addToOptions = 'si'
                        for (const sponsorJobOffer of this.tableData) {
                            if (el.attributes.id == sponsorJobOffer.attributes.program_id) {
                                addToOptions = 'no'
                                break;
                            }
                        }
                        return addToOptions == 'si'
                    }).map(el => ({ value: el.attributes.id, text: el.attributes.name }))
                })
                .finally(() => {
                    this.programsLoading = false
                })
        },

        // modal
        openModalCreate (){
            this.clearInputsAndStates()
            this.setOptions()
            this.$bvModal.show('modal-create-template')
        },

        closeModalCreate (){
            this.$bvModal.hide('modal-create-template')
            this.clearInputsAndStates()
        },

        createSponsorJobOffer () {
            this.createLoading = true 
            this.initialInputValues = false
            this.inputErrors = {}

            let TemplateForm = new FormData()
            TemplateForm.append('sponsor_id', this.$route.params.IdSponsor)
            TemplateForm.append('program_id', this.program_id)
            TemplateForm.append('template_name', this.templateName)

            storeSponsorJobOffer(TemplateForm)
                .then(() => {
                    swal('Creación correcta!', 'templeate creado', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.closeModalCreate()
                                    this.getData()
                                    break;

                                default:
                                    console.log('create-template-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.inputErrors = {}
            this.initialInputValues = true

            this.program_id = ''
        },

    }
}
</script>