import { post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }
//post
function storeUserEmployee (pFormData, pIdUser) {
    const URI = `${BASE_URL}/api/users/${pIdUser}/employees`
    return post(URI, pFormData, { headers })
}

export {
    storeUserEmployee,
}