<template>
    <last-documents-component
        :ui="ui"
        :routeDocuments="routeDocuments"
    ></last-documents-component>
</template>

<script>
import LastDocumentsComponent from '@/components/Admin-Employee/Participant/Filters/LastDocumentsComponent.vue'

export default {
    name : 'LastDocuments',
    components: { LastDocumentsComponent },
    data: () => ({
        ui: 'admin',
        routeDocuments: 'admin-participants-documents'
    })
}
</script>