import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function storeCost (pFormData) {
    const URI = `${BASE_URL}/api/costs`
    return axios.post(URI, pFormData, { headers })
}

//put
function updateCost (pFormData, pIdCost) {
    const URI = `${BASE_URL}/api/costs/${pIdCost}`
    return axios.post(URI, pFormData, { headers })
}

//delete
function destroyCost (pIdCost) {
    const URI = `${BASE_URL}/api/costs/${pIdCost}`
    return axios.delete(URI, { headers })
}

export {
    storeCost,
    updateCost,
    destroyCost
}