<template>
    <!-- create admin with existing user -->
    <div>
        <!-- search user -->
        <b-form @submit.prevent="searchUserWithoutRoleAdmin">
            <b-form-row>
                <b-col cols="10">
                    <b-form-group>
                        <pulse-loader
                            :loading="loadingTableData"
                            :color="'#5e72e4'"
                            :margin="'5px'"
                            :size="10"
                            class="pt-2 pb-1 pl-2 text-center"
                            style="border-radius:5px; border:1px solid #5e72e4"
                        />
                        
                        <b-form-input
                            type="text"
                            placeholder="Búsqueda por nombres o dni"
                            v-model="inputToSearch"
                            :state="showInputStatus('input_to_search')"
                            v-show="!loadingTableData"
                        />

                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('input_to_search')" :key="`${i}-serchUsers`">
                            {{ inputError }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="2">
                    <b-form-group>
                        <b-button
                            class="w-100 py-auto px-0"
                            variant="primary"
                            type="submit"
                        ><b-icon icon="search" class="m-0 p-0"/></b-button>
                    </b-form-group>
                </b-col>
            </b-form-row>
        </b-form>

        <!-- laodingTableData -->
        <div style="height:300px;" v-show="loadingTableData">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
        </div>

        <!-- card users table -->
        <b-card
            no-body
            border-variant="lighter"
            v-show="!loadingTableData && !searchFailed"
            style="overflow:hidden"
        >
            <!-- users table-->
            <div class="table-responsive mb-0">
                <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                >
                    <template slot="columns">
                        <th>Nombres</th>
                        <th>documento</th>
                        <th>correo</th>
                        <th></th>
                    </template>

                    <template slot-scope="{row}">
                        <td class="text-uppercase">
                            <!-- {{ row.attributes.last_name }} {{ row.attributes.name }} -->
                            <span class="text-uppercase">{{ row.attributes.last_name}} </span>
                            <span class="text-capitalize">{{ row.attributes.name}}</span>
                        </td>

                        <td>
                            {{ row.attributes.document_number}}
                        </td>

                        <td>
                            {{ row.attributes.email}}
                        </td>

                        <td class="text-center">
                            <b-btn 
                                variant="outline-primary"
                                size="sm"
                                @click="$bvModal.show(`userRole-modal-${row.attributes.document_number}`)"
                            >
                                <b-icon icon="grid-fill" class="m-0 p-0"/>
                            </b-btn>
                        </td>
                    </template>
                </base-table>
            </div>
        </b-card>

        <!-- pagination -->
        <div class="card-footer border-0" v-show="!loadingTableData && !searchFailed">
            <base-pagination
                class="mb-0"
                align="center"
                :pageCount="tableMeta.last_page" 
                :perPage="tableMeta.per_page"
                :value="tableMeta.current_page"
                @input="paginationCLiked"
            />
        </div>

        <!-- user-roles modals -->
        <b-modal
            v-for="(item, i) in tableData" :key="`${i}-roleuser`"
            :id="`userRole-modal-${item.attributes.document_number}`"
            centered
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto">ROLES DEL USUARIO</b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="danger" @click="close()">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- user data -->
                        <b-col cols="12" class="mb-5 d-flex justify-content-center">
                            <div class="py-3 px-4 d-flex align-items-center modal-user-data"
                            >
                                <b-icon 
                                    icon="person-square"
                                    variant="primary"
                                    class="mr-3 modal-user-data-icon"
                                />
                                <span style="width: fit-content">
                                    {{ item.attributes.last_name }} {{ item.attributes.name }}
                                </span>
                                <div class="modal-user-data-id">
                                    ID-USER: {{ item.attributes.id }}
                                </div>
                            </div>
                        </b-col>

                        <!-- table -->
                        <b-col cols="12">
                            <b-card no-body>
                                <!-- laodingTableData -->
                                <div style="height:300px;" v-show="item.createAdminLoading || false">
                                    <pulse-loader
                                        :color="'#5e72e4'"
                                        :margin="'10px'"
                                        :size="20"
                                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                                </div>

                                <!-- user-roles table-->
                                <div class="table-responsive mb-0" v-show="!item.createAdminLoading && true">
                                    <base-table class="table align-items-center table-flush"
                                        thead-classes="thead-light"
                                        tbody-classes="list"
                                        :data="item.attributes.user_roles"
                                    >
                                        <template slot="columns">
                                            <th>Rol</th>
                                            <th>estado</th>
                                        </template>

                                        <template slot-scope="{row}">
                                            <td>{{ row.attributes.role_name }}</td>
                                            <td>{{ row.attributes.status }}</td>
                                        </template>
                                    </base-table>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal footer -->
            <template #modal-footer>
                <b-container fluid style="border-top:1px solid rgba(0, 0, 0, 0.075)" class="pb-1 pt-3">
                    <b-row align-v="center" align-h="center">
                        <b-col cols="auto">
                            <b-button variant="primary" @click="createAdmin(item)">
                                CREAR ADMIN.
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { storeUserAdmin } from '@/api/User/UserAdmin'
import { usersSearch } from '@/api/User/UserSearch'
import swal from 'sweetalert'
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'AdminExistingUser',
    //components: { PulseLoader },
    data: () => ({
        //
        inputToSearch: '',
        inputToSearchErrors: {},
        searchFailed: true,
        //
        tableData: [],
        tableMeta: {},
        //
        initialInputValues: true,
        loadingTableData: false
    }),
    methods: {
        createAdmin (pUser) {
            this.$set(pUser, "createAdminLoading", true);

            let AdminFormData = new FormData()
            AdminFormData.append('description', `admin ${pUser.attributes.name}`)

            storeUserAdmin(AdminFormData, pUser.attributes.id)
                .then(() => {
                     swal('Creación correcta!', 'Nuevo admin. creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'admin-admins-list'})
                                    break;

                                default:
                                    console.log('adminCreate-existingUser-storeUserAdmin-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log(err.response);
                })
                .finally(() => {
                    pUser.createAdminLoading = false
                    console.log('request create userAdmin end');
                })
        },

        searchUserWithoutRoleAdmin (pPage = 1) {
            this.initialInputValues = false
            this.loadingTableData = true
            this.inputToSearchErrors = {}

            let SearchFormData = new FormData()
            SearchFormData.append('input_to_search', this.inputToSearch)

            usersSearch(pPage, SearchFormData)
                .then(({data}) => {
                    this.tableData = data.data.filter(el => {
                        let hasAdminRole
                        for (const elem of el.attributes.user_roles) {
                             if (elem.attributes.role_name == 'admin') {
                                hasAdminRole = false
                                break
                            } else {
                                hasAdminRole = true
                            }
                        }
                        return hasAdminRole
                    })

                    this.tableMeta = data.meta
                    this.searchFailed = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputToSearchErrors = err.response.data.errors
                        this.searchFailed = true
                    }
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('request userSearch end');
                })
        },

        paginationCLiked (e) {
            this.searchUserWithoutRoleAdmin(e)
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputToSearchErrors).includes(pInput)) return this.inputToSearchErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputToSearchErrors).includes(pInput)) return false
            else return true
        }
    },
}
</script>

<style scoped>
.modal-user-data-icon:hover ~ .modal-user-data-id {
    transform: scale(1);
}
.modal-user-data {
    width: fit-content;
    border: 1px solid #5e72e4;
    border-radius:10px;
    text-transform: uppercase;
}
.modal-user-data-id {
    position: absolute;
    bottom: 80%;
    padding: 4px 8px;
    background: #000;
    border-radius: 5px;
    font-size: 10pt;
    color: white;
    opacity: .75;
    transition: .300s ease-in all;
    transform-origin: left bottom;
    transform: scale(0);
}
</style>