import { get, post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getProgramSeasonParticipant (pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipants/${pIdProgramSeasonParticipant}`
    return get(URI, { headers })
}

function getProgramSeasonParticipantLite (pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/${pIdProgramSeasonParticipant}/lite-resource`
    return get(URI, { headers })
}

//
function updateStatusDocument (pFormData, pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/${pIdProgramSeasonParticipant}/updateStatusDocument`
    return post(URI, pFormData, { headers })
}

function updateStatusFicha (pFormData, pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/${pIdProgramSeasonParticipant}/updateStatusFicha`
    return post(URI, pFormData, { headers })
}

function updateNumberOfPostulations (pFormData, pIdProgramSeasonParticipant) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/${pIdProgramSeasonParticipant}/updateNumberOfPostulations`
    return post(URI, pFormData, { headers })
}

export {
    getProgramSeasonParticipant,
    getProgramSeasonParticipantLite,
    updateStatusDocument,
    updateStatusFicha,
    updateNumberOfPostulations
}