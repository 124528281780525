import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL_TO_SIGNATURE = store.state.api.baseUrlToSignature
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function storeSignature(pFormdata) {
    const URI = `${BASE_URL_TO_SIGNATURE}/api/save-signature`
    return axios.post(URI, pFormdata, { headers })
}

function storeFingerprint(pFormdata) {
    const URI = `${BASE_URL_TO_SIGNATURE}/api/save-fingerprint`
    return axios.post(URI, pFormdata, { headers })
}

function storeDniAnverso(pFormdata) {
    const URI = `${BASE_URL_TO_SIGNATURE}/api/save-dniAnverso`
    return axios.post(URI, pFormdata, { headers })
}

function storeDniReverso(pFormdata) {
    const URI = `${BASE_URL_TO_SIGNATURE}/api/save-dniReverso`
    return axios.post(URI, pFormdata, { headers })
}

export {
    storeSignature,
    storeFingerprint,
    storeDniAnverso,
    storeDniReverso,
}