import { get } from 'axios'
import store from '@/store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = {
    'Cache-Control': 'no-store, no-cache',
    Authorization: `Bearer ${store.state.user.attributes.token}`
}

function participantInformationExport (pSeason, pParams) {
    const URI = `${BASE_URL}/api/export/season/${pSeason}/participant-information`
    const responseType = 'blob'
    return get(URI, { headers, responseType, params: pParams })
}

export { participantInformationExport }