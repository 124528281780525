<template>
    <span>
        <b-button variant="outline-primary" size="sm" @click="modalClick(file)" class="mr-2"> <b-icon icon="eye"  scale="1.2"></b-icon></b-button>

        <b-modal
            :id="`modal-html-contract-generated-preview-${id}`"
            centered
            hide-footer
            size="xl"
            header-class="pb-0"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Vista previa</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalClose" :disabled="loadingPreview">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row style="overflow-x:auto">
                        <b-col v-show="loadingPreview">
                            <div style="height:250px;">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="15"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col cols="12" v-show="!loadingPreview" class="mb-5">
                            <b-button @click="modalVerifyClick" variant="outline-primary" size="sm" >Verificar</b-button>
                        </b-col>

                        <b-col cols="12" v-if="!loadingPreview" class="view-wraper-vue-pdf">
                            <vue-pdf-embed :source="documentSelectedToPreview"></vue-pdf-embed>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!--  modal verify -->
        <b-modal
            :id="`modal-verify-${id}`"
            centered
            hide-footer
            size="md"
            header-class="pb-0"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Vista previa</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalVerifyClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row style="overflow-x:auto">
                        <b-col cols="12">
                            <b-form @submit.prevent="verificarContratoHtml">
                                <!-- status -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Estado:"
                                        >
                                            <pulse-loader
                                                :loading="setLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="input_verification_status"
                                                :options="statusOptions"
                                                :state="showInputStatus('verification_status')"
                                                v-show="!setLoading"
                                            ><template #first><b-form-select-option value=null disabled>-- Seleccione un estado --</b-form-select-option></template>
                                            </b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('verification_status')"
                                                :key="`${i}-verification_status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Comentarios :" 
                                        >
                                            <pulse-loader
                                                :loading="setLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese los comentarios"
                                                v-model="input_verification_comments"
                                                :state="showInputStatus('verification_comments')"
                                                v-show="!setLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showInputErrors('verification_comments')"
                                                :key="`${i}--verification_comments`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="setLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </span>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { setVerifyData } from '@/api/HtmlContract/HtmlContract'

import swal from 'sweetalert'

export default {
    name: 'HtmlContractGeneratedPreviewComponent',
    props: {
        htmlContract: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        file: {
            type: String,
            required: true
        }
    },
    components: { VuePdfEmbed },
    data: () => ({
        input_verification_status: '',
        input_verification_comments: '',
        setLoading: false,
        //
        documentSelectedToPreview: '',
        loadingPreview: false,
        //
        inputErrors: {},
        initialInputValues: true,
        //
        statusOptions: [
            { value: 'verde', text: 'verificado' },
            { value: 'amarillo', text: 'pendiente' },
            { value: 'rojo', text: 'no válido' },
        ],
    }),

    methods: {
        modalClick(pDocumentToPreview) {
            this.setDocummentToshow(pDocumentToPreview)
            this.$bvModal.show(`modal-html-contract-generated-preview-${this.id}`)
        },
        modalClose() {
            this.$bvModal.hide(`modal-html-contract-generated-preview-${this.id}`)
        },

        setDocummentToshow(pDocumentToPreview) {
            this.loadingPreview = true

            setTimeout(() => {
                this.documentSelectedToPreview = pDocumentToPreview
                this.loadingPreview = false
            }, 1500);
        },

        // modal verify
        modalVerifyClick() {
            this.input_verification_status = this.htmlContract.verification_status
            this.input_verification_comments = this.htmlContract.verification_comments
            this.$bvModal.show(`modal-verify-${this.id}`)
        },
        modalVerifyClose() {
            this.$bvModal.hide(`modal-verify-${this.id}`)
            this.clearInputsAndStates()
        },

        verificarContratoHtml() {
            this.inputErrors = {}
            this.initialInputValues = false
            this.setLoading = true

            let VerifyForm = new FormData()
            VerifyForm.append('.method', 'put')
            VerifyForm.append('verification_status', this.input_verification_status)
            VerifyForm.append('verification_comments', this.input_verification_comments == null ? '' : this.input_verification_comments)

            setVerifyData(VerifyForm, this.htmlContract.id)
                .then(() => {
                    swal('Actualización correcta!', '', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$emit('setVerifyDone')
                                    this.modalVerifyClose()
                                    break;

                                default:
                                    console.log('set-verify--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.setLoading = false
                })
        },


        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.input_verification_status = ''
            this.input_verification_comments = ''
            this.initialInputValues = true
            this.inputErrors = {}
        },
    }
}
</script>

<style scoped>
.view-wraper-vue-pdf {
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 5px;
    background-color: #ccc;
    height: 72vh;
    overflow: auto;
}
.vue-pdf-embed > div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.view-document {
    max-width: 100%;
    height: 100%;
}
</style>