import { post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//post
function storeUserAdmin (pFormData, pIdUser) {
    const URI = `${BASE_URL}/api/users/${pIdUser}/admins`
    return post(URI, pFormData)
}

export {
    storeUserAdmin,
}