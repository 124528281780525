import { getUserNotifications } from '@/api/User/UserNotifications'

export default {
    namespaced:  true,
    state: {
        data: [],
        loading: false,
        ghost_loading: false,
    },

    mutations: {
        // notifications data
        SET_DATA (state, payload) {
            state.data = payload
        },

        DELETE_DATA (state) {
            state.attributes = []
        },

        SET_LOADING_STATUS (state, payload) {
            state.loading = payload
        },

        SET_GHOST_LOADING_STATUS (state, payload) {
            state.ghost_loading = payload
        },
    },

    actions: {
        getData (context, payload = { amount : '', ghost: false }) {
            if (payload.ghost) context.commit('SET_GHOST_LOADING_STATUS', true)
            else context.commit('SET_LOADING_STATUS', true)

            getUserNotifications(
                context.rootState.api.baseUrl,
                context.rootState.user.attributes.token,
                context.rootState.user.attributes.IdUser,
                payload.amount)
                .then(({ data }) => {
                    context.commit('SET_DATA', data.data)
                })
                .finally(() => {
                    if (payload.ghost) context.commit('SET_GHOST_LOADING_STATUS', false)
                    else context.commit('SET_LOADING_STATUS', false)
                })
        },
    },

    getters: {
        getNotification: (state) => (pLimint = '') => {
            if (pLimint != '') {
                console.log('limit:', pLimint)
            } else {
                return state.data
            }
        },

        getNotReadNumber (state) {
            let num = 0
            for (const notification of state.data) {
                if (notification.attributes.read == 'no') num += 1
            }
            return num 
        }
    }
}