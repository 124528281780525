<template>
    <b-card bg-variant="transparent" border-variant="white" no-body>
        <b-card-header header-bg-variant="transparent" header-border-variant="white" header-class="m-0 p-0">
            <b-row>
                <b-col>
                    <h3 class="mb-0 text-cielo text-uppercase">
                        Voucher reembolsado
                    </h3>
                </b-col>

                <b-col cols="auto">
                    <b-button variant="cielo" size="sm" v-b-toggle="'collapse-invalid-vouchers'">
                        <b-icon icon="eye" class="mr-1"></b-icon> VER
                    </b-button>
                </b-col>
            </b-row>
        </b-card-header>

        <b-collapse id="collapse-invalid-vouchers">
            <b-row align-h="start">
                <b-col cols="12" class="mt-4">
                    <b-card>
                        <b-row>
                            <b-col cols="12" class="text-right mb-2" v-if="ui != 'lead'">
                                <b-button size="sm" variant="transparent" @click="deleteRefundered(refundedVoucher)" v-show="!refundedVoucher.deleteLoading">
                                    <b-icon icon="trash" variant="magenta">
                                </b-icon></b-button>

                                <div style="height:120px;"  v-show="refundedVoucher.deleteLoading">
                                    <pulse-loader
                                        :color="'#EF4A81'"
                                        :margin="'10px'"
                                        :size="20"
                                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                                </div>
                            </b-col>

                            <b-col md="6" class="align-self-center"  v-show="!refundedVoucher.deleteLoading">
                                <b-card-img
                                    :src="refundedVoucher.attributes.image"
                                    alt="voucher"
                                    fluid
                                    center
                                    class="apk-voucher border border-cielo"
                                    style="border: gray 1px solid"
                                ></b-card-img>
                            </b-col>

                            <b-col md="6"  v-show="!refundedVoucher.deleteLoading">
                                <b-card-text>
                                    <b-list-group>
                                        <b-list-group-item class="text-body-1" variant="cielo">
                                            <b-icon icon="bookmark-fill" class="mr-1"></b-icon>
                                            {{ refundedVoucher.attributes.code }}
                                        </b-list-group-item>

                                        <b-list-group-item class="text-body-1">
                                            <b-icon icon="file-text" class="mr-1"></b-icon>
                                            {{ refundedVoucher.attributes.comments }}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-collapse>
    </b-card>
</template>

<script>
import { destroyRefunderedVoucher } from '@/api/RefundedVoucher/RefundedVoucher'
import swal from 'sweetalert'

export default {
    name: 'ShowRefundVoucherComponent',
    props: {
        refundedVoucher: {
            type: Object,
            required: true
        },
        ui: {
            type: String,
            required: true
        }
    },
    data: () => ({}),
    methods: {
        deleteRefundered(pRefunderedVoucher) {
            swal('Se eliminará el voucher.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pRefunderedVoucher, 'deleteLoading', true)
                           destroyRefunderedVoucher(pRefunderedVoucher.attributes.id)
                                .then(() => {
                                    this.$emit('deleted')
                                })
                                .finally(() => {
                                    pRefunderedVoucher.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete--viu-swal-break');
                            break;
                    }
                })
        },
    }
}
</script>