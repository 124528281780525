<template>
    <postulant-details-component :ui="'admin'" :routeToList="'admin-postulants-list'"></postulant-details-component>
</template>

<script>
import PostulantDetailsComponent from '@/components/Admin-Employee/Postulant/Details/PostulantDetailsComponent.vue'

export default {
    name: "PostulantDetails",
    components: { PostulantDetailsComponent },
    data: () => ({}),
}
</script>