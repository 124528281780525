import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

// get
function getAllActiveSalesAdvisorsByProgram (pProgramId) {
    const URI = `${BASE_URL}/api/all-salesAdvisors-activate/program/${pProgramId}`
    return get(URI, { headers })
}
export { getAllActiveSalesAdvisorsByProgram }