<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="getData"
                            />
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th class="text-center">detalles</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                            <td>{{ row.attributes.name }}</td>
                            <td>{{ row.attributes.description }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: routeName,
                                        params: { 'IdContractLayout': row.id }    // IdContractLayout ~ IdHtmlLayout
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.2"></b-icon>
                                </b-btn>
                            </td>

                            <td class="text-center">
                                <pulse-loader
                                    :loading="row.deleteLoading || false"
                                    :color="'#5e72e4'"
                                    :margin="'2px'"
                                    :size="8"
                                    class="pt-2 pb-1"
                                ></pulse-loader>

                                <b-btn variant="transparent" size="sm" 
                                    v-show="!row.deleteLoading && true"
                                    @click="deleteHtmlLayout(row)"
                                ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getSeasonHtmlLayouts, destroyHtmlLayout } from '@/api/HtmlLayout/HtmlLayout';
import { getAllSeasons } from '@/api/Season/Season'
import swal from 'sweetalert'

export default {
    name: "ContractLayoutListComponent",
    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
    }),
    props: {
        routeName: {
            type: String
        }
    },
    created() {
        this.getSeasonsData()
    },
    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasonOptions) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                    this.getData()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getData () {
            this.loadingTableData = true
            getSeasonHtmlLayouts(this.seasonSelected)
                .then(({ data }) => {
                    this.tableData = data.data.filter(el => el.attributes.type == 'contract')
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        reloadData () {
            this.getSeasonsData()
        },

        deleteHtmlLayout (pHtmlLayout) {
            swal(`Se eliminará el layout: ${pHtmlLayout.attributes.name}`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pHtmlLayout, 'deleteLoading', true)
                            destroyHtmlLayout(pHtmlLayout.attributes.id)
                                .then(() => {
                                    this.getData()
                                })
                                .finally(() => {
                                    pHtmlLayout.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-htmllayout-swal-break');
                            break;
                    }
                })
        }
    }
}
</script>