import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// sponsortJobOffer
function updateProgramSeasonSponsorInputsOrder (pFormdata, IdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsor/${IdProgramSeasonSponsor}/updateInputsOrder`
    return post(URI, pFormdata, { headers })
}

export { updateProgramSeasonSponsorInputsOrder }