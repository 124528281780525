<template>
    <div>
        <b-button variant="outline-primary" size="sm" @click="modalClick"> <b-icon icon="pencil"  scale="1.3"></b-icon></b-button>

        <b-modal
            :id="`modal-${type}-${id}`"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-backdrop
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR ESTADO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- form -->
                        <b-col cols="12">

                            <b-list-group class="mb-5">
                                <b-list-group-item>
                                    <b-row>
                                        <b-col cols="3" class="apk-details-tag" >Estado</b-col>
                                        <b-col cols="1" class="apk-details-tag">:</b-col>
                                        <b-col class="text-magenta">{{ model.status }}</b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item>
                                    <b-row>
                                        <b-col cols="3" class="apk-details-tag" >Comen.</b-col>
                                        <b-col cols="1" class="apk-details-tag">:</b-col>
                                        <b-col >{{ model.comments }}</b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item>
                                    <b-row>
                                        <b-col v-if="type == 'abandonment'" class="text-center">
                                            <b-button @click="changeToPostponement" variant="primary">Cambiar a estado postergado</b-button>
                                        </b-col>
                                        <b-col v-if="type == 'abandonment'" class="text-center">
                                            <b-button @click="deleteAbandonment" variant="magenta">Quitar de abandonado</b-button>
                                        </b-col>

                                        <b-col v-if="type == 'postponement'" class="text-center">
                                            <b-button @click="changeToAbandonment" variant="primary">Cambiar a estado abandonado</b-button>
                                        </b-col>
                                        <b-col v-if="type == 'postponement'" class="text-center">
                                            <b-button @click="deletePostponement" variant="magenta">Quitar de postergado</b-button>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { abandonmentChangeToPostponement, destroyAbandonment } from '@/api/Abandonment/Abandonment'
import { postponementChangeToAbandonment, destroyPostponement } from '@/api/Postponement/Postponement'
import swal from 'sweetalert'

export default {
    name: 'ChangeProgramStateComponent',
    props: {
        type: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        model: {
            type: Object,
        }
    },
    data: () => ({
        comments: '',
        loading: false,
        //
        inputErrors: {},
        initialInputValues: true
    }),

    methods: {
        modalClick() {
            this.clearInputsAndStates()
            this.$bvModal.show(`modal-${this.type}-${this.id}`)
        },
        modalClose() {
            this.$bvModal.hide(`modal-${this.type}-${this.id}`)
            this.clearInputsAndStates()
        },

        changeToAbandonment () {
            swal('Se cambiará el estado del programa a ABANDONADO', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, cambiar.']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.loading = true
                            postponementChangeToAbandonment(this.model.id)
                                .then(() => {
                                    swal('Programa actualizado!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.modalClose()
                                                    this.$emit('updated')
                                                    break;

                                                default:
                                                    console.log('change-to-abandonment--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;

                        case null:
                            this.modalClose()
                            break;

                        default:
                            console.log('abandon-alert-break');
                            break;
                    }
                })
        },

        deleteAbandonment () {
            swal('Se eliminará el estado ABANDONADO', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar.']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.loading = true
                            destroyAbandonment(this.model.id)
                                .then(() => {
                                    swal('Programa actualizado!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.modalClose()
                                                    this.$emit('updated')
                                                    break;

                                                default:
                                                    console.log('change-to-abandonment--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;

                        case null:
                            this.modalClose()
                            break;

                        default:
                            console.log('abandon-alert-break');
                            break;
                    }
                })
        },

        changeToPostponement () {
            swal('Se cambiará el estado del programa a POSTERGADO', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, cambiar.']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.loading = true
                            abandonmentChangeToPostponement(this.model.id)
                                .then(() => {
                                    swal('Programa actualizado!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.modalClose()
                                                    this.$emit('updated')
                                                    break;

                                                default:
                                                    console.log('change-to-abandonment--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;

                        case null:
                            this.modalClose()
                            break;

                        default:
                            console.log('abandon-alert-break');
                            break;
                    }
                })
        },

         deletePostponement () {
            swal('Se eliminará el estado POSTERGADO', '¿Desea continuar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar.']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.loading = true
                            destroyPostponement(this.model.id)
                                .then(() => {
                                    swal('Programa actualizado!', '', 'success')
                                        .then(value => {
                                            switch (value) {
                                                case true:
                                                case null:
                                                    this.modalClose()
                                                    this.$emit('updated')
                                                    break;

                                                default:
                                                    console.log('change-to-abandonment--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                            break;

                        case null:
                            this.modalClose()
                            break;

                        default:
                            console.log('abandon-alert-break');
                            break;
                    }
                })
        },

        showInputErrors(pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus(pInput) {
            if (this.initialInputValues) return null
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates() {
            this.comments = ''
            this.initialInputValues = true
            this.inputErrors = {}
        },
    }
}
</script>

<style scoped>
.apk-details-tag {
    text-transform: uppercase;
    color: #b5b4b4;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 12px;
}
</style>