import { get, post } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function getCulturalAdvisorCulturalAdvisorPermissions (pIdCulturalAdvisor) {
    const URI = `${BASE_URL}/api/culturalAdvisors/${pIdCulturalAdvisor}/culturalAdvisorPermissions`
    return get(URI, { headers })
}

function updateCulturalAdvisorCulturalAdvisorPermissions (pFormData, pIdCulturalAdvisor, pIdCulturalAdvisorPermission) {
    const URI = `${BASE_URL}/api/culturalAdvisors/${pIdCulturalAdvisor}/culturalAdvisorPermissions/${pIdCulturalAdvisorPermission}`
    return post(URI, pFormData, { headers })
}

export {
    getCulturalAdvisorCulturalAdvisorPermissions,
    updateCulturalAdvisorCulturalAdvisorPermissions
}