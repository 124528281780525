<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                LISTA
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchVoucher">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                placeholder="Búsqueda por nombres o apellidos o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col >
                                        <b-form-group class="mb-0">
                                            <b-button class="w-100" size="sm" variant="primary" type="submit" >
                                                <b-icon icon="search" class="m-0 p-0"/>
                                            </b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>detalles</th>
                            <th>preview</th>
                            <th>Código</th>
                            <th>concepto</th>
                            <th>Lead</th>
                            <th>Detalles</th>
                            <th>programa</th>
                            <th>temporada</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <span>{{ index + 1 }}</span>
                            </td>

                            <td>
                                <b-btn v-if="row.type == 'PostulantVoucher-Search'"
                                    :to="{
                                        name: routeToPostulantsDetails,
                                        params: { 
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="contract-1" target="_blank"
                                    size="sm"><b-icon icon="three-dots" variant="white"></b-icon>
                                </b-btn>

                                <!-- contractVoucher -->
                                <!-- has participant  == si -->
                                <b-btn v-if="row.type == 'ContractVoucher-Search' && row.attributes.hasParticipant == 'si'"
                                    :to="{
                                        name: routeToParticipantsDetails,
                                        params: { 
                                            'IdProgramSeasonParticipant': row.attributes.programSeasonParticipant_id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="contract-payment-1" target="_blank"
                                    size="sm" ><b-icon icon="three-dots" variant="white"></b-icon>
                                </b-btn>

                                <!-- has participant == no-->
                                <b-btn v-if="row.type == 'ContractVoucher-Search' && row.attributes.hasParticipant == 'no'"
                                    :to="{
                                        name: routeToPostulantsDetails,
                                        params: { 
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="contract-1" target="_blank"
                                    size="sm" ><b-icon icon="three-dots" variant="white"></b-icon>
                                </b-btn>
                            </td>

                            <!-- preview -->
                            <td>
                                <b-button variant="outline-primary" size="sm" @click="modalVoucherPreviewClick(row.attributes.image)"
                                ><b-icon icon="eye" scale="1.2"></b-icon></b-button>
                            </td>

                            <!-- code -->
                            <td>{{ row.attributes.code }}</td>

                            <!-- voucher_concept -->
                            <td>{{ row.attributes.voucher_concept }}</td>

                            <!-- lead -->
                            <td>{{ row.attributes.user_full_name }}</td>

                            <!-- detalles -->
                            <td>{{ row.attributes.description }}</td>

                            <!-- program -->
                            <td class="text-uppercase">
                                <span :class="[row.attributes.program_id == 1 ? 'text-primary' : (row.attributes.program_id == 2 || row.attributes.program_id == 3 ? 'text-cielo' : (row.attributes.program_id == 4 ? 'text-naranja' : 'text-magenta')) ]">
                                    {{ row.attributes.program == 'work and travel' ? 'wat' : row.attributes.program}}
                                </span> 
                            </td>

                            <!-- seasom -->
                            <td>{{ row.attributes.season }}</td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- MODAL DOCUMENT PREVIEW  -->
        <b-modal
            id="modal-voucher-search-preview"
            centered
            hide-footer
            size="xl"
            header-class="pb-0"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="8"><h3 class="text-primary mb-0 text-uppercase">Vista previa</h3></b-col>
                        <b-col cols="auto" class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalVoucherPreviewClose" :disabled="loadingPreview">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row style="overflow-x:auto">
                        <b-col v-show="loadingPreview">
                            <div style="height:250px;">
                                <pulse-loader :color="'#5e72e4'" :margin="'10px'" :size="15" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col cols="12" v-show="!loadingPreview" class="view-wraper">
                            <embed :src="documentSelectedToPreview" class="view-document"/>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

    </b-row>
</template>

<script>
import { voucherSearch } from '@/api/Voucher/VoucherSearch';

export default {
    name: "PostulantsList",
    props: {
        routeToPostulantsDetails: {
            type: String,
            required: true
        },
        routeToParticipantsDetails: {
            type: String,
            required: true
        }
    },
    data: () => ({
        tableData: [],
        loadingTableData: false,
        //
        input_to_search: '',
        //
        documentSelectedToPreview: '',
        loadingPreview: false
    }),
    created() {
        this.searchVoucher()
    },
    methods: {
        /**search */
        searchVoucher () {
            this.loadingTableData = true

            const SearchForm = new FormData()
            SearchForm.append('input_to_search', this.input_to_search)
            SearchForm.append('test', 'no')

            voucherSearch(SearchForm)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        reloadData () {
            this.searchVoucher()
        },

        // modal preview
        modalVoucherPreviewClick (pVoucherToPreview) {
            this.$bvModal.show('modal-voucher-search-preview')
            this.setDocummentToshow(pVoucherToPreview)
        },
        modalVoucherPreviewClose () {
            this.$bvModal.hide('modal-voucher-search-preview')
        },

        setDocummentToshow(pVoucherToPreview) {
            this.loadingPreview = true
            setTimeout(() => {
                this.documentSelectedToPreview = pVoucherToPreview
                this.loadingPreview = false
            }, 1500);
        },

    }
}
</script>

<style scoped>
.view-wraper {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 5px;
    background-color: #ccc;
    height: 72vh;
    overflow: auto;
}
.view-document {
    max-width: 100%;
    height: 100%;
}
</style>