import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function getPostulation (pIdPostulation) {
    const URI = `${BASE_URL}/api/postulations/${pIdPostulation}`
    return axios.get(URI, { headers })
}

function storePostulation (pFormData) {
    const URI = `${BASE_URL}/api/postulations`
    return axios.post(URI, pFormData, { headers })
}

function registerPostulation (pFormData) {
    const URI = `${BASE_URL}/api/postulation/register`
    return axios.post(URI, pFormData, { headers })
}

function updatePostulation (pFormData, pIdPostulation) {
    const URI = `${BASE_URL}/api/postulations/${pIdPostulation}`
    return axios.post(URI, pFormData, { headers })
}

function destroyPostulation (pIdPostulation) {
    const URI = `${BASE_URL}/api/postulations/${pIdPostulation}`
    return axios.delete(URI, { headers })
}

function updateSubject (pFormData, pIdPostulation) {
    const URI = `${BASE_URL}/api/postulation/${pIdPostulation}/update-subject`
    return axios.post(URI, pFormData, { headers })
}

export {
    getPostulation,
    storePostulation,
    registerPostulation,
    updatePostulation,
    destroyPostulation,
    updateSubject
}