<template>
    <b-row class="mb-4">
        <b-col cols="12" >
            <b-card bg-variant="transparent" no-body class="border-0">
                <b-card-header class="border-0 bg-transparent">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-white text-button">
                                TEMPORADA :
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="3">
                            <pulse-loader
                                :loading="seasonsLoading"
                                :color="'#fff'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #fff"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasons"
                                v-show="!seasonsLoading"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="seasonsLoading || loading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loading data -->
                <div style="height:250px;" v-show="seasonsLoading || loading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-row v-if="!seasonsLoading && !loading">
                    <b-col cols="12" lg="6" class="mb-5">
                        <b-card class="shadow">
                            <b-card-title>
                                Descargar datos de documentos presentados de todos los participantes.
                            </b-card-title>

                            <div>
                                <b-col class="text-center">
                                    <b-button size="lg" variant="primary" @click="exportParticipantsDocuments">
                                        <b-icon icon="download" class="mr-2"></b-icon> DESCARGAR
                                    </b-button>
                                </b-col>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getAllSeasons } from '@/api/Season/Season'
import { exportDocuments } from '@/api/Season/ProgramSeasonParticipant/Export/Documents'
import FileSaver from 'file-saver';

export default {
    name: "ParticipantDocumentsExportComponent",
    data: () => ({
        loading: false,
        //
        seasons: [],
        seasonSelected: '',
        seasonsLoading: false
    }),

    mounted() {
        this.getSeasons()
    },

    methods: {
        getSeasons() {
            this.seasonsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasons = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))

                    // set current year if season-selected is empty
                    if (this.seasonSelected == '') {
                        for (const season of this.seasons) {
                            if(season.text ==  new Date().getFullYear()) {
                                this.seasonSelected = season.value
                                break;
                            }
                        }
                    }
                })
                .finally(() => {
                    this.seasonsLoading = false
                })
        },

        // export
        exportParticipantsDocuments () {
            this.loading = true
            exportDocuments(this.seasonSelected)
                .then(res => {
                    FileSaver.saveAs(res.data, 'Participant-documents.xlsx');
                })
                .finally(() => {
                    this.loading = false
                })
        },

        reloadData () {
            this.getSeasons()
        },
    }
}
</script>