<template>
    <b-row >
        <b-col cols="12" lg="6">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-uppercase">
                                Detalles del Programa
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="naranja" size="sm" @click="modalEditProgramSeasonClick(programSeason.attributes)">
                                <b-icon icon="pencil"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonPostulant -->
                <div style="height:300px;" v-show="programSeasonLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!programSeasonLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col>Programa</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeason.attributes.program_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Temporada</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeason.attributes.season_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Full placement</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeason.attributes.full_placement_cost_formated }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Self placement</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeason.attributes.self_placement_cost_formated }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Estado</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col :class="programSeason.attributes.status == 'activado' ? 'text-green' : 'text-gander'">
                                    {{ programSeason.attributes.status }}
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <b-col cols="12" lg="6">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-uppercase">
                                (+) Costos
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="green" size="sm" @click="modalCreateCostClick">
                                <b-icon icon="plus" ></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonPostulant -->
                <div style="height:300px;" v-show="programSeasonLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- COST TABLE -->
                <b-card class="table-responsive mt-5" v-show="!programSeasonLoading" no-body>
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="programSeason.attributes.costs"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>NOMBRE</th>
                            <th>cantidad</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.attributes.name }}</td>
                            <td>{{ row.attributes.amount_formated }}</td>
                            <td class="text-center">
                                <b-button variant="naranja" size="sm" @click="modalEditCostClick(row.attributes)">
                                    <b-icon icon="pencil"></b-icon>
                                </b-button>
                            </td>
                            <td class="text-center">
                                <div v-show="row.deleteLoading || false">
                                    <pulse-loader
                                        :color="'#EF4A81'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="text-center"
                                    ></pulse-loader>
                                </div>

                                <b-btn variant="magenta" size="sm" @click="deleteCost(row)" v-show="!row.deleteLoading">
                                    <b-icon icon="trash"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </b-card>

            </b-card>
        </b-col>

        <!-- modal: create-cost -->
        <b-modal
            id="modal-create-cost"
            centered
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">CREAR COSTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalCreateCostClose"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="createCost">
                        <b-form-row>
                            <b-col>
                                <b-form-group label="Nombre">
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese una nombre."
                                        v-model="name"
                                        :state="showInputStatus('name')"
                                        v-show="!createLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('name')" :key="`${i}-create-name`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group label="Cantidad">
                                    <pulse-loader
                                        :loading="createLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese la cantidad."
                                        v-model="amount"
                                        :state="showInputStatus('amount')"
                                        v-show="!createLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('amount')" :key="`${i}-create-amount`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="8">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    :disabled="createLoading"
                                >CREAR</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>

        <!-- modal: edit-cost -->
        <b-modal
            id="modal-edit-cost"
            centered
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR COSTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalEditCostClose"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="editCost">
                        <b-form-row>
                            <b-col>
                                <b-form-group label="Nombre">
                                    <pulse-loader
                                        :loading="updateLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese una nombre."
                                        v-model="costSelected.name"
                                        :state="showInputStatus('name')"
                                        v-show="!updateLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('name')" :key="`${i}-create-name`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group label="Cantidad">
                                    <pulse-loader
                                        :loading="updateLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese la cantidad."
                                        v-model="costSelected.amount"
                                        :state="showInputStatus('amount')"
                                        v-show="!updateLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('amount')" :key="`${i}-create-amount`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="8">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    :disabled="updateLoading"
                                >ACTUALIZAR</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>

        <!-- modal: edit-programSeason -->
        <b-modal
            id="modal-edit-programSeason"
            centered
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR PROGRAMA - TEMPORADA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                    clearInputsAndStates()
                                    close()
                                }"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="editProgramSeason(programSeasonSelected)">
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Programa"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-select 
                                        v-model="programSeasonSelected.program_id"
                                        :state="showInputStatus('program_id') && showInputStatus('program_code')"
                                        v-show="!editProgramSeasonLoading"
                                        disabled
                                    >
                                        <template #first>
                                            <b-form-select-option :value="programSeasonSelected.program_id" disabled>
                                                {{ programSeasonSelected.program_name }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_id')" :key="`${i}-createProgSea-program_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_code')" :key="`${i}-createProgSea-program_code`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Temporada"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-select 
                                        v-model="programSeasonSelected.season_id"
                                        :state="showInputStatus('season_id') && showInputStatus('program_code')"
                                        v-show="!editProgramSeasonLoading"
                                        disabled
                                    >
                                        <template #first>
                                            <b-form-select-option :value="programSeasonSelected.season_id" disabled>
                                                {{ programSeasonSelected.season_name }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('season_id')" :key="`${i}-createProgSea-season_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('program_code')" :key="`${i}-createProgSea-pro-co`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Full placement"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese el costo de full placement."
                                        v-model="programSeasonSelected.full_placement_cost"
                                        :state="showInputStatus('full_placement_cost')"
                                        v-show="!editProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('full_placement_cost')" :key="`${i}-createProgSea-cost`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        
                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Self placement"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-input
                                        type="number"
                                        step="0.01"
                                        placeholder="Ingrese el costo de self placement."
                                        v-model="programSeasonSelected.self_placement_cost"
                                        :state="showInputStatus('self_placement_cost')"
                                        v-show="!editProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('self_placement_cost')" :key="`${i}-createProgSea-cost`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row>
                            <b-col>
                                <b-form-group
                                    label="Descripción"
                                >
                                    <pulse-loader
                                        :loading="editProgramSeasonLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #d1d7dc"
                                    />

                                    <b-form-textarea 
                                        placeholder="Ingrese una descripción (opcional)"
                                        v-model="programSeasonSelected.description"
                                        :state="showInputStatus('description')"
                                        v-show="!editProgramSeasonLoading"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('description')" :key="`${i}-createProgSea-description`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="8">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    :disabled="editProgramSeasonLoading"
                                >ACTUALIZAR</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getProgramSeason, updateProgramSeason } from '@/api/ProgramSeason/ProgramSeason'
import { storeCost, updateCost, destroyCost } from '@/api/Cost/Cost'
import swal from 'sweetalert'

export default {
    name: "ProgramDetails",
    data: () => ({
        programSeason: {
            attributes: {}
        },
        programSeasonLoading: false,
        //
        name: '',
        amount: '',
        createLoading: false,
        inputErrors: {},
        initialInputValues: true,
        //
        costSelected: {},
        updateLoading: false,
        /**
         *  (edit) - programSeason
         */
        programSeasonSelected: {},
        editProgramSeasonLoading: false
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.programSeasonLoading = true
            let IdProgramSeason = this.$route.params.IdProgramSeason // ok
            getProgramSeason(IdProgramSeason)
                .then(({ data }) => {
                    this.programSeason = data.data
                })
                .finally(() => {
                    this.programSeasonLoading = false
                })
        },

        modalCreateCostClick() {
            this.$bvModal.show('modal-create-cost')
        },
        modalCreateCostClose() {
            this.$bvModal.hide('modal-create-cost')
            this.clearInputsAndStates()
        },

        createCost() {
            this.createLoading = true
            this.inputErrors = {}
            this.initialInputValues = false
            let CostForm = new FormData()
            CostForm.append('programSeason_id', this.$route.params.IdProgramSeason)
            CostForm.append('name', this.name)
            CostForm.append('amount', this.amount)
            storeCost(CostForm)
                .then(() => {
                    this.reloadData()
                    this.modalCreateCostClose()
                })
                .catch(err => {
                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                })
                .finally(() => {
                    this.createLoading = false
                })
        },

        modalEditCostClick(pCost) {
            this.costSelected = {...pCost}
            this.$bvModal.show('modal-edit-cost')
        },
        modalEditCostClose() {
            this.$bvModal.hide('modal-edit-cost')
            this.clearInputsAndStates()
        },

        editCost() {
            this.updateLoading = true
            this.inputErrors = {}
            this.initialInputValues = false
            let CostForm = new FormData()
            CostForm.append('.method', 'put')
            CostForm.append('name', this.costSelected.name)
            CostForm.append('amount', this.costSelected.amount)
            updateCost(CostForm, this.costSelected.id)
                .then(() => {
                    this.reloadData()
                    this.modalEditCostClose()
                })
                .catch(err => {
                    if (err.response.status === 422 ) this.inputErrors = err.response.data.errors
                })
                .finally(() => {
                    this.updateLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}

            // modal (create cost) 
            this.name = ''
            this.amount = ''

            // modal (edit cost) 
            this.costSelected = {}

            // modal (edit programSeason)
            this.programSeasonSelected = {}
        },

        deleteCost(pCost) {
            this.$set(pCost, 'deleteLoading', true)
            destroyCost(pCost.attributes.id)
                .then(() => {
                    getProgramSeason(this.$route.params.IdProgramSeason)
                        .then(({ data }) => {
                            this.programSeason = data.data
                        })
                })
                .finally(() => {
                    pCost.deleteLoading = false
                })
        },

        /**
         *  Modal (edit) programSeason
         */
        modalEditProgramSeasonClick (pProgramSeason) {
            this.programSeasonSelected = {...pProgramSeason}
            this.$bvModal.show('modal-edit-programSeason')
        },

        editProgramSeason(pProgramSeason) {
            this.inputErrors = {}
            this.initialInputValues = false
            this.editProgramSeasonLoading = true

            let ProgramSeason = new FormData()
            ProgramSeason.append('.method', 'put');
            ProgramSeason.append('full_placement_cost', pProgramSeason.full_placement_cost);
            ProgramSeason.append('self_placement_cost', pProgramSeason.self_placement_cost == null ? '' : pProgramSeason.self_placement_cost);
            ProgramSeason.append('description', pProgramSeason.description);

            updateProgramSeason(ProgramSeason, pProgramSeason.id)
                .then(( ) => {
                    swal('Actualización correcta!', 'Programa-temporada actualizado', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getData()
                                    this.$bvModal.hide('modal-edit-programSeason')
                                    break;

                                default:
                                    console.log('editProgramSeason-updateProgramSeason-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editProgramSeasonLoading = false
                })
        },

        reloadData() {
            this.getData()
        }
    }
}
</script>