import { get } from 'axios'
import store from '@/store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function filterParticipantDocument (pParams) {
    let uri = `${BASE_URL}/api/programSeasonParticipant/filters/participantDocument`
    return get(uri, { headers, params: pParams })
}

function filterParticipantDocumentsLastUploads (pParams) {
    const URI = `${BASE_URL}/api/programSeasonParticipant/filters/participantDocument-last-uploads`
    return get(URI, { headers, params: pParams })
}


export { 
    filterParticipantDocument,
    filterParticipantDocumentsLastUploads
}