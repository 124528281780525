<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'Participantes', path: { name: 'admin-participants-list' } }"/>
            <intranet-nav-content-link :link="{ name: 'Filtros', path: { name: 'admin-participants-filter-documents' } }"/>
            <!-- <intranet-nav-content-link :link="{ name: 'Test', path: { name: 'admin-participants-list-test' } }"/> -->
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';

export default {
    name: 'AdminParticipants',
    components: { 
        IntranetNavContent,
        IntranetNavContentLinkExact,
        IntranetNavContentLink
    },
};
</script>
