import axios from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function storeEmployerInterview (pFormData) {
    const URI = `${BASE_URL}/api/employerInterviews`
    return axios.post(URI, pFormData, { headers })
}

function updateEmployerInterview (pFormData, pIdEmployerInterview) {
    const URI = `${BASE_URL}/api/employerInterviews/${pIdEmployerInterview}`
    return axios.post(URI, pFormData, { headers })
}

function destroyEmployerInterview (pIdEmployerInterview) {
    const URI = `${BASE_URL}/api/employerInterviews/${pIdEmployerInterview}`
    return axios.delete(URI, { headers })
}

function updateResultsEmployerInterview (pFormData, pIdEmployerInterview) {
    const URI = `${BASE_URL}/api/employerInterview/${pIdEmployerInterview}/update-results`
    return axios.post(URI, pFormData, { headers })
}

export {
    storeEmployerInterview,
    updateEmployerInterview,
    destroyEmployerInterview,
    updateResultsEmployerInterview
}