import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// sponsotJobOffer.jobOfferInput
function getSponsorJobOfferJobOfferInputs (IdSponsorJobOffer) {
    const URI = `${BASE_URL}/api/sponsorJobOffers/${IdSponsorJobOffer}/jobOfferInputs`
    return get(URI, { headers })
}

export {
    getSponsorJobOfferJobOfferInputs,
}