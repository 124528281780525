<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <div v-if="contractLayoutLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="5">
                    </pulse-loader>
                </div>

                <div v-else>
                    <intranet-nav-content-link-exact :link="{
                        name: layoutName,
                        path: { name: routeName , params : { 'IdContractLayout' : contractLayout.id }} }">
                    </intranet-nav-content-link-exact>
                </div>
            </div>
        </b-col>

        <!-- form -->
        <b-col>
            <b-card class="shadow">
                <b-card-header class="border-0 px-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-uppercase text-primary">
                                editar layout
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button size="sm" variant="primary" @click="reloadData"><b-icon icon="arrow-counterclockwise"></b-icon></b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <transition name="slide-fade">
                <b-form @submit.prevent="getCurrentEditorOutput" v-if="!contractLayoutLoading">
                    <b-row align-v="center" align-h="center" class="my-2">
                        <b-col cols="12">
                            <b-form-row>
                                <!-- name -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label="Nombre:"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el nombre del layout."
                                            v-model="contractLayout.name"
                                            :state="showInputStatus('name')"
                                            v-show="!updateLoading"
                                            spellcheck="false"
                                            autocomplete="off"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('name')"
                                            :key="`${i}-create-layout-name`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- season -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label="Temporada:"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select
                                            class="text-center"
                                            size="sm"
                                            v-model="contractLayout.season_id"
                                            :options="seasonOptions"
                                            v-show="!updateLoading"
                                            :state="showInputStatus('season_id')"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('season_id')"
                                            :key="`${i}-create-layout-season_id`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- description -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label="Descripción:"
                                    >
                                        <pulse-loader
                                            :loading="updateLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="text-center pt-2 pb-1 pl-2"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea 
                                            placeholder="Ingrese una descripción (opcional)"
                                            v-model="contractLayout.description"
                                            :state="showInputStatus('description')"
                                            v-show="!updateLoading"
                                            rows="3"
                                            max-rows="6"
                                            spellcheck="false"
                                            autocomplete="off"
                                        />

                                        <b-form-invalid-feedback
                                            v-for="(inputError, i) in showInputErrors('description')"
                                            :key="`${i}-create-layout-description`"
                                        >
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col v-show="updateLoading">
                            <div style="height:200px;">
                                    <pulse-loader 
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="16"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col v-show="!updateLoading">
                            <tiptap
                                :getHtml="editorOutputRequest"
                                :initialBodyValue="contractLayout.body"
                                :initialFooterValue="contractLayout.footer"
                                @output="editLayout" :uiMode="'test'"></tiptap>
                        </b-col>
                    </b-row>

                    <b-row align-v="center" align-h="center" class="mt-5">
                        <b-col cols="auto">
                            <b-button variant="primary" type="submit">
                                ACTUALIZAR LAYOUT
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
                </transition>
            </b-card>
        </b-col>
    </b-row>

</template>

<script>
import { getHtmlLayout, updateHtmlLayout } from '@/api/HtmlLayout/HtmlLayout'
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { getAllSeasons } from '@/api/Season/Season'
import Tiptap from '@/components/Tiptap/components/Tiptap.vue'
import swal from 'sweetalert'

export default {
    name: 'ContractLayoutComponent',
    components: { IntranetNavContentLinkExact, Tiptap },
    props: {
        routeName: {
            type: String
        }
    },
    data: () => ({
        layoutName: '',
        contractLayout: {},
        contractLayoutLoading: false,
        // form
        updateLoading: false,
        initialInputValues: true,
        inputErrors: {},
        // to tiptap
        editorOutputRequest: 1,
        contractBodyHtmlValue: '',
        contractFooterHtmlValue: '',
        //
        seasonOptions: [],
        seasonSelected: '',
        getSeasonOptionsLoading: false,
    }),
    created () {
        this.getSeasonsData()
    },
    methods: {
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                    this.getData()
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        getData () {
            this.contractLayoutLoading = true
            getHtmlLayout(this.$route.params.IdContractLayout)
                .then(({ data }) => {
                    this.layoutName = data.data.attributes.name
                    this.contractLayout = data.data.attributes
                })
                .finally(() => {
                    this.contractLayoutLoading = false
                })
        },
        //
        getCurrentEditorOutput() {
            this.editorOutputRequest += 1
        },

        editLayout(pOutput) {
            this.updateLoading = true
            this.initialInputValues = false

            let LayoutForm = new FormData()
            LayoutForm.append('.method', 'put')
            LayoutForm.append('season_id', this.contractLayout.season_id)
            LayoutForm.append('name', this.contractLayout.name)
            LayoutForm.append('type', 'contract')
            LayoutForm.append('header', '')
            LayoutForm.append('body', pOutput.body)
            LayoutForm.append('footer', pOutput.footer)
            LayoutForm.append('description', this.contractLayout.description)

            updateHtmlLayout(LayoutForm, this.contractLayout.id)
                .then(({ data }) => {
                    swal('Actualización correcta!', `Layout: ${data.data.attributes.name} actualizado.`, 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    break;

                                default:
                                    console.log('updatelayout--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.updateLoading = false
                })

        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialInputValues = true
            this.inputErrors = {}
        },

        reloadData() {
            this.getSeasonsData()
            this.clearInputsAndStates()
        }
        
    }
};
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
/* .slide-fade-leave-active {
  transition: all .5s ease;
} */
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

</style>