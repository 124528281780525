<template>
    <b-row class="mb-4">
     <b-col>
         <b-card class="shadow" no-body>
             <b-card-header class="border-0">
                 <b-row class="align-items-center">
                     <b-col>
                         <h3 class="mb-0 text-primary text-button">
                             Documentos
                         </h3>
                     </b-col>

                     <b-col class="text-right">
                         <b-button variant="green" size="sm" @click="modalVisaDocumentsClick"  :disabled="dataLoading" v-if="ui == 'admin' || ui == 'employee'">
                             <b-icon icon="plus" scale="1.2"></b-icon>
                         </b-button>
                         
                         <b-button variant="primary" size="sm" @click="reloadData" :disabled="dataLoading">
                             <b-icon icon="arrow-repeat"></b-icon>
                         </b-button>
                     </b-col>
                 </b-row>
             </b-card-header>

             <!-- loadingTableData -->
             <div style="height:300px;" v-show="dataLoading">
                 <pulse-loader
                     :color="'#5e72e4'"
                     :margin="'10px'"
                     :size="20"
                     style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
             </div>

             <!-- table -->
             <div class="table-responsive" v-show="!dataLoading">
                 <base-table class="table align-items-center table-flush"
                     thead-classes="thead-light"
                     tbody-classes="list"
                     :data="participantVisaDocuments"
                 >
                     <template slot="columns">
                         <th>#</th>
                         <th>DOCUMENTO</th>
                         <th>ARCHIVO</th>
                         <th>Descripción</th>
                         <th v-if="ui == 'admin' || ui == 'employee'"></th>
                     </template>

                     <template slot-scope="{row, index}">
                          <td>{{ index + 1 }}</td>
                         <td>{{ row.attributes.name }}</td>

                         <td>
                             <b-button
                                 :variant="row.attributes.file ? 'outline-primary' : 'outline-light'"
                                 size="sm" :href="row.attributes.file" target="_black"
                                 ><b-icon icon="link" scale="1.2"></b-icon>
                             </b-button>
                         </td>

                         <td>{{ row.attributes.description }}</td>

                         <td v-if="ui == 'admin' || ui == 'employee'">
                             <b-button size="sm" @click="modalEditVisaDocumentsClick(index, row)">
                                 <b-icon icon="pencil" variant="naranja"></b-icon>
                             </b-button>

                             <b-button size="sm" @click="deleteParticipantVisaDoc(index, row)" :disabled="row.attributes.deleteLoading">
                                 <pulse-loader :color="'#EF4A81'" :margin="'2px'" :size="8" v-if="row.attributes.deleteLoading"/>
                                 <b-icon icon="trash" variant="magenta" v-else></b-icon>
                             </b-button>
                         </td>
                     </template>
                 </base-table>
             </div>
         </b-card>
     </b-col>

     <!-- MODAL VISA DOCUMENTS -->
     <b-modal
         id="modal-visa-documents"
         centered
         hide-footer
         size="lg"
         no-close-on-backdrop
         no-close-on-esc
     >
         <!-- modal header -->
         <template #modal-header>
             <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                 <b-row align-h="between" align-v="center">
                     <b-col cols="auto"><h3 class="text-primary mb-0 text-button">DOCS.</h3></b-col>
                     <b-col cols="auto">
                         <b-button size="sm" variant="magenta" @click="modalVisaDocumentsClose">
                             <b-icon icon="x" scale="1.5"/>
                         </b-button>
                     </b-col>
                 </b-row>
             </b-container>
         </template>

         <!-- modal content -->
         <template #default>
             <b-container>
                 <b-card no-body>
                     <!-- table -->
                     <div class="table-responsive">
                         <base-table class="table align-items-center table-flush"
                             thead-classes="thead-light"
                             tbody-classes="list"
                             :data="visaDocumentsFiltered"
                         >
                             <template slot="columns">
                                 <th>#</th>
                                 <th>Nombre</th>
                                 <th></th>
                             </template>

                             <template slot-scope="{row, index}">
                                 <td>{{ index + 1 }}</td>

                                 <td>{{ row.attributes.name }}</td>

                                 <td>
                                     <b-button variant="green" size="sm" @click="modalAddDocumentClick(index, row)" :disabled="dataLoading">
                                         <b-icon icon="plus" scale="1.2"></b-icon>
                                     </b-button>
                                 </td>
                             </template>
                         </base-table>
                     </div>
                 </b-card>
             </b-container>
         </template>
     </b-modal>

     <!-- MODAL ADD DOCUMENT -->
      <b-modal
         id="modal-add-document"
         centered
         hide-footer
         size="lg"
         no-close-on-backdrop
         no-close-on-esc
     >
         <!-- modal header -->
         <template #modal-header>
             <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                 <b-row align-h="between" align-v="center">
                     <b-col cols="auto"><h3 class="text-primary mb-0 text-button">ADD DOC.</h3></b-col>
                     <b-col cols="auto">
                         <b-button size="sm" variant="magenta" @click="modalAddDocumentClose">
                             <b-icon icon="x" scale="1.5"/>
                         </b-button>
                     </b-col>
                 </b-row>
             </b-container>
         </template>

         <!-- modal content -->
         <template #default>
             <b-container>
                 <b-form @submit.prevent="addDocument">
                     <!-- nombre -->
                     <b-form-row>
                         <b-col>
                             <b-form-group
                                 label="Documento :"
                             >
                                 <pulse-loader
                                     :loading="createLoading"
                                     :color="'#5e72e4'"
                                     :margin="'5px'"
                                     :size="10"
                                     class="pt-2 pb-1 pl-2 text-center"
                                     style="border-radius:5px; border:1px solid #5e72e4"
                                 />

                                 <b-form-input
                                     type="text"
                                     v-model="visaDocumentSelected.attributes.name"
                                     v-show="!createLoading"
                                     disabled
                                 />
                             </b-form-group>
                         </b-col>
                     </b-form-row>

                     <!-- nombre -->
                     <b-form-row>
                         <b-col>
                             <b-form-group
                                 label="Documento :"
                             >
                                 <pulse-loader
                                     :loading="createLoading"
                                     :color="'#5e72e4'"
                                     :margin="'5px'"
                                     :size="10"
                                     class="pt-2 pb-1 pl-2 text-center"
                                     style="border-radius:5px; border:1px solid #5e72e4"
                                 />

                                 <!-- archivo -->
                                 <b-form-file
                                     v-model="file"
                                     :state="showInputStatus('file')"
                                     v-show="!createLoading"
                                     style="overflow:hidden;"
                                 />
                                 <b-form-invalid-feedback
                                     v-for="(inputError, i) in showInputErrors('file')"
                                     :key="`${i}-create-resource-file`"
                                 >
                                     {{ inputError }}
                                 </b-form-invalid-feedback>
                             </b-form-group>
                         </b-col>
                     </b-form-row>

                     <!-- description-->
                     <b-form-row>
                         <b-col cols="12">
                             <b-form-group
                                 label="Descripción"
                             >
                                 <pulse-loader
                                     :loading="createLoading"
                                     :color="'#5e72e4'"
                                     :margin="'5px'"
                                     :size="10"
                                     class="pt-2 pb-1 pl-2 text-center"
                                     style="border-radius:5px; border:1px solid #5e72e4"
                                 />

                                 <b-form-textarea 
                                     placeholder="Ingrese una descripción (opcional)"
                                     v-model="description"
                                     :state="showInputStatus('description')"
                                     v-show="!createLoading"
                                     rows="3"
                                     max-rows="6"
                                 />

                                 <b-form-invalid-feedback
                                     v-for="(inputError, i) in showInputStatus('description')"
                                     :key="`${i}-create-resource-description`"
                                 >
                                     {{ inputError }}
                                 </b-form-invalid-feedback>
                             </b-form-group>
                         </b-col>
                     </b-form-row>

                     <b-row align-v="center" align-h="center">
                         <b-col cols="auto">
                             <b-button variant="primary" type="submit"
                                 :disabled="createLoading"
                             >
                                 GUARDAR
                             </b-button>
                         </b-col>
                     </b-row>
                 </b-form>
             </b-container>
         </template>
     </b-modal>

     <!-- MODAL ADD DOCUMENT -->
      <b-modal
         id="modal-edit-document"
         centered
         hide-footer
         size="lg"
         no-close-on-backdrop
         no-close-on-esc
     >
         <!-- modal header -->
         <template #modal-header>
             <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                 <b-row align-h="between" align-v="center">
                     <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDIT DOC.</h3></b-col>
                     <b-col cols="auto">
                         <b-button size="sm" variant="magenta" @click="modalEditVisaDocumentsClose">
                             <b-icon icon="x" scale="1.5"/>
                         </b-button>
                     </b-col>
                 </b-row>
             </b-container>
         </template>

         <!-- modal content -->
         <template #default>
             <b-container>
                 <b-form @submit.prevent="editParticipantVisaDoc">
                     <!-- nombre -->
                     <b-form-row>
                         <b-col>
                             <b-form-group
                                 label="Documento :"
                             >
                                 <pulse-loader
                                     :loading="editLoading"
                                     :color="'#5e72e4'"
                                     :margin="'5px'"
                                     :size="10"
                                     class="pt-2 pb-1 pl-2 text-center"
                                     style="border-radius:5px; border:1px solid #5e72e4"
                                 />

                                 <b-form-input
                                     type="text"
                                     v-model="participantVisaDocumentSelected.attributes.name"
                                     v-show="!editLoading"
                                     disabled
                                 />
                             </b-form-group>
                         </b-col>
                     </b-form-row>

                     <!-- nombre -->
                     <b-form-row>
                         <b-col>
                             <b-form-group
                                 label="Documento :"
                             >
                                 <pulse-loader
                                     :loading="editLoading"
                                     :color="'#5e72e4'"
                                     :margin="'5px'"
                                     :size="10"
                                     class="pt-2 pb-1 pl-2 text-center"
                                     style="border-radius:5px; border:1px solid #5e72e4"
                                 />

                                 <!-- archivo -->
                                 <b-form-file
                                     v-model="file"
                                     :state="showInputStatus('file')"
                                     v-show="!editLoading"
                                     style="overflow:hidden;"
                                 />
                                 <b-form-invalid-feedback
                                     v-for="(inputError, i) in showInputErrors('file')"
                                     :key="`${i}-create-resource-file`"
                                 >
                                     {{ inputError }}
                                 </b-form-invalid-feedback>
                             </b-form-group>
                         </b-col>
                     </b-form-row>

                     <!-- description-->
                     <b-form-row>
                         <b-col cols="12">
                             <b-form-group
                                 label="Descripción"
                             >
                                 <pulse-loader
                                     :loading="editLoading"
                                     :color="'#5e72e4'"
                                     :margin="'5px'"
                                     :size="10"
                                     class="pt-2 pb-1 pl-2 text-center"
                                     style="border-radius:5px; border:1px solid #5e72e4"
                                 />

                                 <b-form-textarea 
                                     placeholder="Ingrese una descripción (opcional)"
                                     v-model="participantVisaDocumentSelected.attributes.description"
                                     :state="showInputStatus('description')"
                                     v-show="!editLoading"
                                     rows="3"
                                     max-rows="6"
                                 />

                                 <b-form-invalid-feedback
                                     v-for="(inputError, i) in showInputStatus('description')"
                                     :key="`${i}-create-resource-description`"
                                 >
                                     {{ inputError }}
                                 </b-form-invalid-feedback>
                             </b-form-group>
                         </b-col>
                     </b-form-row>

                     <b-row align-v="center" align-h="center">
                         <b-col cols="auto">
                             <b-button variant="primary" type="submit"
                                 :disabled="editLoading"
                             >
                                 GUARDAR
                             </b-button>
                         </b-col>
                     </b-row>
                 </b-form>
             </b-container>
         </template>
     </b-modal>
 </b-row>
</template>

<script>
import { getParticipantVisaDocuments, storeParticipantVisaDocument, updateParticipantVisaDocument,destroyParticipantVisaDocument } from '@/api/ParticipantVisaDocument/ParticipantVisaDocument'
import { getVisaDocuments } from '@/api/VisaDocument/VisaDocument'
import swal from 'sweetalert'

export default {
    name: "ParticipantVisaDocumentComponent",
    props: {
        ui: {
            type: String,
            required: true
        }
    },
    data: () => ({
        participantVisaDocuments: [],
        visaDocumentsFiltered: [],
        dataLoading: false,
        // create participant visa document
        visaDocumentSelected: {
            attributes: {}
        },
        file: null,
        description: '',
        createLoading:false,
        // edit participant visa document
        participantVisaDocumentSelected: {
            attributes: {}
        },
        editLoading: false,
        //
        initialInputValues: true,
        inputErrors: []

    }),

    created() {
        this.getData()
    },

    methods: {
        getData() {
            this.dataLoading = true

            getParticipantVisaDocuments(this.$route.params.IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.participantVisaDocuments = data.data
                    this.getVisaData()
                })
        },

        getVisaData() {
            getVisaDocuments()
                .then(({ data }) => {
                    this.visaDocumentsFiltered = data.data.filter(el => {
                        // si esta con status inactivo: false - no mostrar 
                        if (el.attributes.status == 0) return false
                        // si esta ya esta registrado: false - no mostrar
                        for (const participantVisaDoc of this.participantVisaDocuments) {
                            if (participantVisaDoc.attributes.visaDocument_id == el.attributes.id) return false
                        }
                        // caso contrario: true - mostrar
                        return true
                    })
                })
                .finally(() => {
                    this.dataLoading = false
                })
        },

        modalVisaDocumentsClick() {
            this.$bvModal.show('modal-visa-documents')
        },
        modalVisaDocumentsClose() {
            this.$bvModal.hide('modal-visa-documents')
        },

        // create
        modalAddDocumentClick(pIndex, pVisaDocument) {
            this.visaDocumentSelected = JSON.parse(JSON.stringify({...pVisaDocument, index: pIndex}))
            this.$bvModal.show('modal-add-document')
        },
        modalAddDocumentClose() {
            this.$bvModal.hide('modal-add-document')
            this.clearInputsAndStates()
        },
        addDocument() {
            this.createLoading = true
            this.initialInputValues = false

            let DocFormdata = new FormData()
            DocFormdata.append('programSeasonParticipant_id', this.$route.params.IdProgramSeasonParticipant)
            DocFormdata.append('visaDocument_id', this.visaDocumentSelected.attributes.id)
            DocFormdata.append('file', this.file == null ? '' : this.file)
            DocFormdata.append('description', this.description)

            storeParticipantVisaDocument(DocFormdata)
                .then(({ data }) => {
                    this.participantVisaDocuments.push(data.data)
                    this.visaDocumentsFiltered.splice(this.visaDocumentSelected.index, 1)
                    this.modalAddDocumentClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createLoading = false
                })

        },

        // edit
        modalEditVisaDocumentsClick(pIndex, pParticipantVisaDocument) {
            this.participantVisaDocumentSelected = JSON.parse(JSON.stringify({...pParticipantVisaDocument, index: pIndex}))
            this.$bvModal.show('modal-edit-document')
        },
        modalEditVisaDocumentsClose() {
            this.$bvModal.hide('modal-edit-document')
            this.clearInputsAndStates()
        },
        editParticipantVisaDoc(){
            this.editLoading = true
            this.initialInputValues = false

            let DocFormdata = new FormData()
            DocFormdata.append('.method', 'put')
            DocFormdata.append('file', this.file == null ? '' : this.file)
            DocFormdata.append('description', this.participantVisaDocumentSelected.attributes.description)


            updateParticipantVisaDocument(this.participantVisaDocumentSelected.id, DocFormdata)
                .then(({ data }) => {
                    this.participantVisaDocuments.splice(this.participantVisaDocumentSelected.index, 1, data.data)
                    this.modalEditVisaDocumentsClose()
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editLoading = false
                })
        },

        clearInputsAndStates() {
            this.visaDocumentSelected = {
                attributes: {}
            }

            this.participantVisaDocumentSelected = {
                attributes: {}
            }

            this.initialInputValues = true
            this.inputErrors = {}

            
            this.file = null,
            this.description = ''
        },

        // globar input methods
        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        // delete
        deleteParticipantVisaDoc (pIndex, pParticipantVisaDocument) {
            swal(`Se eliminará.`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pParticipantVisaDocument, 'deleteLoading', true)
                            destroyParticipantVisaDocument(pParticipantVisaDocument.id)
                                .then(() => {
                                    this.participantVisaDocuments.splice(pIndex, 1)
                                    this.getVisaData()
                                })
                                .finally(() => {
                                    pParticipantVisaDocument.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-partVisaDoc-swal-break');
                            break;
                    }
                })
        },

        reloadData() {
            this.getData()
        }

    }
}
</script>