<template>
    <b-row>
        <b-col>
            <b-card class="shadow">
                <b-card-header>
                    <h3 class="mb-0 text-button text-primary">
                        Detalles de Empleado
                    </h3>
                </b-card-header>

                <b-card-text class="mt-5">
                    <ul class="ml-4">
                        <li> nombre: {{ this.employeeData.attributes.name }}</li>
                        <li> apellido: {{ this.employeeData.attributes.last_name }}</li>
                        <li> tipo doc: {{ this.employeeData.attributes.document_type }}</li>
                        <li> documento: {{ this.employeeData.attributes.document_number }}</li>
                        <li> celular: {{ this.employeeData.attributes.phone_number }}</li>
                        <li> correo: {{ this.employeeData.attributes.email }}</li>
                        <li> descripción: {{ this.employeeData.attributes.description }}</li>
                        <li> estado de usuario: {{ this.employeeData.attributes.user_status }}</li>
                        <li> estado de empleado: {{ this.employeeData.attributes.employee_status }}</li>
                    </ul>
                </b-card-text>
            </b-card>
        </b-col>

        <b-col cols="12" lg="7" class="mt-4 mt-lg-0">
            <manage-roles 
                v-if="showRoles"
                :userId="employeeData.attributes.user_id"
                role="empleado"
            />
        </b-col>

        <b-col cols="12" class="mt-4">
            <manage-permissions 
                v-if="showRoles"
                :employeeId="employeeData.attributes.id"
            />
        </b-col>
    </b-row>
</template>

<script>
import { getEmployee } from '@/api/Employee/Employee'
import ManageRoles from '@/layout/Admin/ManageRoles.vue'
import ManagePermissions from '@/layout/Admin/ManagePermissions.vue'
import swal from 'sweetalert'

export default {
    name: 'EmployeesDetails',
    components: {
        ManageRoles,
        ManagePermissions,
    },
    data: () => ({
        employeeData: {
            attributes: {
                 //employee
                id: '',
                user_id: '',
                description: '',
                employee_status: '',
                //user
                name: '',
                last_name: '',
                document_type: '',
                document_number: '',
                phone_number: '',
                email: '',
                user_status: '',
            }
        },
        //
        loadingEmployeeData: false,
        showRoles: false
    }),
    created () {
        this.getEmployeeData()
    },
    methods: {
        getEmployeeData () {
            this.loadingEmployeeData = true

            getEmployee(this.$route.params.id) //IdEmployee
                .then(({data}) => {
                    this.employeeData = data.data
                    this.showRoles = true
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        swal('404', 'Recurso no encontrado', 'warning')
                            .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.$router.push({ name: 'admin-employees-list' })
                                        break;

                                    default:
                                        console.log('employeeDetails-getEmployee-swal-default-break');
                                        break;
                                }
                            })
                    }
                })
                .finally(() => {
                    this.loadingEmployeeData = false
                })
        },
    },

}
</script>