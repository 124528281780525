<template>
    <general-job-inputs-component></general-job-inputs-component>
</template>

<script>
import GeneralJobInputsComponent from '@/components/Managment/Sponsors/GeneralJobInputsComponent.vue'

export default {
    name: 'GeneralJobInputs',
    components: { GeneralJobInputsComponent },
}
</script>