import { get, post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

// get-postulant-vouchers - without paginate
function getPostulantVouchers (pIdProgramSeasonPostulant) {
    const URI = `${BASE_URL}/api/programSeasonPostulants/${pIdProgramSeasonPostulant}/postulantVouchers`
    return get(URI, { headers })
}

function updatePostulantVoucher (pFormData, pIdPostulantVoucher) {
    const URI = `${BASE_URL}/api/postulantVouchers/${pIdPostulantVoucher}`
    return post(URI, pFormData, { headers })
}

function storePostulantVoucher (pFormData) {
    const URI = `${BASE_URL}/api/postulantVouchers`
    return post(URI, pFormData,  { headers })
}
export {
    getPostulantVouchers,
    updatePostulantVoucher,
    storePostulantVoucher
}