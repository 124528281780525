<template>
  <div id="app">
    <v-app>
      <notifications></notifications>
      <fade-transition :duration="350" origin="left" mode="out-in">
          <router-view/>
      </fade-transition>
    </v-app>

    <!-- MODAL UPDATE ROLES -->
    <b-modal
        id="modal-intrefaz"
        hide-footer hide-header-close
        title="Actualización de interfaz"
        size="sm"
        centered
        title-class="text-primary text-uppercase"
    >
        <div style="height:250px;">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'14px'"
                :size="28"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"
            />
        </div>
    </b-modal>
  </div>
</template>

<script>
/** */
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');
import { FadeTransition  } from 'vue2-transitions';

export default {
    components: { FadeTransition },

    created () {
        this.retrieveNotifications()
        this.iterativeRequest()
    },

    mounted () {
        this.pusherConection()
    },

    methods: {
        pusherConection () {
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: '16fe0e10606c1b51bc59',
                cluster: 'us2',
                forceTLS: true
            });

            window.Echo.channel('user-role-changed.user.' + this.$store.state.user.attributes.IdUser).listen("UserRoles\\UserRolesChangedEvent", (e) => {
                this.$bvModal.show('modal-intrefaz')
                this.$store.commit('user/SET_USER_ROLES_PUSHER', e.roles, { root: true })
            })
            
            window.Echo.channel('channel-test').listen("Test\\OrderStatusChangedEvent", (e) => {
                console.log('pusher: ', e.mensaje )
            })
        },

        // default param ghost false
        retrieveNotifications (pGhost = false) {
            this.$store.dispatch('notification/getData', { ghost: pGhost }, { root: true })
        },

        iterativeRequest () {
            setTimeout(() => {
                // with param ghost true
                this.retrieveNotifications(true)
                this.iterativeRequest()
            }, 60 * 1000);
        }
    }
}
</script>

<style>
*::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
*::-webkit-scrollbar-track {
		border-radius: 50px;
		background-color: rgba(94, 114, 228, 0.15);
	}
*::-webkit-scrollbar-thumb {
		border-radius: 50px;
		background-color: rgba(94, 114, 228, 0.35);
	}
*::-webkit-scrollbar-thumb:hover {
		background-color: rgba(94, 114, 228, 0.65);
	}

.apk-nav-tabs::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.apk-nav-tabs::-webkit-scrollbar-track {
    border-radius: 50px;
    /* background-color: rgba(84, 77, 119, 0.45); */
    background-color: rgba(94, 114, 228, 0.15);
}
.apk-nav-tabs::-webkit-scrollbar-thumb {
    border-radius: 50px;
    /* background-color: rgba(84, 77, 119, 0.55); */
    background-color: rgba(94, 114, 228, 0.35);
}
.apk-nav-tabs::-webkit-scrollbar-thumb:hover {
    /* background-color: rgba(84, 77, 119, 0.65); */
    background-color: rgba(94, 114, 228, 0.65);
}
</style>