import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#5e72e4',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                //
                violeta:    '#685BC7',
                cielo:      '#41B6E6',
                naranja:    '#FFAD00',
                magenta:    '#EF4A81',
                turquesa:   '#64CCC9',
                //
                'schedule-english-3':  '#2F5D62',
                'schedule-english-2':  '#5E8B7E',
                'schedule-english-1':  '#A7C4BC',
                'schedule-english-0':  '#DFEEEA',
                //
                'schedule-talks-3':  '#494CA2',
                'schedule-talks-2':  '#8186D5',
                'schedule-talks-1':  '#C6CBEF',
                'schedule-talks-0':  '#E3E7F1',
                //
                'apk-bg-1':  '#f7f8fe',
            },
        },
    },
});
