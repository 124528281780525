import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// programSeasonSponsor.programJobField
function getProgramSeasonSponsorProgramJobFields (pIdProgramSeasonSponsor) {
    const URI = `${BASE_URL}/api/programSeasonSponsors/${pIdProgramSeasonSponsor}/programJobFields`
    return get(URI, { headers })
}
export {
    getProgramSeasonSponsorProgramJobFields,
}