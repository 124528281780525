<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" 
                                :disabled="programSeasonLoading"
                                @click="modalChooseSponsorClick"
                            ><b-icon icon="plus" scale="1.5"></b-icon>
                            </b-button>

                            <b-button
                                variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Sponsor</th>
                            <th>programa</th>
                            <th>temporada</th>
                            <th>Descripción</th>
                            <th>estado</th>
                            <th class="text-center">detalles</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.attributes.sponsor_name }}</td>
                            <td>{{ row.attributes.program }}</td>
                            <td>{{ row.attributes.season }}</td>
                            <td>{{ row.attributes.description }}</td>
                            <td>{{ row.attributes.status }}</td>
                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: 'admin-program-sponsor-details',
                                        params: { 'IdProgramSeasonSponsor': row.id }    //IdProgramSeasonSponsor
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                ><b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <div v-show="row.attributes.programSponsorJobs == 0">
                                    <pulse-loader
                                        :loading="row.deleteLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'2px'"
                                        :size="8"
                                        class="pt-2 pb-1"
                                    ></pulse-loader>

                                    <b-btn variant="transparent" size="sm" 
                                        v-show="!row.deleteLoading && true"
                                        @click="deleteProgramSeasonSponsor(row)"
                                    ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                    </b-btn>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>

        <!-- modal -->
        <!--
            modal choose sponsor
        -->
        <b-modal
            id="modal-choose-sponsor"
            centered
            hide-footer
            size="md"
            header-class="pb-0"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">SELECCIONAR SPONSOR</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalChooseSponsorClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <!-- laodingTableData -->
                            <div style="height:150px;" v-show="loadingsSonsorJobOfferTableData">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                            <!-- card participants table -->
                            <b-card no-body>
                                <div class="table-responsive mb-0" v-show="!loadingsSonsorJobOfferTableData">
                                    <base-table class="table align-items-center table-flush"
                                        thead-classes="thead-light"
                                        tbody-classes="list"
                                        :data="sponsorJobOfferTableData"
                                    >
                                        <template slot="columns">
                                            <th>Sponsor</th>
                                            <th>programa</th>
                                            <th></th>
                                        </template>

                                        <template slot-scope="{row}">
                                            <td>{{ row.attributes.sponsor_name}}</td>
                                            <td>{{ row.attributes.program_name}}</td>
                                            <td class="text-center">
                                                <b-btn variant="green" size="sm" v-show="row.attributes.input_order != ''"
                                                    @click="modalCreateProgramSeasonSponsorClick(row.attributes)"
                                                ><b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-btn>
                                            </td>
                                        </template>
                                    </base-table>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!--
            modal register sponsor
        -->
        <b-modal
            id="modal-create-program-season-sponsor"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            header-class="pb-0"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">Crear</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalCreateProgramSeasonSponsorClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12" class="mb-3 d-flex justify-content-center">
                            <div class="py-3 px-4 d-flex align-items-center modal-user-data">
                                <b-icon icon="person-square" variant="primary" class="mr-3"/>
                                <span class="text-primary">
                                    {{ selectedSponsorJobOffer.sponsor_name }} - {{ selectedSponsorJobOffer.program_name }}
                                </span>
                            </div>
                        </b-col>
                        <b-col cols="12">
                             <span class="text-danger"
                                v-for="(inputError, i) in showModalInputErrors('programSeasonSponsor_code')"
                                :key="`${i}-createPostulant-programSeasonSponsor_code`"
                            >{{ inputError }}</span>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="createProgramSeasonSponsor">
                                <b-form-row>
                                    <!-- switch -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="¿Mostrar excel de ofertas laborales? "
                                        >
                                            <pulse-loader
                                                :loading="storeLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-checkbox
                                                v-model="show_excel"
                                                v-show="!storeLoading"
                                                switch
                                                value="si"
                                                unchecked-value="no"
                                                @change="showExcelChanged"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <!-- sheet_ID -->
                                    <transition name="slide-fade">
                                    <b-col cols="12" v-show="show_excel == 'si'">
                                        <b-form-group
                                            label="Sheet_ID: "
                                            description=" Ejemplo: https://docs.google.com/spreadsheets/d/SHEET_ID/edit#gid=315073237"
                                        >
                                            <pulse-loader
                                                :loading="storeLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                v-model="excel_identifier"
                                                placeholder="Código que esta después de: '/d/' de la url de su excel."
                                                :state="showModalInputStatus('excel_identifier')"
                                                v-show="!storeLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('excel_identifier')"
                                                :key="`${i}-createPostulant-excel_identifier`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    </transition>

                                    <!-- gid -->
                                    <transition name="slide-fade">
                                    <b-col cols="12" v-show="show_excel == 'si'">
                                        <b-form-group
                                            label="gid: "
                                            description=" Ejemplo: https://docs.google.com/spreadsheets/d/SHEET_ID/edit#gid=315073237"
                                        >
                                            <pulse-loader
                                                :loading="storeLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                v-model="gid"
                                                placeholder="Código gid de la url de su excel. ejm:315073237"
                                                :state="showModalInputStatus('gid')"
                                                v-show="!storeLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('gid')"
                                                :key="`${i}-createPostulant-gid`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    </transition>

                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="storeLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

    </b-row>
</template>

<script>
// programSeason.ProgramSeasonSponsor
import { getProgramSeasonProgramSeasonSponsors } from '@/api/ProgramSeason/ProgramSeasonProgramSeasonSponsor';
import { destroyProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor';
import { storeProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor'
import { getSponsorJobOffers } from '@/api/SponsorJobOffer/SponsorJobOffer';
import { getProgramSeason } from '@/api/ProgramSeason/ProgramSeason'
import swal from 'sweetalert'

export default {
    name: "SponsorsList",
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        programSeason: {},
        programSeasonLoading: false,
        //
        sponsorJobOfferTableData: [],
        loadingsSonsorJobOfferTableData: false,
        //
        selectedSponsorJobOffer: {},
        // form
        show_excel: 'no',
        excel_identifier: '',
        gid: '',

        modalInitialValues: true,
        modalInputErrors: {},
        storeLoading: false,
        //
        
    }),

    created() {
        this.getData()
    },

    methods: {
        getData (pPage = 1) {
            this.loadingTableData = true
            this.programSeasonLoading = true

            let IdProgramSeason = this.$route.params.IdProgramSeason

            getProgramSeasonProgramSeasonSponsors(pPage, IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta

                getProgramSeason(IdProgramSeason)
                    .then(({data}) => {
                        this.programSeason = data.data.attributes
                    })
                    .finally(() => {
                        this.programSeasonLoading = false
                    })
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        // Modal CHOOSE sponsor
        modalChooseSponsorClick () {
            this.getSponsors()
            this.$bvModal.show('modal-choose-sponsor')
        },
        modalChooseSponsorClose () {
            this.$bvModal.hide('modal-choose-sponsor')
        },

        getSponsors () {
            this.loadingsSonsorJobOfferTableData = true

            getSponsorJobOffers ()
                .then(({data}) => {
                    this.sponsorJobOfferTableData = data.data.filter(el => el.attributes.program_id == this.programSeason.program_id)
                    this.sponsorJobOfferTableData = this.sponsorJobOfferTableData.filter(el => {
                        let setAsOption = 'si'
                        for (const programSeasonSponsor of this.tableData) {
                            if (el.attributes.sponsor_id == programSeasonSponsor.attributes.sponsor_id) {
                                setAsOption = 'no'
                                break
                            }
                        }
                        return setAsOption == 'si'
                    })
                })
                .finally(() => {
                    this.loadingsSonsorJobOfferTableData = false
                })
        },

        // Modal CREATE program season sponsor
        modalCreateProgramSeasonSponsorClick (pSponsorJobOffer) {
            this.clearInputsAndStates()
            this.selectedSponsorJobOffer = {...pSponsorJobOffer}
            this.$bvModal.show('modal-create-program-season-sponsor')
        },
        modalCreateProgramSeasonSponsorClose () {
            this.$bvModal.hide('modal-create-program-season-sponsor')
            this.clearInputsAndStates()
        },

        createProgramSeasonSponsor () {
            this.storeLoading = true
            this.modalInitialValues = false
            this.modalInputErrors = {}

            let IdProgramSeason = this.$route.params.IdProgramSeason
            let ProgramSeasonSponsorForm = new FormData()

            ProgramSeasonSponsorForm.append('programSeason_id', IdProgramSeason)
            ProgramSeasonSponsorForm.append('sponsor_id', this.selectedSponsorJobOffer.sponsor_id)
            ProgramSeasonSponsorForm.append('programSeasonSponsor_code', `${IdProgramSeason}-${this.selectedSponsorJobOffer.sponsor_id}`)   // programSeason_id - sponsor_id
            ProgramSeasonSponsorForm.append('show_excel', this.show_excel)
            //
            if (this.show_excel == 'si') {
                ProgramSeasonSponsorForm.append('excel_identifier', this.excel_identifier)
                ProgramSeasonSponsorForm.append('gid', this.gid)
            } else {
                ProgramSeasonSponsorForm.append('excel_identifier', '')
                ProgramSeasonSponsorForm.append('gid', '')
            }
            //
            ProgramSeasonSponsorForm.append('sponsorJobOffer_id', this.selectedSponsorJobOffer.id)

            storeProgramSeasonSponsor(ProgramSeasonSponsorForm)
                .then(() => {
                    swal('Asignación correcta!', 'Sponsor registrado .', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getData()
                                    this.getSponsors()
                                    this.modalCreateProgramSeasonSponsorClose()
                                    this.modalChooseSponsorClose()
                                    break;
                                default:
                                    console.log('create english interview res swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.storeLoading = false
                    console.log('request userSearch end');
                })
        },

        clearInputsAndStates () {
            this.modalInitialValues = true
            this.modalInputErrors = true

            this.selectedSponsorJobOffer = {}
            this.show_excel = 'no'
            this.excel_identifier = ''
            this.gid = ''
        },

        showExcelChanged(e) {
            if (e == 'no') {
                this.excel_identifier = ''
                this.gid = ''
            }
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.modalInitialValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        //
        paginationCLiked (e) {
            this.getData(e)
        },
        reloadData () {
            this.getData()
        },

        // delete program-season-sponsor
        deleteProgramSeasonSponsor (pProgramSeasonSponsor) {
            swal('Se eliminará el sponsor registrado', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramSeasonSponsor, 'deleteLoading', true)
                            destroyProgramSeasonSponsor(pProgramSeasonSponsor.attributes.id)
                                .then(() => {
                                    this.getData()
                                })
                                .finally(() => {
                                    pProgramSeasonSponsor.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-programSeasonSponsor-swal-break');
                            break;
                    }
                })
        },

    }
}
</script>

<style scoped>
.modal-user-data {
    width: 100%;
    border: 1px solid #5e72e4;
    border-radius:10px;
    text-transform: uppercase;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>