<template>
    <b-row >
        <!-- postulant details -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow">
                <b-card-header class="pt-0 pb-  2" >
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                  Detalles del Sponsor
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData" :disabled="programSeasonSponsorLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonSponsor -->
                <div style="height:100px;" v-show="programSeasonSponsorLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- programSeasonSponsor details -->
                <div v-show="!programSeasonSponsorLoading" class="mt-5">
                    <b-row>
                        <b-col cols="12" md="6">
                            <b-list-group>
                                <b-list-group-item>
                                    <b-row>
                                        <b-col>Sponsor</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.sponsor_name }}</b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item>
                                    <b-row>
                                        <b-col>Programa</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.program }}</b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>

                        <b-col cols="12" md="6">
                            <b-list-group>
                                <b-list-group-item>
                                    <b-row>
                                        <b-col>Temporada</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.season }}</b-col>
                                    </b-row>
                                </b-list-group-item>

                                <b-list-group-item>
                                    <b-row>
                                        <b-col>Descripción</b-col>
                                        <b-col cols="1">:</b-col>
                                        <b-col>{{ programSeasonSponsor.description }}</b-col>
                                    </b-row>
                                </b-list-group-item>
                             </b-list-group>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </b-col>

        <!-- sponsor-job-fields -->
        <b-col cols="12" lg="6">
            <b-card class="shadow" no-body>
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Campos
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalAddField">
                                <b-icon icon="plus"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadFieldsData" :disabled="fieldsLoading">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding fields -->
                <div style="height:250px;" v-show="fieldsLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <b-card
                    v-show="!fieldsLoading"
                    style="max-height:450px;"
                    no-body
                >
                    <div class="table-responsive" >
                        <table class="table tablesorter align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th></th>
                                    <th>Campo</th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                </tr>
                            </thead>

                            <tbody class="list" id="program-job-fields">
                                <tr v-for="(order, i) in input_order" :key="i+'order'" :data-id="order">
                                    <td>
                                        <b-button variant="transparent" size="sm"
                                            style="cursor:grab"
                                            class="handle"
                                        ><b-icon icon="chevron-bar-expand" variant="primary" scale="1.3"></b-icon>
                                        </b-button>
                                    </td>

                                    <td v-for="(field, j) in programJobFields" :key="j+'tableOneSub'" v-show="order == field.id">
                                        {{ field.attributes.field_name }}
                                    </td>

                                    <td class="text-center">
                                        <div v-for="(field, j) in programJobFields" :key="j+'proJoF'" v-show="order==field.id">
                                            <div v-for="(enableInput, i) in enableInputsToAddToJobTitleOrder" :key="`${i}-e-i-add`" v-show="field.id==enableInput">
                                                <pulse-loader
                                                    :loading="field.deleteLoading || false"
                                                    :color="'#EF4A81'"
                                                    :margin="'2px'"
                                                    :size="8"
                                                    class="pt-2 pb-1"
                                                ></pulse-loader>

                                                <b-button
                                                    v-show="!field.deleteLoading && true"
                                                    size="sm"
                                                    variant="magenta"
                                                    @click="deleteField(field)"
                                                >
                                                    <b-icon icon="trash" scale="1.1"></b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="text-center">
                                        <div v-for="(field, j) in programJobFields" :key="j+'proJoF'" v-show="order==field.id">
                                            <div v-for="(enableInput, i) in enableInputsToAddToJobTitleOrder" :key="`${i}-e-i-add`" v-show="field.id==enableInput">
                                                <pulse-loader
                                                    :loading="field.addtitleOrderLoading || false"
                                                    :color="'#41B6E6'"
                                                    :margin="'2px'"
                                                    :size="8"
                                                    class="pt-2 pb-1"
                                                ></pulse-loader>

                                                <b-button
                                                    v-show="!field.addtitleOrderLoading && true"
                                                    size="sm"
                                                    variant="cielo"
                                                    @click="addTitleInputOrder(field)"
                                                >
                                                    <b-icon icon="plus" scale="1.2"></b-icon> IDEN.
                                                </b-button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-card>
            </b-card>

            <!-- modal add field -->
            <b-modal
                    id="modal-add-field"
                    centered
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                    size="md"
                    header-class="pb-0"
                >
                    <!-- modal header -->
                    <template #modal-header>
                        <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                            <b-row align-h="between" align-v="center">
                                <b-col cols="10">
                                    <h3 class="text-primary mb-0 text-uppercase">
                                        agregar campo
                                    </h3>
                                </b-col>

                                <b-col cols="2" class="text-right">
                                    <b-button size="sm" variant="magenta" @click="closeModalAddField">
                                        <b-icon icon="x" scale="1.5"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>

                    <!-- modal main content -->
                    <template #default>
                        <b-container>
                            <div style="height:200px;" v-show="getInputsLoading">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                            <b-list-group v-show="!getInputsLoading">
                                <b-list-group-item class="text-center text-uppercase" variant="primary" style="font-weight:bold">
                                    <span v-if="notFoundMessage == ''">{{ sponsorJobOffer.sponsor_name }} - {{ sponsorJobOffer.program_name }}</span>
                                    <span v-else>{{ notFoundMessage }}</span>
                                </b-list-group-item>

                                <b-list-group-item
                                    class="d-flex justify-content-between align-items-center"
                                    v-for="(jobOfferInput ,i) in jobOfferInputs" :key="`${i}-joi`"
                                    v-show="notFoundMessage == ''"
                                >
                                    <pulse-loader
                                        :loading="jobOfferInput.addLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="d-flex align-items-center"
                                    ></pulse-loader>

                                    <span v-show="!jobOfferInput.addLoading && true">
                                        {{ jobOfferInput.attributes.input_name }}
                                    </span>

                                    <b-button variant="green" size="sm" @click="addInput(jobOfferInput)"
                                        class="text-caption" :disabled="jobOfferInput.addLoading || false"
                                    ><b-icon icon="plus" scale="1.5"></b-icon>
                                    </b-button>
                                </b-list-group-item>
                            </b-list-group>
                        </b-container>
                    </template>
            </b-modal>
        </b-col>

        <!-- sponsor-job-fields-title-identifier -->
        <b-col cols="12" lg="6">
            <b-card class="shadow" no-body>
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-cielo text-button">
                                Identificador
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding fields -->
                <div style="height:250px;" v-show="fieldsLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <div
                    v-show="!fieldsLoading"
                    style="max-height:450px;"
                >
                    <b-list-group horizontal class="d-flex justify-content-center">
                        <b-list-group-item v-for="(order, i) in job_title_order" :key="`${i}-jto`" variant="cielo" class="py-2">
                            <p class="m-0 p-0 text-lowercase text-black" style="font-weight:bold;letter-spacing:2px;"
                                v-for="(field, i) in programJobFields" :key="`${i}-pjf`"
                                v-show="order==field.id"
                            >{{ field.attributes.field_name }}</p>
                        </b-list-group-item>
                    </b-list-group>

                    <div class="table-responsive" >
                        <table class="table tablesorter align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th></th>
                                    <th>Campo</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody class="list" id="program-job-fields-title-identifier">
                                <tr v-for="(item, i) in job_title_order" :key="i+'job_title_order'" :data-id="item">
                                    <td>
                                        <b-button variant="transparent" size="sm"
                                            style="cursor:grab"
                                            class="handle"
                                        ><b-icon icon="chevron-bar-expand" variant="cielo" scale="1.3"></b-icon>
                                        </b-button>
                                    </td>

                                    <td v-for="(field, j) in programJobFields" :key="j+'tableOneSub'" v-show="item == field.id">
                                        {{ field.attributes.field_name }}
                                    </td>

                                    <td v-for="(field, j) in programJobFields" :key="j+'tableOneSubtwo'" v-show="item == field.id  && job_title_order.length > 1">
                                        <pulse-loader
                                            :loading="field.deleteTitleOrderLoading || false"
                                            :color="'#EF4A81'"
                                            :margin="'2px'"
                                            :size="8"
                                            class="pt-2 pb-1"
                                        ></pulse-loader>

                                        <b-button
                                            v-show="!field.deleteTitleOrderLoading && true"
                                            size="sm"
                                            variant="magenta"
                                            @click="deleteTitleInputOrder(field)"
                                        ><b-icon icon="trash" scale="1.1"></b-icon>
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor'
import { getProgramSeasonSponsorProgramJobFields } from '@/api/ProgramSeasonSponsor/ProgramJobField'
import { updateProgramSeasonSponsorInputsOrder } from '@/api/ProgramSeasonSponsor/UpdateInputsOrder';
import { addProgramSeasonSponsorTitleOrder } from '@/api/ProgramSeasonSponsor/AddTitleOrder';
import { updateProgramSeasonSponsorJobTitleOrder } from '@/api/ProgramSeasonSponsor/UpdateJobTitleOrder';
import { deleteProgramSeasonSponsorTitleOrder } from '@/api/ProgramSeasonSponsor/DeleteTitleOrder';
//
// SponsorJobOffer.JobOfferInput
import { getSponsorJobOfferJobOfferInputs } from '@/api/SponsorJobOffer/JobOfferInput'; 
import { storeProgramJobField, destroyProgramJobField} from '@/api/ProgramJobField/ProgramJobField';
//
import Sortable from 'sortablejs';
import swal from 'sweetalert';

export default {
    name: "ProgramSponsorDetails",
    data: () => ({
        programSeasonSponsor: {
            attributes: {}
        },
        //
        mySortable: {},
        mySortableTwo: {},
        //
        input_order: '',
        job_title_order: '',
        enableInputsToAddToJobTitleOrder: [],
        programSeasonSponsorLoading: false,
        //
        programJobFields: {},
        fieldsLoading: false,
        //modal
        sponsorJobOffer: {},
        jobOfferInputs: [],
        getInputsLoading: false,
        //
        notFoundMessage: ''
    }),
    created() {
        this.getData()
        this.getFieldsData()
    },
    mounted () {
        this.setSortable()
        this.setSortableTwo()
    },
    methods: {
        getData () {
            this.programSeasonSponsorLoading = true

            let IdProgramSeasonSponsor = this.$route.params.IdProgramSeasonSponsor
            getProgramSeasonSponsor(IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.programSeasonSponsor = data.data.attributes
                    this.input_order = this.programSeasonSponsor.input_order.split('|')
                    this.job_title_order = this.programSeasonSponsor.job_title_order.split('|')
                    //a
                    this.enableInputsToAddToJobTitleOrder = this.input_order.filter(el => {
                        let enableToAdd = 'si'
                        for (const iterator of this.job_title_order) {
                            if (el == iterator) {
                                enableToAdd = 'no'
                                break
                            }
                        }
                        return enableToAdd == 'si'
                    })
                    //b
                    this.mySortable.sort(this.input_order, false)
                    this.mySortableTwo.sort(this.job_title_order, false)
                })
                .finally(() => {
                    this.programSeasonSponsorLoading = false
                    //b
                    this.mySortable.sort(this.input_order, false)
                    this.mySortableTwo.sort(this.job_title_order, false)
                })
        },
        getDataWithoutLoading () {
            getProgramSeasonSponsor(this.$route.params.IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.programSeasonSponsor = data.data.attributes
                    this.input_order = this.programSeasonSponsor.input_order.split('|')
                    this.job_title_order = this.programSeasonSponsor.job_title_order.split('|')
                    //a
                    this.enableInputsToAddToJobTitleOrder = this.input_order.filter(el => {
                        let enableToAdd = 'si'
                        for (const iterator of this.job_title_order) {
                            if (el == iterator) {
                                enableToAdd = 'no'
                                break
                            }
                        }
                        return enableToAdd == 'si'
                    })
                    //b
                    this.mySortable.sort(this.input_order, false)
                    this.mySortableTwo.sort(this.job_title_order, false)
                })
                .finally(() => {
                    //b
                    this.mySortable.sort(this.input_order, false)
                    this.mySortableTwo.sort(this.job_title_order, false)
                })
        },
        reloadData () {
            this.getData()
        },

        // SORTABLE ONE
        setSortable () {
            let el = document.getElementById('program-job-fields');

            this.mySortable = Sortable.create(el, {
                animation: 150,
                group: "program-job-fields",
                dataIdAttr: 'data-id',
                //
                handle: '.handle',
                //
                ghostClass: "sortable-ghost",
                chosenClass: "sortable-chosen",
                dragClass: "sortable-drag",
                //
                onEnd: () =>  {
                    console.log('el. end');
                },
                //
                store: {
                    set: sortable => {
                        let orderJoined = sortable.toArray().join('|')
                        let orderForm = new FormData()
                        orderForm.append('.method', 'put')
                        orderForm.append('input_order', orderJoined)
                        updateProgramSeasonSponsorInputsOrder (orderForm, this.$route.params.IdProgramSeasonSponsor)
                            .then(() => {
                                this.getDataWithoutLoading()
                            })
                    },
                },
            });
        },
        // END SORTABLE ONE

        // SORTABLE TWO
        setSortableTwo () {
            let el = document.getElementById('program-job-fields-title-identifier');

            this.mySortableTwo = Sortable.create(el, {
                animation: 150,
                group: "program-job-fields-title-identifier",
                dataIdAttr: 'data-id',
                //
                handle: '.handle',
                //
                ghostClass: "sortable-ghost",
                chosenClass: "sortable-chosen",
                dragClass: "sortable-drag",
                //
                onEnd: () =>  {
                    console.log('el. end');
                },
                //
                store: {
                    set: sortable => {
                        let orderJoined = sortable.toArray().join('|')
                        let orderForm = new FormData()
                        orderForm.append('.method', 'put')
                        orderForm.append('job_title_order', orderJoined)
                        updateProgramSeasonSponsorJobTitleOrder (orderForm, this.$route.params.IdProgramSeasonSponsor)
                            .then(() => {
                                this.getDataWithoutLoading()
                            })
                    },
                },
            });
        },
        // END SORTABLE TwO

        //programSeasonSponsor.ProgramJobFields
        getFieldsData () {
            this.fieldsLoading = true
            getProgramSeasonSponsorProgramJobFields(this.$route.params.IdProgramSeasonSponsor)
                .then(({ data }) => {
                    this.programJobFields = data.data
                })
                .finally(() => {
                    this.fieldsLoading = false
                })
        },
        reloadFieldsData () {
            this.getDataWithoutLoading()
            this.getFieldsData()
        },

        // add title order
        addTitleInputOrder (pProgramJobField) {
            let OrderForm = new FormData()

            swal(`Se agregará ${pProgramJobField.attributes.field_name}.`, '¿Desea agregar como identificador?', 'warning', { dangerMode: false, buttons: ['cancelar', 'Si, agregar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramJobField, 'addtitleOrderLoading', true)
                            OrderForm.append('.method', 'put')
                            OrderForm.append('IdProgramJobField', pProgramJobField.id)

                            addProgramSeasonSponsorTitleOrder(OrderForm, this.$route.params.IdProgramSeasonSponsor)
                                .then(() => {
                                    this.getDataWithoutLoading()
                                })
                                .finally(() => {
                                    pProgramJobField.addtitleOrderLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-addTitleInputOrder-swal-break');
                            break;
                    }
                })
        },
        // delete title order
        deleteTitleInputOrder (pProgramJobField) {
            let OrderForm = new FormData()

            swal(`Se quitará ${pProgramJobField.attributes.field_name}.`, '¿Desea quitar como identificador?', 'warning', { dangerMode: false, buttons: ['cancelar', 'Si, quitar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramJobField, 'deleteTitleOrderLoading', true)
                            OrderForm.append('.method', 'put')
                            OrderForm.append('IdProgramJobField', pProgramJobField.id)

                            deleteProgramSeasonSponsorTitleOrder(OrderForm, this.$route.params.IdProgramSeasonSponsor)
                                .then(() => {
                                    this.getDataWithoutLoading()
                                })
                                .finally(() => {
                                    pProgramJobField.deleteTitleOrderLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-deleteTitleInputOrder-swal-break');
                            break;
                    }
                })
        },

        // modal
        openModalAddField () {
            this.getInputs()
            this.$bvModal.show('modal-add-field')
        },
        closeModalAddField () {
            this.$bvModal.hide('modal-add-field')
        },
        getInputs () {
            this.getInputsLoading = true
            this.notFoundMessage = ''

            let filtered = this.programSeasonSponsor.sponsorJobOffers.filter(el => {
                if(el.attributes.program_id == this.programSeasonSponsor.program_id) return true
            })

            if (filtered.length == 1) {
                this.sponsorJobOffer = filtered[0].attributes

                getSponsorJobOfferJobOfferInputs(this.sponsorJobOffer.id)
                    .then(({ data }) => {
                        this.jobOfferInputs = data.data.filter(el => {
                            let setAsOption = 'si'
                            for (const programJobField of this.programJobFields) {
                                if (programJobField.attributes.field_name == el.attributes.input_name) setAsOption = 'no'
                            }
                            return setAsOption == 'si'
                        })
                    })
                    .finally(() => {
                        this.getInputsLoading = false
                    })
            } else {
                this.notFoundMessage = 'No existe el sponsor - programa'
                this.getInputsLoading = false
            }

        },
        addInput(pJobOfferInput) {
            this.$set(pJobOfferInput, 'addLoading', true)

            let InputForm = new FormData()
            InputForm.append('programSeasonSponsor_id', this.$route.params.IdProgramSeasonSponsor)
            InputForm.append('field_name', pJobOfferInput.attributes.input_name)

            storeProgramJobField(InputForm)
                .then(() => {
                    this.reloadFieldsData()
                    swal(`Se agregó correctamente.`, 'Campo agregado.', 'success', { dangerMode: false, buttons: ['Cerrar', 'Seguir agregando']})
                        .then(value => {
                            switch (value) {
                                case true:
                                    this.getInputs()
                                    break;
                                case null:
                                    this.closeModalAddField()
                                    break;
                                default:
                                    console.log('delete-addInput-swal-break');
                                    break;
                            }
                         })
                })
                .finally(() => {
                    pJobOfferInput.addLoading = false
                })
        },

        // delete field
        deleteField (pProgramJobField) {
            swal(`Se eliminará ${pProgramJobField.attributes.field_name}.`, '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramJobField, 'deleteLoading', true)
                            destroyProgramJobField(pProgramJobField.id)
                                .then(() => {
                                    this.reloadFieldsData()
                                })
                                .finally(() => {
                                    pProgramJobField.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-deleteField-swal-break');
                            break;
                    }
                })
        }
    }
}
</script>

<style scoped>
.sortable-ghost {
    background-color: #dfe1ee;
    cursor: grabbing;
}
.sortable-chosen {
    cursor: grabbing;
}
.sortable-drag {
    opacity: 0;
}
</style>