<template>
    <job-offers-inputs-components></job-offers-inputs-components>
</template>

<script>
import JobOffersInputsComponents from '@/components/Managment/Sponsors/SponsorJobOffers/JobOffersInputsComponents.vue'

export default {
  components: { JobOffersInputsComponents },
    name: 'JobOffersInputs'
}
</script>