import { post } from 'axios'
import store from '../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = {
    'Cache-Control': 'no-store, no-cache',
    Authorization: `Bearer ${store.state.user.attributes.token}`
}

function programSeasonPostulationSearch (pIdProgramSeason, pIdProgramSeasonSponsor, pFormData) {
    const URI = `${BASE_URL}/api/programSeason/${pIdProgramSeason}/programSeasonSponsor/${pIdProgramSeasonSponsor}/postulation-search`
    return post(URI, pFormData, { headers })
}

function programSeasonPostulationExport (pIdProgramSeason, pIdProgramSeasonSponsor, pFormData) {
    const URI = `${BASE_URL}/api/programSeason/${pIdProgramSeason}/programSeasonSponsor/${pIdProgramSeasonSponsor}/postulation-export`
    const responseType = 'blob'
    return post(URI, pFormData, { headers, responseType })
}

export {
    programSeasonPostulationSearch,
    programSeasonPostulationExport
}