import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

// sponsortJobOffer
function storeJobOfferInput (pFormdata) {
    const URI = `${BASE_URL}/api/jobOfferInputs`
    return axios.post(URI, pFormdata, { headers })
}

function destroyJobOfferInput (pIdJobOfferInput) {
    const URI = `${BASE_URL}/api/jobOfferInputs/${pIdJobOfferInput}`
    return axios.delete(URI, { headers })
}

export { storeJobOfferInput, destroyJobOfferInput }