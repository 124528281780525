import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = {
    'Cache-Control': 'no-store, no-cache',
    Authorization: `Bearer ${store.state.user.attributes.token}`
}


function seasonProgramSeasonParticipantSearch (pIdSeason, pFormData, pPage) {
    const URI = `${BASE_URL}/api/season/${pIdSeason}/programSeasonParticipant-search?page=${pPage}`
    return post(URI, pFormData, { headers })
}
function seasonProgramSeasonParticipantExport (pIdSeason, pFormData) {
    const URI = `${BASE_URL}/api/season/${pIdSeason}/programSeasonParticipant-export`
    const responseType = 'blob'
    return post(URI, pFormData, { headers, responseType })
}

export {
    seasonProgramSeasonParticipantSearch,
    seasonProgramSeasonParticipantExport
}