<template>
    <!-- create employee with new user -->
    <b-form @submit.prevent="createEmployee">
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Nombres del empleado"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese los nombres"
                        v-model="name"
                        :state="showInputStatus('name')"
                        v-show="!createEmployeeLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('name')" :key="`${i}-createEmploye-name`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Apellidos del empleado"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese los apellidos"
                        v-model="lastName"
                        :state="showInputStatus('last_name')"
                        v-show="!createEmployeeLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('last_name')" :key="`${i}-createEmploye-lastName`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>
    
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Tipo de documento"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-select 
                        v-model="documentType"
                        :options="documentTypeOptions"
                        :state="showInputStatus('document_type')"
                        v-show="!createEmployeeLoading"
                    >
                        <template #first>
                            <b-form-select-option value="" disabled>
                                -- Seleccione el tipo de documento --
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('document_type')" :key="`${i}-createEmploye-documentType`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Número de documento"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-input
                        type="number"
                        placeholder="Ingrese el número de documento"
                        v-model="documentNumber"
                        :state="showInputStatus('document_number')"
                        v-show="!createEmployeeLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('document_number')" :key="`${i}-createEmploye-documentNumber`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Número de celular"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-input
                        type="number"
                        placeholder="Ingrese el número de celular"
                        v-model="phoneNumber"
                        :state="showInputStatus('phone_number')"
                        v-show="!createEmployeeLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('phone_number')" :key="`${i}-createEmploye-phoneNumber`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>
    
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Correo"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-input
                        type="email"
                        placeholder="Ingrese el correo"
                        v-model="email"
                        :state="showInputStatus('email')"
                        v-show="!createEmployeeLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('email')" :key="`${i}-createEmploye-email`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
                <b-form-group
                    label="Descripción"
                >
                    <pulse-loader
                        :loading="createEmployeeLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #5e72e4"
                    />

                    <b-form-textarea 
                        placeholder="Ingrese una descripción (opcional)"
                        v-model="description"
                        :state="showInputStatus('description')"
                        v-show="!createEmployeeLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('description')" :key="`${i}-createEmploye-description`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- Employee permission -->
        <b-form-row>
            <b-col cols="12" sm="6">
                <b-card border-variant="light">
                    <b-card-header class="py-0 px-0 pb-2">
                        <pulse-loader
                            :loading="createEmployeeLoading"
                            :color="'#5e72e4'"
                            :margin="'5px'"
                            :size="10"
                            class="pt-2 pb-1 pl-2"
                            style="border-radius:5px; border:1px solid #5e72e4"
                        />

                        <b-form-checkbox
                            v-model="salesAdvisorChecked"
                            switch
                            size="sm"
                            v-show="!createEmployeeLoading"
                            >
                            Asesor de ventas
                        </b-form-checkbox>

                        <b-form-invalid-feedback 
                            v-for="(inputError, i) in showInputErrors('employee_permissions')"
                            :key="`${i}-createEmploye-permissions`"
                            :state="showInputStatus('employee_permissions')"
                        >
                            {{ inputError }}
                        </b-form-invalid-feedback>
                    </b-card-header>

                    <b-form-checkbox-group
                        class="mt-3 pl-5 pl-sm-3 pl-md-5"
                        v-model="salesAdvisorPermissions"
                        :options="advisorPermissions"
                        stacked
                        plain
                        :state="showInputStatus('employee_permissions')"
                        v-show="!createEmployeeLoading"
                    />

                </b-card>
            </b-col>

            <b-col cols="12" sm="6" class="mt-3 mt-sm-0">
                <b-card border-variant="light">
                    <b-card-header class="py-0 px-0 pb-2">
                        <pulse-loader
                            :loading="createEmployeeLoading"
                            :color="'#5e72e4'"
                            :margin="'5px'"
                            :size="10"
                            class="pt-2 pb-1 pl-2"
                            style="border-radius:5px; border:1px solid #5e72e4"
                        />

                        <b-form-checkbox
                            v-model="culturalAdvisorChecked"
                            switch
                            size="sm"
                            v-show="!createEmployeeLoading"
                        >
                            Asesor cultural
                        </b-form-checkbox>

                        <b-form-invalid-feedback 
                            v-for="(inputError, i) in showInputErrors('employee_permissions')"
                            :key="`${i}-createEmploye-permissions`"
                            :state="showInputStatus('employee_permissions')"
                        >
                            {{ inputError }}
                        </b-form-invalid-feedback>
                    </b-card-header>

                    <b-form-checkbox-group
                        class="mt-3 pl-5 pl-sm-3 pl-md-5"
                        v-model="culturalAdvisorPermissions"
                        :options="advisorPermissions"
                        stacked
                        plain
                        :state="showInputStatus('employee_permissions')"
                        v-show="!createEmployeeLoading"
                    />

                </b-card>
            </b-col>
        </b-form-row>

        <b-form-row class="justify-content-center">
            <b-col cols="12" sm="8">
                <b-button
                    class="w-100 mt-4 "
                    variant="primary"
                    type="submit"
                    :disabled="createEmployeeLoading"
                >CREAR</b-button>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<script>
import { storeEmployee } from '@/api/Employee/Employee'
import swal from 'sweetalert'
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'EmployeeNewUser',
    //components: { PulseLoader },
    data: () => ({
        //form
        name: '',
        lastName: '',
        documentType: '',
        documentNumber: '',
        phoneNumber: '',
        email: '',
        description: '',
        //
        createEmployeeLoading: false,
        //
        documentTypeOptions: [
            { value: 'extranjeria', text: 'carnet de extranjería' },
            { value: 'dni', text: 'dni' }
        ],
        //}
        initialInputValues: true,
        inputErrors: {},
        //
        advisorPermissions: [
            { value: 1, text: 'Work and travel'},
            { value: 2, text: 'Internship'},
            { value: 3, text: 'Trainee'},
            { value: 4, text: 'Aupair'},
            { value: 5, text: 'Teach'},
        ],
        salesAdvisorChecked: false,
        salesAdvisorPermissions: [],
        culturalAdvisorChecked: false,
        culturalAdvisorPermissions: [],
    }),

    watch: {
        salesAdvisorChecked() {
            if (this.salesAdvisorChecked) this.salesAdvisorPermissions = this.advisorPermissions.map(el => el.value)
            else this.salesAdvisorPermissions = []
        },
        culturalAdvisorChecked() {
            if (this.culturalAdvisorChecked) this.culturalAdvisorPermissions = this.advisorPermissions.map(el => el.value)
            else this.culturalAdvisorPermissions = []
        }
    },

    methods: {
        createEmployee () {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createEmployeeLoading = true
            
            let EmployeeFormData = new FormData()
            EmployeeFormData.append('name', this.name)
            EmployeeFormData.append('last_name', this.lastName)
            EmployeeFormData.append('document_type', this.documentType)
            EmployeeFormData.append('document_number', this.documentNumber)
            EmployeeFormData.append('phone_number', this.phoneNumber)
            EmployeeFormData.append('email', this.email)
            EmployeeFormData.append('description', this.description)

            // permissions
            if (this.salesAdvisorChecked || this.culturalAdvisorChecked) EmployeeFormData.append('employee_permissions', 'si')
            else EmployeeFormData.append('employee_permissions', 'no')

             // asesor-ventas
            if (this.salesAdvisorChecked) EmployeeFormData.append('create_sales_advisor_permission', 'si')
            else EmployeeFormData.append('create_sales_advisor_permission', 'no')

            // asesor-cultural
            if (this.culturalAdvisorChecked) EmployeeFormData.append('create_cultural_advisor_permission', 'si')
            else EmployeeFormData.append('create_cultural_advisor_permission', 'no')


            // sales-advisor-permissions
            if (this.salesAdvisorPermissions.includes(1)) EmployeeFormData.append('s_a_create_wat', 'si')
            else EmployeeFormData.append('s_a_create_wat', 'no')

            if (this.salesAdvisorPermissions.includes(2)) EmployeeFormData.append('s_a_create_internship', 'si')
            else EmployeeFormData.append('s_a_create_internship', 'no')

            if (this.salesAdvisorPermissions.includes(3)) EmployeeFormData.append('s_a_create_trainee', 'si')
            else EmployeeFormData.append('s_a_create_trainee', 'no')

            if (this.salesAdvisorPermissions.includes(4)) EmployeeFormData.append('s_a_create_aupair', 'si')
            else EmployeeFormData.append('s_a_create_aupair', 'no')
            
            if (this.salesAdvisorPermissions.includes(5)) EmployeeFormData.append('s_a_create_teach', 'si')
            else EmployeeFormData.append('s_a_create_teach', 'no')

            // cultural-advisor-permissions
            if (this.culturalAdvisorPermissions.includes(1)) EmployeeFormData.append('c_a_create_wat', 'si')
            else EmployeeFormData.append('c_a_create_wat', 'no')

            if (this.culturalAdvisorPermissions.includes(2)) EmployeeFormData.append('c_a_create_internship', 'si')
            else EmployeeFormData.append('c_a_create_internship', 'no')

            if (this.culturalAdvisorPermissions.includes(3)) EmployeeFormData.append('c_a_create_trainee', 'si')
            else EmployeeFormData.append('c_a_create_trainee', 'no')

            if (this.culturalAdvisorPermissions.includes(4)) EmployeeFormData.append('c_a_create_aupair', 'si')
            else EmployeeFormData.append('c_a_create_aupair', 'no')
            
            if (this.culturalAdvisorPermissions.includes(5)) EmployeeFormData.append('c_a_create_teach', 'si')
            else EmployeeFormData.append('c_a_create_teach', 'no')
            

            storeEmployee(EmployeeFormData)
                .then(() => {
                     swal('Creación correcta!', 'Nuevo empleado creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'admin-employees-list'})
                                    break;

                                default:
                                    console.log('employeeCreate-newUser-storeEmployee-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createEmployeeLoading = false
                    console.log('request create employee end');
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        }
    },
}
</script>