import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function updateProgramSponsorJobStatus (pFormdata, pIdProgramSponsorJob) {
    const URI = `${BASE_URL}/api/programSponsorJob/${pIdProgramSponsorJob}/updateStatus`
    return axios.post(URI, pFormdata, { headers })
}

export { updateProgramSponsorJobStatus }