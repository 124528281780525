<template>
    <page-information-component
        :code="code"
        :paragraphOne="text_one"
        :paragraphTwo="text_two"
    ></page-information-component>
</template>

<script>
import PageInformationComponent from '@/components/UI/PageInformationComponent.vue'

export default {
    name: 'OnePage',
    components: { PageInformationComponent },
    data: () => ({
        code: '400',
        text_one: 'No puedes tener más de',
        text_two: 'una ventana del mismo tipo.',
    })
}
</script>