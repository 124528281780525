<template>
    <router-link
        :to="link.path"
        class="text-uppercase intranet-link"
        style="font-weight: bold"
        exact-active-class="intranet-link-active"
        active-class="intranet-link-active"
    >{{ link.name }}</router-link>
</template>

<script>
export default {
    name: 'IntranetNavItem',
    props: {
        link: {
            type: Object,
        }
    },
}
</script>

<style scoped>
.intranet-link {
    color: rgba(255, 255, 255, .6);
    margin-right: 20px;
    margin-bottom: 2px;

}
.intranet-link-active {
    /* color: #EF4A81 !important; */
    color: #5e72e4 !important;
    background-color: white;
    padding: 2px 10px;
    margin-right: 20px;
    margin-bottom: 2px;
    border-radius: 3px;
}
</style>