import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getAllProgramDocumentsBySeason (pIdSeason) {
    const URI = `${BASE_URL}/api/all-programDocuments-by-season/${pIdSeason}/programDocumentsSorted`
    return axios.get(URI, { headers })
}

function storeProgramDocument (pFormData) {
    const URI = `${BASE_URL}/api/programDocuments`
    return axios.post(URI, pFormData, { headers })
}

function updateProgramDocument (pFormData, pIdProgramDocument) {
    const URI = `${BASE_URL}/api/programDocuments/${pIdProgramDocument}`
    return axios.post(URI, pFormData, { headers })
}

function destroyProgramDocument (pIdProgramDocument) {
    const URI = `${BASE_URL}/api/programDocuments/${pIdProgramDocument}`
    return axios.delete(URI, { headers })
}

export {
    getAllProgramDocumentsBySeason,
    storeProgramDocument,
    updateProgramDocument,
    destroyProgramDocument
 }