import axios from 'axios'

// function login(pUrl, pFormData) {
//     return axios.post(`${pUrl}/api/login`, pFormData)
// }

function login(pUrl, pFormData) {
    const URI = `${pUrl}/api/login`
    return axios.post(URI, pFormData)
}

export { login }